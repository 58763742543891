<div [fxLayout]="layout" [fxLayoutAlign]="layout === 'row' ? 'start center' : 'start start'"
     [fxLayoutGap]="layout === 'row' ? '8px' : '4px'" class="box">
  <span class="label">{{label}}:</span>
  <span *ngIf="!(showNoValue && !value); else notDefined"
        fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="start center">
    <mat-icon *ngIf="prefixIcon">{{prefixIcon}}</mat-icon>
    {{value}}
    <mat-icon *ngIf="suffixIcon">{{suffixIcon}}</mat-icon>
  </span>
</div>

<ng-template #notDefined>
  <span class="not-defined">{{noValueLabel}}</span>
</ng-template>
