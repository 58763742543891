<div class="center-container" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start center">
  <div>
    <h2>Put new password</h2>
  </div>
  <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
    <i class="fa fa-key" fxFlex="0 0 16px"></i>
    <input type="password" name="password" [(ngModel)]="newPassword" id="password"
           placeholder="Password" required>
  </div>
  <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
    <i class="fa fa-key" fxFlex="0 0 16px"></i>
    <input type="password" name="repassword" [(ngModel)]="newRePassword" id="repassword"
           placeholder="Re-Password" required>
  </div>
  <div>
    <button mat-raised-button color="primary" (click)="passChangAction()">
      Change password
      <i class="fa fa-unlock-alt"></i>
    </button>
  </div>
</div>
