import { DefaultForm } from '../default-form';
import { FormControl, Validators } from '@angular/forms';
import { ParkingInfo } from '../../../modules/transports/interfaces';

export class ParkingSpotForm extends DefaultForm<ParkingInfo> {
  constructor(controls?: any | null) {
    super({
      'id': new FormControl(null),
      'nazwa': new FormControl(null, Validators.required),
      'region': new FormControl(null, Validators.required),
      'kraj': new FormControl(null, Validators.required),
      'uwagi': new FormControl(null, Validators.required),
      'telefon': new FormControl(null, Validators.required),
      'telefon2': new FormControl(null, Validators.required),
      'atrakcje': new FormControl(null, Validators.required),

      'dozorowany': new FormControl(false, Validators.required),
      'kamery': new FormControl(false, Validators.required),
      'strzezony': new FormControl(false, Validators.required),
      'oswietlony': new FormControl(false, Validators.required),
      'nadzLadNiebezp': new FormControl(false, Validators.required),
      'ogrodzony': new FormControl(false, Validators.required),

      'pies': new FormControl(false, Validators.required),
      'nocleg': new FormControl(false, Validators.required),
    });
    if (controls) {
      this.patchFromModel(controls);
    }
  }

  enable(opts?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.enable(opts);
    setTimeout(() => super.enable(opts));
  }

  disable(opts?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.disable(opts);
    setTimeout(() => super.disable(opts));
  }
}
