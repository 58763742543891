import {
  Karta,
  KartaTerminal,
  OprogramowanieTerminala,
  PoleRamkiTypDanych,
  Ramka,
  SamochodTerminal,
  StatusTerminala,
  Terminal,
  TerminalNaczepa,
  WersjaRamkiOpr
} from '../models/dto/terminale';
import {
  GET_BOLT_PIN_SUCCESS,
  LOAD_FRAME_FIELD_TYPES_SUCCESS,
  LOAD_FRAMES_FOR_SOFTWARE_SUCCESS,
  LOAD_FRAMES_SUCCESS,
  LOAD_SIM_CARDS_SUCCESS,
  LOAD_TERMINAL_SOFTWARE_LIST_SUCCESS,
  LOAD_TERMINAL_STATUSES_SUCCESS,
  LOAD_TERMINALS_SIM_CARDS_SUCCESS,
  LOAD_TERMINALS_SUCCESS,
  LOAD_TERMINALS_TRAILS_SUCCESS,
  LOAD_TERMINALS_TRUCKS_SUCCESS,
  NEW_TERMINAL_TRAIL_SUCCESS,
  NEW_TERMINAL_TRUCK_SUCCESS,
  TerminalsActions,
  UPDATE_FRAME_FIELD_SUCCESS,
  UPDATE_FRAME_VERSION_SUCCESS,
} from './terminal.actions';

export interface State {
  frames: Ramka[];
  frameFieldTypes: PoleRamkiTypDanych[];
  softwareFrames: WersjaRamkiOpr[];

  terminals: Terminal[];
  terminalStatuses: StatusTerminala[];
  terminalTruck: SamochodTerminal[];
  terminalTrail: TerminalNaczepa[];
  softwareList: OprogramowanieTerminala[];
  terminalSimCards: KartaTerminal[];
  simCards: Karta[];
  boltPin: Terminal;
}

export const initialState: State = {
  frames: [],
  frameFieldTypes: [],
  softwareFrames: [],

  terminals: [],
  terminalStatuses: [],
  terminalTruck: [],
  terminalTrail: [],
  softwareList: [],
  terminalSimCards: [],
  simCards: [],
  boltPin: undefined
};

export function terminalReducer(state = initialState, action: TerminalsActions) {
  switch (action.type) {

    case LOAD_FRAMES_FOR_SOFTWARE_SUCCESS:
      return {...state, softwareFrames: action.payload.result};

    case GET_BOLT_PIN_SUCCESS:
      return {...state, boltPin: action.payload.data};

    case LOAD_FRAMES_SUCCESS:
      return {...state, frames: action.payload.frames};

    case LOAD_FRAME_FIELD_TYPES_SUCCESS:
      return {...state, frameFieldTypes: action.payload.typy};

    case UPDATE_FRAME_FIELD_SUCCESS: {
      const nFrames = [...state.frames];
      const res = nFrames.map(n => {
        if (n.id !== action.payload.ramka.id) {
          return n;
        }
        return {
          ...n, wersjaRamkis: n.wersjaRamkis.map(w => {
            if (w.id !== action.payload.wersja.id) {
              return w;
            }
            return {
              ...w, poleRamkis: w.poleRamkis.map(p => {
                if (p.id !== action.payload.pole.id) {
                  return p;
                }
                return action.payload.pole;
              })
            };
          })
        };
      });
      return {...state, frames: res};
    }
    case UPDATE_FRAME_VERSION_SUCCESS: {
      const nFrames = [...state.frames];
      const res = nFrames.map(n => {
        if (n.id !== action.payload.ramka.id) {
          return n;
        }
        return {
          ...n, wersjaRamkis: n.wersjaRamkis.map(w => {
            if (w.id !== action.payload.wersja.id) {
              return w;
            }
            return {...w, ...action.payload.wersja};
          })
        };
      });
      return {...state, frames: res};
    }
    case LOAD_TERMINALS_SUCCESS:
      return {...state, terminals: action.payload};
    case LOAD_TERMINAL_STATUSES_SUCCESS:
      return {...state, terminalStatuses: action.payload};
    case LOAD_TERMINALS_TRAILS_SUCCESS:
      return {...state, terminalTrail: action.payload};
    case LOAD_TERMINALS_TRUCKS_SUCCESS:
      return {...state, terminalTruck: action.payload};
    case LOAD_TERMINAL_SOFTWARE_LIST_SUCCESS:
      return {...state, softwareList: action.payload};
    case LOAD_TERMINALS_SIM_CARDS_SUCCESS:
      return {...state, terminalSimCards: action.terminalSimCards};
    case LOAD_SIM_CARDS_SUCCESS:
      return {...state, simCards: action.simCards};

    case NEW_TERMINAL_TRAIL_SUCCESS:
      return {...state, terminalTrail: [...state.terminalTrail, action.payload]};
    case NEW_TERMINAL_TRUCK_SUCCESS:
      return {...state, terminalTruck: [...state.terminalTruck, action.payload]};

    default:
      return {...state};
  }
}

export const getTerminals = (currState: State) => currState.terminals;
export const getFrames = (currState: State) => currState.frames;
export const getFrameFieldTypes = (currState: State) => currState.frameFieldTypes;
export const getSoftwareFrames = (currState: State) => currState.softwareFrames;
export const getTerminalStatuses = (currState: State) => currState.terminalStatuses;
export const getSoftwareList = (currState: State) => currState.softwareList;
export const getTerminalTruck = (currState: State) => currState.terminalTruck;
export const getTerminalTrail = (currState: State) => currState.terminalTrail;
export const getTerminalSimCards = (currState: State) => currState.terminalSimCards;
export const getSimCards = (customElements) => customElements.simCards;
export const getBoltPin = (currState: State) => currState.boltPin;
export const getTruckTerminalByTruckId = (currState: State, props) => {
  return currState ? currState.terminalTruck.find(t => t._samochod.id === props.truckId) : null;
};
