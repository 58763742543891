import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, forkJoin, Observable} from 'rxjs';
import {Injectable} from '@angular/core';

import {
  Marka,
  Model,
  Naczepa,
  PowodNieczynnosci,
  RodzajPojazdu,
  Samochod,
  StanPracy,
  StatusPojazdu,
  TypZapiecia
} from 'src/app/models/dto/vehicle';
import {TypNaczepy} from '../models/dto/vehicle';
import {environment} from 'src/environments/environment';
import {EnvHelper} from '../helpers';
import {CommonDirective} from '../modules/access-control/directives/common.directive';

@Injectable({
  providedIn: 'root'
})
export class TrucksInventoryService {

  vehiclesEndpoint = '';

  trails = new BehaviorSubject<Array<Naczepa>>([]);

  constructor(private http: HttpClient) {

    this.vehiclesEndpoint = EnvHelper.getApiUrl();
  }

  /**
   * wywołanie do API w celu pobrania nowych danych
   */
  loadTrucks(): Observable<Samochod[]> {
    return this.http
      .get<Samochod[]>(
        this.vehiclesEndpoint +
        environment.apiModules.Vehicle.samochody
      );
  }

  loadTruckById(id: number): Observable<Samochod> {
    return this.http
      .get<Samochod>(
        this.vehiclesEndpoint +
        environment.apiModules.Vehicle.samochody +
        '/' + id
      );
  }

  addNewTruck(info: Samochod): Observable<any> {
    const newObj = {
      '_firma': environment.apiModules.Company.firma + '/' + (info.id_firma || CommonDirective.userTokenDetails.jti),
      '_model': environment.apiModules.Vehicle.model + '/' + info._model.id,
      '_status': environment.apiModules.Vehicle.status_pojazdu + '/' + info._status.id,
      '_akt_stan_pracy': environment.apiModules.Vehicle.stan_pracy + '/' + info._akt_stan_pracy.id,
      '_typ_zapiecia': environment.apiModules.Vehicle.typ_zapiecia + '/' + info._typ_zapiecia.id,
      'nr_rej': info.nr_rej ?? '',
      'czy_ciagnik': info.czy_ciagnik,
      'dop_ladownosc': info.dop_ladownosc,
      'akt_ryzyko': info.akt_ryzyko,
      'l_przycisk_napad': info.l_przycisk_napad,
      'liczba_miejsc': info.liczba_miejsc,
      'czy_osobowy': info.czy_osobowy,
      'id_kierowca_1': info.id_kierowca_1,
      'id_kierowca_2': info.id_kierowca_2,
      'vin': info.vin,
      'phonenumber': info.phonenumber,
      'uwagi': info.uwagi.toString()
    };

    return this.http
      .post(
        this.vehiclesEndpoint + environment.apiModules.Vehicle.samochody, newObj
      );
  }

  updateTruck(record: Partial<Samochod>): Observable<unknown> {
    const data = {...record} as any;
    if (data._model) {
      data._model = environment.apiModules.Vehicle.model + '/' + record._model.id;
    }
    if (data._status) {
      data._status = environment.apiModules.Vehicle.status_pojazdu + '/' + record._status.id;
    }
    if (data._akt_stan_pracy) {
      data._akt_stan_pracy = environment.apiModules.Vehicle.stan_pracy + '/' + record._akt_stan_pracy.id;
    }
    if (data._typ_zapiecia) {
      data._typ_zapiecia = environment.apiModules.Vehicle.typ_zapiecia + '/' + record._typ_zapiecia.id;
    }
    data.uwagi = (data.uwagi ?? []).toString();

    return this.http
      .put(
        this.vehiclesEndpoint + environment.apiModules.Vehicle.samochody + '_edycja/' + record.id, data
      );
  }

  getTruckTablet(firstDriverId: number, secondDriverId: number): Observable<any> {
    if (secondDriverId) {
      return forkJoin(
        this.http.get<any>(
          this.vehiclesEndpoint + environment.apiModules.Vehicle.kierowcaTablet + '/' + firstDriverId),
        this.http.get<any>(
          this.vehiclesEndpoint + environment.apiModules.Vehicle.kierowcaTablet + '/' + secondDriverId)
      );
    } else {
      return this.http.get<any>(
        this.vehiclesEndpoint + environment.apiModules.Vehicle.kierowcaTablet + '/' + firstDriverId);
    }
  }

  addNewTrail(info: Naczepa): Observable<any> {
    const newObj = {
      'akt_ryzyko': info.akt_ryzyko || 0,
      'czy_full_praw_cm': info.czy_full_praw_cm || false,
      'czy_naczepa': info.czy_naczepa,
      'id_pojazdu_firmy': info.id_pojazdu_firmy,
      '_model': environment.apiModules.Vehicle.model + '/' + info._model.id,
      '_stan_pracy': environment.apiModules.Vehicle.stan_pracy + '/' + info._stan_pracy?.id,
      '_status': environment.apiModules.Vehicle.status_pojazdu + '/' + info._status.id,
      '_typ': environment.apiModules.Vehicle.typ_naczepy + '/' + info._typ.id,
      '_typ_zapiecia': environment.apiModules.Vehicle.typ_zapiecia + '/' + info._typ_zapiecia.id,
      '_firma': environment.apiModules.Company.firma + '/' + (info.id_firma || CommonDirective.userTokenDetails.jti),
      'ladownosc': info.ladownosc,
      'uwagi': info.uwagi,
      'nr_rej': info.nr_rej,
      'vin': info.vin
    };

    return this.http
      .post(
        this.vehiclesEndpoint + environment.apiModules.Vehicle.naczepy_v2, newObj
      );
  }

  updateTrail(trail: Naczepa): Observable<unknown> {
    const newObj = {
      'akt_ryzyko': trail.akt_ryzyko,
      'czy_full_praw_cm': trail.czy_full_praw_cm,
      'czy_naczepa': trail.czy_naczepa,
      'id_pojazdu_firmy': trail.id_pojazdu_firmy,
      '_model': environment.apiModules.Vehicle.model + '/' + trail._model.id,
      '_stan_pracy': environment.apiModules.Vehicle.stan_pracy + '/' + trail._stan_pracy?.id,
      '_status': environment.apiModules.Vehicle.status_pojazdu + '/' + trail._status.id,
      '_typ': environment.apiModules.Vehicle.typ_naczepy + '/' + trail._typ.id,
      '_typ_zapiecia': environment.apiModules.Vehicle.typ_zapiecia + '/' + trail._typ_zapiecia.id,
      '_firma': environment.apiModules.Company.firma + '/' + trail?._firma.id,
      'ladownosc': trail.ladownosc,
      'nr_rej': trail.nr_rej,
      'vin': trail.vin
    };

    return this.http
      .put(
        this.vehiclesEndpoint + environment.apiModules.Vehicle.naczepy + '_edycja/' + trail.id, newObj
      );
  }


  loadTrails(): Observable<Naczepa[]> {
    return this.http
      .get<Naczepa[]>(
        this.vehiclesEndpoint + environment.apiModules.Vehicle.naczepy
      );
  }

  // getTrailList(searchString?: string): Observable<Array<ZtNaczepa>> {
  //   // wykonaj zapytanie do bazy
  //   return this.trails.asObservable();
  // }

  //
  // updTruckInfo(info: TruckSimpleDto): void {
  //
  // }
  //
  // updTrailInfo(info: TrailSimpleDto): void {
  //
  // }


  getTrailTypes(): Observable<TypNaczepy[]> {
    return this.http.get<TypNaczepy[]>(
      this.vehiclesEndpoint + environment.apiModules.Vehicle.typ_naczepy
    );
  }

  delTrailType(type: TypNaczepy): Observable<any> {
    return this.http.delete(this.vehiclesEndpoint + environment.apiModules.Vehicle.typ_naczepy + '/' + type.id);
  }

  updTrailType(type: TypNaczepy): Observable<any> {
    return this.http.put(this.vehiclesEndpoint + environment.apiModules.Vehicle.typ_naczepy + '/' + type.id, {typ: type.typ});
  }

  addTrailType(type: TypNaczepy): Observable<any> {
    return this.http.post(this.vehiclesEndpoint + environment.apiModules.Vehicle.typ_naczepy, {typ: type.typ});
  }


  getConnectionTypes(): Observable<Array<TypZapiecia>> {
    return this.http.get<Array<TypZapiecia>>(this.vehiclesEndpoint + environment.apiModules.Vehicle.typ_zapiecia);
  }

  delconnectionType(type: TypZapiecia): Observable<any> {
    return this.http.delete(this.vehiclesEndpoint + environment.apiModules.Vehicle.typ_zapiecia + '/' + type.id);
  }

  updConnectionType(type: TypZapiecia): Observable<any> {
    return this.http.put(this.vehiclesEndpoint + environment.apiModules.Vehicle.typ_zapiecia + '/' + type.id,
      {nazwa: type.nazwa, opis: type.opis});
  }

  addConnectionType(type: TypZapiecia): Observable<any> {
    return this.http.post(this.vehiclesEndpoint + environment.apiModules.Vehicle.typ_zapiecia, {nazwa: type.nazwa, opis: type.opis});
  }


  getVehicleTypes(): Observable<Array<RodzajPojazdu>> {
    return this.http.get<Array<RodzajPojazdu>>(this.vehiclesEndpoint + environment.apiModules.Vehicle.rodzaj_pojazdu);
  }

  delVehicleType(type: RodzajPojazdu): Observable<any> {
    return this.http.delete(this.vehiclesEndpoint + environment.apiModules.Vehicle.rodzaj_pojazdu + '/' + type.id);
  }

  updVehicleType(type: RodzajPojazdu): Observable<any> {
    return this.http.put(this.vehiclesEndpoint + environment.apiModules.Vehicle.rodzaj_pojazdu + '/' + type.id, {rodzaj: type.rodzaj});
  }

  addVehicleType(type: RodzajPojazdu): Observable<any> {
    return this.http.post(this.vehiclesEndpoint + environment.apiModules.Vehicle.rodzaj_pojazdu, {rodzaj: type.rodzaj});
  }


  getUnavailReasons(): Observable<Array<PowodNieczynnosci>> {
    return this.http.get<Array<PowodNieczynnosci>>(this.vehiclesEndpoint + environment.apiModules.Vehicle.powod_nieczynn);
  }

  delUnavailReason(type: PowodNieczynnosci): Observable<any> {
    return this.http.delete(this.vehiclesEndpoint + environment.apiModules.Vehicle.powod_nieczynn + '/' + type.id);
  }

  updUnavailReason(type: PowodNieczynnosci): Observable<any> {
    return this.http.put(this.vehiclesEndpoint + environment.apiModules.Vehicle.powod_nieczynn + '/' + type.id, {powod: type.powod});
  }

  addUnavailReason(type: PowodNieczynnosci): Observable<any> {
    return this.http.post(this.vehiclesEndpoint + environment.apiModules.Vehicle.powod_nieczynn, {powod: type.powod});
  }


  getVehicleVendors(): Observable<Array<Marka>> {
    return this.http.get<Array<Marka>>(this.vehiclesEndpoint + environment.apiModules.Vehicle.marka);
  }

  delVehicleKind(type: Marka): Observable<any> {
    return this.http.delete(this.vehiclesEndpoint + environment.apiModules.Vehicle.marka + '/' + type.id);
  }

  updVehicleKind(type: Marka): Observable<any> {
    return this.http.put(this.vehiclesEndpoint + environment.apiModules.Vehicle.marka + '/' + type.id, {marka: type.marka});
  }

  addVehicleKind(type: Marka): Observable<any> {
    return this.http.post(this.vehiclesEndpoint + environment.apiModules.Vehicle.marka, {marka: type.marka});
  }


  getVehicleModels(): Observable<Array<Model>> {
    return this.http.get<Array<Model>>(this.vehiclesEndpoint + environment.apiModules.Vehicle.model);
  }

  delVehicleModel(type: Model): Observable<any> {
    return this.http.delete(this.vehiclesEndpoint + environment.apiModules.Vehicle.model + '/' + type.id);
  }

  updVehicleModel(type: Model): Observable<any> {
    return this.http.put(this.vehiclesEndpoint + environment.apiModules.Vehicle.model + '/' + type.id
      , {
        model: type.model,
        _marka: environment.apiModules.Vehicle.marka + '/' + type._marka.id,
        _rodzaj: environment.apiModules.Vehicle.rodzaj_pojazdu + '/' + type._rodzaj.id
      });
  }

  addVehicleModel(type: Model): Observable<any> {
    return this.http.post(this.vehiclesEndpoint + environment.apiModules.Vehicle.model
      , {
        model: type.model,
        _marka: environment.apiModules.Vehicle.marka + '/' + type._marka.id,
        _rodzaj: environment.apiModules.Vehicle.rodzaj_pojazdu + '/' + type._rodzaj.id
      });
  }

  getWorkStates(): Observable<StanPracy[]> {
    return this.http.get<StanPracy[]>(this.vehiclesEndpoint + environment.apiModules.Vehicle.stan_pracy);
  }

  getVehicleStates(): Observable<StatusPojazdu[]> {
    return this.http.get<StatusPojazdu[]>(this.vehiclesEndpoint + environment.apiModules.Vehicle.status_pojazdu);
  }

  removeVehicle(vehicle: Samochod): Observable<void> {
    return this.http.delete<void>(
      this.vehiclesEndpoint + environment.apiModules.Vehicle.samochody + '_edycja/' + vehicle.id
    );
  }

  removeVehicleForce(id: number): Observable<void> {
    return this.http.delete<void>(
      this.vehiclesEndpoint + environment.apiModules.Vehicle.samochody + '/' + id + '/force'
    );
  }

  removeTrail(trail: Naczepa): Observable<void> {
    return this.http
      .delete<void>(
        this.vehiclesEndpoint + environment.apiModules.Vehicle.naczepy + '_edycja/' + trail.id
      );
  }

  removeTrailForce(trail: Naczepa): Observable<void> {
    return this.http
      .delete<void>(
        `${this.vehiclesEndpoint}${environment.apiModules.Vehicle.naczepy}/${trail.id}/force`
      );
  }
}
