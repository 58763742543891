<button mat-fab color="{{mainColor}}" [matMenuTriggerFor]="menu">
  <mat-icon [innerHTML]="iconName"></mat-icon>
</button>
<mat-menu #menu="matMenu">
  <ng-container *ngFor="let link of menuItems$ | async">
    <button mat-menu-item
            *ngIf="link.itemType === undefined || link.itemType === 'link'"
            [routerLink]="link.path">
      <mat-icon *ngIf="link.icon" [innerHTML]="link.icon" class="material-icons-outlined {{link.icon}}"></mat-icon>
      {{link.title}}
    </button>

    <button mat-menu-item class="{{link.itemType}}"
            *ngIf="link.itemType === 'callback' && link.callbackAction"
            (click)="link.callbackAction()">
      <mat-icon *ngIf="link.icon" [innerHTML]="link.icon" class="material-icons-outlined {{link.icon}}"></mat-icon>
      {{link.title}}
    </button>
  </ng-container>
</mat-menu>
