import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { filter } from 'rxjs/operators';
import { PointGuard } from 'src/app/modules/shared/type-guards';
import * as fromRoot from '../../../../../app.reducer';
import { Privs } from '../../../../../helpers/enum';
import { AutocompleteSuggestion } from '../../../../../models/map/autocomplete';
import { HereService } from '../../../services/here.service';
import { LocationService } from '../../../services/location.service';
import { PointSearchBase } from '../../point-search-base/pont-search-base';
import { SearchBaseRouteComponent } from '../search-base-route/search-base-route.component';

@Component({
  selector: 'app-general-search-route',
  templateUrl: './general-search-route.component.html',
  styleUrls: ['./general-search-route.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralSearchRouteComponent extends SearchBaseRouteComponent {
  ePrivs = Privs;
  hintPoints: AutocompleteSuggestion[] = [];
  previousStartPoint: PointGuard;
  previousEndPoint: PointGuard;

  constructor(
    protected hereService: HereService,
    protected store: Store<fromRoot.State>,
    protected cd: ChangeDetectorRef
  ) {
    super(hereService, store, cd);
    this.subs.add(
      this.store
        .select(fromRoot.selectors.map.getReversGeocode)
        .pipe(filter((r) => r !== null && r !== undefined))
        .subscribe((point) => {
          const locationPoint = point;
          const pointId = locationPoint.location.locationId;
          switch ((point.type || '').toLowerCase()) {
            case 'start':
              const startPointCtl: AbstractControl =
                this.searchRouteForm.getStartPoint();
              if (startPointCtl.value) {
                this.removePoint(startPointCtl.value);
              }
              startPointCtl.setValue(locationPoint);
              this.hereService.addPoint(
                locationPoint.location.displayPosition,
                {
                  pointId,
                }
              );
              break;

            case 'stop':
              const endPointCtl: AbstractControl =
                this.searchRouteForm.getEndPoint();
              if (endPointCtl.value) {
                this.removePoint(endPointCtl.value);
              }
              endPointCtl.setValue(locationPoint);
              this.hereService.addPoint(
                locationPoint.location.displayPosition,
                {
                  pointId,
                }
              );
              break;

            case 'way-point':
            default:
              this.searchRouteForm
                .getViaPoints()
                .push(new FormControl(locationPoint));
              this.hereService.addPointDraggable(
                locationPoint.location.displayPosition,
                { pointId },
              );
              break;
          }

          this.cd.markForCheck();
        })
    );

    this.subs.add(
      this.searchRouteForm.getStartPoint().valueChanges.subscribe((value) => {
        this.removePoint(this.previousStartPoint);
        this.previousStartPoint = value;
      })
    );

    this.subs.add(
      this.searchRouteForm.getEndPoint().valueChanges.subscribe((value) => {
        this.removePoint(this.previousEndPoint);
        this.previousEndPoint = value;
      })
    );
  }

  downloadJson(type = 'json') {
    const searchBase: PointSearchBase = new PointSearchBase();
    if (!this.searchedRoute || this.searchedRoute.length < 1) {
      return false;
    }

    if (type === 'json') {
      const blob = new Blob([JSON.stringify(this.searchedRoute)], {
        type: 'text/plain',
      });
      saveAs(
        blob,
        moment().format('YYYYMMDD_HHmmss') + '_fdw-searched-route-export.json'
      );
      return false;
    }

    if (type === 'geojson') {
      const shape = this.searchedRoute[0]['response']['route'][0][
        'shape'
      ] as string[];
      const coordinates = shape.map((e) => {
        const r = e.split(',');
        return [+r[1], +r[0]];
      });

      const geojson = {
        type: 'FeatureCollection',
        name: 'FFM: ' + moment().format('YYYYMMDD_HHmmss'),
        features: [
          {
            type: 'Feature',
            properties: {
              od: searchBase.displayFn(
                this.searchRouteForm.get('poczatekTrasy').value
              ),
              do: searchBase.displayFn(this.searchRouteForm.get('koniecTrasy').value),
            },
            geometry: {
              type: 'LineString',
              coordinates: coordinates,
            },
          },
        ],
      };
      const blob = new Blob([JSON.stringify(geojson)], { type: 'text/plain' });
      saveAs(
        blob,
        moment().format('YYYYMMDD_HHmmss') +
          '_fdw-searched-route-export.geojson'
      );
      return false;
    }

    return false;
  }

  contextMenu = (e) => {
    if (e.target !== HereService.map) {
      return;
    }
    // ? Get geo coordinates from the screen coordinates.
    const coord = HereService.map.screenToGeo(e.viewportX, e.viewportY);

    e.items.push(
      // ? Create a menu item, that has only a label,
      // ? which displays the current coordinates.
      new HereService.H.util.ContextItem({
        label: LocationService.getMapCoordsStr(coord),
      }),
      // ? Create an item, that will change the map center when clicking on it.
      new HereService.H.util.ContextItem({
        label: 'Center map here',
        callback: function () {
          HereService.map.setCenter(coord, true);
        },
      }),
      // ? It is possible to add a separator between items in order to logically group them.
      HereService.H.util.ContextItem.SEPARATOR,
      // ? This menu item will add a new circle to the map
      new HereService.H.util.ContextItem({
        label: 'Starting point',
        callback: async () => {
          await this.reverseGeocodeFromCtxMenu(coord, 'start');
        },
      }),
      new HereService.H.util.ContextItem({
        label: 'New VIA point',
        callback: async () => {
          await this.reverseGeocodeFromCtxMenu(coord, 'way-point');
        },
      }),
      new HereService.H.util.ContextItem({
        label: 'Destination',
        callback: async () => {
          await this.reverseGeocodeFromCtxMenu(coord, 'stop');
        },
      })
    );
  }
}
