import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import * as fromRoot from '../../app.reducer';
import * as fromAlert from '../../modules/alerts/ngrx/alert.reducer';
import { GenericNavItem } from 'src/app/models/dto';
import { SecurityHelper, Strings, TransportAlert } from '../../helpers';
import * as fromTickets from '../../modules/tickets/ngrx/tickets.reducer';
import * as fromDrivers from '../../modules/driver-reports/ngrx/driver-reports.reducer';
import { TransportTicket } from '../../helpers/transport-ticket';
import { EventTypeEnum } from './components/event-quantity-badge/event-quantity-badge.component';
import { DriverReportsStatusHelper } from '../../modules/driver-reports/helper';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit, OnDestroy {
  subs = new Subscription();

  leftTopLinks: GenericNavItem[] = [];
  leftBottomLinks: GenericNavItem[] = [];
  isUserAuth$: Observable<boolean>;
  newAlertIndicator$: Observable<number> =
    this.store.select(fromAlert.getFlattenedTransportAlerts)
      .pipe(
        map(flattenedAlerts => {
          return flattenedAlerts.filter(TransportAlert.isNewAlert).length;
        })
      );
  newTicketIndicator$: Observable<number> =
    this.store.select(fromTickets.getAllTickets)
      .pipe(map(tickets => tickets.filter(TransportTicket.isTicketActive).length));
  newDriverReportIndicator$: Observable<number> =
    this.store.select(fromDrivers.getDriverReportsByTypeId)
      .pipe(map(driverReports => driverReports.filter(DriverReportsStatusHelper.isDriverReportNew).length));
  eventType: typeof EventTypeEnum = EventTypeEnum;

  constructor(private store: Store<fromRoot.State>) {
  }

  ngOnInit(): void {
    this.isUserAuth$ = this.store.select(fromRoot.selectors.auth.getAuthIsAuthenticated);
    this.subs.add(
      this.store.select(fromRoot.selectors.ui.getTopLeftMenuLinks)
        .pipe(
          filter(r => Strings.getObjectHash(r) !== Strings.getObjectHash(this.leftTopLinks))
        )
        .subscribe(r => this.leftTopLinks = SecurityHelper.filterLinks([...r]))
    );

    this.subs.add(
      this.store.select(fromRoot.selectors.ui.getTopRightMenuLinks)
        .pipe(
          filter(r => r.length > 0)
        )
        .subscribe(r => this.leftBottomLinks = r)
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
