import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ReverseGeocodeResponse } from 'src/app/models/map/reverseGeocode';
import { PointSearchBase } from '../point-search-base/pont-search-base';

@Component({
  selector: 'via-points',
  templateUrl: './via-points.component.html',
  styleUrls: ['./via-points.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViaPointsComponent implements OnInit, OnDestroy {
  @Input() viaPoints: FormArray;
  @Input() disabled = false;
  @Output() pointRemoved: EventEmitter<ReverseGeocodeResponse> =
    new EventEmitter();

  private changeSubscription: Subscription;
  searchBase: PointSearchBase = new PointSearchBase();

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.changeSubscription = this.viaPoints.valueChanges.subscribe((_) => {
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.changeSubscription.unsubscribe();
  }

  drop(event: CdkDragDrop<ReverseGeocodeResponse[]>): void {
    const value = [...this.viaPoints.value];
    moveItemInArray(value, event.previousIndex, event.currentIndex);
    this.viaPoints.setValue(value);
  }

  remove(point: ReverseGeocodeResponse, index: number): void {
    this.viaPoints.removeAt(index);
    this.pointRemoved.emit(point);
  }
}
