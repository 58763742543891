export enum UserRoles {
  ADMIN_FDW = 1,
  ADMIN = 2,
  DYSPOZYTOR = 3,
  KIEROWNIK_SERWISU = 4,
  SERWISANT = 5,
  NIEZNANA = 6,
  KIEROWCA = 7,
  CM = 8,
}
