import { DefaultForm } from '../default-form';
import { NewTransitRouteRequest } from './new-transit-route-request';
import { FormControl, Validators } from '@angular/forms';

export class MapSaveSearchedRouteForm extends DefaultForm<NewTransitRouteRequest> {

  constructor(controls?: NewTransitRouteRequest | null) {
    super({
      nazwa_trasy: new FormControl('', Validators.required),
      czy_utworzyc_szablon: new FormControl(true),
      nazwa_szablonu: new FormControl('', Validators.required),
      czy_prywatny: new FormControl(false),
      id_przejazd: new FormControl('', Validators.required),
      id_wersja_mapy: new FormControl('2', Validators.required),
      numer_porzadkowy: new FormControl('', Validators.required),
      przelicz_trase: new FormControl(true),
      trasa_map_json: new FormControl('', Validators.required),
      id_punkt_sped_poczatkowy: new FormControl('', Validators.required),
      id_punkt_sped_koncowy: new FormControl('', Validators.required),
    });

    if (controls) {
      this.patchFromModel(controls);
    }

    this.get('czy_utworzyc_szablon')
      .valueChanges
      .subscribe(r => {
        this.get('nazwa_szablonu').clearValidators();
        if (r) {
          this.get('nazwa_szablonu').setValidators([Validators.required]);
        }
        this.get('nazwa_szablonu').updateValueAndValidity();
      });
  }

}
