import { GenericNavItem } from '../models/dto';
import { HasRolesDirective } from '../modules/access-control/directives/by-role/has-roles.directive';
import { HasPrivilegesDirective } from '../modules/access-control/directives/by-privilege/has-privileges.directive';
import { Privs } from './enum';

export class SecurityHelper {
  static filterLinks(links: GenericNavItem[]): GenericNavItem[] {
    return links.filter(link => {
      if (!link.security) {
        return true;
      }
      switch (link.security.type) {
        case 'PRIVILEGES':
          return HasPrivilegesDirective.check(link.security.value as Privs[]);

        case 'ROLES':
          return HasRolesDirective.check((link.security.value ?? []) as string[]);

        default:
          return false;
      }
    });
  }
}
