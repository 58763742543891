import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { debounceTime, filter } from 'rxjs/operators';

import * as fromRoot from '../../../../../../app.reducer';
import * as UI from '../../../../../../ngrx/ui.actions';

import { HereService } from '../../../../services/here.service';
import { SideLinkActionData, TerminalLocationSat, TerminalLocationShort } from '../../../../../../models/helpers';
import { TrackInformationComponent } from '../../track-information/track-information.component';
import { ObjectInformationComponent } from '../../object-information/object-information.component';
import { MapObjectsHelper } from '../MapObjects.helper';
import { Terminal } from '../../../../../../models/dto/terminale';
import { Filtry } from '../../../../../../models/map';
import { ToastType } from '../../../../../../helpers/enum';
import { MapNgrxActions } from '../../../../enum';
import { LocationService } from '../../../../services/location.service';

interface PreviousPoint {
  no: number;
  dl: number;
  szer: number;
  czas: number;
}

@Component({
  selector: 'app-terminal-track-points',
  templateUrl: './terminal-track-points.component.html',
  styleUrls: ['./terminal-track-points.component.scss']
})
export class TerminalTrackPointsComponent implements OnInit, OnDestroy {
  @Input() lastAction: SideLinkActionData = null;
  pngCache: { [key: string]: any } = {};
  private filters: Filtry;
  private subs = new Subscription();
  private previousPoint: PreviousPoint = {czas: 0, dl: 0, szer: 0, no: -1};
  private trackTimeIntervalSeconds = 120;

  constructor(private store: Store<fromRoot.State>,
              private hereService: HereService,
              private dialog: MatDialog) {
  }

  ngOnInit() {

    this.subs.add(
      this.store.select(fromRoot.selectors.ui.getMapSearchEventsNumber)
        .pipe(
          debounceTime(500),
          filter(r => r === 0)
        )
        .subscribe(() => {
          this.store.dispatch(UI.showUserMessage({message: {
              type: ToastType.SUCCESS, message: 'The map is ready'
            }}));
        })
    );

    this.subs.add(
      combineLatest([
        this.store.select(fromRoot.selectors.devices.getTerminals),
        this.store.select(fromRoot.selectors.map.getTerminalTrackPoints),
        this.store.select(fromRoot.selectors.auth.getAuthIsAuthenticated),
        this.store.select(fromRoot.selectors.map.getFilters),
      ])
        .pipe(
          filter(res =>
            res[0] && res[0].length > 0
            && res[1] && res[1].points.length > 0
            && res[2]
          )
        )
        .subscribe(([terminals, tracks, , filters]) => {
          this.filters = filters;
          if (this.lastAction && this.lastAction.value !== MapNgrxActions.SHOW_TRACK) {
            return false;
          }

          HereService.map.removeObjects(HereService.map.getObjects());

          tracks.points.forEach(positions => {
            if (tracks.search && tracks.search.pokazLinie) {
              this.putLines([...positions], terminals);
            }

            if (tracks.search && tracks.search.pokazPunkty) {
              this.putPoints([...positions], terminals);
            }
          });

          tracks.satPoints.forEach(positions => this.putSatPoints([...positions], terminals));
        })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private putSatPoints(positions: TerminalLocationSat[], terminals: Terminal[]) {
    [...positions]
      .sort((a, b) => a.czas > b.czas ? -1 : 1)
      .forEach((terminalPosition) => {
        const coords =  LocationService.getCoordsMap(terminalPosition);

        const terminal = terminals.find(term => term.id === terminalPosition.id_terminal);
        const domElement = document.createElement('img');
        domElement.src = 'assets/img/map/marker-sat.svg';
        domElement.style.left = '-14px';
        domElement.style.top = '-28px';
        const object = new HereService.H.map.DomMarker(coords, {
          data: {
            ost_data: terminalPosition.czas,
            terminal,
            terminalMismatch: typeof terminal === 'undefined',
            ost_dane: terminalPosition
          },
          icon: new HereService.H.map.DomIcon(domElement)
        });
        object.addEventListener('tap', (evt) => {
          const pointData = evt.target.getData();

          const dialogConfig = new MatDialogConfig();
          dialogConfig.data = pointData;

          this.dialog.open(ObjectInformationComponent, dialogConfig);
        });
        HereService.map.addObject(object);
      });
  }

  private putPoints(positions: TerminalLocationShort[], terminals: Terminal[]) {
    this.store.dispatch(UI.mapSearchRouteProcessing());
    [...positions]
      .sort((a, b) => a.czas > b.czas ? -1 : 1)
      .forEach((terminalPosition) => {
        if (this.previousPoint.no === terminalPosition.nr_rekordu) {
          return false;
        }

        if (this.filters.reduceMarkersAmount) {
          const sz1 = Math.pow((terminalPosition.szerokosc - this.previousPoint.szer), 2);
          const dl1 = Math.pow((terminalPosition.dlugosc - this.previousPoint.dl), 2);
          const odl = Math.sqrt(sz1 + dl1);
          if (odl < 0.00008) {
            return false;
          }
        }

        const coords = LocationService.getCoordsMap(terminalPosition);

        const terminal = terminals.find(term => term.id === terminalPosition.id_terminal);

        const t = MapObjectsHelper.getIconForMarkerPng(terminalPosition, this.filters.markerColoringScheme);
        if (!this.pngCache[t]) {
          this.pngCache[t] = new HereService.H.map.Icon(t);
        }
        const pngIcon = this.pngCache[t];

        const object = new HereService.H.map.Marker(coords, {
          data: {
            ost_data: terminalPosition.czas,
            terminal,
            terminalMismatch: typeof terminal === 'undefined',
            ost_dane: terminalPosition
          },
          icon: pngIcon
        });
        object.addEventListener('tap', (evt) => {
          const pointData = evt.target.getData();

          const dialogConfig = new MatDialogConfig();
          dialogConfig.data = pointData;

          this.dialog.open(ObjectInformationComponent, dialogConfig);
        });
        HereService.map.addObject(object);
        this.previousPoint = {
          czas: +terminalPosition.czas,
          no: terminalPosition.nr_rekordu,
          dl: terminalPosition.dlugosc,
          szer: terminalPosition.szerokosc
        };
      });
    this.store.dispatch(UI.mapSearchRouteProcessingDone());
  }

  private putLines(positions: TerminalLocationShort[], terminals: Terminal[]) {
    const lines = [];
    let linesNo = -1;
    const linesPoints = [[]];
    [...positions]
      .sort((a, b) => {
        if (a.id_terminal < b.id_terminal) {
          return -1;
        }
        if (a.id_terminal > b.id_terminal) {
          return 1;
        }
        if (a.czas < b.czas) {
          return -1;
        }
        if (a.czas > b.czas) {
          return 1;
        }
        if (a.nr_rekordu < b.nr_rekordu) {
          return -1;
        }
        if (a.nr_rekordu > b.nr_rekordu) {
          return 1;
        }

        return 0;
      })
      .filter(point => Math.abs(+point.czas_zapisu - +point.czas) / 1000 < this.trackTimeIntervalSeconds)
      .forEach((terminalPosition, idx) => {
        if (idx === 0) {
          lines.push(new HereService.H.geo.LineString());
          linesNo++;
          this.previousPoint.czas = +terminalPosition.czas;
          this.previousPoint.no = -1;
        }

        if (this.previousPoint.no !== terminalPosition.nr_rekordu) {

          const pointInterval = Math.abs((this.previousPoint.czas - +terminalPosition.czas) / 1000);
          if (pointInterval > this.trackTimeIntervalSeconds) {
            lines.push(new HereService.H.geo.LineString());
            linesNo++;
            linesPoints[linesNo] = [];
          }

          lines[linesNo].pushPoint( LocationService.getCoordsMap(terminalPosition));
          linesPoints[linesNo].push(terminalPosition);
          this.previousPoint.czas = +terminalPosition.czas;
        }
        this.previousPoint.no = terminalPosition.nr_rekordu;

        return terminalPosition;
      });

    let n = 0;
    lines
      .forEach((lineString, i) => {
        if (lineString.getPointCount() < 5) {
          return;
        }
        this.store.dispatch(UI.mapSearchRouteProcessing());
        n++;
        setTimeout(() => {
          const color = MapObjectsHelper.getLineColor(i);
          const pierwszy = linesPoints[i][0];
          const ostatni = linesPoints[i][linesPoints[i].length - 1];

          const terminal = terminals.find(term => term.id === pierwszy.id_terminal);
          const object = new HereService.H.map.Polyline(
            lineString, {
              style: {lineWidth: 8, strokeColor: color[0], fillColor: color[1]},
              arrows: true,
              data: {pierwszy, ostatni, terminal, il_ramek: linesPoints[i].length}
            }
          );

          object.addEventListener('tap', (evt) => {
            const pointData = evt.target.getData();

            const dialogConfig = new MatDialogConfig();
            dialogConfig.data = pointData;

            this.dialog.open(TrackInformationComponent, dialogConfig);
          });
          HereService.map.addObject(object);
          this.store.dispatch(UI.mapSearchRouteProcessingDone());
        }, (n - 1) * 50);
      });
  }

}
