import { Component, OnInit } from '@angular/core';
import { NewAppUser } from '../../../../models/authentication';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  newuser = new NewAppUser();

  regForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]]
  });

  constructor(private fb: FormBuilder) {
  }

  ngOnInit() {

  }

  registerAction(e) {
    e.preventDefault();
  }

}
