<form class="form-horizontal" role="form">
  <div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-6">
      <h2>Reseting...</h2>
      <hr>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-6">
      <div class="form-group has-danger">
        <label class="sr-only">Login</label>
        <div class="input-group mb-2 mr-sm-2 mb-sm-0">
          <div class="input-group-addon" style="width: 2.6rem"><i class="fa fa-at"></i></div>
          <input type="text" class="form-control" name="login" [(ngModel)]="resetpwd.login" placeholder="User login"
            required autofocus>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-6">
      <div class="form-group has-danger">
        <label class="sr-only">Phone</label>
        <div class="input-group mb-2 mr-sm-2 mb-sm-0">
          <div class="input-group-addon" style="width: 2.6rem"><i class="fa fa-at"></i></div>
          <input type="text" class="form-control" name="phone" [(ngModel)]="resetpwd.phone" placeholder="Phone no."
            required autofocus>
        </div>
      </div>
    </div>
  </div>
  <div class="row" style="padding-top: 1rem">
    <div class="col-md-3"></div>
    <div class="col-md-6">
      <button type="submit" class="btn btn-danger" (click)="resetPwdAction($event)">
        <i class="fa fa-sign-in"></i>
        Reset
      </button>
    </div>
  </div>
</form>
