import { TicketStatus } from './enum';
import { Ticket } from '../modules/tickets/interfaces';

export class TransportTicket {

  static isTicketFinished(ticket: Ticket): boolean {
    return ticket?.statusT.id === TicketStatus.FINISHED;
  }

  static isTicketAssigned(ticket: Ticket): boolean {
    return ticket?.statusT.id === TicketStatus.ASSIGNED;
  }

  static isTicketNew(ticket: Ticket): boolean {
    return ticket?.statusT.id === TicketStatus.NEW;
  }

  static isTicketActive(ticket: Ticket): boolean {
    return TransportTicket.isTicketNew(ticket) || TransportTicket.isTicketAssigned(ticket);
  }
}
