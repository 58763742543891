import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, delay, exhaustMap, filter, map, switchMap, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';

import * as GENERAL from './general.actions';
import { loadCompanyListRequest, loadDictionaryData, updateCompanyRequest } from './general.actions';
import * as MAP from './map.actions';
import * as VEH from './vehicle.actions';
import * as fromRoot from '../app.reducer';
import * as TERMINAL from './terminal.actions';
import * as USER from './user.actions';
import * as FRAME from './frame.actions';
import * as TRANSSET from '../modules/transport-set/ngrx/transset.actions';
import * as TICKETS from '../modules/tickets/ngrx/tickets.actions';

import { CompanyService } from '../services';
import { Messages, Privs, ToastType, UserRoles } from '../helpers/enum';
import { showUserMessage } from './ui.actions';
import { HasPrivilegesDirective } from '../modules/access-control/directives/by-privilege/has-privileges.directive';
import { CommonDirective } from '../modules/access-control/directives/common.directive';
import { loadAvailableCmTerminalListRequest } from '../modules/transports/ngrx/transport.actions';

@Injectable()
export class GeneralEffects {
  loadDictionaryData$ = createEffect(() => this.actions.pipe(
    ofType(loadDictionaryData),
    tap(() => {
      this.store.dispatch(new VEH.LoadVehiclesRequest());
      this.store.dispatch(GENERAL.loadCompanyListRequest());
      this.store.dispatch(new VEH.LoadTrailsRequest());
      this.loadCmData();

      HasPrivilegesDirective.checkAndFire(
        [Privs.READ_TERMINALS],
        () => {
          this.store.dispatch(new TERMINAL.LoadTerminalsRequest());
          this.store.dispatch(new TERMINAL.LoadTerminalStatusesRequest());
        },
        TERMINAL.LoadTerminalsRequest.name);
    }),
    delay(350),
    tap(() => {
      this.store.dispatch(MAP.LoadTerminalsLastPositionRequest());
      this.store.dispatch(new USER.LoadCompanyUserListRequest());
    }),
    delay(100),
    tap(() => {
      HasPrivilegesDirective.checkAndFire(
        [Privs.READ_SHIPPING_POINT],
        () => this.store.dispatch(TRANSSET.loadShippingPointRequest()),
        TRANSSET.loadShippingPointRequest.type);

      HasPrivilegesDirective.checkAndFire(
        [Privs.MNG_TERMINAL_SOFT],
        () => this.store.dispatch(new TERMINAL.LoadTerminalSoftwareListRequest()),
        TERMINAL.LoadTerminalSoftwareListRequest.name);

      this.store.dispatch(FRAME.infoRequest());
      this.store.dispatch(TICKETS.loadEventsRequest());
    })
  ), {dispatch: false});
  updateCompany$ = createEffect(() => this.actions.pipe(
    ofType(updateCompanyRequest),
    exhaustMap(action => {
      return this.companies.updateCompanyInfo(action.company)
        .pipe(
          map(() => {
            this.store.dispatch(showUserMessage({
              message: {
                type: ToastType.SUCCESS,
                message: Messages.SAVING_DATA_SUCCESS
              }
            }));
            return loadCompanyListRequest();
          })
        );
    })
  ));
  getCompanyList$ = createEffect(() => this.actions.pipe(
    ofType(loadCompanyListRequest),
    switchMap(() => this.store.select(fromRoot.selectors.auth.getUserTokenDetails)),
    filter(r => r !== null),
    exhaustMap((token) => {
      return this.companies.getCompanies(token)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            if (err.status === 403) {
              return of([]);
            }
            throw err;
          }),
          map(companies => GENERAL.loadCompanyListSuccess({companies}))
        );
    })
  ));

  constructor(public actions: Actions,
              private store: Store<fromRoot.State>,
              private companies: CompanyService) {
  }

  private loadCmData() {
    if (CommonDirective.userTokenDetails.role_id !== UserRoles.CM) {
      return;
    }
    this.store.dispatch(loadAvailableCmTerminalListRequest());
  }
}
