import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MatTooltipModule } from '@angular/material/tooltip';

import { CustomMaterialModule } from '../material.modules';
import { DriverComponent, DriverCreateComponent, DriverListComponent } from './components';
import * as fromDriver from './ngrx/driver.reducer';
import { DriverEffects } from './ngrx/driver.effects';
import { DriverEditComponent } from './components/driver-edit/driver-edit.component';
import { SharedModule } from '../shared/shared.module';
import { DriverPinsComponent } from './components/driver-edit/driver-pins/driver-pins.component';

@NgModule({
  declarations: [DriverComponent, DriverCreateComponent, DriverListComponent, DriverEditComponent, DriverPinsComponent],
  imports: [
    CommonModule, CustomMaterialModule, FormsModule, RouterModule,

    StoreModule.forFeature(fromDriver.featureName, fromDriver.reducer),
    EffectsModule.forFeature([DriverEffects]), MatTooltipModule, SharedModule
  ],
  exports: [DriverComponent, DriverCreateComponent, DriverListComponent, DriverEditComponent]
})
export class DriversModule {
}
