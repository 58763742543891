import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { delay } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import * as fromRoot from '../../../../../app.reducer';
import * as Auth from '../../../../../ngrx/auth.actions';
import * as UI from '../../../../../ngrx/ui.actions';

@Component({
  selector: 'app-step1-login-form',
  templateUrl: './step1-login-form.component.html',
  styleUrls: ['./step1-login-form.component.scss']
})
export class Step1LoginFormComponent implements OnInit, OnDestroy {
  public login = '';
  public password = '';
  private subs = new Subscription();

  constructor(private store: Store<fromRoot.State>
  ) {
  }

  ngOnInit() {
    this.subs.add(
      this.store.select(fromRoot.selectors.auth.getAuthStep)
        .pipe(delay(50))
        .subscribe(res => {
          if (res.step === 3) {
            this.store.dispatch(new Auth.AuthenticationSuccess());
            this.store.dispatch(UI.setTopRightLinks({links: [{path: '/access/logout', title: 'Logout'}]}));
          }
        })
    );
  }

  loginAction(event) {
    event.preventDefault();
    this.store.dispatch(new Auth.LoginRequest({login: this.login, password: this.password}));
    return false;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
