import { createAction, props } from '@ngrx/store';
import { IndividualConfig } from 'ngx-toastr/toastr/toastr-config';

import { GenericNavItem, RightDrawer } from '../models/dto';
import { SideLinkActionData } from '../models/helpers';
import { ViewMode } from '../helpers/enum';

export const mapSearchRouteRequest = createAction('[UI] Map search route request');
export const mapSearchRouteRequestDone = createAction('[UI] Map search route request - done');
export const mapSearchRouteProcessing = createAction('[UI] Map search route processing');
export const mapSearchRouteProcessingDone = createAction('[UI] Map search route processing - done');

export const addLink = createAction(
  '[UI] Add Generic menu link',
  props<{ link: GenericNavItem }>()
);
export const removeLink = createAction(
  '[UI] REMOVE Generic menu link',
  props<{ linkHash: string }>()
);
export const addTopRightLink = createAction(
  '[UI] Add TOP right menu link',
  props<{ link: GenericNavItem }>()
);
export const addTopLeftLink = createAction(
  '[UI] Add TOP left menu link',
  props<{ link: GenericNavItem }>()
);
export const addSideLink = createAction(
  '[UI] Add SIDE menu link',
  props<{ link: GenericNavItem }>()
);
export const setLinks = createAction(
  '[UI] Set Generic menu links',
  props<{ links: GenericNavItem[] }>()
);

export const setTopRightLinks = createAction(
  '[UI] Set TOP right menu links',
  props<{ links: GenericNavItem[] }>()
);
export const setTopLinks = createAction(
  '[UI] Set TOP menu links',
  props<{ links: GenericNavItem[] }>()
);
export const setTopLeftLinks = createAction(
  '[UI] Set TOP left menu links',
  props<{ links: GenericNavItem[] }>()
);
export const setSideLinks = createAction(
  '[UI] Set SIDE menu links',
  props<{ links: GenericNavItem[] }>()
);

export const switchSideMenu = createAction(
  '[UI] Switch side menu visibility',
  props<{ visible?: boolean }>()
);
export const resetAllLinks = createAction('[UI] Reset all links');

export const userError = createAction(
  '[UI] Some error occurred',
  props<{ message: string }>()
);

export const sideLinkAction = createAction(
  '[UI] SIDE menu link ngrx action',
  props<{ linkAction: SideLinkActionData }>()
);

export const resizeWindow = createAction(
  '[UI] Resize window event',
  props<{ context: string }>()
);
export const showProgressBar = createAction('[UI] Show progress bar');
export const hideProgressBar = createAction('[UI] Hide progress bar');
export const showUserMessage = createAction(
  '[UI] Show user message',
  props<{ message: { type: string, message: string, title?: string, config?: Partial<IndividualConfig> } }>()
);

export const switchRightDrawer = createAction(
  '[UI] Switch right drawer',
  props<RightDrawer>()
);
export const switchLeftDrawer = createAction(
  '[UI] Switch left drawer',
  props<{ show: boolean }>()
);
export const setAlertViewMode = createAction(
  '[UI] Set alert view mode',
  props<{ mode: ViewMode }>()
);
export const setTransportViewMode = createAction(
  '[UI] Set transport view mode',
  props<{ mode: ViewMode }>()
);
export const setTicketViewMode = createAction(
  '[UI] Set ticket view mode',
  props<{ mode: ViewMode }>()
);
