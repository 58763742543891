import { Ticket } from '../../interfaces';
import { Filter } from './filter';

export class AllFiltersMustMatchFilter implements Filter {
  constructor(private readonly filters: Filter[]) {
  }

  isMatching(ticket: Ticket): boolean {
    return this.filters.every((filter) => {
      return filter.isMatching(ticket);
    });
  }
}
