import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TicketFilterStatus } from '../../../tickets/models/filters/ticket-status-filter';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../app.reducer';
import { setDriverReportsFilter } from '../../ngrx/driver-reports.actions';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-driver-report-list-header',
  templateUrl: './driver-report-list-header.component.html',
  styleUrls: ['./driver-report-list-header.component.scss']
})
export class DriverReportListHeaderComponent implements OnInit {
  public searchControl = new FormControl('');
  public statusFilterControl = new FormControl(TicketFilterStatus.NEW);
  public DEBOUNCE_TIME_MS = 350;

  public destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(private store: Store<fromRoot.State>) {
  }

  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(debounceTime(this.DEBOUNCE_TIME_MS), takeUntil(this.destroyed$))
      .subscribe((text) => {
        this.store.dispatch(setDriverReportsFilter({text: text, status: this.statusFilterControl.value}));
      });

    this.statusFilterControl.valueChanges
      .pipe(debounceTime(this.DEBOUNCE_TIME_MS), takeUntil(this.destroyed$))
      .subscribe((status) => {
        this.store.dispatch(setDriverReportsFilter({text: this.searchControl.value, status: status}));
      });
  }
}
