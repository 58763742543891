<div class="card text-center">
  <!--  <div class="card-header">-->
  <!--    <ul class="nav nav-tabs card-header-tabs">-->
  <!--      <li class="nav-item">-->
  <!--        <a class="nav-link" routerLinkActive="active" href="#" routerLink="login">Login</a>-->
  <!--      </li>-->
  <!--    </ul>-->
  <!--  </div>-->
  <div class="card-body">
    <router-outlet></router-outlet>
  </div>
</div>
