<div class="date-time-picker" [formGroup]="dateTimeForm">
  <p-calendar
    [showIcon]="true"
    [minDate]="minDate"
    [maxDate]="maxDate"
    formControlName="date"
    dateFormat="dd.mm.yy"
    readonlyInput="true"
    placeholder="Date">
  </p-calendar>

  <p-calendar
    [timeOnly]="true"
    formControlName="time"
    class="time-only"
    placeholder="Time">
  </p-calendar>
</div>
