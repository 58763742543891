import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, delay, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import * as Vehicles from './vehicle.actions';
import {
  AddTrailRequest,
  AddTruckRequest,
  EditTrailRequest,
  EditVehicleRequest,
  LoadVehicleRequest,
  RemoveTrailRequest, RemoveVehicleForceRequest,
  RemoveVehicleRequest
} from './vehicle.actions';
import * as UI from './ui.actions';
import * as fromRoot from '../app.reducer';
import { TrucksInventoryService } from '../services';
import { AppUserInfo } from '../models/authentication';
import { TerminalMngService } from '../services/terminal-mng.service';
import { Messages, ToastType } from '../helpers/enum';
import { MatDialog } from '@angular/material/dialog';
import { BaseYesNoConfig } from '../modules/shared/interfaces';
import { BaseYesNoDialogComponent } from '../modules/shared/dialogs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class VehicleEffects {


  loadTruckInfo$ = createEffect(() => this.actions.pipe(
    ofType<LoadVehicleRequest>(Vehicles.LOAD_VEHICLE_REQUEST),
    delay(5),
    switchMap((action) => {
        return this.veh.loadTruckById(action.payload)
          .pipe(
            map(vehicle => new Vehicles.LoadVehicleSuccess(vehicle)),
            catchError(() => {
              return of(UI.userError({message: Messages.READING_DATA_ERR}));
            })
          );
      }
    )
  ));

  loadTrucks$ = createEffect(() => this.actions.pipe(
    ofType(Vehicles.LOAD_VEHICLES_REQUEST),
    delay(5),
    switchMap(() => {
        return this.veh.loadTrucks()
          .pipe(
            map(vehicles => new Vehicles.LoadVehiclesSuccess(vehicles)),
            catchError(() => {
              return of(UI.userError({message: Messages.READING_DATA_ERR}));
            })
          );
      }
    )
  ));

  loadTrails$ = createEffect(() => this.actions.pipe(
    ofType(Vehicles.LOAD_TRAILS_REQUEST),
    delay(5),
    switchMap(() => {
        return this.veh.loadTrails()
          .pipe(
            map(trails => new Vehicles.LoadTrailsSuccess(trails)),
            catchError(() => {
              return of(UI.userError({message: Messages.READING_DATA_ERR}));
            })
          );
      }
    )
  ));

  loadTrailTypes$ = createEffect(() => this.actions.pipe(
    ofType(Vehicles.LOAD_TRAIL_TYPES_REQUEST),
    delay(5),
    switchMap(() => {
        return this.veh.getTrailTypes()
          .pipe(
            map(trailTypes => new Vehicles.LoadTrailTypesSuccess(trailTypes)),
            catchError(() => {
              return of(UI.userError({message: Messages.READING_DATA_ERR}));
            })
          );
      }
    )
  ));

  loadConnType$ = createEffect(() => this.actions.pipe(
    ofType(Vehicles.LOAD_CONN_TYPES_REQUEST),
    delay(5),
    switchMap(() => {
      return this.veh.getConnectionTypes()
        .pipe(
          map(connTypes => new Vehicles.LoadConnectionTypesSuccess(connTypes)),
          catchError(() => {
            return of(UI.userError({message: Messages.READING_DATA_ERR}));
          })
        );
    })
  ));

  loadTruckTypes$ = createEffect(() => this.actions.pipe(
    ofType(Vehicles.LOAD_TRUCK_TYPES_REQUEST),
    delay(5),
    switchMap(() => {
        return this.veh.getVehicleTypes()
          .pipe(
            map(vehicleTypes => new Vehicles.LoadTruckTypesSuccess(vehicleTypes)),
            catchError(() => {
              return of(UI.userError({message: Messages.READING_DATA_ERR}));
            })
          );
      }
    )
  ));

  loadUnavailReasons$ = createEffect(() => this.actions.pipe(
    ofType(Vehicles.LOAD_UNAVAIL_REASONS_REQUEST),
    delay(5),
    switchMap(() => {
        return this.veh.getUnavailReasons()
          .pipe(
            map(unavailReasons => new Vehicles.LoadUnavailReasonsSuccess(unavailReasons)),
            catchError(() => {
              return of(UI.userError({message: Messages.READING_DATA_ERR}));
            })
          );
      }
    )
  ));

  loadModels$ = createEffect(() => this.actions.pipe(
    ofType(Vehicles.LOAD_MODELS_REQUEST),
    delay(5),
    switchMap(() => {
        return this.veh.getVehicleModels()
          .pipe(
            map(models => new Vehicles.LoadModelsSuccess(models)),
            catchError(() => {
              return of(UI.userError({message: Messages.READING_DATA_ERR}));
            })
          );
      }
    )
  ));

  loadVendors$ = createEffect(() => this.actions.pipe(
    ofType(Vehicles.LOAD_VENDOR_REQUEST),
    delay(5),
    switchMap(() => {
        return this.veh.getVehicleVendors()
          .pipe(
            map(vendors => new Vehicles.LoadVendorsSuccess(vendors)),
            catchError(() => {
              return of(UI.userError({message: Messages.READING_DATA_ERR}));
            })
          );
      }
    )
  ));

  loadWorkStates$ = createEffect(() => this.actions.pipe(
    ofType(Vehicles.LOAD_WORK_STATES_REQUEST),
    delay(5),
    switchMap(() => {
        return this.veh.getWorkStates()
          .pipe(
            map(states => new Vehicles.LoadWorkStatesSuccess(states)),
            catchError(() => {
              return of(UI.userError({message: Messages.READING_DATA_ERR}));
            })
          );
      }
    )
  ));

  loadVehicleStates$ = createEffect(() => this.actions.pipe(
    ofType(Vehicles.LOAD_VEHICLE_STATES_REQUEST),
    delay(5),
    switchMap(() => {
        return this.veh.getVehicleStates()
          .pipe(
            map(states => new Vehicles.LoadVehicleStatesSuccess(states)),
            catchError(() => {
              return of(UI.userError({message: Messages.READING_DATA_ERR}));
            })
          );
      }
    )
  ));

  removeTrail$ = createEffect(() => this.actions.pipe(
    ofType<RemoveTrailRequest>(Vehicles.REMOVE_TRAIL_REQUEST),
    switchMap(action => {
      return this.veh.removeTrailForce(action.payload.trail)
        .pipe(
          map(() => {
            this.toastr.success('Trail removed');
            return new Vehicles.LoadTrailsRequest();
          }),
          catchError((errorResponse: HttpErrorResponse) => {
            switch(errorResponse.status) {
              case 452:
                return of(UI.userError({message: 'Trailer is assigned to transit set which is in use'}));

              default:
                return of(UI.userError({message: Messages.SAVING_DATA_ERR}));
            }
          })
        );
    })
  ));

  removeVehicle$ = createEffect(() => this.actions.pipe(
    ofType<RemoveVehicleRequest>(Vehicles.REMOVE_VEHICLE_REQUEST),
    switchMap(action => {
      return this.veh.removeVehicle(action.payload.vehicle)
        .pipe(
          map(() => {
            this.toastr.success('Vehicle removed');
            return new Vehicles.LoadVehiclesRequest();
          }),
          catchError((error: HttpErrorResponse) => {
            if (error.status > 450 && error.status <= 560) {
              const config: BaseYesNoConfig = {
                title: 'Truck deletion',
                content: `Truck is already in use, are you sure you want to delete this truck anyway?`,
                yesAction: () => {
                  this.store.dispatch(new Vehicles.RemoveVehicleForceRequest({id: action.payload.vehicle.id}));
                },
                yesLabel: 'Delete',
                yesColor: 'warn',
                noLabel: 'Cancel',
                noColor: 'primary',
                autoClosure: true,
              };
              this.dialog.open(BaseYesNoDialogComponent, {
                data: config,
                id: 'BaseYesNoDialogComponent-TruckRemovalForce',
                position: {top: '7%'}
              });
              return EMPTY;
            } else {
              return of(UI.userError({message: Messages.SAVING_DATA_ERR}));
            }
          })
        );
    })
  ));

  removeVehicleForce$ = createEffect(() => this.actions.pipe(
    ofType<RemoveVehicleForceRequest>(Vehicles.REMOVE_VEHICLE_FORCE_REQUEST),
    switchMap(action => {
      return this.veh.removeVehicleForce(action.payload.id)
        .pipe(
          map(() => {
            this.toastr.success('Vehicle removed');
            return new Vehicles.LoadVehiclesRequest();
          }),
          catchError(() => {
            return of(UI.userError({message: Messages.SAVING_DATA_ERR}));
          })
        );
    })
  ));

  updateTruck$ = createEffect(() => this.actions.pipe(
    ofType<EditVehicleRequest>(Vehicles.EDIT_VEHICLE_REQUEST),
    switchMap(action => {
      return this.veh.updateTruck(action.payload.record)
        .pipe(
          map(() => {
            if (action.payload.callback) {
              action.payload.callback();
            }
            return new Vehicles.LoadVehiclesRequest();
          }),
          catchError((errorResponse: HttpErrorResponse) => {
            return of(UI.userError({ message: errorResponse.error.error.message }));
          })
        );
    })
  ));

  updateTrail$ = createEffect(() => this.actions.pipe(
    ofType<EditTrailRequest>(Vehicles.EDIT_TRAIL_REQUEST),
    switchMap(action => {
      return this.veh.updateTrail(action.payload.trail)
        .pipe(
          map(() => {
            if (action.payload.callback) {
              action.payload.callback();
            }
            return new Vehicles.LoadTrailsRequest();
          }),
          catchError(() => {
            return of(UI.userError({message: Messages.SAVING_DATA_ERR}));
          })
        );
    })
  ));
  private appUserInfo: AppUserInfo;

  addNewTruck$ = createEffect(() => this.actions.pipe(
    ofType<AddTruckRequest>(Vehicles.ADD_TRUCK_REQUEST),
    delay(5),
    switchMap((action) => {
        const data = {...action.payload, id_firma: parseInt(this.appUserInfo.companyId, 10)};
        return this.veh.addNewTruck(data.truck)
          .pipe(
            tap(() => {
              this.store.dispatch(UI.showUserMessage({
                message: {
                  title: 'Truck addition', message: 'New truck has been created', type: ToastType.SUCCESS
                }
              }));
              this.router.navigateByUrl('/dictionaries/vehicles/menu/truck/list');
            }),
            map(() => new Vehicles.LoadVehiclesRequest()),
            catchError((errorResponse: HttpErrorResponse) => {
              return of(UI.userError({ message: errorResponse.error.error.message }));
            })
          );
      }
    ),
  ));

  addNewTrail$ = createEffect(() => this.actions.pipe(
    ofType<AddTrailRequest>(Vehicles.ADD_TRAIL_REQUEST),
    delay(5),
    switchMap((action) => {
        const payload = {...action.payload, ...{id_firma: parseInt(this.appUserInfo.companyId, 10)}};
        return this.veh.addNewTrail(payload.trailer)
          .pipe(
            map(() => {
              action.payload.callback();
              this.router.navigateByUrl('/dictionaries/vehicles/menu/trail/list');
              return new Vehicles.LoadTrailsRequest();
            }),
            catchError(() => {
              return of(UI.userError({message: Messages.SAVING_DATA_ERR}));
            })
          );
      }
    ),
  ));

  loadTuckTablet$ = createEffect(() => this.actions.pipe(
    ofType<Vehicles.TruckTabletLocationRequest>(Vehicles.TRUCK_TABLET_LOC_REQUEST),
    exhaustMap(({payload}) => {
      return this.veh.getTruckTablet(payload.firstDriverId, payload.secondDriverId)
        .pipe(
          map((result) => new Vehicles.TruckTabletLocationSuccess({data: result})),
          catchError(error => {
            return of(UI.userError({message: Messages.READING_DATA_ERR}));
          })
        );
    })
  ));

  constructor(private actions: Actions, private veh: TrucksInventoryService, private terms: TerminalMngService,
              private store: Store<fromRoot.State>, private router: Router, private toastr: ToastrService, private dialog: MatDialog) {
    this.store.select(fromRoot.selectors.auth.getUserInfo).subscribe(info => this.appUserInfo = info);
  }
}
