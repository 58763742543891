import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { map, tap } from 'rxjs/operators';

import * as fromEventsConfig from '../../../ngrx/event-config.reducer';
import * as UI from '../../../../../ngrx/ui.actions';

import { IRodzajAlarmu } from '../../../../alerts/interfaces';
import { AlertTypeFormComponent } from '../alert-type-form/alert-type-form.component';
import { RightDrawerService } from '../../../../shared/services';
import { GenericNavItem } from '../../../../../models/dto';
import { Strings } from '../../../../../helpers';

@Component({
  selector: 'app-alert-type-list',
  templateUrl: './alert-type-list.component.html',
  styleUrls: ['./alert-type-list.component.scss'],
})
export class AlertTypeListComponent implements OnInit, OnDestroy {
  dataSource = new MatTableDataSource();
  subs = new Subscription;
  alertTypes$: Observable<IRodzajAlarmu[]>;
  alertTypesList = [];

  alertTypeLink: GenericNavItem = {
    callbackAction: () => {
      this.rightDrawer.open(AlertTypeFormComponent);
    },
    title: 'New alert type', menuType: 'SIDE', path: '',
    position: null, itemType: 'callback', icon: 'add_circle_outline'
  };

  constructor(
    private store: Store<fromEventsConfig.State>, private rightDrawer: RightDrawerService
  ) {
  }

  ngOnInit(): void {
    this.alertTypes$ = this.store.select(fromEventsConfig.getAlertTypes).pipe(
      map((alertTypes: IRodzajAlarmu[]) => {
        this.alertTypesList = [...alertTypes].sort((a, b) => a.id > b.id ? 1 : -1);
        return this.alertTypesList;
      }),
      tap(alertTypes => {
        return this.dataSource.data = alertTypes;
      })
    );

    this.store.dispatch(UI.addSideLink({link: this.alertTypeLink}));
    this.subs.add(this.alertTypes$.subscribe(_ => {
    }));
  }

  ngOnDestroy() {
    this.rightDrawer.close();
    this.subs.unsubscribe();

    this.store.dispatch(UI.removeLink({linkHash: Strings.getObjectHash(this.alertTypeLink)}));
  }
}
