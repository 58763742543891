import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromTransport from '../../ngrx/transport.reducer';
import * as TRANSPORT from '../../ngrx/transport.actions';

@Component({
  selector: 'app-favourite-transports',
  templateUrl: './favourite-transits.component.html',
  styleUrls: ['./favourite-transits.component.scss']
})
export class FavouriteTransitsComponent {

  constructor(private store: Store<fromTransport.State>) {
    this.store.dispatch(TRANSPORT.loadAllTransitsRequest());
  }
}
