import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthStep } from '../../../../models/dto/user';
import * as fromRoot from 'src/app/app.reducer';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public currentLoginStep$ = new Observable<AuthStep>();

  constructor(private store: Store<fromRoot.State>, private router: Router) {
    this.store.select(fromRoot.selectors.auth.getAuthIsAuthenticated)
      .pipe(
        take(1)
      )
      .subscribe(isAuthenticated => {
        if (isAuthenticated) {
          this.router.navigate(['/home']);
        }
      });
  }

  ngOnInit() {
    this.currentLoginStep$ = this.store.select(fromRoot.selectors.auth.getAuthStep);
  }

}
