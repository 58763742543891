<ng-container *ngIf="!isPageReady">
  <div style="margin: 50px auto; width: 250px; text-align: center;">
    <span>Loading data, please wait</span>
    <br/>
    <br/>
    <mat-spinner style="margin: 25px auto;"></mat-spinner>
  </div>
</ng-container>
<div class="new-transit-container" *ngIf="isPageReady">
  <form *ngIf="form" [formGroup]="form" fxLayout="column" fxLayoutGap="8px">
    <mat-horizontal-stepper [linear]="isFormLinear" [selectedIndex]="currentStep" (selectionChange)="stepSelectionChanged($event)">

      <mat-step [stepControl]="form.stepOne" formGroupName="stepOne" [completed]="false">
        <ng-template matStepLabel>
          <p class="heading">Truck & Trailer</p>
        </ng-template>

        <div class="step-content" fxFlex fxLayout="column" fxLayoutGap="16px">
          <mat-form-field appearance="outline">
            <mat-label>Name...</mat-label>
            <input matInput formControlName="name" [disabled]="editedTransit?.zakonczony">
          </mat-form-field>

          <div formGroupName="departureAndArrival" fxLayout="column" fxLayoutGap="8px">
            <div fxLayoutAlign="center center" fxFlex fxLayout="column">
              <p class="label" style="width: 500px;">Departure</p>
              <div fxLayout="row" fxLayoutGap="32px" fxLayoutAlign="center start">
                <div>
                  <p-calendar [showIcon]="true" [minDate]="today" formControlName="fromDate" dateFormat="dd.mm.yy"
                              readonlyInput="true" placeholder="Date"></p-calendar>
                </div>
                <div>
                  <p-calendar class="time-only" [timeOnly]="true" formControlName="fromTime"
                              placeholder="Time"></p-calendar>
                </div>
              </div>
            </div>

            <div fxLayoutAlign="center center" fxFlex fxLayout="column">
              <p class="label" style="width: 500px;">Arrival</p>
              <div fxLayout="row" fxLayoutGap="32px">
                <div>
                  <p-calendar [showIcon]="true" [disabled]="!form.isDepartureValid"
                              [minDate]="setMinimumClosingDate('stepOne', 'departureAndArrival')"
                              formControlName="toDate"
                              dateFormat="dd.mm.yy" readonlyInput="true" placeholder="Date"></p-calendar>
                </div>
                <div>
                  <p-calendar class="time-only" [timeOnly]="true" formControlName="toTime" placeholder="Time"
                              [disabled]="!form.isDepartureValid"
                              [ngClass]="{'invalid-time': closingTimeIsWrong('stepOne', 'departureAndArrival')}"></p-calendar>
                </div>
              </div>
            </div>
          </div>

          <div>
            <mat-accordion>
              <div>
                <button mat-raised-button color="primary"
                        (click)="openTransportSetPanel()" [disabled]="!form.departureAndArrival.valid"
                        *ngIf="!(readyTransportSetsPanelIsOpened$ | async)">{{ selectSetButtonContent }}</button>
                <button mat-raised-button color="warn"
                        (click)="closeTransportSetPanel()"
                        *ngIf="readyTransportSetsPanelIsOpened$ | async">Cancel
                </button>
              </div>
              <mat-expansion-panel [expanded]="readyTransportSetsPanelIsOpened$ | async" #panel>
                <app-ready-transport-sets></app-ready-transport-sets>
              </mat-expansion-panel>
            </mat-accordion>
          </div>

          <mat-form-field *ngIf="form.transsetObj.valid" appearance="outline">
            <mat-label>Transport set...</mat-label>
            <input matInput formControlName="set" class="mat-body-2 pointer" [readonly]="true"
                   (click)="showChosenSetDetails()">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Transit notes...</mat-label>
            <textarea matInput class="mat-body-2" formControlName="notes"></textarea>
          </mat-form-field>

          <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="end">
            <button mat-raised-button color="primary" (click)="saveTransport()" *ngIf="!editedTransit?.zakonczony">
              <span [innerText]="form.transportId ? 'Update transport' : 'Create transport'"></span>
            </button>
            <button mat-stroked-button color="accent" matStepperNext
                    class="next-page-btn"
                    (click)="form.markStepAsTouched('stepOne')"
                    [disabled]="!form.transsetObj.valid || !form.stepOne.valid || !form.transportId || form.controls['stepOne'].dirty"
                    fxLayoutAlign="space-around">
              <span>Next</span>
              <mat-icon>navigate_next</mat-icon>
            </button>
          </div>
        </div>

      </mat-step>

      <mat-step [stepControl]="form.stepTwo" formGroupName="stepTwo" [completed]="false">
        <ng-template matStepLabel>
          <p class="heading" (click)="form.markStepAsTouched('stepOne')">Drivers</p>
        </ng-template>

        <app-transit-drivers-form [form]="form" [transport]="editedTransit">
        </app-transit-drivers-form>
      </mat-step>

      <mat-step [stepControl]="form.stepThree" formGroupName="stepThree" [completed]="false"
                *appHasPrivileges="[ePrivs.EDIT_ROUTES_TRACK]">
        <ng-template matStepLabel>
          <p class="heading" (click)="form.markStepAsTouched('stepOne')">Route</p>
        </ng-template>
        <div fxFlex fxLayout="column" fxLayoutGap="16px" class="step-content">
          <div formArrayName="routeSections"
               *ngFor="let routeSection of form.routeSections['controls']; let i = index">
            <mat-card [formGroupName]="i" fxLayout="column" fxLayoutGap="8px">
              <div *ngIf="routeSection?.value.type === 'saved' || routeSection?.value.type === 'edit'"
                   fxFlex fxLayout="row" fxLayoutGap="8px">
                <div fxLayout="column" fxFlex="66%">
                  <div fxFlex fxLayout="row">
                    <span class="label" fxFlex="25%">Route name</span>
                    <span>{{routeSection.value?.assignedRoute.nazwa}}</span>
                  </div>
                  <div fxFlex fxLayout="row">
                    <span class="label" fxFlex="25%">Distance</span>
                    <span>{{routeSection.value?.assignedRoute._trasa.dlugosc_trasy}} km</span>
                  </div>
                </div>
                <span fxFlex></span>
                <div>
                  <button *ngIf="!editedTransit?.zakonczony"
                          mat-raised-button [color]="routeSection?.value.type === 'saved' ? 'primary' : 'warn'"
                          (click)="editRouteSection(i)"
                          [innerText]="routeSection?.value.type === 'saved' ? 'Change' : 'Cancel'"></button>
                </div>
              </div>
              <div fxLayout="column" fxLayoutGap="4px"
                   *ngIf="routeSection?.value.type === 'new' || routeSection?.value.type === 'edit'">
                <div>
                  <div fxLayout="row" fxLayoutAlign="space-between">
                    <button mat-button color="primary"
                            (click)="createNewRouteSectionOption(i)">Create new route
                    </button>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
                  <mat-form-field appearance="outline" fxFlex="50%">
                    <mat-label>Select saved route...</mat-label>
                    <input type="text" matInput formControlName="savedRoute"
                           [matAutocomplete]="autoSavedRoute"
                           class="mat-body-2">
                    <mat-autocomplete autoActiveFirstOption #autoSavedRoute="matAutocomplete"
                                      [displayWith]="savedRouteDisplay">
                      <mat-option *ngFor="let option of filteredRouteSections" [value]="option"
                                  class="mat-body-2">
                        {{option.nazwa}}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-icon matSuffix>search</mat-icon>
                  </mat-form-field>

                  <span *ngIf="!(twoRouteSectionsAreContinuous$(i) | async)">Current and previous route sections do not overlap...</span>
                  <div>
                    <mat-checkbox color="primary" formControlName="onlyPrivate"
                                  (change)="setFilter($event.checked, i)">
                      Private only
                    </mat-checkbox>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="end"
                     fxLayoutGap="8px">
                  <button mat-raised-button color="primary"
                          (click)="saveTemplateMadeRouteSection(i, form.routeSections.value[i])"
                          [disabled]="!(twoRouteSectionsAreContinuous$(i) | async) || !form.routeSections.value[i]?.savedRoute?.id"
                          [innerText]="form.routeSections.value[i]?.id ? 'Update' : 'Save'">
                  </button>
                  <button mat-raised-button color="accent" (click)="removeRouteSection(i)">
                    Remove
                  </button>
                </div>
              </div>
            </mat-card>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="16px">
            <div fxLayout="row" fxLayoutGap="16px">
              <button mat-raised-button color="primary" [disabled]="editedTransit?.zakonczony" (click)="form.addRouteSection()">
                Add route
              </button>
              <button mat-stroked-button color="primary" (click)="showOnMap(eMapViewRoute.TRANSPORT_ROUTE)"
                      [disabled]="!form.stepThree.value.routeSections[0]?.assignedRoute?.id">
                <span class="material-icons-outlined">
                  map
                </span>
                Show on map
              </button>
            </div>

            <div *ngIf="form.routeSections.length > 1">
              <button mat-button color="primary">Verify route continuity</button>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between">
            <button mat-button color="primary" matStepperPrevious>
              <mat-icon>navigate_before</mat-icon>
              <span>Back</span>
            </button>
            <button mat-stroked-button color="accent" matStepperNext>
              <span>Next</span>
              <mat-icon>navigate_next</mat-icon>
            </button>
          </div>
        </div>
      </mat-step>

      <mat-step [stepControl]="form.stepFour" formGroupName="stepFour" [completed]="false"
                *appHasPrivileges="[ePrivs.MNG_TRANSSET_SAFETY]">
        <ng-template matStepLabel>
          <p class="heading" (click)="form.markStepAsTouched('stepOne')">Security</p>
        </ng-template>
        <div fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="16px" *ngIf="editedTransit">
          <div fxLayout="column" fxLayoutGap="8px" class="security-container">
            <mat-card>
              <mat-card-title fxLayoutAlign="space-between">
                <span>Parking lots</span>
              </mat-card-title>
              <mat-card-content>
                <div *ngFor="let transportParking of editedTransit.parkingPrzejazdList" class="assigned-parking"
                     fxLayout="row">
                  <p class="parking-no">
                    <span>Parking no: {{ transportParking.parking.id }}</span>
                  </p>
                  <p class="type">
                    {{ transportParking.parking.nazwa }} ({{ transportParking.parking.kraj }}
                    - {{ transportParking.parking.region }})
                  </p>

                </div>
              </mat-card-content>
              <mat-card-actions fxLayoutAlign="end">
                <button mat-raised-button color="primary" class="mat-button" type="button"
                        [disabled]="editedTransit?.zakonczony"
                        (click)="showOnMap(eMapViewRoute.PARKING)"
                        style="width: fit-content">
                  Transport parking lots
                </button>
              </mat-card-actions>
            </mat-card>
            <mat-card fxLayout="column" fxLayoutGap="16px" class="monitoring-card">
              <mat-card-title>
                <span>Security details</span>
              </mat-card-title>

              <mat-card-content fxFlex fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="16px"
                                class="monitoring-content">
                <div fxFlex fxLayout="column" fxLayoutGap="16px">
                  <mat-checkbox color="primary" [formControl]="form.getStepFourControl('monitored')">Monitored transport</mat-checkbox>

                  <app-select  label="Monitoring center" [style.width]="'fit-content'"
                  [control]="form.getStepFourControl('centrum')" [lov]="cmLov"></app-select>

                  <app-select label="Goods owner" [style.width]="'fit-content'"
                  [control]="form.getStepFourControl('wlascicielTowaru')" [lov]="cargoOwnerLov"></app-select>

                  <mat-checkbox color="primary" formControlName="syrena">Trailer's alarm siren</mat-checkbox>
                  <mat-checkbox color="primary" formControlName="coldChain">Cold chain</mat-checkbox>
                </div>

                <div fxFlex fxLayout="column" fxLayoutGap="16px">
                  <mat-checkbox color="primary" formControlName="geofencing">Geofencing</mat-checkbox>
                  <app-input label="Meters" [control]="form.getStepFourControl('geofMetry')" [style.width]="'fit-content'"
                             [readonly]="!form.getStepFourControl('geofencing').value"
                  ></app-input>
                </div>
                <div>

                  <app-select label="Choose owner" [style.width]="'fit-content'"
                  [control]="form.getStepFourControl('dyspozytor')" [lov]="dispatchersLov"></app-select>

                </div>
              </mat-card-content>
              <mat-card-actions fxLayoutAlign="end">
                <button mat-raised-button color="primary" class="mat-button" type="button"
                        (click)="updateTransportSecurity()"
                        [disabled]="(!form.stepFour.dirty || form.stepFour.invalid)"
                        style="width: fit-content">
                  Update monitoring
                </button>
              </mat-card-actions>
            </mat-card>
          </div>
          <div fxFlex fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="space-between">
            <button mat-button color="primary" matStepperPrevious>
              <mat-icon>navigate_before</mat-icon>
              <span>Back</span>
            </button>
            <button mat-stroked-button color="accent" matStepperNext>
              <span>Next</span>
              <mat-icon>navigate_next</mat-icon>
            </button>
          </div>
        </div>
      </mat-step>

      <mat-step [stepControl]="form.stepFour" [completed]="false"
                *appHasPrivileges="[ePrivs.READ_TRANSIT]">
        <ng-template matStepLabel>
          <p class="heading">Overview</p>
        </ng-template>
        <ng-template matStepContent>
          <div class="step-content">
            <app-transport-overview
              [transport]="editedTransit"
            ></app-transport-overview>
          </div>
        </ng-template>
      </mat-step>
    </mat-horizontal-stepper>
  </form>
</div>
