<div class="tile-container {{type}}" *ngIf="event"
     fxLayout="row" fxLayoutAlign="start start">
  <div class="notifications-column">
    <div class="notifications" fxLayout="row" fxLayoutAlign="center center">
      <mat-icon class="notifications-icon material-icons-outlined {{clickOption}}"
                [matTooltip]="tooltip ? tooltip : ''" [innerHTML]="getIconType()">
      </mat-icon>
    </div>
    <mat-icon *ngIf="shouldShowOnMap()" (click)="centerToEvent()"
              class="goto-map material-icons-outlined" [matTooltip]="'Go to...'">
      gps_fixed
    </mat-icon>
  </div>

  <div class="event-info" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="0.25rem" *ngIf="type === 'vehicle-event'">
    <p class="id">Event no.: {{event.id}} / {{event.idRekord}}</p>
    <p class="name" [innerHTML]="getInfo('name')"></p>
    <p class="type" [innerHTML]="getInfo('type')"></p>
    <span class="date" [matTooltip]="event.czas | date : dt.PIPE_DATETIME_LONGER_LABEL">
      Date: <span>{{event.czas | date : dt.PIPE_DATETIME_LABEL}}</span>
    </span>
    <span class="date">{{event.typZd?.typ}}</span>
  </div>

  <div class="event-info" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="0.25rem" *ngIf="type === 'driver-event'">
    <p class="id">Event no.: {{event.id}}</p>
    <p class="name" [innerHTML]="getInfo('name')"></p>
    <p class="type" [innerHTML]="getInfo('type')"></p>
    <span class="date" [matTooltip]="event.czas | date : dt.PIPE_DATETIME_LONGER_LABEL">
      Date: <span>{{event.czas | date : dt.PIPE_DATETIME_LABEL}}</span>
    </span>
    <span class="date">{{event.typZd?.typ}}</span>
  </div>

</div>
