import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

const MAX_STRENGTH = 5;

@Component({
  selector: 'sat-signal-strength',
  templateUrl: './sat-signal-strength.component.html',
  styleUrls: ['./sat-signal-strength.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SatSignalStrengthComponent {
  @Input() signalStrength: number = 0;
  bars: Array<number> = new Array(MAX_STRENGTH);
  constructor() {}
}
