import { Filter } from './filter';
import { IAlarmTransportowyUproszczony } from '../../interfaces';

export class FilterAlerts {
  private filters = new Map<string, Filter>();

  set(filter: Filter): void {
    this.filters.set(filter.name, filter);
  }

  applyFilters(alerts: IAlarmTransportowyUproszczony[]): IAlarmTransportowyUproszczony[] {
    let filteredAlerts = alerts;

    this.filters.forEach((filter) => {
      filteredAlerts = filteredAlerts.filter((alarm) => filter.isMatching(alarm));
    });

    return filteredAlerts;
  }

  resetFilter(name: string): void {
    if (this.filters.has(name)) {
      this.filters.delete(name);
    }
  }
}
