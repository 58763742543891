<ng-container *ngIf="ticket">
  <h5>Alerts in ticket <em>({{pending.length}} / {{ticket.alarmy.length}})</em></h5>
  <cdk-virtual-scroll-viewport itemSize="88" class="virtual-scroll" minBufferPx="190" maxBufferPx="380">
    <div fxLayout="column" fxLayoutGap="8px">
      <div *cdkVirtualFor="let alert of ticket.alarmy | sortTicketAlerts" fxLayout="column" fxLayoutGap="2px"
           class="alert" [class.closed]="!(alert | isTransportAlertPending)">
        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
          <ng-container *ngIf="{
                            isSelectable: selectable$ | async,
                            selected: selectedAlerts$ | async
                            } as alertSelectDetails"
          >
            <mat-checkbox *ngIf="alertSelectDetails.isSelectable && alert | isTransportAlertPending"
                          (change)="checkAlert(alert.id, $event)"
                          [checked]="alertSelectDetails.selected.includes(alert.id)"
            ></mat-checkbox>
          </ng-container>
          <app-text-field [matTooltip]="getTimeTooltip(alert)"
                          label="Time" [value]="alert.czasRozpoczecia | date : df.PIPE_DATETIME_LABEL">
          </app-text-field>
          <app-text-field
            label="Id" [value]="alert.id">
          </app-text-field>
          <span fxFlex></span>
          <button mat-icon-button (click)="alertInfo(alert.id)">
            <mat-icon class="material-icons-outlined">info</mat-icon>
          </button>
        </div>
        <app-text-field label="Name" [value]="alert.opis"></app-text-field>
      </div>
    </div>
  </cdk-virtual-scroll-viewport>
</ng-container>
