import { Action } from '@ngrx/store';
import { IRodzajAlarmu } from '../../alerts/interfaces/rodzaj-alarmu';

export enum ActionNames {
  LOAD_ALERT_TYPES_REQUEST = '[EVENT CONFIG] load alert types - request',
  LOAD_ALERT_TYPES_SUCCESS = '[EVENT CONFIG] load alert types - success',
  ADD_ALERT_TYPE_REQUEST = '[EVENT CONFIG] add alert type - request',
  ADD_ALERT_TYPE_SUCCESS = '[EVENT CONFIG] add alert type - success',
  DELETE_ALERT_TYPE_REQUEST = '[EVENT CONFIG] delete alert type - request',
  DELETE_ALERT_TYPE_SUCCESS = '[EVENT CONFIG] delete alert type - success',
  UPDATE_ALERT_TYPE_REQUEST = '[EVENT CONFIG] upadate alert type - request',
  UPDATE_ALERT_TYPE_SUCCESS = '[EVENT CONFIG] upadate alert type - success',
  OPEN_ALERT_TYPE_EDITION = '[EVENT CONFIG] open alert type edition',
  CLOSE_ALERT_TYPE_EDITION = '[EVENT CONFIG] close alert type edition',
}

export class LoadAlertTypesRequest implements Action {
  readonly type = ActionNames.LOAD_ALERT_TYPES_REQUEST;
}

export class LoadAlertTypesSuccess implements Action {
  readonly type = ActionNames.LOAD_ALERT_TYPES_SUCCESS;

  constructor(public payload: IRodzajAlarmu[]) {
  }
}

export class AddAlertTypeRequest implements Action {
  readonly type = ActionNames.ADD_ALERT_TYPE_REQUEST;

  constructor(public payload: IRodzajAlarmu) {
  }
}

export class AddAlertTypeSuccess implements Action {
  readonly type = ActionNames.ADD_ALERT_TYPE_SUCCESS;

  constructor(public payload: IRodzajAlarmu) {
  }
}

export class DeleteAlertTypeRequest implements Action {
  readonly type = ActionNames.DELETE_ALERT_TYPE_REQUEST;

  constructor(public payload: number) {
  }
}

export class DeleteAlertTypeSuccess implements Action {
  readonly type = ActionNames.DELETE_ALERT_TYPE_SUCCESS;

  constructor(public payload: number) {
  }
}

export class UpdateAlertTypeRequest implements Action {
  readonly type = ActionNames.UPDATE_ALERT_TYPE_REQUEST;

  constructor(public payload: IRodzajAlarmu) {
  }
}

export class UpdateAlertTypeSuccess implements Action {
  readonly type = ActionNames.UPDATE_ALERT_TYPE_SUCCESS;

  constructor(public payload: IRodzajAlarmu) {
  }
}

export class OpenAlertTypeEdition implements Action {
  readonly type = ActionNames.OPEN_ALERT_TYPE_EDITION;

  constructor(public payload: IRodzajAlarmu) {
  }
}

export class CloseAlertTypeEdition implements Action {
  readonly type = ActionNames.CLOSE_ALERT_TYPE_EDITION;
}

export type EventConfigActions =
  LoadAlertTypesRequest |
  LoadAlertTypesSuccess |
  AddAlertTypeRequest |
  AddAlertTypeSuccess |
  DeleteAlertTypeRequest |
  DeleteAlertTypeSuccess |
  UpdateAlertTypeRequest |
  UpdateAlertTypeSuccess |
  OpenAlertTypeEdition |
  CloseAlertTypeEdition;
