import { Pipe, PipeTransform } from '@angular/core';
import { Rygiel } from '../../../models/dto/terminale';
import { Locker } from '../../../models/dto/vehicle';
import { LockerHelper, LockerInfo } from '../helpers';

@Pipe({
  name: 'getLockerStatus'
})
export class GetLockerStatusPipe implements PipeTransform {

  transform(locker?: Rygiel | Locker): LockerInfo {
    return LockerHelper.getLockerStatus(locker);
  }

}
