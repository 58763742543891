import { ColorIndex } from '../../../../shared/interfaces/color-index';
import { TerminalLocationShort } from '../../../../../models/helpers';
import { IInfo } from '../../../../../models/frame';
import { LocationHelper } from '../../../../../helpers';

export class LastReportColor implements ColorIndex {
  constructor(private value: TerminalLocationShort | IInfo) {
  }

  getColorIndex(): number {
    const opoznienie = Math.abs(this.getTime() - this.getSavingTime()) / 1000;
    switch (true) {
      case opoznienie > 600:
        return 0;

      case opoznienie > 300:
        return 1;

      case opoznienie > 120:
        return 2;

      case opoznienie > 60:
        return 3;

      case opoznienie > 10:
        return 4;

      default:
        return 5;
    }
  }

  private getTime(): number {
    return new Date().getTime();
  }

  private getSavingTime(): number {
    if (LocationHelper.isTerminalLocationShort(this.value)) {
      return this.value.czas_zapisu;
    }

    return Date.parse(this.value.czasZapisu);
  }
}
