import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import * as fromUser from '../ngrx/user.reducer';
import { LogowanieUzytkownika, Uzytkownik } from '../models/dto/user';
import { RoleUzytkownikow, UprawnienieUzytkownika } from '../models/authentication';

interface Selectors {
  getCompanyUsers: MemoizedSelector<any, Uzytkownik[]>;
  getUserLoginAttempts: MemoizedSelector<any, LogowanieUzytkownika[]>;
  getSelectedUserInfo: MemoizedSelector<any, Uzytkownik>;
  getUsersPrivileges: MemoizedSelector<any, UprawnienieUzytkownika[]>;
  getCompanyUsersRoles: MemoizedSelector<any, RoleUzytkownikow[]>;
  getCompanyRoles: MemoizedSelector<any, RoleUzytkownikow[]>;

  getUserPrivilegesByUserId: (userId: number) => MemoizedSelector<any, UprawnienieUzytkownika[]>;
  getUserPrivilegesByUserLogin: (userLogin: string) => MemoizedSelector<any, UprawnienieUzytkownika[]>;
  getCompanyUsersByRole: (roleId: number) => MemoizedSelector<any, Uzytkownik[]>;
  getUserByUsername: (username: string) => MemoizedSelector<any, Uzytkownik>;
}

export class UserSelectors {
  static _state = createFeatureSelector<fromUser.State>('user');

  static _selectors: Selectors;

  static getSelectors() {
    this._selectors = {
      getCompanyUsers: createSelector(this._state, fromUser.getCompanyUsers),
      getUserLoginAttempts: createSelector(this._state, fromUser.getUserLoginAttempts),
      getSelectedUserInfo: createSelector(this._state, fromUser.getSelectedUserInfo),
      getUsersPrivileges: createSelector(this._state, fromUser.getUsersPrivileges),
      getCompanyUsersRoles: null,
      getCompanyRoles: createSelector(this._state, fromUser.getCompanyRoles),

      getUserPrivilegesByUserId: userId => createSelector(
        this._state,
        data => data.uprawnienia_uzytkownika.filter(p => {
          if (typeof p._uzytkownik === 'string') {
            return p._uzytkownik === '/api/uzytkownik/' + userId;
          } else {
            return p._uzytkownik.id === userId;
          }
        })
      ),
      getUserPrivilegesByUserLogin: userLogin => createSelector(
        this._state,
        data => data.uprawnienia_uzytkownika.filter(p => p._uzytkownik.username === userLogin)
      ),

      getCompanyUsersByRole: roleId => createSelector(
        this._state,
        data => data.uzytkownicy.filter(user => user._rola.id === roleId)
      ),

      getUserByUsername: username => createSelector(
        this._state,
        data => data.uzytkownicy.find(user => user.username === username)
      )

    };

    this._selectors.getCompanyUsersRoles = createSelector(this._selectors.getCompanyUsers, (users: Uzytkownik[]) => {
      return users
        .map((user: Uzytkownik) => user._rola)
        .filter((role, index, roles) => {
          return roles.findIndex((secondRole) => secondRole.id === role.id) === index;
        })
        ;
    });

    return this._selectors;
  }
}
