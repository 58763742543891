import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  Kierowca,
  NaczepaZestawSimple,
  SamochodZestawSimple,
  ZestawTransportowy,
  ZestawTransportowyRequestNewFields,
  ZestawTransportowySimple
} from '../interfaces';
import { EnvHelper } from '../../../helpers';
import { environment } from '../../../../environments/environment';
import { PunktSpedycyjny } from '../../../models/dto/transportSets';
import { NewTransitRouteRequest } from '../../../models/form/map-search-route-form';

@Injectable({
  providedIn: 'root'
})
export class TransportSetService {

  transApi = environment.apiModules.TransSets;

  constructor(private http: HttpClient) {
  }

  getDrivers(): Observable<Kierowca[]> {
    return this.http.get<Kierowca[]>(
      EnvHelper.getApiUrl() + this.transApi.sets + this.transApi.drivers
    );
  }

  createTransportSet(request: ZestawTransportowyRequestNewFields): Observable<unknown> {
    return this.http.post(
      EnvHelper.getApiUrl() + this.transApi.sets + '_edycja', request
    );
  }

  setDriver1(driverId: number, setId: number) {
    return this.http.put<Kierowca[]>(
      EnvHelper.getApiUrl() + this.transApi.sets + '/drivers/' + setId + '/setdriver1/' + driverId, {}
    );
  }

  setDriver2(driverId: number, setId: number) {
    return this.http.put<Kierowca[]>(
      EnvHelper.getApiUrl() + this.transApi.sets + '/drivers/' + setId + '/setdriver2/' + driverId, {}
    );
  }

  setTruck(truckId: number, setId: number) {
    return this.http.post(
      EnvHelper.getApiUrl() + this.transApi.sets + this.transApi.transportTrucks, {'id_samochod': truckId, 'id_zestaw': setId}
    );
  }

  setTrail(trailId: number, setId: number) {
    return this.http.post(
      EnvHelper.getApiUrl() + this.transApi.sets + this.transApi.transportTrails, {
        'id_naczepa': trailId,
        'id_zestaw': setId,
        'zezw_odlaczenia': true
      }
    );
  }

  getActiveTransportSets(): Observable<ZestawTransportowySimple[]> {
    return this.http.get<ZestawTransportowySimple[]>(
      EnvHelper.getApiUrl() + this.transApi.sets
    );
  }

  getActiveFullTransportSets(): Observable<ZestawTransportowy[]> {
    return this.http.get<ZestawTransportowy[]>(
      EnvHelper.getApiUrl() + this.transApi.sets + this.transApi.fullSets
    );
  }

  createNewTransportSet(set: ZestawTransportowySimple): Observable<ZestawTransportowySimple> {
    return this.http.post<ZestawTransportowySimple>(EnvHelper.getApiUrl() + this.transApi.sets, set);
  }

  createNewTransportSetOneShot(set: ZestawTransportowySimple): Observable<ZestawTransportowySimple> {
    return this.http.post<ZestawTransportowySimple>(EnvHelper.getApiUrl() + this.transApi.sets, set);
  }

  getTruckToTransportsAssignment(): Observable<SamochodZestawSimple[]> {
    return this.http.get<SamochodZestawSimple[]>(
      EnvHelper.getApiUrl() + this.transApi.sets + this.transApi.transportTrucks
    );
  }

  getTrailToTransportsAssignment(): Observable<NaczepaZestawSimple[]> {
    return this.http.get<NaczepaZestawSimple[]>(
      EnvHelper.getApiUrl() + this.transApi.sets + this.transApi.transportTrails
    );
  }

  assignTruckToTransport(inputData: SamochodZestawSimple): Observable<any> {
    return this.http.post(EnvHelper.getApiUrl() + this.transApi.transportTrucks, inputData);
  }

  updateTruckToTransport(inputData: SamochodZestawSimple): Observable<any> {
    return this.http.put(EnvHelper.getApiUrl() + this.transApi.transportTrucks + '/' + inputData.id, inputData);
  }

  assignTrailToTransport(inputData: NaczepaZestawSimple): Observable<any> {
    return this.http.post(EnvHelper.getApiUrl() + this.transApi.transportTrucks, inputData);
  }

  updateTrailToTransport(inputData: NaczepaZestawSimple): Observable<any> {
    return this.http.put(EnvHelper.getApiUrl() + this.transApi.transportTrucks + '/' + inputData.id, inputData);
  }

  getTruckForTransport(trans_id: number) {
    return this.http.get<ZestawTransportowySimple[]>(
      EnvHelper.getApiUrl() + this.transApi.sets + this.transApi.transportTrucks + '/set/' + trans_id.toString()
    );
  }

  getShippingPoint(): Observable<PunktSpedycyjny[]> {
    return this.http.get<PunktSpedycyjny[]>(
      EnvHelper.getApiUrl() + this.transApi.punktySpedycyjne + '_odczyt'
    );
  }

  newShippingPoint(data: PunktSpedycyjny): Observable<undefined> {
    return this.http.post<undefined>(
      EnvHelper.getApiUrl() + this.transApi.punktySpedycyjne + '_edycja', data
    );
  }
  updateShippingPoint(data: PunktSpedycyjny): Observable<undefined> {
    return this.http.put<undefined>(
      EnvHelper.getApiUrl() + this.transApi.punktySpedycyjne + '_edycja/' + data.id, data
    );
  }
  removeShippingPoint(id: number): Observable<undefined> {
    return this.http.delete<undefined>(
      EnvHelper.getApiUrl() + this.transApi.punktySpedycyjne + '_edycja/' + id
    );
  }

  newTranssetRoute(data: NewTransitRouteRequest): Observable<undefined> {
    return this.http.post<any>(
      EnvHelper.getApiUrl() + this.transApi.nowaTrasa + '_edycja', data
    );
  }
}
