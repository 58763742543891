import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import * as fromTerminal from '../ngrx/terminal.reducer';
import {
  Karta,
  KartaTerminal,
  OprogramowanieTerminala,
  PoleRamkiTypDanych,
  Ramka,
  SamochodTerminal,
  StatusTerminala,
  Terminal,
  TerminalNaczepa,
  WersjaRamkiOpr
} from '../models/dto/terminale';
import { MomentHelper } from '../helpers';

interface Selectors {
  getTerminalSimCards: MemoizedSelector<any, KartaTerminal[]>;

  getTerminals: MemoizedSelector<any, Terminal[]>;
  getFrames: MemoizedSelector<any, Ramka[]>;
  getFrameFieldTypes: MemoizedSelector<any, PoleRamkiTypDanych[]>;
  getSoftwareFrames: MemoizedSelector<any, WersjaRamkiOpr[]>;
  getTerminalStatuses: MemoizedSelector<any, StatusTerminala[]>;
  getSoftwareList: MemoizedSelector<any, OprogramowanieTerminala[]>;
  getTerminalTruck: MemoizedSelector<any, SamochodTerminal[]>;
  getTerminalTrail: MemoizedSelector<any, TerminalNaczepa[]>;
  getSimCards: MemoizedSelector<any, Karta[]>;
  getBoltPin: (terminalId: number) => MemoizedSelector<any, Terminal>;
  getTruckTerminalByTruckId: (truckId: number) => MemoizedSelector<any, SamochodTerminal>;
  getTrailTerminalByTrailId: (trailId: number) => MemoizedSelector<any, TerminalNaczepa>;
  getTerminalById: (terminalId: number) => MemoizedSelector<any, Terminal>;
  getTerminalSimCardsByTerminalId: (terminalId: number) => MemoizedSelector<any, KartaTerminal[]>;
}

export class DeviceSelectors {
  static _state = createFeatureSelector<fromTerminal.State>('device');

  static _selectors: Selectors;

  static getSelectors() {
    this._selectors = {
      getTerminalSimCards: createSelector(this._state, fromTerminal.getTerminalSimCards),

      getTerminals: createSelector(this._state, fromTerminal.getTerminals),
      getFrames: createSelector(this._state, fromTerminal.getFrames),
      getFrameFieldTypes: createSelector(this._state, fromTerminal.getFrameFieldTypes),
      getSoftwareFrames: createSelector(this._state, fromTerminal.getSoftwareFrames),
      getTerminalStatuses: createSelector(this._state, fromTerminal.getTerminalStatuses),
      getSoftwareList: createSelector(this._state, fromTerminal.getSoftwareList),
      getTerminalTruck: createSelector(this._state, fromTerminal.getTerminalTruck),
      getTerminalTrail: createSelector(this._state, fromTerminal.getTerminalTrail),
      getSimCards: createSelector(this._state, fromTerminal.getSimCards),

      getBoltPin: terminalId => createSelector(this._state, data => data.boltPin?.id === terminalId ? data.boltPin : undefined),
      getTruckTerminalByTruckId: truckId => createSelector(
        this._state,
        data => data.terminalTruck.find(t => t._samochod.id === truckId)
      ),
      getTrailTerminalByTrailId: trailId => createSelector(
        this._state,
        data => data.terminalTrail.find(t => t._naczepa.id === trailId)
      ),
      getTerminalById: terminalId => createSelector(
        this._state,
        data => data.terminals.find(t => t.id === terminalId)
      ),
      getTerminalSimCardsByTerminalId: terminalId => createSelector(
        this._state,
        data =>
          data.terminalSimCards
            .filter(card => card._terminal.id === terminalId)
            .sort((a, b) => MomentHelper.compareDates(b.czas_zamont, a.czas_zamont))
      )
    };

    return this._selectors;
  }
}
