import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';

import * as TRANSSET from './transset.actions';
import * as UI from '../../../ngrx/ui.actions';
import * as fromRoot from '../../../app.reducer';

import { TransportSetService } from '../services/transport-set.service';
import { Messages, ToastType } from '../../../helpers/enum';
import { TransitService } from '../../transports/services';
import { Router } from '@angular/router';

@Injectable()
export class TranssetEffects {

  loadDrivers$ = createEffect(() => this.action.pipe(
    ofType(TRANSSET.loadDriversRequest),
    exhaustMap(() => {
      return this.transApi.getDrivers()
        .pipe(
          map(kierowcy => TRANSSET.loadDriversSuccess({kierowcy})),
          catchError(() => {
            return of(UI.userError({message: Messages.READING_DATA_ERR}));
          })
        );
    })
  ));


  loadFullTranssetListNew$ = createEffect(() => this.action.pipe(
    ofType(TRANSSET.loadFullTranssetListRequestNew),
    exhaustMap(() => {
      return this.transitApi.getAllTransportSets()
        .pipe(
          map(zestawy => TRANSSET.loadFullTranssetListSuccessNew({zestawy})),
          catchError(() => {
            return of(UI.userError({message: Messages.READING_DATA_ERR}));
          })
        );
    })
  ));

  newTransportSetFullNew$ = createEffect(() => this.action.pipe(
    ofType(TRANSSET.addNewSetFullNew),
    exhaustMap(({request: {request, callback}}) => {
      return this.transApi.createTransportSet(request)
        .pipe(
          map(() => {
            if (callback) {
              callback();
            }
            return TRANSSET.loadFullTranssetListRequestNew();
          }),
          catchError(({error}) => {
            let message = Messages.SAVING_DATA_ERR;
            if (error.error) {
              message = error.error;
            }
            return of(UI.userError({message}));
          })
        );
    })
  ));

  newTransportSetFull$ = createEffect(() => this.action.pipe(
    ofType(TRANSSET.addNewSetFull),
    exhaustMap(action => {
      return this.transApi.createNewTransportSet(action.request.zestaw)
        .pipe(
          map(zestaw => {
            const fields = action.request.fields;
            if (fields.kierowca1) {
              this.transApi.setDriver1(fields.kierowca1.id, zestaw.id).subscribe();
            }
            if (fields.kierowca2) {
              this.transApi.setDriver2(fields.kierowca2.id, zestaw.id).subscribe();
            }
            if (fields.naczepa) {
              this.transApi.setTrail(fields.naczepa.id, zestaw.id).subscribe();
            }
            if (fields.samochod) {
              this.transApi.setTruck(fields.samochod.id, zestaw.id).subscribe();
            }
            if (action.request.callback) {
              action.request.callback();
            } else {
              this.toastrService.success('New set has been created.');
            }
            return TRANSSET.loadFullTranssetListRequestNew();
          }),
          catchError(() => {
            return of(UI.userError({message: Messages.SAVING_DATA_ERR}));
          })
        );
    })
  ));

  loadShippingPoints$ = createEffect(() => this.action.pipe(
    ofType(TRANSSET.loadShippingPointRequest),
    exhaustMap(() => {
      return this.transApi.getShippingPoint()
        .pipe(
          map(punkty => TRANSSET.loadShippingPointSuccess({punkty})),
          catchError(() => {
            return of(UI.userError({message: Messages.READING_DATA_ERR}));
          })
        );
    })
  ));

  newShippingPoint$ = createEffect(() => this.action.pipe(
    ofType(TRANSSET.newShippingPointRequest),
    exhaustMap(request => {
      return this.transApi.newShippingPoint(request.punkt).pipe(
        map(() => {
          this.router.navigate(['/dictionaries/shipping-points']);
          return TRANSSET.shippingPointSuccess({ message: 'Shipping point added' });
        }),
        catchError(() => {
          return of(TRANSSET.shippingPointFailure());
        })
      );
    })
  ));

  updateShippingPoint$ = createEffect(() => this.action.pipe(
    ofType(TRANSSET.updateShippingPointRequest),
    exhaustMap(request => {
      return this.transApi.updateShippingPoint(request.punkt).pipe(
        map(() => {
          this.router.navigate(['/dictionaries/shipping-points']);
          return TRANSSET.shippingPointSuccess({ message: 'Shipping point updated' });
        }),
        catchError(() => {
          return of(TRANSSET.shippingPointFailure());
        })
      );
    })
  ));

  shippingPointSuccess$ = createEffect(() => this.action.pipe(
    ofType(TRANSSET.shippingPointSuccess),
    switchMap(({ message }) => of(UI.showUserMessage({message: {type: ToastType.SUCCESS, message}})))
  ));

  shippingPointFailure$ = createEffect(() => this.action.pipe(
    ofType(TRANSSET.shippingPointFailure),
    switchMap(() => of(UI.userError({message: Messages.SAVING_DATA_ERR})))
  ));

  removeShippingPoint$ = createEffect(() => this.action.pipe(
    ofType(TRANSSET.removeShippingPointRequest),
    exhaustMap(request => {
      return this.transApi.removeShippingPoint(request.id).pipe(
        map(() => {
          if (request.successCallback) {
            request.successCallback();
          }
          this.store.dispatch(TRANSSET.loadShippingPointRequest());
          return UI.showUserMessage({message: {type: ToastType.SUCCESS, message: 'Shipping point removed'}});
        }),
        catchError(() => {
          return of(UI.userError({message: Messages.SAVING_DATA_ERR}));
        })
      );
    })
  ));


  newTranssetRouteRequest$ = createEffect(() => this.action.pipe(
    ofType(TRANSSET.newTranssetRouteRequest),
    exhaustMap(action => {
      return this.transApi.newTranssetRoute(action.request)
        .pipe(
          map(() => {
            action.successCallback();
            this.store.dispatch(TRANSSET.newTranssetRouteRequestCompleted());
            return UI.showUserMessage({message: {type: ToastType.SUCCESS, message: 'Route have been added'}});
          }),
          catchError(() => {
            this.store.dispatch(TRANSSET.newTranssetRouteRequestFailed());
            return of(UI.userError({message: Messages.SAVING_DATA_ERR}));
          })
        );
    })
  ));

  constructor(private action: Actions,
              private transApi: TransportSetService,
              private transitApi: TransitService,
              private toastrService: ToastrService,
              private store: Store<fromRoot.State>,
              private router: Router) {

  }
}
