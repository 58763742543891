import {
  ADD_TRAIL_SUCCESS,
  ADD_TRUCK_SUCCESS,
  FILTER_VEHICLES,
  LOAD_CONN_TYPES_SUCCESS,
  LOAD_MODELS_SUCCESS,
  LOAD_TRAIL_TYPES_SUCCESS,
  LOAD_TRAILS_SUCCESS,
  LOAD_TRUCK_TYPES_SUCCESS,
  LOAD_UNAVAIL_REASONS_SUCCESS,
  LOAD_VEHICLE_STATES_SUCCESS,
  LOAD_VEHICLE_SUCCESS,
  LOAD_VEHICLES_SUCCESS,
  LOAD_VENDOR_SUCCESS,
  LOAD_WORK_STATES_SUCCESS,
  TRUCK_TABLET_LOC_SUCCESS,
  VehicleActions
} from './vehicle.actions';
import {
  Marka,
  Model,
  Naczepa,
  Samochod,
  StanPracy,
  StatusPojazdu,
  TypZapiecia,
  VehSearchTerm
} from '../models/dto/vehicle';

export interface State {
  trucks: Samochod[];
  trails: Naczepa[];
  vehSearchTerm: VehSearchTerm;
  // todo: dodac typ na driverTAblet
  truckTablet: any;
  baseTypes: {
    trailType: any[];
    connType: TypZapiecia[];
    truckType: Marka[];
    unavailReasons: any[];
    model: Model[];
    vendor: Marka[];
    workStates: StanPracy[],
    vehicleStates: StatusPojazdu[]
  };
}

export const initialState: State = {
  trucks: [],
  trails: [],
  vehSearchTerm: {
    term: '',
    kind: 'none',
    states: []
  },
  truckTablet: [],
  baseTypes: {
    trailType: [],
    connType: [],
    truckType: [],
    unavailReasons: [],
    model: [],
    vendor: [],
    workStates: [],
    vehicleStates: []
  }
};

export function vehicleReducer(state = initialState, action: VehicleActions) {
  switch (action.type) {
    case TRUCK_TABLET_LOC_SUCCESS: {
      if (!action.payload.data) {
        return {...state};
      }
      // console.log(action.payload.data[0]);
      // const newTablet = [...state.truckTablet].filter(info => info._tablet.id !== action.payload.data._tablet.id);
      return {...state, truckTablet: [action.payload.data]};
      // return {...state, truckTablet: {...action.payload.data}};
    }
    case FILTER_VEHICLES:
      let vehSearchTerm = state.vehSearchTerm;
      if (action.payload.kind === 'none') {
        return {...state, vehSearchTerm: initialState.vehSearchTerm};
      }

      if (action.payload.kind !== vehSearchTerm.kind) {
        vehSearchTerm = {...initialState.vehSearchTerm, kind: action.payload.kind};
      }

      if (action.payload.term !== undefined) {
        vehSearchTerm = {...vehSearchTerm, term: action.payload.term};
      }

      if (action.payload.states !== undefined) {
        vehSearchTerm = {...vehSearchTerm, states: action.payload.states};
      }

      return {...state, vehSearchTerm};

    case LOAD_VEHICLE_SUCCESS:
      let trucks = [...state.trucks];
      trucks = trucks.filter(truck => truck.id !== action.payload.id);
      return {...state, trucks: [...trucks, action.payload]};

    case ADD_TRUCK_SUCCESS:
      return {...state, trucks: [...state.trucks, action.payload]};

    case LOAD_VEHICLES_SUCCESS:
      return {...state, trucks: action.payload};

    case ADD_TRAIL_SUCCESS:
      return {...state, trails: [...state.trails, action.payload]};

    case LOAD_TRAILS_SUCCESS:
      return {...state, trails: action.payload};

    case LOAD_TRAIL_TYPES_SUCCESS:
      return {...state, baseTypes: {...state.baseTypes, trailType: action.payload}};

    case LOAD_CONN_TYPES_SUCCESS:
      return {...state, baseTypes: {...state.baseTypes, connType: action.payload}};

    case LOAD_TRUCK_TYPES_SUCCESS:
      return {...state, baseTypes: {...state.baseTypes, truckType: action.payload}};

    case LOAD_UNAVAIL_REASONS_SUCCESS:
      return {...state, baseTypes: {...state.baseTypes, unavailReasons: action.payload}};

    case LOAD_MODELS_SUCCESS:
      return {...state, baseTypes: {...state.baseTypes, model: action.payload}};

    case LOAD_VENDOR_SUCCESS:
      return {...state, baseTypes: {...state.baseTypes, vendor: action.payload}};

    case LOAD_WORK_STATES_SUCCESS:
      return {...state, baseTypes: {...state.baseTypes, workStates: action.payload}};

    case LOAD_VEHICLE_STATES_SUCCESS:
      return {...state, baseTypes: {...state.baseTypes, vehicleStates: action.payload}};

    default:
      return state;
  }
}

export const getTrucks = (currState: State) => currState.trucks;
export const getTrails = (currState: State) => currState.trails;

export const getTrailTypes = (currState: State) => currState.baseTypes.trailType;
export const getConnectionTypes = (currState: State) => currState.baseTypes.connType;
export const getTruckTypes = (currState: State) => currState.baseTypes.truckType;
export const getUnavailabilityReasons = (currState: State) => currState.baseTypes.unavailReasons;
export const getModels = (currState: State) => currState.baseTypes.model;
export const getVendors = (currState: State) => currState.baseTypes.vendor;
export const getWorkStates = (currState: State) => currState.baseTypes.workStates;
export const getTruckWorkStates = (currState: State) => currState.baseTypes.workStates.filter(state => state.dotyczy_pojazdu);
export const getTrailWorkStates = (currState: State) => currState.baseTypes.workStates.filter(state => !state.dotyczy_pojazdu);
export const getVehicleStates = (currState: State) => currState.baseTypes.vehicleStates;
export const getVehicleSearch = (currState: State) => currState.vehSearchTerm;
export const getTruckTablet = (currState: State) => currState.truckTablet;

