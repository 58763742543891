import { Pipe, PipeTransform } from '@angular/core';
import { IAlarmTransportowy } from '../../alerts/interfaces';
import { IAlarmTransportowyUproszczonyDTO } from '../../../models/dto/alert/alarm-transportowy-uproszczony';
import { TransportAlert } from '../../../helpers';
import { IAlarmPodstawowy } from '../../../models/dto/alert/alarm-transportowy';

@Pipe({
  name: 'isTransportAlertPending'
})
export class IsTransportAlertPendingPipe implements PipeTransform {

  transform(alert: IAlarmTransportowy | IAlarmTransportowyUproszczonyDTO | IAlarmPodstawowy): boolean {
    return TransportAlert.isAlertOpen(alert);
  }

}
