import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { combineLatest, Subscription } from 'rxjs';

@Component({
  selector: 'date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimePickerComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateTimePickerComponent
  implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() minDate: Date;
  @Input() maxDate: Date;

  private onChange: (value: Date | null) => void;
  private onTouched: () => void;
  private changeSubscription: Subscription;

  dateTimeForm: FormGroup;
  disabled: boolean;

  constructor(private fb: FormBuilder, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.createForm();
    this.registerDateTimeChange();
  }

  ngOnDestroy(): void {
    this.changeSubscription.unsubscribe();
  }

  writeValue(obj: Date | null): void {
    this.dateTimeForm.get('date').setValue(obj !== null ? new Date(obj) : null);
    this.dateTimeForm.get('time').setValue(obj !== null ? new Date(obj) : null);
  }

  registerOnChange(fn: (value: Date | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;

    if (this.disabled) {
      this.dateTimeForm.disable();
    } else {
      this.dateTimeForm.enable();
    }

    this.cd.markForCheck();
  }

  private registerDateTimeChange(): void {
    this.changeSubscription = combineLatest([
      this.dateTimeForm.get('date').valueChanges,
      this.dateTimeForm.get('time').valueChanges,
    ]).subscribe(([date, time]: [Date | null, Date | null]) => {
      if (typeof this.onChange !== 'undefined') {
        if (date !== null && time !== null) {
          const updatedDate: Date = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            time.getHours(),
            time.getMinutes(),
            0,
            0
          );

          this.onChange(updatedDate);
          this.onTouched();
          return;
        }

        this.onChange(null);
        this.onTouched();
      }
    });
  }

  private createForm(): void {
    this.dateTimeForm = this.fb.group({
      date: this.fb.control(null),
      time: this.fb.control(null),
    });
  }
}
