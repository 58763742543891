import { ToastrService } from 'ngx-toastr';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserManagementService } from 'src/app/services';
import { filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromRoot from '../../../app.reducer';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss']
})
export class PasswordChangeComponent implements OnInit, OnDestroy {
  private subs = new Subscription();

  // user: Uzytkownik;
  userId = 0;
  newPassword = '';
  newRePassword = '';

  constructor(
    private userSrv: UserManagementService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private store: Store<fromRoot.State>) {
  }

  ngOnInit() {
    this.subs.add(
      this.store.select(fromRoot.selectors.user.getSelectedUserInfo)
        .pipe(
          filter(user => user !== undefined)
        )
        .subscribe(user => {
          this.userId = user?.id;
        })
    );
  }

  passChangAction() {
    if (this.newPassword !== this.newRePassword) {
      this.toastr.error('Podane hasła nie są takie same!', 'Niepoprawne wartości');
      return;
    }

    this.userSrv.changUserPassword(this.userId, this.newPassword).subscribe(() => {
      this.toastr.success('Hasło zostało zmienione', 'Wykonane');
    });

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
