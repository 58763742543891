<mat-sidenav-container [hasBackdrop]="false">

  <mat-sidenav #sidenav mode="over" fxFlex="1 2 calc(100vw*2/12)" [attr.color]="'primary'" (keydown.escape)="toggleMainMenu(true)"
               [(opened)]="opened" fxLayoutGap="10px" fxLayout="column" (openedChange)="openedLeftChangeEvent()">

    <mat-card class="example-card">
      <mat-card-header fxLayout="column">
        <app-user-affiliation (mainMenuCloseIconClicked_)="toggleMainMenu()"
                              (showUserInfoAction)="showUserInfo()"></app-user-affiliation>
      </mat-card-header>
    </mat-card>

    <app-main-menu></app-main-menu>

  </mat-sidenav>

  <mat-sidenav-content fxFlex>
    <div class="top {{animationClass}}" [class.opened]="sidenav.opened">
      <div fxFlex fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
        <button type="button" *ngIf="!sidenav.opened" mat-icon-button (click)="toggleMainMenu()" readonly="readonly">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <div fxLayout="column" fxLayoutGap="8px">
          <app-top-bar></app-top-bar>
        </div>
        <span fxFlex></span>
        <app-top-right-corner-menu (showUserInfoAction)="showUserInfo()">
        </app-top-right-corner-menu>
      </div>
    </div>

    <mat-drawer-container class="bottom {{animationClass}}" (backdropClick)="toggleRightDrawer()"
                          [hasBackdrop]="rightDrawerSrv.hasBackdrop$ | async" [class.opened]="sidenav.opened">
      <mat-drawer #drawer fxLayout="column" fxLayoutGap="8px" (keydown.escape)="toggleRightDrawer()"
                  [mode]="rightDrawerSrv.mode$ | async" position="end" [disableClose]="!(rightDrawerSrv.isClosable$ | async)"
                  (openedChange)="openedRightChangeEvent()">
        <div class="right-drawer-close" fxLayout="row" fxLayoutAlign="end" *ngIf="rightDrawerSrv.isClosable$ | async">
          <mat-icon class="close-icon pointer" (click)="toggleRightDrawer()">close</mat-icon>
        </div>
        <app-right-drawer-container></app-right-drawer-container>
      </mat-drawer>
      <mat-drawer-content>
        <app-sub-menu></app-sub-menu>
        <ng-content></ng-content>
        <router-outlet></router-outlet>
      </mat-drawer-content>
    </mat-drawer-container>

  </mat-sidenav-content>
</mat-sidenav-container>

<app-progress-bar *ngIf="(showProgressBar$ | async)"></app-progress-bar>
