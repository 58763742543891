<ng-container *ngIf="transport as transit">
  <div class="transit-assigned-parking-container" fxLayout="column" fxLayoutGap="16px">
    <p mat-subheading-1 class="drawer-heading">Assign parking</p>
    <p class="parking-heading">
    <span class="vehicle-name"
          [innerHTML]="(transit.zestaw?.truckSet | vehicleFromTransit) ? (transit.zestaw?.truckSet | vehicleFromTransit).nrRej : 'No truck'"
          [class.no-vehicle]="!(transit.zestaw?.truckSet | vehicleFromTransit)">

    </span> /
      <span class="vehicle-name"
            [innerHTML]="(transit.zestaw?.trailerSet | vehicleFromTransit) ? (transit.zestaw?.trailerSet | vehicleFromTransit).nrRej : 'No trailer'"></span>
      (transport no: {{ transit.id }})
    </p>
    <div fxLayout="column" fxLayoutGap="8px" class="assigned-parking-container">
      <div *ngFor="let transportParking of transport.parkingPrzejazdList" class="assigned-parking"
           fxLayout="row" fxLayoutAlign="space-between">
        <div class="parking-content" fxLayout="column">
          <p class="registration" fxLayout="row" fxLayoutAlign="start center">
            <span>Parking no: {{ transportParking.parking.id }}</span>
          </p>
          <p class="type">
            {{ transportParking.parking.nazwa }} ({{ transportParking.parking.kraj }}
            - {{ transportParking.parking.region }})
          </p>
        </div>
        <div class="parking-actions" fxLayout="column" fxLayoutAlign="center">
          <button mat-icon-button (click)="unassign(transit.id, transportParking.parking.id)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="r-drawer-container">
    <form [formGroup]="assignParkingForm" fxLayout="column" fxLayoutGap="10px" (ngSubmit)="assign(transit.id)">
      <mat-form-field>
        <input type="text" placeholder="Start search parking..." matInput formControlName="parking"
               [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayParking">
          <mat-option *ngFor="let parking of filteredParking$ | async" [value]="parking">
            {{ parking.nazwa }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <button mat-raised-button type="submit" [disabled]="!assignParkingForm.valid" color="primary">Assign</button>
      <button mat-raised-button type="submit" *ngIf="fallback" (click)="fallback()">Save</button>
    </form>
  </div>
</ng-container>
