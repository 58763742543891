import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { AuthenticationService, MyToastService } from '../services';
import * as fromRoot from '../app.reducer';
import * as AUTH from '../ngrx/auth.actions';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private toastr: ToastrService,
    private myMsg: MyToastService,
    private dialogRef: MatDialog,
    private store: Store<fromRoot.State>) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.dialogRef.closeAll();
          this.router.navigate(['/']);
          this.myMsg.error(err.error['error'] + ' (' + err.status + ')');
          this.store.dispatch(new AUTH.LogoutRequest());
        }

        return throwError(err);
      }));
  }
}
