import { Component, Input } from '@angular/core';
import { ZestawTransportowyBase } from '../../../../../models/dto/transportSets';

@Component({
  selector: 'app-truck-and-trailer',
  templateUrl: './truck-and-trailer.component.html',
  styleUrls: ['./truck-and-trailer.component.scss']
})
export class TruckAndTrailerComponent {
  @Input() zestaw: ZestawTransportowyBase;
}
