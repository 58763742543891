import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { debounceTime } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

import * as fromRoot from './app.reducer';
import * as GENERAL from './ngrx/general.actions';
import * as UI from './ngrx/ui.actions';

import { NavMenuService } from './services';
import { IconService } from './modules/map/services/icon.service';
import { PageTitleHelper } from './helpers';
import { ToastType } from './helpers/enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private wasLogged = false;

  constructor(
    private sideNavService: NavMenuService,
    private store: Store<fromRoot.State>,
    private icons: IconService,
    private titleService: Title
  ) {
    icons.loadBaseMapIcon();
    PageTitleHelper.titleService = titleService;
  }

  ngOnInit(): void {
    this.store
      .select(fromRoot.selectors.auth.getAuthIsAuthenticated)
      .pipe(
        debounceTime(100)
      )
      .subscribe((isAuth) => {
        this.wasLogged = this.wasLogged || isAuth;
        if (!isAuth) {
          if (!this.wasLogged) {
            const msg = {
              message: 'Please login to start work!',
              type: ToastType.INFO,
              title: 'Hello',
            };
            this.store.dispatch(UI.showUserMessage({message: msg}));
          }
        } else {
          this.wasLogged = true;
          this.store.dispatch(GENERAL.loadDictionaryData());
        }
      });

    this.sideNavService.generateGeneralTopLinks();
  }
}
