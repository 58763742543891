<cdk-virtual-scroll-viewport
  itemSize="65"
  minBufferPx="calc(100vh - 186px)"
  maxBufferPx="500"
  class="virtual-scroll">
  <div fxLayout="row wrap" fxLayoutGap="8px grid" fxLayoutAlign="flex-start">
    <div *cdkVirtualFor="let alert of flattenedAlerts; templateCacheSize: 16; trackBy: trackByMethod"
         fxFlex="0 0 calc(25%)">
        <app-alert-tile [alert]="alert"
                        (click)="onTileClick(alert)"
                        class="pointer">
        </app-alert-tile>
    </div>
  </div>
</cdk-virtual-scroll-viewport>
