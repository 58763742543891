<ng-container *ngIf="(form$ | async) as form">
  <div fxLayout="column" fxLayoutGap="16px" class="user-form-container fwd-form">
    <h2 mat-subheading-1>Edit user</h2>

    <form [formGroup]="form" class="form" fxLayout="column" fxLayoutGap="8px" autocomplete="off" (ngSubmit)="onSubmit(form)">
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput id="imie" formControlName="imie">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Surname</mat-label>
        <input matInput id="nazwisko" formControlName="nazwisko">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput id="email" formControlName="email">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Tel. no. 1</mat-label>
        <input matInput id="tel1" formControlName="tel1">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Tel. no. 2</mat-label>
        <input matInput id="tel2" formControlName="tel2">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Company</mat-label>
        <input type="text" id="firma" matInput [matAutocomplete]="autoCompany"
               formControlName="firma" readonly>
        <mat-autocomplete #autoCompany="matAutocomplete" [displayWith]="displayCompany">
          <mat-option *ngFor="let company of filteredCompany$ | async" [value]="company">
            {{ displayCompany(company) }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Login</mat-label>
        <input matInput id="login" formControlName="login" readonly>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Current role</mat-label>
        <input matInput [matAutocomplete]="autoRole" type="text" id="rola"
               formControlName="rola" autocomplete="user-role">
        <mat-autocomplete #autoRole="matAutocomplete" [displayWith]="displayRole">
          <mat-option *ngFor="let role of filteredRoles$ | async" [value]="role">
            {{ displayRole(role) }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <button mat-raised-button color="primary" class="mat-button accept-button" type="submit">
        Save
      </button>
    </form>
  </div>

</ng-container>

