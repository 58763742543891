import { Component, Input } from '@angular/core';

export enum EventTypeEnum {
  ALERTS = 'alerts',
  TICKETS = 'tickets',
  DRIVER_REPORTS = 'driver-reports'
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'event-quantity-badge',
  templateUrl: './event-quantity-badge.component.html',
  styleUrls: ['./event-quantity-badge.component.scss']
})
export class EventQuantityBadgeComponent {
  @Input() quantity: number;
  @Input() link: string;
  @Input() badgeType: EventTypeEnum;
}



