import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, startWith } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import * as fromRoot from '../../../../app.reducer';
import * as TRANSSET from '../../../transport-set/ngrx/transset.actions';
import * as MAP from '../../../../ngrx/map.actions';

import { RightDrawerService } from '../../../shared/services';
import { Strings } from '../../../../helpers';
import { GenericPoint } from '../../../../models/map';
import { PunktSpedycyjny } from '../../../../models/dto/transportSets';
import { BaseYesNoConfig } from '../../../shared/interfaces';
import { BaseYesNoDialogComponent } from '../../../shared/dialogs';
import { ShippingPointComponent } from '../../../map/components';
import { MapViewRoute, SvgMapIcon } from '../../../../helpers/enum';
import { LocationService } from '../../../map/services/location.service';

@Component({
  selector: 'app-shipping-points',
  templateUrl: './shipping-points.component.html',
  styleUrls: ['./shipping-points.component.scss']
})
export class ShippingPointsComponent implements OnInit, OnDestroy {
  static readonly pointListRoute = '/dictionaries/shipping-points';

  @ViewChild(MatSort) sort: MatSort;
  dataSource = new MatTableDataSource<PunktSpedycyjny>();
  displayedColumns: string[] = ['id', 'nazwa', 'nazwa_kod', 'kraj', 'miasto', 'opis', 'inne'];
  formSearch = new FormControl();
  subs = new Subscription();
  shippingPoints: PunktSpedycyjny[];

  constructor(
    private store: Store<fromRoot.State>,
    private rightDrawer: RightDrawerService,
    private dialog: MatDialog,
    private router: Router) {
    this.store.dispatch(TRANSSET.loadShippingPointRequest());
  }

  private static filter(punkt: PunktSpedycyjny, term: string): boolean {
    if (term.trim().length < 1) {
      return true;
    }
    const searchField = punkt.nazwa + punkt.kod + punkt.nazwa_kod + punkt.miasto + punkt.panstwo;
    return Strings.searchTextIgnoreAccents(term, searchField) >= 0;
  }

  ngOnInit(): void {
    this.subs.add(
      this.store.select(fromRoot.selectors.transset.getShippingPoints)
        .subscribe(r => {
          this.shippingPoints = r;
          this.dataSource.data = [...this.shippingPoints]
            .filter(punkt => ShippingPointsComponent.filter(punkt, this.formSearch.value ?? ''));
          this.dataSource.sort = this.sort;
        })
    );

    this.formSearch.valueChanges
      .pipe(
        startWith(''),
        debounceTime(250)
      )
      .subscribe(r => {
        this.dataSource.data = [...this.shippingPoints].filter(punkt => ShippingPointsComponent.filter(punkt, r));
      });
  }

  remove(punkt: PunktSpedycyjny) {
    this.rightDrawer.close();
    const config: BaseYesNoConfig = {
      title: 'Shipping point deletion',
      content: `Are you sure you want to delete this item?`,
      yesAction: () => this.store.dispatch(TRANSSET.removeShippingPointRequest({id: punkt.id})),
      yesLabel: 'Delete',
      yesColor: 'warn',
      noLabel: 'Cancel',
      noColor: 'primary',
      autoClosure: true,
    };
    this.dialog.open(BaseYesNoDialogComponent, {
      data: config,
      id: 'BaseYesNoDialogComponent-ShippingPoint',
      position: {top: '7%'}
    });
  }

  presentOnMap(point: PunktSpedycyjny) {
    const coords = LocationService.getCoordsNamedPL(point);
    const points: GenericPoint[] = [
      {
        ...coords, id: point.id,
        label: '(' + point.nazwa_kod + '): ' + point.nazwa,
        iconType: SvgMapIcon.SHIPPING
      }
    ];
    this.store.dispatch(MAP.AdhocPoints({points}));
    const extraData = {
      shippingPoint: point,
      callbackData: {
        label: 'Go back',
        fn: () => {
          this.router.navigateByUrl(ShippingPointsComponent.pointListRoute);
        }
      }
    };
    this.store.dispatch(MAP.ViewerExtraData({extraData}));
    this.router.navigate(['/map-view', MapViewRoute.SHIPPING_POINT, point.id]);
  }

  createNewPoint() {
    this.rightDrawer.close();
    setTimeout(() => {
      const extraData = {
        callbackData: {
          label: 'Go back',
          fn: () => {
            this.router.navigateByUrl(ShippingPointsComponent.pointListRoute);
          }
        }
      };
      this.store.dispatch(MAP.ViewerExtraData({extraData}));
      this.router.navigate(['/map-view', MapViewRoute.SHIPPING_POINT]);
    }, 500);
  }

  presentAllOnMap() {
    const points = this.shippingPoints.map(point => {
      const coords = LocationService.getCoordsNamedPL(point);
      return {...coords,
        id: point.id,
        label: point.nazwa,
        iconType: SvgMapIcon.SHIPPING,
        pointClickAction: () => {
          this.rightDrawer.openOver(ShippingPointComponent, {
            shippingPoint: point,
            callbackData: {
              label: 'Go back to table',
              fn: () => {
                this.router.navigateByUrl(ShippingPointsComponent.pointListRoute);
              }
            }
          });
        }
      } as GenericPoint;
    });
    this.store.dispatch(MAP.AdhocPoints({points}));
    this.router.navigate(['/map-view/' + MapViewRoute.SHIPPING_POINT_LIST]);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.rightDrawer.close();
  }
}
