import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import * as ALERTS from '../../../ngrx/alert.actions';
import * as fromAlert from '../../../ngrx/alert.reducer';
import { IAlarmTransportowyUproszczony } from '../../../interfaces';
import { AlertService } from '../../../services';

@Component({
  selector: 'app-alert-tile-list',
  templateUrl: './alert-tile-list.component.html',
  styleUrls: ['./alert-tile-list.component.scss']
})
export class AlertTileListComponent {
  @Input() flattenedAlerts: IAlarmTransportowyUproszczony[];

  constructor(
    private router: Router,
    private alertSrv: AlertService,
    private store: Store<fromAlert.State>) {
  }

  trackByMethod(index: number, element: IAlarmTransportowyUproszczony): number {
    return element.id;
  }

  onTileClick(alert: IAlarmTransportowyUproszczony) {
    this.store.dispatch(new ALERTS.LoadCurrentlyViewedAlertId(alert.id));

    if (!!alert.id) {
      this.router.navigate(['events', 'alerts', 'transport', alert.id.toString()]);
    }
  }

}
