import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import * as USER from './user.actions';
import * as UI from './ui.actions';
import * as fromRoot from '../app.reducer';

import { UserManagementService } from '../services';
import { Messages } from '../helpers/enum';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class UserEffects {
  addNewUser$ = createEffect(() => this.actions.pipe(
    ofType<USER.AddNewUserRequest>(USER.ADD_NEW_USER_REQUEST),
    switchMap(action => {
      return this.users.createNewUser(action.payload)
        .pipe(
          map(() => {
            this.store.dispatch(new USER.LoadCompanyUserListRequest());
            this.toastr.success(Messages.SAVING_DATA_SUCCESS);
            return new USER.AddNewUserSuccess();
          }),
          catchError((errorResponse: HttpErrorResponse) => {
            let message = Messages.SAVING_DATA_ERR;

            if(errorResponse.status.toString().startsWith('4') && errorResponse.error.error?.message) {
              message = errorResponse.error.error.message;
            }

            return of(UI.userError({message}));
          })
        );
    })
  ));
  editUser$ = createEffect(() => this.actions.pipe(
    ofType<USER.EditUserRequest>(USER.EDIT_USER_REQUEST),
    switchMap(action => {
      return this.users.editUser(action.user)
        .pipe(
          map(() => {
            this.toastr.success(Messages.SAVING_DATA_SUCCESS);
            return new USER.LoadCompanyUserListRequest();
          }),
          catchError(() => {
            return of(UI.userError({message: Messages.SAVING_DATA_ERR}));
          })
        );
    })
  ));

  lockUser$ = createEffect(() => this.actions.pipe(
    ofType<USER.LockUserRequest>(USER.LOCK_USER),
    switchMap(action => {
      return this.users.lockUser(action.payload.userId)
        .pipe(
          map(() => {
            if (action.payload.callback) {
              action.payload.callback();
            } else {
              this.toastr.success(Messages.SAVING_DATA_SUCCESS);
            }
            return new USER.LoadCompanyUserListRequest();
          }),
          catchError(() => {
            return of(UI.userError({message: Messages.SAVING_DATA_ERR}));
          })
        );
    })
  ));

  unLockUser$ = createEffect(() => this.actions.pipe(
    ofType<USER.UnLockUserRequest>(USER.UNLOCK_USER),
    switchMap(action => {
      return this.users.unlockUser(action.payload.userId)
        .pipe(
          map(() => {
            if (action.payload.callback) {
              action.payload.callback();
            } else {
              this.toastr.success(Messages.SAVING_DATA_SUCCESS);
            }
            return new USER.LoadCompanyUserListRequest();
          }),
          catchError(() => {
            return of(UI.userError({message: Messages.SAVING_DATA_ERR}));
          })
        );
    })
  ));

  removeUser$ = createEffect(() => this.actions.pipe(
    ofType<USER.RemoveUserRequest>(USER.REMOVE_USER),
    switchMap(action => {
      return this.users.removeUser(action.payload.userId)
        .pipe(
          map(() => {
            if (action.payload.callback) {
              action.payload.callback();
            } else {
              this.toastr.success(Messages.SAVING_DATA_SUCCESS);
            }
            return new USER.LoadCompanyUserListRequest();
          }),
          catchError(() => {
            return of(UI.userError({message: Messages.SAVING_DATA_ERR}));
          })
        );
    })
  ));

  loadCompanyUsers$ = createEffect(() => this.actions.pipe(
    ofType(USER.LOAD_COMPANY_USER_LIST_REQUEST),
    switchMap(() => {
      return this.users.getWorkerList().pipe(
        map(users => new USER.LoadCompanyUserListSuccess(users)),
        catchError(() => {
          return of(UI.userError({message: Messages.READING_DATA_ERR}));
        })
      );
    })
  ));
  loadUserByLogin$ = createEffect(() => this.actions.pipe(
    ofType<USER.LoadUserByLoginRequest>(USER.LOAD_USER_BY_LOGIN_REQUEST),
    switchMap((action) => {
      return this.users.getWorkerInfo(action.payload)
        .pipe(
          map(users => users.pop()),
          map(user => new USER.LoadUserByLoginSuccess(user)),
          catchError(() => {
            return of(UI.userError({message: Messages.READING_DATA_ERR}));
          })
        );
    })
  ));
  loadUserLogins$ = createEffect(() => this.actions.pipe(
    ofType<USER.LoadUserLoginAttemptsRequest>(USER.LOAD_USER_LOGIN_ATTEMPTS_REQUEST),
    switchMap((action) => {
      return this.users.getUserLogHistory(action.payload).pipe(
        map(users => new USER.LoadCompanyUserListSuccess(users)),
        catchError(() => {
          return of(UI.userError({message: Messages.READING_DATA_ERR}));
        })
      );
    })
  ));
  loadUserPrivileges$ = createEffect(() => this.actions.pipe(
    ofType(USER.LOAD_USER_PRIVILEGES_REQUEST),
    mergeMap(() => {
      return this.users.getUserPrivileges()
        .pipe(
          map(usersPrivileges => new USER.LoadUserPrivilegesSuccess(usersPrivileges)),
          catchError(() => {
            return of(UI.userError({message: Messages.READING_DATA_ERR}));
          })
        );
    })
  ));
  removeUserPrivilege$ = createEffect(() => this.actions.pipe(
    ofType<USER.RemoveUserPrivilegeRequest>(USER.REMOVE_USER_PRIVILEGE_REQUEST),
    switchMap((action) => {
      return this.users.removeUserPrivilege(action.payload)
        .pipe(
          map(() => new USER.RemoveUserPrivilegeSuccess()),
          catchError(() => {
            return of(UI.userError({message: Messages.GENERIC_FAILURE}));
          })
        );
    })
  ));
  addUserPrivilege$ = createEffect(() => this.actions.pipe(
    ofType<USER.AddUserPrivilegeRequest>(USER.ADD_USER_PRIVILEGE_REQUEST),
    switchMap((action) => {
      return this.users.addUserPrivilege(action.payload)
        .pipe(
          map((a) => {
            const pLoad = {...action.payload, id: a.id};
            return new USER.AddUserPrivilegeSuccess(pLoad);
          }),
          catchError(() => {
            return of(UI.userError({message: Messages.GENERIC_FAILURE}));
          })
        );
    })
  ));
  loadCompanyRoles$ = createEffect(() => this.actions.pipe(
    ofType<USER.LoadCompanyRolesRequest>(USER.LOAD_COMPANY_ROLES_REQUEST),
    switchMap((_) => {
      return this.users.getRoles()
        .pipe(
          map((roles) => {
            return new USER.LoadCompanyRolesSuccess({role: roles});
          }),
          catchError(() => {
            return of(UI.userError({message: Messages.GENERIC_FAILURE}));
          })
        );
    })
  ));

  constructor(private actions: Actions,
              private store: Store<fromRoot.State>,
              private users: UserManagementService,
              private toastr: ToastrService,) {
  }
}
