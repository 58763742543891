import { DefaultForm } from './default-form';
import { UzytkownikNowy } from '../dto/user/uzytkownik-nowy';
import { FormControl, Validators } from '@angular/forms';
import { usernameValidator } from '../../modules/users/user-create/validators/user.validators';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../app.reducer';

export class RegisterForm extends DefaultForm<UzytkownikNowy> {
  constructor(store: Store<fromRoot.State>) {
    super({
      imie: new FormControl(null, [Validators.required]),
      nazwisko: new FormControl(null, [Validators.required]),
      tel1: new FormControl(null, [Validators.required]),
      tel2: new FormControl(null, [Validators.required]),

      username: new FormControl(null, {validators: [Validators.required], asyncValidators: [usernameValidator(store)]}),
      password: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required]),
      nr_d: new FormControl(null, [Validators.required]),
      nr_l: new FormControl('-', [Validators.required]),
      ul: new FormControl(null, [Validators.required]),

      miasto: new FormControl(null, [Validators.required]),
      panstwo: new FormControl(null, [Validators.required]),
      kod: new FormControl(null, [Validators.required]),

      data_zatr: new FormControl(null, [Validators.required]),
      pyt: new FormControl(null, [Validators.required]),
      odp: new FormControl(null, [Validators.required]),
      _firma: new FormControl(null, [Validators.required]),
      _rola: new FormControl(null, [Validators.required]),
    });
  }
}
