import { Strings } from '../../../helpers';
import { VehicleFromTransitPipe } from '../../shared/pipes';
import { ITransportEntity } from '../interfaces/transport-entity';
import { Filter } from './filter';

export class SearchTextFilter implements Filter {
  constructor(private readonly searchText: string | null) {
  }

  private static createSearchableStringFrom(transport: ITransportEntity): string {
    const trailer = VehicleFromTransitPipe.transform(transport.zestaw?.trailerSet);
    const truck = VehicleFromTransitPipe.transform(transport.zestaw?.truckSet);
    const searchableFields = [
      transport.dyspozytor?.imie,
      transport.dyspozytor?.nazwisko,
      transport.id,
      transport.zestaw.id,
      trailer?.nrRej,
      trailer?.uwagi,
      truck?.nrRej,
      truck?.uwagi,
      transport.uwagi
    ].filter((value) => !!value);

    return JSON.stringify(searchableFields);
  }

  isMatching(transport: ITransportEntity): boolean {
    if (this.searchText.length > 0) {
      return Strings.searchTextIgnoreAccents(
        this.searchText,
        SearchTextFilter.createSearchableStringFrom(transport)
      ) !== -1;
    }
    return true;
  }
}
