import { FormControl, Validators } from '@angular/forms';
import { merge, Observable, of } from 'rxjs';
import { DefaultForm } from './default-form';
import { ParkingInfo } from '../../modules/transports/interfaces';

export class AssignParkingForm extends DefaultForm<{parking: ParkingInfo}> {
  constructor(model?: {parking: ParkingInfo} | null) {
    super({
      parking: new FormControl(null, {validators: [Validators.required]}),
    });

    if (model) {
      this.patchFromModel(model);
    }
    this.model = model;
  }

  parkingChanges(): Observable<string | null> {
    return merge(
      of(null),
      this.get('parking').valueChanges
    );
  }

  getParkingId(): number|null {
    const parking = this.get('parking').value as ParkingInfo|null;

    return parking?.id;
  }
}
