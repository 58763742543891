import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromRoot from '../../../app.reducer';
import * as FRAME from '../../../ngrx/frame.actions';
import * as TRANSPORT from '../ngrx/transport.actions';
import * as VEHICLE from '../../../ngrx/vehicle.actions';
import * as UI from '../../../ngrx/ui.actions';

@Component({
  selector: 'app-transit-entrypoint',
  templateUrl: './entrypoint.component.html',
  styleUrls: ['./entrypoint.component.scss']
})
export class EntrypointComponent {

  constructor(private store: Store<fromRoot.State>) {
    this.store.dispatch(UI.setSideLinks({links: []}));
    this.store.dispatch(FRAME.infoRequest());
    this.store.dispatch(TRANSPORT.loadRouteTemplatesRequest());
    this.store.dispatch(new VEHICLE.LoadWorkStatesRequest());
    this.store.dispatch(new VEHICLE.LoadVehicleStatesRequest());
    this.store.dispatch(new VEHICLE.LoadModelsRequest());
  }
}
