import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateFormat } from '../../../../../helpers/enum';

@Component({
  selector: 'app-track-information',
  templateUrl: './track-information.component.html',
  styleUrls: ['./track-information.component.scss']
})
export class TrackInformationComponent {

  public trackInfo = {};
  df = DateFormat;

  constructor(private dialogRef: MatDialogRef<TrackInformationComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
    this.trackInfo = data;
  }

}
