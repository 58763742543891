<div *ngIf="assignedParameters && assignedParameters.length > 0"
     fxFlex fxLayout="column" fxLayoutGap="8px">
  <div *ngFor="let param of assignedParameters" class="parameter"
       fxFlex fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="start center">
    <span>({{param.parametr.id}})</span>
    <span>{{param.parametr.parametr}}</span>
    <span fxFlex></span>
    <button mat-icon-button (click)="removeParam(param)">
      <mat-icon class="material-icons-outlined">delete</mat-icon>
    </button>
  </div>
</div>
