import { Pipe, PipeTransform } from '@angular/core';
import { IAlarmTransportowy, IAlarmTransportowyUproszczony } from '../../alerts/interfaces';

@Pipe({
  name: 'alertGetType'
})
export class AlertGetTypePipe implements PipeTransform {

  private static getNameByType(alarm: IAlarmTransportowyUproszczony | IAlarmTransportowy, type: string, kind: string): string {
    if (kind === 'type') {
      return type;
    }

    switch (type.toLowerCase()) {
      case 'driver':
        return alarm.daneKierowcy ?? 'Not recognized';

      case 'truck':
      case 'trailer':
      case 'semi-truck':
      case 'semi-trailer':
        return alarm.numerRej ?? 'XXX XXXXX';

      case 'terminal':
        return alarm.terminal.uwagi ?? '' + alarm.terminal.id;
    }
  }

  transform(value: IAlarmTransportowyUproszczony | IAlarmTransportowy, kind: string = 'type'): string {
    if (!value) {
      return 'Not recognized';
    }

    if (value.typPojazdu) {
      return AlertGetTypePipe.getNameByType(value, value.typPojazdu, kind);
    }

    if (value.alarmZeZgloszeniaKierowcy) {
      return AlertGetTypePipe.getNameByType(value, 'Driver', kind);
    }

    if (value.terminal) {
      return AlertGetTypePipe.getNameByType(value, 'Terminal', kind);
    }

    return 'Not recognized';
  }

}
