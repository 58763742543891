import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import moment from 'moment';
import sortBy from 'lodash/sortBy';

import { DefaultForm } from '../default-form';
import { ITransportAssignedDriverExtended, ITransportEntityExtended } from '../../../modules/transports/interfaces/transport-entity';
import { Kierowca } from '../../../modules/drivers/interfaces';
import { MomentHelper, TransportHelper } from '../../../helpers';
import { ICompanyRoute, ISaveTransitRequest } from '../../../modules/transports/interfaces';
import { ZestawTransportowyBase } from '../../dto/transportSets';
import { VehicleFromTransitPipe } from '../../../modules/shared/pipes';
import { IDriverEntityDTO } from '../../dto/transit';

export interface AddedDriver {
  fromDate: Date;
  toDate: Date;
  name: string;
  driver: IDriverEntityDTO;
  transportDriverId?: number;
}

export interface TransportFormRoute {
  assignedRoute?: ICompanyRoute;
  savedRoute: string | ICompanyRoute;
  onlyPrivate: boolean;
  readonly: boolean;
  type?: string;
  id: number;
}

export class CreateNewTransitForm extends DefaultForm<any> {
  constructor(controls?: any | null) {
    super({
      stepOne: new FormGroup({
        name: new FormControl(null, Validators.required),
        departureAndArrival: new FormGroup({
          fromDate: new FormControl(null, Validators.required),
          fromTime: new FormControl(null, Validators.required),
          toDate: new FormControl(null, Validators.required),
          toTime: new FormControl(null, Validators.required),
        }),
        set: new FormControl(null, Validators.required),
        notes: new FormControl(null),
        setObj: new FormControl(null, Validators.required)
      }),
      stepTwo: new FormGroup({
        firstDriver: new FormGroup({
          fromDate: new FormControl(null, Validators.required),
          toDate: new FormControl(null, Validators.required),
          name: new FormControl(null, Validators.required),
          driver: new FormControl(null, Validators.required),
          transportDriverId: new FormControl(null),
        }),
        secondDriver: new FormGroup({
          fromDate: new FormControl(null),
          toDate: new FormControl(null),
          driver: new FormControl(null),
          transportDriverId: new FormControl(null),
        }),
        addedDrivers: new FormArray([], {
          validators: [CreateNewTransitForm.savedDriversValidator]
        }),
      }),

      stepThree: new FormGroup({
        routeSections: new FormArray([]),
      }),
      stepFour: new FormGroup({
        dyspozytor: new FormControl(null),
        centrum: new FormControl(null),
        wlascicielTowaru: new FormControl(null),
        syrena: new FormControl(false),
        coldChain: new FormControl(null),
        geofencing: new FormControl(null),
        geofMetry: new FormControl(null, Validators.required),
        monitored: new FormControl(null),
      }),
    });

    if (controls) {
      this.patchFromModel(controls);
    }
  }

  private _transportId: number = null;

  private static savedDriversValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value.length < 1) {
      return {'driversNotSaved': true};
    }
    const drivers = (control.value as [])
      .filter(d => d['transportDriverId'] === null || d['transportDriverId'] === undefined)
    ;

    if (drivers.length > 0) {
      return {'driversNotSaved': true};
    }
    return null;
  }

  private static driverDateValidator(
    departureDateTime: Date,
    arrivalDateTime: Date
  ): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl) => {
      const fromDate = control.get('fromDate').value;
      const toDate = control.get('toDate').value;
      const departureDate = departureDateTime;
      const arrivalDate = arrivalDateTime;

      const unixFrom = moment(fromDate).unix();
      const unixTo = moment(toDate).unix();
      const unixDeparture = moment(departureDate).unix();
      const unixArrival = moment(arrivalDate).unix();

      const MINUTES_30 = 60 * 30;

      if (!departureDate || !arrivalDate) {
        return { emptyTransportDates: true };
      }

      if (!fromDate || !toDate) {
        return { emptyDates: true };
      }

      const delta = unixTo - unixFrom;

      if (delta < MINUTES_30) {
        if (delta < 0) {
          return { fromGreaterThanTo: true };
        }

        return { lessThan30Minutes: true };
      }

      if (
        unixFrom < unixDeparture ||
        unixTo > unixArrival ||
        unixFrom > unixArrival ||
        unixTo < unixDeparture
      ) {
        return { mismatchWithTransport: true };
      }

      return null;
    };
  }

  get transportId(): number {
    return this._transportId;
  }

  set transportId(id: number) {
    this._transportId = id;
  }

  get isDepartureValid(): boolean {
    return !(this.departureAndArrival.get('fromDate').value === null || this.departureAndArrival.get('fromTime').value === null);
  }

  get isArrivalValid(): boolean {
    return !(this.departureAndArrival.get('toDate').value === null || this.departureAndArrival.get('toTime').value === null);
  }

  get stepOne(): FormGroup {
    return this.get('stepOne') as FormGroup;
  }

  get stepTwo(): FormGroup {
    return this.get('stepTwo') as FormGroup;
  }

  get stepThree(): FormGroup {
    return this.get('stepThree') as FormGroup;
  }

  get stepFour(): FormGroup {
    return this.get('stepFour') as FormGroup;
  }

  getStepFourControl(name: string): FormControl {
    return this.stepFour.get(name) as FormControl;
  }

  get addedDrivers(): FormArray {
    return this.get('stepTwo').get('addedDrivers') as FormArray;
  }

  get secondDriver(): FormGroup {
    return this.get('stepTwo').get('secondDriver') as FormGroup;
  }

  get departureAndArrival(): FormGroup {
    return this.get('stepOne').get('departureAndArrival') as FormGroup;
  }

  get departureDateTimeString(): string {
    return this.departureDateTime.toISOString();
  }

  get departureDateTime(): Date {
    return MomentHelper.datetimeForGivenDateAndTime(
      this.departureAndArrival.get('fromDate').value,
      this.departureAndArrival.get('fromTime').value,
    );
  }

  get arrivalDateTimeString(): string {
    return this.arrivalDateTime.toISOString();
  }

  get arrivalDateTime(): Date {
    return MomentHelper.datetimeForGivenDateAndTime(
      this.departureAndArrival.get('toDate').value,
      this.departureAndArrival.get('toTime').value,
    );
  }

  get transset(): FormControl {
    return this.get('stepOne').get('set') as FormControl;
  }

  get transsetObj(): FormControl {
    return this.get('stepOne').get('setObj') as FormControl;
  }

  get routeSections(): FormArray {
    return this.get('stepThree').get('routeSections') as FormArray;
  }

  patchFromTransportEntity(transport: ITransportEntityExtended, routes?: ICompanyRoute[]): void {
    let departureAndArrival = {
      fromDate: new Date(transport.czasWyjazdu),
      fromTime: new Date(transport.czasWyjazdu),
      toDate: null,
      toTime: null
    };

    if (transport.planowanyCzasPrzyjazdu || transport.czasPrzyjazdu) {
      departureAndArrival = {
        ...departureAndArrival,
        toDate: new Date(transport.planowanyCzasPrzyjazdu ?? transport.czasPrzyjazdu),
        toTime: new Date(transport.planowanyCzasPrzyjazdu ?? transport.czasPrzyjazdu)
      };
    }
    this.stepOne.patchValue({
      name: transport.uwagi,
      departureAndArrival,
      notes: transport.notatki
    });

    this.stepFour.patchValue({
      dyspozytor: transport.dyspozytor,
      centrum: transport.centrum,
      wlascicielTowaru: transport.wlascicielTowaru,
      syrena: transport.syrena,
      coldChain: transport.coldChain,
      geofencing: transport.geofencing,
      geofMetry: transport.geofMetry,
      monitored: transport.monitored,
    });

    if (transport.przekazDoCm || transport.rozpoczety) {
      this.stepFour.disable();
    }

    if (!!transport?.zestaw) {
      this.patchFromTransportSet(transport.zestaw);
    }
    this._transportId = transport.id;

    this.patchDrivers(transport?.kierowcaPrzejazdList);

    this.patchRoutes(routes);

    if (transport?.zakonczony) {
      this.disable();
    }
  }

  patchFromTransportSet(set: ZestawTransportowyBase): void {
    const trailer = VehicleFromTransitPipe.transform(set.trailerSet);
    const truck = VehicleFromTransitPipe.transform(set.truckSet);

    this.transset.patchValue(TransportHelper.getVehicleDescription(truck) + ', ' + TransportHelper.getVehicleDescription(trailer));
    this.transsetObj.patchValue(set);
  }

  addDriver(driverInfo?: Partial<AddedDriver>): number {
    const {driver, ...driverInTransit} = driverInfo ?? {driver: null};

    let fromDate = new Date(driverInTransit?.fromDate);
    let toDate = new Date(driverInTransit?.toDate);

    if (!driver) {
      fromDate = new Date(this.stepOne.get('departureAndArrival').get('fromDate').value);
      toDate = new Date(this.stepOne.get('departureAndArrival').get('toDate').value);
    }
    this.addedDrivers.push(new FormGroup({
      fromDate: new FormControl(fromDate, Validators.required),
      toDate: new FormControl(toDate, Validators.required),
      driver: new FormControl(driver, Validators.required),
      transportDriverId: new FormControl(driverInfo?.transportDriverId),
    }, {validators: [CreateNewTransitForm.driverDateValidator(this.departureDateTime, this.arrivalDateTime)]}));

    return this.addedDrivers.length - 1;
  }

  addRouteSection(route?: TransportFormRoute): number {
    this.routeSections.push(new FormGroup({
      assignedRoute: new FormControl(route?.assignedRoute, Validators.required),
      savedRoute: new FormControl(route?.savedRoute, Validators.required),
      onlyPrivate: new FormControl(route?.onlyPrivate ?? false),
      readonly: new FormControl(route?.readonly ?? false),
      type: new FormControl(route?.type ?? 'new'),
      id: new FormControl(route?.id)
    }));

    return this.routeSections.length - 1;
  }

  removeDriver(idx: number) {
    this.addedDrivers.removeAt(idx);
  }

  removeRouteSectionByIdx(idx: number) {
    this.routeSections.removeAt(idx);
  }

  getDriverByIdx(idx: number): FormGroup {
    return this.addedDrivers.controls[idx] as FormGroup;
  }

  patchDriverByIdx(idx: number, driver: Kierowca): void {
    if (driver) {
      const name = driver._uzytkownik.imie + ' ' + driver._uzytkownik.nazwisko;
      this.addedDrivers.controls[idx].patchValue({driver, name});
    } else {
      this.addedDrivers.controls[idx].patchValue({driver});
    }
  }

  /**
   * @param idx index of control
   * @param type values: [savedRoute, departureSpot, arrivalSpot]
   */
  routeSectionByIdxType(idx: number, type: string): FormControl {
    return this.routeSections.controls[idx].get(type) as FormControl;
  }

  markStepAsTouched(stepName: string) {
    (this.controls[stepName] as FormGroup).markAllAsTouched();
  }

  getTransitRequestValue(userId?: number): { transit: ISaveTransitRequest, addedDrivers: AddedDriver[] } {
    if (userId) {
      return {
        transit: {...this.getTransitValue(), dyspozytor: {id: userId}},
        addedDrivers: this.addedDrivers.value
      };
    }
    return {
      transit: this.getTransitValue(),
      addedDrivers: this.addedDrivers.value
    };

  }

  getTransitValue(): ISaveTransitRequest {
    const date = this.stepOne.get('departureAndArrival').value;
    const fromMoment = MomentHelper.datetimeForGivenDateAndTime(date.fromDate, date.fromTime).toISOString();
    const toMoment = MomentHelper.datetimeForGivenDateAndTime(date.toDate, date.toTime).toISOString();
    const transit: ISaveTransitRequest = {
      zestaw: Object.assign(this.transsetObj.value),
      czasWyjazdu: fromMoment,
      planowanyCzasPrzyjazdu: toMoment,
      notatki: this.stepOne.get('notes').value,
      uwagi: this.stepOne.get('name').value,
    };
    if (this._transportId !== null) {
      transit.id = this._transportId;
    }

    return transit;
  }

  reset(value?: any, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.reset(value, options);
    this.routeSections.clear();
    this.addedDrivers.clear();
  }

  private patchDrivers(transitDrivers?: ITransportAssignedDriverExtended[]) {
    if (!transitDrivers) {
      return;
    }
    const addedDrivers = sortBy([...transitDrivers], ['planowanyCzasOd', 'planowanyCzasDo', 'id'])
      .map((driverData) => {
        const {idKierowca, ...driverInTransit} = driverData;
        const name = idKierowca.uzytkownik ? `${idKierowca.uzytkownik.imie} ${idKierowca.uzytkownik.nazwisko}` : '';
        const fromDate = !!driverInTransit?.planowanyCzasOd ? new Date(driverInTransit.planowanyCzasOd) : null;
        const toDate = !!driverInTransit?.planowanyCzasDo ? new Date(driverInTransit.planowanyCzasDo) : null;

        return {
          fromDate,
          toDate,
          name,
          driver: idKierowca,
          transportDriverId: driverInTransit.id
        } as AddedDriver;
      });

    addedDrivers.forEach((driver) => {
      this.addDriver(driver);
    });
  }

  private patchRoutes(routes?: ICompanyRoute[]) {
    if (!routes) {
      return;
    }
    routes
      .map(r => {
        return {
          assignedRoute: r,
          savedRoute: r.punkty,
          onlyPrivate: false,
          readonly: r.z_szablonu,
          id: r.id,
          type: 'saved'
        } as TransportFormRoute;
      })
      .forEach((routeSection) => {
        this.addRouteSection(routeSection);
      });
  }
}
