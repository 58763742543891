<div class="mat-elevation-z8" *ngIf="alert">
  <cdk-virtual-scroll-viewport tvsItemSize="48" headerHeight="56" class="virtual-scroll_">
    <table mat-table matSort multiTemplateDataRows class="mat-elevation-z8"
           [dataSource]="list">
      <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns">
        <th mat-header-cell *matHeaderCellDef [mat-sort-header]="column">{{ column | alertColHeader }}</th>
        <td mat-cell
            *matCellDef="let element"
            [ngClass]="{'td-time': column === 'czas'}">
          <p [ngSwitch]="column">
            <span *ngSwitchCase="'date'">{{ element?.czas | date : df.PIPE_DATETIME_LONGER_LABEL }}</span>
            <span *ngSwitchCase="'statusChange'">
                <span *ngIf="element?.statusZk; else notDriverReportCase">{{ element.statusZk?.status }}</span>
                <ng-template #notDriverReportCase>
                  <span>{{ element?.status }}</span>
                </ng-template>
              </span>
            <span *ngSwitchCase="'by'">
                <span *ngIf="element?.utwPrzez">{{ element.utwPrzez }}</span>
                <span *ngIf="element?.idKierowca && driversList">
                  {{ findDriverById(element.idKierowca)?._uzytkownik?.imie }} {{ findDriverById(element.idKierowca)?._uzytkownik?.nazwisko }}
                </span>
                <span *ngIf="element?.liczbaZd">Vehicle event with id: {{ element?.id }} </span>
                <span *ngIf="element?.zakPrzez">{{ element.zakPrzez }}</span>
                <span *ngIf="element?.przypisanyPrzez">{{ element.przypisanyPrzez }}</span>
              </span>
            <span *ngSwitchCase="'notes'">
                <span *ngIf="element?.statusZk && element?.statusZk?.opis">{{ element.statusZk.opis }}</span>
                <span *ngIf="element?.uwagi"><span>{{ element.uwagi }}</span></span>
                <span *ngIf="element?.liczbaZd || element?.przypisanyPrzez">
                  <span>{{ element?.opis }}</span>
                </span>
              </span>
            <span *ngSwitchDefault>{{ element[column] }}</span>
          </p>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

      <tr mat-row
          *matRowDef="let element; columns: displayedColumns;"
          class="element-row">
      </tr>
    </table>
  </cdk-virtual-scroll-viewport>

</div>
