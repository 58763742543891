import { Injectable } from '@angular/core';
import { AuthEffects } from './ngrx/auth.effects';
import { AdminEffects } from './ngrx/admin.effects';
import { UiEffects } from './ngrx/ui.effects';
import { VehicleEffects } from './ngrx/vehicle.effects';
import { TerminalEffects } from './ngrx/terminal.effects';
import { MapEffects } from './ngrx/map.effects';
import { UserEffects } from './ngrx/user.effects';
import { GeneralEffects } from './ngrx/general.effects';
import { TranssetEffects } from './modules/transport-set/ngrx/transset.effects';
import { FrameEffects } from './ngrx/frame.effects';

@Injectable()
export class AppEffects {
  // constructor(private actions$: Actions) {
  // }
}

export const effects: any[] = [
  AppEffects, AuthEffects, UiEffects, VehicleEffects, TerminalEffects,
  MapEffects, UserEffects, TranssetEffects, GeneralEffects, AdminEffects, FrameEffects
];
