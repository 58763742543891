import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'onlyActive'
})
export class OnlyActivePipe implements PipeTransform {

  transform(value: any[], field: string): any[] {
    return [...value.filter(v => v[field] === true)];
  }

}
