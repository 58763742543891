import { Component, Input, OnDestroy } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { debounceTime, filter, map, tap } from 'rxjs/operators';

import * as fromRoot from '../../../../app.reducer';
import * as UI from '../../../../ngrx/ui.actions';

import { GenericNavItem } from '../../../../models/dto';
import { SecurityHelper, Strings } from '../../../../helpers';

@Component({
  selector: 'app-floating-action-menu',
  templateUrl: './floating-action-menu.component.html',
  styleUrls: ['./floating-action-menu.component.scss']
})
export class FloatingActionMenuComponent implements OnDestroy {
  @Input() iconName = 'more_vert';
  @Input() menuType = 'SIDE';
  @Input() mainColor = 'primary';

  menuItems$: Observable<GenericNavItem[]>;
  hash = '';

  constructor(private store: Store<fromRoot.State>) {
    this.store.dispatch(UI.switchSideMenu({visible: false}));
    this.menuItems$ = combineLatest([
      this.store.select(fromRoot.selectors.ui.getSideMenuLinks),
      this.store.select(fromRoot.selectors.auth.getAuthIsAuthenticated)
    ])
      .pipe(
        debounceTime(300),
        filter(r => this.hash !== Strings.getObjectHash(r[0])),
        map(([links, _]) => SecurityHelper.filterLinks([...links])),
        tap(r => this.hash = Strings.getObjectHash(r)),
      );
  }

  ngOnDestroy() {
    this.store.dispatch(UI.switchSideMenu({visible: true}));
  }

}
