import { Pipe, PipeTransform } from '@angular/core';
import { IAlarmPodstawowy } from '../../../models/dto/alert/alarm-transportowy';
import { MomentHelper, TransportAlert } from '../../../helpers';

@Pipe({
  name: 'sortTicketAlerts'
})
export class SortTicketAlertsPipe implements PipeTransform {

  transform(value: IAlarmPodstawowy[]): IAlarmPodstawowy[] {
    return [...value].sort((a, b) => {
      const isPendingA = TransportAlert.activeAlertStatusList.includes(a.idStatusAl);
      const isPendingB = TransportAlert.activeAlertStatusList.includes(b.idStatusAl);
      if (isPendingA !== isPendingB) {
        return isPendingA ? -1 : 1;
      }

      if (!a.czasZakonczenia && !b.czasZakonczenia) {
        return MomentHelper.compareDates(a.czasRozpoczecia, b.czasRozpoczecia, 'desc');
      }

      if (a.czasZakonczenia && b.czasZakonczenia) {
        return MomentHelper.compareDates(a.czasRozpoczecia, b.czasRozpoczecia, 'desc');
      }

      if (a.czasZakonczenia) {
        return 1;
      }

      return -1;
    });
  }

}
