import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import * as DRIVER_REPORTS from './driver-reports.actions';
import * as UI from '../../../ngrx/ui.actions';
import * as fromRoot from '../../../app.reducer';
import { DriverReportsService } from '../services/driver-reports.service';
import { Messages } from '../../../helpers/enum';

@Injectable()
export class DriverReportsEffects {

  loadAllDriversReports$ = createEffect(() => this.actions.pipe(
    ofType(DRIVER_REPORTS.loadAllDriverReportsRequest),
    exhaustMap(() => {
      return this.driverReportsService.getAllDriverReports().pipe(
        map(driverReports => DRIVER_REPORTS.loadAllDriverReportsSuccess({driverReports})),
        catchError(() => {
          this.store.dispatch(DRIVER_REPORTS.loadAllDriverReportsFailed());
          return of(UI.userError({message: Messages.READING_DATA_ERR}));
        }),
      );
    })
  ));

  loadDriverReportsByTypeId$ = createEffect(() => this.actions.pipe(
    ofType(DRIVER_REPORTS.loadDriverReportsByTypeIdRequest),
    exhaustMap(({reportTypeId}) => {
      return this.driverReportsService.getDriverReportsByTypeId(reportTypeId).pipe(
        map(driverReports => DRIVER_REPORTS.loadDriverReportsByTypeIdSuccess({driverReports})),
        catchError(() => {
          this.store.dispatch(DRIVER_REPORTS.loadDriverReportsByTypeIdFailed());
          return of(UI.userError({message: Messages.READING_DATA_ERR}));
        }),
      );
    })
  ));

  constructor(
    private actions: Actions,
    private store: Store<fromRoot.State>,
    private driverReportsService: DriverReportsService,
  ) {
  }
}
