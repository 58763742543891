<div class="filter-and-switcher" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
  <mat-form-field class="filter-form-field">
    <mat-label>Type to search...</mat-label>
    <input matInput [formControl]="searchControl" name="fdw-search-transport">
    <mat-icon matSuffix class="filter-icon">search</mat-icon>
  </mat-form-field>

  <mat-form-field class="filter-form-field">
    <mat-label>Transport state</mat-label>
    <mat-select [formControl]="transportProgressControl">
      <mat-option [value]="eTransportProgress.not_finished">Not finished</mat-option>
      <mat-option [value]="eTransportProgress.not_started">Not started</mat-option>
      <mat-option [value]="eTransportProgress.started">Started</mat-option>
      <mat-option [value]="eTransportProgress.finished">Finished</mat-option>
    </mat-select>
  </mat-form-field>

  <div *appHasPrivileges="[ePrivs.ADM_TRANSITS, ePrivs.EDIT_TRANSIT]">
    <button mat-raised-button color="primary" routerLink="/transport/new-transport">New transport</button>
  </div>
  <span fxFlex></span>
  <div class="view-switcher">
    <span class="pointer" (click)="tileView()">
      <mat-icon class="apps {{viewMode$ | async}}">apps</mat-icon>
    </span>
    <span class="pointer" (click)="tableView()">
      <mat-icon class="menu {{viewMode$ | async}}">menu</mat-icon>
    </span>
  </div>

</div>
