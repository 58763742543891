import * as CryptoJS from 'crypto-js';
import {
  AuthActions,
  BROWSER_APPROVED,
  BROWSER_CONFIRMED,
  BROWSER_NOT_CONFIRMED,
  CHANGE_USER_COMPANY_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGIN_USER_DATA,
  LOGOUT_SUCCESS,
  PIN_FAILURE,
  PIN_REQUEST,
  PIN_SUCCESS,
  REFRESH_TOKEN_SUCCESS,
} from './auth.actions';
import { AuthStep } from 'src/app/models/dto/user';
import { JwtBodyData } from 'src/app/models/dto';
import { AppUserInfo } from '../models/authentication';
import { JwtHelper } from '../helpers/jwt.helper';
import { CommonDirective } from '../modules/access-control/directives/common.directive';

export interface State {
  isAuthenticated: boolean;
  isBrowserConfirmed: boolean;
  browserId: string;
  appUserInfo: AppUserInfo;
  loginState: AuthStep;
  userToken: string;
  userTokenDetails: JwtBodyData;
}

export const initialState: State = {
  isAuthenticated: false,
  isBrowserConfirmed: false,
  browserId: null,
  appUserInfo: null,
  loginState: {step: 1, info: 'init'},
  userToken: null,
  userTokenDetails: null
};

export function authReducer(state = initialState, action: AuthActions) {
  switch (action.type) {
    case LOGIN_USER_DATA:
      const newAppUser2: AppUserInfo = {
        ...state.appUserInfo,
        name: action.payload.imie,
        surname: action.payload.nazwisko,
        email: action.payload.email,
        role: action.payload._rola
      };

      return {...state, appUserInfo: newAppUser2};

    case LOGIN_SUCCESS:
      const token = action.payload['token'];
      const uLogin = action.payload['login'];

      const tokenBody = JwtHelper.decode(token);
      CommonDirective.userTokenDetails = tokenBody;

      const newAppUser: AppUserInfo = {
        ...state.appUserInfo, login: uLogin,
        companyId: tokenBody.jti,
        userId: tokenBody.sub
      };

      let loginNextStep1 = {step: 2, info: 'pin'};
      let isAuthenticated = state.isAuthenticated;
      if (state.isBrowserConfirmed) {
        loginNextStep1 = {step: 3, info: 'ok'};
        isAuthenticated = true;
      }

      localStorage.setItem(
        'fdwud',
        CryptoJS.AES.encrypt(JSON.stringify({user: newAppUser, token: token}), state.browserId).toString()
      );

      return {
        ...state
        , appUserInfo: newAppUser
        , userToken: token
        , userTokenDetails: tokenBody
        , loginState: loginNextStep1
        , isAuthenticated: isAuthenticated
      };

    case PIN_REQUEST:
      return {...state, loginState: {...state.loginState, step: 2, info: 'pin'}};

    case PIN_SUCCESS:
      localStorage.setItem('fdwgd', state.browserId);
      const loginNextStep2 = {...state.loginState, step: 3, info: 'ok'};
      return {...state, isBrowserConfirmed: true, isAuthenticated: true, loginState: loginNextStep2};

    case BROWSER_APPROVED:
      localStorage.setItem('fdwgd', state.browserId);
      return {...state, isBrowserConfirmed: true};

    case BROWSER_CONFIRMED:
      return {...state, isBrowserConfirmed: true, browserId: action.payload};

    case BROWSER_NOT_CONFIRMED:
      return {...state, isBrowserConfirmed: false, browserId: action.payload};

    case PIN_FAILURE:
    case LOGIN_FAILURE:
      return {...initialState, browserId: state.browserId};

    case LOGOUT_SUCCESS:
      CommonDirective.userTokenDetails = null;
      return {
        ...initialState,
        browserId: state.browserId,
        isBrowserConfirmed: state.isBrowserConfirmed
      }

    case CHANGE_USER_COMPANY_SUCCESS:
      const newAppUser3: AppUserInfo = {
        ...state.appUserInfo,
        companyId: action.payload.toString()
      };
      return {...state, appUserInfo: newAppUser3};

    case REFRESH_TOKEN_SUCCESS:
      if (action.payload && action.payload['token'] && !JwtHelper.isTokenExpired(action.payload['token'])) {
        const details = JwtHelper.decode(action.payload['token']);

        localStorage.setItem(
          'fdwud',
          CryptoJS.AES.encrypt(JSON.stringify({user: state.appUserInfo, token: action.payload['token']}), state.browserId).toString()
        );

        return {...state, userToken: action.payload['token'], userTokenDetails: details};
      }
      return state;

    default:
      return state;
  }
}

export const getIsAuth = (state: State) => state.isAuthenticated;
export const getUserInfo = (state: State) => state.appUserInfo;
export const getIsBrowserConfirmed = (state: State) => state.isBrowserConfirmed;
export const getAuthStep = (state: State) => state.loginState;
export const getBrowserId = (state: State) => state.browserId;
export const getAuthToken = (state: State) => state.userToken;
export const getAuthTokenDetails = (state: State) => state.userTokenDetails;
