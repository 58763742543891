import { Component, Input } from '@angular/core';
import { ITransportEntityExtended } from '../../../interfaces/transport-entity';
import { ParkingInfo } from '../../../interfaces';

@Component({
  selector: 'app-transport-monitoring',
  templateUrl: './transport-monitoring.component.html',
  styleUrls: ['./transport-monitoring.component.scss'],
})
export class TransportMonitoringComponent {
  @Input() transport: ITransportEntityExtended;
  @Input() parkingLots: ParkingInfo[] = [];
  isHidden = false;

  constructor() {}

  toggle() {
    this.isHidden = !this.isHidden;
  }
}
