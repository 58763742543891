import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import * as fromRoot from '../../../../../app.reducer';
import * as TRANSPORT from '../../../ngrx/transport.actions';
import { ITransportEntity } from '../../../interfaces/transport-entity';
import { Strings } from '../../../../../helpers';

@Component({
  selector: 'app-transit-monitor-params',
  templateUrl: './transit-monitor-params.component.html',
  styleUrls: ['./transit-monitor-params.component.scss']
})
export class TransitMonitorParamsComponent implements OnInit, OnDestroy {
  static readonly myname = Strings.getObjectHash('TransitMonitorParamsComponent');
  subs = new Subscription();
  transit: ITransportEntity;

  constructor(private store: Store<fromRoot.State>) {
  }

  ngOnInit(): void {
    this.subs.add(
      this.store.select(fromRoot.selectors.ui.getRightDrawerInfo)
        .pipe(
          filter(r => r.componentData !== undefined),
          filter(data => (data.componentName !== undefined && data.componentName === TransitMonitorParamsComponent.myname)
            || data.isOpened === false || this.transit.id !== data.componentData.transit.id),
          map(data => data.componentData as { transit: ITransportEntity }),
        )
        .subscribe((data) => {
          this.transit = data.transit;
          this.loadParams();
        })
    );
  }

  loadParams(): void {
    if (this.transit && this.transit.id) {
      this.store.dispatch(TRANSPORT.loadAvailableMonitParamsRequest({transitId: this.transit.id}));
      this.store.dispatch(TRANSPORT.loadAssignedMonitParamsRequest({transitId: this.transit.id}));
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
