import { createAction, props } from '@ngrx/store';
import { IAwar, ICn, ICs, IInfo, IRygiel, ISat, ISatJ, ISatS } from '../models/frame';

export const infoRequest = createAction('[FRAMES] Load info - request');
export const rygielRequest = createAction('[FRAMES] Load rygiel - request');
export const awarRequest = createAction('[FRAMES] Load awar - request');
export const cnRequest = createAction('[FRAMES] Load cn - request');
export const csRequest = createAction('[FRAMES] Load cs - request');
export const satRequest = createAction('[FRAMES] Load sat - request');
export const satSRequest = createAction('[FRAMES] Load sat_s - request');
export const satJRequest = createAction('[FRAMES] Load sat_j - request');


export const infoSuccess = createAction(
  '[FRAMES] Load info - success',
  props<{ frames: IInfo[] }>()
);
export const rygielSuccess = createAction(
  '[FRAMES] Load rygiel - success',
  props<{ frames: IRygiel[] }>()
);
export const awarSuccess = createAction(
  '[FRAMES] Load awar - success',
  props<{ frames: IAwar[] }>()
);
export const cnSuccess = createAction(
  '[FRAMES] Load cn - success',
  props<{ frames: ICn[] }>()
);
export const csSuccess = createAction(
  '[FRAMES] Load cs - success',
  props<{ frames: ICs[] }>()
);
export const satSuccess = createAction(
  '[FRAMES] Load cs - success',
  props<{ frames: ISat[] }>()
);
export const satSSuccess = createAction(
  '[FRAMES] Load cs - success',
  props<{ frames: ISatS[] }>()
);
export const satJSuccess = createAction(
  '[FRAMES] Load cs - success',
  props<{ frames: ISatJ[] }>()
);
