<div
  [ngClass]="{
    'background-pink-blink': !alertIsTerminated(alert) && !alertHasOwner(alert) && (alert | isNewAlert | async)
  }"
  class="tile-container {{type}}"
  fxLayout="row"
  fxLayoutAlign="start start">

  <div class="notifications-column">
    <div class="notifications" fxLayout="row" fxLayoutAlign="center center"
         [ngClass]="{
           'light-pink-background': !alertIsTerminated(alert) && alertHasOwner(alert),
            'light-grey-background': alertIsTerminated(alert)
          }">
      <mat-icon class="notifications-icon" [ngClass]="{'red-icon': alertHasOwner(alert)}">
        notifications
      </mat-icon>
    </div>
    <mat-icon *ngIf="showOnMapIcon()" [matTooltip]="getActionIconTooltip()"
              class="material-icons-outlined goto-map"
              (click)="getActionIconRoute()" [innerHTML]="getActionIcon()">
    </mat-icon>
  </div>

  <div class="alert-info" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="0.25rem">
    <p class="id">Alert no.: {{alert.id}}</p>
    <p class="too-long-phrase registration-number"
       [matTooltip]="alert | alertGetType : 'name'">{{ alert | alertGetType : 'name' }}</p>
    <p class="alert-type">{{ alert | alertGetType }}</p>
    <p class="alert-trigger mat-body-2" fxLayout="row" fxLayoutAlign="start">
      <span class="too-long-phrase for-tile" #alertDescrRef [matTooltip]="tooltipAlertDescription"
            matTooltipClass="tooltip-alert" matTooltipPosition="after">
            {{ alert.rodzajAlarmu.rodzaj }}
      </span>
    </p>
    <p class="alert-state" fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start">
      <span class="mat-body-2">Risk assessment</span>
      <span class="mat-body-2">{{ alert.aktOcenaRyzyka }}</span>
    </p>
    <div class="date-and-time" fxLayout="row">
      <p class="date-container" fxFlex fxLayout="row" fxLayoutGap="4px">
        <span class="mat-body-2 date">Date</span>
        <span class="mat-body-2"
              [matTooltip]="alert.czas | date : df.PIPE_LONGER_24H">{{ alert.czas | date : df.PIPE_DATETIME_LABEL }}</span>
      </p>
    </div>
    <div class="alert-owner" *ngIf="type !== 'compact'">
      <div fxLayout="column" fxLayoutGap="8px">
        <div fxLayout="row" fxLayoutAlign="start">
          <div *ngIf="alert.dyspozytor === null" fxLayout="row" fxLayoutAlign="center center">
            <p class="identity-icon-container" fxLayout="row" fxLayoutAlign="center center">
              <mat-icon mat-card-avatar class="identity-icon">perm_identity</mat-icon>
            </p>
            <span class="mat-body-2">No owner</span>
          </div>
          <p *ngIf="alert.dyspozytor !== null"
             class="owner-container" fxLayout="row" fxLayoutAlign="center center">
            <span mat-card-avatar class="dispatcher-image"></span>
            <span class="too-long-phrase for-tile" #alertOwnerRef [matTooltip]="tooltipAlertOwner"
                  matTooltipClass="tooltip-alert" matTooltipPosition="after">
                   {{ alert.dyspozytor?.imie }} {{ alert.dyspozytor?.nazwisko }}
             </span>
          </p>
        </div>
        <p *ngIf="alert.dyspozytor && !alertIsTerminated(alert)" class="mat-button call-to-action">Change owner</p>
        <p *ngIf="!alert.dyspozytor && !alertIsTerminated(alert)" class="mat-button call-to-action">Assign an owner</p>
        <p *ngIf="alertIsTerminated(alert)" class="mat-button call-to-action">Terminated alarm</p>
      </div>
    </div>
  </div>

</div>
