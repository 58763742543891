import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { WlascicielTowaru } from '../../../../models/dto/transportSets';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, startWith } from 'rxjs/operators';

import * as fromRoot from '../../../../app.reducer';
import * as TRANSPORT from '../../ngrx/transport.actions';
import { removeCargoOwnerRequest } from '../../ngrx/transport.actions';
import * as fromTransport from '../../ngrx/transport.reducer';
import * as UI from '../../../../ngrx/ui.actions';

import { RightDrawerService } from '../../../shared/services';
import { Strings } from '../../../../helpers';
import { BaseYesNoConfig } from '../../../shared/interfaces';
import { BaseYesNoDialogComponent } from '../../../shared/dialogs';
import { CargoOwnerComponent } from '../../components';
import { ToastType } from '../../../../helpers/enum';
import { TransportMsg } from '../../../../messages';

@Component({
  selector: 'app-cargo-owner-list',
  templateUrl: './cargo-owner-list.component.html',
  styleUrls: ['./cargo-owner-list.component.scss']
})
export class CargoOwnerListComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new MatTableDataSource<WlascicielTowaru>([]);
  displayedColumns: string[] = ['id', 'nazwa', 'telefon', 'kontakt', 'uwagi', 'inne'];
  formSearch = new FormControl();
  subs = new Subscription();

  cargoOwnerList: WlascicielTowaru[] = [];

  constructor(
    private store: Store<fromRoot.State>,
    private rightDrawer: RightDrawerService,
    private dialog: MatDialog) {
    this.store.dispatch(TRANSPORT.loadCargoOwnersRequest());
  }

  private static filter(owner: WlascicielTowaru, term: string): boolean {
    if (term.trim().length < 1) {
      return true;
    }

    term = term.trim()
      .replace(/\s+/, ' ');
    const telefonClean = owner.telefon.replace(/[()-.,]+/gi, ' ').replace(/\s+/gi, ' ');

    const searchField = owner.nazwa + owner.kontakt + owner.telefon + telefonClean + owner.uwagi;
    return Strings.searchTextIgnoreAccents(term, searchField) >= 0;
  }

  ngOnInit(): void {
    this.subs.add(
      this.store.select(fromTransport.getCargoOwners)
        .subscribe(r => {
          this.cargoOwnerList = r;
          this.dataSource.data = [...this.cargoOwnerList]
            .filter(owner => CargoOwnerListComponent.filter(owner, this.formSearch.value ?? ''));
          this.dataSource.sort = this.sort;
        })
    );

    this.formSearch.valueChanges
      .pipe(
        startWith(''),
        debounceTime(250)
      )
      .subscribe(r => {
        this.dataSource.data = [...this.cargoOwnerList].filter(punkt => CargoOwnerListComponent.filter(punkt, r));
      });
  }

  edit(owner: WlascicielTowaru) {
    this.rightDrawer.openOver(CargoOwnerComponent, {owner});
  }

  createNew() {
    this.rightDrawer.close();
    setTimeout(() => {
      this.rightDrawer.openOver(CargoOwnerComponent);
    });
  }

  remove(owner: WlascicielTowaru) {
    this.rightDrawer.close();
    const config: BaseYesNoConfig = {
      title: 'Cargo owner deletion',
      content: `Are you sure you want to delete this item?`,
      yesAction: () => {
        this.store.dispatch(removeCargoOwnerRequest({
          owner, callback: () => {
            this.rightDrawer.close();
            this.store.dispatch(UI.showUserMessage({message: {type: ToastType.WARN, message: TransportMsg.CARGO_OWN_REMOVED}}));
          }
        }));
      },
      yesLabel: 'Delete',
      yesColor: 'warn',
      noLabel: 'Cancel',
      noColor: 'primary',
      autoClosure: true,
    };
    this.dialog.open(BaseYesNoDialogComponent, {
      data: config,
      id: 'BaseYesNoDialogComponent-ShippingPoint',
      position: {top: '7%'}
    });
  }
}
