import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yesNo'
})
export class YesNoPipe implements PipeTransform {

  transform(value: any, args: string = ''): any {

    let prefix = '';
    if (args === 'class') {
      prefix = 'myboolpipe-';
    }

    return value ? prefix + 'Yes' : prefix + 'No';
  }

}
