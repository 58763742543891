import get from 'lodash/get';
import { SelectOption } from '../interfaces';

export class FormHelper {

  static makeSelectLovWithSub<T>(
    values: T[],
    labelPaths: string[] | string,
    subPaths: string[] | string,
    joinChar = ' ',
    isDisabled?: (T) => boolean): SelectOption<T>[] {

    return FormHelper
      .makeSelectLov(values, labelPaths, joinChar, isDisabled)
      .map(selectOption => {
        const subLabel = FormHelper.createLabel(selectOption.value, subPaths, joinChar);

        return {...selectOption, subLabel};
      });
  }

  static makeSelectItemWithSub<T>(value: T, labelPaths: string[] | string, subPaths: string[] | string, joinChar = ' ',
                                  isDisabled?: (T) => boolean): SelectOption<T> | undefined {
    if (!value) {
      return undefined;
    }

    const item = FormHelper.makeSelectItem(value, labelPaths, joinChar, isDisabled);
    const subLabel = FormHelper.createLabel(item.value, subPaths, joinChar);
    return {...item, subLabel};
  }

  static makeSelectItem<T>(value: T, labelPaths: string[] | string, joinChar = ' ',
                           isDisabled?: (T) => boolean): SelectOption<T> | undefined {
    if (!value) {
      return undefined;
    }
    return {
      label: FormHelper.createLabel(value, labelPaths, joinChar),
      value: value,
      disabled: isDisabled ? isDisabled(value) : false
    };
  }

  static makeSelectLov<T>(values: T[], labelPaths: string[] | string, joinChar = ' ', isDisabled?: (T) => boolean): SelectOption<T>[] {
    return [...values].map(element => {
      return {
        label: FormHelper.createLabel(element, labelPaths, joinChar),
        value: element,
        disabled: isDisabled ? isDisabled(element) : false
      };
    });
  }

  private static createLabel<T>(element: T, labelPaths: string[] | string, joinChar: string): string {
    if (labelPaths instanceof Array) {
      return labelPaths.map(key => get(element, key, 'Not defined')).join(joinChar);
    } else {
      return get(element, labelPaths, 'Not defined');
    }
  }
}
