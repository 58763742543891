import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { MapRouting } from './map.routing';
import { MapMainComponent } from './main/map-main.component';
import { HereComponent } from './main/here/here.component';
import { ObjectInformationComponent } from './main/here/object-information/object-information.component';
import { CustomMaterialModule } from '../material.modules';
import {
  GeneralSearchRouteComponent,
  MapStylesAndColorsComponent,
  PendingTransportListComponent,
  SearchAndAssignRouteComponent,
  SearchRouteComponent,
  ShippingPointComponent,
  ShowTracksComponent,
  TransportAlertComponent,
  GeocodingSearchComponent,
  ViaPointsComponent
} from './components';
import { MY_DATE_FORMATS } from '../../helpers';
import { TrackInformationComponent } from './main/here/track-information/track-information.component';
import { LastTerminalPositionsComponent, TerminalTrackPointsComponent } from './main/here/helpers';
import { SharedModule } from '../shared/shared.module';
import { ViewerComponent } from './viewer/viewer.component';
import { AlertsModule } from '../alerts/alerts.module';
import { TransportModule } from '../transports/transport.module';
import { VehiclesModule } from '../vehicles/vehicles.module';
import { ShippingPointSearchComponent } from './components/shipping-point-search/shipping-point-search.component';

@NgModule({
  imports: [
    CommonModule,
    MapRouting,
    CustomMaterialModule,
    FormsModule,
    SharedModule,
    AlertsModule,
    TransportModule,
    ScrollingModule,
    VehiclesModule,
  ],
  declarations: [
    MapMainComponent,
    HereComponent,
    ObjectInformationComponent,
    ShowTracksComponent,
    SearchRouteComponent,
    TrackInformationComponent,
    LastTerminalPositionsComponent,
    TerminalTrackPointsComponent,
    MapStylesAndColorsComponent,
    GeneralSearchRouteComponent,
    SearchAndAssignRouteComponent,
    ShippingPointComponent,
    TransportAlertComponent,
    ViewerComponent,
    PendingTransportListComponent,
    GeocodingSearchComponent,
    ShippingPointSearchComponent,
    ViaPointsComponent,
  ],
  entryComponents: [ObjectInformationComponent, TrackInformationComponent],
  exports: [TransportAlertComponent],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
  ],
})
export class MapModule {}
