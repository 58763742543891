export enum DriversStatus {
  PERM_BLOCK = 0,
  READY = 1,
  SUSPENDED = 2,
  UNAVAILABLE = 3,
  DURING_TERMINATION = 4,
  FIRED = 5,
  REMOVED = 6,
}

export enum DriverStatusDetails {
  'Permanently blocked' = 0,
  'Ready' = 1,
  'Suspended in activities' = 2,
  'Temporarily unavailable' = 3,
  'On notice' = 4,
  'Fired' = 5,
}

