import { Action, createReducer, on } from '@ngrx/store';

import { GenericNavItem, RightDrawer } from '../models/dto';
import * as uiActions from './ui.actions';
import { SideLinkActionData } from '../models/helpers';
import { Strings } from '../helpers';
import { ViewMode } from '../helpers/enum';

export interface State {
  isLoading: boolean;
  navLinks: GenericNavItem[];
  sideMenuActive: boolean;
  map: {
    routeSearchRequests: number;
    routeSearchProcessingEvt: number;
  };
  sideLinkAction: SideLinkActionData;
  resizeWindowEvent: string;
  progressBarVisible: boolean;
  showLeftDrawer: boolean;
  rightDrawer: RightDrawer;
  alertViewMode: ViewMode;
  transportViewMode: ViewMode;
  ticketViewMode: ViewMode;
}

export const initialState: State = {
  isLoading: false,
  navLinks: [
    // {path: '/home', title: 'Home', menuType: 'TOP', position: 'LEFT'},
    {path: '/access/login', title: 'Sign in', menuType: 'TOP', position: 'RIGHT'}
  ],
  sideMenuActive: true,
  map: {
    routeSearchRequests: 0,
    routeSearchProcessingEvt: 0
  },
  sideLinkAction: null,
  resizeWindowEvent: null,
  progressBarVisible: false,
  showLeftDrawer: true,
  rightDrawer: {
    isOpened: false,
    componentName: undefined,
    componentData: undefined
  },
  alertViewMode: ViewMode.TABLE,
  transportViewMode: ViewMode.TABLE,
  ticketViewMode: ViewMode.TABLE,
};

const uiReducerNew = createReducer(
  initialState,
  on(uiActions.setAlertViewMode, (state, {mode}) => ({...state, alertViewMode: mode})),
  on(uiActions.setTransportViewMode, (state, {mode}) => ({...state, transportViewMode: mode})),
  on(uiActions.setTicketViewMode, (state, {mode}) => ({...state, ticketViewMode: mode})),
  on(uiActions.switchSideMenu, (state, {visible}) => ({...state, sideMenuActive: visible})),
  on(uiActions.removeLink, (state, {linkHash}) => {
    const navLinks = [...state.navLinks].filter(item => Strings.getObjectHash(item) !== linkHash);
    return {...state, navLinks};
  }),

  on(uiActions.addLink, (state, {link}) => {
    const elem = state.navLinks.find(e => Strings.getObjectHash(e) === Strings.getObjectHash(link));
    return {...state, navLinks: elem ? [...state.navLinks, link] : state.navLinks};
  }),
  on(uiActions.addSideLink, (state, {link}) => {
    link = {...link, menuType: 'SIDE' as const, position: null};
    const elem = state.navLinks.find(e => Strings.getObjectHash(e) === Strings.getObjectHash(link));
    return {...state, navLinks: elem ? [...state.navLinks, link] : state.navLinks};
  }),
  on(uiActions.addTopLeftLink, (state, {link}) => {
    link = {...link, menuType: 'TOP' as const, position: 'LEFT' as const};
    const elem = state.navLinks.find(e => Strings.getObjectHash(e) === Strings.getObjectHash(link));
    return {...state, navLinks: elem ? [...state.navLinks, link] : state.navLinks};
  }),
  on(uiActions.addTopRightLink, (state, {link}) => {
    link = {...link, menuType: 'TOP' as const, position: 'RIGHT' as const};
    const elem = state.navLinks.find(e => Strings.getObjectHash(e) === Strings.getObjectHash(link));
    return {...state, navLinks: elem ? [...state.navLinks, link] : state.navLinks};
  }),
  on(uiActions.setLinks, (state, {links}) => ({...state, navLinks: links})),
  on(uiActions.setSideLinks, (state, {links}) => {
    const navLinks = [
      ...state.navLinks.filter(link => link.menuType !== 'SIDE'),
      ...links.map(item => {
        return {...item, menuType: 'SIDE' as const, position: null};
      })
    ];
    return {...state, navLinks};
  }),
  on(uiActions.setTopLinks, (state, {links}) => {
    const navLinks = [
      ...state.navLinks.filter(link => link.menuType !== 'TOP'),
      ...links.map(item => {
        return {...item, menuType: 'TOP' as const};
      })
    ];
    return {...state, navLinks};
  }),
  on(uiActions.setTopLeftLinks, (state, {links}) => {
    const navLinks = [
      ...state.navLinks.filter(link => link.menuType !== 'TOP' && link.position !== 'LEFT'),
      ...links.map(item => {
        return {...item, menuType: 'TOP' as const, position: 'LEFT' as const};
      })
    ];
    return {...state, navLinks};
  }),
  on(uiActions.setTopRightLinks, (state, {links}) => {
    const navLinks = [
      ...state.navLinks.filter(link => link.menuType !== 'TOP' && link.position !== 'RIGHT'),
      ...links.map(item => {
        return {...item, menuType: 'TOP' as const, position: 'RIGHT' as const};
      })
    ];
    return {...state, navLinks};
  }),
  on(uiActions.resetAllLinks, (state) => ({...state, navLinks: initialState.navLinks})),
  on(uiActions.sideLinkAction, (state, {linkAction}) => ({...state, sideLinkAction: linkAction})),
  on(uiActions.resizeWindow, (state, {context}) => ({...state, resizeWindowEvent: context})),

  on(uiActions.mapSearchRouteRequest, (state) => ({...state, map: {...state.map, routeSearchRequests: state.map.routeSearchRequests + 1}})),
  on(uiActions.mapSearchRouteRequestDone, (state) =>
    ({...state, map: {...state.map, routeSearchRequests: state.map.routeSearchRequests - 1}})),
  on(uiActions.mapSearchRouteProcessing, (state) =>
    ({...state, map: {...state.map, routeSearchProcessingEvt: state.map.routeSearchProcessingEvt + 1}})),
  on(uiActions.mapSearchRouteProcessingDone, (state) =>
    ({...state, map: {...state.map, routeSearchProcessingEvt: state.map.routeSearchProcessingEvt - 1}})),

  on(uiActions.showProgressBar, (state) => ({...state, progressBarVisible: true})),
  on(uiActions.hideProgressBar, (state) => ({...state, progressBarVisible: false})),

  on(uiActions.switchRightDrawer, (state, data) => ({...state, rightDrawer: data})),
  on(uiActions.switchLeftDrawer, (state, {show}) => ({...state, showLeftDrawer: show})),
);

export function reducer(state: State | undefined, action: Action) {
  return uiReducerNew(state, action);
}

export const getIsLoading = (state: State) => state.isLoading;

export const getSideLinks = (state: State) => state.navLinks.filter(link => link.menuType === 'SIDE');
export const getSideLinksStatus = (state: State) => state.sideMenuActive;
export const getMapSearchRouteEvents = (state: State) => state.map.routeSearchProcessingEvt + state.map.routeSearchRequests;
export const getTopLeftLinks = (state: State) => state.navLinks.filter(link => link.menuType === 'TOP' && link.position === 'LEFT');
export const getTopRightLinks = (state: State) => state.navLinks.filter(link => link.menuType === 'TOP' && link.position === 'RIGHT');
export const getSideLinkAction = (state: State) => state.sideLinkAction;
export const getResizeWindowStatus = (state: State) => state.resizeWindowEvent;
export const getRightDrawer = (state: State) => state.rightDrawer;

export const isLeftDrawerOpened = (state: State) => state.showLeftDrawer;
export const isProgressBarVisible = (state: State) => state.progressBarVisible;
export const getAlertViewMode = (state: State) => state.alertViewMode;
export const getTransportViewMode = (state: State) => state.transportViewMode;
export const getTicketViewMode = (state: State) => state.ticketViewMode;
