import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { interval, Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import * as fromRoot from '../../../app.reducer';
import * as UI from '../../../ngrx/ui.actions';
import * as FRAME from '../../../ngrx/frame.actions';
import * as TRANSPORT from '../../transports/ngrx/transport.actions';

import { GenericNavItem } from '../../../models/dto';
import { SideLinkActionData } from '../../../models/helpers';
import { PendingTransportListComponent, SearchRouteComponent, ShowTracksComponent } from '../components';
import { RightDrawerService } from '../../shared/services';
import { Privs } from '../../../helpers/enum';
import { SecurityHelper } from '../../../helpers';
import { MapNgrxActions } from '../enum';

@Component({
  selector: 'app-map-main',
  templateUrl: './map-main.component.html',
  styleUrls: ['./map-main.component.scss']
})
export class MapMainComponent implements OnInit, OnDestroy {

  currentSideLinkActions: Observable<SideLinkActionData>;
  positionTimer: any;
  subs = new Subscription();

  mapMenu: GenericNavItem[] = [];

  constructor(private store: Store<fromRoot.State>, private rightDrawer: RightDrawerService) {
  }

  ngOnDestroy() {
    clearInterval(this.positionTimer);
    this.rightDrawer.close();
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.store.dispatch(UI.setSideLinks({links: []}));
    const links: GenericNavItem[] = [
      {
        path: MapNgrxActions.GENERIC_VIEW,
        title: 'Pending transports',
        itemType: 'UiNgrxAction',
        security: {type: 'PRIVILEGES', value: [Privs.ADM_TRANSITS, Privs.READ_TRANSIT]}
      },
      {
        path: MapNgrxActions.SHOW_TRACK,
        title: 'Show trace',
        itemType: 'UiNgrxAction',
        security: {type: 'PRIVILEGES', value: [Privs.ADM_TRANSITS, Privs.READ_ROUTES_TRACKS]}
      },
      {
        path: MapNgrxActions.SHOW_VEHICLES,
        title: 'All vehicles',
        itemType: 'UiNgrxAction',
        security: {type: 'PRIVILEGES', value: [Privs.ADM_ROUTE_TRACKS, Privs.READ_VEHICLES]}
      },
      {
        path: MapNgrxActions.SEARCH_ROUTE,
        title: 'Map route',
        itemType: 'UiNgrxAction',
        security: {type: 'PRIVILEGES', value: [Privs.ADM_ROUTE_TRACKS, Privs.READ_ROUTES_TRACKS]}
      }];
    this.mapMenu = SecurityHelper.filterLinks(links);

    this.store.dispatch(UI.sideLinkAction({linkAction: {value: MapNgrxActions.SHOW_VEHICLES}}));
    this.currentSideLinkActions = this.store.select(fromRoot.selectors.ui.getLastSideLinkAction);

    this.store.dispatch(TRANSPORT.loadAllTransitsRequest());
    this.subs.add(interval(45 * 1000).subscribe(() => {
      this.store.dispatch(TRANSPORT.loadAllTransitsRequest());
    }));

    setTimeout(() => {
      this.ngrxMenuAction(MapNgrxActions.GENERIC_VIEW);
    });

    this.subs.add(
      this.store.select(fromRoot.selectors.ui.getLastSideLinkAction)
        .pipe(debounceTime(500))
        .subscribe(action => {
          switch (action.value) {
            case MapNgrxActions.GENERIC_VIEW:
            case MapNgrxActions.SHOW_VEHICLES:
              clearInterval(this.positionTimer);
              this.store.dispatch(FRAME.infoRequest());
              this.positionTimer = setInterval(() => {
                this.store.dispatch(FRAME.infoRequest());
              }, 10 * 1000);
              break;

            default:
              clearInterval(this.positionTimer);
          }
        })
    );
  }

  ngrxMenuAction(actionValue: string) {
    switch (actionValue) {
      case MapNgrxActions.GENERIC_VIEW:
        this.rightDrawer.open(PendingTransportListComponent);
        clearInterval(this.positionTimer);
        break;

      case MapNgrxActions.SHOW_TRACK:
        this.rightDrawer.open(ShowTracksComponent);
        clearInterval(this.positionTimer);
        break;

      case MapNgrxActions.SEARCH_ROUTE:
        this.rightDrawer.open(SearchRouteComponent);
        clearInterval(this.positionTimer);
        break;

      case MapNgrxActions.SHOW_VEHICLES:
        this.rightDrawer.close();
        this.store.dispatch(FRAME.infoRequest());
        break;
    }
    this.store.dispatch(UI.sideLinkAction({linkAction: {value: actionValue}}));
    return false;
  }
}
