<div class="content" fxFlex fxLayout="column" fxLayoutGap="16px">
  <h2>{{title}}</h2>
  <form [formGroup]="myForm">
    <div fxFlex fxLayout="column" fxLayoutGap="4px">
      <app-input autocomplete="false" label="Name"
                 [control]="myForm.getFormControl('nazwa')"></app-input>
      <app-input autocomplete="false" label="Phone no."
                 [control]="myForm.getFormControl('telefon')"></app-input>
      <app-input autocomplete="false" label="Contact person"
                 [control]="myForm.getFormControl('kontakt')"></app-input>
      <app-input autocomplete="false" label="Description"
                 [control]="myForm.getFormControl('uwagi')"></app-input>

      <div fxFlex fxLayout="row" fxLayoutGap="4px">
        <button mat-stroked-button color="accent" (click)="resetForm()">Reset</button>
        <button mat-raised-button color="primary" *ngIf="myForm.enabled" [disabled]="myForm.invalid" (click)="save()">Save</button>
        <button mat-raised-button color="primary" (click)="editForm()" *ngIf="myForm.disabled">
          Edit
        </button>
      </div>
    </div>
  </form>
</div>
