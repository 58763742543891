<div
[ngClass]="{
    low: percentage < BATTERY_PERCENT_VERY_LOW,
    med: percentage >= BATTERY_PERCENT_VERY_LOW && percentage <= BATTERY_PERCENT_LOW,
    high: percentage > BATTERY_PERCENT_LOW,
    'with-percentage': showPercentage
  }"
  class="battery-level">
  <span class="battery-percentage outer">{{percentage}}%</span>
  <span class="battery-bar">
    <span class="battery-percentage inner">{{percentage}}%</span>
  </span>
</div>
