export enum Privs {
  ADM_COMP = 8,
  ADM_USERS = 9,
  ADM_USERS_ROLES = 10,
  ADM_SYS_PRIV = 11,
  ADM_API_ROUTING = 12,
  ADM_SELF_ACC = 13,
  ADM_COMP_IP = 14,
  ADM_VEHICLES = 15,
  ADM_TERMINAL = 16,
  ADM_DRIVER = 17,
  ADM_TRANSITS = 18,
  ADM_TABLETS = 19,
  ADM_SYS_ALERTS = 20,
  ADM_AUTOM_PROCESSES = 21,
  ADM_SYS = 22,
  ADM_FRAME_MQTT = 23,
  READ_COMP = 24,
  EDIT_COMP = 25,
  MNG_MONIT_CM = 26,
  MNG_COMP_MODULES = 27,
  MNG_CERTS = 28,
  READ_USERS = 29,
  EDIT_USERS = 30,
  EDIT_USERS_PRIV = 31,
  MNG_USER_BLOCKADE = 32,
  READ_USERS_ROLES = 33,
  EDIT_USER_ROLES = 34,
  EDIT_ROLES_PRIVS = 35,
  MNG_COMP_ROLES = 36,
  READ_SYS_PRIV = 37,
  EDIT_SYS_PRIV = 38,
  READ_ROUTING_API = 39,
  EDIT_API_ADDR = 40,
  EDIT_ROUTING_API = 41,
  READ_COMP_IPS = 42,
  EDIT_COMP_IP = 43,
  READ_VEHICLES = 44,
  MNG_TERMINAL_DIC = 45,
  EDIT_TRUCS = 46,
  EDIT_TRAILS = 47,
  MNG_VEHICLES_OPEN = 48,
  READ_TERMINALS = 49,
  EDIT_TERMINAL = 50,
  MNG_TERMINAL_SOFT = 51,
  MNG_TERMINAL_SAFETY = 52,
  MNG_TERMINAL_STATUS = 53,
  MNG_TERMINAL_ASSESMENT = 54,
  TEST_TERMINAL = 55,
  MNG_TERMINAL_LIQUIDATION = 56,
  READ_DRIVERS = 57,
  EDIT_DRIVER = 58,
  MNG_SHIPPING_POINTS = 59,
  READ_TRANSSETS = 60,
  EDIT_TRANSSET = 61,
  MNG_TRANSSET_STATUS = 62,
  TRANSSET_TO_DISP = 63,
  ADM_ROUTE_TRACKS = 64,
  READ_ROUTES_TRACKS = 65,
  EDIT_ROUTES_TRACK = 66,
  READ_INCIDENTS = 67,
  READ_COMM_HOLES = 68,
  READ_TRANSIT = 69,
  EDIT_TRANSIT = 70,
  TRANSIT_APPROVE_START = 71,
  TRANSIT_TO_DISP_WRK = 72,
  TRANSIT_TO_CM_WRK = 73,
  MNG_TRANSIT_STATE = 74,
  MNG_CARGO_OPEN = 75,
  MNG_TRANSSET_SAFETY = 76,
  MNG_ALARMS_EVENTS_TRANSIT = 77,
  READ_ALARMS_EVENTS_TRANSIT = 120,
  MNG_DIC_TABLETS = 78,
  READ_TABLETS = 79,
  EDIT_TABLETS = 80,
  COMM_WITH_DRIVER = 81,
  READ_SYSTEM_ALERTS = 82,
  MNG_SYS_ALERTS = 83,
  READ_AUTOM_PROCESSES_STATE = 84,
  MNG_AUTOM_PROCESS = 85,
  MONIT_SAFETY_MNG_PROC = 86,
  MONIT_NET_STATE = 87,
  READ_FRAMES_MQTT = 88,
  EDIT_FRAM_MQTT = 89,
  READ_SELF_ACC = 90,
  EDIT_SELF_ACC = 91,
  'API_TABLET-1' = 99,
  SYS_PROC = 100,
  MNG_GRABBERS = 101,
  MNG_TEMRINAL_TESTS = 102,
  COMM_TO_TERMINAL = 103,
  EDIT_DRIV_LIC = 104,
  READ_DRIV_LICENCE = 105,
  'API_TABLET-2' = 107,
  'API_TABLET-3' = 108,
  MNG_MONIT_PARAM_TPL = 109,
  TEST_TERMINAL_READINESS = 110,
  TEST_BEFORE_START = 111,
  READ_CURR_SENSOR = 112,
  READ_SHIPPING_POINT = 113,
  ADM_MOB_MAG = 114,
  GENERAL_READ = 115,
  GUI_ADMIN = 121,
  GUI_ALERTS = 122,
  GUI_INVENTORY = 123,
  GUI_MAP = 124,
  GUI_TRANSPORT = 125,
  MNG_TICKETS = 126,
  READ_TICKETS = 127,
  EDIT_TICKETS = 128,
  MNG_CARGO_OWNER = 129,
  READ_CARGO_OWNER = 130,
  EDIT_CARGO_OWNER = 131,
  DEL_CARGO_OWNER = 132,
  GENERATE_TRANSPORT_REPORT = 133,
  START_TRANSIT = 136,
  STOP_TRANSIT = 137
}
