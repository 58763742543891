import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as DRIVERS_REPORTS from './driver-reports.actions';
import { IDriverReport } from '../interfaces';

export interface State {
  allDriversReports: IDriverReport[];
  DriverReportsByTypeId: IDriverReport[];
  driversReportsFilter: {
    text: string;
    status: string;
  };
  isDataLoading: boolean;
}

const initialState: State = {
  allDriversReports: [],
  DriverReportsByTypeId: [],
  driversReportsFilter: {
    text: '',
    status: 'New'
  },
  isDataLoading: false,
};

export const featureName = 'driver-reports';

const driverReportsReducer = createReducer(
  initialState,
  on(DRIVERS_REPORTS.loadAllDriverReportsRequest, (state) => ({...state, isDataLoading: true})),
  on(DRIVERS_REPORTS.loadAllDriverReportsSuccess, (state, {driverReports}) => (
    {
      ...state,
      allDriversReports: driverReports,
      isDataLoading: false
    }
  )),
  on(DRIVERS_REPORTS.loadDriverReportsByTypeIdRequest, (state) => ({...state, isDataLoading: true})),
  on(DRIVERS_REPORTS.loadDriverReportsByTypeIdSuccess, (state, {driverReports}) => (
    {
      ...state,
      DriverReportsByTypeId: driverReports,
      isDataLoading: false
    }
  )),
  on(DRIVERS_REPORTS.loadAllDriverReportsFailed, (state, ) => ({...state, isDataLoading: false})),
  on(DRIVERS_REPORTS.loadDriverReportsByTypeIdFailed, (state, ) => ({...state, isDataLoading: false})),
  on(DRIVERS_REPORTS.setDriverReportsFilter, (state, driversReportsFilter) => ({...state, driversReportsFilter})),
);

export function reducer(state: State | undefined, action: Action) {
  return driverReportsReducer(state, action);
}

export const getState = createFeatureSelector<State>(featureName);
export const getAllDriversReports = createSelector(getState, (state: State) => state.allDriversReports);
export const getDriverReportsByTypeId = createSelector(getState, (state: State) => state.DriverReportsByTypeId);
export const getDriversReportsFilter = createSelector(getState, (state: State) => state.driversReportsFilter);
export const isDataLoading = createSelector(getState, (state: State) => state.isDataLoading);
