import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { SelectOption } from '../../interfaces';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent {
  @Input() label: string | null;
  @Input() placeholder: string | null;
  @Input() control: FormControl;
  @Input() readonly = false;
  @Input() hint = '';
  @Input() id = '';
  @Input() suffixIcon = '';
  @Input() lov: SelectOption<any>[] = [];
  @Input() compareWith: (o1, o2) => boolean = this.compareById;
  @Output() selected = new EventEmitter<MatOptionSelectionChange>();

  isRequired(): boolean {
    return this.control.errors !== null && this.control.errors.required;
  }

  compareById(o1, o2) {
    if (!o1 || !o2) {
      return false;
    }
    return o1.id === o2.id;
  }
}

