<div class="details-frame" fxLayout="column" fxLayoutGap="8px">
  <div class="details-frame-first-row" fxLayout="row">
    <div class="alert-detail-unit name" fxLayout="column">
      <span class="mat-subheading-1">Name</span>
      <span class="content">{{alert | alertGetType : 'name'}}</span>
    </div>
    <div class="alert-detail-unit type" fxLayout="column">
      <span class="mat-subheading-1">Type</span>
      <span class="content">{{alert | alertGetType}}</span>
    </div>
    <div class="alert-detail-unit" fxLayout="column">
      <span class="mat-subheading-1">Risk assessment</span>
      <span class="content">{{ alert?.aktOcenaRyzyka }}</span>
    </div>
  </div>
  <div class="details-frame-second-row" fxLayout="row">
    <div class="alert-detail-unit dscr" fxLayout="column">
      <span class="mat-subheading-1">Description</span>
      <span class="content" fxFlex fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
        <span *ngIf="alert?.rodzajAlarmu?.rodzaj">{{ alert?.rodzajAlarmu?.rodzaj }}:</span>
        <span>{{ alert?.opis }}</span>
      </span>
    </div>
  </div>
  <div class="details-frame-third-row" fxLayout="row">
    <div class="alert-detail-unit" fxLayout="column">
      <span class="mat-subheading-1">Date</span>
      <span class="content">{{ alert?.czas | date : df.PIPE_DATETIME_LONGER_LABEL }}</span>
    </div>
    <div class="alert-detail-unit" fxLayout="column">
      <span class="mat-subheading-1">First event date</span>
      <span class="content">{{ firstEvent?.czas | date : df.PIPE_DATETIME_LONGER_LABEL }}</span>
    </div>
    <div class="alert-detail-unit" fxLayout="column" *ngIf="terminal">
      <span class="mat-subheading-1">Software version</span>
      <span class="content">{{ terminal?._opr.oznaczenie }}</span>
    </div>
    <div class="alert-detail-unit" fxLayout="column">
      <span class="mat-subheading-1">Events</span>
      <span class="content">
        <a matTooltip="Vehicle / Driver" class="p-link" (click)="showEvents(alert)">
          {{ alert?.zdarzeniaPojazdu?.length }} / {{ alert?.zgloszeniaKierowcy?.length }}
        </a>
      </span>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between">
    <div fxLayout="column" fxFlex="50%">
      <div class="alert-detail-unit owner"><span class="mat-subheading-1">Ticket</span></div>
      <div fxFlex="45%" class="details-frame-fifth-row" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px">
        <div *ngIf="ticketCommentControl.disabled; else enterTicketName" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px">
          <button mat-raised-button color="primary" (click)="createTicket()" [disabled]="!ta.isAlertOpen(alert) || alert?.ticket?.id !== null">Create a ticket</button>
          <button mat-raised-button *ngIf="alert?.ticket?.id !== null"
                  [routerLink]="['/events/tickets', alert.ticket.id]">Go to your ticket</button>
        </div>
        <ng-template #enterTicketName>
          <app-input class="ticket-name" autocomplete="false" label="Ticket title (optional)" [control]="ticketCommentControl" name="fdw-ticket-comment">
          </app-input>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
            <button mat-raised-button color="primary"
                    [disabled]="ticketCommentControl.invalid"
                    (click)="confirmTicketCreation()">
              Create ticket
            </button>
            <button mat-raised-button color="accent" (click)="ticketCommentControl.disable()">Cancel</button>
          </div>
        </ng-template>
      </div>
    </div>

    <div class="details-frame-fourth-row" fxLayout="column" fxFlex="35%">
      <div class="alert-detail-unit owner"><span class="mat-subheading-1">Owner</span></div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <p class="identity-icon-container" fxLayout="row" fxLayoutAlign="center center">
          <mat-icon *ngIf="!alert?.dyspozytor; else dispatcherImage" class="identity-icon">perm_identity</mat-icon>
          <ng-template #dispatcherImage>
            <mat-icon>person_outline</mat-icon>
          </ng-template>
        </p>

        <span class="mat-body-2 alert-owner" *ngIf="alert?.dyspozytor; else noDispatcher">
          {{ alert?.dyspozytor.imie }} {{ alert?.dyspozytor.nazwisko}}
        </span>
        <ng-template #noDispatcher>
          <span class="mat-body-2 alert-owner">No owner</span>
        </ng-template>
      </div>
    </div>
  </div>
</div>
