import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import * as fromRoot from '../../../../app.reducer';
import * as fromTransport from '../../ngrx/transport.reducer';
import * as TRANSPORT from '../../ngrx/transport.actions';

import { ITransportEntityExtended } from '../../interfaces/transport-entity';
import { ICompanyRoute, ParkingInfo } from '../../interfaces';
import { Strings } from '../../../../helpers';
import { Privs } from '../../../../helpers/enum';

@Component({
  selector: 'app-transport-overview',
  templateUrl: './transport-overview.component.html',
  styleUrls: ['./transport-overview.component.scss']
})
export class TransportOverviewComponent implements OnInit, OnDestroy {
  static readonly myname = Strings.getObjectHash('TransportOverviewComponent');

  @Input() transport: ITransportEntityExtended;
  parkingLots: ParkingInfo[];

  routes: ICompanyRoute[] = [];
  isRightDrawer = false;
  ePrivs = Privs;

  subs = new Subscription();

  constructor(private store: Store<fromRoot.State>) {
    this.store.dispatch(TRANSPORT.loadCargoOwnersRequest());
    this.store.dispatch(TRANSPORT.loadCmListRequest());

    this.subs.add(
      store.select(fromRoot.selectors.ui.getRightDrawerInfo)
        .pipe(
          filter(r => !!r?.componentData?.transport),
          filter(data => (data.componentName !== undefined && data.componentName === TransportOverviewComponent.myname)
            || data.isOpened === false),
          map(data => data.componentData as { transport: ITransportEntityExtended }),
          filter(data => this.transport === undefined || this.transport.id !== data.transport.id),
          map(r => r.transport)
        )
        .subscribe(data => {
          this.isRightDrawer = true;
        })
    );

    this.subs.add(
      this.store
        .select(fromTransport.getCurrentTransit)
        .pipe(filter((transport) => typeof transport !== 'undefined' && transport !== null))
        .subscribe((transport) => {
          this.transport = transport;
        })
    );
  }

  ngOnInit() {
    this.subs.add(
      this.store.select(fromTransport.getRoutesByTransitId(this.transport.id))
        .pipe(filter(r => r.length > 0))
        .subscribe(r => this.routes = r)
    );

    this.parkingLots = this.transport.parkingPrzejazdList.map(info => info.parking);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
