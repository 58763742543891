import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { debounceTime, startWith } from 'rxjs/operators';
import intersection from 'lodash/intersection';

import * as fromRoot from '../../../../app.reducer';
import * as fromTransport from '../../../transports/ngrx/transport.reducer';
import { Strings, TransportHelper } from '../../../../helpers';
import { ITransportEntity } from '../../../transports/interfaces/transport-entity';
import { SearchTextFilter } from '../../../transports/models/search-text-filter';
import { Terminal } from '../../../../models/dto/terminale';
import { MarkTermianls } from '../../../../ngrx/map.actions';

@Component({
  selector: 'app-pending-transport-list',
  templateUrl: './pending-transport-list.component.html',
  styleUrls: ['./pending-transport-list.component.scss']
})
export class PendingTransportListComponent implements OnDestroy {
  static readonly myname = Strings.getObjectHash('PendingTransportListComponent');
  transportList: ITransportEntity[] = [];
  subs = new Subscription();
  textSearch = new FormControl();
  markedTerminalList: Terminal[] = [];

  constructor(private store: Store<fromRoot.State>) {
    this.subs.add(
      combineLatest([
        this.store.select(fromTransport.getAllTransits),
        this.textSearch.valueChanges.pipe(startWith(''), debounceTime(350)),
        this.store.select(fromRoot.selectors.map.getMarkedTerminals),
      ])
        .subscribe(([list, text, markedTerminal]) => {
          this.markedTerminalList = markedTerminal;
          const searchFilter = new SearchTextFilter(text);
          this.transportList = list
            .filter(f => TransportHelper.isValidTransset(f.zestaw))
            .filter(f => f.zakonczony === false)
            .filter(f => searchFilter.isMatching(f))
            .sort((a, b) => {
              if (markedTerminal.length < 1) {
                return 0;
              }
              const markedTerminalIds = markedTerminal.map(t => t.id).filter(t => t !== undefined);

              const terminalA = TransportHelper.getTerminalIdList(a);
              const terminalB = TransportHelper.getTerminalIdList(b);

              if (intersection(terminalA, markedTerminalIds).length > 0) {
                return -1;
              }
              if (intersection(terminalB, markedTerminalIds).length > 0) {
                return 1;
              }

              return 0;
            });
        })
    );
  }

  clearMarkedTerminals() {
    this.store.dispatch(MarkTermianls({mapPointData: []}));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
