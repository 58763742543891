<div class="alert-info-container" fxLayout="row" fxLayoutAlign="start stretch" *ngIf="currentAlert">

  <div *ngIf="!currentAlert?.dyspozytor && eTransportAlert.isAlertOpen(currentAlert)"
       class="notifications" fxLayout="row" fxLayoutAlign="center">
    <mat-icon class="notifications-icon">notifications</mat-icon>
  </div>
  <div *ngIf="currentAlert?.dyspozytor && eTransportAlert.isAlertOpen(currentAlert)"
       class="notifications light-pink-background" fxLayout="row" fxLayoutAlign="center">
    <mat-icon class="notifications-icon red-icon">notifications</mat-icon>
  </div>
  <div
    *ngIf="eTransportAlert.isAlertClosed(currentAlert)"
    class="notifications light-grey-background" fxLayout="row" fxLayoutAlign="center">
    <mat-icon class="notifications-icon">notifications</mat-icon>
  </div>

  <div class="detailed-information" fxFlex="100" fxLayout="column" fxLayoutGap="16px">
    <div class="alert-and-transport-info-container" fxLayout="row" fxLayoutGap="calc(100% * 1 / 12 + 8px)">
      <div class="alert-daapp-transport-datata" fxFlex fxLayout="column">
        <p class="section-heading">Alert data</p>
        <app-alert-data *ngIf="currentAlert" [alertId]="currentAlert.id"
                        [terminalId]="currentAlert.terminal ? currentAlert.terminal.id : null"
                        fxFlex></app-alert-data>
      </div>
      <div class="transport-data" fxFlex="22em" fxLayout="column">
        <p class="section-heading">Latest transport location</p>
        <app-transport-data [alert]="currentAlert" fxFlex>
        </app-transport-data>
      </div>
    </div>
    <div class="timeline-container" fxFlex fxLayout="column" fxLayoutAlign="start">
      <p class="section-heading">Timeline</p>
      <app-alert-timeline [alertId]="currentAlert.id"></app-alert-timeline>
    </div>
  </div>

  <div class="close" fxLayout="row" fxLayoutAlign="center">
    <mat-icon class="close-icon pointer" (click)="closeAndNavigateBack()">close</mat-icon>
  </div>

</div>
