import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { ITransportEntity } from '../../../interfaces/transport-entity';
import * as fromTransport from '../../../ngrx/transport.reducer';
import * as fromRoot from '../../../../../app.reducer';
import { Parameter, TransitParameter } from '../../../interfaces';
import * as TRANSPORT from '../../../ngrx/transport.actions';

@Component({
  selector: 'app-transit-assigned-params',
  templateUrl: './transit-assigned-params.component.html',
  styleUrls: ['./transit-assigned-params.component.scss']
})
export class TransitAssignedParamsComponent implements OnInit {
  @Input() transit: ITransportEntity;
  @Output() reloadParameters = new EventEmitter<string>();

  availableParameters: Parameter[];
  assignedParameters: TransitParameter[];

  constructor(private store: Store<fromRoot.State>) {
  }

  ngOnInit(): void {
    combineLatest([
      this.store.select(fromTransport.getAvailableMonitParams),
      this.store.select(fromTransport.getAssignedMonitParams),
    ])
      .pipe(
        filter(r => r[0] !== null && r[1] !== null)
      )
      .subscribe(([avail, assigned]) => {
        this.availableParameters = avail;
        this.assignedParameters = [...assigned]
          .sort((a, b) => a.parametr.id > b.parametr.id ? 1 : -1);
      });
  }

  removeParam(param: TransitParameter) {
    this.store.dispatch(TRANSPORT.deleteMonitorParamRequest({
      id: param.id,
      onSuccess: () => {
        this.reloadParameters.emit('parameter removed');
      }
    }));
  }

}
