import { Action } from '@ngrx/store';
import {
  IAlarmTransportowy,
  IAlarmTransportowyUproszczony,
  IPodjetaAkcja,
  IPrzypisanieAlarmuDyspozytorowi,
  ITworzenieTicketuDlaAlarmu,
  IZamkniecieAlarmu
} from '../interfaces';
import { Uzytkownik } from 'src/app/models/dto/user';
import { INowaPodjetaAkcjaDTO } from '../../../models/dto/alert/podjeta-akcja';
import { IStatusAlarmu } from '../../../models/dto/alert/alarm-transportowy-uproszczony';
import { IAlertFilter } from '../models/filters';
import moment from 'moment';

export enum ActionNames {
  LOAD_ALL_TRANSPORT_ALERTS_REQUEST = '[ALERT] load all transport alerts - request',
  LOAD_ALL_TRANSPORT_ALERTS_SUCCESS = '[ALERT] load all transport alerts - success',
  LOAD_TRANSPORT_ALERTS_BY_STATUS_REQUEST = '[ALERT] load transport alerts by status - request',
  LOAD_TRANSPORT_ALERTS_BY_STATUS_SUCCESS = '[ALERT] load transport alerts by status - success',
  LOAD_DETAILED_TRANSPORT_ALERT_BY_ID_REQUEST = '[ALERT] load detailed transport alert by id - request',
  LOAD_DETAILED_TRANSPORT_ALERT_BY_ID_SUCCESS = '[ALERT] load detailed transport alert by id - success',
  LOAD_CURRENTLY_VIEWED_ALERT_ID = '[ALERT load currently viewed alert id]',

  ASSIGN_TRANSPORT_ALERT_HANDLER_REQUEST = '[ALERT] assign transport alert handler - request',
  ASSIGN_TRANSPORT_ALERT_HANDLER_SUCCESS = '[ALERT] assign transport alert handler - success',
  UPDATE_DETAILED_TRANSPORT_ALERT_BY_ID_REQUEST = '[ALERT] update detailed transport alert by id - request',
  UPDATE_DETAILED_TRANSPORT_ALERT_BY_ID_SUCCESS = '[ALERT] update detailed transport alert by id - success',
  LOAD_AVAILABLE_DISPATCHERS_REQUEST = '[ALERT] get available dispatchers - request',
  LOAD_AVAILABLE_DISPATCHERS_SUCCESS = '[ALERT] get available dispatchers - success',
  ASSIGN_TRANSPORT_ALERT_TO_PARTICULAR_DISPATCHER_REQUEST = '[ALERT] assing transport alert to particular dispatcher - request',
  ASSIGN_TRANSPORT_ALERT_TO_PARTICULAR_DISPATCHER_SUCCESS = '[ALERT] assing transport alert to particular dispatcher - success',
  CANCEL_TRANSPORT_ALERT_PARTICULAR_DISPATCHER_ASSIGNMENT_REQUEST =
    '[ALERT] cancel transport alert particular dispatcher assignment - request',
  CANCEL_TRANSPORT_ALERT_PARTICULAR_DISPATCHER_ASSIGNMENT_SUCCESS =
    '[ALERT] cancel transport alert particular dispatcher assignment - success',
  CREATE_TRANSPORT_ALERT_TICKET_REQUEST = '[ALERT] create transport alert ticket - request',
  CREATE_TRANSPORT_ALERT_TICKET_SUCCESS = '[ALERT] create transport alert ticket - success',
  TERMINATE_TRANSPORT_ALERT_REQUEST = '[ALERT] terminate transport alert - request',
  TERMINATE_TRANSPORT_ALERT_SUCCESS = '[ALERT] terminate transport alert - success',

  SAVE_TAKEN_ACTION_REQUEST = '[ALERT] save taken action - request',
  SAVE_TAKEN_ACTION_SUCCESS = '[ALERT] save taken action - success',

  LOAD_TAKEN_ACTIONS_BY_TICKET_ID_REQUEST = '[ALERT] load taken actions by ticket id - request',
  LOAD_TAKEN_ACTIONS_BY_TICKET_ID_SUCCESS = '[ALERT] load taken actions by ticket id - success',

  LOAD_ALERT_STATUS_LIST_REQUEST = '[Alert] load alertstatus list - request',
  LOAD_ALERT_STATUS_LIST_SUCCESS = '[Alert] load alertstatus list - success',

  SET_ALERT_FILTER = '[ALERT] set alert filter',
}

export class LoadAllTransportAlertsRequest implements Action {
  readonly type = ActionNames.LOAD_ALL_TRANSPORT_ALERTS_REQUEST;
}

export class LoadAllTransportAlertsSuccess implements Action {
  readonly type = ActionNames.LOAD_ALL_TRANSPORT_ALERTS_SUCCESS;

  constructor(public payload: IAlarmTransportowyUproszczony[]) {
  }
}

export class LoadTransportAlertsByStatusRequest implements Action {
  readonly type = ActionNames.LOAD_TRANSPORT_ALERTS_BY_STATUS_REQUEST;

  constructor(public payload: { statusList: number[], fromTimestamp?: number, refreshDates?: boolean }) {
  }
}

export class LoadTransportAlertsByStatusSuccess implements Action {
  readonly type = ActionNames.LOAD_TRANSPORT_ALERTS_BY_STATUS_SUCCESS;

  constructor(public payload: { statusList: number[], alerts: IAlarmTransportowyUproszczony[], refreshDates?: boolean, updateDate?: moment.Moment }) {
  }
}

export class LoadDetailedTransportAlertByIdRequest implements Action {
  readonly type = ActionNames.LOAD_DETAILED_TRANSPORT_ALERT_BY_ID_REQUEST;

  constructor(public payload: number) {
  }
}

export class LoadDetailedTransportAlertByIdSuccess implements Action {
  readonly type = ActionNames.LOAD_DETAILED_TRANSPORT_ALERT_BY_ID_SUCCESS;

  constructor(public payload: IAlarmTransportowy) {
  }
}

export class LoadCurrentlyViewedAlertId implements Action {
  readonly type = ActionNames.LOAD_CURRENTLY_VIEWED_ALERT_ID;

  constructor(public payload: number | null) {
  }
}


export class AssignTransportAlertHandlerRequest implements Action {
  readonly type = ActionNames.ASSIGN_TRANSPORT_ALERT_HANDLER_REQUEST;

  constructor(public payload: { alertId: number }) {
  }
}

export class AssignTransportAlertHandlerSuccess implements Action {
  readonly type = ActionNames.ASSIGN_TRANSPORT_ALERT_HANDLER_SUCCESS;

  constructor(public payload: { alertId: number }) {
  }
}

export class UpdateDetailedTransportAlertByIdRequest implements Action {
  readonly type = ActionNames.UPDATE_DETAILED_TRANSPORT_ALERT_BY_ID_REQUEST;

  constructor(public payload: number) {
  }
}

export class UpdateDetailedTransportAlertByIdSuccess implements Action {
  readonly type = ActionNames.UPDATE_DETAILED_TRANSPORT_ALERT_BY_ID_SUCCESS;

  constructor(public payload: IAlarmTransportowy) {
  }
}

export class LoadAvailableDispatchersRequest implements Action {
  readonly type = ActionNames.LOAD_AVAILABLE_DISPATCHERS_REQUEST;

}

export class LoadAvailableDispatchersSuccess implements Action {
  readonly type = ActionNames.LOAD_AVAILABLE_DISPATCHERS_SUCCESS;

  constructor(public payload: Uzytkownik[]) {
  }
}

export class AssignTransportAlertToParticularDispatcherRequest implements Action {
  readonly type = ActionNames.ASSIGN_TRANSPORT_ALERT_TO_PARTICULAR_DISPATCHER_REQUEST;

  constructor(public payload: IPrzypisanieAlarmuDyspozytorowi) {
  }
}

export class AssignTransportAlertToParticularDispatcherSuccess implements Action {
  readonly type = ActionNames.ASSIGN_TRANSPORT_ALERT_TO_PARTICULAR_DISPATCHER_SUCCESS;

  constructor(public payload: IPrzypisanieAlarmuDyspozytorowi) {
  }
}

export class CancelTransportAlertParticularDispatcherAssignmentRequest implements Action {
  readonly type = ActionNames.CANCEL_TRANSPORT_ALERT_PARTICULAR_DISPATCHER_ASSIGNMENT_REQUEST;

  constructor(public payload: { alertId: number }) {
  }
}

export class CancelTransportAlertParticularDispatcherAssignmentSuccess implements Action {
  readonly type = ActionNames.CANCEL_TRANSPORT_ALERT_PARTICULAR_DISPATCHER_ASSIGNMENT_SUCCESS;

  constructor(public payload: { alertId: number }) {
  }
}

export class CreateTransportAlertTicketRequest implements Action {
  readonly type = ActionNames.CREATE_TRANSPORT_ALERT_TICKET_REQUEST;

  constructor(public payload: {data: ITworzenieTicketuDlaAlarmu, callback: () => void}) {
  }
}

export class CreateTransportAlertTicketSuccess implements Action {
  readonly type = ActionNames.CREATE_TRANSPORT_ALERT_TICKET_SUCCESS;

  constructor(public payload: { alarm: IAlarmTransportowy }) {
  }
}

export class TerminateTransportAlertRequest implements Action {
  readonly type = ActionNames.TERMINATE_TRANSPORT_ALERT_REQUEST;

  constructor(public payload: IZamkniecieAlarmu) {
  }
}

export class TerminateTransportAlertSuccess implements Action {
  readonly type = ActionNames.TERMINATE_TRANSPORT_ALERT_SUCCESS;

  constructor(public payload: { alertId: number }) {
  }
}

export class SaveTakenActionRequest implements Action {
  readonly type = ActionNames.SAVE_TAKEN_ACTION_REQUEST;

  constructor(public payload: INowaPodjetaAkcjaDTO) {
  }
}

export class SaveTakenActionSuccess implements Action {
  readonly type = ActionNames.SAVE_TAKEN_ACTION_SUCCESS;

  constructor(public payload: { ticketId: number, action: IPodjetaAkcja }) {
  }
}

export class LoadTakenActionsByTicketIdRequest implements Action {
  readonly type = ActionNames.LOAD_TAKEN_ACTIONS_BY_TICKET_ID_REQUEST;

  constructor(public payload: number) {
  }
}

export class LoadTakenActionsByTicketIdSuccess implements Action {
  readonly type = ActionNames.LOAD_TAKEN_ACTIONS_BY_TICKET_ID_SUCCESS;

  constructor(public payload: { ticketId: number, actions: IPodjetaAkcja[] }) {
  }
}

export class LoadAlertStatusListRequest implements Action {
  readonly type = ActionNames.LOAD_ALERT_STATUS_LIST_REQUEST;
}

export class LoadAlertStatusListSuccess implements Action {
  readonly type = ActionNames.LOAD_ALERT_STATUS_LIST_SUCCESS;

  constructor(public payload: { statusList: IStatusAlarmu[] }) {
  }
}

export class SetAlertFilter implements Action {
  readonly type = ActionNames.SET_ALERT_FILTER;

  constructor(public payload: IAlertFilter) {
  }
}


export type AlertActions =
  LoadAllTransportAlertsRequest | LoadAllTransportAlertsSuccess |
  LoadTransportAlertsByStatusRequest | LoadTransportAlertsByStatusSuccess |
  LoadDetailedTransportAlertByIdRequest | LoadDetailedTransportAlertByIdSuccess |
  LoadCurrentlyViewedAlertId |

  AssignTransportAlertHandlerRequest | AssignTransportAlertHandlerSuccess |
  UpdateDetailedTransportAlertByIdRequest | UpdateDetailedTransportAlertByIdSuccess |
  LoadAvailableDispatchersRequest | LoadAvailableDispatchersSuccess |
  AssignTransportAlertToParticularDispatcherRequest | AssignTransportAlertToParticularDispatcherSuccess |
  CancelTransportAlertParticularDispatcherAssignmentRequest | CancelTransportAlertParticularDispatcherAssignmentSuccess |
  TerminateTransportAlertRequest | TerminateTransportAlertSuccess |
  CreateTransportAlertTicketRequest | CreateTransportAlertTicketSuccess |
  SaveTakenActionRequest | SaveTakenActionSuccess |
  LoadTakenActionsByTicketIdRequest | LoadTakenActionsByTicketIdSuccess |
  LoadAlertStatusListRequest | LoadAlertStatusListSuccess |
  SetAlertFilter;
