<form method="POST" action="/login" fxFlex fxLayout="column" fxLayoutGap="10px">
  <mat-form-field>
    <mat-label>Login</mat-label>
    <input matInput type="text" name="login" id="login" [(ngModel)]="login"
           placeholder="Your login"
           required autofocus>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Password</mat-label>
    <input matInput type="password" name="password" [(ngModel)]="password" id="password"
           placeholder="Password" required>
  </mat-form-field>
  <div>
    <button type="submit" mat-raised-button color="primary"
            (click)="loginAction($event)" [disabled]="login.length < 3 || password.length < 3">
      Sign in
    </button>
  </div>
</form>
