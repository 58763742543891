import { FormControl, FormGroup } from '@angular/forms';

export abstract class DefaultForm<T> extends FormGroup {
  protected model: T | null = null;

  getUpdatedModel(): T {
    return {...this.model ?? {}, ...this.value};
  }

  patchFromModel(data: T): void {
    this.model = {...data};
    this.patchValue(this.model);
  }

  patchFromPartialModel(data: Partial<T>): void {
    this.model = {...this.model, ...data};
    this.patchValue(this.model);
  }

  getFormControl(name: string): FormControl {
    return this.get(name) as FormControl;
  }

  reset(value?: any, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.reset(value, options);

    this.model = null;
  }

  getValue(): any {
    return {...this.value};
  }
}
