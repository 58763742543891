<div *ngIf="{saveInProgress: saveInProgress$ | async} as createRoute" class="r-drawer-container" fxFlex fxLayout="column" fxLayoutGap="10px">
  <div class="title-bar">
    Route mapping
  </div>
  <mat-vertical-stepper #stepper>
    <mat-step>
      <mat-card>
        <mat-card-content>
          <form [formGroup]="searchRouteForm" (ngSubmit)="onSubmit()" fxFlex fxLayout="column" fxLayoutGap="10px">
            <ng-template matStepLabel>Create route</ng-template>
            <div fxFlex fxLayout="column" fxLayoutGap="10px">
              <mat-form-field>
                <mat-label>Route start</mat-label>
                <shipping-point-search
                  [pointIdPrefix]="startIdPrefix"
                  [iconName]="startIcon"
                  formControlName="poczatekTrasy"
                  >

                </shipping-point-search>
              </mat-form-field>

              <via-points
                [viaPoints]="searchRouteForm.getViaPoints()"
                (pointRemoved)="removeViaPoint($event)">
              </via-points>

              <mat-form-field>
                <mat-label>Route end</mat-label>
                <shipping-point-search
                  [pointIdPrefix]="endIdPrefix"
                  [iconName]="endIcon"
                  formControlName="koniecTrasy"
                  >

                </shipping-point-search>
              </mat-form-field>
            </div>
            <div class="actions" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
              <button *ngIf="inData" mat-raised-button [disabled]="createRoute.saveInProgress" (click)="cancelAction()" type="button">Back</button>
              <span fxFlex></span>
              <button mat-raised-button [disabled]="createRoute.saveInProgress" (click)="clearPoints()" type="button">Clear</button>
              <button mat-raised-button [disabled]="createRoute.saveInProgress" color="primary" type="submit" [disabled]="searchRouteForm.invalid">Search</button>
            </div>
            <button mat-raised-button color="accent" matStepperNext type="button"
                    (click)="initTransitFormFields()"
                    *ngIf="this.searchedRoute.length > 0"
                    [disabled]="this.searchedRoute.length < 1">
              Add route
            </button>
          </form>
        </mat-card-content>
      </mat-card>
    </mat-step>
    <mat-step>
      <mat-card>
        <mat-card-content>
          <form [formGroup]="transitRouteForm" fxFlex fxLayout="column" fxLayoutGap="10px" (ngSubmit)="addNewRoute()">
            <ng-template matStepLabel>Save route</ng-template>
            <div fxFlex fxLayout="column" fxLayoutGap="10px">
              <mat-form-field>
                <mat-label>Name</mat-label>
                <input matInput formControlName="nazwa_trasy" name="trackName">
              </mat-form-field>
              <mat-checkbox formControlName="czy_utworzyc_szablon" #tpl>Create template</mat-checkbox>
              <mat-form-field *ngIf="tpl.checked">
                <mat-label>Template name</mat-label>
                <input matInput formControlName="nazwa_szablonu" name="templateName">
              </mat-form-field>
              <mat-checkbox formControlName="czy_prywatny">Private template</mat-checkbox>

            </div>
            <div class="actions" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
              <button *ngIf="inData" mat-raised-button [disabled]="createRoute.saveInProgress" (click)="cancelAction()" type="button">Back</button>
              <span fxFlex></span>
              <button mat-raised-button [disabled]="createRoute.saveInProgress" (click)="clearPoints() && stepper.reset()" type="button">Clear</button>
              <button mat-raised-button color="primary" type="submit"
                      [disabled]="!searchRouteForm.valid || !transitRouteForm.valid || createRoute.saveInProgress"
              >Save
              </button>
            </div>
          </form>
        </mat-card-content>

        <mat-card-footer>
          <mat-progress-bar
            *ngIf="createRoute.saveInProgress"
            color="accent"
            mode="indeterminate">
          </mat-progress-bar>
        </mat-card-footer>
      </mat-card>
    </mat-step>
  </mat-vertical-stepper>
</div>
