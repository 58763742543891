import { Injectable, NgZone } from '@angular/core';
import { IndividualConfig } from 'ngx-toastr/toastr/toastr-config';
import { ToastrService } from 'ngx-toastr';
import { ToastType } from '../helpers/enum';

@Injectable({
  providedIn: 'root',
})
export class MyToastService {
  defSettings = {
    timeOut: 5000,
  };

  constructor(private zone: NgZone, private toastr: ToastrService) {
  }

  public show(message, action: string = ToastType.SUCCESS, title?: string, defSettings?: Partial<IndividualConfig>) {
    switch (action) {
      case ToastType.SUCCESS:
        this.toastr.success(message, title, this.defSettings);
        break;

      case ToastType.ERROR:
        this.toastr.error(message, title, {
          ...this.defSettings,
          timeOut: 10000,
        });
        break;

      case ToastType.WARN:
        this.toastr.warning(message, title, {...this.defSettings, ...defSettings});
        break;

      case ToastType.INFO:
      default:
        this.toastr.info(message, title, {...this.defSettings, ...defSettings});
    }
  }

  public error(message) {
    this.show(message, ToastType.ERROR, 'Error occurred');
  }
}
