import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';
import { Observable } from 'rxjs';
import { UserDetailsForm } from '../../../models/form/user-details-form';
import { debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { RightDrawer } from '../../../models/dto';
import { Uzytkownik } from '../../../models/dto/user';
import { Firma } from '../../../models/dto/company';
import { RoleUzytkownikow } from '../../../models/authentication';
import { EditUserRequest, LoadCompanyRolesRequest } from '../../../ngrx/user.actions';
import { Strings } from '../../../helpers';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent {
  static readonly myname = Strings.getObjectHash('UserEditComponent');

  form$: Observable<UserDetailsForm>;
  filteredRoles$: Observable<RoleUzytkownikow[]>;
  filteredCompany$: Observable<Firma[]>;

  constructor(private store: Store<fromRoot.State>) {
    this.store.dispatch(new LoadCompanyRolesRequest());
    this.form$ = this.store.select(fromRoot.selectors.ui.getRightDrawerInfo).pipe(
      filter((value: RightDrawer) => value.componentName === UserEditComponent.myname),
      map(value => {
        const form = new UserDetailsForm(value.componentData as Uzytkownik);
        form.get('login').disable();
        form.get('firma').disable();

        return form;
      })
    );
    this.filteredRoles$ = this.form$.pipe(
      switchMap((form) => {
        return form.roleChanges().pipe(
          debounceTime(300),
          switchMap((value) => {
            return this.store.select(fromRoot.selectors.user.getCompanyRoles).pipe(
              map((roles) => {
                if (typeof value !== 'string') {
                  return roles;
                }

                return roles.filter((role) => role.nazwa_roli.includes(value));
              })
            );
          })
        );
      })
    );
    this.filteredCompany$ = this.form$.pipe(
      switchMap((form) => {
        return form.companyChanges().pipe(
          debounceTime(300),
          switchMap((value) => {
            return this.store.select(fromRoot.getCompanyList).pipe(
              map((companies) => {
                if (typeof value !== 'string') {
                  return companies;
                }

                return companies.filter((company: Firma) =>
                  company.nazwa.includes(value) || company.vat.includes(value) || company.ulica.includes(value) ||
                  company.kod.includes(value)
                );
              })
            );
          })
        );
      })
    );
  }

  displayRole(role: RoleUzytkownikow): string {
    return role.nazwa_roli;
  }

  displayCompany(company: Firma): string {
    return company.nazwa;
  }

  onSubmit(form: UserDetailsForm) {
    form.markAllAsTouched();
    if (form.valid) {
      this.store.dispatch(new EditUserRequest({user: form.getUpdatedModel()}));
    }
  }
}
