import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { HereService } from '../../../services/here.service';
import * as fromRoot from '../../../../../app.reducer';
import { MapSearchRouteForm } from '../../../../../models/form/map-search-route-form';
import * as MAP from '../../../../../ngrx/map.actions';
import { CoordsMap } from 'src/app/modules/shared/interfaces';
import { DraggedPoint, MapChannelEventType } from 'src/app/models/map';
import { MapPointsGuard, PointGuard } from 'src/app/modules/shared/type-guards';
import { ReverseGeocodeResponse } from 'src/app/models/map/reverseGeocode';
@Component({
  selector: 'app-search-base-route',
  template: '',
})
export abstract class SearchBaseRouteComponent implements OnInit, OnDestroy {
  subs = new Subscription();
  searchedRoute: any[] = [];
  searchRouteForm = new MapSearchRouteForm();
  abstract contextMenu: (e: any) => void;

  protected constructor(
    protected hereService: HereService,
    protected store: Store<fromRoot.State>,
    protected cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.initMapData();
  }

  ngOnDestroy(): void {
    this.store.dispatch(MAP.SearchRouteForWayPointsSuccess({}));
    this.store.dispatch(MAP.ReverseGeocodeSuccess({}));

    HereService.map.removeEventListener('contextmenu', this.contextMenu);
    this.subs.unsubscribe();
  }

  onSubmit(): boolean {
    const val = this.searchRouteForm.getValue();
    const data = [val.poczatekTrasy, ...val.posrednie, val.koniecTrasy];
    this.store.dispatch(MAP.SearchRouteForWayPointsRequest({ data }));
    return false;
  }

  clearPoints(): void {
    this.hereService.clearAllPoints();
    this.searchRouteForm.reset();
    this.searchedRoute = [];
  }

  removeViaPoint(viaPoint: ReverseGeocodeResponse): void {
    this.removePoint(viaPoint);
    this.cd.detectChanges();
  }

  protected initMapData(): void {
    if (HereService.isInit) {
      HereService.map.addEventListener('contextmenu', this.contextMenu);

      this.subs.add(
        this.store
          .select(fromRoot.selectors.map.getSearchingRouteResults)
          .pipe(filter((r) => r && r.length > 0))
          .subscribe((r) => {
            this.searchedRoute = r;
            this.cd.markForCheck();
          })
      );

      this.subs.add(
        HereService.channel.subscribe(async (event) => {
          switch (event.eventType) {
            case MapChannelEventType.PointDragend:
              // Get original marker's point id for comparison
              const markerPointId: string = event.value.pointId;

              const reverseGeocodeRespone =
                await this.hereService.reverseGeocode(
                  (event.value as DraggedPoint).geo
                );

              this.searchRouteForm.replaceViaPoint(
                markerPointId,
                reverseGeocodeRespone
              );
              break;

            case MapChannelEventType.RemoveWaypoint:
              this.searchRouteForm.removeViaPoint({
                pointId: event.value.pointId,
              });
              break;

            default:
              return;
          }

          this.cd.markForCheck();
        })
      );
    }
  }

  protected async reverseGeocodeFromCtxMenu(
    coord: CoordsMap,
    type: ContextMenuPoint
  ): Promise<void> {
    const reverseGeocodeRespone = await this.hereService.reverseGeocode(coord);
    this.store.dispatch(
      MAP.ReverseGeocodeSuccess({
        data: {
          ...reverseGeocodeRespone,
          type,
        },
      })
    );
  }

  protected removePoint(point: PointGuard): void {
    if (!point) {
      return;
    }

    if (MapPointsGuard.isLocationData(point)) {
      this.hereService.removeMapObject(point.LocationId);
    }

    if (MapPointsGuard.isReverseGeocodeResponse(point)) {
      this.hereService.removeMapObject(point.location.locationId);
    }

    if (MapPointsGuard.isAutoCompleteSuggestion(point)) {
      this.hereService.removeMapObject(point.locationId);
    }
  }
}

type ContextMenuPoint = 'start' | 'way-point' | 'stop';
