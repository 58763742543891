<mat-tab-group>
  <mat-tab label="Terminal">
    <p
      *ngIf="terminalInfoPass.terminalMismatch"
      class="terminal-mismatch">
      <mat-icon>warning</mat-icon>
      Mismatch of terminal ID in the system and ID ({{terminalInfoPass.ost_dane.idTerminal}}) received in transmitted data.
    </p>

    <div class="my-2">
      Location date: {{terminalInfoPass.ost_data | date : df.PIPE_DATETIME_LONGER_LABEL}}
    </div>

    <div class="details">
      <span class="label">Delay:</span>
      <span class="value">{{delay / 1000}} s</span>

      <span class="label">Record no.:</span>
      <span class="value">
        {{ terminalInfoPass?.ost_dane?.nr_rekordu || terminalInfoPass?.ost_dane?.nrRekordu }}
      </span>

        <ng-container *ngIf="locationShort">
          <span class="label">Speed:</span>
          <span class="value">{{locationShort.predkosc}} km/h</span>

          <span class="label">Height:</span>
          <span class="value">{{locationShort.wysokosc}} m</span>

          <span class="label">Sat. avail.:</span>
          <span class="value" *ngIf="locationShort.liczba_sat_wid ; else noData">
            {{locationShort.liczba_sat_wid}} pc.
          </span>

          <span class="label">Sat. in use:</span>
          <span class="value" *ngIf="locationShort.liczba_sat_uzy ; else noData">
            {{locationShort.liczba_sat_uzy}} pc.
          </span>

          <span class="label">Azimuth:</span>
          <span class="value"> {{locationShort.azymut}}</span>

          <span class="label">GSM signal strength:</span>
          <span class="value" *ngIf="locationShort.rssi_wart ; else noData">
            {{locationShort.rssi_wart}}
            : {{rssiHelper.rssiToDbmLabel(locationShort.rssi_wart)}}
          </span>

          <span class="label">Battery level:</span>
          <span class="value d-flex align-items-center">
            {{locationShort.zw}}%
            <battery-level class="d-inline ml-2" [percentage]="locationShort.zw"></battery-level>
          </span>
      </ng-container>

      <ng-container *ngIf="locationSat">
          <span class="label">Type:</span>
          <span class="value">Satellite</span>
      </ng-container>

      <ng-container *ngIf="rygiel">
        <span class="label">Locker:</span>
        <span class="value">
          <mat-icon
            class="{{rygiel.zamkniety ? 'lock' : 'open'}}">
            {{ rygiel.zamkniety ? 'lock' : 'lock_open' }}
          </mat-icon>
        </span>
      </ng-container>
    </div>

    <div *ngIf="!terminalInfoPass.terminalMismatch" class="mt-3">
      Notes: <i>({{terminalInfoPass.terminal.id}})</i> {{terminalInfoPass.terminal.uwagi}}
    </div>

    <ng-template #noData>
      <span class="value" title="No data">B. d.</span>
    </ng-template>
  </mat-tab>

  <mat-tab
    *ngIf="!terminalInfoPass.terminalMismatch && terminalInfoPass.terminal?.samochods?.length > 0"
    label="Truck">
    <div class="details">
      <span class="label">Info:</span>
      <span class="value">
        {{terminalInfoPass.terminal.samochods[0]._model.model}}
        {{terminalInfoPass.terminal.samochods[0]._model._marka.marka}}
      </span>

      <span class="label">Plates:</span>
      <span class="value">{{terminalInfoPass.terminal.samochods[0].nr_rej}}</span>

      <span class="label">Notes:</span>
      <span class="value">{{terminalInfoPass.terminal.samochods[0].uwagi}}</span>
    </div>
  </mat-tab>

  <mat-tab
    *ngIf="!terminalInfoPass.terminalMismatch && terminalInfoPass.terminal?.naczepas?.length > 0"
    label="Trailer">
    <div class="details">
      <span class="label">Info:</span>
      <span class="value">
        {{terminalInfoPass.terminal.naczepas[0]._model.model}}
        {{terminalInfoPass.terminal.naczepas[0]._model._marka.marka}}
      </span>

      <span class="label">Plates:</span>
      <span class="value">{{terminalInfoPass.terminal.naczepas[0].nr_rej}}</span>

      <span class="label">Notes:</span>
      <span class="value">{{terminalInfoPass.terminal.naczepas[0].uwagi}}</span>

      <ng-container *ngIf="rygiel">
        <span class="label">Locker:</span>
        <span class="value">
          <mat-icon
            class="{{rygiel.zamkniety ? 'lock' : 'open'}}">
            {{ rygiel.zamkniety ? 'lock' : 'lock_open' }}
          </mat-icon>
        </span>
      </ng-container>
    </div>
  </mat-tab>

  <mat-tab
    *ngIf="!terminalInfoPass.terminalMismatch &&
            terminalInfoPass.terminal?.naczepas?.length < 1 &&
            terminalInfoPass.terminal?.samochods?.length < 1"
    label="Not assigned"
    disabled="true">
  </mat-tab>
</mat-tab-group>
