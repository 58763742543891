import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import * as fromRoot from '../../../../app.reducer';
import { ParkingInfo } from '../../interfaces';
import { Strings } from '../../../../helpers';
import { ParkingSpotForm } from '../../../../models/form/transit/parking-spot-form';

enum TitleByContext {
  'map-view' = 'Parking on map',
  view = 'View parking info',
  edit = 'Edit parking info',
  create = 'Create parking',
}

interface CallbackData {
  label: string;
  fn: () => void;
}

@Component({
  selector: 'app-parking-view',
  templateUrl: './parking-view.component.html',
  styleUrls: ['./parking-view.component.scss']
})
export class ParkingViewComponent implements OnInit, OnDestroy {
  static readonly myname = Strings.getObjectHash('ParkingViewComponent');

  title = 'Parking info';
  context = '';
  parking: ParkingInfo;
  subs = new Subscription();
  form: ParkingSpotForm;
  callbackData: CallbackData;

  constructor(private store: Store<fromRoot.State>) {
  }

  ngOnInit(): void {
    this.subs.add(
      this.store.select(fromRoot.selectors.ui.getRightDrawerInfo)
        .pipe(
          filter(data => (data.componentName !== undefined && data.componentName === ParkingViewComponent.myname)
            || data.isOpened === false)
        )
        .subscribe((data) => {
          if (data.componentData && data.componentData.mode && data.componentData.mode === 'map-view') {
            this.context = data.componentData.mode;
            this.patchForm(data.componentData.data);
            if (data.componentData.callbackData) {
              this.callbackData = data.componentData.callbackData;
            }

          } else if (data.componentData) {
            this.context = 'view';
            this.patchForm(data.componentData);

          } else {
            // todo: open map with form in right-drawer
            this.parking = null;
            this.context = 'create';
            this.form = new ParkingSpotForm();
            this.form.enable();
          }

          this.title = TitleByContext[this.context];
        })
    );
  }

  patchForm(componentData: ParkingInfo): void {
    if (Strings.areObjetsEquals(this.parking, componentData)) {
      return;
    }
    this.parking = componentData;
    this.form = new ParkingSpotForm(componentData);
    this.form.disable();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  resetForm() {
    if (this.parking) {
      this.context = 'view';
      this.form = new ParkingSpotForm(this.parking);
      this.form.disable();
    } else {
      this.context = 'create';
      this.form.reset();
    }
    this.title = TitleByContext[this.context];
  }

  editForm() {
    this.form.enable();
    this.context = 'edit';
    this.title = TitleByContext[this.context];
  }

  callback() {
    this.callbackData.fn();
  }

}
