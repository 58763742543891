import { Filter } from './filter';
import { ITransportEntity } from '../interfaces/transport-entity';

export class AllFiltersMustMatchFilter implements Filter {
  constructor(private readonly filters: Filter[]) {}

  isMatching(transport: ITransportEntity): boolean {
    return this.filters.every((filter) => {
      return filter.isMatching(transport);
    });
  }
}
