import { Title } from '@angular/platform-browser';

export abstract class PageTitleHelper {
  private static title: Title;
  private static initialTitle: string;

  public static set titleService(titleService) {
    if (!PageTitleHelper.title) {
      PageTitleHelper.title = titleService;
      PageTitleHelper.initialTitle = PageTitleHelper.title.getTitle();
    }
  }

  public static setPageTitle(title: string[], prefix: string = null): string {
    if (!PageTitleHelper.title) {
      return '';
    }
    const titleSeparator = ' / ';
    const localPrefix = prefix ?? this.initialTitle ?? '';
    const currentTitle = PageTitleHelper.title.getTitle()
      .split(titleSeparator)
      .filter(item => item !== localPrefix)
      .join(titleSeparator);
    PageTitleHelper.title.setTitle([localPrefix, ...title].join(titleSeparator));
    return currentTitle;
  }
}
