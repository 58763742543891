import { Pipe, PipeTransform } from '@angular/core';
import { ITransportEntity } from '../interfaces/transport-entity';
import { TransportMsg } from '../../../messages';
import { TransportHelper } from '../../../helpers';

interface Result {
  sClass: string;
  tooltip: string;
}

@Pipe({
  name: 'canPresentTransportOnMap'
})
export class CanPresentTransportOnMapPipe implements PipeTransform {

  transform(transport: ITransportEntity): Result {
    const terminalList = TransportHelper.getTerminalIdList(transport);

    if (terminalList.length < 1) {
      return {
        sClass: 'disabled',
        tooltip: TransportMsg.NO_TRACKING_DEVICE
      };
    }

    return {
      sClass: '',
      tooltip: 'Show on the map'
    };
  }

}
