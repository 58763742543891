import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ITransportEntity } from '../../interfaces/transport-entity';
import { TransitService } from '../../services';
import { BehaviorSubject } from 'rxjs';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'siren-dialog',
  templateUrl: './siren-dialog.component.html',
  styleUrls: ['./siren-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SirenDialogComponent {
  sirenArmed$: BehaviorSubject<boolean> = new BehaviorSubject(
    this.data.syrenaUzbrojona
  );
  processing$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ITransportEntity,
    private transitService: TransitService
  ) {}

  armDisarmSiren(event: MatSlideToggleChange): void {
    this.processing$.next(true);

    this.transitService
      .armDisarmSiren(this.data.id, event.checked)
      .subscribe(() => {
        this.sirenArmed$.next(event.checked);
        this.processing$.next(false);
      });
  }
}
