<transit-test-result-frame>
  <p class="results-title">{{ resultsTitle }}</p>
  <ng-container [ngSwitch]="getTextStatus(status)">
    <span
      *ngSwitchCase="textStatusEnum.NOT_VERIFIED"
      class="status-text not-verified"
    >
      Not verified. Couldn't verify.
    </span>

    <span
      *ngSwitchCase="textStatusEnum.NOT_VERIFIED_PROCESSING"
      class="status-text verifing"
    >
      <mat-spinner [diameter]="16" class="loading-spinner"></mat-spinner>
      Not verified yet. Verifying.
    </span>

    <ng-container *ngSwitchCase="textStatusEnum.VERIFIED_WORKS_WELL">
      <span class="status-text works-well">
        <mat-icon class="status-icon works-well-icon">check_circle</mat-icon>
        Verified. Works well.
      </span>

      <span class="status-text">
        <mat-icon class="status-icon">schedule</mat-icon>

        <span class="time">
          Last frame time:
          <span class="time-value">
            {{ status.infoFrame.czas | date: dateFormat.PIPE_LONG_24H }}
          </span>
        </span>
      </span>
    </ng-container>
  </ng-container>
</transit-test-result-frame>
