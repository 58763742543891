<form>
  <div>
    <h2>Type authorization PIN</h2>
    <hr>
  </div>
  <div>
    <mat-form-field>
      <mat-label>PIN</mat-label>
      <input matInput type="text" id="pin" name="pin" placeholder="Podaj pin" [(ngModel)]="userPin" required
             autofocus>
    </mat-form-field>
  </div>
  <div>
    <button type="submit" mat-raised-button color="accent" (click)="checkPin()"
            [disabled]="!userPin || userPin.length < 4">
      Verify
    </button>
  </div>
</form>
