import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as TICKETS from './tickets.actions';
import { Event, Ticket, TicketEvents } from '../interfaces';
import { TicketFilterStatus } from '../models/filters/ticket-status-filter';

export interface State {
  allTickets: Ticket[];
  ticketEvents: TicketEvents[];
  events: Event[];
  ticketFilter: {
    text: string;
    status: string;
  };
  checkedAlertIdList: number[];
  selectTicketAlerts: boolean;
  isDataLoading: boolean;
}

const initialState: State = {
  allTickets: [],
  ticketEvents: [],
  events: [],
  ticketFilter: {
    text: '',
    status: TicketFilterStatus.NEW
  },
  checkedAlertIdList: [],
  selectTicketAlerts: false,
  isDataLoading: false,
};

export const featureName = 'tickets';

const ticketReducer = createReducer(
  initialState,
  on(TICKETS.loadAllTicketsRequest, (state) => ({...state, isDataLoading: true})),
  on(TICKETS.loadAllTicketsSuccess, (state, {tickets}) => ({...state, allTickets: tickets, isDataLoading: false})),
  on(TICKETS.loadAllTicketsFailed, (state, ) => ({...state, isDataLoading: false})),
  on(TICKETS.getTicketsForTransportSuccess, (state, {tickets}) => {
    const ticketIdList = tickets.map(t => t.id);
    const newTickets = state.allTickets.slice().filter(t => !ticketIdList.includes(t.id));
    return {...state, allTickets: [...newTickets, ...tickets]};
  }),
  on(TICKETS.loadTicketByIdSuccess, (state, {ticket}) => ({
    ...state,
    allTickets: [...state.allTickets.filter(t => t.id !== ticket.id), ticket]
  })),
  on(TICKETS.loadEventsSuccess, (state, {events}) => ({...state, events})),
  on(TICKETS.loadTicketEventsSuccess, (state, {ticketId, events}) => {
    const newEvents = [...state.ticketEvents].filter(e => e.idTicket !== ticketId);
    return {...state, ticketEvents: [...newEvents, ...events]};
  }),
  on(TICKETS.setTicketFilter, (state, ticketFilter) => ({...state, ticketFilter})),
  on(TICKETS.startCheckTicketAlert, (state, {start}) => ({...state, selectTicketAlerts: start, checkedAlertIdList: []})),
  on(TICKETS.checkTicketAlert, (state, {alertId, checked}) => {
    const checkedAlertIdList = [...state.checkedAlertIdList].filter(id => id !== alertId);
    if (checked) {
      checkedAlertIdList.push(alertId);
    }
    return {...state, checkedAlertIdList};
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return ticketReducer(state, action);
}

export const getState = createFeatureSelector<State>(featureName);
export const getAllTickets = createSelector(getState, (state: State) => state.allTickets);
export const getEvents = createSelector(getState, (state: State) => state.events);
export const getTicketSelectedAlerts = createSelector(getState, (state: State) => state.checkedAlertIdList);
export const selectTicketAlert = createSelector(getState, (state: State) => state.selectTicketAlerts);
export const getTicketById = (ticketId: number) =>
  createSelector(getState, (state: State) => state.allTickets.find(tt => tt.id === ticketId));
export const getTicketByTransport = (transportId: number) =>
  createSelector(getState, (state: State) => state.allTickets.filter(tt => tt.idPrzejazd === transportId));
export const getTicketEventsById = (ticketId: number) =>
  createSelector(getState, (state: State) => state.ticketEvents.filter(tt => tt.idTicket === ticketId));
export const getTicketFilter = createSelector(getState, (state: State) => state.ticketFilter);
export const isDataLoading = createSelector(getState, (state: State) => state.isDataLoading);
