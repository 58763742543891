import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/modules/alerts/services';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit, OnDestroy {

  currentRouterLink: string;
  currentAlertDetail = '';

  subs = new Subscription();

  constructor(private route: ActivatedRoute, private alertSrv: AlertService) {
  }

  routeChildren: ActivatedRoute[] = this.route.children;

  ngOnInit(): void {
    this.subs.add(
      this.alertSrv.showAlertDetailInBreadcrumb$.subscribe((terminalId) => {
        this.currentAlertDetail = terminalId;
      })
    );
  }

  showCurrentRouterLinkItem(linkName: string) {
    this.currentRouterLink = linkName;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
