import { DraggedPoint } from './dragged-point';
import { MapPoint } from './map-point';

export interface MapChannelEvent {
  eventType: MapChannelEventType;
  value: MapPoint | DraggedPoint;
}

export enum MapChannelEventType {
  PointDrag,
  PointDragend,
  RemoveWaypoint
}
