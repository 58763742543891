import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import * as fromFrame from '../ngrx/frame.reducer';
import { IAwar, ICn, ICs, IInfo, IRygiel, ISat, ISatJ, ISatS } from '../models/frame';

interface Selectors {
  getInfo: MemoizedSelector<any, IInfo[]>;
  getSatJ: MemoizedSelector<any, ISatJ[]>;
  getRygiel: MemoizedSelector<any, IRygiel[]>;
  getAwar: MemoizedSelector<any, IAwar[]>;
  getCn: MemoizedSelector<any, ICn[]>;
  getCs: MemoizedSelector<any, ICs[]>;

  getInfoByTerminal: (terminalId: number) => MemoizedSelector<any, IInfo>;
  getSatByTerminal: (terminalId: number) => MemoizedSelector<any, ISat>;
  getSatSByTerminal: (terminalId: number) => MemoizedSelector<any, ISatS>;
  getSatJByTerminal: (terminalId: number) => MemoizedSelector<any, ISatJ>;
}

export class FrameSelectors {
  static _state = createFeatureSelector<fromFrame.State>('frame');
  static _selectors: Selectors;

  static getSelectors() {
    this._selectors = {
      getAwar: createSelector(this._state, fromFrame.getAwar),
      getCn: createSelector(this._state, fromFrame.getCn),
      getCs: createSelector(this._state, fromFrame.getCs),
      getInfo: createSelector(this._state, fromFrame.getInfo),
      getSatJ: createSelector(this._state, fromFrame.getSatJ),
      getRygiel: createSelector(this._state, fromFrame.getRygiel),

      getInfoByTerminal: terminalId => createSelector(
        this._state,
        data => data.info.find(t => t.idTerminal === terminalId)
      ),
      getSatByTerminal: terminalId => createSelector(
        this._state,
        data => data.sat.find(t => t.idTerminal === terminalId)
      ),
      getSatSByTerminal: terminalId => createSelector(
        this._state,
        data => data.satS.find(t => t.idTerminal === terminalId)
      ),
      getSatJByTerminal: terminalId => createSelector(
        this._state,
        data => data.satJ.find(t => t.idTerminal === terminalId)
      ),
    };

    return this._selectors;
  }
}
