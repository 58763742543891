import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseYesNoConfig } from '../../interfaces';

@Component({
  selector: 'app-base-yes-no-dialog',
  templateUrl: './base-yes-no-dialog.component.html',
  styleUrls: ['./base-yes-no-dialog.component.scss']
})
export class BaseYesNoDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public config: BaseYesNoConfig,
              public dialogRef: MatDialogRef<BaseYesNoDialogComponent>) {
  }

  yesAction(autoClose = false) {
    if (!autoClose) {
      if (this.config.yesAction) {
        this.config.yesAction();
        return false;
      }
      this.dialogRef.close();
      return;
    }
    if (this.config.yesAction) {
      this.config.yesAction();
    }
    this.dialogRef.close();
  }

  noAction() {
    if (this.config.noAction) {
      this.config.noAction();
    }
    this.dialogRef.close();
  }

}
