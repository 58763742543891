import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DriverReportsComponent } from './driver-reports.component';
import { DriverReportListComponent } from './pages/driver-report-list/driver-report-list.component';
import { DriverReportTableListComponent } from './components/driver-report-table-list/driver-report-table-list.component';
import { DriverReportListHeaderComponent } from './components/driver-report-list-header/driver-report-list-header.component';
import { CustomMaterialModule } from '../material.modules';
import { SharedModule } from '../shared/shared.module';
import { StoreModule } from '@ngrx/store';
import * as fromDriverReports from '../driver-reports/ngrx/driver-reports.reducer';
import { EffectsModule } from '@ngrx/effects';
import { DriverReportsEffects } from './ngrx/driver-reports.effects';

@NgModule({
  declarations: [
    DriverReportsComponent,
    DriverReportListComponent,
    DriverReportTableListComponent,
    DriverReportListHeaderComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    CustomMaterialModule,
    SharedModule,
    StoreModule.forFeature(fromDriverReports.featureName, fromDriverReports.reducer),
    EffectsModule.forFeature([DriverReportsEffects])
  ],
  exports: [DriverReportsComponent]
})
export class DriverReportsModule { }
