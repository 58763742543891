<div class="d-flex">
  <mat-form-field>
  <mat-label>Type to search...</mat-label>
  <input matInput name="fdw-search-ticket" [formControl]="searchControl">
  <mat-icon matSuffix class="filter-icon">search</mat-icon>
  </mat-form-field>

  <mat-form-field class="pl-2">
    <mat-label>Filter by status</mat-label>
    <mat-select class="select-input" [formControl]="statusFilterControl">
      <mat-option value="new">New</mat-option>
      <mat-option value="received">Received</mat-option>
      <mat-option value="finished">Finished</mat-option>
      <mat-option value="assigned to ticket">Assigned to ticket</mat-option>
      <mat-option value="cancelled">Cancelled</mat-option>
      <mat-option value="all">All</mat-option>
    </mat-select>
  </mat-form-field>
</div>
