<div class="mat-elevation-z8 white-bar tickets-table-container"
     *ngIf="{isLoading: isDataLoading$ | async} as driverReports">
  <div class="mat-elevation-z8 white-bar driver-reports-table-container">
    <div class="table-wrapper">
      <table mat-table [dataSource]="driverReportList"
             matSort class="mat-elevation-z8">
        <ng-container [matColumnDef]="driverReportsColumns.Id">
          <th mat-header-cell *matHeaderCellDef>{{driverReportsColumns.Id}}</th>
          <td mat-cell *matCellDef="let element">{{element.id}}</td>
        </ng-container>

        <ng-container [matColumnDef]="driverReportsColumns.Status">
          <th mat-header-cell *matHeaderCellDef>{{driverReportsColumns.Status}}</th>
          <td mat-cell *matCellDef="let element">{{element.statusZk.status}}</td>
        </ng-container>

        <ng-container [matColumnDef]="driverReportsColumns.Description">
          <th mat-header-cell *matHeaderCellDef>{{driverReportsColumns.Description}}</th>
          <td mat-cell *matCellDef="let element">{{element.opis}}</td>
        </ng-container>

        <ng-container [matColumnDef]="driverReportsColumns.Driver">
          <th mat-header-cell *matHeaderCellDef>{{driverReportsColumns.Driver}}</th>
          <td mat-cell *matCellDef="let element">{{element.kierowca.uzytkownik.imie}} {{element.kierowca.uzytkownik.nazwisko}}</td>
        </ng-container>

        <ng-container [matColumnDef]="driverReportsColumns.Route">
          <th mat-header-cell *matHeaderCellDef>{{driverReportsColumns.Route}}</th>
          <td mat-cell *matCellDef="let element">{{element.idPrzejazd?.idMscDocelowe?.nazwa}} - {{element.idPrzejazd?.idMscWyjazdu?.nazwa}}</td>
        </ng-container>

        <ng-container [matColumnDef]="driverReportsColumns.GoodsOwner">
          <th mat-header-cell *matHeaderCellDef>{{driverReportsColumns.GoodsOwner}}</th>
          <td mat-cell *matCellDef="let element">{{element.idMWlascicielTowaru?.nazwa || '--'}}</td>
        </ng-container>
        <ng-container [matColumnDef]="driverReportsColumns.ReportDate">
          <th mat-header-cell *matHeaderCellDef>{{driverReportsColumns.ReportDate}}</th>
          <td mat-cell *matCellDef="let element">{{element.czas | date : dateFormat.PIPE_LONG_24H}}</td>
        </ng-container>

        <ng-container [matColumnDef]="driverReportsColumns.Actions">
          <th mat-header-cell *matHeaderCellDef>{{driverReportsColumns.Actions}}</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon
                class="more-actions-icon">more_vert
              </mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="updateRequest(element.id, true)">
                <mat-icon>check</mat-icon>
                <span class="action-description">Confirm</span>
              </button>
              <button mat-menu-item (click)="updateRequest(element.id, false)">
                <mat-icon>close</mat-icon>
                <span class="action-description">Reject</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <ng-container matColumnDef="progress">
          <th mat-header-cell *matCellDef="let element"></th>
          <td mat-cell *matHeaderCellDef table-progress-cell [columnSpan]="displayedColumns.length"></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr
          *matHeaderRowDef="['progress']; sticky: true"
          mat-header-row
          table-progress-row
          [isVisible]="driverReports.isLoading">
        </tr>
        <tr
          *matRowDef="let element; columns: displayedColumns;"
          mat-row
        ></tr>
      </table>
      <mat-paginator #paginator
                     [length]="driverReportList.data.length"
                     appPaginationPageSize
                     showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>
</div>
