<cdk-virtual-scroll-viewport itemSize="83" class="virtual-scroll" minBufferPx="190" maxBufferPx="380">
  <div fxLayout="column" fxLayoutGap="8px">
    <div *cdkVirtualFor="let event of eventList" class="event-entry" fxLayout="column" fxLayoutGap="8px">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="row" fxLayoutGap="8px">
          <app-text-field layout="column" label="Date" class="date"
                          [value]="event.czas | date : df.PIPE_DATETIME_LABEL"></app-text-field>
          <app-text-field layout="column" label="Type" [value]="event.akcja.akcja"
                          [matTooltip]="event.akcja.opis"></app-text-field>
        </div>
        <span *ngIf="event.obslugujacy as user" fxLayout="row" fxLayoutAlign="start center">
          <app-text-field layout="column" label="User" [value]="user.nazwisko"
                          [matTooltip]="user.imie + ' ' + user.nazwisko"></app-text-field>
        </span>
      </div>
      <app-text-field label="Comment" [value]="event.opis"></app-text-field>
    </div>
  </div>
</cdk-virtual-scroll-viewport>
