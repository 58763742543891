import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

@Component({
  selector: 'td[table-progress-cell]',
  templateUrl: './table-progress-cell.component.html',
  host: {
    class: 'table-progress-cell',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableProgressCellComponent {
  @HostBinding('attr.colspan') @Input() columnSpan: number;
}
