import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonDirective } from './directives/common.directive';
import { HasRoleSuperAdminDirective } from './directives/by-role/has-role-super-admin.directive';
import { HasRolesDirective } from './directives/by-role/has-roles.directive';
import { HasPrivilegesDirective } from './directives/by-privilege/has-privileges.directive';
import { HasRoleCmDirective } from './directives/by-role/has-role-cm.directive';


@NgModule({
  declarations: [
    CommonDirective,
    HasRoleSuperAdminDirective,
    HasRolesDirective, HasRoleCmDirective,
    HasPrivilegesDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [CommonDirective, HasRoleSuperAdminDirective, HasRolesDirective, HasRoleCmDirective, HasPrivilegesDirective]
})
export class AccessControlModule {
}
