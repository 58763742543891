<mat-card class="example-card">
  <mat-card-header>
    <mat-card-title>Terminal details</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div fxFlex fxLayout="column" fxLayoutGap="10px">
      <div>
        Route estimated time range: <br/>
        {{trackInfo['pierwszy']['czas'] | date : df.PIPE_DATETIME_LABEL}}
        to {{trackInfo['ostatni']['czas'] | date : df.PIPE_DATETIME_LABEL}}
      </div>
      <div fxFlex fxLayout="column">
        <p>
          <span class="label">First frame:</span>
          <span class="value">{{trackInfo['pierwszy'].nr_rekordu}}</span>
        </p>
        <p>
          <span class="label">Last frame:</span>
          <span class="value">{{trackInfo['ostatni'].nr_rekordu}}</span>
        </p>
        <p>
          <span class="label">Number of records:</span>
          <span class="value"> {{trackInfo['il_ramek']}}</span>
        </p>
      </div>
      <div>
        Remarks: {{trackInfo['terminal'].uwagi}}<br/>
        Produced: {{trackInfo['terminal'].data_produkcji | date : df.PIPE_DATE_LABEL}}
      </div>
    </div>
  </mat-card-content>
</mat-card>
