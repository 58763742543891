import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { FormControl } from '@angular/forms';

import * as fromRoot from '../../../../../app.reducer';
import * as TRANSPORT from '../../../ngrx/transport.actions';
import * as UI from '../../../../../ngrx/ui.actions';

import { MapViewRoute, Privs, ToastType, WorkStateIds } from 'src/app/helpers/enum';
import { TransportMsg } from 'src/app/messages';
import { BaseConfirmationDialogComponent, BaseYesNoDialogComponent } from 'src/app/modules/shared/dialogs';
import { BaseDialogConfig, BaseYesNoConfig } from 'src/app/modules/shared/interfaces';
import { ITransportEntityExtended } from '../../../interfaces/transport-entity';
import { Router } from '@angular/router';

@Component({
  selector: 'app-transport-actions',
  templateUrl: './transport-actions.component.html',
  styleUrls: ['./transport-actions.component.scss']
})
export class TransportActionsComponent {
  @Input() transport: ITransportEntityExtended;
  public commentControl = new FormControl();
  eMapViewRoute: typeof MapViewRoute = MapViewRoute;
  ePrivs: typeof Privs = Privs;
  workStateIds: typeof WorkStateIds = WorkStateIds;

  constructor(private store: Store<fromRoot.State>, private dialog: MatDialog, private router: Router) {
  }

  deleteTransport(): void {
    if (this.transport.zakonczony || !this.transport.rozpoczety) {
      const config: BaseYesNoConfig = {
        title: 'Transport deletion',
        content: TransportMsg.DELETE,
        yesAction: () => this.store.dispatch(TRANSPORT.deleteTransitRequest({transitId: this.transport.id})),
        yesLabel: 'Delete',
        yesColor: 'warn',
        noLabel: 'Cancel',
        noColor: 'primary',
        autoClosure: true,
      };
      this.dialog.open(BaseYesNoDialogComponent, {
        data: config,
        id: 'BaseYesNoDialogComponent-Transport',
        position: {top: '7%'}
      });
    } else {
      const config: BaseDialogConfig = {
        title: 'Transport deletion',
        content: TransportMsg.DELETE_NOT_FINALIZED,
        confirmationLabel: 'Close',
      };
      this.dialog.open(BaseConfirmationDialogComponent, {
        data: config,
        id: 'BaseConfirmationDialogComponent-Transport',
        position: {top: '7%'}
      });
    }
  }

  transportStartStop(): void {
    if (!this.transport.kierowcaPrzejazdList.length) {
      this.store.dispatch(UI.showUserMessage({message: {message: 'Please select at least one driver.', type: ToastType.WARN}}));
      return;
    }

    const config: BaseYesNoConfig = {
      title: '',
      content: `Are you sure you want to proceed?`,
      yesLabel: 'Yes',
      yesColor: 'warn',
      noLabel: 'No',
      noColor: 'primary',
      autoClosure: true,
    };
    if (this.transport.rozpoczety && this.transport.stanPracy.id !== this.workStateIds.TRANSPORT_TESTING) {
      config.title = 'Stopping transport.';
      config.yesAction = () => {
        this.store.dispatch(TRANSPORT.stopTransportRequest({
          transportId: this.transport.id,
          onSuccess: () => {
            this.store.dispatch(TRANSPORT.loadTransportByIdRequest({transportId: this.transport.id}));
            this.store.dispatch(TRANSPORT.loadAllTransitsRequest());
            this.store.dispatch(UI.showUserMessage({message: {message: 'Transport stopped.', type: ToastType.SUCCESS}}));
          }
        }));
      };
    } else if (this.transport.zakonczony) {
      this.store.dispatch(UI.showUserMessage({message: {message: 'Transport already finished.', type: ToastType.WARN}}));
      return;
    } else {
      config.title = 'Starting transport.';

      config.yesAction = () => {
        const startTransport = (newBoltPin: boolean) => {
          this.store.dispatch(TRANSPORT.startTransportRequest({
            transportId: this.transport.id,
            newBoltPin,
            onSuccess: () => {
              this.store.dispatch(TRANSPORT.loadTransportByIdRequest({transportId: this.transport.id}));
              this.store.dispatch(TRANSPORT.loadAllTransitsRequest());
              this.store.dispatch(UI.showUserMessage({message: {message: 'Transport started.', type: ToastType.SUCCESS}}));
            }
          }));
        };

        const configBoltPin: BaseYesNoConfig = {
          title: 'New bolt code?',
          content: 'Do you want to generate new bolt code?',
          yesLabel: 'Yes',
          yesColor: 'warn',
          noLabel: 'No',
          noColor: 'primary',
          autoClosure: true,
          noAction: () => {
            startTransport(false);
          },
          yesAction: () => {
            startTransport(true);
          }
        };
        this.dialog.open(BaseYesNoDialogComponent, {
          data: configBoltPin,
          id: 'BaseYesNoDialogComponent-Transport-newboltcode',
          position: {top: '7%'}
        });
      };
    }
    this.dialog.open(BaseYesNoDialogComponent, {
      data: config,
      id: 'BaseYesNoDialogComponent-Transport-startstop',
      position: {top: '7%'}
    });
  }

  activateMonitoring(): void {
    if (!this.transport.centrum) {
      this.store.dispatch(UI.showUserMessage({message: {message: 'You have not selected Monitoring center.', type: ToastType.ERROR}}));
    } else {
      const config: BaseYesNoConfig = {
        title: '',
        content: `Are you sure you want ${this.transport.centrum.nazwa} to monitor the transport?`,
        yesAction: () => this.store.dispatch(TRANSPORT.activateMonitoringRequest({
          transportId: this.transport.id,
          onSuccess: () => {
            this.store.dispatch(TRANSPORT.loadTransportByIdRequest({transportId: this.transport.id}));
            this.store.dispatch(UI.showUserMessage({message: {message: 'Monitoring activated.', type: ToastType.SUCCESS}}));
          }
        })),
        yesLabel: 'Yes',
        yesColor: 'warn',
        noLabel: 'No',
        noColor: 'primary',
        autoClosure: true,
      };
      this.dialog.open(BaseYesNoDialogComponent, {
        data: config,
        id: 'BaseYesNoDialogComponent-ActivateMonitoring',
        position: {top: '7%'}
      });

    }
  }

  testAlarms(): void {
    if (!this.transportInTesting && this.transport.stanPracy.id !== this.workStateIds.TRANSPORT_ON_ROUTE) {
      this.store.dispatch(TRANSPORT.startTestingTransportRequest({transportId: this.transport.id}));
    } else if (this.transportInTesting || this.transport.stanPracy.id === this.workStateIds.TRANSPORT_ON_ROUTE) {
      this.router.navigate(['/map-view', this.eMapViewRoute.ALARM_TEST, this.transport.id]);
    }
  }

  get transportInTesting(): boolean {
    return this.transport.stanPracy.id === this.workStateIds.TRANSPORT_TESTING;
  }
}
