import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { filter } from 'rxjs/operators';
import { PageTitleHelper } from '../../../helpers';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})

export class BreadcrumbComponent implements OnInit {
  static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  @Output() lastRouterLinkItem_ = new EventEmitter<string>();

  menuItems: MenuItem[];
  menuItemsWithoutLastOne: MenuItem[];

  constructor(private route: ActivatedRoute, private router: Router) {
  }

  private static hideDashboardVerbiage(url: string): boolean {
    // return url === '/home' || url === '/' || url === '/access/login';
    return url === '/' || url === '/access/login';
  }

  ngOnInit(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        PageTitleHelper.setPageTitle(event.url.split('/'));
        this.menuItems = this.createBreadcrumbs(this.route.root, ['/']);
        const numberOfMenuItems = this.menuItems.length;

        const lastMenuItem = this.menuItems[numberOfMenuItems - 1];
        if (lastMenuItem) {
          this.lastRouterLinkItem_.emit(lastMenuItem.label);
          this.menuItemsWithoutLastOne = this.menuItems.slice(0, numberOfMenuItems - 1);
        }
      });
  }

  private createBreadcrumbs(
    route: ActivatedRoute,
    routerLink: string[] = ['/'],
    breadcrumbs: MenuItem[] = [{label: '', routerLink: ['/']}]): MenuItem[] {

    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routerLink_: string = (child.snapshot.routeConfig.path).slice(0, child.snapshot.routeConfig.path.length);

      if (routerLink_ !== '') {
        routerLink = [...routerLink, routerLink_];
      }

      const label = child.snapshot.data[BreadcrumbComponent.ROUTE_DATA_BREADCRUMB];
      if (label !== null && label !== undefined) {
        breadcrumbs.push({label, routerLink});
      }

      return this.createBreadcrumbs(child, routerLink, breadcrumbs);
    }
  }
}
