import { createAction, props } from '@ngrx/store';
import { ITransportAssignedDriver, ITransportEntity, ITransportEntityExtended } from '../interfaces/transport-entity';
import {
  CompanyMonit,
  ICompanyRoute,
  IDriverEntity,
  ISaveTransitInfoRequest,
  ISaveTransitInfoSuccess,
  ISaveTransitRequest,
  ITimeRange,
  Parameter,
  ParkingInfo,
  TransitParameter
} from '../interfaces';
import { WlascicielTowaru, ZestawTransportowy, ZestawTransportowyBase } from '../../../models/dto/transportSets';
import { IRouteTemplateEntity } from '../interfaces/route-template-entity';
import { ITemplateMadeRouteSection } from '../interfaces/template-made-route-section';
import { AtLeast } from '../../../helpers/types';
import { Terminal } from '../../../models/dto/terminale';
import { TransportProgress } from '../models/transport-progress-state-filter';

export const loadTransportByIdRequest = createAction(
  '[TRANSPORT] load transport by id - request',
  props<{ transportId: number }>()
);

export const loadTransitByIdSuccess = createAction(
  '[TRANSPORT] load transport by id - success',
  props<{ transit: ITransportEntityExtended }>()
);

export const loadAllTransitsRequest = createAction(
  '[TRANSPORT] load all transports - request'
);

export const loadAllTransitsSuccess = createAction(
  '[TRANSPORT] load all transports - success',
  props<{ transitList: ITransportEntity[] }>()
);

export const loadAllTransitsFailed = createAction(
  '[TRANSPORT] load all transports - failed'
);

export const loadCompanyParkingRequest = createAction(
  '[TRANSPORT] load parking - request'
);

export const loadCompanyParkingSuccess = createAction(
  '[TRANSPORT] load parking - success',
  props<{ parkingLots: ParkingInfo[] }>()
);

export const loadTransportParkingRequest = createAction(
  '[TRANSPORT] load transport parking - request',
  props<{ transportId: number }>()
);
export const loadTransportParkingSuccess = createAction(
  '[TRANSPORT] load transport parking - success',
  props<{ transportId: number, parkingLots: ParkingInfo[] }>()
);
export const assignParkingToTransitRequest = createAction(
  '[TRANSPORT] assign parking to transport - request',
  props<{ transitId: number, parkingId: number }>()
);

export const unassignParkingFromTransitRequest = createAction(
  '[TRANSPORT] unassign parking from transport - request',
  props<{ transitId: number, parkingId: number }>()
);

export const removeParkingRequest = createAction(
  '[TRANSPORT] remove parking by id - request',
  props<{ parkingId: number }>()
);

export const loadAvailableMonitParamsRequest = createAction(
  '[TRANSPORT] load available monitoring params - request',
  props<{ transitId: number }>()
);

export const loadAvailableCmTerminalListRequest = createAction('[TRANSPORT] load available terminal for CM - request');
export const loadAvailableCmTerminalListSuccess = createAction(
  '[TRANSPORT] load available terminal for CM - success',
  props<{ terminalList: Terminal[] }>()
);

export const loadAvailableMonitParamsSuccess = createAction(
  '[TRANSPORT] load available monitoring params - success',
  props<{ paramList: Parameter[] }>()
);

export const loadAssignedMonitParamsRequest = createAction(
  '[TRANSPORT] load assigned monitoring params - request',
  props<{ transitId: number }>()
);

export const loadAssignedMonitParamsSuccess = createAction(
  '[TRANSPORT] load assigned monitoring params - success',
  props<{ transitParams: TransitParameter[] }>()
);

export const openTransportSetDetails = createAction(
  '[TRANSPORT] open transport set details',
  props<{ set: ZestawTransportowy }>()
);

export const closeTransportSetDetails = createAction(
  '[TRANSPORT] close transport set details'
);

export const showTransportSetAsChosen = createAction(
  '[TRANSPORT] show transport set as chosen',
  props<{ truckMakeAndModel: string, trailerMakeAndModel: string }>()
);

export const showTransportSetAsChosenNew = createAction(
  '[TRANSPORT] show transport set as chosen - new',
  props<{ zestaw: ZestawTransportowyBase }>()
);

export const showTransportSetAsNotChosen = createAction(
  '[TRANSPORT] show transport set as not chosen'
);

export const loadRouteTemplatesRequest = createAction(
  '[TRANSPORT] load route templates - request'
);

export const loadRouteTemplatesSuccess = createAction(
  '[TRANSPORT] load route templates - success',
  props<{ routes: IRouteTemplateEntity[] }>()
);

export const loadTransportRoutesRequest = createAction(
  '[TRANSPORT] load transport routes - request',
  props<{ transportId: number }>()
);

export const loadTransitRoutesSuccess = createAction(
  '[TRANSPORT] load transport routes - success',
  props<{ transitRoutes: ICompanyRoute[], transitId: number }>()
);

export const loadTransportSetsAvailableWithinTimeRangeRequest = createAction(
  '[TRANSPORT] load transport sets available within time range - request',
  props<{ timeRange: ITimeRange }>()
);

export const loadTransportSetsAvailableWithinTimeRangeSuccess = createAction(
  '[TRANSPORT] load transport sets available within time range - success',
  props<{ setList: ZestawTransportowyBase[] }>()
);

export const loadDriversAvailableWithinTimeRangeRequest = createAction(
  '[TRANSPORT] load drivers available within time range - request',
  props<{ timeRange: ITimeRange }>()
);

export const loadDriversAvailableWithinTimeRangeSuccess = createAction(
  '[TRANSPORT] load drivers available within time range - success',
  props<{ drivers: IDriverEntity[] }>()
);

export const saveTransitAndPassChosenDriversInfoRequest = createAction(
  '[TRANSPORT] create new transport and pass chosen drivers info - request',
  props<{ transitRequest: ISaveTransitInfoRequest, successCallback?: () => void }>()
);

export const saveTransportRequest = createAction(
  '[TRANSPORT] create new transport - request',
  props<{ transportRequest: ISaveTransitRequest, successCallback?: (transportId: number) => void }>()
);

export const saveTransitAndPassChosenDriversInfoSuccess = createAction(
  '[TRANSPORT] create new transport and pass chosen drivers info - success',
  props<{ transitResponse: ISaveTransitInfoSuccess }>()
);

export const assignDriversToNewlyCreatedTransit = createAction(
  '[TRANSPORT] assign drivers to newly created transit',
  props<{ transit: ITransportEntity }>()
);

export const deleteTransitRequest = createAction(
  '[TRANSPORT] delete transport - request',
  props<{ transitId: number }>()
);

export const deleteTransitSuccess = createAction(
  '[TRANSPORT] delete transport - success',
  props<{ transitId: number }>()
);

export const toggleReadyTransportSetsPanel = createAction(
  '[TRANSPORT] toggle ready transport sets panel',
  props<{ show: boolean }>()
);

export const removeDriversFromTransitRequest = createAction(
  '[TRANSPORT] remove drivers from transport - request',
  props<{ transitId: number, drivers: number[] }>()
);

export const removesDriversFromTransitSuccess = createAction(
  '[TRANSPORT] remove drivers from transport - success'
);

export const assignDriverToTransitRequest = createAction(
  '[TRANSPORT] assign driver to transport - request',
  props<{ transitDriver: AtLeast<ITransportAssignedDriver, {idKierowca: number, idPrzejazd: number}> }>()
);

export const assignDriverToTransitSuccess = createAction(
  '[TRANSPORT] assign driver to transport - success',
  props<{ transitDriver: ITransportAssignedDriver }>()
);

export const saveTemplateMadeRouteSectionRequest = createAction(
  '[TRANSPORT] save template made route section - request',
  props<{ route: ITemplateMadeRouteSection, onSuccess: () => void }>()
);

export const saveTemplateMadeRouteSectionSuccess = createAction(
  '[TRANSPORT] save template made route section - success',
  props<{ route: ITemplateMadeRouteSection }>()
);

export const saveMonitorParamRequest = createAction(
  '[TRANSPORT] save monitoring param - request',
  props<{ param: any, onSuccess?: () => void }>()
);

export const saveMonitorParamSuccess = createAction(
  '[TRANSPORT] save monitoring param - success',
  props<{ requestedParam: any }>()
);

export const deleteMonitorParamRequest = createAction(
  '[TRANSPORT] delete monitoring param - request',
  props<{ id: number, onSuccess?: () => void }>()
);

export const deleteMonitorParamSuccess = createAction(
  '[TRANSPORT] delete monitoring param - success'
);

export const removeRouteSectionRequest = createAction(
  '[TRANSPORT] remove route section - request',
  props<{ routeId: number, onSuccess?: () => void }>()
);

export const removeRouteSectionSuccess = createAction(
  '[TRANSPORT] remove route section - success'
);

export const startTransportRequest = createAction(
  '[TRANSPORT] start transport - request',
  props<{ transportId: number, onSuccess?: () => void, newBoltPin?: boolean }>()
);

export const stopTransportRequest = createAction(
  '[TRANSPORT] stop transport - request',
  props<{ transportId: number, onSuccess?: () => void }>()
);

export const startStopTransportSuccess = createAction(
  '[TRANSPORT] start/stop transport - success'
);

export const startTestingTransportRequest = createAction(
  '[TRANSPORT] start testing transport - request',
  props<{ transportId: number }>()
);

export const startTestingTransportSuccess = createAction(
  '[TRANSPORT] start testing transport - success',
  props<{ transportId: number }>()
);

export const loadCargoOwnersRequest = createAction(
  '[TRANSPORT] load cargo owners - request'
);

export const loadCargoOwnersSuccess = createAction(
  '[TRANSPORT] load cargo owners - success',
  props<{ cargoOwners: WlascicielTowaru[] }>()
);

export const removeCargoOwnerRequest = createAction(
  '[TRANSPORT] remove cargo owner - request',
  props<{ owner: WlascicielTowaru, callback?: () => void }>()
);

export const removeCargoOwnerSuccess = createAction(
  '[TRANSPORT] remove cargo owner - success',
  props<{ id: number }>()
);

export const newCargoOwnerRequest = createAction(
  '[TRANSPORT] new cargo owner - request',
  props<{ owner: WlascicielTowaru, callback?: () => void }>()
);

export const updateCargoOwnerRequest = createAction(
  '[TRANSPORT] update cargo owner - request',
  props<{ owner: WlascicielTowaru, callback?: () => void }>()
);

export const loadCmListRequest = createAction('[TRANSPORT] load cm list - request');
export const loadCmListSuccess = createAction(
  '[TRANSPORT] load cm list - success',
  props<{ cmList: CompanyMonit[] }>()
);

export const updateTransportRequest = createAction(
  '[TRANSPORT] update transport - request',
  props<{ transport: Partial<ITransportEntity>, transportId: number }>()
);

export const setTransportFilter = createAction(
  '[TRANSPORT] set transport filter',
  props<{ text: string, state: TransportProgress }>()
);

export const activateMonitoringRequest = createAction(
  '[TRANSPORT] activate monitoring - request',
  props<{ transportId: number, onSuccess?: () => void }>()
);

export const activateMonitoringSuccess = createAction(
  '[TRANSPORT] activate monitoring - success'
);

export const generateReportRequest = createAction(
  '[TRANSPORT] generate report - request',
  props<{ transportId: number}>()
);

export const generateReportComplete = createAction(
  '[TRANSPORT] generate report - complete',
  props<{ transportId: number}>()
);
