import { createAction, props } from '@ngrx/store';
import { Event, Ticket, TicketEvents, TicketMaintainer } from '../interfaces';
import { INowaPodjetaAkcjaDTO } from '../../../models/dto/alert/podjeta-akcja';

export const loadTicketByIdRequest = createAction(
  '[TICKETS] load ticket by id - request',
  props<{ ticketId: number }>()
);

export const loadTicketByIdSuccess = createAction(
  '[TICKETS] load ticket by id - success',
  props<{ ticket: Ticket }>()
);

export const loadAllTicketsRequest = createAction(
  '[TICKETS] load all ticket-list - request'
);

export const loadAllTicketsSuccess = createAction(
  '[TICKETS] load all ticket-list - success',
  props<{ tickets: Ticket[] }>()
);

export const loadAllTicketsFailed = createAction(
  '[TICKETS] load all ticket-list - failed'
);

export const setTicketFilter = createAction(
  '[TICKETS] set ticket filter',
  props<{ text: string, status: string }>()
);

export const loadTicketEventsRequest = createAction(
  '[TICKETS] get ticket events - request',
  props<{ ticketId: number }>()
);

export const loadTicketEventsSuccess = createAction(
  '[TICKETS] get ticket events - success',
  props<{ events: TicketEvents[], ticketId: number }>()
);

export const loadEventsRequest = createAction('[TICKETS] get events - request');

export const loadEventsSuccess = createAction(
  '[TICKETS] get events - success',
  props<{ events: Event[] }>()
);

export const newTicketActionRequest = createAction(
  '[TICKETS] new ticket action - request',
  props<{ ticketAction: INowaPodjetaAkcjaDTO, callback: () => void }>()
);

export const updateTicketRequest = createAction(
  '[TICKETS] update ticket - request',
  props<{ ticket: Partial<Ticket> | TicketMaintainer, callback?: () => void }>()
);

export const checkTicketAlert = createAction(
  '[TICKETS] check ticket alert',
  props<{ alertId: number, checked: boolean }>()
);

export const startCheckTicketAlert = createAction(
  '[TICKETS] start checking ticket alerts',
  props<{ start: boolean }>()
);

export const getTicketsForTransportRequest = createAction(
  '[TICKETS] get tickets for transport - request',
  props<{ transportId: number }>()
);

export const getTicketsForTransportSuccess = createAction(
  '[TICKETS] get tickets for transport - success',
  props<{ tickets: Ticket[] }>()
);
