import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import * as fromRoot from '../app.reducer';

import { AppUserInfo } from '../models/authentication';
import { GenericNavItem, JwtBodyData, LinkAclConfig } from '../models/dto';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';
import { UserRoleNames } from '../helpers/enum';
import { MainMenuHelper } from '../helpers';
import { BaseYesNoConfig } from '../modules/shared/interfaces';
import { General } from '../messages';
import { BaseYesNoDialogComponent } from '../modules/shared/dialogs';

@Injectable({
  providedIn: 'root'
})
export class UserMenuService {

  private currentUser: AppUserInfo = null;
  private userTokenDetails: JwtBodyData;
  private isFdwAdm = false;

  constructor(
    private auth: AuthenticationService,
    private store: Store<fromRoot.State>,
    private readonly router: Router,
    private dialog: MatDialog
  ) {
    this.store.select(fromRoot.selectors.auth.getUserInfo)
      .subscribe(info => this.currentUser = info);
    this.store.select(fromRoot.selectors.auth.getUserTokenDetails)
      .pipe(
        filter(r => r !== null && r !== undefined)
      )
      .subscribe(info => {
        this.userTokenDetails = info;
        this.isFdwAdm = this.userTokenDetails.roles.indexOf(UserRoleNames.adminFdw) > -1;
      });
  }

  getSideMenuForUser(type: string): GenericNavItem[] {
    const links: GenericNavItem[] = [];
    if (!this.currentUser || !environment.sideLinks[type]) {
      return links;
    }
    const config: LinkAclConfig[] = environment.sideLinks[type];
    config.forEach(entry => {
      const link: GenericNavItem = {
        path: entry.path,
        title: entry.title
      };
      if (this.isFdwAdm) {
        links.push(link);
        return;
      }

      links.push({...link, security: {type: 'PRIVILEGES', value: entry.privileges}});
    });
    return links;
  }

  getTopMenuForUser(): GenericNavItem[] {
    if (!this.currentUser || !this.currentUser.role) {
      return null;
    }

    const topLinks: GenericNavItem[] = [];
    topLinks.push({path: '/home', title: 'Home', position: 'LEFT', icon: 'home'});

    const isFdwAdminUser = this.currentUser.role.nazwa_roli === UserRoleNames.adminFdw && +(this.currentUser?.companyId ?? 0) === 1;
    const isNonFdwAdminUser = !isFdwAdminUser;
    if (isFdwAdminUser) {
      topLinks.push({path: '/admin', title: 'Admin', position: 'RIGHT', icon: 'admin_panel_settings'});
    }

    topLinks.push(MainMenuHelper.getMapLink(isNonFdwAdminUser));
    topLinks.push(MainMenuHelper.getInventoryLink(isNonFdwAdminUser));
    topLinks.push(MainMenuHelper.getTransportLink(isNonFdwAdminUser));
    topLinks.push(MainMenuHelper.getEventAlertLink(isNonFdwAdminUser));
    topLinks.push(MainMenuHelper.getTicketsLink(isNonFdwAdminUser));
    topLinks.push(MainMenuHelper.getDriverReports(isNonFdwAdminUser));
    topLinks.push({
      path: '/access/logout', title: 'Sign out', position: 'RIGHT', itemType: 'badge', icon: 'logout',
      callbackAction: () => {
        const config: BaseYesNoConfig = {
          title: 'Are you sure?',
          content: General.LOGOUT,
          yesAction: () => {
            this.router.navigate(['/access/logout']);
          },
          yesLabel: 'Done, please sign me out',
          yesColor: 'warn',
          noLabel: 'Do not sign me out yet',
          noColor: 'primary',
          autoClosure: true,
        };
        this.dialog.open(BaseYesNoDialogComponent, {
          data: config,
          id: 'BaseYesNoDialogComponent-TicketClose',
          position: {top: '7%'}
        });
      }
    });

    return topLinks.map(link => {
      link.menuType = 'TOP';
      return link;
    });
  }

}
