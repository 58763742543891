import moment from 'moment/moment';
import { NaczepaZestawNew, SamochodZestawNew, ZestawTransportowyVehicles } from '../models/dto/transportSets';
import { VehicleBasic } from '../models/dto/vehicle';
import { ITransportEntity, ITransportEntityExtended } from '../modules/transports/interfaces/transport-entity';
import { TerminalDetails, TerminalVehicleType } from '../models/dto/terminale';


export class TransportHelper {
  static isNew(transit: { id?: number | string }): boolean {
    return transit.id === undefined || transit.id === null || isNaN(+transit.id) || +transit.id < 1;
  }

  static isValidTransset(value: ZestawTransportowyVehicles): boolean {
    return !(value.trailerSet === undefined || value.trailerSet.length < 1 || value.trailerSet.length > 1);
  }

  static getBasicVehicle(value: (NaczepaZestawNew | SamochodZestawNew)[]): VehicleBasic | undefined {
    if (value === undefined || value.length < 1) {
      return undefined;
    }

    const out = [...value].reduce((prev, current) =>
      moment(prev.czasDolaczenia).unix() > moment(current.czasDolaczenia).unix() ? prev : current);

    if (out['naczepa']) {
      return out['naczepa'];
    }
    if (out['samochod']) {
      return out['samochod'];
    }

    return undefined;
  }

  static getVehicleDescription(vehicle: VehicleBasic): string {
    if (!vehicle) {
      return 'No info';
    }

    return `${vehicle.model.marka.marka} ${vehicle.model.model}: ${vehicle.nrRej}`;
  }

  static getTerminalList(transport: ITransportEntity | ITransportEntityExtended): TerminalDetails[] {
    const terminalList: TerminalDetails[] = [];
    const trailer = TransportHelper.getBasicVehicle(transport.zestaw.trailerSet);
    if (trailer) {
      terminalList.push({
        id: trailer.naczepaTerminal
          .find(t => t.czasUsuniecia === null)
          ?.terminal.id,
        vehicleType: TerminalVehicleType.TRAILER,
        vehiclePlate: trailer.nrRej,
        vehicleId: trailer.id
      });
    }

    const truck = TransportHelper.getBasicVehicle(transport.zestaw.truckSet);
    if (truck) {
      terminalList.push({
        id: truck.samochodTerminal
          .find(t => t.czasUsuniecia === null)
          ?.terminal.id,
        vehicleType: TerminalVehicleType.TRUCK,
        vehiclePlate: truck.nrRej,
        vehicleId: truck.id
      });
    }

    return terminalList.filter(info => info.id !== undefined);
  }

  static getTerminalIdList(transport: ITransportEntity | ITransportEntityExtended): number[] {
    const terminalList = [];
    const trailer = TransportHelper.getBasicVehicle(transport.zestaw.trailerSet);
    if (trailer) {
      terminalList.push(
        trailer.naczepaTerminal
          .find(t => t.czasUsuniecia === null)
          ?.terminal.id);
    }

    const truck = TransportHelper.getBasicVehicle(transport.zestaw.truckSet);
    if (truck) {
      terminalList.push(
        truck.samochodTerminal
          .find(t => t.czasUsuniecia === null)
          ?.terminal.id);
    }

    return terminalList.filter(id => id !== undefined);
  }
}
