import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import { EnvHelper } from '../helpers';
import { environment } from '../../environments/environment';
import { IAwar, ICn, ICs, IInfo, IRygiel, ISat, ISatJ, ISatS } from '../models/frame';
import { Terminal } from '../models/dto/terminale';

@Injectable({
  providedIn: 'root'
})
export class LastFrameDataService {
  readonly endpoint = EnvHelper.getApiUrl();
  readonly fr = environment.apiModules.Pakiet;

  constructor(private http: HttpClient) {
  }

  get awar$(): Observable<IAwar[]> {
    return this.http.get<IAwar[]>(this.endpoint + this.fr.awar);
  }

  get cs$(): Observable<ICs[]> {
    return this.http.get<ICs[]>(this.endpoint + this.fr.cs);
  }

  get cn$(): Observable<ICn[]> {
    return this.http.get<ICn[]>(this.endpoint + this.fr.cn);
  }

  get rygiel$(): Observable<IRygiel[]> {
    return this.http.get<IRygiel[]>(this.endpoint + this.fr.rygiel);
  }

  getInfoFrames(terminalList?: Terminal[]): Observable<IInfo[]> {
    if (terminalList && terminalList.length > 0) {
      const params = new HttpParams()
        .set('terminalIds', terminalList.map(r => r.id).join(','));
      return this.http.get<IInfo[]>(this.endpoint + this.fr.info + '/terminals', {params});
    }
    return this.http.get<IInfo[]>(this.endpoint + this.fr.info);
  }

  getSatFrames(terminalList?: Terminal[]): Observable<ISat[]> {
    if (terminalList && terminalList.length > 0) {
      const params = new HttpParams()
        .set('terminalIds', terminalList.map(r => r.id).join(','));
      return this.http.get<ISat[]>(this.endpoint + this.fr.sat + '/terminals', {params});
    }
    return this.http.get<ISat[]>(this.endpoint + this.fr.sat);
  }

  getSatSFrames(terminalList?: Terminal[]): Observable<ISatS[]> {
    if (terminalList && terminalList.length > 0) {
      const params = new HttpParams()
        .set('terminalIds', terminalList.map(r => r.id).join(','));
      return this.http.get<ISatS[]>(this.endpoint + this.fr.satS + '/terminals', {params});
    }
    return this.http.get<ISatS[]>(this.endpoint + this.fr.satS);
  }

  getSatJFrames(terminalList?: Terminal[]): Observable<ISatJ[]> {
    if (terminalList && terminalList.length > 0) {
      const params = new HttpParams()
        .set('terminalIds', terminalList.map(r => r.id).join(','));
      return this.http.get<ISatJ[]>(this.endpoint + this.fr.satJ + '/terminals', {params});
    }
    return this.http.get<ISatJ[]>(this.endpoint + this.fr.satJ);
  }

  getSatTerminalFrame(id: Terminal['id']): Observable<unknown> {
    return this.http.get<unknown>(this.endpoint + this.fr.satTerminal + `/${id}`);
  }

  getSatSTerminalFrame(id: Terminal['id']): Observable<unknown> {
    return this.http.get<unknown>(this.endpoint + this.fr.satSTerminal + `/${id}`);
  }

  getSatJTerminalFrame(id: Terminal['id']): Observable<unknown> {
    return this.http.get<unknown>(this.endpoint + this.fr.satJTerminal + `/${id}`);
  }
}
