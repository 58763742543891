// [admin] load routing list

import { Action } from '@ngrx/store';
import { RoutingApi } from '../models/admin';
import { Uprawnienie } from '../models/authentication';

export const LOAD_ROUTING_LIST_REQUEST = '[ADMIN] load routing list - request';
export const LOAD_ROUTING_LIST_SUCCESS = '[ADMIN] load routing list - success';

export class LoadRoutingListRequest implements Action {
  readonly type = LOAD_ROUTING_LIST_REQUEST;
}

export class LoadRoutingListSuccess implements Action {
  readonly type = LOAD_ROUTING_LIST_SUCCESS;

  constructor(public payload: RoutingApi[]) {
  }
}

// [admin] new routing entry

export const NEW_ROUTING_ENTRY_REQUEST = '[ADMIN] new routing entry - request';
export const NEW_ROUTING_ENTRY_SUCCESS = '[ADMIN] new routing entry - success';

export class NewRoutingEntryRequest implements Action {
  readonly type = NEW_ROUTING_ENTRY_REQUEST;

  constructor(public payload: RoutingApi) {
  }
}

export class NewRoutingEntrySuccess implements Action {
  readonly type = NEW_ROUTING_ENTRY_SUCCESS;

  constructor(public payload: RoutingApi) {
  }

}

// [admin] update routing entry

export const UPDATE_ROUTING_ENTRY_REQUEST = '[ADMIN] update routing entry - request';
export const UPDATE_ROUTING_ENTRY_SUCCESS = '[ADMIN] update routing entry - success';

export class UpdateRoutingEntryRequest implements Action {
  readonly type = UPDATE_ROUTING_ENTRY_REQUEST;

  constructor(public payload: RoutingApi) {
  }
}

export class UpdateRoutingEntrySuccess implements Action {
  readonly type = UPDATE_ROUTING_ENTRY_SUCCESS;

  constructor(public payload: RoutingApi) {
  }
}

// [admin] remove routing entry

export const REMOVE_ROUTING_ENTRY_REQUEST = '[ADMIN] remove routing entry - request';
export const REMOVE_ROUTING_ENTRY_SUCCESS = '[ADMIN] remove routing entry - success';

export class RemoveRoutingEntryRequest implements Action {
  readonly type = REMOVE_ROUTING_ENTRY_REQUEST;

  constructor(public payload: RoutingApi) {
  }
}

export class RemoveRoutingEntrySuccess implements Action {
  readonly type = REMOVE_ROUTING_ENTRY_SUCCESS;

}

// [admin] reload proxy routing

export const RELOAD_PROXY_ROUTING_REQUEST = '[ADMIN] reload proxy routing - request';
export const RELOAD_PROXY_ROUTING_SUCCESS = '[ADMIN] reload proxy routing - success';

export class ReloadProxyRoutingRequest implements Action {
  readonly type = RELOAD_PROXY_ROUTING_REQUEST;
}

export class ReloadProxyRoutingSuccess implements Action {
  readonly type = RELOAD_PROXY_ROUTING_SUCCESS;
}

// [admin] new privilege

export const NEW_PRIVILEGE_REQUEST = '[ADMIN] new privilege - request';
export const NEW_PRIVILEGE_SUCCESS = '[ADMIN] new privilege - success';

export class NewPrivilegeRequest implements Action {
  readonly type = NEW_PRIVILEGE_REQUEST;

  constructor(public payload: Uprawnienie) {
  }
}

export class NewPrivilegeSuccess implements Action {
  readonly type = NEW_PRIVILEGE_SUCCESS;

  constructor(public payload: Uprawnienie) {
  }
}

// [admin] update privilege

export const UPDATE_PRIVILEGE_REQUEST = '[ADMIN] update privilege - request';
export const UPDATE_PRIVILEGE_SUCCESS = '[ADMIN] update privilege - success';

export class UpdatePrivilegeRequest implements Action {
  readonly type = UPDATE_PRIVILEGE_REQUEST;

  constructor(public payload: Uprawnienie) {
  }
}

export class UpdatePrivilegeSuccess implements Action {
  readonly type = UPDATE_PRIVILEGE_SUCCESS;

  constructor(public payload: Uprawnienie) {
  }
}

// [admin] load privileges

export const LOAD_PRIVILEGES_REQUEST = '[ADMIN] load privileges - request';
export const LOAD_PRIVILEGES_SUCCESS = '[ADMIN] load privileges - success';

export class LoadPrivilegesRequest implements Action {
  readonly type = LOAD_PRIVILEGES_REQUEST;
}

export class LoadPrivilegesSuccess implements Action {
  readonly type = LOAD_PRIVILEGES_SUCCESS;

  constructor(public payload: Uprawnienie[]) {
  }
}

export type AdminActions = LoadRoutingListRequest | LoadRoutingListSuccess
  | NewRoutingEntryRequest | NewRoutingEntrySuccess
  | UpdateRoutingEntryRequest | UpdateRoutingEntrySuccess
  | RemoveRoutingEntryRequest | RemoveRoutingEntrySuccess
  | ReloadProxyRoutingRequest | ReloadProxyRoutingSuccess
  | NewPrivilegeRequest | NewPrivilegeSuccess | UpdatePrivilegeRequest | UpdatePrivilegeSuccess
  | LoadPrivilegesRequest | LoadPrivilegesSuccess;
