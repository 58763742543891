import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription, timer } from 'rxjs';
import { debounceTime, filter, take, tap } from 'rxjs/operators';
import moment from 'moment';

import * as fromRoot from '../../../app.reducer';
import * as UI from '../../../ngrx/ui.actions';
import * as MAP from '../../../ngrx/map.actions';
import * as FRAME from '../../../ngrx/frame.actions';
import * as TRANSPORT from '../../transports/ngrx/transport.actions';
import * as fromTransport from '../../transports/ngrx/transport.reducer';

import { HereService } from '../services/here.service';
import { RightDrawerService } from '../../shared/services';
import { SearchRouteComponent, ShippingPointComponent, TransportAlertComponent } from '../components';
import { MapObjectsHelper } from '../main/here/helpers';
import { ParkingViewComponent, TransitAlarmTestComponent } from '../../transports/components';
import { MapViewRoute, SvgMapIcon, ToastType, TransportFormStep } from '../../../helpers/enum';
import { Strings, TransportHelper } from '../../../helpers';
import { LocationService } from '../services/location.service';
import { GenericPoint } from '../../../models/map';
import { Coords, CoordsMap } from '../../shared/interfaces';
import { MapMsg, TransportMsg } from '../../../messages';
import { TransportOverviewComponent } from '../../transports/components/transport-overview';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MarkTermianls } from '../../../ngrx/map.actions';
import { ObjectInformationComponent } from '../main/here/object-information/object-information.component';
import { IInfo } from '../../../models/frame';
import { Terminal } from '../../../models/dto/terminale';
import { isNil } from 'lodash';

@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss']
})
export class ViewerComponent implements OnInit, OnDestroy {
  H = window['H'];

  @ViewChild('map', {static: true}) mapContainer: ElementRef;
  map: any;
  rightDrawerName: string = null;

  subs = new Subscription();

  private terminalIdListFromTransport: number[];
  private defaultTimeout = 1000;

  constructor(private hereService: HereService,
              private route: ActivatedRoute,
              private rightDrawer: RightDrawerService,
              private router: Router,
              private dialog: MatDialog,
              private store: Store<fromRoot.State>) {
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.rightDrawer.close(this.rightDrawerName);
    this.store.dispatch(MAP.CenterMap({}));
    this.store.dispatch(MAP.AdhocPoints({points: []}));
    this.store.dispatch(MAP.AdhocLines({lines: []}));
    this.store.dispatch(MAP.ReverseGeocodeSuccess({}));
  }

  ngOnInit(): void {
    this.store.dispatch(UI.setSideLinks({links: []}));
    this.map = this.hereService.initializeMap(this.H, this.mapContainer);
    setTimeout(() => this.onResize());

    this.subs.add(
      this.store.select(fromRoot.selectors.ui.getWindowResizeEvent)
        .subscribe(action => {
          if (action && action.length > 0) {
            this.onResize();
          }
        })
    );

    let pointHash = '';
    let lineHash = '';
    let group;
    this.subs.add(
      combineLatest([
        this.store.select(fromRoot.selectors.map.getAdhocPoints),
        this.store.select(fromRoot.selectors.map.getAdhocLines)
      ])
        .pipe(debounceTime(500))
        .subscribe(([points, lines]) => {
          if (lines.length > 0 && lineHash !== Strings.getObjectHash(lines)) {
            lineHash = Strings.getObjectHash(lines);
            group = this.hereService.addGenericLineGroup(lines, 'adhoc-lines');
          }
          setTimeout(() => {
            if (points.length > 0 && pointHash !== Strings.getObjectHash(points)) {
              const rootRouteType = rootRoute.trim().toLowerCase();
              const pointGroups = new Map<string, GenericPoint[]>();
              pointHash = Strings.getObjectHash(points);

              points.forEach(point => {
                const tmpGroupName = point.groupName || 'default';
                if (pointGroups.has(tmpGroupName)) {
                  pointGroups.get(tmpGroupName).push(point);
                } else {
                  pointGroups.set(tmpGroupName, [point]);
                }
              });

              pointGroups.forEach((groupPoints, groupName) => {
                this.hereService.addGenericPointGroup(groupPoints, MapObjectsHelper.svgAlertEventDomObject, true, groupName);
              });

              if (group && rootRouteType !== MapViewRoute.ALERT) {
                this.hereService.centerMap(group);
              }
            }
          }, 300);
        })
    );

    this.subs.add(
      this.store.select(fromRoot.selectors.map.getMapCenter)
        .pipe(
          filter(r => r.point !== null),
          debounceTime(500)
        )
        .subscribe(data => {
          HereService.map.setCenter(LocationService.getCoordsMap(data.point));
          HereService.map.setZoom(data.zoom);
        })
    );

    const rootRoute = this.route.snapshot.routeConfig.path.split('/')[0];
    const routeParams = this.route.snapshot.params;

    this.handleRoute(rootRoute, routeParams);
  }

  onResize($event = null) {
    this.map.getViewPort().resize();
  }

  private handleRoute(rootRoute: string, params: any) {
    const transportId = +params['transportId'];
    const rootRouteType = rootRoute.trim().toLowerCase();

    switch (rootRouteType) {
      case MapViewRoute.ALARM_TEST: {
        this.rightDrawer.open(
          TransitAlarmTestComponent,
          {
            fallback: () => this.router.navigate(['transport', 'edit', transportId, TransportFormStep.Overview]),
            transportId
          }
        );
        break;
      }

      case MapViewRoute.TRANSPORT_NEW_ROUTE: {
        this.store.select(fromRoot.selectors.map.getViewerExtraData).pipe(
          filter(r => r !== undefined),
          take(1)
        ).subscribe(data => {
          this.rightDrawer.open(SearchRouteComponent, data, false);
          this.rightDrawerName = SearchRouteComponent.myname;
        });
        break;
      }

      case MapViewRoute.TRANSPORT_PROGRESS: {
        this.loadTransportRoute(transportId);
        this.loadParkingLots(transportId);

        this.subs.add(timer(0, 5 * this.defaultTimeout).subscribe(() => {
          this.store.dispatch(FRAME.infoRequest());
        }));

        this.subs.add(
          combineLatest([
            this.store.select(fromRoot.selectors.frame.getInfo),
            this.store.select(fromRoot.selectors.auth.getAuthIsAuthenticated),
            this.store.select(fromRoot.selectors.devices.getTerminals),
          ])
            .pipe(filter(([terminalList, isAuthenticated, terminals]: [IInfo[], boolean, Terminal[]]) =>
              terminalList && terminalList.length > 0 && isAuthenticated))
            .subscribe(([terminalList, _, terminals]) => {
              terminalList = terminalList
                .filter(terminal => this.terminalIdListFromTransport?.includes(terminal.idTerminal))
                .filter(t => moment().diff(moment(t.czas), 'days') < 7)
                .filter(t => LocationService.coordsValidator(t));

              if (!terminalList.length) {
                this.store.dispatch(UI.showUserMessage({
                  message: {message: TransportMsg.NO_RECENT_TRACKING_INFO, type: ToastType.WARN}
                }));
                return;
              }

              const markers = terminalList.map((terminalPosition: IInfo) => {
                if (
                  !LocationService.coordsValidator(terminalPosition)
                  || moment().diff(moment(terminalPosition.czas), 'days') > 30
                ) {
                  return;
                }

                const coords: CoordsMap | undefined = LocationService.getCoordsMap(terminalPosition);

                const terminal: Terminal = terminals.find(term => term.id === terminalPosition.idTerminal);
                const marker = new HereService.H.map.DomMarker(coords, {
                  data: {ost_data: terminalPosition.czas, terminal: terminal, ost_dane: terminalPosition},
                  icon: new HereService.H.map.DomIcon(
                    MapObjectsHelper.getIconForMarkerSvg(terminalPosition)
                  )
                });
                marker.addEventListener('tap', (evt) => {
                  const pointData = evt.target.getData();

                  const dialogConfig = new MatDialogConfig();
                  dialogConfig.data = pointData;
                  if (!!pointData?.terminal?.id) {
                    this.store.dispatch(MarkTermianls({mapPointData: [pointData.terminal]}));
                  }

                  this.dialog.open(ObjectInformationComponent, dialogConfig);
                });
                return marker;
              });

              this.hereService.addCustomGroupObjects(markers, 'transport-terminal-location');
            })
        );
        break;
      }

      case MapViewRoute.SHIPPING_POINT: {
        this.store.select(fromRoot.selectors.map.getViewerExtraData).pipe(
          filter(r => r !== undefined),
          take(1)
        ).subscribe(data => {
          this.rightDrawer.open(ShippingPointComponent, {...params, ...data});
          this.rightDrawerName = ShippingPointComponent.myname;
        });
        break;
      }

      case MapViewRoute.ALERT: {
        this.loadTransportRoute(transportId);

        this.rightDrawer.open(TransportAlertComponent, {...params, id: +params.alertId});
        this.rightDrawerName = TransportAlertComponent.myname;

        break;
      }

      case MapViewRoute.TRANSPORT_PARKING_LIST: {
        this.store.dispatch(UI.showUserMessage({
          message: {
            type: ToastType.INFO,
            message: MapMsg.MARKER_SELECT
          }
        }));
        break;
      }

      case MapViewRoute.PARKING_LIST: {
        this.store.dispatch(UI.showUserMessage({
          message: {
            type: ToastType.INFO,
            message: MapMsg.MARKER_PREVIEW
          }
        }));
        break;
      }

      case MapViewRoute.PARKING: {
        this.store.select(fromRoot.selectors.map.getViewerExtraData)
          .pipe(
            take(1),
            tap((data) => {
              if (!data || !data.parking) {
                this.router.navigateByUrl('/transport/parking');
              }
            }),
            filter(r => r !== null && r.parking !== undefined)
          )
          .subscribe(data => {
            this.rightDrawer.open(ParkingViewComponent, {mode: 'map-view', data: data.parking, callbackData: data.callbackData});
            this.rightDrawerName = ParkingViewComponent.myname;
          });
        break;
      }

      case MapViewRoute.TRANSPORT_ROUTE: {
        this.loadTransportRoute(transportId);
        this.loadParkingLots(transportId, false);

        break;
      }
    }
  }

  private loadTransportRoute(transportId: number): void {
    this.store.dispatch(TRANSPORT.loadTransportRoutesRequest({transportId}));

    this.subs.add(
      this.store.select(fromTransport.getRoutesByTransitId(transportId))
        .pipe(
          filter(routes => !isNil(routes) && !!routes.length),
          take(1)
        )
        .subscribe(routes => {
          if (typeof routes !== undefined && routes?.length > 0) {
            const lines = routes
              .map(element => JSON.parse(element.punkty) as string[])
              .map(e => {
                return e
                  .map(str => str.split(','))
                  .map(([gpsNs, gpsEw]) => {
                    return {gpsEw: +gpsEw, gpsNs: +gpsNs} as Coords;
                  });
              });
            this.store.dispatch(MAP.AdhocLines({lines}));
          } else {
            this.store.dispatch(UI.showUserMessage({
              message: {
                title: 'Routes',
                message: TransportMsg.PROGRESS_ROUTES,
                type: ToastType.WARN, config: {timeOut: 15 * this.defaultTimeout}
              }
            }));
          }
        })
    );
  }

  private loadParkingLots(transportId: number, openDrawer = true): void {
    this.store.dispatch(TRANSPORT.loadCompanyParkingRequest());
    this.store.dispatch(TRANSPORT.loadTransportByIdRequest({transportId}));

    this.subs.add(
      combineLatest([
        this.store.select(fromTransport.getCompanyParkingLots),
        this.store.select(fromTransport.getCurrentTransit)
      ])
        .pipe(
          debounceTime(350),
          filter(r => r[0] !== undefined && r[1] !== undefined && r[1] !== null),
          take(1)
        )
        .subscribe(([parkingLots, transport]) => {
          if (parkingLots.length === 0) {
            this.store.dispatch(UI.showUserMessage({
              message: {
                title: 'Parking lots',
                message: TransportMsg.PROGRESS_PARKING_LOTS,
                type: ToastType.WARN, config: {timeOut: 10 * this.defaultTimeout}
              }
            }));

            return;
          }

          this.terminalIdListFromTransport = TransportHelper.getTerminalIdList(transport);

          const availableParkingPoints = parkingLots.map(parking => {
            if (transport.parkingPrzejazdList.find(assignedParking => assignedParking.parking.id === parking.id)) {
              return;
            }
            const coords = LocationService.getCoordsNamedPL(parking);
            return {
              ...coords,
              id: parking.id,
              label: parking.nazwa,
              iconType: SvgMapIcon.PARKING,
            } as GenericPoint;
          }).filter(r => r !== undefined);

          const selectedParkingLots = transport.parkingPrzejazdList.map(transportParking => {
            const coords = LocationService.getCoordsNamedPL(transportParking.parking);
            return {
              ...coords,
              id: transportParking.parking.id,
              label: transportParking.parking.nazwa,
              iconType: SvgMapIcon.PARKING_SELECTED,
            } as GenericPoint;
          });

          if (openDrawer) {
            this.rightDrawer.open(TransportOverviewComponent, {transport}, false);
            this.rightDrawerName = TransportOverviewComponent.myname;
          }

          this.store.dispatch(MAP.AdhocPoints({
            points: [...availableParkingPoints, ...selectedParkingLots]
          }));
        })
    );
  }
}
