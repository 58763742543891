import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';

import { CustomMaterialModule } from '../material.modules';
import * as fromAlert from './ngrx/alert.reducer';
import { AlertEffects } from './ngrx/alert.effects';
import {
  AlertDataComponent,
  AlertEventsListComponent,
  AlertTableListComponent,
  AlertTileComponent,
  AlertTileListComponent,
  AlertTimelineComponent,
  EventTileComponent,
  EventTileListComponent,
  TransportDataComponent
} from './components';
import { SharedModule } from '../shared/shared.module';
import { AlertsComponent, TransportAlertDetailsComponent, TransportAlertListComponent } from './pages';
import { TableModule } from 'primeng/table';
import { IsNewAlertPipe } from './pipes/is-new-alert.pipe';

@NgModule({
  declarations: [
    AlertsComponent,
    TransportAlertListComponent,
    TransportAlertDetailsComponent,
    AlertTileComponent,
    AlertTableListComponent,
    AlertTileListComponent,
    AlertTimelineComponent,
    AlertDataComponent,
    AlertEventsListComponent,
    TransportDataComponent,
    EventTileComponent,
    EventTileListComponent,
    IsNewAlertPipe,
  ],
  imports: [
    ScrollingModule,
    TableVirtualScrollModule,
    CommonModule,
    CustomMaterialModule,
    RouterModule, SharedModule,
    StoreModule.forFeature(fromAlert.featureName, fromAlert.reducer),
    EffectsModule.forFeature([AlertEffects]),
    TableModule
  ],
  exports: [
    AlertsComponent,
    TransportAlertListComponent,
    AlertTileComponent,
    EventTileComponent,
    EventTileListComponent,
  ]
})
export class AlertsModule {
}
