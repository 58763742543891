import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../app.reducer';
import * as MAP from '../../../../ngrx/map.actions';
import { MapColorScheme } from '../../../../helpers/enum';

@Component({
  selector: 'app-map-styles-and-colors',
  templateUrl: './map-styles-and-colors.component.html',
  styleUrls: ['./map-styles-and-colors.component.scss']
})
export class MapStylesAndColorsComponent {

  formFilter = new FormGroup({
    markerColoringScheme: new FormControl(MapColorScheme.DELAY),
    reduceMarkersAmount: new FormControl(true)
  });

  constructor(private store: Store<fromRoot.State>) {
  }
  applyFilters() {
    this.store.dispatch(MAP.NewMapFilters({filters: this.formFilter.value}));
  }

}
