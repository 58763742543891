import { IDriverReport } from '../interfaces';
import { Filter } from './filter';
import { DriverReportStatusNameEnum } from '../enums/driver-report-status.enum';
import { DriverReportsStatusHelper } from './driver-reports-status-helper';

export class DriverReportStatusFilter implements Filter {
  constructor(private readonly status: string) {
  }

  isMatching(driverReport: IDriverReport): boolean {
    switch (this.status) {
      case DriverReportStatusNameEnum.NEW: {
        return DriverReportsStatusHelper.isDriverReportNew(driverReport);
      }
      case DriverReportStatusNameEnum.RECEIVED: {
        return DriverReportsStatusHelper.isDriverReportReceived(driverReport);
      }
      case DriverReportStatusNameEnum.FINISHED: {
        return DriverReportsStatusHelper.isDriverReportFinished(driverReport);
      }
      case DriverReportStatusNameEnum.CANCELLED: {
        return DriverReportsStatusHelper.isDriverReportCanceled(driverReport);
      }
      case DriverReportStatusNameEnum.ASSIGNED_TO_TICKET: {
        return DriverReportsStatusHelper.isDriverReportAssignedToTicket(driverReport);
      }
    }
    return true;
  }
}
