import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { EnvHelper } from '../../../helpers';
import { WlascicielTowaru } from '../../../models/dto/transportSets';
import { CargoOwnerStatus } from '../../../helpers/enum';

@Injectable({
  providedIn: 'root'
})
export class CargoOwnerService {
  apiConfig = environment.apiModules;

  constructor(
    private readonly http: HttpClient
  ) {
  }

  loadCargoOwnerList(statusId: number = CargoOwnerStatus.ACTIVE): Observable<WlascicielTowaru[]> {
    return this.http.get<WlascicielTowaru[]>(
      EnvHelper.getApiUrl() + this.apiConfig.Transit.wlasciciel_towaru + '_odczyt/' + statusId
    );
  }

  removeCargoOwner(owner: WlascicielTowaru): Observable<unknown> {
    return this.http.delete(
      EnvHelper.getApiUrl() + this.apiConfig.Transit.wlasciciel_towaru + '_edycja/' + owner.id, {body: owner}
    );
  }

  newCargoOwner(owner: WlascicielTowaru): Observable<unknown> {
    return this.http.post(
      EnvHelper.getApiUrl() + this.apiConfig.Transit.wlasciciel_towaru + '_edycja', owner
    );
  }

  updateCargoOwner(owner: WlascicielTowaru): Observable<unknown> {
    return this.http.put(
      EnvHelper.getApiUrl() + this.apiConfig.Transit.wlasciciel_towaru + '_edycja/' + owner.id, owner
    );
  }
}
