import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { interval, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import * as fromRoot from '../../../../app.reducer';
import * as fromTickets from '../../ngrx/tickets.reducer';
import * as UI from '../../../../ngrx/ui.actions';
import * as TICKETS from '../../ngrx/tickets.actions';

import { Ticket } from '../../interfaces';
import * as TRANSPORT from '../../../transports/ngrx/transport.actions';
import * as fromTransport from '../../../transports/ngrx/transport.reducer';
import { IDriverEntityDTO } from '../../../../models/dto/transit';

@Component({
  selector: 'app-ticket-details',
  templateUrl: './ticket-details.component.html',
  styleUrls: ['./ticket-details.component.scss']
})
export class TicketDetailsComponent implements OnInit, OnDestroy {
  ticket: Ticket;
  drivers: IDriverEntityDTO[];
  subs = new Subscription();
  ticketInterval = interval(20 * 1000);
  idPrzejazd: number;

  constructor(private route: ActivatedRoute, private store: Store<fromRoot.State>) {
    this.subs.add(
      this.ticketInterval
        .pipe(filter(() => this.ticket !== undefined && this.ticket.id !== undefined))
        .subscribe(() => {
          this.store.dispatch(TICKETS.loadTicketByIdRequest({ticketId: this.ticket.id}));
        })
    );

    this.subs.add(
      this.route.paramMap
        .pipe(
          filter(res => res.get('id') !== null),
          map(res => +res.get('id')),
          switchMap(ticketId => this.store.select(fromTickets.getTicketById(ticketId))),
          filter(data => data !== undefined)
        )
        .subscribe(ticketData => {
          this.ticket = ticketData;

          if (this.idPrzejazd !== ticketData.idPrzejazd) {
            this.idPrzejazd = ticketData.idPrzejazd;
            this.store.dispatch(TRANSPORT.loadTransportByIdRequest({transportId: ticketData.idPrzejazd}));
          }
        })
    );
    this.store.dispatch(UI.switchLeftDrawer({show: false}));
  }

  ngOnInit(): void {
    this.subs.add(
      this.store.select(fromTransport.getCurrentTransit)
        .pipe(
          filter(r => r?.id === this.ticket?.idPrzejazd),
          map(transportData => transportData?.kierowcaPrzejazdList.map(driver => driver?.idKierowca))
        )
        .subscribe(driversList => {
          this.drivers = driversList;
        })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.store.dispatch(UI.switchLeftDrawer({show: true}));
  }
}
