<div fxLayout="column" class="mat-elevation-z3 main">
  <h5>Drivers</h5>
  <div fxLayout="column" fxLayoutGap="8px">
    <ng-container *ngIf="kierowcaPrzejazdList && kierowcaPrzejazdList.length > 0; else noDriverAssigned">
      <div *ngFor="let driver of kierowcaPrzejazdList" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center"
           class="mat-elevation-z1 driver">
        <mat-icon>person_outline</mat-icon>
        <span>{{driver.idKierowca.id}}:</span>

        <span>{{driver.idKierowca.uzytkownik.imie}} {{driver.idKierowca.uzytkownik.nazwisko}}</span>
        <span fxFlex></span>
        <div fxLayout="column" fxLayoutGap="4px">
          <div fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="start center">
            <mat-icon>call</mat-icon>
            <div fxLayout="column" fxLayoutGap="4px" fxLayoutAlign="start start">
              <ng-container *ngIf="driver.idKierowca.uzytkownik.tel1; else noPhone">
                <span matTooltip="Primary phone"> {{driver.idKierowca.uzytkownik.tel1}}</span>
              </ng-container>
              <ng-container *ngIf="driver.idKierowca.uzytkownik.tel2">
                <span matTooltip="Secondary phone" class="backup-phone">{{driver.idKierowca.uzytkownik.tel2}}</span>
              </ng-container>
            </div>
          </div>
          <span *ngIf="driver.idKierowca.potwierdz">Password: {{driver.idKierowca.potwierdz}}</span>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<ng-template #noDriverAssigned>
  <div class="error">There is no driver assigned to the transport.</div>
</ng-template>
<ng-template #noPhone>
  <div class="error">Phone not defined.</div>
</ng-template>
