<div class="menu-with-logo" fxLayout="column" fxLayoutAlign="space-between">

  <div class="menu-items" fxLayout="column">

    <ul class="menu-items-top">
      <ng-container *ngIf="(isUserAuth$ | async)">
        <li *ngFor="let link of leftTopLinks; index as i" class="mat-body-2"
            fxLayout fxLayoutAlign="start center"
            routerLinkActive="active"
            routerLink="{{link.path}}" style="position: relative">
          <span><mat-icon [innerHTML]="link.icon" class="mat-icon material-icons-outlined"></mat-icon></span>
          {{link.title}}
          <div class="event-notice" *ngIf="link.needsAlertNotification">
            <event-quantity-badge [quantity]="newAlertIndicator$ | async" link="events/alerts" [badgeType]="eventType.ALERTS"></event-quantity-badge>
          </div>
          <div class="event-notice" *ngIf="link.needsTicketNotification">
            <event-quantity-badge [quantity]="newTicketIndicator$ | async" link="/events/tickets/ticketList" [badgeType]="eventType.TICKETS"></event-quantity-badge>
          </div>
          <div class="event-notice" *ngIf="link.needsDriverReportsNotification">
            <event-quantity-badge [quantity]="newDriverReportIndicator$ | async" link="/events/driver-reports/driver-report-list" [badgeType]="eventType.DRIVER_REPORTS"></event-quantity-badge>
          </div>
        </li>
      </ng-container>
    </ul>

    <div class="divider"></div>

    <ul class="menu-items-bottom">
      <ng-container *ngFor="let link of leftBottomLinks">
        <li *ngIf="link.itemType !== 'badge'" class="mat-body-2"
            fxLayout fxLayoutAlign="start center"
            routerLink="{{link.path}}"
            routerLinkActive="active">
          <span><mat-icon [innerHTML]="link.icon" class="mat-icon material-icons-outlined"></mat-icon></span>
            {{link.title}}
        </li>
        <li *ngIf="link.itemType === 'badge' && !link.callbackActionName && !link.callbackAction" class="mat-body-2"
            fxLayout fxLayoutAlign="start center"
            routerLinkActive="active"
            routerLink="{{link.path}}"
            title="{{link.description}}">
          <span><mat-icon [innerHTML]="link.icon" class="mat-icon material-icons-outlined"></mat-icon></span>
          {{link.title}}
        </li>
        <li *ngIf="link.itemType === 'badge' && link.callbackAction" class="mat-body-2"
            fxLayout fxLayoutAlign="start center"
            title="{{link.description}}"
            (click)="link.callbackAction()">
          <span><mat-icon [innerHTML]="link.icon" class="mat-icon material-icons-outlined"></mat-icon></span>
          {{link.title}}
        </li>
      </ng-container>
    </ul>

  </div>

  <div class="logo-image-container">
    <svg></svg>
  </div>

</div>
