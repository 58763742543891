import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'longitude'
})
export class LongitudePipe implements PipeTransform {

  transform(value: string | number): string {
    if (+value >= 0) {
      return value + 'E';
    } else {
      return value + 'W';
    }
  }

}
