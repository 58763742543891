import { Uzytkownik } from '../user';
import { StatusTerminala } from './status-terminala';
import { OprogramowanieTerminala } from './oprogramowanie-terminala';
import { Naczepa, Samochod } from '../vehicle';
import { Rygiel } from './rygiel';

export interface Terminal {
  id?: number;
  _status?: StatusTerminala;
  _firma?: string;
  przypisany?: boolean;
  klucz_serwis?: boolean;
  zmiana_kluczy: boolean;
  nowy_klucz_publ?: string;
  data_produkcji: string;
  potw_przypis?: boolean;
  zlikwidowany?: boolean;
  synchro_czasu?: string;
  nr_tel?: string;
  nr_seryjny?: string;
  nr_tel_sat?: string;
  tryb_testowy?: boolean;

  _serwisant?: Uzytkownik;
  zezw_tryb_test?: boolean;
  kod_otwarcia?: number;
  czas_ost_kom: string;
  oczek_czas_kom: string;
  czestotl_kom?: number;
  _graber: {};
  w_zestawie?: boolean;

  czas_odczytu_wypos: string;
  zgoda_dezakt?: boolean;
  zgoda_likw?: boolean;
  _opr: OprogramowanieTerminala;
  czy_aktywny?: boolean;
  ind_monit_par?: boolean;
  _oper_gsm?: string;

  uwagi?: string;

  samochods?: Samochod[];
  naczepas?: Naczepa[];
  rygiels?: Rygiel[];
  kartas: string[];
  zw?: number | null; // battery level
}

export interface TerminalDetails {
  id: number;
  vehicleType: TerminalVehicleType;
  vehiclePlate: string;
  vehicleId: number;
}

export enum TerminalVehicleType {
  TRUCK = 'truck',
  TRAILER = 'trailer',
  BRAND = 'brand',
  PLATE = 'plate',
  VEHICLE_ID = 'id',
}

export interface ITerminalEquipment {
  idTerminal: number;
  listaPolRamek: IListPolRamek[];
  typZlaczaNazwa: string;
  wyposazenieDostepne: boolean;
  wyposazenieMax: number;
  wyposazenieMin: number;
  wyposazenieMnoznik: number;
  wyposazenieNazwa: string;
  wyposazenieOfset: number;
  wyposazenieTerminalaAktywne: boolean;
  wyposazenieTerminalaEkstender: number;
  wyposazenieTerminalaId: number;
  wyposazenieTerminalaNotatki: string;
  wyposazenieTerminalaNumerZlacza: number;
  wyposazenieTerminalaUszkodzone: boolean;
  wyposazenieTerminalaWyposazenieId: number;
  wyposazenieUsuniete: boolean;
  wyposazenieZwracaWartosc: boolean;
}

export interface IListPolRamek {
  idWyposazeniePoleRamki: number;
  nazwaRamki: string;
  poleRamki: string;
}
