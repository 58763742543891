export enum TransportMsg {
  PROGRESS_ROUTES = 'Unfortunately we were not able to acquire information about assigned routes, please check if there is any assigned.',
  PROGRESS_PARKING_LOTS = 'Unfortunately we were not able to acquire information about assigned parking lots, please check if there is any assigned.',
  PROGRESS_GATHERING_DATA = 'Please sit in calm while the data are being acquired.',
  DELETE = 'Are you sure you want to delete this transit?',
  DELETE_NOT_FINALIZED = 'Transport must not be deleted since it is not yet finalized.',
  NO_INFORMATION = 'No information about transport.',
  NO_TRACKING_DEVICE = 'No tracking devices detected.',
  NO_LOCKER_DEVICE = 'No locker detected.',
  NO_RECENT_TRACKING_INFO = 'No recent info from tracking system.',
  CARGO_OWN_ADDED = 'New Cargo owner has been added.',
  CARGO_OWN_UPDATED = 'Cargo owner has been updated.',
  CARGO_OWN_REMOVED = 'Cargo owner has been removed.',
  TRANSPORT_CREATED = 'Transport has been created.',
  TRANSPORT_UPDATED = 'Transport has been updated.',
}
