<div class="r-drawer-container" fxFlex fxLayout="column" fxLayoutGap="10px">
  <div class="title-bar">
    Map route
  </div>
  <form [formGroup]="searchRouteForm" (ngSubmit)="onSubmit()" fxFlex fxLayout="column" fxLayoutGap="10px">
    <div fxFlex fxLayout="column" fxLayoutGap="10px">
      <mat-form-field>
        <mat-label>Route start</mat-label>
        <geocoding-search formControlName="poczatekTrasy"></geocoding-search>
      </mat-form-field>

      <via-points
        [viaPoints]="searchRouteForm.getViaPoints()"
        (pointRemoved)="removeViaPoint($event)">
      </via-points>

      <mat-form-field>
        <mat-label>Route end</mat-label>
        <geocoding-search formControlName="koniecTrasy"></geocoding-search>
      </mat-form-field>
    </div>
    <div class="actions" fxLayout="row" fxLayoutGap="10px">
      <div *appHasPrivileges="[ePrivs.READ_ROUTES_TRACKS]" fxLayoutAlign="start center">
        <div *ngIf="searchedRoute.length > 0" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
          <a href="#" (click)="downloadJson('json')">Json</a>
          <a href="#" (click)="downloadJson('geojson')">Geojson</a>
        </div>
      </div>
      <button mat-raised-button type="button" (click)="clearPoints()">Clear</button>
      <button mat-raised-button color="primary" type="submit" [disabled]="searchRouteForm.invalid">Search</button>
    </div>
  </form>
</div>
