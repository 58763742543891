<div class="transport-set-details-container" fxLayout="column">

  <div *ngIf="truck">
    <div class="truck-details" fxLayout="column">
      <p class="name-heading">Truck</p>
      <p mat-subheading-2 class="name">
        {{ truck.model.marka.marka }} {{ truck.model.model }}: {{ truck.nrRej }}
      </p>
    </div>
    <div class="details-frame" fxLayout="column">
      <div class="details-frame-row" fxLayout="row" fxLayoutAlign="space-between" fxFlex>
        <span class="mat-subheading-1">Buckle</span>
      </div>
      <div class="details-frame-row" fxLayout="row" fxLayoutAlign="space-between" fxFlex>
        <span class="mat-subheading-1">Working state</span>
      </div>
      <div class="details-frame-row" fxLayout="row" fxLayoutAlign="space-between" fxFlex>
        <span class="mat-subheading-1">Allowable cargo load</span>
      </div>
      <div class="details-frame-row" fxLayout="row" fxLayoutAlign="space-between" fxFlex>
        <span class="mat-subheading-1">Cargo capacity</span>
      </div>
      <div class="details-frame-row" fxLayout="row" fxLayoutAlign="space-between" fxFlex>
        <span class="mat-subheading-1">Seating capacity</span>
      </div>
      <div class="details-frame-row" fxLayout="row" fxLayoutAlign="space-between" fxFlex>
        <span class="mat-subheading-1">Status</span>
        <span class="mat-subheading-1 align-right">
          {{ truck.status.status }}
        </span>
      </div>
      <div class="details-frame-row" fxLayout="row" fxLayoutAlign="space-between" fxFlex>
        <span class="mat-subheading-1">Remarks</span>
        <span *ngIf="truck?.hasOwnProperty('uwagi')" class="mat-subheading-1 align-right">
          {{ truck.uwagi }}
        </span>
      </div>
    </div>
  </div>

  <div class="trailer-details" fxLayout="column">
    <p class="name-heading">Trailer</p>
    <p *ngIf="trailer" mat-subheading-2 class="name">
      {{ trailer.model.marka.marka }} {{ trailer.model.model }}: {{ trailer.nrRej }}
    </p>
  </div>
  <div class="details-frame drawer-bottom" fxLayout="column">
    <div class="details-frame-row" fxLayout="row" fxLayoutAlign="space-between" fxFlex>
      <span class="mat-subheading-1">Buckle</span>
    </div>
    <div class="details-frame-row" fxLayout="row" fxLayoutAlign="space-between" fxFlex>
      <span class="mat-subheading-1">Working state</span>
    </div>
    <div class="details-frame-row" fxLayout="row" fxLayoutAlign="space-between" fxFlex>
      <span class="mat-subheading-1">Allowable cargo load</span>
    </div>
    <div class="details-frame-row" fxLayout="row" fxLayoutAlign="space-between" fxFlex>
      <span class="mat-subheading-1">Cargo capacity</span>
    </div>
  </div>

  <div *ngIf="readyTransportSetsPanelIsOpened$ | async" fxFlex fxLayoutAlign="end center" fxLayoutGap="5px">
    <button mat-raised-button color="primary" class="mat-button" type="button" (click)="selectSet()">
      Select
    </button>
  </div>

</div>
