import { BehaviorSubject, Observable } from 'rxjs';
import { FrameTest } from './frame-test';
import { TransitTest } from './transit-test';

export interface TabletStatus {
  noDevice?: boolean;
  upToDate: boolean;
  tablet;
}
export class TransitTestTablet implements TransitTest<TabletStatus> {
  protected frameTest: FrameTest;

  protected status$: BehaviorSubject<TabletStatus> = new BehaviorSubject({
    upToDate: false,
    tablet: null,
  });

  constructor(frameRequestTimestamp: number) {
    this.frameTest = new FrameTest(frameRequestTimestamp);
  }

  get status(): Observable<TabletStatus> {
    return this.status$.asObservable();
  }

  passData(tablets): void {
    this.test(tablets);
  }

  dispose(): void {
    return;
  }

  protected test(tablets): void {
    // No tablets, still waiting
    if (!tablets?.length) {
      return;
    }

    const tablet = this.selectTablet(tablets);

    // No tablet selected
    if (!tablet) {
      return;
    }

    // Empty {} = no device
    if (!tablet.czas_ost_kom) {
      this.status$.next({
        upToDate: false,
        noDevice: true,
        tablet: null,
      });
      return;
    }

    // Up to date - tablet logged in
    if (this.frameTest.isUpToDate(tablet.czas_ost_kom)) {
      this.status$.next({
        upToDate: true,
        noDevice: false,
        tablet,
      });

    // Tablet was online but not up to date position for now
    } else {
      this.status$.next({
        upToDate: false,
        noDevice: false,
        tablet,
      });
    }
  }

  private selectTablet(tablets) {
    const tabletsWithUpdatedDate = tablets?.map((item) => ({
      ...item,
      czas_ost_kom: item?.czas_ost_kom?.date.concat(
        ' ',
        item?.czas_ost_kom?.timezone
      ),
    }));
    const tablet =
      tabletsWithUpdatedDate?.length > 1
        ? this.compareTablets(tabletsWithUpdatedDate)
        : tabletsWithUpdatedDate[0];
    return tablet ? tablet : null;
  }

  private compareTablets(tablets) {
    const tabletOneActivity = !!tablets[0].czas_ost_kom
      ? new Date(tablets[0].czas_ost_kom)
      : null;
    const tabletTwoActivity = !!tablets[1].czas_ost_kom
      ? new Date(tablets[1].czas_ost_kom)
      : null;
    return tabletOneActivity > tabletTwoActivity ? tablets[0] : tablets[1];
  }
}
