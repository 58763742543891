import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import * as fromRoot from '../../../../../app.reducer';
import { Strings } from '../../../../../helpers';
import { PunktSpedycyjny } from '../../../../../models/dto/transportSets';
import { NewTransitRouteRequest } from '../../../../../models/form/map-search-route-form';
import { MapSaveSearchedRouteForm } from '../../../../../models/form/map-search-route-form/map-save-searched-route-form';
import { SearchRouteComponentData } from '../../../../../models/map';
import { RightDrawerService } from '../../../../shared/services';
import * as TRANSSET from '../../../../transport-set/ngrx/transset.actions';
import { MapObjectsHelper } from '../../../main/here/helpers';
import { HereService } from '../../../services/here.service';
import { LocationService } from '../../../services/location.service';
import { SearchBaseRouteComponent } from '../search-base-route/search-base-route.component';

@Component({
  selector: 'app-search-and-assign-route',
  templateUrl: './search-and-assign-route.component.html',
  styleUrls: ['./search-and-assign-route.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchAndAssignRouteComponent extends SearchBaseRouteComponent {
  @Input() inData: SearchRouteComponentData = null;

  punktySpedycyjne: PunktSpedycyjny[] = [];
  punktyWyszukane$: Observable<PunktSpedycyjny[]>;

  searchText = new BehaviorSubject<string>('');

  startIdPrefix = 'punkt_sped_start_';
  startIcon = MapObjectsHelper.pngIcons.green;
  endIdPrefix = 'punkt_sped_end_';
  endIcon = MapObjectsHelper.pngIcons.red;

  transitRouteForm = new MapSaveSearchedRouteForm();
  saveInProgress$: Observable<boolean> = this.store
    .select(fromRoot.selectors.transset.getSaveRouteInProgress)
    .pipe(
      tap((inProgress) => {
        if (inProgress) {
          this.transitRouteForm.disable();
          this.searchRouteForm.disable();
        } else {
          this.transitRouteForm.enable();
          this.searchRouteForm.enable();
        }
      })
    );

  constructor(
    protected hereService: HereService,
    protected store: Store<fromRoot.State>,
    protected cd: ChangeDetectorRef,
    protected rightDrawer: RightDrawerService
  ) {
    super(hereService, store, cd);

    this.subs.add(
      this.store
        .select(fromRoot.selectors.transset.getShippingPoints)
        .pipe(
          filter(
            (r) =>
              r.length > 0 &&
              Strings.getObjectHash(r) !==
                Strings.getObjectHash(this.punktySpedycyjne)
          )
        )
        .subscribe(
          (r) =>
            (this.punktySpedycyjne = [...r].sort((a, b) =>
              a.nazwa_kod.toLowerCase() > b.nazwa_kod.toLowerCase() ? 1 : -1
            ))
        )
    );

    this.subs.add(
      this.searchRouteForm
        .getStartPoint()
        .valueChanges.subscribe((point: PunktSpedycyjny) => {
          this.replacePoint(
            'id_punkt_sped_poczatkowy',
            this.startIdPrefix,
            point
          );
        })
    );

    this.subs.add(
      this.searchRouteForm
        .getEndPoint()
        .valueChanges.subscribe((point: PunktSpedycyjny) => {
          this.replacePoint('id_punkt_sped_koncowy', this.endIdPrefix, point);
        })
    );

    this.subs.add(
      this.store
        .select(fromRoot.selectors.map.getReversGeocode)
        .pipe(filter((r) => r !== null && r !== undefined))
        .subscribe((point) => {
          const locationPoint = point;
          const pointId = locationPoint.location.locationId;
          switch ((point.type || '').toLowerCase()) {
            case 'way-point':
            default:
              this.searchRouteForm
                .getViaPoints()
                .push(new FormControl(locationPoint));
              this.hereService.addPointDraggable(
                locationPoint.location.displayPosition,
                { pointId },
              );
              break;
          }
        })
    );
  }

  initTransitFormFields() {
    this.transitRouteForm.patchFromPartialModel({
      id_przejazd: '' + this.inData.transitId,
      numer_porzadkowy: '' + this.inData.sequence,
      trasa_map_json: this.searchedRoute[0],
    });
  }

  addNewRoute() {
    const request: NewTransitRouteRequest = { ...this.transitRouteForm.value };
    this.store.dispatch(
      TRANSSET.newTranssetRouteRequest({
        request,
        successCallback: this.inData.saveCallback,
      })
    );
  }

  cancelAction() {
    this.inData.fallback();
  }

  clearPoints() {
    super.clearPoints();
    return true;
  }

  contextMenu = (e) => {
    if (e.target !== HereService.map) {
      return;
    }
    // ? Get geo coordinates from the screen coordinates.
    const coord = HereService.map.screenToGeo(e.viewportX, e.viewportY);

    e.items.push(
      // ? Create a menu item, that has only a label,
      // ? which displays the current coordinates.
      new HereService.H.util.ContextItem({
        label: LocationService.getMapCoordsStr(coord),
      }),
      // ? Create an item, that will change the map center when clicking on it.
      new HereService.H.util.ContextItem({
        label: 'Center map here',
        callback: function () {
          HereService.map.setCenter(coord, true);
        },
      }),
      // ? It is possible to add a separator between items in order to logically group them.
      HereService.H.util.ContextItem.SEPARATOR,
      new HereService.H.util.ContextItem({
        label: 'Add VIA point',
        callback: async () => {
          await this.reverseGeocodeFromCtxMenu(coord, 'way-point');
        },
      })
    );
    // tslint:disable-next-line:semicolon
  };

  private replacePoint(
    controlName: string,
    prefix: string,
    point: PunktSpedycyjny
  ): void {
    const currentPoint: number = this.transitRouteForm.get(controlName).value;
    if (currentPoint) {
      this.hereService.removeMapObject(`${prefix}${currentPoint}`);
    }

    this.transitRouteForm.patchValue({
      [controlName]: point.id,
      trasa_map_json: '',
    });
    this.searchedRoute = [];
  }
}
