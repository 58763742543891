import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DateFormat } from 'src/app/helpers/enum';
import { TabletStatus } from '../transit-test/transit-test-tablet';

enum TabletTextStatus {
  NOT_VERIFIED_PROCESSING,
  NO_DEVICE,
  WAS_ONLINE_PROCESSING_LOCATION,
  WAS_ONLINE_NO_LOCATION,
  VERIFIED_WORKS_WELL,
}

@Component({
  selector: 'transit-test-result-tablet',
  templateUrl: './transit-test-result-tablet.component.html',
  styleUrls: ['../transit-test-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransitTestResultTabletComponent {
  @Input() resultsTitle: string;
  @Input() status: TabletStatus;
  @Input() isDone: boolean = false;

  textStatusEnum: typeof TabletTextStatus = TabletTextStatus;
  dateFormat: typeof DateFormat = DateFormat;

  constructor() {}

  getTextStatus(status: TabletStatus): TabletTextStatus {
    if (!status || !status.hasOwnProperty('noDevice')) {
      return TabletTextStatus.NOT_VERIFIED_PROCESSING;
    }

    if (status.noDevice) {
      return TabletTextStatus.NO_DEVICE;
    }

    if (status.tablet && !status.upToDate && !this.isDone) {
      return TabletTextStatus.WAS_ONLINE_PROCESSING_LOCATION;
    }

    if (status.tablet && !status.upToDate && this.isDone) {
      return TabletTextStatus.WAS_ONLINE_NO_LOCATION;
    }

    if (status.upToDate) {
      return TabletTextStatus.VERIFIED_WORKS_WELL;
    }
  }
}
