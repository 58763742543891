import { ActionNames, DriverActions } from './driver.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Kategoria, Kierowca } from '../interfaces';

export interface State {
  kierowcy: Kierowca[];
  kategorie: Kategoria[];
  pins: Pick<Kierowca, 'pin' | 'pin_ca'> | null;
  pinGenerationInProgress: boolean;
}

export const initialState: State = {
  kierowcy: [],
  kategorie: [],
  pins: null,
  pinGenerationInProgress: false,
};

export const featureName = 'drivers';

export function reducer(state = initialState, action: DriverActions) {
  switch (action.type) {
    case ActionNames.LOAD_DRIVERS_SUCCESS:
      return {...state, kierowcy: action.payload};

    case ActionNames.LOAD_DRIVING_LICENCES_SUCCESS:
      return {...state, kategorie: action.payload.kategorie};

    case ActionNames.ADD_DRIVER_LICENCE_SUCCESS: {
      const kierowcy = [...state.kierowcy].map(k => {
        if (k.id !== action.payload.kierowcaKategoria.id_kierowca) {
          return k;
        }
        return {...k, _kategorie: [...k._kategorie, action.payload.kierowcaKategoria]};
      });
      return {...state, kierowcy: kierowcy};
    }

    case ActionNames.SET_PINS_TO_DISPLAY: {
      return {...state, pins: action.payload, pinGenerationInProgress: false};
    }

    case ActionNames.GENERATE_NEW_PINS: {
      return {...state, pinGenerationInProgress: true};
    }

    default:
      return state;
  }
}


export const getState = createFeatureSelector<State>(featureName);
export const getDrivers = createSelector(getState, (state: State) => state.kierowcy);
export const getLicences = createSelector(getState, (state: State) => state.kategorie);
export const getPins = createSelector(getState, (state: State) => state.pins);
