import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import intersection from 'lodash/intersection';

import * as UI from '../../../../ngrx/ui.actions';
import * as MAP from '../../../../ngrx/map.actions';
import * as fromRoot from '../../../../app.reducer';

import { ITransportEntity } from '../../interfaces/transport-entity';
import { TransportMsg } from '../../../../messages';
import { MapViewRoute, Privs, ToastType, TransportFormStep } from '../../../../helpers/enum';
import { Locker } from '../../../../models/dto/vehicle';
import { VehicleFromTransitPipe } from '../../../shared/pipes';
import { TransportHelper } from '../../../../helpers';
import { Terminal } from '../../../../models/dto/terminale';

@Component({
  selector: 'app-transport-tile',
  templateUrl: './transport-tile.component.html',
  styleUrls: ['./transport-tile.component.scss']
})
export class TransportTileComponent {

  @Input() transport: ITransportEntity;
  @Input() markedTerminalList: Terminal[] = [];
  @Input() editable = false;
  ePrivs = Privs;

  constructor(private store: Store<fromRoot.State>,
              private readonly router: Router) {

  }

  showOnTheMap(): void {
    const terminalList = TransportHelper.getTerminalIdList(this.transport);

    if (terminalList.length < 1) {
      this.store.dispatch(UI.showUserMessage({message: {message: TransportMsg.NO_TRACKING_DEVICE, type: ToastType.WARN}}));
      return;
    }

    this.store.dispatch(MAP.ViewerExtraData({extraData: terminalList}));
    this.router.navigate(['/map-view', MapViewRoute.TRANSPORT_PROGRESS, this.transport.id])
      .then(() => {
        this.store.dispatch(UI.showUserMessage({message: {
            message: TransportMsg.PROGRESS_GATHERING_DATA,
            type: ToastType.INFO
          }}));
      });
  }

  transportStartPoint(ctx: string): string {
    if (!this.transport.mscWyjazdu) {
      return ctx === 'name' ? 'Not defined' : 'no-data';
    }

    return this.transport.mscWyjazdu.nazwa_kod || this.transport.mscWyjazdu['nazwaKod'];
  }

  transportEndPoint(ctx: string): string {
    if (!this.transport.mscDocelowe) {
      return ctx === 'name' ? 'Not defined' : 'no-data';
    }

    return this.transport.mscDocelowe.nazwa_kod || this.transport.mscDocelowe['nazwaKod'];
  }

  getLocker(transport: ITransportEntity): Locker {
    const trailer = VehicleFromTransitPipe.transform(transport.zestaw.trailerSet);
    return trailer?.naczepaTerminal[0]?.terminal.rygle[0] || undefined;
  }

  editDetails(transport: ITransportEntity): void {
    let step = TransportFormStep.Overview;
    if (transport.kierowcaPrzejazdList.length < 1) {
      step = TransportFormStep.Drivers;
    }
    this.router.navigate(['transport', 'edit', transport.id, step]);
  }

  getSelectedClass(): string {
    if (this.markedTerminalList.length < 1) {
      return '';
    }

    const terminalList = TransportHelper.getTerminalIdList(this.transport);
    const markedTerminalIds = this.markedTerminalList.map(t => t.id).filter(t => t !== undefined);
    return intersection(terminalList, markedTerminalIds).length > 0 ? 'selected' : 'hidden';
  }
}
