import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { Firma } from '../models/dto/company';
import { EnvHelper } from '../helpers';
import { GenericType } from '../models/dto/common';
import { JwtBodyData } from '../models/dto';
import { UserRoles } from '../helpers/enum';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  companyEndpoint = EnvHelper.getApiUrl() + environment.apiModules.Company.firma;
  companyTypeEndpoint = EnvHelper.getApiUrl() + environment.apiModules.Company.typ_firmy;

  constructor(private http: HttpClient) {
  }

  public getCompanies(userToken: JwtBodyData): Observable<Firma[]> {
    let url = this.companyEndpoint + '_odczyt';
    if (userToken.role_id === UserRoles.ADMIN_FDW) {
      url = this.companyEndpoint;
    }

    return this.http.get<Firma[]>(url);
  }

  public getCompanyInfo(cid: number): Observable<Firma> {
    return this.http.get<Firma>(this.companyEndpoint + '/' + cid);

  }

  public getCompanyTypeList(): Observable<Array<GenericType>> {
    return this.http.get<GenericType[]>(this.companyTypeEndpoint);
  }

  public deleteCompany(company: Firma): Observable<any> {
    return this.http.delete(this.companyEndpoint + '_edycja/' + company.id);
  }

  public updateCompanyInfo(company: Firma) {
    return this.http.put<Firma>(this.companyEndpoint + '_edycja/' + company.id, company);
  }

  public insertCompanyInfo(company: Firma): Observable<Firma> {
    return this.http.post<Firma>(this.companyEndpoint + '_edycja', company);
  }

  public changeCompanyState(company: Firma): Observable<Firma> {
    return this.http.put<Firma>(this.companyEndpoint + '_edycja/' + company.id, {zablokowana: !company.zablokowana});
  }
}
