import { Filter } from './filter';
import { IAlarmTransportowyUproszczony } from '../../interfaces';
import { Strings } from '../../../../helpers';

export class SearchTextFilter extends Filter {
  constructor(private readonly searchText) {
    super();
  }

  get name(): string {
    return 'search-text';
  }

  private static createSearchableStringFrom(alarm: IAlarmTransportowyUproszczony): string {
    let searchableString = (alarm.rodzajAlarmu?.rodzaj ?? '') + (alarm.typPojazdu ?? '');
    if (alarm.numerRej) {
      searchableString += alarm.numerRej;
    }
    if (alarm.terminal) {
      searchableString += alarm.terminal.id;
      searchableString += alarm.terminal.uwagi ?? '';
    }

    searchableString += alarm.opis || '';

    return searchableString;
  }

  isMatching(alarm: IAlarmTransportowyUproszczony): boolean {
    if (this.searchText.length > 0) {
      return Strings.searchTextIgnoreAccents(this.searchText, SearchTextFilter.createSearchableStringFrom(alarm)) !== -1;
    }
    return true;
  }
}
