import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { CustomMaterialModule } from '../material.modules';
import { SetCreateComponent, SetDetailsComponent, SetListComponent, TransportSetComponent } from './components';
import * as fromTransset from './ngrx/transset.reducer';
import { TranssetEffects } from './ngrx/transset.effects';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [SetCreateComponent, SetDetailsComponent, SetListComponent, TransportSetComponent],
  imports: [
    CommonModule, CustomMaterialModule, FormsModule, RouterModule,

    StoreModule.forFeature(fromTransset.featureName, fromTransset.reducer),
    EffectsModule.forFeature([TranssetEffects]), MatTooltipModule, SharedModule
  ]
})
export class TransportSetModule {
}
