import moment from 'moment';

export class MomentHelper {
  static datetimeForGivenDateAndTime(date: Date, time: Date): Date {
    if (!date || !time) {
      return undefined;
    }
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const hours = time.getHours();
    const minutes = time.getMinutes();
    return new Date(year, month, day, hours, minutes);
  }

  static momentForGivenDateAndTime(date: string, time: string): moment.Moment | null {
    const momentDate = moment(date);
    const momentTime = moment(time);
    const year = momentDate.toDate().getFullYear();
    const month = momentDate.toDate().getMonth();
    const day = momentDate.toDate().getDate();
    const hours = momentTime.toDate().getHours();
    const minutes = momentTime.toDate().getMinutes();
    return moment(new Date(year, month, day, hours, minutes));
  }

  static isCurrentRange(dateFrom: string, dateTo: string): boolean {
    const currentUnix = moment().unix();
    return currentUnix > moment(dateFrom).unix() && currentUnix <= moment(dateTo).unix();
  }

  static compareDates(strDate1, strDate2, orderType = 'asc'): number {
    if (orderType === 'asc') {
      return moment(strDate1).unix() - moment(strDate2).unix();
    }
    return moment(strDate2).unix() - moment(strDate1).unix();
  }

  static isFutureDate(strDate: string): boolean {
    return moment(strDate).diff(moment()) > 0;
  }
}
