import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import * as UI from '../../../../ngrx/ui.actions';
import * as fromRoot from '../../../../app.reducer';
import * as fromAlert from '../../../../modules/alerts/ngrx/alert.reducer';

import { TransportAlert } from 'src/app/helpers';
import { AppUserInfo } from 'src/app/models/authentication';
import { IAlarmTransportowyUproszczony } from 'src/app/modules/alerts/interfaces/alarm-transportowy-uproszczony';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public username$: Observable<AppUserInfo>;
  public alerts$: Observable<IAlarmTransportowyUproszczony[]>;

  constructor(private store: Store<fromRoot.State>) {}

  ngOnInit() {
    this.store.dispatch(UI.setSideLinks({links: []}));
    this.username$ = this.store.select(fromRoot.selectors.auth.getUserInfo);
    this.alerts$ = this.store.select(fromAlert.getFlattenedTransportAlerts).pipe(map(value => value.filter(TransportAlert.isNewAlert)));
  }
}
