import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { map, switchMap, tap } from 'rxjs/operators';

import * as fromRoot from '../../app.reducer';
import * as fromAlert from '../../modules/alerts/ngrx/alert.reducer';
import * as userRoles from '../../modules/users/dictionary-user-role';
import { AppUserInfo } from '../../models/authentication';
import { TransportAlert } from '../../helpers';

@Component({
  selector: 'app-top-right-corner-menu',
  templateUrl: './top-right-corner-menu.component.html',
  styleUrls: ['./top-right-corner-menu.component.scss']
})
export class TopRightCornerMenuComponent implements OnDestroy {

  subs = new Subscription();
  sidenavIsOpened$: Observable<boolean>;
  newAlertIndicator: number;
  isUserAuth$: Observable<boolean>;
  userInfo: AppUserInfo;
  showNewAlertNotice = false;

  @Output() showUserInfoAction = new EventEmitter<boolean>();

  constructor(private store: Store<fromRoot.State>) {
    this.isUserAuth$ = this.store.select(fromRoot.selectors.auth.getAuthIsAuthenticated);
    this.sidenavIsOpened$ = this.store.select(fromRoot.selectors.ui.isLeftDrawerOpened);

    this.subs.add(
      this.store.select(fromRoot.selectors.auth.getUserInfo)
        .pipe(
          switchMap((userInfo: AppUserInfo) => {
            return this.store.select(fromAlert.getFlattenedTransportAlerts)
              .pipe(
                map(flattenedAlerts => {
                  return flattenedAlerts.filter(TransportAlert.isNewAlert).length;
                }),
                tap((newAlert) => {
                  if (userInfo) {
                    this.showNewAlertNotice = newAlert['isPresent'] === true &&
                      userRoles.DictionaryUserRole.MCWorkerOrDispatcherId.indexOf(+userInfo.userId) !== -1;
                  }
                })
              );
          }),
        )
        .subscribe(newAlert => {
          this.newAlertIndicator = newAlert;
        })
    );
  }

  showUserInfo(): void {
    this.showUserInfoAction.emit(true);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
