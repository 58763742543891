import { Pipe, PipeTransform } from '@angular/core';
import { IWorkStatusEntity } from '../interfaces/work-status-entity';
import { WorkStateIds } from '../../../helpers/enum';

interface WorkIcon {
  icon: string;
  tooltip: string;
  sClass?: string;
}

@Pipe({
  name: 'getTransportStatusIcon'
})
export class GetTransportStatusIconPipe implements PipeTransform {

  transform(value: IWorkStatusEntity, started: boolean, finished: boolean): WorkIcon {
    let out: WorkIcon = {icon: 'help', tooltip: 'Status not recognized: ' + value.stanPracy, sClass: 'warning'};
    if (finished) {
      return {icon: 'check_circle_outline', tooltip: 'Transport accomplished.', sClass: 'finished'};
    }
    switch (value.id) {
      case WorkStateIds.TRANSPORT_GOES_UNLADEN:
        out = {...out, icon: 'local_shipping', tooltip: 'Transport in progress without load.', sClass: 'material-icons-outlined unladen'};
        break;
      case WorkStateIds.TRANSPORT_ON_ROUTE:
        out = {...out, icon: 'local_shipping', tooltip: 'Transport in progress.', sClass: ''};
        break;
      case WorkStateIds.TRANSPORT_TESTING:
        out = {...out, icon: 'title', tooltip: 'Transport testing.', sClass: ''};
        break;
      case WorkStateIds.TRANSPORT_IDLE:
        out = {...out, icon: 'local_parking', tooltip: 'Transport waiting.', sClass: 'idle'};
        break;
    }
    return out;
  }

}
