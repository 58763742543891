import { Component, Input } from '@angular/core';
import { Terminal } from '../../../../models/dto/terminale';

@Component({
  selector: 'app-terminal-short-info',
  templateUrl: './terminal-short-info.component.html',
  styleUrls: ['./terminal-short-info.component.scss']
})
export class TerminalShortInfoComponent {

  @Input() terminal: Terminal;
  @Input() mode = 'bar';

}
