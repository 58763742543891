import { Pipe, PipeTransform } from '@angular/core';
import { MomentHelper, TransportAlert } from '../../../helpers';
import { IAlarmTransportowyUproszczony } from '../../alerts/interfaces';

@Pipe({
  name: 'sortAlertsUproszczony'
})
export class SortAlertsUproszczonyPipe implements PipeTransform {

  transform(value: IAlarmTransportowyUproszczony[]): IAlarmTransportowyUproszczony[] {
    return [...value].sort((a, b) => {

      if (TransportAlert.isAlertOpen(a) !== TransportAlert.isAlertOpen(b)) {
        if (TransportAlert.isAlertOpen(a)) {
          return -1;
        }

        if (TransportAlert.isAlertOpen(b)) {
          return 1;
        }
      }

      if (a.czas && b.czas) {
        return MomentHelper.compareDates(a.czas, b.czas, 'desc');
      }

      if (a.czas) {
        return 1;
      }

      return -1;
    });
  }

}
