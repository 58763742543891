import {
  ADD_NEW_USER_REQUEST,
  ADD_NEW_USER_SUCCESS,
  ADD_USER_PRIVILEGE_SUCCESS,
  LOAD_COMPANY_ROLES_SUCCESS,
  LOAD_COMPANY_USER_LIST_SUCCESS,
  LOAD_USER_BY_LOGIN_SUCCESS,
  LOAD_USER_LOGIN_ATTEMPTS_SUCCESS,
  LOAD_USER_PRIVILEGES_SUCCESS,
  REMOVE_USER_PRIVILEGE_REQUEST,
  UserActions
} from './user.actions';
import { LogowanieUzytkownika, Uzytkownik } from '../models/dto/user';
import { RoleUzytkownikow, Uprawnienie, UprawnienieUzytkownika } from '../models/authentication';

export interface State {
  uzytkownicy: Uzytkownik[];
  logowania_Uzytkownika: LogowanieUzytkownika[];
  uprawnienia_uzytkownika: UprawnienieUzytkownika[];
  uprawnienia: Uprawnienie[];
  wybrany_uzytkownik: Uzytkownik;
  role: RoleUzytkownikow[];
  addUserSuccess: boolean;
}

export const initialState: State = {
  uzytkownicy: [],
  logowania_Uzytkownika: [],
  uprawnienia_uzytkownika: [],
  uprawnienia: [],
  wybrany_uzytkownik: null,
  role: [],
  addUserSuccess: false
};

export function userReducer(state = initialState, action: UserActions) {
  switch (action.type) {
    case LOAD_COMPANY_USER_LIST_SUCCESS:
      return {...state, uzytkownicy: action.payload};

    case LOAD_USER_LOGIN_ATTEMPTS_SUCCESS:
      return {...state, logowania_Uzytkownika: action.payload};

    case LOAD_USER_BY_LOGIN_SUCCESS:
      return {...state, wybrany_uzytkownik: action.payload};

    case LOAD_USER_PRIVILEGES_SUCCESS:
      return {...state, uprawnienia_uzytkownika: action.payload};

    case LOAD_COMPANY_ROLES_SUCCESS:
      return {...state, role: action.payload.role};

    case ADD_USER_PRIVILEGE_SUCCESS:
      return {...state, uprawnienia_uzytkownika: [...state.uprawnienia_uzytkownika, action.payload]};

    case REMOVE_USER_PRIVILEGE_REQUEST:
      const irup = state.uprawnienia_uzytkownika.indexOf(action.payload);
      const newUserPrivs = [...state.uprawnienia_uzytkownika];
      newUserPrivs.splice(irup, 1);
      return {...state, uprawnienia_uzytkownika: newUserPrivs};

    case ADD_NEW_USER_REQUEST:
      return {...state, addUserSuccess: false};

    case ADD_NEW_USER_SUCCESS:
      return {...state, addUserSuccess: true};

    default:
      return state;
  }
}

export const getCompanyUsers = (state: State) => state.uzytkownicy;
export const getUserLoginAttempts = (state: State) => state.logowania_Uzytkownika;
export const getSelectedUserInfo = (state: State) => state.wybrany_uzytkownik;
export const getUsersPrivileges = (state: State) => state.uprawnienia_uzytkownika;
export const getPrivileges = (state: State) => state.uprawnienia;
export const getCompanyRoles = (state: State) => state.role;
