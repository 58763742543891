import { RoutingApi } from '../models/admin';
import {
  AdminActions,
  LOAD_PRIVILEGES_SUCCESS,
  LOAD_ROUTING_LIST_SUCCESS,
  NEW_ROUTING_ENTRY_SUCCESS,
  RELOAD_PROXY_ROUTING_SUCCESS,
  REMOVE_ROUTING_ENTRY_REQUEST,
  UPDATE_PRIVILEGE_SUCCESS,
  UPDATE_ROUTING_ENTRY_SUCCESS
} from './admin.actions';
import { Uprawnienie } from '../models/authentication';

export interface State {
  routing: RoutingApi[];
  routingChanged: boolean;
  uprawnienia: Uprawnienie[];
}

export const initialState: State = {
  routing: [],
  routingChanged: false,
  uprawnienia: []
};

export function adminReducer(state = initialState, action: AdminActions) {
  switch (action.type) {
    case LOAD_ROUTING_LIST_SUCCESS:
      return {...state, routing: action.payload};

    case NEW_ROUTING_ENTRY_SUCCESS:
      return {...state, routing: [...state.routing, action.payload], routingChanged: true};

    case REMOVE_ROUTING_ENTRY_REQUEST:
      const ridx = state.routing.indexOf(action.payload);
      const nr = state.routing.splice(ridx, 1);
      return {...state, routing: nr, routingChanged: true};

    case UPDATE_ROUTING_ENTRY_SUCCESS:
      const uidx = state.routing.findIndex(item => item.id === action.payload.id);
      const nnr = [...state.routing];
      nnr.splice(uidx, 1);
      nnr.push(action.payload);
      return {...state, routing: nnr, routingChanged: true};

    case RELOAD_PROXY_ROUTING_SUCCESS:
      return {...state, routingChanged: false};

    case LOAD_PRIVILEGES_SUCCESS:
      return {...state, uprawnienia: action.payload};

    case UPDATE_PRIVILEGE_SUCCESS:
      const ui = state.uprawnienia.findIndex(item => item.id === action.payload.id);
      const nu = [...state.uprawnienia];
      nu.splice(ui, 1);
      nu.push(action.payload);
      return {...state, uprawnienia: nu};

    default:
      return {...state};
  }
}

export const getProxyRouting = (state: State) => [...state.routing].sort((a, b) => a.id > b.id ? 1 : -1);
export const isRoutingChanged = (state: State) => state.routingChanged;
export const getPrivileges = (state: State) => [...state.uprawnienia].sort((a, b) => a.id > b.id ? 1 : -1);
