import { ITransportEntity } from '../interfaces/transport-entity';
import { Filter } from './filter';

export class DispatcherFilter implements Filter {
  constructor(private readonly dispatcherId: number | null) {
  }

  isMatching(transport: ITransportEntity): boolean {
    if (this.dispatcherId && this.dispatcherId > 0) {
      return (+transport.dyspozytor?.id) === +this.dispatcherId;
    }
    return true;
  }
}
