export class TopNavItem {
  position: string;
  path: string;
  title: string;
  icon?: string;

  constructor(url: string, label: string, pos: string) {
    this.position = pos;
    this.path = url;
    this.title = label;
  }
}
