import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';

import * as DRIVER from './driver.actions';
import * as UI from '../../../ngrx/ui.actions';
import * as fromRoot from '../../../app.reducer';

import { DriverService } from '../services';
import { Messages, ToastType } from '../../../helpers/enum';

@Injectable()
export class DriverEffects {
  newDriver$ = createEffect(() => this.actions.pipe(
    ofType<DRIVER.NewDriversRequest>(DRIVER.ActionNames.NEW_DRIVERS_REQUEST),
    switchMap(action => {
      return this.driverSrv.newDriver(action.payload.kierowca).pipe(
        switchMap((result) => {
          if (action.payload.callback) {
            action.payload.callback(result['kierowca']['id']);
          }

          return [
            new DRIVER.LoadDriversRequest(),
            new DRIVER.SetPinsToDisplay({ pin: result.pin, pin_ca: result.pin_ca })
          ]
        }),
        catchError(() => {
          return of(UI.userError({message: Messages.SAVING_DATA_ERR}));
        })
      );
    })
  ));


  addDriverLicense$ = createEffect(() => this.actions.pipe(
    ofType<DRIVER.AddDriverLicenceRequest>(DRIVER.ActionNames.ADD_DRIVER_LICENCE_REQUEST),
    switchMap(action => {
      return this.driverSrv.newDriverLiccense(action.payload.kierowcaKategoria).pipe(
        map((resp) => new DRIVER.AddDriverLicenceSuccess({kierowcaKategoria: resp})),
        catchError(() => {
          return of(UI.userError({message: Messages.SAVING_DATA_ERR}));
        })
      );
    })
  ));

  removeDriverLicense$ = createEffect(() => this.actions.pipe(
    ofType<DRIVER.RemoveDriverLicenceRequest>(DRIVER.ActionNames.REMOVE_DRIVER_LICENCE_REQUEST),
    switchMap(action => {
      const data = action.payload;
      return this.driverSrv.removeDriverLiccense(data.id).pipe(
        map(() => {
          action.payload.successCallback();
          return new DRIVER.LoadDriversRequest();
        }),
        catchError(() => {
          return of(UI.userError({message: Messages.SAVING_DATA_ERR}));
        })
      );
    })
  ));


  updDriver$ = createEffect(() => this.actions.pipe(
    ofType<DRIVER.UpdDriverRequest>(DRIVER.ActionNames.UPD_DRIVER_REQUEST),
    switchMap(action => {
      return this.driverSrv.updateDriver(action.payload.kierowca).pipe(
        map(() => {
          if (action.payload.callback) {
            action.payload.callback();
          }
          return new DRIVER.UpdDriverSuccess();
        }),
        catchError(() => {
          return of(UI.userError({message: Messages.SAVING_DATA_ERR}));
        })
      );
    })
  ));


  loadCompanyDrivers$ = createEffect(() => this.actions.pipe(
    ofType(DRIVER.ActionNames.LOAD_DRIVERS_REQUEST),
    switchMap(() => {
      return this.driverSrv.getDriversInfo().pipe(
        map(drivers => new DRIVER.LoadDriversSuccess(drivers)),
        catchError(() => {
          return of(UI.userError({message: Messages.READING_DATA_ERR}));
        })
      );
    })
  ));


  loadDrivingLicenses$ = createEffect(() => this.actions.pipe(
    ofType(DRIVER.ActionNames.LOAD_DRIVING_LICENCES_REQUEST),
    switchMap(() => {
      return this.driverSrv.getDrivingLicenses().pipe(
        map(kategorie => new DRIVER.LoadDrivingLicensesSuccess({kategorie})),
        catchError(() => {
          return of(UI.userError({message: Messages.READING_DATA_ERR}));
        })
      );
    })
  ));

  deleteDriver$ = createEffect(() => this.actions.pipe(
    ofType<DRIVER.DeleteDriverRequest>(DRIVER.ActionNames.DELETE_DRIVER_REQUEST),
    switchMap(action => {
      const data = action.payload;
      return this.driverSrv.deleteDriver(data.id).pipe(
        map(() => {
          return new DRIVER.LoadDriversRequest();
        }),
        tap(() => {
          this.store.dispatch(UI.showUserMessage({
            message: {
              type: ToastType.SUCCESS, message: `Driver has been deleted. (${data.id})`
            }
          }));
          this.router.navigate(['dictionaries/driver/list']);
        }),
        catchError(() => {
          return of(UI.userError({message: Messages.GENERIC_FAILURE}));
        })
      );
    })
  ));

  generateNewPins$ = createEffect(() => this.actions.pipe(
    ofType<DRIVER.GenerateNewPins>(DRIVER.ActionNames.GENERATE_NEW_PINS),
    switchMap(action =>
      this.driverSrv.generateNewPins(action.payload.id, action.payload.pinType).pipe(
        map((response) => new DRIVER.SetPinsToDisplay(response)),
        catchError(() => {
          return of(UI.userError({message: Messages.GENERIC_FAILURE}));
        })
      )

    )
  ));

  constructor(
    private actions: Actions,
    private driverSrv: DriverService,
    private store: Store<fromRoot.State>,
    private router: Router) {
  }
}
