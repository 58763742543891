import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../app.reducer';

import * as frameActions from './frame.actions';
import * as UI from './ui.actions';

import { LastFrameDataService } from '../services';
import { Messages, UserRoles } from '../helpers/enum';
import { CommonDirective } from '../modules/access-control/directives/common.directive';

@Injectable()
export class FrameEffects {

  loadInfo$ = createEffect(() => this.actions$.pipe(
    ofType(frameActions.infoRequest),
    withLatestFrom(this.store),
    exhaustMap(([, storeState]) => {
      const terminalList = [];
      if (CommonDirective.userTokenDetails.role_id === UserRoles.CM) {
        terminalList.push(...storeState.device.terminals);
      }
      return this.frameService.getInfoFrames(terminalList).pipe(
        map(result => frameActions.infoSuccess({frames: result})),
        catchError(() => of(UI.userError({message: Messages.READING_DATA_ERR})))
      );
    })
  ));


  loadSat$ = createEffect(() => this.actions$.pipe(
    ofType(frameActions.satRequest),
    withLatestFrom(this.store),
    exhaustMap(([, storeState]) => {
      const terminalList = [];
      if (CommonDirective.userTokenDetails.role_id === UserRoles.CM) {
        terminalList.push(...storeState.device.terminals);
      }
      return this.frameService.getSatFrames(terminalList).pipe(
        map(result => frameActions.satSuccess({frames: result})),
        catchError(() => of(UI.userError({message: Messages.READING_DATA_ERR})))
      );
    })
  ));
  loadSatS$ = createEffect(() => this.actions$.pipe(
    ofType(frameActions.satSRequest),
    withLatestFrom(this.store),
    exhaustMap(([, storeState]) => {
      const terminalList = [];
      if (CommonDirective.userTokenDetails.role_id === UserRoles.CM) {
        terminalList.push(...storeState.device.terminals);
      }
      return this.frameService.getSatSFrames(terminalList).pipe(
        map(result => frameActions.satSSuccess({frames: result})),
        catchError(() => of(UI.userError({message: Messages.READING_DATA_ERR})))
      );
    })
  ));
  loadSatJ$ = createEffect(() => this.actions$.pipe(
    ofType(frameActions.satJRequest),
    withLatestFrom(this.store),
    exhaustMap(([, storeState]) => {
      const terminalList = [];
      if (CommonDirective.userTokenDetails.role_id === UserRoles.CM) {
        terminalList.push(...storeState.device.terminals);
      }
      return this.frameService.getSatJFrames(terminalList).pipe(
        map(result => frameActions.satJSuccess({frames: result})),
        catchError(() => of(UI.userError({message: Messages.READING_DATA_ERR})))
      );
    })
  ));

  loadAwar$ = createEffect(() => this.actions$.pipe(
    ofType(frameActions.awarRequest),
    switchMap(() =>
      this.frameService.awar$.pipe(
        map(result => frameActions.awarSuccess({frames: result})),
        catchError(() => of(UI.userError({message: Messages.READING_DATA_ERR})))
      ))
  ));

  loadRygiel$ = createEffect(() => this.actions$.pipe(
    ofType(frameActions.rygielRequest),
    switchMap(() =>
      this.frameService.rygiel$.pipe(
        map(result => frameActions.rygielSuccess({frames: result})),
        catchError(() => of(UI.userError({message: Messages.READING_DATA_ERR})))
      ))
  ));

  loadCn$ = createEffect(() => this.actions$.pipe(
    ofType(frameActions.cnRequest),
    switchMap(() =>
      this.frameService.cn$.pipe(
        map(result => frameActions.cnSuccess({frames: result})),
        catchError(() => of(UI.userError({message: Messages.READING_DATA_ERR})))
      ))
  ));

  loadCs$ = createEffect(() => this.actions$.pipe(
    ofType(frameActions.csRequest),
    switchMap(() =>
      this.frameService.cs$.pipe(
        map(result => frameActions.csSuccess({frames: result})),
        catchError(() => of(UI.userError({message: Messages.READING_DATA_ERR})))
      ))
  ));

  constructor(
    // inject Actions from @ngrx/effects
    private actions$: Actions,
    private frameService: LastFrameDataService,
    private store: Store<fromRoot.State>
  ) {
  }
}
