import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import * as fromRoot from '../../../../app.reducer';
import * as UI from '../../../../ngrx/ui.actions';

import { Strings } from '../../../../helpers';
import { CargoOwnerForm } from '../../../../models/form/transit';
import { WlascicielTowaru } from '../../../../models/dto/transportSets';
import { newCargoOwnerRequest, updateCargoOwnerRequest } from '../../ngrx/transport.actions';
import { ToastType } from '../../../../helpers/enum';
import { TransportMsg } from '../../../../messages';
import { RightDrawerService } from '../../../shared/services';

@Component({
  selector: 'app-cargo-owner',
  templateUrl: './cargo-owner.component.html',
  styleUrls: ['./cargo-owner.component.scss']
})
export class CargoOwnerComponent implements OnInit, OnDestroy {
  static readonly myname = Strings.getObjectHash('CargoOwnerComponent');
  subs = new Subscription();

  title = 'Cargo owner';
  myForm: CargoOwnerForm;
  owner: WlascicielTowaru;

  constructor(private store: Store<fromRoot.State>, private rightDrawer: RightDrawerService) {
  }

  ngOnInit(): void {
    this.subs.add(
      this.store.select(fromRoot.selectors.ui.getRightDrawerInfo)
        .pipe(
          filter(data => (data.componentName !== undefined && data.componentName === CargoOwnerComponent.myname)
            || data.isOpened === false)
        )
        .subscribe((data) => {
          if (data.componentData) {
            this.patchForm(data.componentData);
            this.title = 'Cargo owner - view';
            return;
          }

          this.myForm = new CargoOwnerForm();
          this.title = 'New cargo owner';
        })
    );
  }

  patchForm(componentData: WlascicielTowaru): void {
    if (Strings.areObjetsEquals(this.owner, componentData['owner'])) {
      return;
    }
    this.owner = componentData['owner'];
    this.myForm = new CargoOwnerForm(this.owner);
    this.myForm.disable();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.store.dispatch(UI.switchSideMenu({visible: true}));
  }

  resetForm() {
    this.title = 'Cargo owner - view';
    this.myForm.disable();
  }

  editForm() {
    this.myForm.enable();
    this.title = 'Cargo owner - edit';
  }

  save() {
    const owner = this.myForm.getUpdatedModel();
    if (!owner.id) {
      this.store.dispatch(newCargoOwnerRequest({
        owner, callback: () => {
          this.rightDrawer.close();
          this.store.dispatch(UI.showUserMessage({message: {type: ToastType.SUCCESS, message: TransportMsg.CARGO_OWN_ADDED}}));
        }
      }));
    } else {
      this.store.dispatch(updateCargoOwnerRequest({
        owner, callback: () => {
          this.rightDrawer.close();
          this.store.dispatch(UI.showUserMessage({message: {type: ToastType.SUCCESS, message: TransportMsg.CARGO_OWN_UPDATED}}));
        }
      }));
    }
  }
}
