import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ITransportEntity } from '../../interfaces/transport-entity';
import * as fromRoot from '../../../../app.reducer';
import { IInfo } from '../../../../models/frame';
import { DateFormat } from '../../../../helpers/enum';
import { Strings } from '../../../../helpers';

@Component({
  selector: 'app-transit-details',
  templateUrl: './transit-details.component.html',
  styleUrls: ['./transit-details.component.scss']
})
export class TransitDetailsComponent implements OnInit, OnDestroy {
  static readonly myname = Strings.getObjectHash('TransitDetailsComponent');
  transit: ITransportEntity;
  lastLocation: IInfo;
  df = DateFormat;

  subs = new Subscription();
  locationSub = new Subscription();

  constructor(private store: Store<fromRoot.State>) {
  }

  ngOnInit(): void {
    this.subs.add(
      this.store.select(fromRoot.selectors.ui.getRightDrawerInfo)
        .pipe(
          filter(data => (data.componentName !== undefined && data.componentName === TransitDetailsComponent.myname)
            || data.isOpened === false && this.lastLocation !== null),
          map(data => data.componentData as { terminalId: number, transit: ITransportEntity }),
          filter(data => data !== undefined)
        )
        .subscribe(data => {
          this.locationSub.unsubscribe();
          this.locationSub.add(this.store.select(fromRoot.selectors.frame.getInfoByTerminal(data.terminalId))
            .pipe(filter(f => f !== undefined && (this.lastLocation === undefined || f.czas !== this.lastLocation.czas)))
            .subscribe(r => {
              this.lastLocation = r;
            })
          );
          this.transit = data.transit;
        })
    );
  }

  ngOnDestroy() {
    this.locationSub.unsubscribe();
    this.subs.unsubscribe();
  }
}
