import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import * as fromAuth from '../ngrx/auth.reducer';
import { AppUserInfo } from '../models/authentication';
import { AuthStep } from '../models/dto/user';
import { JwtBodyData } from '../models/dto';

interface Selectors {
  getAuthIsAuthenticated: MemoizedSelector<any, boolean>;
  getIsBrowserValid: MemoizedSelector<any, boolean>;
  getUserInfo: MemoizedSelector<any, AppUserInfo>;
  getAuthStep: MemoizedSelector<any, AuthStep>;
  getBrowserId: MemoizedSelector<any, string>;
  getUserToken: MemoizedSelector<any, string>;
  getUserTokenDetails: MemoizedSelector<any, JwtBodyData>;
}

export class AuthSelectors {
  static _state = createFeatureSelector<fromAuth.State>('auth');
  static _selectors: Selectors;

  static getSelectors() {
    this._selectors = {
      getAuthIsAuthenticated: createSelector(this._state, fromAuth.getIsAuth),
      getIsBrowserValid: createSelector(this._state, fromAuth.getIsBrowserConfirmed),
      getUserInfo: createSelector(this._state, fromAuth.getUserInfo),
      getAuthStep: createSelector(this._state, fromAuth.getAuthStep),
      getBrowserId: createSelector(this._state, fromAuth.getBrowserId),
      getUserToken: createSelector(this._state, fromAuth.getAuthToken),
      getUserTokenDetails: createSelector(this._state, fromAuth.getAuthTokenDetails)
    };

    return this._selectors;
  }
}
