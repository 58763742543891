import { Uzytkownik } from 'src/app/models/dto/user';
import { Action, createAction, props } from '@ngrx/store';

//region deprecated - NGRX actions
export const AUTHENTICATION_SUCCESS = '[Auth] Authorization success';
export const LOGOUT_REQUEST = '[Auth] LOGOUT request';
export const LOGOUT_SUCCESS = '[Auth] LOGOUT success';

export const LOGIN_REQUEST = '[Auth] New login request';
export const LOGIN_SUCCESS = '[Auth] Login success';
export const LOGIN_SUCCESS_RESTORED = '[Auth] Login state restored';

export const LOGIN_USER_DATA = '[Auth] Login success - set user information';
export const LOGIN_FAILURE = '[Auth] Login failure';

export const PIN_REQUEST = '[Auth] New PIN verification request';
export const PIN_SUCCESS = '[Auth] PIN verification success';
export const PIN_FAILURE = '[Auth] PIN verification failure';

export class LogoutRequest implements Action {
  readonly type = LOGOUT_REQUEST;

  constructor(public payload?: string) {
  }
}

export class LogoutSuccess implements Action {
  readonly type = LOGOUT_SUCCESS;
}

export class LoginRequest implements Action {
  readonly type = LOGIN_REQUEST;

  constructor(public payload: { login: string, password: string }) {
  }
}

export class LoginSuccess implements Action {
  readonly type = LOGIN_SUCCESS;

  constructor(public payload: { login: string, token: string }) {
  }
}

export class LoginSuccessRestored implements Action {
  readonly type = LOGIN_SUCCESS_RESTORED;

  constructor(public payload: { user: { companyId: number, login: string, userId: number }, token: string }) {
  }
}

export class LoginUserData implements Action {
  readonly type = LOGIN_USER_DATA;

  constructor(public payload: Uzytkownik) {
  }
}

export class LoginFailure implements Action {
  readonly type = LOGIN_FAILURE;
}

export class PinRequest implements Action {
  readonly type = PIN_REQUEST;

  /**
   * Pin podany przez użytkownika
   * @param payload pin
   */
  constructor(public payload: string) {
  }
}

export class PinSuccess implements Action {
  readonly type = PIN_SUCCESS;
}

export class PinFailure implements Action {
  readonly type = PIN_FAILURE;
}

export const BROWSER_CONFIRMED = '[Auth] Browser confirmed';
export const BROWSER_NOT_CONFIRMED = '[Auth] Browser currently not confirmed';
export const BROWSER_APPROVED = '[Auth] Browser just confirmed';

export class BrowserConfirmed implements Action {
  readonly type = BROWSER_CONFIRMED;

  constructor(public payload: string) {
  }
}

export class BrowserNotConfirmed implements Action {
  readonly type = BROWSER_NOT_CONFIRMED;

  constructor(public payload: string) {
  }
}

export class BrowserApproved implements Action {
  readonly type = BROWSER_APPROVED;

  constructor(public payload: string) {
  }

}

export class AuthenticationSuccess {
  readonly type = AUTHENTICATION_SUCCESS;
}

export const CHANGE_USER_COMPANY_REQ = '[Auth] Change local user company REQUEST';
export const CHANGE_USER_COMPANY_SUCCESS = '[Auth] Change local user company SUCCESS';

export class ChangeUserComapnyReq {
  readonly type = CHANGE_USER_COMPANY_REQ;

  constructor(public payload: string) {
  }
}

export class ChangeUserComapnySuccess {
  readonly type = CHANGE_USER_COMPANY_SUCCESS;

  /**
   * Zmiana firmy w aplikacji
   * @param payload identyfikator firmy
   */
  constructor(public payload: string) {
  }
}

// [auth] refresh token

export const REFRESH_TOKEN_REQUEST = '[AUTH] refresh token - request';
export const REFRESH_TOKEN_SUCCESS = '[AUTH] refresh token - success';

export class RefreshTokenRequest implements Action {
  readonly type = REFRESH_TOKEN_REQUEST;

}

export class RefreshTokenSuccess implements Action {
  readonly type = REFRESH_TOKEN_SUCCESS;

  constructor(public payload: any) {
  }
}

export type AuthActions =
  LoginRequest | LoginFailure | LoginSuccess | LoginUserData | LoginSuccessRestored |
  PinRequest | PinFailure | PinSuccess |
  BrowserConfirmed | BrowserNotConfirmed | BrowserApproved |
  AuthenticationSuccess | LogoutRequest | ChangeUserComapnyReq | ChangeUserComapnySuccess |
  RefreshTokenRequest | RefreshTokenSuccess | LogoutSuccess;
//endregion

//region NGRX new actions
export const LogoutRequest2 = createAction(
  '[Auth] LOGOUT request',
  props<{ payload?: string }>()
);

export const LoginRequest2 = createAction(
  '[Auth] New login request',
  props<{ payload: { login: string, password: string } }>()
);

export const LoginSuccess2 = createAction(
  '[Auth] Login success',
  props<{ payload: { login: string, token: string } }>()
);

export const LoginSuccessRestored2 = createAction(
  '[Auth] Login state restored',
  props<{ payload: { user: { companyId: number, login: string, userId: number }, token: string } }>()
);

export const LoginUserData2 = createAction(
  '[Auth] Login success - set user information',
  props<{ payload: Uzytkownik }>()
);

export const LoginFailure2 = createAction('[Auth] Login failure');

export const PinRequest2 = createAction(
  '[Auth] New PIN verification request',
  props<{ payload: string }>()
);

export const PinSuccess2 = createAction('[Auth] PIN verification success');

export const PinFailure2 = createAction('[Auth] PIN verification failure');
//endregion
