<ng-container *ngIf="userAffiliationModel$ | async as userAffiliationModel">
  <div class="user-basic-info"
       fxLayout="row" fxLayoutAlign="space-beteween center" fxLayoutGap="10px">
    <div mat-card-avatar class="example-header-image pointer"
         (click)="showUserInfo()" appMaterialElevation>
    </div>
    <span class="name-surname mat-body-2">{{ userAffiliationModel.fullName | titlecase}}</span>
    <mat-icon class="close-icon" (click)="toggle_()">close</mat-icon>
  </div>
  <div class="user-affiliation mat-body-2" fxLayout="row">
    <div fxLayout="column" fxFlexOffset="42px">
      <span *ngIf="userAffiliationModel.role">{{ userAffiliationModel.role.nazwa_roli | titlecase}}</span>
      <span *ngIf="userAffiliationModel.companyName">{{ userAffiliationModel.companyName }}</span>
    </div>
  </div>
</ng-container>
