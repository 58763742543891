import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'transit-test-result-frame',
  template: `
    <mat-card class="transit-test-frame">
      <ng-content></ng-content>
    </mat-card>
  `,
  styleUrls: ['./transit-test-result-frame.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransitTestResultFrameComponent {
  constructor() {}
}
