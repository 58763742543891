import { Locker } from '../../../models/dto/vehicle';
import { Rygiel } from '../../../models/dto/terminale';
import { BoltState } from '../../../helpers/enum';

export interface LockerInfo {
  class: BoltState;
  tooltip: string;
  icon: string;
}

export class LockerHelper {
  static getLockerStatus(rygiel?: Locker | Rygiel): LockerInfo {
    if (!rygiel) {
      return {class: BoltState.NO_DEVICE, tooltip: 'No locker for this transport detected', icon: 'no_encryption'};
    }
    switch (true) {
      case rygiel.otwieranie:
        return {class: BoltState.OPENING, tooltip: 'Bolt is opening', icon: 'vpn_key'};
      case rygiel.zamykanie:
        return {class: BoltState.CLOSING, tooltip: 'Bolt is closing', icon: 'vpn_key'};
      case rygiel.otwarty:
        return {class: BoltState.OPENED, tooltip: 'Bolt is open', icon: 'lock_open'};
      case rygiel.zamkniety:
        return {class: BoltState.CLOSED, tooltip: 'Bolt is closed', icon: 'lock'};
      default:
        return {class: BoltState.UNKNOWN, tooltip: 'No info about bolt state', icon: 'lock'};
    }
  }
}
