import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { interval, Observable, Subscription } from 'rxjs';
import * as fromTransport from '../../ngrx/transport.reducer';
import * as TRANSPORT from '../../ngrx/transport.actions';
import { ViewMode } from '../../../../helpers/enum';
import * as fromRoot from '../../../../app.reducer';

@Component({
  selector: 'app-transport-list',
  templateUrl: './transport-list.component.html',
  styleUrls: ['./transport-list.component.scss']
})
export class TransportListComponent implements OnDestroy {

  subs = new Subscription();
  viewMode$: Observable<ViewMode>;
  eViewMode = ViewMode;

  constructor(
    private store: Store<fromTransport.State>
  ) {
    this.store.dispatch(TRANSPORT.loadAllTransitsRequest());
    this.viewMode$ = store.select(fromRoot.selectors.ui.getTransportViewMode);
    this.subs.add(interval(45 * 1000).subscribe(() => {
      this.store.dispatch(TRANSPORT.loadAllTransitsRequest());
    }));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
