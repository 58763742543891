import { Directive, Input } from '@angular/core';
import { CommonDirective } from '../common.directive';

@Directive({
  selector: '[appHasRoles]'
})
/**
 * Generic role where as a value you can pass multiple roles to verify e.g.: *appHasRole="role1,role2"
 */
export class HasRolesDirective extends CommonDirective {
  protected reqRoles: string[] = [];

  @Input()
  set appHasRole(role: string) {
    this.reqRoles = role.split(',').map(r => r.trim());
  }

  static check(roles: string[]): boolean {
    return CommonDirective.tokenDetails$?.getValue()?.roles?.filter(r => roles.indexOf(r) !== -1).length > 0;
  }

  protected checkPermission(): boolean {
    const matchRoles = CommonDirective.tokenDetails$.getValue().roles.filter(r => this.reqRoles.indexOf(r) !== -1);
    return matchRoles.length > 0;
  }
}
