import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {
  MatFormField,
  MatFormFieldControl,
  MAT_FORM_FIELD,
} from '@angular/material/form-field';
import {
  BehaviorSubject,
  fromEvent,
  Observable,
  Subject,
  Subscription,
} from 'rxjs';
import {
  debounceTime,
  map,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { AutocompleteSuggestion } from 'src/app/models/map/autocomplete';
import { GeocodeResponse, LocationData } from 'src/app/models/map/geocode';
import { PointGuard } from 'src/app/modules/shared/type-guards';
import { HereService } from '../../services/here.service';
import { PointSearchBase } from '../point-search-base/pont-search-base';

const INPUT_DEBOUCE_MS = 350;

@Component({
  selector: 'geocoding-search',
  templateUrl: './geocoding-search.component.html',
  styleUrls: ['./geocoding-search.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: GeocodingSearchComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeocodingSearchComponent
  implements
    AfterViewInit,
    OnDestroy,
    MatFormFieldControl<PointGuard>,
    ControlValueAccessor {
  static nextId = 0;

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }
  @HostBinding() id = `geocoding-search-${GeocodingSearchComponent.nextId++}`;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  @Input() addPoint = true;
  @Output() geocoded: EventEmitter<LocationData> = new EventEmitter();

  @ViewChild('searchInput', { static: false }) input: ElementRef;

  private inputSubscription: Subscription;
  private locationsSearch$: Subject<string> = new Subject();
  private _placeholder: string;

  areSuggestionsLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isLocationLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  locations$: Observable<AutocompleteSuggestion[]> = this.locationsSearch$.pipe(
    switchMap((query: string) => this.geocode(query)),
    startWith([])
  );

  loadingSpinnerDiameter = 24;
  locationSpinnerDiameter = 16;
  stateChanges = new Subject<void>();
  focused: boolean;
  required: boolean;
  disabled: boolean;
  errorState: boolean;
  _value: PointGuard;

  searchBase: PointSearchBase = new PointSearchBase();

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    private hereService: HereService,
    private cd: ChangeDetectorRef
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  onChange = (_: PointGuard) => {};
  onTouched = () => {};

  ngAfterViewInit(): void {
    this.setupInputEvent();
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.inputSubscription.unsubscribe();
    this.locationsSearch$.unsubscribe();
  }

  writeValue(obj: PointGuard): void {
    this.value = obj;
    this.stateChanges.next();
    this.onChange(this.value);
    this.onTouched();
  }

  registerOnChange(fn: (point: PointGuard) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  setDescribedByIds(ids: string[]): void {
    this.input?.nativeElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(_: MouseEvent): void {
    return;
  }

  locationPicked(event: MatAutocompleteSelectedEvent): void {
    this.placeLocationDataPoint(event.option.value).pipe(take(1)).subscribe();
  }

  get empty(): boolean {
    return !Boolean(this.input?.nativeElement.value);
  }

  set value(_value: PointGuard) {
    this._value = _value;
    this.cd.detectChanges();
  }

  get value(): PointGuard {
    return this._value;
  }

  private geocode(query: string): Observable<AutocompleteSuggestion[]> {
    return this.hereService.autoComplete(query).pipe(
      tap(() => {
        this.areSuggestionsLoading$.next(false);
      }),
      map((result) => result.suggestions)
    );
  }

  private placeLocationDataPoint(
    point: AutocompleteSuggestion
  ): Observable<LocationData> {
    this.isLocationLoading$.next(true);

    return this.hereService.geoCodeByLocId(point.locationId).pipe(
      map((res: GeocodeResponse) => {
        const pointLocation = res.Response.View[0].Result[0].Location;
        this.writeValue(pointLocation);
        this.geocoded.emit(pointLocation);

        // Sometimes parent component should handle it on its own
        if (this.addPoint) {
          this.hereService.addPoint(pointLocation, {
            pointId: pointLocation.LocationId,
          });
        }

        this.isLocationLoading$.next(false);
        return pointLocation;
      })
    );
  }

  private setupInputEvent(): void {
    this.inputSubscription = fromEvent(this.input.nativeElement, 'input')
      .pipe(
        tap(() => {
          this.areSuggestionsLoading$.next(true);
        }),
        debounceTime(INPUT_DEBOUCE_MS)
      )
      .subscribe((event: InputEvent) => {
        this.locationsSearch$.next((event.target as HTMLInputElement).value);
      });
  }
}
