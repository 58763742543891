import { Component, Input } from '@angular/core';
import { ParkingInfo } from '../../../interfaces';

@Component({
  selector: 'app-transport-parkings',
  templateUrl: './transport-parkings.component.html',
  styleUrls: ['./transport-parkings.component.scss']
})
export class TransportParkingsComponent {
  @Input() parkingLots: ParkingInfo[] = [];
  isHidden = true;
}
