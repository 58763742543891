import { AfterContentChecked, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { IAlarmTransportowy, IAlarmTransportowyUproszczony } from '../../../interfaces';
import { DateFormat, MapViewRoute } from '../../../../../helpers/enum';
import { TransportAlert } from '../../../../../helpers';
import { RightDrawerService } from '../../../../shared/services';
import { LocationService } from '../../../../map/services/location.service';

@Component({
  selector: 'app-alert-tile',
  templateUrl: './alert-tile.component.html',
  styleUrls: ['./alert-tile.component.scss']
})
export class AlertTileComponent implements AfterContentChecked {
  @Input() alert: IAlarmTransportowyUproszczony | IAlarmTransportowy;
  @Input() type = '';
  @Input() context = '';
  @ViewChild('alertDescrRef') alertDescrRef: ElementRef;
  @ViewChild('alertOwnerRef') alertOwnerRef: ElementRef;
  df = DateFormat;

  tooltipAlertDescription: string;
  tooltipAlertOwner: string;

  constructor(private router: Router, private rightDrawer: RightDrawerService) {
  }

  alertHasOwner(alert: IAlarmTransportowyUproszczony | IAlarmTransportowy) {
    return !!alert.dyspozytor;
  }

  alertIsTerminated(alert: IAlarmTransportowyUproszczony | IAlarmTransportowy) {
    return TransportAlert.isAlertClosed(alert);
  }

  ngAfterContentChecked() {
    if (this.alertDescrRef && +this.alertDescrRef.nativeElement.clientWidth > +'159') {
      this.tooltipAlertDescription = this.alert.rodzajAlarmu.rodzaj;
    } else {
      this.tooltipAlertDescription = '';
    }

    if (this.alertOwnerRef && +this.alertOwnerRef.nativeElement.clientWidth > +'159') {
      this.tooltipAlertOwner = this.alert.dyspozytor?.imie + ' ' + this.alert.dyspozytor?.nazwisko;
    } else {
      this.tooltipAlertOwner = '';
    }
  }

  getActionIconRoute(): void {
    this.rightDrawer.close();
    if (this.isMapContext()) {
      this.router.navigate(['events/alerts/transport/', this.alert.id]);
      return;
    }
    this.router.navigate(['/map-view', MapViewRoute.ALERT, this.alert.id, this.alert.idPrzejazd]);
  }

  getActionIconTooltip(): string {
    if (this.isMapContext()) {
      return 'Go back to alert';
    }
    return 'Show on map';
  }

  getActionIcon() {
    if (this.isMapContext()) {
      return 'source';
    }
    return 'map';
  }

  showOnMapIcon(): boolean {
    if (!this.isMapContext() && this.alert['zdarzeniaPojazdu']) {
      const al = this.alert as IAlarmTransportowy;
      return [...al.zdarzeniaPojazdu ?? [], ...al.zgloszeniaKierowcy ?? []]
        .filter(item => LocationService.coordsValidator(item))
        .length > 0;
    }

    return this.isMapContext();
  }

  private isMapContext(): boolean {
    return this.context === 'map';
  }
}
