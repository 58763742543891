import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ISat, ISatJ } from 'src/app/models/frame';
import { FrameTest } from './frame-test';
import { TransitTest } from './transit-test';

export interface SatTestStatus {
  noDevice?: boolean;
  deviceOk: boolean;
  signalStrength: number;
}

export interface SatJTestStatus {
  upToDate: boolean;
  satJFrame: ISatJ | null;
}

export type SatStatus = SatJTestStatus & SatTestStatus;
export type SatFrameType = 'satj' | 'sat';

export class TransitTestSat
  implements TransitTest<SatStatus, ISatJ[] | ISat[]>
{
  private frameTest: FrameTest;
  private satJFrameStatus$: BehaviorSubject<SatJTestStatus> =
    new BehaviorSubject({
      upToDate: false,
      satJFrame: null,
    });
  private satFrameStatus$: BehaviorSubject<SatTestStatus> = new BehaviorSubject(
    {
      deviceOk: false,
      signalStrength: 0,
    }
  );
  private satFramesStatus$: Observable<SatStatus> = combineLatest([
    this.satJFrameStatus$,
    this.satFrameStatus$,
  ]).pipe(
    map(([satJStatus, satStatus]) => {
      const status: SatStatus = {
        ...satJStatus,
        ...satStatus,
      };

      this.status$.next(status);

      return status;
    })
  );

  private status$: BehaviorSubject<SatStatus> = new BehaviorSubject({
    upToDate: false,
    satJFrame: null,
    deviceOk: false,
    signalStrength: 0,
  });

  private statusSubscription: Subscription;

  constructor(frameRequestTimestamp: number) {
    this.frameTest = new FrameTest(frameRequestTimestamp);
    this.statusSubscription = this.satFramesStatus$.subscribe();
  }

  get status(): Observable<SatStatus> {
    return this.status$.asObservable();
  }

  dispose(): void {
    if (this.statusSubscription) {
      this.statusSubscription.unsubscribe();
    }
  }

  passData(frames: ISatJ[] | ISat[], frameType: SatFrameType) {
    this.testFrames(frames, frameType);
  }

  private testFrames(frames: ISatJ[] | ISat[], frameType: SatFrameType): void {
    switch (frameType) {
      case 'sat':
        const satStatus: SatTestStatus = {
          deviceOk: false,
          signalStrength: 0,
        };
        const frame: ISat | undefined = frames[0] as ISat;

        // SAT device exists
        if (frame) {
          satStatus.noDevice = false;
          satStatus.deviceOk = frame.satSpr;
          satStatus.signalStrength = frame.silaSat;

        // SAT device does not exist
        } else {
          satStatus.noDevice = true;
        }

        this.satFrameStatus$.next(satStatus);
        break;

      case 'satj':
        const satJStatus: SatJTestStatus = {
          upToDate: false,
          satJFrame: null,
        };

        const upToDateFrame: ISatJ = (<ISatJ[]>frames).find((frame) =>
          this.frameTest.isUpToDate(frame?.czas)
        );

        // SAT is up to date
        if (upToDateFrame) {
          satJStatus.upToDate = true;
          satJStatus.satJFrame = upToDateFrame;
        }

        this.satJFrameStatus$.next(satJStatus);
        break;
    }
  }
}
