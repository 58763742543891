import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromAlert from '../../../alerts/ngrx/alert.reducer';
import * as fromRoot from '../../../../app.reducer';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { IAlarmTransportowy, IAlarmTransportowyUproszczony } from '../../../alerts/interfaces';
import { combineLatest, interval, Subscription } from 'rxjs';
import * as ALERT from '../../../alerts/ngrx/alert.actions';
import { MomentHelper, Strings } from '../../../../helpers';

@Component({
  selector: 'app-transport-alert',
  templateUrl: './transport-alert.component.html',
  styleUrls: ['./transport-alert.component.scss']
})
export class TransportAlertComponent implements OnInit, OnDestroy {
  static readonly myname = Strings.getObjectHash('TransportAlertComponent');

  readonly originTitle;
  currentAlert: IAlarmTransportowyUproszczony;
  currentDetailedAlert: IAlarmTransportowy;
  subs = new Subscription();

  constructor(private store: Store<fromRoot.State>) {
  }

  ngOnInit(): void {
    this.subs.add(
      interval(500).pipe(take(1))
        .subscribe(() => {
          if (this.currentAlert === undefined) {
            this.store.dispatch(new ALERT.LoadAllTransportAlertsRequest());
          }
        })
    );


    setTimeout(() => {
      if (this.currentAlert === undefined) {
        this.store.dispatch(new ALERT.LoadAllTransportAlertsRequest());
      }
    }, 500);

    this.subs.add(
      this.store.select(fromRoot.selectors.ui.getRightDrawerInfo)
        .pipe(
          filter(data => (data.componentName !== undefined && data.componentName === TransportAlertComponent.myname)
            || data.isOpened === false && this.currentAlert !== null),
          filter(data => data.componentData !== undefined),
          map(data => data.componentData as { id: number })
        )
        .pipe(
          tap((alertInfo) => this.store.dispatch(new ALERT.LoadDetailedTransportAlertByIdRequest(alertInfo.id))),
          switchMap(alertInfo => {
            return combineLatest([
              this.store.select(fromAlert.getFlattenedTransportAlertsById(alertInfo.id)),
              this.store.select(fromAlert.getDetailedTransportAlertsById(alertInfo.id))
            ]);
          })
        )
        .subscribe(([alert, detailedAlert]) => {
          if (alert && Strings.getObjectHash(alert) !== Strings.getObjectHash(this.currentAlert)) {
            this.currentAlert = {...alert};
          }
          if (detailedAlert) {
            const newDetailedAlerts = {
              ...detailedAlert, zdarzeniaPojazdu: [...detailedAlert.zdarzeniaPojazdu]
                .sort((a, b) =>
                  MomentHelper.compareDates(a.czas, b.czas, 'desc'))
            };
            if (Strings.getObjectHash(newDetailedAlerts) !== Strings.getObjectHash(this.currentDetailedAlert)) {
              this.currentDetailedAlert = newDetailedAlerts;
            }
          }
        })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
