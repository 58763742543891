import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';
import { Uzytkownik } from '../../../models/dto/user';
import { UserEditComponent } from '../user-edit/user-edit.component';
import { RightDrawerService } from '../../shared/services';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;

  lastEditedUserId: number | null = null;
  subs = new Subscription();
  displayedColumns: string[] = ['id', 'nazwa', 'firma', 'login', 'email', 'lBlHasel', 'actions'];
  userList = new MatTableDataSource();

  constructor(private store: Store<fromRoot.State>, private rightDrawerService: RightDrawerService) {
  }

  ngOnInit() {
    this.userList.sortingDataAccessor = (item: Uzytkownik, property) => {
      switch (property) {
        case 'id':
          return item.id;
        case 'nazwa':
          return item.nazwisko;
        case 'firma':
          return item._firma.nazwa;
        case 'login':
          return item.username;
        case 'email':
          return item.email;
        case 'lBlHasel':
          return item.l_bl_hasel;
        default:
          return item[property];
      }
    };

    this.subs.add(
      this.store.select(fromRoot.selectors.user.getCompanyUsers).subscribe(workers => {
        this.userList.data = workers;
        this.userList.sort = this.sort;
      })
    );
  }

  ngAfterViewInit() {
    this.userList.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onEdit(user: Uzytkownik): void {
    this.lastEditedUserId = user?.id;
    this.rightDrawerService.openOver(UserEditComponent, user);
  }
}
