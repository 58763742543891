import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { UserInfoComponent } from './layout/user-info/user-info.component';
import { HomeComponent } from './modules/shared/components';
import { IsAuthenticatedGuard } from './guards';

export const routes: Routes = [
  {
    path: '', children: [
      {path: '', redirectTo: '/access/login', pathMatch: 'full'},
      {
        path: 'home', children: [
          {path: '', component: HomeComponent},
          {path: 'userInfo', component: UserInfoComponent},
        ], canActivate: [IsAuthenticatedGuard]
      },
      {path: 'admin', loadChildren: () => import('./modules-lazy/administration/admin.module').then(m => m.AdminModule)},
      {path: 'dictionaries', loadChildren: () => import('./modules-lazy/inventory/inventory.module').then(m => m.InventoryModule)},
      // { path: 'events', loadChildren: () => import('./modules-lazy/event/event.module').then(m => m.EventModule) },
      // { path: 'transit', loadChildren: () => import('./modules-lazy/transit/transit.module').then(m => m.TransitModule) }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRouting {
}
