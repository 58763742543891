import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';

import * as fromRoot from '../../../app.reducer';
import * as MAP from '../../../ngrx/map.actions';

import { GenericPoint, MapChannelEvent, MapChannelEventType, MapDragEvent, MapPoint, Waypoint } from '../../../models/map';
import { AutocompleteResponse } from '../../../models/map/autocomplete';
import { GeocodeResponse, LocationData } from '../../../models/map/geocode';
import { ReverseGeocodeResponse } from '../../../models/map/reverseGeocode';
import { PunktSpedycyjny } from '../../../models/dto/transportSets';
import { IconService } from './icon.service';
import { Coords, CoordsMap } from '../../shared/interfaces';
import { MapObjectsHelper } from '../main/here/helpers';
import { LocationService } from './location.service';
import { SvgMapIcon } from '../../../helpers/enum';
import { environment } from 'src/environments/environment';
import * as flexiblePolyline from '../../../helpers/flexible-polyline.js';
import { MapPointsGuard, PointGuard } from '../../shared/type-guards';
import { uniqWith, xorWith, isEqual } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class HereService {
  public static isInit = false;
  public static channel = new Subject<MapChannelEvent>();

  private static _map: any;
  private static _H: any;
  private static mapGroups: {[key: string]: any} = {};

  private apiKey: string;

  private AUTOCOMPLETION_URL = 'https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json';
  private GOCODE_URL = 'https://geocoder.ls.hereapi.com/6.2/geocode.json';
  private ROUTE_URL = 'https://router.hereapi.com/v8/routes';

  private _platform: any;

  private static isInitialized() {
    if (!HereService.isInit) {
      throw new Error('Map was not initialized! Please call HereService.initializeMap first');
    }

    return HereService.isInit;
  }

  constructor(
    private http: HttpClient,
    private store: Store<fromRoot.State>) {}

  static get map(): any {
    HereService.isInitialized();
    return HereService._map;
  }

  static get H(): any {
    HereService.isInitialized();
    return HereService._H;
  }

  get platform(): any {
    HereService.isInitialized();
    return this._platform;
  }


  initializeMap(H, mapContainer) {
    if (!HereService.isInit) {
      HereService._H = H;
      this.apiKey = environment.here.apiKey;
      this._platform = new H.service.Platform({
        apikey: this.apiKey,
        useCIT: true,
        useHTTPS: true
      });
    }

    const defaultLayers = this._platform.createDefaultLayers({tileSize: 512});

    const newMap = new H.Map(
      mapContainer.nativeElement,
      defaultLayers.vector.normal.map,
      {
        center: {lat: 52.069167, lng: 19.480556},
        pixelRatio: Math.min(2, devicePixelRatio),
        zoom: 6
      }
    );

    const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(newMap));
    const ui = H.ui.UI.createDefault(newMap, defaultLayers);
    ui.getControl('scalebar').setAlignment('bottom-center');
    HereService._map = newMap;

    this.addDraggableFunctionality(HereService._map, HereService._H, behavior);

    HereService.isInit = true;
    return HereService.map;
  }

  addDraggableFunctionality(map, H, behavior): void {
    // disable the default draggability of the underlying map
    // and calculate the offset between mouse and target's position
    // when starting to drag a marker object:
    map.addEventListener(
      'dragstart',
      function (ev: MapDragEvent) {
        const target = ev.target;
        const pointer = ev.currentPointer;
        if (target instanceof H.map.Marker) {
          const targetPosition = map.geoToScreen(target.getGeometry());
          target['offset'] = new H.math.Point(
            pointer.viewportX - targetPosition.x,
            pointer.viewportY - targetPosition.y
          );
          behavior.disable();
        }
      },
      false
    );

    // re-enable the default draggability of the underlying map
    // when dragging has completed
    map.addEventListener(
      'dragend',
      function (ev: MapDragEvent) {
        const target = ev.target;
        const pointer = ev.currentPointer;
        if (target instanceof H.map.Marker) {
          const geo = map.screenToGeo(
            pointer.viewportX - target['offset'].x,
            pointer.viewportY - target['offset'].y
          );
          HereService.channel.next({
            eventType: MapChannelEventType.PointDragend,
            value: { ...target.getData(), geo: geo },
          });

          behavior.enable();
        }
      },
      false
    );

    // Listen to the drag event and move the position of the marker
    // as necessary
    map.addEventListener(
      'drag',
      (ev: MapDragEvent) => {
        const target = ev.target;
        const pointer = ev.currentPointer;
        if (target instanceof H.map.Marker) {
          const geo = map.screenToGeo(
            pointer.viewportX - target['offset'].x,
            pointer.viewportY - target['offset'].y
          );
          target.setGeometry(geo);
          HereService.channel.next({
            eventType: MapChannelEventType.PointDrag,
            value: { ...target.getData(), geo: geo },
          });
        }
      },
      false
    );
  }

  geoCode(textAddress: string): Observable<GeocodeResponse> {
    const params: HttpParams = new HttpParams()
      .append('searchtext', textAddress)
      .append('apiKey', this.apiKey);

    return this.http.get<GeocodeResponse>(this.GOCODE_URL, {params: params});
  }

  geoCodeByLocId(locationId: string): Observable<GeocodeResponse> {
    const params: HttpParams = new HttpParams()
      .append('locationId', locationId)
      .append('apiKey', this.apiKey);

    return this.http.get<GeocodeResponse>(this.GOCODE_URL, {params: params});
  }

  autoComplete(textToSearch: string): Observable<AutocompleteResponse> {
    const params: HttpParams = new HttpParams()
      .append('query', textToSearch)
      .append('beginHighlight', '<b>')
      .append('endHighlight', '</b>')
      .append('maxresults', '7')
      .append('apiKey', this.apiKey);

    const headers: HttpHeaders = new HttpHeaders()
      .append('Accept-Language', 'pl-PL');

    return this.http.get<AutocompleteResponse>(this.AUTOCOMPLETION_URL, {params, headers});
  }

  addPoint(point: PointGuard, metadata?: { data?: any, pointId?: string }, marker?: string) {
    let coord: CoordsMap;

    if (MapPointsGuard.isCoordsNamed(point)) {
      coord = {
        lat: point.latitude,
        lng: point.longitude
      };
    } else if (MapPointsGuard.isLocationData(point)) {
      coord = {
        lat: point.DisplayPosition.Latitude,
        lng: point.DisplayPosition.Longitude
      };
    } else if (MapPointsGuard.isCoordsMap(point)) {
      coord = {...point};
    }

    let ops = {};
    if (marker) {
      ops = {...ops, icon: new HereService.H.map.Icon(marker)};
    }

    const mapPoint = new HereService._H.map.Marker(coord, ops);

    let pointData = {};
    if (metadata) {
      pointData = {...pointData, ...metadata};
    }
    mapPoint.setData(pointData);
    HereService.map.addObject(mapPoint);
  }

  addGenericLine(line: Coords[], colorNo: number = 1): void {
    const color = MapObjectsHelper.getLineColor(colorNo);
    const lineString = new HereService.H.geo.LineString();

    line
      .filter(point => LocationService.coordsValidatorRaw(point))
      .map(point => LocationService.getCoordsMap(point))
      .forEach(point => lineString.pushPoint(point));

    HereService.map.addObject(new HereService.H.map.Polyline(
      lineString, {
        style: {lineWidth: 12, strokeColor: color[0], fillColor: color[1]},
        arrows: true
      }
    ));
  }

  addCustomGroupObjects(objects: any[], groupName?: string): void {
    const group = new HereService._H.map.Group();
    group.addObjects(objects);
    if (groupName) {
      if (HereService.mapGroups[groupName]) {
        try {
          HereService.map.removeObject(HereService.mapGroups[groupName]);
        } catch (e) {
          console.error(e);
        }
      }
      HereService.mapGroups[groupName] = group;
    }
    HereService.map.addObject(group);
  }

  // TODO there is no type for map group right now
  addGenericLineGroup(lines: Coords[][], groupName?: string): unknown {
    const group = new HereService._H.map.Group();

    lines.forEach((line, idx) => {
      const color = MapObjectsHelper.getLineColor(idx + 1);
      const lineString = new HereService.H.geo.LineString();

      line
        .filter(point => LocationService.coordsValidatorRaw(point))
        .map(point => LocationService.getCoordsMap(point))
        .forEach(point => lineString.pushPoint(point));

      const [outlinePolyline, directionPolyline] = this.getPolylines(lineString, color as [string, string]);

      group.addObject(outlinePolyline);
      group.addObject(directionPolyline);
    });

    if (groupName) {
      if (HereService.mapGroups[groupName]) {
        try {
          HereService.map.removeObject(HereService.mapGroups[groupName]);
        } catch (e) {
          console.error(e);
        }
      }
      HereService.mapGroups[groupName] = group;
    }
    HereService.map.addObject(group);

    return group;
  }

  addGenericPointGroup(points: GenericPoint[], iconDom?: any, center = false, groupName?: string) {
    const groupPoints = points.map(point => {
      const coord = LocationService.getCoordsMap(point);

      let mapPoint;
      if (typeof coord !== 'undefined') {
        if (point.domIconString) {
          mapPoint = new HereService._H.map.DomMarker(coord, {
            icon: new HereService._H.map.DomIcon(point.domIconString)
          });
        } else if (point.iconType && point.iconType !== SvgMapIcon.__CUSTOM_ICON) {
          mapPoint = new HereService._H.map.DomMarker(coord, {
            icon: new HereService._H.map.DomIcon(IconService.getSvgIcon(point.iconType))
          });
        } else if (iconDom) {
          mapPoint = new HereService._H.map.DomMarker(coord, {
            icon: new HereService._H.map.DomIcon(iconDom)
          });
        } else {
          mapPoint = new HereService._H.map.Marker(coord);
        }
        if (point.pointClickAction) {
          mapPoint.addEventListener('tap', (evt) => point.pointClickAction(evt));
        }
      }
      return mapPoint;
    }).filter(point => typeof point !== 'undefined');
    const group = new HereService._H.map.Group();
    group.addObjects(groupPoints);

    if (groupName) {
      if (HereService.mapGroups[groupName]) {
        try {
          HereService.map.removeObject(HereService.mapGroups[groupName]);
        } catch (e) {
          console.error(e);
        }
      }
      HereService.mapGroups[groupName] = group;
    }
    HereService.map.addObject(group);

    if (center) {
      HereService.map.setCenter(LocationService.getCoordsMap(points[0]));
    }
  }

  addPointDraggable(
    point: PointGuard,
    metadata?: { data?: any, pointId?: string }) {
    let coord: CoordsMap;

    if (MapPointsGuard.isCoordsNamed(point)) {
      coord = {
        lat: point.latitude,
        lng: point.longitude
      };
    } else if (MapPointsGuard.isLocationData(point)) {
      coord = {
        lat: point.DisplayPosition.Latitude,
        lng: point.DisplayPosition.Longitude
      };
    }

    const mapPoint = new HereService._H.map.Marker(coord, {
      // mark the object as volatile for the smooth dragging
      volatility: true
    });
    // Ensure that the marker can receive drag events
    mapPoint.draggable = true;

    let pointData: MapPoint = {pointId: '-1'};
    if (metadata) {
      pointData = {...pointData, ...metadata};
    }
    mapPoint.setData(pointData);

    // Subscribe to the "contextmenu" eventas we did for the map.
    mapPoint.addEventListener('contextmenu', (e) => {
      // Add another menu item,
      // that will be visible only when clicking on this object.
      //
      // New item doesn't replace items, which are added by the map.
      // So we may want to add a separator to between them.
      e.items.push(
        new HereService._H.util.ContextItem({
          label: 'Remove',
          callback: () => {
            HereService._map.removeObject(mapPoint);
            HereService.channel.next({
              eventType: MapChannelEventType.RemoveWaypoint,
              value: pointData,
            });
          },
        })
      );
    });

    HereService.map.addObject(mapPoint);
  }

  removeMapObject(pointId: string): boolean {
    const objList = HereService.map.getObjects() as any[];
    const obj = objList.find(o => o.getData()?.pointId === pointId);

    if (obj) {
      HereService.map.removeObject(obj);
      return true;
    }

    return false;
  }

  clearAllPoints() {
    HereService.map.removeObjects(HereService.map.getObjects());
  }

  clearAllLines(): boolean {
    const objs = HereService.map.getObjects() as any[];
    const lines = [];
    objs.forEach(o => {
      if (o instanceof HereService.H.map.Polyline) {
        lines.push(o);
      }
    });
    if (lines.length === 0) {
      return false;
    }

    HereService.map.removeObjects(lines);
    return true;
  }

  reverseGeocode(coord: CoordsMap): Promise<ReverseGeocodeResponse> {
    const geocoder = this.platform.getGeocodingService(),
      reverseGeocodingParameters = {
        prox: `${coord.lat},${coord.lng},75`,
        mode: 'retrieveAddresses',
        maxresults: '1',
        jsonattributes: 1
      };

    return new Promise<ReverseGeocodeResponse>(
      (
        resolve: (value: ReverseGeocodeResponse) => void,
        reject: () => void
      ) => {
        geocoder.reverseGeocode(
          reverseGeocodingParameters,
          (result) => {
            const data = result['response']['view'][0][
              'result'
            ][0] as ReverseGeocodeResponse;
            resolve(data);
          },
          (error) => {
            console.error(error);
            reject();
          }
        );
      }
    );
  }

  // TODO types are must have here
  findRoute(points: (LocationData | ReverseGeocodeResponse | PunktSpedycyjny)[]): void {
    const group = new HereService._H.map.Group();

    try {
      HereService.map.removeObject(HereService.mapGroups['foundRoute']);
    } catch (e) {
      console.error(e);
    }

    let routeRequestParams: HttpParams = new HttpParams()
    .append('routingMode', 'fast')
    .append('transportMode', 'truck')
    .append('return', 'polyline,actions,instructions,summary')
    .append('apiKey', this.apiKey);

    points
    .forEach((point, index: number) => {
      const position =
      index === 0 ? 'origin' :
        index === points.length - 1 ? 'destination' :
          'via';

      if (point.hasOwnProperty('DisplayPosition')) {
        const p = point as LocationData;
        routeRequestParams = routeRequestParams.append(position, `${p.DisplayPosition.Latitude},${p.DisplayPosition.Longitude}`);
      } else if (typeof point['nazwa_kod'] !== 'undefined') {
        const p = point as PunktSpedycyjny;
        routeRequestParams = routeRequestParams.append(position, `${p.gps_ns},${p.gps_ew}`);
      } else {
        const p = point as ReverseGeocodeResponse;
        routeRequestParams = routeRequestParams.append(position, `${p.location.displayPosition.latitude},${p.location.displayPosition.longitude}`);
      }
    });

    this.http.get<any>(this.ROUTE_URL, {params: routeRequestParams}).subscribe((result) => {
      this.clearAllLines();
      this.store.dispatch(MAP.SearchRouteForWayPointsSuccess({data: [this.mapRouteResponse(result, points)]}));

      const route = result.routes[0];
      const sections = route.sections;

      sections.forEach(section => {
        const lineString =  new HereService._H.geo.LineString.fromFlexiblePolyline(section.polyline);
        const [outlinePolyline, directionPolyline] = this.getPolylines(lineString);

        group.addObject(outlinePolyline);
        group.addObject(directionPolyline);
      });

      HereService.map.addObject(group);
      HereService.mapGroups['foundRoute'] = group;
      this.centerMap(group);

    }, (error) => console.error(error));
  }

  getPolylines(
    lineString: CoordsMap[],
    [strokeColor, fillColor]: [string, string] = ['rgba(0, 128, 255, 0.7)', undefined]
  ) {
    const outlinePolyline = new HereService._H.map.Polyline(lineString, {
      style: {
        lineWidth: 6,
        strokeColor,
        ...(fillColor ? { fillColor } : {})
      }
    });

    const directionPolyline = new HereService._H.map.Polyline(lineString, {
      style: {
        lineWidth: 4,
        strokeColor: 'rgb(255, 255, 255)',
        lineDash: [0, 2],
        lineTailCap: 'arrow-tail',
        lineHeadCap: 'arrow-head'
      }
    });

    return [outlinePolyline, directionPolyline];
  }

  // TODO type of 'by' has to be more specific
  centerMap(by: { getBoundingBox: () => object }): void {
    HereService.map.getViewPort().setPadding(50, 50, 50, 50);
    HereService.map.getViewModel().setLookAtData({bounds: by.getBoundingBox()});
  }

  /**
   * Restores original structure from previous api version
   * for back-end purposes
   */
  private mapRouteResponse(response, points): object {
    const sections = response.routes[0].sections;
    const mapped = {
      response: {
        language: 'en-us',
        metaInfo: {
          timestamp: new Date().toISOString(),
        },
        route: [
          {
            leg: [],
            mode: {
              type: 'fastest',
              transportModes: ['truck'],
              trafficMode: 'disabled',
              feature: [],
            },
            shape: [],
            summary: {
              distance: 0,
              baseTime: 0,
              travelTime: 0,
              flags: [''],
              text: '',
              _type: '',
            },
            waypoint: [],
          },
        ],
      },
    };

    const sectionsEdges: {
      start: CoordsMap;
      end: CoordsMap;
      mode: string;
    }[] = [];

    sections.forEach((section) => {
      const decodedPolyline = flexiblePolyline.decode(section.polyline);
      // Add section shape to overall shape
      mapped.response.route[0].shape = [
        ...mapped.response.route[0].shape,
        ...decodedPolyline.polyline.map(
          ([lat, lng]: [number, number]) =>
            `${lat.toFixed(6)},${lng.toFixed(6)}`
        ),
      ];

      // Add edges
      const [latStart, lngStart] = decodedPolyline.polyline[0];
      const [latEnd, lngEnd] =
        decodedPolyline.polyline[decodedPolyline.polyline.length - 1];
      sectionsEdges.push({
        start: {
          lat: Number(latStart.toFixed(6)),
          lng: Number(lngStart.toFixed(6)),
        },
        end: {
          lat: Number(latEnd.toFixed(6)),
          lng: Number(lngEnd.toFixed(6)),
        },
        mode: section.transport.mode,
      });

      // Calculate maneuvers
      mapped.response.route[0].leg.push({
        maneuver: section.actions.map((action, index) => {
          // Calculate route shape of action
          const nextAction = section.actions[index + 1] || {};
          const nextOffset = nextAction.offset || action.offset + 1;
          let maneuverShape = [];
          if (decodedPolyline) {
            maneuverShape = decodedPolyline.polyline
              .slice(action.offset, nextOffset + 1)
              .map(([lat, lng]) => `${lat.toFixed(6)},${lng.toFixed(6)}`);
          }

          // Return action + shape as maneuver
          return {
            ...action,
            position: {
              latitude: Number.parseFloat(
                decodedPolyline.polyline[action.offset][0].toFixed(6)
              ),
              longitude: Number.parseFloat(
                decodedPolyline.polyline[action.offset][1].toFixed(6)
              ),
            },
            shape: maneuverShape,
            travelTime: action.duration,
          };
        }),
      });

      // Update summary
      const { distance, travelTime, baseTime } =
        mapped.response.route[0].summary;
      mapped.response.route[0].summary = {
        ...mapped.response.route[0].summary,
        distance: distance + section.summary.length,
        travelTime: travelTime + section.summary.duration,
        baseTime: baseTime + section.summary.baseDuration,
      };
    });

    // Prepare waypoints
    const reduceToCoords: (acc: CoordsMap[], sectionEdges) => CoordsMap[] = (
      acc,
      sectionEdge
    ) => {
      acc = [...acc, sectionEdge.start, sectionEdge.end];
      return acc;
    };

    const allSegments: CoordsMap[] = uniqWith(
      sectionsEdges.reduce(reduceToCoords, []),
      isEqual
    );

    const nonTruckSegments: CoordsMap[] = uniqWith(
      sectionsEdges
        .filter((sectionEdge) => sectionEdge.mode !== 'truck')
        .reduce(reduceToCoords, []),
      isEqual
    );

    const waypoints: CoordsMap[] = xorWith(
      allSegments,
      nonTruckSegments,
      isEqual
    );

    // Add waypoints with places labels
    points.forEach((point, index) => {
      const waypoint: Waypoint = {
        label: '',
        mappedRoadName: '',
      };
      const p = point;
      const { lat, lng } = waypoints[index];

      waypoint.mappedPosition = {
        latitude: Number(lat.toFixed(6)),
        longitude: Number(lng.toFixed(6)),
      };

      if (MapPointsGuard.isLocationData(p)) {
        waypoint.label = p.Address.Label;
        waypoint.originalPosition = {
          latitude: Number(p.DisplayPosition.Latitude.toFixed(6)),
          longitude: Number(p.DisplayPosition.Longitude.toFixed(6)),
        };
      } else if (MapPointsGuard.isPunktSpedycyjny(p)) {
        waypoint.label = p.nazwa;
        waypoint.originalPosition = {
          latitude: Number(p.gps_ns.toFixed(6)),
          longitude: Number(p.gps_ew.toFixed(6)),
        };
      } else if (MapPointsGuard.isReverseGeocodeResponse(p)) {
        waypoint.label = p.location.address.label;
        waypoint.originalPosition = {
          latitude: Number(p.location.displayPosition.latitude.toFixed(6)),
          longitude: Number(p.location.displayPosition.longitude.toFixed(6)),
        };
      } else {
        return;
      }

      waypoint.mappedRoadName = waypoint.label;
      mapped.response.route[0].waypoint.push(waypoint);
    });

    return mapped;
  }
}
