import { Pipe, PipeTransform } from '@angular/core';
import { Kierowca } from '../../drivers/interfaces';

@Pipe({
  name: 'filterDrivers',
})
export class FilterDriversPipe implements PipeTransform {
  transform(drivers: Kierowca[], query: string | null | undefined): Kierowca[] {
    return query
      ? drivers.filter((driver) =>
          `${driver._uzytkownik?.imie} ${driver._uzytkownik?.nazwisko}`
            .toLowerCase()
            .includes(query.toLowerCase())
        )
      : drivers;
  }
}
