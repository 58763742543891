import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AuthenticationRouting } from './authentication.routing';
import {
  AccessComponent,
  LoginComponent,
  LogoutComponent,
  PasswordResetComponent,
  RegisterComponent,
  Step1LoginFormComponent,
  Step2LoginPinComponent
} from './access';
import { CustomMaterialModule } from '../material.modules';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AuthenticationRouting,
    FlexLayoutModule,
    CustomMaterialModule
  ],
  declarations: [LoginComponent, RegisterComponent, PasswordResetComponent,
    AccessComponent, LogoutComponent,
    Step1LoginFormComponent, Step2LoginPinComponent],
  exports: [LoginComponent, RegisterComponent, PasswordResetComponent],
})
export class AuthenticationModule {
}
