<div class="content" fxFlex fxLayout="column" fxLayoutGap="16px">
  <h2>{{title}}</h2>
  <form [formGroup]="form">
    <div fxFlex fxLayout="column" fxLayoutGap="4px">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput formControlName="nazwa" name="parking-name">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Region</mat-label>
        <input matInput formControlName="region" name="parking-region">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Country</mat-label>
        <input matInput formControlName="kraj" name="kraj">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Details</mat-label>
        <input matInput formControlName="uwagi" name="parking-details">
      </mat-form-field>

      <div fxFlex fxLayout="row" fxLayoutGap="8px">
        <mat-form-field class="phone-no">
          <mat-label>Phone 1</mat-label>
          <input matInput formControlName="telefon" name="parking-telefon">
        </mat-form-field>
        <mat-form-field class="phone-no">
          <mat-label>Phone 2</mat-label>
          <input matInput formControlName="telefon2" name="perking-telefon-2">
        </mat-form-field>
      </div>

      <mat-form-field>
        <mat-label>Utilities</mat-label>
        <input matInput formControlName="atrakcje" name="parking-atrakcje">
      </mat-form-field>

      <div fxFlex fxLayout="row" fxLayoutGap="4px">
        <mat-checkbox class="example-margin" formControlName="dozorowany">Supervised</mat-checkbox>
        <mat-checkbox class="example-margin" formControlName="kamery">Cameras</mat-checkbox>
      </div>
      <div fxFlex fxLayout="row" fxLayoutGap="4px">
        <mat-checkbox class="example-margin" formControlName="strzezony">Monitored</mat-checkbox>
        <mat-checkbox class="example-margin" formControlName="oswietlony">Illuminated</mat-checkbox>
      </div>
      <div fxFlex fxLayout="row" fxLayoutGap="4px">
        <mat-checkbox class="example-margin" formControlName="nadzLadNiebezp"
                      matTooltip="Does the car park supervise dangerous goods?">Dangerous goods
        </mat-checkbox>
        <mat-checkbox class="example-margin" formControlName="ogrodzony">Fenced</mat-checkbox>
      </div>
      <div fxFlex fxLayout="row" fxLayoutGap="4px">
        <mat-checkbox class="example-margin" formControlName="pies">Dog</mat-checkbox>
        <mat-checkbox class="example-margin" formControlName="nocleg">Overnight stays</mat-checkbox>
      </div>

      <div fxFlex fxLayout="row" fxLayoutGap="4px">
        <ng-container *ngIf="context === 'map-view'">
          <span fxFlex></span>
          <button mat-raised-button color="primary" (click)="callback()">{{callbackData.label}}</button>
        </ng-container>
        <ng-container *ngIf="context !== 'map-view'">
          <button mat-stroked-button color="accent" *ngIf="context!=='view'" (click)="resetForm()">Reset</button>
          <button mat-raised-button color="primary" *ngIf="context!=='view'">Save</button>
          <!-- todo: zapis danych nt parkingów w oddzielnym zadaniu-->
          <button mat-raised-button color="primary" *ngIf="context==='view'" (click)="editForm()" [disabled]="true">
            Edit
          </button>
        </ng-container>
      </div>
    </div>
  </form>
</div>
