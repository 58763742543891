import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';

import * as fromRoot from '../../app.reducer';
import * as UI from '../../ngrx/ui.actions';

import { GenericNavItem } from 'src/app/models/dto';
import { SecurityHelper, Strings } from '../../helpers';

@Component({
  selector: 'app-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.scss']
})
export class SubMenuComponent implements OnInit, OnDestroy {

  subs = new Subscription();
  hash = '';

  subMenuLinks: GenericNavItem[];
  subMenuNgrxLinks: GenericNavItem[];

  isMenuVisible = true;

  constructor(private store: Store<fromRoot.State>) {
  }

  ngOnInit(): void {
    this.subs.add(
      combineLatest([
        this.store.select(fromRoot.selectors.ui.getSideMenuLinks),
        this.store.select(fromRoot.selectors.auth.getAuthIsAuthenticated),
        this.store.select(fromRoot.selectors.ui.getSideMenuLinksStatus),
      ])
        .subscribe(([links, _, status]) => {
          this.isMenuVisible = status;
          if (this.hash !== Strings.getObjectHash(links)) {
            const approvedLinks = SecurityHelper.filterLinks([...links]);
            this.subMenuLinks = approvedLinks.filter(link => link.itemType !== 'UiNgrxAction');
            this.subMenuNgrxLinks = approvedLinks.filter(link => link.itemType === 'UiNgrxAction');
          }
          this.hash = Strings.getObjectHash(links);
        })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngrxMenuAction(actionValue: string) {
    this.store.dispatch(UI.sideLinkAction({linkAction: {value: actionValue}}));
    return false;
  }

}
