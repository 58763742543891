import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'alertColHeader',
})
export class AlertColHeaderPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'status': {
        return 'Status';
      }
      case 'riskAssessment': {
        return 'Risk';
      }
      case 'name': {
        return 'Name';
      }
      case 'type': {
        return 'Type';
      }
      case 'description': {
        return 'Description';
      }
      case 'date': {
        return 'Date';
      }
      case 'time': {
        return 'Time';
      }
      case 'owner': {
        return 'Owner';
      }
      case 'statusChange': {
        return 'Status change';
      }
      case 'by': {
        return 'By';
      }
      case 'notes': {
        return 'Notes';
      }
      default:
        return '';
    }
  }
}
