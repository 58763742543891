<div class="alert-type-form-container" fxLayout="column" fxLayoutGap="16px">
  <p mat-subheading-1>{{ formHeading }}</p>

  <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()" fxLayout="column" fxLayoutGap="16px">
    <mat-form-field appearance="outline" class="custom-form-ctrl alert-type">
      <mat-label>Alert type...</mat-label>
      <input type="text" matInput formControlName="type">
    </mat-form-field>
  
    <mat-form-field appearance="outline" class="custom-form-ctrl alert-type">
      <mat-label>Description...</mat-label>
      <input type="text" matInput formControlName="description">
    </mat-form-field>
  
    <mat-form-field appearance="outline" class="custom-form-ctrl alert-type">
      <mat-label>Risk assessment</mat-label>
      <input type="number" matInput formControlName="riskAssessment">
    </mat-form-field>

    <div fxFlex fxLayoutAlign="end center" fxLayoutGap="5px">
      <button mat-raised-button color="primary" class="mat-button" type="submit" [disabled]="form.invalid">
        {{ buttonName }}
      </button>
    </div>
  </form>

</div>
