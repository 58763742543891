import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getObjectById'
})
export class GetObjectByIdPipe implements PipeTransform {

  transform<T>(value: T[], id: number): T | undefined {
    return value.find(item => {
      if (!item['id']) {
        return false;
      }
      return item['id'] === id;
    });
  }

}
