import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SideNavItem, TopNavItem } from '../models';


@Injectable({
  providedIn: 'root'
})
export class NavMenuService {

  // private subjectSideLinks$ = new Subject<SideNavItem[]>();
  private subjectSideLinks$ = new BehaviorSubject<Array<SideNavItem>>([]);
  private subjectTopLinks$ = new BehaviorSubject<Array<TopNavItem>>([]);

  constructor() {
    this.clearMenuItems();
  }

  /**
   * Wyczyszczenie obecnego menu
   */
  clearMenuItems(): void {
    this.subjectSideLinks$.next([]);
  }

  /**
   * Wyczyszczenie listy linków TopNav i wpisanie tylko linku logowania
   */
  clearTopMenuItems(): void {

    this.subjectTopLinks$.next([]);
  }

  /**
   * Dodanie nowego linku do górnego menu
   * @param links zestaw linkow razem z tytułami
   */
  addNewTopMenuItem(links: TopNavItem[]): void {
    this.subjectTopLinks$.next(links);
  }

  /**
   * Wygenerowanie domyślnego menu dla wszystkich NIEZALOGOWANYCH użytkowników
   */
  generateGeneralTopLinks() {
    this.clearTopMenuItems();

    const links = [];
    links.push(new TopNavItem('/access/login', 'Login', 'right'));
    links.push(new TopNavItem('/', 'Home', 'left'));
    this.addNewTopMenuItem(links);
  }

}
