import { IAlarmTransportowy } from '../modules/alerts/interfaces';
import { AlertStatus, TerminalStatus } from './enum';
import { IAlarmTransportowyUproszczonyDTO } from '../models/dto/alert/alarm-transportowy-uproszczony';
import { IAlarmPodstawowy } from '../models/dto/alert/alarm-transportowy';

export class TransportAlert {
  static activeAlertStatusList = [AlertStatus.new, AlertStatus.handled, AlertStatus.unsolved];
  static notActiveAlertStatusList = [
    AlertStatus.closedAsFalse, AlertStatus.automaticallyClosedViaSystem, AlertStatus.closedAfterIncidentOccurrence, AlertStatus.testAlarm
  ];

  static isAlertClosed(alert: IAlarmTransportowy | IAlarmTransportowyUproszczonyDTO | IAlarmPodstawowy): boolean {
    if (!alert) {
      return false;
    }

    if ('idStatusAl' in alert) {
      return !TransportAlert.activeAlertStatusList.includes(alert.idStatusAl);
    }

    if (!alert.statusAlarmu) {
      return false;
    }
    return !TransportAlert.activeAlertStatusList.includes(alert.statusAlarmu.id);
  }

  static isAlertOpen(alert: IAlarmTransportowy | IAlarmTransportowyUproszczonyDTO | IAlarmPodstawowy): boolean {
    if (!alert) {
      return false;
    }

    if ('idStatusAl' in alert) {
      return TransportAlert.activeAlertStatusList.includes(alert.idStatusAl);
    }

    if (!alert.statusAlarmu) {
      return false;
    }
    return TransportAlert.activeAlertStatusList.includes(alert.statusAlarmu.id);
  }

  static isProdAlert(alert: IAlarmTransportowy | IAlarmTransportowyUproszczonyDTO): boolean {
    if (alert.terminal) {
      return alert.terminal.status.id === TerminalStatus.IN_USE;
    }

    return true;
  }

  static isNewAlert(alert: IAlarmTransportowy | IAlarmTransportowyUproszczonyDTO): boolean {
    let terminalCond = true;
    if (alert.terminal) {
      terminalCond = alert.terminal.status.id === TerminalStatus.IN_USE;
    }

    return alert.statusAlarmu.id === AlertStatus.new && terminalCond;
  }
}
