import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, debounceTime, map, mergeMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';

import * as ADMIN from './admin.actions';
import * as UI from './ui.actions';

import { AdministrationService } from '../services';
import { Messages } from '../helpers/enum';

@Injectable()
export class AdminEffects {

  loadRouting$ = createEffect(() => this.actions.pipe(
    ofType(ADMIN.LOAD_ROUTING_LIST_REQUEST),
    switchMap(() => {
      return this.adminSrv.getRouting().pipe(
        map(list => new ADMIN.LoadRoutingListSuccess(list)),
        catchError(() => {
          return of(UI.userError({message: Messages.READING_DATA_ERR}));
        })
      );
    })
  ));

  addRouting$ = createEffect(() => this.actions.pipe(
    ofType<ADMIN.NewRoutingEntryRequest>(ADMIN.NEW_ROUTING_ENTRY_REQUEST),
    switchMap(action => {
      return this.adminSrv.addRouting(action.payload).pipe(
        map(resp => {
          const t = {...action.payload, id: resp['id']};
          return new ADMIN.NewRoutingEntrySuccess(t);
        }),
        catchError(() => {
          return of(UI.userError({message: Messages.READING_DATA_ERR}));
        })
      );
    })
  ));

  updRouting$ = createEffect(() => this.actions.pipe(
    ofType<ADMIN.UpdateRoutingEntryRequest>(ADMIN.UPDATE_ROUTING_ENTRY_REQUEST),
    switchMap(action => {
      return this.adminSrv.updRouting(action.payload).pipe(
        map(resp => {
          const t = {...action.payload, id: resp['id']};
          return new ADMIN.UpdateRoutingEntrySuccess(t);
        }),
        catchError(() => {
          return of(UI.userError({message: Messages.READING_DATA_ERR}));
        })
      );
    })
  ));

  delRouting$ = createEffect(() => this.actions.pipe(
    ofType<ADMIN.RemoveRoutingEntryRequest>(ADMIN.REMOVE_ROUTING_ENTRY_REQUEST),
    switchMap(action => {
      return this.adminSrv.delRouting(action.payload).pipe(
        map(() => {
          return new ADMIN.RemoveRoutingEntrySuccess();
        }),
        catchError(() => {
          return of(UI.userError({message: Messages.READING_DATA_ERR}));
        })
      );
    })
  ));

  reloadProxyRouting$ = createEffect(() => this.actions.pipe(
    ofType(ADMIN.RELOAD_PROXY_ROUTING_REQUEST),
    switchMap(() => {
      return this.adminSrv.reloadProxyRouting()
        .pipe(
          map(() => new ADMIN.ReloadProxyRoutingSuccess()),
          catchError(() => {
            return of(UI.userError({message: Messages.GENERIC_FAILURE}));
          })
        );
    })
  ));

  loadPrivileges$ = createEffect(() => this.actions.pipe(
    ofType(ADMIN.LOAD_PRIVILEGES_REQUEST),
    debounceTime(500),
    mergeMap(() => {
      return this.adminSrv.getPrivileges()
        .pipe(
          map(privs => new ADMIN.LoadPrivilegesSuccess(privs)),
          catchError(() => {
            return of(UI.userError({message: Messages.READING_DATA_ERR}));
          })
        );
    })
  ));

  updatePrivilege$ = createEffect(() => this.actions.pipe(
    ofType<ADMIN.UpdatePrivilegeRequest>(ADMIN.UPDATE_PRIVILEGE_REQUEST),
    mergeMap(action => {
      return this.adminSrv.updPrivilege(action.payload)
        .pipe(
          map(() => new ADMIN.UpdatePrivilegeSuccess(action.payload)),
          catchError(() => {
            return of(UI.userError({message: Messages.SAVING_DATA_ERR}));
          })
        );
    })
  ));

  constructor(
    private actions: Actions,
    private adminSrv: AdministrationService) {
  }
}
