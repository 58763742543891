<ng-template #noTransport>
  <span><i>No transport for given alert.</i></span>
</ng-template>
<div class="details-frame" fxLayout="column">
  <div class="details-frame-first-row" fxLayout="row" fxLayoutAlign="end center" fxFlex>
    <span class="mat-subheading-1" *ngIf="alert.idPrzejazd; else noTransport">
      <button mat-raised-button (click)="showTransportData(alert.idPrzejazd)">Show transport details</button>
    </span>
  </div>

  <ng-container *ngIf="!lastLocation">
    <div><i>No location info</i></div>
  </ng-container>

  <ng-container *ngIf="lastLocation">
    <div fxLayout="row" fxLayoutAlign="space-between" fxFlex>
      <span class="mat-subheading-1">Date</span>
      <span class="mat-subheading-1">{{ lastLocation.czas | date : df.PIPE_DATETIME_LONGER_LABEL}}</span>
    </div>
    <div class="details-frame-second-row" fxLayout="row" fxLayoutAlign="space-between" fxFlex>
      <span class="mat-subheading-1">Speed</span>
      <span class="mat-subheading-1">{{ lastLocation.predkosc + ' km/h'}}</span>
    </div>
    <div class="details-frame-third-row" fxLayout="row" fxLayoutAlign="space-between" fxFlex>
      <span class="mat-subheading-1">Position</span>
      <span *ngIf="lastLocation.szerokosc !== null && lastLocation.dlugosc !== null" class="mat-subheading-1">
        {{ lastLocation.szerokosc | latitude }} {{ lastLocation.dlugosc | longitude }}
      </span>
    </div>
    <div class="details-frame-fourth-row" fxLayout="row" fxLayoutAlign="space-between" fxFlex>
      <span class="mat-subheading-1">Azimuth</span>
      <span *ngIf="lastLocation.azymut !== null" class="mat-subheading-1">
        {{ lastLocation.azymut }}<span>&deg;</span>
      </span>
    </div>
    <div class="details-frame-fifth-row" fxLayout="row" fxLayoutAlign="space-between" fxFlex>
      <span class="mat-subheading-1">Altitude</span>
      <span *ngIf="lastLocation.wysokosc !== null" class="mat-subheading-1">
        {{ lastLocation.wysokosc + ' m' }}
      </span>
    </div>
    <div class="details-frame-sixth-row" fxLayout="row" fxLayoutAlign="space-between" fxFlex>
      <span class="mat-subheading-1">Sat. used/vis.</span>
      <span *ngIf="lastLocation.liczba_sat_wid !== null" class="mat-subheading-1">
        {{ lastLocation.liczba_sat_uzy + ' pcs.' }} / {{ lastLocation.liczba_sat_wid + ' pcs.' }}
      </span>
    </div>
    <div class="details-frame-eighth-row" fxLayout="row" fxLayoutAlign="space-between" fxFlex>
      <span class="mat-subheading-1">Record no</span>
      <span *ngIf="lastLocation.nrRekordu !== null" class="mat-subheading-1">
        {{ lastLocation.nrRekordu }}
      </span>
    </div>
    <div class="details-frame-nineth-row" fxLayout="row" fxLayoutAlign="space-between" fxFlex>
      <span class="mat-subheading-1">GSM signal strength</span>
      <span *ngIf="lastLocation.rssi_wart !== null" class="mat-subheading-1">
        {{ lastLocation.rssi_wart + ' dbm' }}
      </span>
    </div>
  </ng-container>
</div>
