<div fxLayout="row" fxLayoutAlign="space-between center">
  <h4>Ticket details</h4>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
    <span>Status:</span>
    <mat-chip-list>
      <mat-chip [color]="ticket.statusT.id === eTicketStatus.FINISHED ? 'primary' : 'warn'" [selected]="true">{{ticket.statusT.status}}</mat-chip>
    </mat-chip-list>
  </div>
</div>
<div fxLayout="column" fxLayoutGap="32px">
  <div class="ticket-box" fxLayout="column" fxLayoutGap="12px">
    <app-text-field label="Id" [value]="ticket.id"></app-text-field>
    <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="space-between center">
      <app-text-field label="Created by" value="{{ticket.tworca.imie}} {{ticket.tworca.nazwisko}}"></app-text-field>
      <app-text-field label="Created at" [value]="ticket.dataUtw | date : df.PIPE_DATETIME_LABEL"></app-text-field>
    </div>
    <div fxLayout="column" fxLayoutGap="16px" fxLayoutAlign=" start">
<!--      todo: zmienic wartosci na jednego stringa-->
      <app-text-field *ngFor="let driver of drivers; let i = index" [label]="'Driver ' + (i+1)"
                      [value]="driver.uzytkownik.imie + ' ' + driver.uzytkownik.nazwisko + ', ' + driver.uzytkownik.tel1 + ', password: ' + (driver.potwierdz || 'not set')"
      ></app-text-field>
    </div>
    <div fxLayout="row" fxLayoutGap="32px" fxLayoutAlign="space-between center">
      <app-text-field [matTooltip]="ticket.samochod?.uwagi" layout="column"
                     label="Truck" [value]="ticket.samochod?.nrRej"></app-text-field>
      <app-text-field [matTooltip]="ticket.naczepa?.uwagi" layout="column"
                     label="Trailer" [value]="ticket.naczepa?.nrRej"></app-text-field>
      <app-text-field [matTooltip]="ticket.terminal?.uwagi" layout="column"
                     label="Terminal" [value]="ticket.terminal?.id"></app-text-field>
    </div>
    <br>
    <app-text-field
      label="Attendant" prefixIcon="person"
      value="{{ticket.obslugujacy.imie}} {{ticket.obslugujacy.nazwisko}}"></app-text-field>
    <div *ngIf="ticketAttendantControl.enabled" fxLayout="column" fxLayoutGap="8px">
      <app-autocomplete label="New attendant" [lov]="companyUsersLov"
                        [control]="ticketAttendantControl"></app-autocomplete>
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="end center">
        <button mat-raised-button (click)="reassignTicket(false)">Cancel</button>
        <button mat-raised-button color="primary" (click)="reassignTicket(true)">Save</button>
      </div>
    </div>
    <app-input label="Title" [control]="form.nazwa" *ngIf="ticketAttendantControl.disabled"></app-input>
    <app-textarea label="Notes" [control]="form.uwagi" *ngIf="ticketAttendantControl.disabled"></app-textarea>
    <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="ticketAttendantControl.disabled">
      <div>
        <button mat-raised-button *ngIf="form.disabled" (click)="reassignTicket()">Assign ticket to someone else</button>
      </div>
      <div fxLayout="row" fxLayoutGap="16px">
        <button mat-raised-button *ngIf="form.enabled" color="accent" (click)="cancel()">Cancel</button>
        <button mat-raised-button color="primary" (click)="saveOrEdit()" [disabled]="form.enabled && form.invalid">
          {{form.disabled ? 'Edit' : 'Save'}}
        </button>
      </div>
    </div>
  </div>
  <div fxLayout="column" fxLayoutGap="16px" *ngIf="alertTerminationReasonControl.disabled && ticketAttendantControl.disabled">
    <button mat-raised-button
            [disabled]="pendingAlerts.length < 1"
            (click)="startTerminatingAllAlerts()">Terminate all alerts in this ticket</button>
    <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="space-between center">
      <button mat-raised-button [disabled]="pendingAlerts.length < 1"
              (click)="startSelectingAlerts()">Select alerts to terminate</button>
      <button mat-raised-button [disabled]="selectedAlerts.length < 1">Terminate selected alerts</button>
    </div>
    <br />
    <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="space-between center">
      <button mat-raised-button
              [disabled]="pendingAlerts.length > 0 || ticket.statusT.id === eTicketStatus.FINISHED"
              color="accent" (click)="closeTicket()">Close ticket</button>
      <button mat-raised-button (click)="showHistory = !showHistory">History</button>
    </div>
  </div>
  <div fxLayout="column" fxLayoutGap="16px" *ngIf="alertTerminationReasonControl.enabled">
    <app-select placeholder="Choose termination reason..." label="Termination reason" id="action"
                [control]="alertTerminationReasonControl" [lov]="alertTerminationReasonLov"></app-select>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
      <button mat-stroked-button (click)="cancelCloseAlerts()">Cancel</button>
      <button mat-raised-button color="accent"
              (click)="closeAlerts()"
              [disabled]="!canCloseAlerts()">Close Alerts</button>
    </div>
  </div>
  <app-transport-tickets [ticket]="ticket" *ngIf="showHistory"></app-transport-tickets>
</div>
<ng-template #notDefined>
  <span class="not-defined">Not defined</span>
</ng-template>
