import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import * as fromMap from '../ngrx/map.reducer';
import { TerminalLocationSat, TerminalLocationShort } from '../models/helpers';
import { DraggedPoint, Filtry, GenericPoint, MapCenter, MapPoint, TermianlSearchTracksParams } from '../models/map';
import { ReverseGeocodeResponse } from '../models/map/reverseGeocode';
import { Coords } from '../modules/shared/interfaces';
import { Terminal } from '../models/dto/terminale';

interface Selectors {
  getLastTerminalPositions: MemoizedSelector<any, TerminalLocationShort[]>;
  getReversGeocode: MemoizedSelector<any, ReverseGeocodeResponse>;
  getMovedPoint: MemoizedSelector<any, DraggedPoint>;
  getRemovedPoint: MemoizedSelector<any, MapPoint>;
  getAdhocPoints: MemoizedSelector<any, GenericPoint[]>;
  getAdhocLines: MemoizedSelector<any, Coords[][]>;
  getMapCenter: MemoizedSelector<any, MapCenter>;
  getTerminalTrackPoints: MemoizedSelector<any, {
    points: TerminalLocationShort[][],
    satPoints: TerminalLocationSat[][], search: TermianlSearchTracksParams
  }>;
  getFilters: MemoizedSelector<any, Filtry>;
  getSearchingRouteResults: MemoizedSelector<any, any[]>;
  getViewerExtraData: MemoizedSelector<any, any>;
  getMarkedTerminals: MemoizedSelector<any, Terminal[]>;
}

export class MapSelectors {
  static _state = createFeatureSelector<fromMap.State>('map');

  static _selectors: Selectors;

  static getSelectors() {
    this._selectors = {
      getLastTerminalPositions: createSelector(this._state, fromMap.getLastLocations),
      getReversGeocode: createSelector(this._state, fromMap.getReverseGeocode),
      getMovedPoint: createSelector(this._state, fromMap.getMovedPoint),
      getRemovedPoint: createSelector(this._state, fromMap.getRemovedPoint),
      getAdhocPoints: createSelector(this._state, fromMap.getAdhocPoints),
      getAdhocLines: createSelector(this._state, fromMap.getAdhocLines),
      getMapCenter: createSelector(this._state, fromMap.getMapCenter),
      getTerminalTrackPoints: createSelector(this._state, fromMap.getSelectedTerminalPath),
      getFilters: createSelector(this._state, fromMap.getFilters),
      getSearchingRouteResults: createSelector(this._state, fromMap.getSearchingRouteResults),
      getViewerExtraData: createSelector(this._state, fromMap.getViewerExtraData),
      getMarkedTerminals: createSelector(this._state, fromMap.getSelectedTerminalList),
    };

    return this._selectors;
  }
}
