import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { IRodzajAlarmu } from 'src/app/modules/alerts/interfaces/rodzaj-alarmu';
import * as EVENTS_CONFIG from '../../../ngrx/event-config.actions';
import * as fromRoot from '../../../../../app.reducer';
import { RightDrawerService } from '../../../../shared/services';
import { Strings } from '../../../../../helpers';

@Component({
  selector: 'app-alert-type-form',
  templateUrl: './alert-type-form.component.html',
  styleUrls: ['./alert-type-form.component.scss']
})

export class AlertTypeFormComponent implements OnInit, OnDestroy {
  static readonly myname = Strings.getObjectHash('AlertTypeFormComponent');

  form: FormGroup;
  alertType: IRodzajAlarmu;
  buttonName = 'Add';
  formHeading = 'Add new alert type';
  editedAlertType: IRodzajAlarmu = null;

  currentFormValue$: Observable<any>;
  subs = new Subscription();

  constructor(
    private store: Store<fromRoot.State>,
    private rightDrawer: RightDrawerService) {
    this.form = new FormGroup({
      'type': new FormControl(null, Validators.required),
      'description': new FormControl(null, Validators.required),
      'riskAssessment': new FormControl(null, [Validators.required, Validators.pattern(/^\d$/)])
    });
  }

  ngOnInit(): void {
    this.subs.add(
      this.store.select(fromRoot.selectors.ui.getRightDrawerInfo)
        .pipe(
          filter(data => (data.componentName !== undefined && data.componentName === AlertTypeFormComponent.myname)
            || data.isOpened === false && this.editedAlertType !== null),
          map(data => data.componentData as IRodzajAlarmu)
        )
        .subscribe(alertType => {
          this.init();
          if (alertType) {
            this.editedAlertType = alertType;
            this.buttonName = 'Update';
            this.formHeading = 'Edit alert type';
            this.form.patchValue({
              'type': this.editedAlertType.rodzaj,
              'description': this.editedAlertType.opis,
              'riskAssessment': this.editedAlertType?.ocenaR,
            });
          }
        })
    );

    if (!!this.form) {
      this.currentFormValue$ = this.form.valueChanges;
      this.subs.add(this.currentFormValue$.subscribe(_ => {
      }));
    }
  }

  clearForm() {
    this.form.reset();
  }

  onSubmit() {
    this.alertType = {
      id: null,
      ocenaR: this.form.value['riskAssessment'],
      opis: this.form.value['description'],
      rodzaj: this.form.value['type']
    };
    if (!!this.editedAlertType) {
      this.alertType.id = this.editedAlertType.id;
      this.store.dispatch(new EVENTS_CONFIG.UpdateAlertTypeRequest(this.alertType));
    } else {
      this.store.dispatch(new EVENTS_CONFIG.AddAlertTypeRequest(this.alertType));
    }
    this.clearForm();
    this.rightDrawer.close();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private init() {
    this.buttonName = 'Add';
    this.formHeading = 'Add new alert type';
    this.editedAlertType = null;

    this.form.patchValue({
      'type': null,
      'description': null,
      'riskAssessment': null,
    });
  }
}
