import { environment } from '../../environments/environment';

export class EnvHelper {
  static getApiUrl(): string {
    return environment.proxyAddr.length ? `https://${environment.proxyAddr}` : '';
  }

  static getDomainJwtBlacklist(): Array<string> {
    return [
      "hereapi.com"
    ]
  }

  // We have to precisely return websocket url along with its protocol (https)
  static getWebsocketUrl(): string {
    if (environment.wsAddr.includes('https')) {
      return environment.wsAddr;
    }

    const { hostname, port } = window.location;
    return `https://${hostname}${port ? `:${port}` : ``}${environment.wsAddr}`;
  }
}
