import { FormControl, Validators } from '@angular/forms';
import { DefaultForm } from './default-form';
import { PunktSpedycyjny } from '../dto/transportSets';
import { Address, ReverseGeocodeResponse } from '../map/reverseGeocode';
import { AddressData, LocationData } from '../map/geocode';

export class ShippingPointForm extends DefaultForm<PunktSpedycyjny> {
  private initValues: PunktSpedycyjny = {
    id: null,
    nazwa: '',
    nazwa_kod: '',
    ulica: '',
    nr_d: '',
    nr_l: '',
    miasto: '',
    panstwo: '',
    promien_bazy: 0,
    kod: '',
    gps_ns: 0,
    gps_ew: 0,
    gps_h: 0,
    opis: '',
    firmowy: true,
  };
  constructor(shippingPoint?: PunktSpedycyjny | null) {
    super({
      id: new FormControl(null),
      id_firma: new FormControl(null, Validators.required),
      nazwa: new FormControl('', Validators.required),
      nazwa_kod: new FormControl('', [Validators.required, Validators.maxLength(10)]),
      ulica: new FormControl('', Validators.required),
      nr_d: new FormControl('', Validators.required),
      nr_l: new FormControl(''),
      miasto: new FormControl('', Validators.required),
      panstwo: new FormControl('', Validators.required),
      promien_bazy: new FormControl(0, Validators.required),
      kod: new FormControl('', Validators.required),
      gps_ns: new FormControl(0, Validators.required),
      gps_ew: new FormControl(0, Validators.required),
      gps_h: new FormControl(0, Validators.required),
      opis: new FormControl(''),
      firmowy: new FormControl(true, Validators.required)
    });

    if (shippingPoint) {
      this.patchValue(shippingPoint, { emitEvent: false, onlySelf: true });
    }
  }

  patchFromReverseGeocodeResponse(response: ReverseGeocodeResponse): void {
    const location = response.location;
    const address: Partial<Address> = location.address;

    this.patchValue({
      miasto: address.city ?? '',
      panstwo: address.country ?? '',
      ulica: address.street ?? '',
      kod: address.postalCode ?? '',
      nr_d: address.houseNumber ?? '',
      gps_ns: location.displayPosition.latitude ?? 0,
      gps_ew: location.displayPosition.longitude ?? 0
    }, { emitEvent: false, onlySelf: true });
  }

  patchFromGeocodeResponse(response: LocationData): void {
    const location = response.DisplayPosition;
    const address: Partial<AddressData> = response.Address;
    this.patchValue({
      miasto: address.City ?? '',
      panstwo: address.Country ?? '',
      ulica: address.Street ?? '',
      kod: address.PostalCode ?? '',
      nr_d: address.HouseNumber ?? '',
      gps_ns: location.Latitude ?? 0,
      gps_ew: location.Longitude ?? 0
    }, { emitEvent: false, onlySelf: true });
  }

  reset(value?: any, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.reset(value, options);
    this.patchValue(this.initValues);
  }

  getUpdatedModel(): PunktSpedycyjny {
    return {...this.model, ...this.value};
  }
}
