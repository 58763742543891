import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CustomMaterialModule } from 'src/app/modules/material.modules';

import {
  PasswordChangeComponent,
  UserCreateComponent,
  UserDetailsComponent,
  UserListComponent,
  UserLogHistoryComponent,
  UserMngComponent
} from './index';
import { UserPrivilegesComponent } from './user-privileges/user-privileges.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { SharedModule } from '../shared/shared.module';
import { MenuComponent } from './menu/menu.component';

@NgModule({
  imports: [
    CommonModule, CustomMaterialModule, FormsModule, RouterModule, SharedModule
  ],
  declarations: [
    UserMngComponent, PasswordChangeComponent, UserCreateComponent, UserDetailsComponent,
    UserListComponent, UserLogHistoryComponent, UserPrivilegesComponent, UserEditComponent, MenuComponent,
  ],
  exports: [
    UserMngComponent, PasswordChangeComponent, UserCreateComponent, UserDetailsComponent,
    UserListComponent, UserLogHistoryComponent
  ]
})
export class UsersModule {
}
