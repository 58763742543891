import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import * as fromRoot from '../app.reducer';
import { AuthenticationService, MyToastService, UserManagementService } from '../services';
import * as Auth from './auth.actions';
import { PrivilegeHelper } from '../modules/access-control/helpers';
import { RightDrawerService } from '../modules/shared/services';
import { WebsocketService } from '../modules/shared/services/websocket.service';
import { Privs, UserRoles } from '../helpers/enum';

@Injectable()
export class AuthEffects {
  browserId = null;

  authenticationSuccess$ = createEffect(() => this.actions.pipe(
    ofType(Auth.AUTHENTICATION_SUCCESS),
    tap(() => {
      this.myMsg.show('Zostałeś zalogowany do aplikacji');

      this.router.navigate(['/home']);
    })
  ), {dispatch: false});

  logoutEvent$ = createEffect(() => this.actions.pipe(
    ofType<Auth.LogoutRequest>(Auth.LOGOUT_REQUEST),
    switchMap((action) => {
      this.rightDrawer.close();

      if(this.websocket.connected) {
        this.websocket.unsubscribeFromChannel();
        this.websocket.disconnect();
      }

      localStorage.removeItem('fdwud');
      this.router.navigate(['/access/login'])
        .then(() => {
          let msg = 'You have been log out from the app';
          if (action.payload) {
            msg = action.payload;
          }
          this.myMsg.show(msg);
        });

        return of(new Auth.LogoutSuccess())
    })
  ));

  loginSuccessRestored$ = createEffect(() => this.actions.pipe(
    ofType<Auth.LoginSuccessRestored>(Auth.LOGIN_SUCCESS_RESTORED),
    switchMap((info) => {
      setTimeout(
        () =>
          this.userService
            .getWorkerInfo(info.payload.user.login)
            .pipe(map((res) => res[0]))
            .subscribe((userInfo) =>
              this.store.dispatch(new Auth.LoginUserData(userInfo))
            ),
        1000
      );

      return of(
        new Auth.LoginSuccess({
          token: info.payload.token,
          login: info.payload.user.login,
        })
      );
    })
  ));

  loginSuccess$ = createEffect(() => this.actions.pipe(
    ofType<Auth.LoginSuccess>(Auth.LOGIN_SUCCESS),
    withLatestFrom(this.store.select(state => state.auth.userTokenDetails)),
    tap(([action, tokenDetails]) => {
      if (this.authSrv.isBrowserValid()) {
        this.authSrv.loadPrivilegesMap()
          .subscribe(privileges => {
            PrivilegeHelper.parsePrivileges(privileges);
            this.userService
              .getWorkerInfo(action.payload.login)
              .pipe(map((res) => res[0]))
              .subscribe(async (userInfo) => {
                this.store.dispatch(new Auth.LoginUserData(userInfo));

                const shouldSubscribeToChannel = [
                  Privs.MNG_ALARMS_EVENTS_TRANSIT,
                  Privs.ADM_TRANSITS
                ].some(priv => tokenDetails.perm.includes(priv));

                try {
                  const connected = await this.websocket.connect();
                  if (connected && shouldSubscribeToChannel) {
                    const isCM = userInfo._rola.id === UserRoles.CM;
                    this.websocket.subscribeTo(isCM ? 'cm' : 'co', userInfo._firma.id);
                  }
                } catch (error) {
                  console.error(error);
                }


                if (window.location.pathname === '/access/login') {
                  this.router.navigate(['/home']);
                }
              });
          });

      }
    })
  ), {dispatch: false});

  loginFailure$ = createEffect(() => this.actions.pipe(
    ofType<Auth.LoginFailure>(Auth.LOGIN_FAILURE),
    tap((_) => {
      this.myMsg.error('Invalid login or password');
    })
  ), {dispatch: false});

  pinSuccess$ = createEffect(() => this.actions.pipe(
    ofType<Auth.PinSuccess>(Auth.PIN_SUCCESS),
    withLatestFrom(this.store),
    tap(([, storeState]) => {
      if (this.authSrv.isBrowserValid()) {
        this.userService
          .getWorkerInfo(storeState.auth.appUserInfo.login)
          .pipe(map((res) => res[0]))
          .subscribe((userInfo) => {
            this.store.dispatch(new Auth.LoginUserData(userInfo));
            if (window.location.pathname === '/access/login') {
              this.router.navigate(['/home']);
            }
          });
      }
    })
  ), {dispatch: false});

  refreshRequest$ = createEffect(() => this.actions.pipe(
    ofType(Auth.REFRESH_TOKEN_REQUEST),
    switchMap(() => {
      return this.authSrv.refreshJwtToken().pipe(
        map((res) => {
          const alertDialog = this.dialog.openDialogs.find(
            (d) => d.id === 'BaseYesNoDialogComponent-tokenExpirationAlert'
          );
          if (alertDialog) {
            alertDialog.close();
          }
          return new Auth.RefreshTokenSuccess(res);
        })
      );
    })
  ));

  loginRequest$ = createEffect(() => this.actions.pipe(
    ofType(Auth.LOGIN_REQUEST),
    map((action: Auth.LoginRequest) => action.payload),
    switchMap((info) => {
      return this.authSrv
        .authenticate(info.login, info.password, this.browserId)
        .pipe(
          map(
            (res) =>
              new Auth.LoginSuccess({login: info.login, token: res['token']})
          ),
          catchError((_) => {
            return of(new Auth.LoginFailure());
          })
        );
    }),
  ));

  pinVeryfication$ = createEffect(() => this.actions.pipe(
    ofType(Auth.PIN_REQUEST),
    map((action: Auth.PinRequest) => action.payload),
    switchMap((pin) => {
      if (pin === '1987') {
        return of(new Auth.PinSuccess());
      } else {
        return of(new Auth.PinFailure());
      }
    })
  ));

  companyChangeRequest$ = createEffect(() => this.actions.pipe(
    ofType(Auth.CHANGE_USER_COMPANY_REQ),
    map((action: Auth.ChangeUserComapnyReq) => action.payload),
    switchMap((newCompanyId) => {
      return of(new Auth.ChangeUserComapnySuccess(newCompanyId));
    })
  ));

  constructor(
    private actions: Actions,
    private authSrv: AuthenticationService,
    private store: Store<fromRoot.State>,
    private router: Router,
    private myMsg: MyToastService,
    private userService: UserManagementService,
    private dialog: MatDialog,
    private rightDrawer: RightDrawerService,
    private websocket: WebsocketService
  ) {
    this.store
      .select(fromRoot.selectors.auth.getBrowserId)
      .subscribe((browserId) => (this.browserId = browserId));
  }
}
