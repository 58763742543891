import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, delay, map, switchMap, tap, throttleTime } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { Store } from '@ngrx/store';

import * as MAP from './map.actions';
import * as UI from './ui.actions';
import * as fromRoot from '../app.reducer';

import { HereService } from '../modules/map/services/here.service';
import { LocationService } from '../modules/map/services/location.service';
import { Messages, ToastType } from '../helpers/enum';
import { TerminalLocationSat, TerminalLocationShort } from '../models/helpers';

@Injectable()
export class MapEffects {
  searchRouteForWayPoints$ = createEffect(() => this.actions.pipe(
      ofType(MAP.SearchRouteForWayPointsRequest),
      tap((action) => {
        this.store.dispatch(UI.showUserMessage({message: {
            type: ToastType.INFO, message: 'Searching for best route...'
          }}));

        this.hereSrv.findRoute(action.data);
      })
    ),
    {dispatch: false});

  getTerminalsTrackPoints$ = createEffect(() => this.actions.pipe(
    ofType(MAP.GetTerminalLocPointsRequest),
    tap(i => {
      this.store.dispatch(UI.showUserMessage({message: {
          type: ToastType.INFO, message: 'Downloading data for ' + i.searchParams.terminals.length + ' chosen devices'
        }}));
    }),
    delay(5),
    switchMap((action) => {
      return forkJoin(this.locationSrv.getCurrentTerminalTrackPoints(action.searchParams))
        .pipe(
          tap(() => {
            this.store.dispatch(UI.showUserMessage({message: {
                type: ToastType.INFO, message: 'Preparing the map...'
              }}));
          }),
          delay(50),
          map((pointsData: [TerminalLocationShort[], TerminalLocationSat[]][]) => {
            const points = [], satPoints = [];
            pointsData.forEach(terminalTracks => {
              points.push(terminalTracks[0]);
              satPoints.push(terminalTracks[1]);
            });

            return MAP.GetTerminalLocPointsSuccess({points, satPoints, searchParams: action.searchParams});
          }),
          catchError(() => {
            return of(UI.userError({message: Messages.READING_DATA_ERR}));
          })
        );
    })
  ));

  getLastTerminalPositions = createEffect(() => this.actions.pipe(
    ofType(MAP.LoadTerminalsLastPositionRequest),
    throttleTime(20 * 1000),
    switchMap(() => {
      return this.locationSrv.getLastTerminalPositions()
        .pipe(
          map(positions => MAP.LoadTerminalsLastPositionSuccess({terminalPoints: positions})),
          catchError(() => {
            return of(UI.userError({message: Messages.READING_DATA_ERR}));
          })
        );
    })
  ));

  constructor(private actions: Actions,
              private locationSrv: LocationService,
              private hereSrv: HereService,
              private store: Store<fromRoot.State>) {
  }
}
