import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import * as fromRoot from '../../../../app.reducer';
import { IAlarmTransportowy } from '../../interfaces';
import { Strings } from '../../../../helpers';

@Component({
  selector: 'app-alert-events-list',
  templateUrl: './alert-events-list.component.html',
  styleUrls: ['./alert-events-list.component.scss']
})
export class AlertEventsListComponent {
  static readonly myname = Strings.getObjectHash('AlertEventsListComponent');

  alert$: Observable<IAlarmTransportowy>;

  constructor(private store: Store<fromRoot.State>) {
    this.alert$ = store.select(fromRoot.selectors.ui.getRightDrawerInfo).pipe(
      filter(data => (data.componentName !== undefined && data.componentName === AlertEventsListComponent.myname)),
      map(data => data.componentData as IAlarmTransportowy)
    );
  }
}
