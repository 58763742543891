import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromRoot from '../../../../app.reducer';
import * as UI from '../../../../ngrx/ui.actions';
import * as FRAME from '../../../../ngrx/frame.actions';

import { GenericNavItem } from '../../../../models/dto';
import { Privs, UserRoles } from '../../../../helpers/enum';
import * as ALERT from '../../../alerts/ngrx/alert.actions';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnDestroy {
  positionTimer;

  constructor(private store: Store<fromRoot.State>) {
    const sideLinks: GenericNavItem[] = [];
    sideLinks.push({
      path: '/events/alerts', title: 'Alarms', itemType: 'link', icon: 'notification_important',
      security: {type: 'PRIVILEGES', value: [Privs.READ_ALARMS_EVENTS_TRANSIT, Privs.MNG_ALARMS_EVENTS_TRANSIT]}}
    );
    sideLinks.push({
      path: '/events/config', title: 'Configuration', itemType: 'link', icon: 'construction',
      security: {type: 'PRIVILEGES', value: [UserRoles.ADMIN]}}
    );
    sideLinks.push({
      path: '/events/tickets', title: 'Tickets', itemType: 'link', icon: 'label',
      security: {type: 'PRIVILEGES', value: [Privs.MNG_ALARMS_EVENTS_TRANSIT]}
    });
    sideLinks.push({
      path: '/events/driver-reports', title: 'Driver reports', itemType: 'link', icon: 'report',
      // todo: dodac uprawnienia
    });

    this.store.dispatch(UI.setSideLinks({links: sideLinks}));
    this.positionTimer = setInterval(() => {
      this.store.dispatch(FRAME.infoRequest());
    }, 5 * 1000);

    this.store.dispatch(new ALERT.LoadAlertStatusListRequest());
  }

  ngOnDestroy() {
    clearInterval(this.positionTimer);
  }

}
