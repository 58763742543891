<div class="mat-elevation-z8">
  <p-table #table [value]="alertsListInternal" [scrollable]="true" scrollHeight="calc(100vh - 275px)"
           [virtualRowHeight]="35"
           [virtualScroll]="true" (sortFunction)="customSort($event)" [customSort]="true"
           [rowTrackBy]="trackByFunction">
    <ng-template pTemplate="header">
      <tr>
        <th id="status" class="status">Status</th>
        <th id="alert-id" pSortableColumn="id" class="id">Id
          <p-sortIcon field="id"></p-sortIcon>
        </th>
        <th id="ticket-id" pSortableColumn="ticket.id" class="ticket">Ticket
          <p-sortIcon field="ticket.id"></p-sortIcon>
        </th>
        <th id="aktOcenaRyzyka" pSortableColumn="aktOcenaRyzyka" class="risk">Risk
          <p-sortIcon field="aktOcenaRyzyka"></p-sortIcon>
        </th>
        <th id="idZestaw" pSortableColumn="idZestaw" class="ticket">Set
          <p-sortIcon field="idZestaw"></p-sortIcon>
        </th>
        <th id="idPrzejazd" pSortableColumn="idPrzejazd" class="ticket">Transport ID
          <p-sortIcon field="idPrzejazd"></p-sortIcon>
        </th>
        <th id="name" class="name" pSortableColumn="name">Name
          <p-sortIcon field="name"></p-sortIcon>
        </th>
        <th id="company" pSortableColumn="firma.nazwa" class="company" *appHasRoleCm>Company
          <p-sortIcon field="firma.nazwa"></p-sortIcon>
        </th>
        <th id="cargo-owner" pSortableColumn="cargo-owner" class="cargo-owner">Cargo owner
          <p-sortIcon field="cargo-owner"></p-sortIcon>
        </th>
        <th id="description" pSortableColumn="rodzajAlarmu.rodzaj" class="description">Description
          <p-sortIcon field="rodzajAlarmu.rodzaj"></p-sortIcon>
        </th>
        <th id="czas" pSortableColumn="czas" class="date">Date
          <p-sortIcon field="czas"></p-sortIcon>
        </th>
<!--        nazwa kolumny zmieniona z Owner na Dispatcher-->
        <th id="owner" class="owner">Dispatcher</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-alert>
      <tr
        class="element-row"
        [ngClass]="{
          'background-pink-blink': !alertIsTerminated(alert) && !alertHasOwner(alert) && (alert | isNewAlert | async),
          'background-pink': !alertIsTerminated(alert) && !alertHasOwner(alert)
        }">
        <td [ngClass]="{
        'status': true,
        'white-bg': !alertIsTerminated(alert) && alertHasOwner(alert),
        'light-grey-bg': alertIsTerminated(alert)
        }" (click)="onAlertClick(alert)">
          <span>
            <mat-icon class="notifications-icon "
                      [ngClass]="{'red-icon': alertHasOwner(alert)}">notifications
            </mat-icon>
          </span>
        </td>
        <td class="id">{{alert.id}}</td>
        <td class="ticket">
          <button mat-icon-button *ngIf="alert.ticket?.id" [routerLink]="['/events/tickets', alert.ticket.id]">
            <mat-icon>label</mat-icon>
          </button>
        </td>
        <td class="risk">{{alert.aktOcenaRyzyka}}</td>
        <td class="ticket">{{alert.idZestaw}}</td>
        <td class="ticket">{{alert.idPrzejazd || 'No data'}}</td>
        <td class="name"
            [matTooltip]="(alert | alertGetType : 'name').length > 15 ? (alert | alertGetType : 'name') : ''">
          {{ alert | alertGetType : 'name' | truncate : 15 : '...' : true }}
        </td>
        <td class="company" *appHasRoleCm>
          {{alert.firma.nazwa}}
        </td>
        <td class="cargo-owner">
          <em style="color: #ffa600;" matTooltip="Work in progres...">TBD</em>
        </td>
        <td [matTooltip]="alert.rodzajAlarmu.rodzaj.length > 50 ? alert.rodzajAlarmu.rodzaj : ''">
          {{ alert.rodzajAlarmu.rodzaj | truncate : 50 : '...' : false }}
        </td>
        <td class="date" [matTooltip]="alert.czas | date : df.PIPE_DATETIME_LONGER_LABEL"
            [ngClass]="{'td-time': true,
                      'background-pink': !alertIsTerminated(alert) && !alertHasOwner(alert)
                    }">
          {{ alert.czas | date : df.PIPE_DATETIME_LABEL }}
        </td>
        <td class="owner">
          <span *ngIf="!alertIsTerminated(alert) && !alertHasOwner(alert); else alreadyHandled">
            <i>- - -</i>
          </span>
          <ng-template #alreadyHandled>
            <span *ngIf="alert.dyspozytor; else noOwner"
                  fxFlex fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
              <mat-icon>person_outline</mat-icon>
              <span class="dispatcher-name" [matTooltip]="alert.dyspozytor?.imie + ' ' + alert.dyspozytor?.nazwisko">
                {{ alert.dyspozytor?.imie | slice : 0 : 1 }}.{{ alert.dyspozytor?.nazwisko | slice : 0 : 1 }}.
              </span>
            </span>
            <ng-template #noOwner>
              <span>
                <span class="dispatcher-name-nobody">No owner</span>
              </span>
            </ng-template>
          </ng-template>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

