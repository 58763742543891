<div class="table-container mat-elevation-z8 white-bar">
  <table mat-table [dataSource]="userList" matSort class="mat-elevation-z8">

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>
    <ng-container matColumnDef="nazwa">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let element">
        {{element.imie}} {{element.nazwisko}}, <br />
        <i>{{element.kod}}, {{element.miasto}}</i>
      </td>
    </ng-container>
    <ng-container matColumnDef="firma">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Company</th>
      <td mat-cell *matCellDef="let element">
        {{ element._firma.nazwa }}
      </td>
    </ng-container>
    <ng-container matColumnDef="login">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Login</th>
      <td mat-cell *matCellDef="let element" >
        <span *ngIf="element.czy_zabl && !element.czy_usun" class="user-lock-info">(User blocked) </span>
        <span *ngIf="element.czy_usun" class="user-lock-info">(User removed) </span>
        <a [routerLink]="['../', element.username]">{{element.username}}</a>
      </td>
    </ng-container>
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
      <td mat-cell *matCellDef="let element">{{element.email}}</td>
    </ng-container>
    <ng-container matColumnDef="lBlHasel">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Number of incorr. logins </th>
      <td mat-cell *matCellDef="let element" title='Ilość błędnych prób logowania'>
        <a href='#' [routerLink]="['../logins/', element.id]">{{element.l_bl_hasel}}</a>&nbsp;
        <a href='#' [routerLink]="['../logins/', element.id]">Show</a>
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" title="Actions">
        <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="!element.czy_usun">
          <mat-icon class="more-actions-icon">more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="onEdit(element)">
            <mat-icon>edit</mat-icon>
            <span class="action-description">Edit user</span>
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row class="element-row" [class.user-locked]="row.czy_zabl"
        *matRowDef="let row; columns: displayedColumns;" [class.selected]="lastEditedUserId === row.id"></tr>
  </table>
</div>
