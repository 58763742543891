import moment from 'moment/moment';

const NO_TS_VAL = 999999999;

export class FrameTest {
  private frameRequestTimestamp: number = NO_TS_VAL;

  constructor(_frameRequestTimestamp: number) {
    this.frameRequestTimestamp = _frameRequestTimestamp;
  }

  isUpToDate(date: string): boolean {
    if (this.frameRequestTimestamp === NO_TS_VAL) {
      return false;
    }

    return moment(date).unix() > this.frameRequestTimestamp;
  }
}
