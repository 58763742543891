import { Pipe, PipeTransform } from '@angular/core';
import { NaczepaZestawNew, SamochodZestawNew } from '../../../models/dto/transportSets';
import { VehicleBasic } from '../../../models/dto/vehicle';
import { TransportHelper } from '../../../helpers';

@Pipe({
  name: 'vehicleFromTransit'
})
export class VehicleFromTransitPipe implements PipeTransform {

  static transform(value: (NaczepaZestawNew | SamochodZestawNew)[]): VehicleBasic {
    return new VehicleFromTransitPipe().transform(value);
  }

  transform(value: (NaczepaZestawNew | SamochodZestawNew)[]): VehicleBasic | undefined {
    return TransportHelper.getBasicVehicle(value);
  }

}
