import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, startWith, } from 'rxjs/operators';

import { ITransportEntity } from '../../../interfaces/transport-entity';
import * as fromRoot from '../../../../../app.reducer';
import { MonitParameterForm } from '../../../../../models/form/transit';
import { Strings } from '../../../../../helpers';
import * as TRANSPORT from '../../../ngrx/transport.actions';
import * as fromTransport from '../../../ngrx/transport.reducer';
import { Parameter, TransitParameter } from '../../../interfaces';

@Component({
  selector: 'app-transit-monitor-param-form',
  templateUrl: './transit-monitor-param-form.component.html',
  styleUrls: ['./transit-monitor-param-form.component.scss']
})
export class TransitMonitorParamFormComponent implements OnInit {
  @Input() transit: ITransportEntity;
  @Input() editParam: any;
  @Output() reloadParameters = new EventEmitter<string>();

  availableParameters: Parameter[];
  assignedParameters: TransitParameter[];
  form = new MonitParameterForm();

  filteredParams$: Observable<Parameter[]>;

  constructor(private store: Store<fromRoot.State>) {

  }

  ngOnInit(): void {
    combineLatest([
      this.store.select(fromTransport.getAvailableMonitParams),
      this.store.select(fromTransport.getAssignedMonitParams),
    ])
      .pipe(
        filter(r => r[0] !== null && r[1] !== null)
      )
      .subscribe(([avail, assigned]) => {
        this.availableParameters = [...avail].filter(p => !assigned.find(a => a.parametr.id === p.id));
        this.assignedParameters = assigned;
      });

    this.form.patchValue({idPrzejazd: this.transit.id});
    this.initForm();

    this.filteredParams$ = this.form.get('parametr').valueChanges.pipe(
      startWith(''),
      map(value => value === null ? '' : value),
      map(value => typeof value === 'string' ? value : value.parametr),
      map(value => this._filter(value))
    );
  }

  displayFn(param: any): string {
    return param && param.parametr ? param.parametr : '';
  }

  initForm(): void {
    this.form.reset();

    if (this.editParam) {
      this.form.patchValue({idPrzejazd: this.transit.id, ...this.editParam});
      return;
    }

    this.form.patchValue({
      id: null,
      parametr: null,
      idPrzejazd: this.transit.id,
      ryzykoBrakWart: 4,
      ryzykoZlaWart: 2,
      wartMax: 24,
      wartMin: 5,
    });

    this.form.markAsPristine();
    this.form.markAsUntouched();
  }

  assignParameter(): void {
    if (!(this.form.valid && this.form.touched)) {
      return;
    }

    this.store.dispatch(TRANSPORT.saveMonitorParamRequest({
      param: this.form.value,
      onSuccess: () => {
        this.initForm();
        this.reloadParameters.emit('parameter saved');
      }
    }));
  }

  private _filter(value: string): Parameter[] {
    if (value.length < 1) {
      return this.availableParameters;
    }
    return this.availableParameters
      .filter(param => Strings.searchTextIgnoreAccents(value, param.parametr) >= 0);
  }


}
