import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import * as fromTickets from './ngrx/tickets.reducer';

import { CustomMaterialModule } from '../material.modules';
import { TicketsEffects } from './ngrx/tickets.effects';
import { SharedModule } from '../shared/shared.module';
import { EntrypointComponent } from './entrypoint/entrypoint.component';
import {
  TicketAlertsComponent,
  TicketDataComponent,
  TicketTableListComponent,
  TicketTimelineComponent,
  TicketTimelineFormComponent,
  TicketViewFilterComponent,
  TransportTicketsComponent
} from './components';
import { TicketDetailsComponent, TicketListComponent } from './page';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgOrderByPipeModule } from 'angular-pipes';
import { SortTicketAlertsPipe } from './pipes';
import { VehiclesModule } from '../vehicles/vehicles.module';

@NgModule({
  declarations: [
    EntrypointComponent,
    TicketTableListComponent,
    TicketListComponent,
    TicketViewFilterComponent,
    TicketDetailsComponent,
    TicketDataComponent,
    TicketAlertsComponent,
    TicketTimelineComponent,
    TicketTimelineFormComponent,
    SortTicketAlertsPipe,
    TransportTicketsComponent,
  ],
    imports: [
        CommonModule,
        CustomMaterialModule,
        RouterModule, SharedModule,
        StoreModule.forFeature(fromTickets.featureName, fromTickets.reducer),
        EffectsModule.forFeature([TicketsEffects]), ScrollingModule, NgOrderByPipeModule, VehiclesModule
    ],
    exports: [
        EntrypointComponent,
        TicketTableListComponent, TicketListComponent
    ]
})

export class TicketsModule {
}
