<div class="tile-container {{getSelectedClass()}}" fxLayout="row" fxLayoutAlign="start start" *ngIf="transport">

  <div class="notifications-column" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="4px">
    <div class="notifications" fxLayout="row" fxLayoutAlign="center center">
      <mat-icon
        [class]="(transport?.stanPracy | getTransportStatusIcon : transport?.rozpoczety : transport?.zakonczony).sClass"
        [innerText]="(transport?.stanPracy | getTransportStatusIcon : transport?.rozpoczety : transport?.zakonczony).icon"
        [matTooltip]="(transport?.stanPracy | getTransportStatusIcon : transport?.rozpoczety : transport?.zakonczony).tooltip">
      </mat-icon>
    </div>
    <mat-icon [matTooltip]="(transport | canPresentTransportOnMap).tooltip"
              class="show-on-map material-icons-outlined pointer {{(transport | canPresentTransportOnMap).sClass}}"
              (click)="showOnTheMap()">map
    </mat-icon>
    <bolt-status-icon *ngIf="!transport?.zakonczony" [locker]="getLocker(transport)"></bolt-status-icon>
    <ng-container *appHasPrivileges="[ePrivs.ADM_TRANSITS]">
      <mat-icon class="pointer" (click)="editDetails(transport)" *ngIf="editable"
                [class.pending]="transport.kierowcaPrzejazdList.length === 0"
                [innerText]="transport.kierowcaPrzejazdList.length > 0 ? 'mode_edit' : 'edit_notifications'"></mat-icon>
    </ng-container>
  </div>

  <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="0.25rem">
    <p class="id" [matTooltip]="transport | getTransportDatesTooltip">
      Transport no.: {{transport.zestaw.id}}/{{transport.id}}
    </p>
    <p [matTooltip]="transport.uwagi" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="0.75rem">
      <span class="vehicle-name"
            [innerHTML]="(transport.zestaw?.truckSet | vehicleFromTransit) ? (transport.zestaw?.truckSet | vehicleFromTransit).nrRej : 'No truck'"
            [class.no-data]="!(transport.zestaw?.truckSet | vehicleFromTransit)"></span>
      <span class="vehicle-name"
            [innerHTML]="(transport.zestaw?.trailerSet | vehicleFromTransit) ? (transport.zestaw?.trailerSet | vehicleFromTransit).nrRej : 'No trailer'"
            [class.no-data]="!(transport.zestaw?.trailerSet | vehicleFromTransit)"></span>
    </p>
    <p>Carrier: {{ transport.zestaw.idFirma }}</p>
    <div fxFlex fxLayout="row" fxLayoutGap="8px">
      <span>Goods owner:</span>
      <span [class.no-data]="!transport.wlascicielTowaru">
        {{ transport.wlascicielTowaru?.nazwa || 'Not defined' }}
      </span>
    </div>
    <div fxFlex fxLayout="row" fxLayoutGap="8px">
      <span>Route:</span>
      <span>
        <span class="{{transportStartPoint('class')}}">{{ transportStartPoint('name') }}</span>
        -
        <span class="{{transportEndPoint('class')}}">{{ transportEndPoint('name') }}</span>
      </span>
    </div>
  </div>

</div>
