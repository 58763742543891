import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { filter, map, tap } from 'rxjs/operators';

import * as fromRoot from '../../../../app.reducer';
import * as fromAlert from '../../../alerts/ngrx/alert.reducer';
import * as ALERTS from '../../../alerts/ngrx/alert.actions';
import { IAlarmTransportowyUproszczony } from '../../../alerts/interfaces';
import { AlertService } from '../../../alerts/services';
import { DateFormat } from '../../../../helpers/enum';
import { Strings } from '../../../../helpers';

@Component({
  selector: 'app-transit-related-alerts',
  templateUrl: './transit-related-alerts.component.html',
  styleUrls: ['./transit-related-alerts.component.scss']
})
export class TransitRelatedAlertsComponent implements OnInit, OnDestroy {
  static readonly myname = Strings.getObjectHash('TransitRelatedAlertsComponent');

  transitRelatedAlertsHeading = 'Alarms and events';
  relatedAlerts: IAlarmTransportowyUproszczony[] = [];
  detailedAlertsList = [];
  subs = new Subscription();
  alertSubs = new Subscription();
  df = DateFormat;
  private currentTranssetId;

  constructor(
    private store: Store<fromRoot.State>,
    private alertSrv: AlertService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.subs.add(
      this.store.select(fromRoot.selectors.ui.getRightDrawerInfo)
        .pipe(
          filter(r => r.componentData !== undefined),
          filter(data => (data.componentName !== undefined && data.componentName === TransitRelatedAlertsComponent.myname)
            || data.isOpened === false || this.currentTranssetId !== data.componentData.transsetId),
          map(data => data.componentData as { transsetId: number })
        )
        .subscribe(data => {
          this.currentTranssetId = data.transsetId;
          this.alertSubs.unsubscribe();
          this.relatedAlerts = [];
          this.alertSubs.add(
            this.store.select(fromAlert.getTransitRelatedFlattenedTransportAlerts(data.transsetId))
              .subscribe(alarms => {
                this.relatedAlerts = alarms;
              })
          );
        })
    );
  }

  onAlertRefClick(alert: IAlarmTransportowyUproszczony) {
    this.store.dispatch(new ALERTS.LoadCurrentlyViewedAlertId(alert.id));

    this.subs.add(
      this.store.select(fromAlert.getDetailedTransportAlerts).pipe(
        tap(detailedAlerts => {
          this.detailedAlertsList = detailedAlerts;
          const alertWithDetailsIsAlreadyInStore: boolean = !!this.detailedAlertsList.find(a => a.id === alert.id);

          if (!alertWithDetailsIsAlreadyInStore) {
            this.store.dispatch(new ALERTS.LoadDetailedTransportAlertByIdRequest(alert.id));
          }

          if (!!alert.id) {
            this.router.navigate(['events', 'alerts', 'transport', alert.id.toString()]);
          }

          if (!!alert.terminal) {
            this.alertSrv.showAlertDetailInBreadcrumb$.next('terminal id ' + alert.terminal.id.toString());
          }
        })
      ).subscribe());
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.alertSubs.unsubscribe();
  }
}
