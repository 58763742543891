import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: 'tr[table-progress-row]',
  host: {
    class: 'table-progress-row',
  },
})
export class TableProgressRowDirective {
  @Input() isVisible: boolean;
  @HostBinding('class.d-none')
  public get isHidden(): boolean {
    return !this.isVisible;
  }
}
