import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { MatCheckboxChange } from '@angular/material/checkbox';

import * as fromRoot from '../../../../app.reducer';
import * as fromTicket from '../../ngrx/tickets.reducer';
import * as fromAlert from '../../../alerts/ngrx/alert.reducer';
import * as ALERT from '../../../alerts/ngrx/alert.actions';
import { checkTicketAlert } from '../../ngrx/tickets.actions';

import { Ticket } from '../../interfaces';
import { DateFormat } from '../../../../helpers/enum';
import { IAlarmPodstawowy } from '../../../../models/dto/alert/alarm-transportowy';
import { AlertEventsListComponent } from '../../../alerts/components';
import { RightDrawerService } from '../../../shared/services';
import { TransportAlert } from '../../../../helpers';

@Component({
  selector: 'app-ticket-alerts',
  templateUrl: './ticket-alerts.component.html',
  styleUrls: ['./ticket-alerts.component.scss']
})
export class TicketAlertsComponent implements OnDestroy, OnInit, OnChanges {

  df = DateFormat;
  subs = new Subscription();
  selectable$: Observable<boolean>;
  selectedAlerts$: Observable<number[]>;
  pending: IAlarmPodstawowy[] = [];

  @Input() ticket: Ticket;

  constructor(private store: Store<fromRoot.State>, private readonly rightDrawer: RightDrawerService) {
    this.selectable$ = store.select(fromTicket.selectTicketAlert);
    this.selectedAlerts$ = store.select(fromTicket.getTicketSelectedAlerts);
  }

  ngOnInit() {
    this.pending = [...this.ticket.alarmy].filter(a => TransportAlert.isAlertOpen(a));
  }

  ngOnChanges(changes: SimpleChanges) {
    this.ticket = changes.ticket.currentValue;
    this.pending = [...this.ticket.alarmy].filter(alert => TransportAlert.isAlertOpen(alert));
  }

  getTimeTooltip(alert: IAlarmPodstawowy): string {
    if (alert.czasZakonczenia) {
      return 'Finished at: ' + moment(alert.czasZakonczenia).format(DateFormat.PIPE_DATETIME_LABEL_MOMENT);
    }
    return '';
  }

  alertInfo(alertId: number): void {
    this.rightDrawer.openOver(AlertEventsListComponent);
    setTimeout(() => {
      this.store.dispatch(new ALERT.LoadDetailedTransportAlertByIdRequest(alertId));
      this.subs.add(
        this.store.select(fromAlert.getDetailedTransportAlertsById(alertId))
          .subscribe(alert => {
            this.rightDrawer.openOver(AlertEventsListComponent, alert);
          })
      );
    }, 500);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  checkAlert(alertId: number, evt: MatCheckboxChange): void {
    this.store.dispatch(checkTicketAlert({alertId, checked: evt.checked}));
  }
}
