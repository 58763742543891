import { Privs } from '../app/helpers/enum';

export const SideLinks = {
  inventory: [
    {title: 'Vehicles', path: './dictionaries/vehicles', privileges: [Privs.READ_VEHICLES, Privs.ADM_VEHICLES]},
    {title: 'Drivers', path: './dictionaries/driver', privileges: [Privs.READ_DRIVERS, Privs.ADM_DRIVER]},
    {title: 'Trans-sets', path: './dictionaries/transport-set', privileges: [Privs.READ_TRANSSETS]},
    {title: 'Shipping pts.', path: './dictionaries/shipping-points', privileges: [Privs.READ_SHIPPING_POINT]},
    {title: 'Parking lots', path: './dictionaries/parking', privileges: [Privs.READ_SHIPPING_POINT]},
    {title: 'Cargo Owners', path: './dictionaries/cargo-owner-list', privileges: [Privs.READ_CARGO_OWNER]},

    {title: 'Users', path: './dictionaries/users', privileges: []},
    {
      title: 'Devices', path: './dictionaries/devices',
      privileges: [Privs.READ_TERMINALS, Privs.EDIT_TERMINAL, Privs.MNG_TERMINAL_SOFT, Privs.MNG_TERMINAL_ASSESMENT]
    },
  ]
};
