import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import get from 'lodash/get';
import { debounceTime, take } from 'rxjs/operators';
import { Table } from 'primeng/table';

import * as ALERTS from '../../ngrx/alert.actions';
import * as fromRoot from '../../../../app.reducer';

import { IAlarmTransportowyUproszczony } from '../../interfaces';
import { AlertService } from '../../services';
import { DateFormat } from '../../../../helpers/enum';
import { TransportAlert } from '../../../../helpers';
import { AlertGetTypePipe } from '../../../shared/pipes';

@Component({
  selector: 'app-alert-table-list',
  templateUrl: './alert-table-list.component.html',
  styleUrls: ['./alert-table-list.component.scss']
})
export class AlertTableListComponent implements OnChanges {
  @Input() alertsList: IAlarmTransportowyUproszczony[];
  alertsListInternal: IAlarmTransportowyUproszczony[] = [];
  df = DateFormat;
  lastSortField: {
    field: string; order: number
  };
  @ViewChild('table') table: Table;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private alertSrv: AlertService,
    private store: Store<fromRoot.State>) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.alertsListInternal = this.sortData(this.alertsList.slice());
  }

  alertHasOwner(alert: IAlarmTransportowyUproszczony) {
    return !!alert.dyspozytor;
  }

  alertIsTerminated(alert: IAlarmTransportowyUproszczony) {
    return TransportAlert.isAlertClosed(alert);
  }

  compare(a: number | string | boolean, b: number | string | boolean, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  onAlertClick(flattenedAlert: IAlarmTransportowyUproszczony) {
    this.store.dispatch(new ALERTS.LoadCurrentlyViewedAlertId(flattenedAlert.id));

    if (!!flattenedAlert.id) {
      this.router.navigate(['events', 'alerts', 'transport', flattenedAlert.id.toString()]);
    }
  }

  customSort(event) {
    of(event)
      .pipe(
        debounceTime(500),
        take(1)
      )
      .subscribe(evt => {
        if (this.lastSortField && this.lastSortField.field === evt.field && this.lastSortField.order === evt.order) {
          return;
        }

        if (this.lastSortField && this.lastSortField.field === evt.field && this.lastSortField.order === -1) {
          this.lastSortField = undefined;
          this.resetSort();
          this.alertsListInternal = this.alertsList.slice();
          return;
        }
        this.lastSortField = {field: evt.field, order: evt.order};
        this.alertsListInternal = this.sortData(evt.data);
      });
  }

  trackByFunction = (index, item: IAlarmTransportowyUproszczony) => {
    return item.id + '-'
      + item.statusAlarmu.id + '-'
      + (item.ticket?.id || '') + '-'
      + item.dyspozytor?.id;
  }

  private sortData(data) {
    if (!this.lastSortField) {
      return data;
    }

    const typeNamePipe = new AlertGetTypePipe();
    return [...data.sort((data1, data2) => {
      let value1 = get(data1, this.lastSortField.field, null);
      let value2 = get(data2, this.lastSortField.field, null);
      switch (this.lastSortField.field) {
        case 'name':
        case 'type':
          value1 = typeNamePipe.transform(data1, this.lastSortField.field);
          value2 = typeNamePipe.transform(data2, this.lastSortField.field);
          break;
      }
      let result;

      if (value1 === null && value2 !== null) {
        result = -1;
      } else if (value1 !== null && value2 === null) {
        result = 1;
      } else if (value1 === null && value2 === null) {
        result = 0;
      } else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      } else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }

      return (this.lastSortField.order * result);
    })];
  }

  private resetSort() {
    this.table.sortOrder = 0;
    this.table.sortField = '';
    this.table.reset();
  }
}
