import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import * as fromAlert from '../../ngrx/alert.reducer';
import * as ALERT from '../../ngrx/alert.actions';
import * as fromRoot from '../../../../app.reducer';
import { showUserMessage } from '../../../../ngrx/ui.actions';

import * as userRoles from '../../../users/dictionary-user-role';
import { IAlarmTransportowy, ITworzenieTicketuDlaAlarmu } from '../../interfaces';
import { IDyspozytorDTO } from 'src/app/models/dto/alert/dyspozytor';
import { Terminal } from 'src/app/models/dto/terminale';
import { IZdarzeniePojazduDTO } from '../../../../models/dto/alert/zdarzenie-pojazdu';
import { RightDrawerService } from 'src/app/modules/shared/services';
import { AlertEventsListComponent } from '../alert-events-list/alert-events-list.component';
import { DateFormat, ToastType } from '../../../../helpers/enum';
import { TransportAlert } from '../../../../helpers';
import { IZgloszenieKierowcyDTO } from '../../../../models/dto/alert/zgloszenie-kierowcy';
import { TicketMsg } from '../../../../messages';

@Component({
  selector: 'app-alert-data',
  templateUrl: './alert-data.component.html',
  styleUrls: ['./alert-data.component.scss']
})
export class AlertDataComponent implements OnInit, OnDestroy {
  @Input() alertId: number;
  @Input() terminalId: number;
  df = DateFormat;
  ta = TransportAlert;

  alert: IAlarmTransportowy;
  terminal: Terminal;
  alertOwner: string | IDyspozytorDTO;

  ticketCommentControl = new FormControl('');

  userIsMCWorker: boolean;
  userInfo: any;

  subs = new Subscription();
  firstEvent: IZdarzeniePojazduDTO | IZgloszenieKierowcyDTO = null;

  constructor(private store: Store<fromRoot.State>, private readonly rightDrawer: RightDrawerService) {
  }

  ngOnInit(): void {
    this.ticketCommentControl.disable();
    this.subs.add(
      combineLatest([
        this.store.select(fromRoot.selectors.auth.getUserInfo),
        this.store.select(fromAlert.getDetailedTransportAlertsById(this.alertId)),
        this.store.select(fromRoot.selectors.devices.getTerminalById(this.terminalId)),
      ])
        .pipe(
          filter(f => f[0] !== undefined && f[0] !== null && f[0].userId !== undefined
            && f[1] !== undefined && f[1].id !== undefined
          )
        )
        .subscribe(([userInfo, alert, terminal]) => {
          this.userIsMCWorker = (userInfo.role?.id === userRoles.DictionaryUserRole.monitoringCenterWorkerId);
          this.userInfo = userInfo;
          this.alert = alert;
          if (alert) {
            this.firstEvent = [...alert?.zdarzeniaPojazdu, ...alert?.zgloszeniaKierowcy]
              .sort((a, b) => Date.parse(a.czas) - Date.parse(b.czas))
              .shift();
          }
          this.terminal = terminal;
        })
    );

    if (!!this.alert && !!this.alert.dyspozytor) {
      this.alertOwner = this.alert.dyspozytor.imie + ' ' + this.alert.dyspozytor.nazwisko;
    } else {
      this.alertOwner = 'No owner';
    }
  }

  createTicket(): void {
    this.store.dispatch(new ALERT.AssignTransportAlertHandlerRequest({alertId: this.alert.id}));
    this.ticketCommentControl.enable();
  }

  confirmTicketCreation(): void {
    const ticketCreationData: ITworzenieTicketuDlaAlarmu = {
      nazwa: this.ticketCommentControl.value,
      alarm: this.alert
    };
    this.store.dispatch(new ALERT.CreateTransportAlertTicketRequest({
      data: ticketCreationData,
      callback: () => this.store.dispatch(showUserMessage({
        message: {message: TicketMsg.CREATED, type: ToastType.SUCCESS}
      }))
    }));
    this.ticketCommentControl.disable();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.rightDrawer.close();
  }

  showEvents(alert: IAlarmTransportowy): void {
    this.rightDrawer.open(AlertEventsListComponent, alert);
  }
}
