import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { NgTruncatePipeModule } from 'angular-pipes';

import { BaseConfirmationDialogComponent, BaseYesNoDialogComponent, InputConfirmationDialogComponent } from './dialogs';
import { SlotAttachToDirective } from './directives/slot-attach-to.directive';
import { SlotDirective } from './directives/slot.directive';
import { MaterialElevationDirective } from './directives/material-elevation.directive';
import {
  AutocompleteComponent,
  FloatingActionMenuComponent,
  HomeComponent,
  MultiselectComponent,
  TextFieldComponent,
  TableProgressCellComponent,
} from './components';
import { AccessControlModule } from '../access-control/access-control.module';
import { TileItemComponent } from './components/tile-item/tile-item.component';
import {
  AlertGetTypePipe,
  GetLockerStatusPipe,
  GetObjectByIdPipe,
  GetObjectByPipe,
  GetTransitTooltipPipe,
  IsTransportAlertPendingPipe,
  OnlyActivePipe,
  SortAlertsUproszczonyPipe,
  TerminalLastTimeInfoPipe,
  ValidateGpsInfoPipe,
  VehicleFromTransitPipe
} from './pipes';
import { CustomMaterialModule } from '../material.modules';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { TableProgressRowDirective } from './directives/table-progress-row.directive';
import { TableDisabledRowDirective } from './directives/table-disabled-row.directive';
import { PaginationPageSizeDirective } from './directives/pagination-page-size.directive';
import { BatteryLevelComponent } from './components/battery-level/battery-level.component';
import { DateTimePickerComponent } from './components/date-time-picker/date-time-picker.component';
import { BoltStatusIconComponent } from './components/bolt-status-icon/bolt-status-icon.component';

@NgModule({
  declarations: [
    OnlyActivePipe,
    TerminalLastTimeInfoPipe,
    BaseConfirmationDialogComponent,
    InputConfirmationDialogComponent,
    BaseYesNoDialogComponent,
    MaterialElevationDirective,
    SlotAttachToDirective,
    SlotDirective,
    HomeComponent,
    ValidateGpsInfoPipe,
    AlertGetTypePipe,
    TileItemComponent,
    FloatingActionMenuComponent,
    VehicleFromTransitPipe,
    GetTransitTooltipPipe,
    GetLockerStatusPipe,
    DatePickerComponent,
    GetObjectByIdPipe,
    TextFieldComponent,
    IsTransportAlertPendingPipe,
    SortAlertsUproszczonyPipe,
    AutocompleteComponent,
    MultiselectComponent,
    GetObjectByPipe,
    PaginationPageSizeDirective,
    TableProgressCellComponent,
    TableProgressRowDirective,
    TableDisabledRowDirective,
    BatteryLevelComponent,
    DateTimePickerComponent,
    BoltStatusIconComponent,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    AccessControlModule,
    RouterModule,
    NgTruncatePipeModule,
    CustomMaterialModule,
  ],
  exports: [OnlyActivePipe, TerminalLastTimeInfoPipe, ValidateGpsInfoPipe,
    MaterialElevationDirective, SlotAttachToDirective, SlotDirective, HomeComponent,
    AccessControlModule, AlertGetTypePipe, TileItemComponent, NgTruncatePipeModule, FloatingActionMenuComponent,
    VehicleFromTransitPipe, GetTransitTooltipPipe, GetLockerStatusPipe, DatePickerComponent, GetObjectByIdPipe, TextFieldComponent,
    IsTransportAlertPendingPipe, SortAlertsUproszczonyPipe, AutocompleteComponent, MultiselectComponent, GetObjectByPipe,
    TableProgressCellComponent, TableProgressRowDirective, TableDisabledRowDirective, PaginationPageSizeDirective, BatteryLevelComponent,
    DateTimePickerComponent,
    BoltStatusIconComponent]
})
export class SharedModule {
}
