
<div *ngIf="readyTransportSetsPanelIsOpened$ | async" style="position: relative">
  <div class="mat-elevation-z8">
    <table mat-table
          [dataSource]="transportSetList$ | async"
          [trackBy]="trackByMethod"
          matSort
          multiTemplateDataRows
          class="mat-elevation-z8">
      <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns;">
        <th mat-header-cell *matHeaderCellDef [mat-sort-header]="column" class="heading">{{ column }}</th>
        <td mat-cell *matCellDef="let element">
          <div [ngSwitch]="column" class="rows-container">
            <span *ngSwitchCase="transportSetsColumns.Id" class="id-column">{{ element?.id }}</span>
            <div *ngSwitchCase="transportSetsColumns.Truck"
                 [matTooltip]="getVehicleInfo(element.truckSet | vehicleFromTransit, 'tooltip')">
              <div style="padding: 3px 0;" class="display-column">
                <terminal-veh-reg-number
                  [vehType]="vehicleType.BRAND"
                >
                  {{(element.truckSet | vehicleFromTransit)?.model?.marka?.marka || 'No data' }}
                </terminal-veh-reg-number>
                <terminal-veh-reg-number
                  [vehType]="vehicleType.PLATE"
                >
                  {{(element.truckSet | vehicleFromTransit)?.nrRej || 'No data' }}
                </terminal-veh-reg-number>
                <terminal-veh-reg-number
                  [vehType]="vehicleType.VEHICLE_ID"
                >
                  {{(element.truckSet | vehicleFromTransit)?.idPojazduFirmy || 'No data' }}
                </terminal-veh-reg-number>
              </div>
            </div>
            <div *ngSwitchCase="transportSetsColumns.Trailer"
                 [matTooltip]="getVehicleInfo(element.trailerSet | vehicleFromTransit, 'tooltip')"
                 class="display-column">
              <terminal-veh-reg-number
                [vehType]="vehicleType.BRAND"
              >
                {{(element.trailerSet | vehicleFromTransit)?.model?.marka?.marka || 'No data' }}
              </terminal-veh-reg-number>
              <terminal-veh-reg-number
                [vehType]="vehicleType.PLATE"
              >
                {{(element.trailerSet | vehicleFromTransit)?.nrRej || 'No data' }}
              </terminal-veh-reg-number>
              <terminal-veh-reg-number
                [vehType]="vehicleType.VEHICLE_ID"
              >
                {{(element.trailerSet | vehicleFromTransit)?.idPojazduFirmy || 'No data' }}
              </terminal-veh-reg-number>
            </div>
            <span *ngSwitchCase="transportSetsColumns.Remarks">
              <span>
                {{ element?.uwagi }}
              </span>
            </span>
            <span *ngSwitchCase="transportSetsColumns.Actions" class="action-column">
              <button mat-icon-button [matMenuTriggerFor]="menu" (click)="shadowTransportSetRow(element)">
                <mat-icon class="more-actions-icon">more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="showDetails(element)">
                  <mat-icon>info</mat-icon>
                  <span class="action-description">Show details</span>
                </button>
                <button mat-menu-item (click)="selectSet(element)">
                  <mat-icon>done</mat-icon>
                  <span class="action-description">Select set</span>
                </button>
              </mat-menu>
            </span>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row class="element-row" *matRowDef="let element; columns: displayedColumns;"
          [ngClass]="{ 'light-gray-background': (currentlyViewedTransportSet$ | async)?.id === element.id }">
      </tr>
    </table>
  </div>
</div>
