import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getObjectBy'
})
export class GetObjectByPipe implements PipeTransform {

  transform<T>(values: T[], fieldName: string, value: number | string | undefined): T | undefined {
    if (value === undefined) {
      return undefined;
    }
    return (values || []).find(item => {
      if (!item[fieldName]) {
        return false;
      }
      return item[fieldName] === value;
    });
  }

}
