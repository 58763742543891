import { Component, OnDestroy } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import * as fromRoot from '../../../../app.reducer';
import * as MAP from '../../../../ngrx/map.actions';
import * as UI from '../../../../ngrx/ui.actions';

import { HereService } from '../../services/here.service';
import { SearchRouteComponentData } from '../../../../models/map';
import { Strings } from '../../../../helpers';

@Component({
  selector: 'app-search-route',
  templateUrl: './search-route.component.html',
  styleUrls: ['./search-route.component.scss']
})
export class SearchRouteComponent implements OnDestroy {
  static readonly myname = Strings.getObjectHash('SearchRouteComponent');

  subs = new Subscription();
  inData: SearchRouteComponentData = null;

  constructor(private hereService: HereService, private store: Store<fromRoot.State>) {
    this.store.dispatch(UI.sideLinkAction({linkAction: {value: SearchRouteComponent.myname}}));
    this.subs.add(
      this.store.select(fromRoot.selectors.ui.getRightDrawerInfo)
        .pipe(
          filter(r => r.componentName
            && r.componentName === SearchRouteComponent.myname
            && r.componentData),
          map(r => r.componentData as SearchRouteComponentData)
        )
        .subscribe(r => {
          this.inData = r;
        })
    );
  }

  ngOnDestroy() {
    this.store.dispatch(MAP.SearchRouteForWayPointsSuccess({}));
    this.store.dispatch(MAP.ReverseGeocodeSuccess({}));

    this.subs.unsubscribe();
  }

}
