export enum TicketTableColumnsEnum {
  Status = 'Status',
  Id = 'Id',
  Company = 'Company',
  Set = 'Set',
  Plates = 'Plates',
  Title = 'Title',
  TotalAlerts = 'Total alerts',
  ActiveAlerts = 'Active alerts',
  StartDate = 'Start date',
  ClosingDate = 'Closing date',
  Attendant = 'Attendant'
}
