<ng-container
  *ngIf="{
    locations: locations$ | async,
    suggestionsLoading: areSuggestionsLoading$ | async,
    locationLoading: isLocationLoading$ | async
  } as geocoding"
>
  <div class="input-container">
    <input
      matInput
      [matAutocomplete]="auto"
      [ngModel]="_value"
      [attr.aria-labelledby]="_formField.getLabelId()"
      [disabled]="disabled"
      (focus)="focused = true"
      (blur)="focused = false"
      #searchInput
    />
    <mat-spinner
      *ngIf="geocoding.locationLoading"
      [diameter]="locationSpinnerDiameter"
      color="accent"
    ></mat-spinner>
  </div>

  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="searchBase.displayFn"
    (optionSelected)="locationPicked($event)"
  >
    <mat-option *ngIf="geocoding.suggestionsLoading" disabled>
      <div class="suggestions-loading">
        <mat-spinner [diameter]="loadingSpinnerDiameter"></mat-spinner>
      </div>
    </mat-option>
    <mat-option *ngFor="let location of geocoding.locations" [value]="location">
      <span [innerHTML]="location.label"></span>
      <small> | {{ location.countryCode }}</small>
    </mat-option>
  </mat-autocomplete>
</ng-container>
