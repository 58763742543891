import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { AccessComponent, LoginComponent, LogoutComponent, PasswordResetComponent, RegisterComponent } from './access';

const routes: Routes = [
  {
    path: 'access', component: AccessComponent, data: {breadcrumb: 'Sign in'}, children: [
      {path: '', redirectTo: 'login', pathMatch: 'full', data: {breadcrumb: null}},
      {path: 'login', component: LoginComponent, data: {breadcrumb: null}},
      {path: 'register', component: RegisterComponent, data: {breadcrumb: null}},
      {path: 'logout', component: LogoutComponent, data: {breadcrumb: null}},
      {path: 'password-reset', component: PasswordResetComponent, data: {breadcrumb: null}}
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRouting {
}
