import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent {
  @Input() label: string | null;
  @Input() placeholder: string | null;
  @Input() control: FormControl;
  @Input() readonly = false;
  @Input() name = 'fdw-generic-input';
  @Input() autocomplete = true;
  @Input() hint = '';
  @Input() suffixIcon = '';
  @Input() minRows = 2;
  @Input() floatLabel: 'always' | 'auto' | 'never' = 'auto';

  isRequired(): boolean {
    return this.control.errors !== null && this.control.errors.required;
  }
}

