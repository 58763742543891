import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { debounceTime, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import * as UI from '../../../../ngrx/ui.actions';
import * as fromRoot from '../../../../app.reducer';

import { ViewMode } from '../../../../helpers/enum';
import { setTicketFilter } from '../../ngrx/tickets.actions';
import { TicketFilterStatus } from '../../models/filters/ticket-status-filter';
import * as fromTickets from '../../ngrx/tickets.reducer';

@Component({
  selector: 'app-ticket-view-filter',
  templateUrl: './ticket-view-filter.component.html',
  styleUrls: ['./ticket-view-filter.component.scss']
})
export class TicketViewFilterComponent implements OnInit, OnDestroy {
  viewMode$: Observable<ViewMode>;
  searchControl = new FormControl('');
  statusFilterControl = new FormControl(TicketFilterStatus.NEW);
  subs = new Subscription();
  private DEBOUNCE_TIME_MS = 350;

  constructor(private store: Store<fromRoot.State>) {
    this.viewMode$ = store.select(fromRoot.selectors.ui.getTicketViewMode);

    this.subs.add(
      this.searchControl.valueChanges
        .pipe(debounceTime(this.DEBOUNCE_TIME_MS))
        .subscribe((text) => {
          this.store.dispatch(setTicketFilter({text: text, status: this.statusFilterControl.value}));
        })
    );

    this.subs.add(
      this.statusFilterControl.valueChanges
        .pipe(debounceTime(this.DEBOUNCE_TIME_MS))
        .subscribe((status) => {
          this.store.dispatch(setTicketFilter({text: this.searchControl.value, status: status}));
        })
    );
  }

  ngOnInit() {
   this.fillFilters();
  }

  private fillFilters(): void {
    this.store.select(fromTickets.getTicketFilter).pipe(take(1)).subscribe(filters => {
      this.statusFilterControl.setValue(filters.status);
      this.searchControl.setValue(filters.text);
    });
  }

  tableView() {
    this.store.dispatch(UI.setTicketViewMode({mode: ViewMode.TABLE}));
  }

  tileView() {
    this.store.dispatch(UI.setTicketViewMode({mode: ViewMode.TILE}));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
