import { Action, createReducer, on } from '@ngrx/store';

import * as frameActions from './frame.actions';
import { IAwar, ICn, ICs, IInfo, IRygiel, ISat, ISatJ, ISatS } from '../models/frame';

export interface State {
  info: IInfo[];
  sat: ISat[];
  satS: ISatS[];
  satJ: ISatJ[];
  awar: IAwar[];
  rygiel: IRygiel[];
  cn: ICn[];
  cs: ICs[];
}

export const initialState: State = {
  info: [],
  sat: [],
  satS: [],
  satJ: [],
  awar: [],
  rygiel: [],
  cn: [],
  cs: []
};

const frameReducer = createReducer(
  initialState,
  on(frameActions.infoSuccess, (state, {frames}) => ({...state, info: frames})),

  on(frameActions.satSuccess, (state, {frames}) => ({...state, sat: frames})),
  on(frameActions.satSSuccess, (state, {frames}) => ({...state, satS: frames})),
  on(frameActions.satJSuccess, (state, {frames}) => ({...state, satJ: frames})),

  on(frameActions.awarSuccess, (state, {frames}) => ({...state, awar: frames})),
  on(frameActions.rygielSuccess, (state, {frames}) => ({...state, rygiel: frames})),
  on(frameActions.cnSuccess, (state, {frames}) => ({...state, cn: frames})),
  on(frameActions.csSuccess, (state, {frames}) => ({...state, cs: frames}))
);

export function reducer(state: State | undefined, action: Action) {
  return frameReducer(state, action);
}

export const getInfo = (state: State) => state.info;
export const getSatJ = (state: State) => state.satJ;
export const getRygiel = (state: State) => state.rygiel;
export const getAwar = (state: State) => state.awar;
export const getCn = (state: State) => state.cn;
export const getCs = (state: State) => state.cs;
