<ng-container *ngIf="{
  sirenArmed: sirenArmed$ | async,
  processing: processing$ | async
} as sirenDialog">
  <h3 mat-dialog-title>{{ data.zestaw.id }}/{{ data.id }} {{ data.uwagi }} - Manage siren</h3>

  <div mat-dialog-content class="siren-dialog-body">
    <div class="d-flex align-items-center">
      <mat-icon class="mr-3">
        {{ sirenDialog.sirenArmed ? 'volume_up' : 'volume_off' }}
      </mat-icon>

      <p class="siren-status">
        {{ sirenDialog.sirenArmed ? 'Siren is armed' : 'Siren is disarmed'}}
      </p>
    </div>

    <mat-card class="siren-toggle-card">
      <mat-card-content>
        <mat-slide-toggle
          [checked]="sirenDialog.sirenArmed"
          [disabled]="sirenDialog.processing"
          color="primary"
          class="siren-toggle"
          (change)="armDisarmSiren($event)">
          Arm/disarm siren
        </mat-slide-toggle>
      </mat-card-content>

      <mat-card-footer>
        <mat-progress-bar
          *ngIf="sirenDialog.processing"
          color="accent"
          mode="indeterminate">
        </mat-progress-bar>
      </mat-card-footer>
    </mat-card>
  </div>
</ng-container>
