import { PunktSpedycyjny } from 'src/app/models/dto/transportSets';
import { AutocompleteSuggestion } from 'src/app/models/map/autocomplete';
import { LocationData } from 'src/app/models/map/geocode';
import { ReverseGeocodeResponse } from 'src/app/models/map/reverseGeocode';
import { CoordsMap, CoordsNamed } from '../interfaces';

export type PointGuard =
  | LocationData
  | ReverseGeocodeResponse
  | PunktSpedycyjny
  | AutocompleteSuggestion
  | CoordsNamed
  | CoordsMap
  | string;

export abstract class MapPointsGuard {
  static isLocationData(_point: PointGuard): _point is LocationData {
    const point = _point as LocationData;
    return typeof point.Address !== 'undefined';
  }

  static isReverseGeocodeResponse(
    _point: PointGuard
  ): _point is ReverseGeocodeResponse {
    const point = _point as ReverseGeocodeResponse;
    return (
      typeof point.location !== 'undefined' &&
      typeof point.location.address !== 'undefined'
    );
  }

  static isPunktSpedycyjny(_point: PointGuard): _point is PunktSpedycyjny {
    const point = _point as PunktSpedycyjny;
    return typeof point.nazwa_kod !== 'undefined';
  }

  static isAutoCompleteSuggestion(
    _point: PointGuard
  ): _point is AutocompleteSuggestion {
    const point = _point as AutocompleteSuggestion;
    return typeof point.address !== 'undefined';
  }

  static isCoordsNamed(_point: PointGuard): _point is CoordsNamed {
    const point = _point as CoordsNamed;
    return typeof point.latitude !== 'undefined';
  }

  static isCoordsMap(_point: PointGuard): _point is CoordsMap {
    const point = _point as CoordsMap;
    return typeof point.lat !== 'undefined';
  }
}
