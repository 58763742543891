import { ColorIndex } from '../../../../shared/interfaces/color-index';
import { TerminalLocationShort } from '../../../../../models/helpers/terminal-location-short';
import { IInfo } from '../../../../../models/frame/i-info';
import { RssiHelper } from '../../../../../helpers/rssi.helper';

export class RssiColor implements ColorIndex {
  constructor(private value: TerminalLocationShort | IInfo) {}

  getColorIndex(): number {
     return RssiHelper.rssiToDbm(this.value.rssi_wart).colorIdx;
  }
}
