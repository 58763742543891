import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { debounceTime, filter, map } from 'rxjs/operators';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import * as fromRoot from '../../../../app.reducer';
import * as MAP from '../../../../ngrx/map.actions';
import * as UI from '../../../../ngrx/ui.actions';

import { Naczepa, Samochod } from '../../../../models/dto/vehicle';
import { Terminal } from '../../../../models/dto/terminale';
import { TermianlSearchTracksParams } from '../../../../models/map';
import { TerminalLocationShort } from '../../../../models/helpers';
import { MY_DATE_FORMATS, Strings } from '../../../../helpers';
import { Privs, ToastType } from '../../../../helpers/enum';
import { MapMsg, TransportMsg } from '../../../../messages';

@Component({
  selector: 'app-show-tracks',
  templateUrl: './show-tracks.component.html',
  styleUrls: ['./show-tracks.component.scss'],
})
export class ShowTracksComponent implements OnInit, OnDestroy {
  static readonly myname = Strings.getObjectHash('ShowTracksComponent');
  ePrivs = Privs;
  terminale: Terminal[] = [];
  fTerminale$: Observable<Terminal[]>;
  naczepy$: Observable<Naczepa[]>;
  samochod$: Observable<Samochod[]>;
  searchProcesses$: Observable<number>;
  formTrasaPrzejazdu = new FormGroup({
    typPojazdu: new FormControl(''),
    identyfikatorPojazdu: new FormControl(),
    identyfikatorTerminala: new FormControl(),
    identyfikatoryPojazdu: new FormControl([], [Validators.required]),
    dataOd: new FormControl(),
    dataDo: new FormControl(),
    pokazPunkty: new FormControl(false),
    pokazLinie: new FormControl(true)
  });
  trasaPrzejazdu: TerminalLocationShort[][] = [];
  @ViewChild('terminalInput') terminalInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  private subs = new Subscription();

  constructor(private store: Store<fromRoot.State>) {
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.naczepy$ = this.store.select(fromRoot.selectors.vehicles.getTrails);
    this.samochod$ = this.store.select(fromRoot.selectors.vehicles.getTrucks);
    this.searchProcesses$ = this.store.select(fromRoot.selectors.ui.getMapSearchEventsNumber).pipe(debounceTime(150));

    this.subs.add(
      combineLatest([
        this.store.select(fromRoot.selectors.devices.getTerminals),
        this.store.select(fromRoot.selectors.map.getTerminalTrackPoints)
      ])
        .pipe(
          filter(res => res[0].length > 0)
        )
        .subscribe(([devs, trackP]) => {
          this.terminale = devs;

          if (trackP.search && trackP.search.terminals) {
            this.trasaPrzejazdu = trackP.points;

            const formDevs = devs.filter(dev => trackP.search.terminals.indexOf(dev.id) >= 0);
            this.formTrasaPrzejazdu.patchValue({
              identyfikatoryPojazdu: formDevs,
              dataOd: moment(trackP.search.dataOd),
              dataDo: moment(trackP.search.dataDo),
              pokazLinie: trackP.search.pokazLinie,
              pokazPunkty: trackP.search.pokazPunkty
            });
          }
        })
    );

    this.formTrasaPrzejazdu.patchValue({
      typPojazdu: 'terminal'
    });

    this.formTrasaPrzejazdu.controls.dataOd
      .setValue(moment().add(-3, 'd'));
    this.formTrasaPrzejazdu.controls.dataDo
      .setValue(moment().add(1, 'd'));

    this.fTerminale$ = this.formTrasaPrzejazdu.get('identyfikatorTerminala').valueChanges.pipe(
      map((search: string | null) => search && typeof search === 'string' ? this._filter(search) : this.terminale.slice()),
    );

    this.subs.add(
      this.formTrasaPrzejazdu.get('identyfikatorPojazdu').valueChanges
        .subscribe(v => {
          switch (this.formTrasaPrzejazdu.get('typPojazdu').value) {
            case 'truck': {
              this.formTrasaPrzejazdu.get('identyfikatoryPojazdu').patchValue([]);
              const terminal = this.terminale.find(t => {
                if (!t.samochods || t.samochods.length < 1) {
                  return false;
                }
                return t.samochods[0].id === v;
              });
              if (!terminal) {
                this.store.dispatch(UI.showUserMessage({message: {message: TransportMsg.NO_TRACKING_DEVICE, type: ToastType.WARN}}));
                return;
              }

              this.formTrasaPrzejazdu.get('identyfikatoryPojazdu').patchValue([terminal]);
              break;
            }

            case 'trail': {
              this.formTrasaPrzejazdu.get('identyfikatoryPojazdu').patchValue([]);
              const terminal = this.terminale.find(t => {
                if (!t.naczepas || t.naczepas.length < 1) {
                  return false;
                }
                return t.naczepas[0].id === v;
              });
              if (!terminal) {
                this.store.dispatch(UI.showUserMessage({message: {message: MapMsg.NO_TRACKING_DEVICE_FOR_VEHICLE, type: ToastType.WARN}}));
                return;
              }
              this.formTrasaPrzejazdu.get('identyfikatoryPojazdu').patchValue([terminal]);
              break;
            }
          }
          console.log(v);
        })
    );
  }

  onSubmit(): void {
    if ((this.formTrasaPrzejazdu.value.identyfikatoryPojazdu as []).length < 1) {
      this.store.dispatch(UI.showUserMessage({message: {message: MapMsg.NO_TRACKING_DEVICE_TO_TRACE, type: ToastType.ERROR}}));
      return;
    }

    const formValues = this.formTrasaPrzejazdu.value;

    const searchParams: TermianlSearchTracksParams = {
      dataOd: formValues.dataOd.format(MY_DATE_FORMATS.parse.dateInput),
      dataDo: formValues.dataDo.format(MY_DATE_FORMATS.parse.dateInput),
      terminals: formValues.identyfikatoryPojazdu.map((ter: Terminal) => ter.id),
      idFirma: 0,
      pokazPunkty: formValues.pokazPunkty,
      pokazLinie: formValues.pokazLinie
    };

    this.store.dispatch(MAP.GetTerminalLocPointsRequest({searchParams}));
  }

  downloadCsv(): void {
    if (!this.trasaPrzejazdu || this.trasaPrzejazdu.length < 1) {
      return;
    }
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(this.trasaPrzejazdu[0][0]);
    const csv = this.trasaPrzejazdu
      .reduce((accumulator, currentValue) => accumulator.concat(...currentValue))
      .map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');

    const blob = new Blob([csvArray], {type: 'text/csv'});
    saveAs(blob, moment().format('YYYYMMDD_HHmmss') + '_fdw-track-export.csv');
  }

  remove(fruit: string): void {
    const index = this.formTrasaPrzejazdu.get('identyfikatoryPojazdu').value.indexOf(fruit);

    if (index >= 0) {
      this.formTrasaPrzejazdu.get('identyfikatoryPojazdu').value.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    (this.formTrasaPrzejazdu.get('identyfikatoryPojazdu').value as Terminal[]).push(event.option.value);
    this.formTrasaPrzejazdu.get('identyfikatorTerminala').setValue(null);
    this.terminalInput.nativeElement.value = '';
  }

  private _filter(value: string): Terminal[] {
    const filterValue = value.toLowerCase();

    return this.terminale
      .filter(terminal => (terminal.id + ': ' + (terminal.uwagi || '')).toLowerCase().indexOf(filterValue) >= 0);
  }
}
