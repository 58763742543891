import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: 'tr[table-disabled-row]',
})
export class TableDisabledRowDirective {
  @HostBinding('class.table-disabled-row')
  @Input('table-disabled-row')
  isDisabled: boolean;
}
