import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatDrawerMode } from '@angular/material/sidenav/drawer';

import * as fromRoot from '../../../app.reducer';
import * as UI from '../../../ngrx/ui.actions';

@Injectable({
  providedIn: 'root'
})
export class RightDrawerService {
  private isClosable = new BehaviorSubject<boolean>(true);
  private mode = new BehaviorSubject<MatDrawerMode>('side');
  private hasBackdrop = new BehaviorSubject<boolean>(false);
  private component = new BehaviorSubject<any>(null);

  constructor(private store: Store<fromRoot.State>) {
  }

  get currentName(): string | null {
    return RightDrawerService.getComponentName(this.component.getValue());
  }

  get isClosable$(): Observable<boolean> {
    return this.isClosable.asObservable();
  }

  get mode$(): Observable<MatDrawerMode> {
    return this.mode.asObservable();
  }

  get hasBackdrop$(): Observable<boolean> {
    return this.hasBackdrop.asObservable();
  }

  static getComponentName(component: any): string | null {
    if (!component) {
      return null;
    }
    if (!component.myname) {
      console.error('RightDrawer component not created properly - missing: myname', component);
    }
    return component?.myname || component?.name || null;
  }

  openOver(component: any, data: any = null, closeable = true): void {
    if (this.mode.value !== 'over') {
      this.mode.next('over');
    }

    if (!this.hasBackdrop.value) {
      this.hasBackdrop.next(true);
    }
    this.openDrawer(component, data, closeable);
  }

  open(component: any, data: any = null, closeable = true): void {
    if (this.mode.value !== 'side') {
      this.mode.next('side');
    }

    if (this.hasBackdrop.value) {
      this.hasBackdrop.next(false);
    }
    this.openDrawer(component, data, closeable);
  }

  close(name: string = null): void {
    if (name === null || (this.component.getValue() !== null && this.currentName === name)) {
      this.component.next(null);
      this.store.dispatch(UI.switchRightDrawer({isOpened: false}));
    }
  }

  getComponent(): Observable<any> {
    return this.component.asObservable();
  }

  private openDrawer(component: any, data: any = null, closeable = true): void {
    this.isClosable.next(closeable);
    const newName = RightDrawerService.getComponentName(component);
    if (this.component.getValue() === null || newName !== this.currentName) {
      this.component.next(component);
    }
    this.store.dispatch(
      UI.switchRightDrawer({isOpened: true, componentName: newName, componentData: data})
    );
  }
}
