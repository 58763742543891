<div class="main draggable" cdkDrag fxFlex fxLayout="column" fxLayoutGap="5px">
  <div class="title-bar" cdkDragHandle fxFlex="1" fxLayout="row" fxLayoutAlign="start center">
    <span fxFlex="9 10 100%">Filters:</span>
    <span fxFlex fxLayoutAlign="end">
      <mat-icon (click)="form.hidden = !form.hidden" class="material-icons">
        details
      </mat-icon>
    </span>
  </div>
  <form [formGroup]="formFilter" #form (submit)="applyFilters()"
        fxFlex fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start end">
    <div fxFlex fxLayout="column" fxLayoutGap="5px">
      <mat-form-field>
        <mat-select formControlName="markerColoringScheme" placeholder="Colors...">
          <mat-option value="predkosc">Speed</mat-option>
          <mat-option value="rssi">GSM signal strength</mat-option>
          <mat-option value="opoznienie">Delay</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-checkbox formControlName="reduceMarkersAmount">Filter points</mat-checkbox>
    </div>
    <button mat-raised-button color="primary">Apply</button>
  </form>
</div>
