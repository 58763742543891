import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { BoltState, TerminalFrames } from 'src/app/helpers/enum';
import { Locker } from 'src/app/models/dto/vehicle';
import { LockerInfo } from 'src/app/modules/shared/helpers';
import { GetLockerStatusPipe } from 'src/app/modules/shared/pipes';
import { TerminalMngService } from 'src/app/services/terminal-mng.service';
import { ITransportEntity } from '../../interfaces/transport-entity';
import { TransitService } from '../../services';

@Component({
  selector: 'bolt-dialog',
  templateUrl: './bolt-dialog.component.html',
  styleUrls: ['./bolt-dialog.component.scss'],
  providers: [GetLockerStatusPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoltDialogComponent implements OnInit, OnDestroy {
  private lockerSubject = new BehaviorSubject<Locker | null | undefined>(
    this.data.transportLocker
  );
  private boltStateSubscription: Subscription | undefined;

  locker$: Observable<Locker | null | undefined> =
    this.lockerSubject.asObservable();
  lockerInfo$: Observable<LockerInfo> = this.lockerSubject
    .asObservable()
    .pipe(map((locker) => this.lockerStatusPipe.transform(locker)));
  processing$ = new BehaviorSubject<boolean>(false);

  BoltState = BoltState;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      transport: ITransportEntity;
      transportLocker: Locker | null | undefined;
    },
    private lockerStatusPipe: GetLockerStatusPipe,
    private transitService: TransitService,
    private terminalMngService: TerminalMngService
  ) {}

  ngOnInit(): void {
    this.listenForBoltState();
  }

  ngOnDestroy(): void {
    this.boltStateSubscription.unsubscribe();
  }

  openCloseBolt(close: boolean): void {
    this.processing$.next(true);

    this.transitService
      .openCloseBolt(
        this.data.transport.id,
        this.data.transportLocker.idTerminal,
        close
      )
      .pipe(
        switchMap(() =>
          this.terminalMngService.getLockerStatus(this.data.transportLocker.id)
        )
      )
      .subscribe((locker) => {
        this.lockerSubject.next(locker);
        this.processing$.next(false);
      });  }

  checkBoltStatus(): void {
    this.processing$.next(true);

    this.terminalMngService
      .getLockerStatus(this.data.transportLocker.id)
      .subscribe((locker) => {
        this.lockerSubject.next(locker);
        this.processing$.next(false);
      });
  }

  private listenForBoltState(): void {
    this.boltStateSubscription = this.lockerInfo$
      .pipe(
        map((lockerInfo) => lockerInfo.class),
        distinctUntilChanged()
      )
      .subscribe((boltState) => {
        switch (boltState) {
          case BoltState.OPENING:
          case BoltState.CLOSING:
          case BoltState.UNKNOWN:
            this.sendLockerStatusFrame(this.data.transportLocker);
            return;

          default:
            return;
        }
      });
  }

  private sendLockerStatusFrame(locker: Locker): void {
    this.processing$.next(true);
    this.terminalMngService
      .requestForFrame(locker.idTerminal, TerminalFrames.rygiel)
      .subscribe(() => {
        this.processing$.next(false);
      });
  }
}
