import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { EnvHelper, Strings } from '../helpers';
import * as fromRoot from '../app.reducer';
import { JwtBodyData } from '../models/dto';
import { environment } from '../../environments/environment';
import { UserRoleNames } from '../helpers/enum';

export const forbiddenAccessErrorMessage = ['Permission denied. User has not required permissions'.toLowerCase()];

@Injectable()
export class Ignore403ErrorsForGivenRolesInterceptor implements HttpInterceptor {

  /**
   * jakie błędy mają nie być zgłaszane na GUI -> Map(rola, url[])
   * @private
   */
  private readonly roleToPathsMap = new Map<string, string[]>();
  private userTokeData: JwtBodyData = null;

  constructor(private readonly store: Store<fromRoot.State>) {
    this.roleToPathsMap.set(
      UserRoleNames.dispatcher,
      [
        EnvHelper.getApiUrl() + environment.apiModules.Driver.kierowca + '_odczyt'
      ]
    );
    this.store.select(fromRoot.selectors.auth.getUserTokenDetails)
      .pipe(filter(token => Strings.getObjectHash(token) !== Strings.getObjectHash(this.userTokeData)))
      .subscribe(r => this.userTokeData = r);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if ((err.status !== 403 && !this.isOther403Error(err.error)) || request.url.includes('login')) {
          return throwError(err);
        }

        if (this.userTokeData && this.userTokeData.roles && this.userTokeData.roles.length > 0) {
          for (const role of this.userTokeData.roles) {
            const paths = this.roleToPathsMap.get(role) ?? [];
            if (paths.includes(request.url)) {
              return of(null);
            }
          }
        }
        return throwError(err);
      })
    );
  }

  private isOther403Error(error: any): boolean {
    return error && !!(error['Error message'] && forbiddenAccessErrorMessage.find(msg => error['Error message'].toLowerCase().includes(msg)));
  }
}
