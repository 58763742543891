import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { EnvHelper } from '../../../helpers';
import { environment } from '../../../../environments/environment';
import { Kategoria, Kierowca, KierowcaKategoria, KierowcaNowy } from '../interfaces';
import { KierowcaBasic } from 'src/app/models/dto/user';

@Injectable({
  providedIn: 'root'
})
export class DriverService {
  apiConfig = environment.apiModules.Driver;

  constructor(private http: HttpClient) {
  }

  getDriversInfo(): Observable<Kierowca[]> {
    return this.http.get<Kierowca[]>(
      EnvHelper.getApiUrl() + this.apiConfig.kierowca + '_odczyt'
    );
  }

  getDriversList(): Observable<KierowcaBasic[]> {
    return this.http.get<KierowcaBasic[]>(
      `${EnvHelper.getApiUrl()}${this.apiConfig.kierowca}_odczyt/basic`
    );
  }

  getDrivingLicenses(): Observable<Kategoria[]> {
    return this.http.get<Kategoria[]>(
      EnvHelper.getApiUrl() + this.apiConfig.kategoriePJ + '_odczyt'
    );
  }

  newDriver(kierowca: Partial<KierowcaNowy>): Observable<any> {
    return this.http.post<any>(
      EnvHelper.getApiUrl() + this.apiConfig.kierowca + '_edycja',
      kierowca
    );
  }

  newDriverLiccense(kategoriaKierowcy: KierowcaKategoria): Observable<any> {
    return this.http.post<any>(
      EnvHelper.getApiUrl() + this.apiConfig.kierowcaKategoriePJ + '_edycja',
      kategoriaKierowcy
    );
  }

  removeDriverLiccense(id: number): Observable<any> {
    return this.http.delete<any>(
      EnvHelper.getApiUrl() + this.apiConfig.kierowcaKategoriePJ + `_edycja/` + id,
    );
  }

  updateDriver(kierowca: KierowcaNowy): Observable<any> {
    return this.http.put<any>(
      EnvHelper.getApiUrl() + this.apiConfig.kierowca + '_edycja/' + kierowca.id,
      kierowca
    );
  }

  deleteDriver(id: number): Observable<unknown> {
    return this.http.delete<any>(
      EnvHelper.getApiUrl() + this.apiConfig.kierowca + '_edycja/' + id
    );
  }

  generateNewPins(
    driverId: number,
    pinType?: 'pin' | 'pinca'
  ): Observable<unknown> {
    return this.http.put<unknown>(
      `${EnvHelper.getApiUrl()}${
        this.apiConfig.kierowca
      }_edycja/zmianaPinu/${driverId}${pinType ? `/${pinType}` : ``}`,
      {}
    );
  }
}
