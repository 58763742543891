import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { combineLatest, Observable, Subject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../app.reducer';
import { map, take, takeUntil } from 'rxjs/operators';
import { IDriverReport } from '../../interfaces';
import { DriverReportsColumnsEnum } from '../../enums/driver-reports-columns.enum';
import * as fromDriverReports from './../../ngrx/driver-reports.reducer';
import { AllFiltersMustMatchFilter, DriverReportStatusFilter, SearchTextFilter } from '../../helper';
import { DriverReportsService } from '../../services/driver-reports.service';
import * as DRIVER_REPORTS from '../../ngrx/driver-reports.actions';
import { DriverReportTypesEnum } from '../../enums/driver-report-types.enum';
import * as UI from '../../../../ngrx/ui.actions';
import { DateFormat, ToastType } from '../../../../helpers/enum';

@Component({
  selector: 'app-driver-report-table-list',
  templateUrl: './driver-report-table-list.component.html',
  styleUrls: ['./driver-report-table-list.component.scss']
})
export class DriverReportTableListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;
  private destroyed$: Subject<boolean> = new Subject();

  driverReportsColumns: typeof DriverReportsColumnsEnum = DriverReportsColumnsEnum;
  driverReportList: MatTableDataSource<IDriverReport> = new MatTableDataSource<IDriverReport>([]);
  displayedColumns: string[] = Object.values(this.driverReportsColumns);
  isDataLoading$: Observable<boolean> = this.store.select(fromDriverReports.isDataLoading);
  dateFormat: typeof DateFormat = DateFormat;

  constructor(private store: Store<fromRoot.State>, private driverReportsService: DriverReportsService) {}

  ngOnInit(): void {
    this.getDriverReports();
  }

  ngAfterViewInit(): void {
    this.driverReportList.sort = this.sort;
    this.driverReportList.paginator = this.paginator;
  }

  getDriverReports(): void {
    combineLatest([
      this.store.select(fromDriverReports.getDriverReportsByTypeId),
      this.store.select(fromDriverReports.getDriversReportsFilter)
    ]).pipe(
      map(([reportsList, reportFilter]) => {
        return {
          reports: [...reportsList],
          filter: new AllFiltersMustMatchFilter([
            new SearchTextFilter(reportFilter.text),
            new DriverReportStatusFilter(reportFilter.status),
          ])
        };
      }),
      map((reportData) => {
          return [...reportData.reports].filter((report) => reportData.filter.isMatching(report));
        }
      ),
      takeUntil(this.destroyed$)
    ).subscribe(driverReports => {
      this.driverReportList.data = driverReports;
    });
  }

  updateRequest(id: number, confirmReport: boolean): void {
    this.driverReportsService.updateDriverReportStatus(id, confirmReport)
      .pipe(take(1))
      .subscribe(() => {
          this.store.dispatch(DRIVER_REPORTS.loadDriverReportsByTypeIdRequest({reportTypeId: DriverReportTypesEnum.PIN_REQUEST}));
          this.store.dispatch(UI.showUserMessage({
            message: {
              type: ToastType.SUCCESS,
              message: 'Driver report updated.'
            }
          }));
        },
        () => {
          this.store.dispatch(UI.showUserMessage({
            message: {
              type: ToastType.ERROR,
              message: 'Driver report updating error.'
            }
          }));
        });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
