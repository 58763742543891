import * as jwt_decode from 'jwt-decode';
import { JwtBodyData } from '../models/dto';

export class JwtHelper {
  private static currentTokenString = '';
  private static currentToken: JwtBodyData = null;

  static decode(token: string): JwtBodyData {
    if (token !== JwtHelper.currentTokenString) {
      const tokenBody = jwt_decode(token);
      if (tokenBody['perm']) {
        tokenBody['perm'] = JSON.parse(
          (tokenBody['perm'] as any as string)
            .replace('{', '[')
            .replace('}', ']')
        );
      }

      JwtHelper.currentToken = tokenBody;
      JwtHelper.currentTokenString = token;
    }
    return JwtHelper.currentToken;
  }

  static getTokenExpirationDate(token: string): Date {
    JwtHelper.currentToken = JwtHelper.decode(token);

    if (JwtHelper.currentToken === null ||  JwtHelper.currentToken.exp === undefined) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(JwtHelper.currentToken.exp);
    return date;
  }

  static getTokenExpirationMinutes(token: string): number {
    const date = JwtHelper.getTokenExpirationDate(token);
    if (date === undefined) {
      return 0;
    }

    const now = new Date();
    return Math.floor((date.valueOf() - now.valueOf()) / (60 * 1000));
  }

  static isTokenExpired(token?: string): boolean {
    if (!token) {
      return true;
    }

    const minutes = JwtHelper.getTokenExpirationMinutes(token);

    console.log('Token valid for: ~' + minutes + ' minutes');
    return minutes <= 1;
  }
}
