<mat-form-field appearance="outline">
  <mat-label>{{ label }}</mat-label>
  <mat-select [compareWith]="compareById" [placeholder]="placeholder" [id]="id"
              [formControl]="control" [disabled]="readonly" multiple>

    <mat-select-trigger>
      <mat-chip-list>
        <mat-chip *ngFor="let item of control.value"
                  [removable]="true" (removed)="onCatRemoved(item)" selected>
          {{ item.label }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </mat-select-trigger>

    <mat-option *ngFor="let item of lov" (onSelectionChange)="selected.emit($event)"
                [value]="item" [disabled]="item.disabled">{{item.label}}
    </mat-option>
  </mat-select>

  <mat-icon matSuffix *ngIf="suffixIcon">{{suffixIcon}}</mat-icon>
  <mat-hint *ngIf="hint">{{hint}}</mat-hint>
  <mat-error *ngIf="control.hasError('required')">Value is required.</mat-error>
  <mat-error *ngIf="control.hasError('custom')">{{ control.errors.custom.message }}.</mat-error>
</mat-form-field>
