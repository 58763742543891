<div fxLayout="column" class="mat-elevation-z3 main">
  <h5>Truck & Trailer</h5>
  <div fxLayout="column" fxLayoutGap="8px" class="mat-elevation-z1 vehicle">
    <ng-container *ngIf="zestaw.truckSet | vehicleFromTransit; else noVehicle">
      <div>
        <span>
          Truck: {{(zestaw.truckSet | vehicleFromTransit)?.id}}, {{(zestaw.truckSet | vehicleFromTransit).nrRej}}
        </span>
      </div>
      <div>
        <span>
          Trailer: {{(zestaw.trailerSet | vehicleFromTransit)?.id}}, {{(zestaw.trailerSet | vehicleFromTransit).nrRej}}
        </span>
      </div>
    </ng-container>
  </div>
</div>
<ng-template #noVehicle>
  <div class="error">Not defined.</div>
</ng-template>
