import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IRodzajAlarmu } from '../../alerts/interfaces/rodzaj-alarmu';
import { ActionNames, EventConfigActions } from './event-config.actions';

export interface State {
  alertTypes: IRodzajAlarmu[];
  currentlyEditedAlertType: IRodzajAlarmu;
}

const initialState: State = {
  alertTypes: [],
  currentlyEditedAlertType: null,
};

export const featureName = 'events-config';

export function reducer(state = initialState, action: EventConfigActions) {
  switch (action.type) {
    case ActionNames.LOAD_ALERT_TYPES_SUCCESS:
      return {
        ...state,
        alertTypes: action.payload,
      };

    case ActionNames.ADD_ALERT_TYPE_SUCCESS:
      return {
        ...state,
        alertTypes: [...state.alertTypes, action.payload]
      };

    case ActionNames.DELETE_ALERT_TYPE_SUCCESS:
      const alertTypeIdToDelete = action.payload;
      const depletedAlertTypes = [...state.alertTypes].filter(a => a.id !== alertTypeIdToDelete);
      return {
        ...state,
        alertTypes: depletedAlertTypes,
      };

    case ActionNames.UPDATE_ALERT_TYPE_SUCCESS:
      const updatedAlertType = action.payload;
      const alertTypesArray = [...state.alertTypes];
      const updatedAlertTypeOrderNumber = alertTypesArray.findIndex(t => t.id === updatedAlertType.id);
      const updatedAlertTypesArray = alertTypesArray.splice(updatedAlertTypeOrderNumber, 1, updatedAlertType);
      return {
        ...state,
        alertTypes: updatedAlertTypesArray,
      };

    case ActionNames.OPEN_ALERT_TYPE_EDITION:
      return {
        ...state,
        currentlyEditedAlertType: action.payload,
      };

    case ActionNames.CLOSE_ALERT_TYPE_EDITION:
      return {
        ...state,
        currentlyEditedAlertType: null,
      };

    default:
      return state;
  }
}

export const getState = createFeatureSelector<State>(featureName);
export const getAlertTypes = createSelector(getState, (state: State) => state.alertTypes);
export const getCurrentlyEditedAlertType = createSelector(getState, (state: State) => state.currentlyEditedAlertType);
