export enum DateFormat {
  PIPE_LONGER_24H = 'yyyy-MM-dd HH:mm:ss',
  PIPE_LONG_24H = 'yyyy-MM-dd HH:mm',
  PIPE_LONGER_12H = 'yyyy-MM-dd hh:mm:ss a',
  PIPE_LONG_12H = 'yyyy-MM-dd hh:mm a',

  PIPE_DATE = 'yyyy-MM-dd',
  PIPE_DATE_LABEL = 'd MMM y',
  PIPE_DATETIME_LABEL = 'd MMM y, HH:mm',
  PIPE_DATETIME_LABEL_MOMENT = 'D MMM YYYY, HH:mm',
  PIPE_DATETIME_LONGER_LABEL = 'd MMM y, HH:mm:ss',

  PIPE_TIMESHORT_24H = 'HH:mm',
  PIPE_TIME_24H = 'HH:mm:ss',

  PIPE_TIMESHORT_12H = 'hh:mm a',
  PIPE_TIME_12H = 'hh:mm:ss a',

  SYSTEM_DATE = 'DD.MM.YYYY',
  PRIME_NG_DATE = 'yy-mm-dd',
  MOMENT_DATE = 'YYYY-MM-DD'
}
