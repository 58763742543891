import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { RightDrawerService } from '../../modules/shared/services';

@Component({
  selector: 'app-right-drawer-container',
  templateUrl: './right-drawer-container.component.html',
  styleUrls: ['./right-drawer-container.component.scss']
})
export class RightDrawerContainerComponent {
  component$: Observable<any>;

  constructor(private rightDrawerSrv: RightDrawerService) {
    this.component$ = this.rightDrawerSrv.getComponent();
  }
}
