import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import * as fromVehicle from '../ngrx/vehicle.reducer';
import {
  Marka,
  Model,
  Naczepa,
  Samochod,
  StanPracy,
  StatusPojazdu,
  TypZapiecia,
  VehSearchTerm
} from '../models/dto/vehicle';

interface Selectors {
  getTrucks: MemoizedSelector<any, Samochod[]>;
  getTrails: MemoizedSelector<any, Naczepa[]>;
  getTrailTypes: MemoizedSelector<any, any>;
  getConnTypes: MemoizedSelector<any, TypZapiecia[]>;
  getTruckTypes: MemoizedSelector<any, any>;
  getUnavailReasons: MemoizedSelector<any, any>;
  getModels: MemoizedSelector<any, Model[]>;
  getVendors: MemoizedSelector<any, Marka[]>;
  getWorkStates: MemoizedSelector<any, StanPracy[]>;
  getTruckWorkStates: MemoizedSelector<any, StanPracy[]>;
  getTrailWorkStates: MemoizedSelector<any, StanPracy[]>;
  getVehicleStates: MemoizedSelector<any, StatusPojazdu[]>;
  getVehicleSearch: MemoizedSelector<any, VehSearchTerm>;

  getTruckTablet: MemoizedSelector<any, any[]>;
  // getTruckTabletByTruckId: (truckId: number) => MemoizedSelector<any, TruckTablet>;
}

export class VehicleSelectors {
  static _state = createFeatureSelector<fromVehicle.State>('vehicle');
  static _selectors: Selectors;

  static getSelectors() {
    this._selectors = {
      getTrucks: createSelector(this._state, fromVehicle.getTrucks),
      getTrails: createSelector(this._state, fromVehicle.getTrails),
      getTrailTypes: createSelector(this._state, fromVehicle.getTrailTypes),
      getConnTypes: createSelector(this._state, fromVehicle.getConnectionTypes),
      getTruckTypes: createSelector(this._state, fromVehicle.getTruckTypes),
      getUnavailReasons: createSelector(this._state, fromVehicle.getUnavailabilityReasons),
      getModels: createSelector(this._state, fromVehicle.getModels),
      getVendors: createSelector(this._state, fromVehicle.getVendors),

      getWorkStates: createSelector(this._state, fromVehicle.getWorkStates),
      getTruckWorkStates: createSelector(this._state, fromVehicle.getTruckWorkStates),
      getTrailWorkStates: createSelector(this._state, fromVehicle.getTrailWorkStates),
      getVehicleStates: createSelector(this._state, fromVehicle.getVehicleStates),
      getVehicleSearch: createSelector(this._state, fromVehicle.getVehicleSearch),
      getTruckTablet: createSelector(this._state, fromVehicle.getTruckTablet),
      // todo: Zmienic na getDriverTablet
      // getTruckTabletByTruckId: (truckId: number) =>
      //   createSelector(this._state, (state) => state.truckTablet.find(t => t?._samochod?.id === truckId)),
    };

    return this._selectors;
  }
}
