<span [ngSwitch]="userRequestState">
  <ng-container *ngSwitchCase="'ok'">
    <form [formGroup]="userForm" class="example-container" autocomplete="off" (ngSubmit)="onSubmit()">
      <mat-accordion>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Basic info
            </mat-panel-title>
            <mat-panel-description>
              Base information about user
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-form-field>
            <input matInput placeholder="Firstname" id="imie" formControlName="imie">
          </mat-form-field>,&nbsp;
          <mat-form-field>
            <input matInput placeholder="Lastname" id="nazwisko" formControlName="nazwisko">
          </mat-form-field>,&nbsp;
          <mat-form-field>
            <input matInput placeholder="Email" id="email" formControlName="email">
          </mat-form-field>
          <br>
          <mat-form-field>
            <input matInput placeholder="Phone nr.1" id="tel1" formControlName="tel1">
          </mat-form-field>,&nbsp;
          <mat-form-field>
            <input matInput placeholder="Phone nr.2" id="tel2" formControlName="tel2">
          </mat-form-field>
          <br>

          <mat-form-field>
            <input type="text" placeholder="Company" id="firma" matInput [matAutocomplete]="autoCompany"
                   formControlName="firma" readonly>
            <mat-autocomplete #autoCompany="matAutocomplete" [displayWith]="displayCompany">
              <mat-option *ngFor="let company of filteredCompany$ | async" [value]="company">
                {{ displayCompany(company) }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>,&nbsp;
          <mat-form-field>
            <input matInput placeholder="Login" id="login" formControlName="login" readonly>
          </mat-form-field>,&nbsp;
          <mat-form-field>
            <input matInput [matAutocomplete]="autoRole" type="text" placeholder="User role" id="rola"
                   formControlName="rola" autocomplete="user-role">
            <mat-autocomplete #autoRole="matAutocomplete" [displayWith]="displayRole">
              <mat-option *ngFor="let role of filteredRoles$ | async" [value]="role">
                {{ displayRole(role) }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>,&nbsp;
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Additional information
            </mat-panel-title>
            <mat-panel-description>
              ...
            </mat-panel-description>
          </mat-expansion-panel-header>
          <br/>

          <mat-form-field>
            <input matInput placeholder="Postal code" id="kod" formControlName="kod">
          </mat-form-field>,&nbsp;
          <mat-form-field>
            <input matInput placeholder="City" id="miasto" formControlName="miasto">
          </mat-form-field>,&nbsp;
          <mat-form-field>
            <input matInput placeholder="Street" id="ulica" formControlName="ulica">
          </mat-form-field>
          <br>

          <mat-form-field title="{{user?.czy_zabl && user?.czas_zabl ? 'Blocked on: ' + user?.czas_zabl : ''}}">
            <input matInput placeholder="Is blocked?" value="{{user?.czy_zabl | yesNo}}" readonly>
          </mat-form-field>,&nbsp;
          <mat-form-field>
            <input matInput placeholder="Employment date" value="{{user?.data_zatr |  date:'yyyy-MM-dd'}}" readonly>
          </mat-form-field>,&nbsp;
          <mat-form-field>
            <input matInput placeholder="Last password change"
                   value="{{user?.czas_zm_hasla.date |  date:'yyyy-MM-dd HH:mm:ss'}}" readonly>
          </mat-form-field>,&nbsp;
        </mat-expansion-panel>
      </mat-accordion>
      <hr/>
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" *ngIf="user.czy_usun === false">
        <button mat-raised-button color="primary" (click)="updateUser()">Save</button>
        <button mat-raised-button color="accent" (click)="removeUser()">Remove</button>
        <button mat-raised-button color="warn" (click)="lockManagement()">
          Change lock status
        </button>
      </div>
    </form>

  </ng-container>

  <ng-container *ngSwitchCase="'error'">
    <h2>User with given login has not been found or unexpected error has occurred.</h2>
  </ng-container>
  <ng-container *ngSwitchDefault>

  </ng-container>
</span>
