import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';

import {
  BaseTypeComponent,
  ConnectionTypeComponent,
  DetailsTrailComponent,
  TerminalListComponent,
  TrailComponent,
  TrailListComponent,
  TrailTypeComponent,
  TruckComponent,
  TruckDetailsComponent,
  TruckListComponent,
  UnavailReasonComponent,
  VehicleModelComponent,
  VehiclesComponent,
  VehicleTypeComponent,
  VehicleVendorComponent,
} from './index';
import { CustomMaterialModule } from 'src/app/modules/material.modules';
import {
  ConfigCreatorComponent,
  ConfigEditorComponent,
  ConfigListComponent,
  FrameConfigComponent,
  SoftwareComponent,
  TerminalComponent,
  TerminalShortInfoComponent,
  TerminalSimCardFormComponent
} from './terminal';
import {
  FrameFieldEditDialogComponent,
  FrameVersionEditDialogComponent,
  SoftwareFrameDialogComponent,
  TerminalAssignComponent,
  TerminalCreateComponent,
  TerminalLockerMngDialogComponent,
  TerminalSoftwareDialogComponent,
  UpgradeTerminalComponent
} from './dialogs';
import { EquipmentComponent } from './terminal/equipment/equipment.component';
import { SharedModule } from '../shared/shared.module';
import { MenuComponent } from './menu/menu.component';
import { TerminalVehRegNumberComponent } from './terminal/list-terminal/terminal-veh-reg-number/terminal-veh-reg-number.component';

@NgModule({
  imports: [
    CommonModule, CustomMaterialModule, FormsModule, RouterModule, MatInputModule, MatButtonModule, MatSelectModule,
    MatRadioModule, MatCardModule, ReactiveFormsModule, SharedModule, MatTooltipModule, CustomMaterialModule
  ],
  declarations: [VehiclesComponent, TruckComponent,
    TrailComponent, BaseTypeComponent, TrailListComponent,
    DetailsTrailComponent, TruckListComponent, TruckDetailsComponent,
    TrailTypeComponent, VehicleTypeComponent, ConnectionTypeComponent, UnavailReasonComponent,
    VehicleModelComponent, VehicleVendorComponent, TerminalComponent, TerminalListComponent,
    TerminalAssignComponent, TerminalCreateComponent, SoftwareComponent, TerminalSoftwareDialogComponent, UpgradeTerminalComponent,
    FrameConfigComponent, ConfigListComponent, ConfigEditorComponent, EquipmentComponent, FrameVersionEditDialogComponent,
    FrameFieldEditDialogComponent,
    ConfigCreatorComponent,
    SoftwareFrameDialogComponent,
    TerminalLockerMngDialogComponent,
    MenuComponent,
    TerminalSimCardFormComponent,
    TerminalShortInfoComponent,
    TerminalVehRegNumberComponent
  ],
    exports: [
        VehiclesComponent, TruckComponent,
        TrailComponent, BaseTypeComponent, TrailListComponent,
        DetailsTrailComponent, TruckListComponent, TruckDetailsComponent,
        TrailTypeComponent, VehicleTypeComponent, ConnectionTypeComponent, UnavailReasonComponent,
        VehicleModelComponent, VehicleVendorComponent, TerminalShortInfoComponent, TerminalVehRegNumberComponent
    ],
  entryComponents: [TerminalCreateComponent, TerminalAssignComponent, TerminalSoftwareDialogComponent, UpgradeTerminalComponent]
})
export class VehiclesModule {
}
