import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MapObjectsHelper } from '../main/here/helpers';
import { SvgMapIcon } from '../../../helpers/enum';

@Injectable({
  providedIn: 'root'
})
export class IconService {
  constructor(private http: HttpClient) {
  }

  static getSvgIcon(type: SvgMapIcon): Element {
    switch (type) {
      case SvgMapIcon.SAT: {
        return MapObjectsHelper.svgSatDomObject;
      }
      case SvgMapIcon.MOBILE: {
        return MapObjectsHelper.svgMobDomObject;
      }
      case SvgMapIcon.PARKING: {
        return MapObjectsHelper.svgParkingDomObject;
      }
      case SvgMapIcon.PARKING_SELECTED: {
        return MapObjectsHelper.svgParkingSelectedDomObject;
      }
      case SvgMapIcon.SHIPPING: {
        return MapObjectsHelper.svgShippingPointDomObject;
      }
      case SvgMapIcon.ALERT_EVENT:
      default: {
        return MapObjectsHelper.svgAlertEventDomObject;
      }
    }
  }

  private static createImgElement(path: string): Element {
    const domElement = document.createElement('img');
    domElement.src = path;
    domElement.style.left = '-14px';
    domElement.style.top = '-28px';
    return domElement;
  }

  loadBaseMapIcon() {
    if (MapObjectsHelper.svgRocketIcon.length < 100) {
      this.http.get('assets/img/map/rocket.svg', {responseType: 'text'})
        .subscribe(data => MapObjectsHelper.svgRocketIcon = data);
    }

    MapObjectsHelper.svgSatDomObject = IconService.createImgElement(SvgMapIcon.SAT);
    MapObjectsHelper.svgMobDomObject = IconService.createImgElement(SvgMapIcon.MOBILE);
    MapObjectsHelper.svgAlertEventDomObject = IconService.createImgElement(SvgMapIcon.ALERT_EVENT);
    MapObjectsHelper.svgParkingDomObject = IconService.createImgElement(SvgMapIcon.PARKING);
    MapObjectsHelper.svgParkingSelectedDomObject = IconService.createImgElement(SvgMapIcon.PARKING_SELECTED);
    MapObjectsHelper.svgShippingPointDomObject = IconService.createImgElement(SvgMapIcon.SHIPPING);
  }
}
