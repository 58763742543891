<div fxLayout="column" class="mat-elevation-z3 main">
  <h5>Timetable</h5>
  <div fxLayout="column" fxLayoutGap="8px" class="mat-elevation-z1 timetable">
    <div class="timetable-details" fxLayoutAlign="space-between">
        <span>
          Departure:
        </span>
      <span>
          {{(transport.czasWyjazdu | date : df.PIPE_DATETIME_LABEL) || 'Not defined'}}
        </span>
    </div>
    <div class="timetable-details" fxLayoutAlign="space-between">
        <span>
          Arrival:
        </span>
      <span>
          {{(transport.planowanyCzasPrzyjazdu | date : df.PIPE_DATETIME_LABEL) || 'Not defined'}}
        </span>
    </div>
  </div>
</div>
