<div *ngIf="mode==='bar'">
  <ng-container [ngSwitch]="true">
    <div class="bar-content" *ngSwitchCase="terminal?.naczepas[0] !== undefined"
         fxLayout="row" fxLayoutGap="8px" [matTooltip]="terminal.naczepas[0].uwagi">
      <span>Trailer:</span><span>{{terminal.naczepas[0].nr_rej}}</span>
    </div>
    <div class="bar-content" *ngSwitchCase="terminal?.samochods[0] !== undefined"
         fxLayout="row" fxLayoutGap="8px" [matTooltip]="terminal.samochods[0].uwagi">
      <span>Truck:</span><span>{{terminal.samochods[0].nr_rej}}</span>
    </div>
    <div class="bar-content" *ngSwitchDefault fxLayout="row" fxLayoutGap="8px" [matTooltip]="terminal.uwagi">
      <span>Terminal:</span><span>{{terminal.id}}</span>
    </div>
  </ng-container>
</div>
