<div fxLayout="column" class="mat-elevation-z3 main">
    <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
      <h5>Security</h5>
      <button mat-stroked-button [innerText]="(!isHidden ? 'Show' : 'Hide') + ' details'" (click)="toggle()"></button>
    </div>
  <div fxLayout="column" fxLayoutGap="8px" *ngIf="isHidden">
    <div class="mat-elevation-z1 p-8">
        <span>Transported for: <b>{{transport.wlascicielTowaru?.nazwa ? transport.wlascicielTowaru.nazwa : 'Not set'}}</b></span>
    </div>
    <div class="mat-elevation-z1 p-8">
        <span>Monitored by: <b>{{transport.centrum?.nazwa ? transport.centrum.nazwa : 'Not set'}}</b></span>
    </div>
    <div class="mat-elevation-z1 p-8">
        <span>Geofencing (meters): <b>{{transport.geofencing ? transport.geofMetry : 'Not set'}}</b></span>
    </div>
    <div class="mat-elevation-z1 p-8">
        <span>Number of safe parking lots assigned: <b>{{parkingLots.length}}</b></span>
    </div>
    <div class="mat-elevation-z1 p-8">
        <span>
          Owned by:
          <b>{{transport.dyspozytor?.imie ? transport.dyspozytor.imie + ' ' + transport.dyspozytor.nazwisko  : 'No owner assigned'}}</b>
        </span>
    </div>
    <div class="mat-elevation-z1 p-8">
        <span>
          Trailer's alarm siren: <b>{{transport.syrena ? 'Enabled' : 'Disabled'}}</b>
          <ng-container *ngIf="transport.syrena">
             - <b>{{transport.syrenaUzbrojona ? 'Armed' : 'Disarmed' }}</b>
          </ng-container>
        </span>
    </div>
    <div class="mat-elevation-z1 p-8">
        <span>
          Cold chain:
          <b>{{transport.coldChain ? 'Set' : 'Not set'}}</b>
        </span>
    </div>
  </div>
</div>
