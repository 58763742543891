import { GenericNavItem } from '../models/dto';
import { Privs } from './enum';

export class MainMenuHelper {

  static getEventAlertLink(security = true): GenericNavItem {
    const link: GenericNavItem = {
      path: '/events/alerts/transport', title: 'Alerts', position: 'LEFT', icon: 'history', needsAlertNotification: true
    };
    if (security) {
      return {
        ...link,
        security: {type: 'PRIVILEGES', value: [Privs.GUI_ALERTS]}
      };
    }

    return link;
  }

  static getTransportLink(security = true): GenericNavItem {
    const link: GenericNavItem = {
      path: '/transport/list', title: 'Transports', position: 'LEFT', icon: 'local_shipping'
    };
    if (security) {
      return {
        ...link,
        security: {type: 'PRIVILEGES', value: [Privs.GUI_TRANSPORT]}
      };
    }

    return link;
  }

  static getMapLink(security): GenericNavItem {
    const link: GenericNavItem = {
      path: '/map', title: 'Map', position: 'LEFT', icon: 'pin_drop'
    };
    if (security) {
      return {
        ...link,
        security: {type: 'PRIVILEGES', value: [Privs.GUI_MAP]}
      };
    }

    return link;
  }

  static getInventoryLink(security = true): GenericNavItem {
    const link: GenericNavItem = {
      path: '/dictionaries', title: 'Inventories', position: 'LEFT', icon: 'note_alt'
    };
    if (security) {
      return {
        ...link,
        security: {type: 'PRIVILEGES', value: [Privs.GUI_INVENTORY]}
      };
    }

    return link;
  }

  static getTicketsLink(security = true): GenericNavItem {
    const link: GenericNavItem = {
      path: '/events/tickets/ticketList', title: 'Tickets', position: 'LEFT', icon: 'label', needsTicketNotification: true
    };
    if (security) {
      return {
        ...link,
        security: {type: 'PRIVILEGES', value: [Privs.MNG_TICKETS]}
      };
    }

    return link;
  }

  static getDriverReports(security = true): GenericNavItem {
    const link: GenericNavItem = {
      path: '/events/driver-reports', title: 'Driver reports', position: 'LEFT', icon: 'report', needsDriverReportsNotification: true
    };
    // todo: dodac uprawnienia

    return link;
  }
}
