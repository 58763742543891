import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DateFormat } from 'src/app/helpers/enum';
import { SatStatus } from '../transit-test/transit-test-sat';

enum SatTextStatus {
  NOT_VERIFIED_PROCESSING,
  NO_DEVICE,
  DEVICE_BROKEN,
  DEVICE_OK_PROCESSING_LOCATION,
  DEVICE_OK_NO_LOCATION,
  VERIFIED_WORKS_WELL,
}

@Component({
  selector: 'transit-test-result-sat',
  templateUrl: './transit-test-result-sat.component.html',
  styleUrls: ['../transit-test-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransitTestResultSatComponent {
  @Input() resultsTitle: string;
  @Input() status: SatStatus;
  @Input() isDone: boolean = false;

  textStatusEnum: typeof SatTextStatus = SatTextStatus;
  dateFormat: typeof DateFormat = DateFormat;

  constructor() {}

  getTextStatus(status: SatStatus): SatTextStatus {
    if (!status || !status.hasOwnProperty('noDevice')) {
      return SatTextStatus.NOT_VERIFIED_PROCESSING;
    }

    if (status.noDevice) {
      return SatTextStatus.NO_DEVICE;
    }

    if (!status.deviceOk) {
      return SatTextStatus.DEVICE_BROKEN;
    }

    if (status.deviceOk && !status.upToDate && !this.isDone) {
      return SatTextStatus.DEVICE_OK_PROCESSING_LOCATION;
    }

    if (status.deviceOk && !status.upToDate && this.isDone) {
      return SatTextStatus.DEVICE_OK_NO_LOCATION;
    }

    if (status.deviceOk && status.upToDate) {
      return SatTextStatus.VERIFIED_WORKS_WELL;
    }
  }
}
