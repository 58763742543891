import { isNumeric } from 'rxjs/internal-compatibility';

export class RssiHelper {
  static readonly RSSI_OUT_OF_RANGE = {dbm: 'XXX dbm', text: 'Out of range', colorIdx: 0, color: '#000000', sClass: 'out-of-range'};
  static RSSI = [
    {dbm: '-109 dbm', text: 'Marginal', colorIdx: 0, color: '#000000', sClass: 'rssi-marginal'},
    {dbm: '-109 dbm', text: 'Marginal', colorIdx: 0, color: '#000000', sClass: 'rssi-marginal'},
    {dbm: '-109 dbm', text: 'Marginal', colorIdx: 0, color: '#000000', sClass: 'rssi-marginal'},
    {dbm: '-107 dbm', text: 'Marginal', colorIdx: 0, color: '#000000', sClass: 'rssi-marginal'},
    {dbm: '-105 dbm', text: 'Marginal', colorIdx: 0, color: '#000000', sClass: 'rssi-marginal'},
    {dbm: '-103 dbm', text: 'Marginal', colorIdx: 0, color: '#000000', sClass: 'rssi-marginal'},
    {dbm: '-101 dbm', text: 'Marginal', colorIdx: 0, color: '#000000', sClass: 'rssi-marginal'},
    {dbm: '-99 dbm', text: 'Marginal', colorIdx: 0, color: '#000000', sClass: 'rssi-marginal'},
    {dbm: '-97 dbm', text: 'Marginal', colorIdx: 1, color: '#a60007', sClass: 'rssi-marginal-up'},
    {dbm: '-95 dbm', text: 'Marginal', colorIdx: 1, color: '#a60007', sClass: 'rssi-marginal-up'},
    {dbm: '-93 dbm', text: 'OK', colorIdx: 2, color: '#da8600', sClass: 'rssi-ok'},
    {dbm: '-91 dbm', text: 'OK', colorIdx: 2, color: '#da8600', sClass: 'rssi-ok'},
    {dbm: '-89 dbm', text: 'OK', colorIdx: 2, color: '#da8600', sClass: 'rssi-ok'},
    {dbm: '-87 dbm', text: 'OK', colorIdx: 2, color: '#da8600', sClass: 'rssi-ok'},
    {dbm: '-85 dbm', text: 'OK', colorIdx: 2, color: '#da8600', sClass: 'rssi-ok'},
    {dbm: '-83 dbm', text: 'Good', colorIdx: 3, color: '#c9d100', sClass: 'rssi-good'},
    {dbm: '-81 dbm', text: 'Good', colorIdx: 3, color: '#c9d100', sClass: 'rssi-good'},
    {dbm: '-79 dbm', text: 'Good', colorIdx: 3, color: '#c9d100', sClass: 'rssi-good'},
    {dbm: '-77 dbm', text: 'Good', colorIdx: 3, color: '#c9d100', sClass: 'rssi-good'},
    {dbm: '-75 dbm', text: 'Good', colorIdx: 3, color: '#c9d100', sClass: 'rssi-good'},
    {dbm: '-73 dbm', text: 'Excellent', colorIdx: 4, color: '#29b304', sClass: 'rssi-excellent'},
    {dbm: '-71 dbm', text: 'Excellent', colorIdx: 4, color: '#29b304', sClass: 'rssi-excellent'},
    {dbm: '-69 dbm', text: 'Excellent', colorIdx: 4, color: '#29b304', sClass: 'rssi-excellent'},
    {dbm: '-67 dbm', text: 'Excellent', colorIdx: 4, color: '#29b304', sClass: 'rssi-excellent'},
    {dbm: '-65 dbm', text: 'Excellent', colorIdx: 4, color: '#29b304', sClass: 'rssi-excellent'},
    {dbm: '-63 dbm', text: 'Excellent', colorIdx: 5, color: '#00aeef', sClass: 'rssi-perfect'},
    {dbm: '-61 dbm', text: 'Excellent', colorIdx: 5, color: '#00aeef', sClass: 'rssi-perfect'},
    {dbm: '-59 dbm', text: 'Excellent', colorIdx: 5, color: '#00aeef', sClass: 'rssi-perfect'},
    {dbm: '-57 dbm', text: 'Excellent', colorIdx: 5, color: '#00aeef', sClass: 'rssi-perfect'},
    {dbm: '-55 dbm', text: 'Excellent', colorIdx: 5, color: '#00aeef', sClass: 'rssi-perfect'},
    {dbm: '-53 dbm', text: 'Excellent', colorIdx: 5, color: '#00aeef', sClass: 'rssi-perfect'},
    {dbm: 'MAX dbm', text: 'Excellent', colorIdx: 5, color: '#00aeef', sClass: 'rssi-perfect'},
  ];

  static rssiToDbm(rssiWart: number) {
    if (!isNumeric(rssiWart) || rssiWart < 0 || rssiWart > this.RSSI.length - 1) {
      return {...this.RSSI_OUT_OF_RANGE, text: this.RSSI_OUT_OF_RANGE + ' - ' + rssiWart};
    }
    return this.RSSI[rssiWart];
  }

  static rssiToDbmLabel(rssiWart: number): string {
    const val = this.rssiToDbm(rssiWart);
    return val.dbm + ' (' + val.text + ')';
  }
}
