<div class="transit-related-alerts-container" fxLayout="column" fxLayoutGap="16px">
  <p mat-subheading-1 class="drawer-heading">{{ transitRelatedAlertsHeading }}</p>
  <p class="alarms-heading">Alarms <em>({{relatedAlerts.length}})</em></p>
  <cdk-virtual-scroll-viewport
    itemSize="74"
    minBufferPx="calc(100vh - 186px)"
    maxBufferPx="500"
    class="virtual-scroll">
    <div fxLayout="column" fxLayoutGap="8px" class="related-alerts-container">
      <div *cdkVirtualFor="let alert of relatedAlerts | sortAlertsUproszczony; templateCacheSize: 16"
           class="related-alert" fxLayout="row" fxLayoutAlign="space-between">
        <div class="alert-info-content" fxLayout="column">
          <p class="registration" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon class="notifications-icon" [class.pending]="alert | isTransportAlertPending">notifications
            </mat-icon>
            <span>{{ alert.numerRej }} {{ alert.opis}}</span>
          </p>
          <p class="type">{{ alert.typPojazdu }} {{ alert.obslugujacy?.imie }} {{ alert.obslugujacy?.nazwisko }}</p>
          <p class="date">{{ alert.czas | date : df.PIPE_DATETIME_LONGER_LABEL }}</p>
        </div>
        <div class="alert-details-refference" fxLayout="column" fxLayoutAlign="center">
          <mat-icon class="chevron-right pointer" (click)="onAlertRefClick(alert)">chevron_right</mat-icon>
        </div>
      </div>
    </div>
  </cdk-virtual-scroll-viewport>
</div>
