import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { debounceTime, filter, first, skipWhile, takeWhile, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UzytkownikNowy } from '../../../models/dto/user/uzytkownik-nowy';
import { Firma } from '../../../models/dto/company';
import * as fromRoot from '../../../app.reducer';
import * as USER from '../../../ngrx/user.actions';
import { Strings } from '../../../helpers';
import { ActivatedRoute, Router } from '@angular/router';
import { RegisterForm } from '../../../models/form/register-form';
import { RoleUzytkownikow } from 'src/app/models/authentication';
import { inArray } from 'src/app/helpers/validators/custom.validators';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss'],
})
export class UserCreateComponent implements OnInit {
  firmy$: Observable<Firma[]>;
  filteredRoles$: Observable<RoleUzytkownikow[]> = this.store
    .select(fromRoot.selectors.user.getCompanyRoles).pipe(
      skipWhile((roles) => !roles.length),
      first(),
      tap((roles) => {
        this.userForm.get('_rola').addValidators(inArray<number>(roles.map(role => role.id)));
      })
    );
  addUserSuccess$ = this.store.select(state => state.user.addUserSuccess)
                    .pipe(
                      takeWhile(success => success === false, true),
                      tap((success) => {
                        if (success) {
                          this.router.navigate(['../list'], { relativeTo: this.route });
                        }
                      })
                    );

  public userForm = new RegisterForm(this.store);

  constructor(
    private store: Store<fromRoot.State>,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.firmy$ = this.store.select(fromRoot.getCompanyList);
    this.setUserFormData();
    this.subscribeUserFormChanges();
    this.store.dispatch(new USER.LoadCompanyRolesRequest());
  }

  setUserFormData(): void {
    const empDate = formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', 'en-US');

    this.userForm.patchValue({
      tel1: 'not-set',
      tel2: 'not-set',
      panstwo: 'Polska',
      nrL: '-',
      pyt: '',
      odp: '',
      data_zatr: empDate,
      _rola: null,
    });
  }

  private subscribeUserFormChanges(): void {
    this.userForm.valueChanges
      .pipe(
        debounceTime(500),
        filter(
          (form) =>
            form.imie &&
            form.nazwisko &&
            form.imie.length > 2 &&
            form.nazwisko.length > 2
        )
      )
      .subscribe((val) => {
        const namePart = val.nazwisko.toString().substr(0, 5).toLowerCase();
        const nLogin = Strings.removeDiacritics(
          namePart +
          val.imie
            .toString()
            .substr(0, 8 - namePart.length)
            .toLowerCase()
        );
        this.userForm.patchValue(
          {
            username: nLogin,
          },
          {emitEvent: false}
        );
        this.userForm.get('username').markAsTouched();
      });
  }

  onSubmit(): void {
    if (this.userForm.invalid) {
      return;
    }

    const nUser: UzytkownikNowy = {
      ...this.userForm.value,
      _firma: +this.userForm.value._firma,
    };

    this.store.dispatch(new USER.AddNewUserRequest(nUser));
    this.addUserSuccess$.subscribe();
  }

  roleName(roles: RoleUzytkownikow[]): (roleId: number) => string {
    return function (roleId) {
      return roles?.find((role) => role.id === roleId)?.nazwa_roli || '';
    };
  }
}
