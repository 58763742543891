import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, debounceTime, exhaustMap, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as TICKETS from '../ngrx/tickets.actions';
import { getTicketsForTransportRequest, getTicketsForTransportSuccess } from './tickets.actions';
import * as UI from '../../../ngrx/ui.actions';
import * as fromRoot from '../../../app.reducer';

import { TicketsService } from '../services/tickets.service';
import { Messages, ToastType } from '../../../helpers/enum';
import { TicketMsg } from '../../../messages';

@Injectable()
export class TicketsEffects {

  loadEvents$ = createEffect(() => this.actions.pipe(
    ofType(TICKETS.loadEventsRequest),
    exhaustMap(() => {
      return this.ticketsSrv.getEvents()
        .pipe(
          map(events => {
            return TICKETS.loadEventsSuccess({events});
          }),
          catchError(() => of(UI.userError({message: Messages.READING_DATA_ERR}))),
        );
    })
  ));

  loadTicketEvents$ = createEffect(() => this.actions.pipe(
    ofType(TICKETS.loadTicketEventsRequest),
    exhaustMap(({ticketId}) => {
      return this.ticketsSrv.getTicketTakenActions(ticketId)
        .pipe(
          map(events => {
            return TICKETS.loadTicketEventsSuccess({events, ticketId});
          }),
          catchError(() => of(UI.userError({message: Messages.READING_DATA_ERR}))),
        );
    })
  ));

  loadAllTickets$ = createEffect(() => this.actions.pipe(
    ofType(TICKETS.loadAllTicketsRequest),
    exhaustMap(() => {
      return this.ticketsSrv.getAllTickets().pipe(
        map(tickets => TICKETS.loadAllTicketsSuccess({tickets})),
        catchError(() => {
          this.store.dispatch(TICKETS.loadAllTicketsFailed());
          return of(UI.userError({message: Messages.READING_DATA_ERR}));
        }),
      );
    })
  ));

  loadTicketById$ = createEffect(() => this.actions.pipe(
    ofType(TICKETS.loadTicketByIdRequest),
    debounceTime(1000),
    switchMap(({ticketId}) => {
      return this.ticketsSrv.getTicketById(ticketId).pipe(
        map(ticket => TICKETS.loadTicketByIdSuccess({ticket})),
        catchError(() => of(UI.userError({message: Messages.READING_DATA_ERR}))),
      );
    })
  ));

  newTicketAction$ = createEffect(() => this.actions.pipe(
    ofType(TICKETS.newTicketActionRequest),
    exhaustMap(({ticketAction, callback}) => {
      return this.ticketsSrv.saveNewTakenAction(ticketAction).pipe(
        map(() => {
          callback();
          return TICKETS.loadTicketEventsRequest({ticketId: ticketAction.idTicket});
        }),
        catchError(() => of(UI.userError({message: Messages.SAVING_DATA_ERR}))),
      );
    })
  ));

  loadTicketsForTransports$ = createEffect(() => this.actions.pipe(
    ofType(getTicketsForTransportRequest),
    exhaustMap(({transportId}) => {
      return this.ticketsSrv.loadTicketsForTransport(transportId).pipe(
        map((tickets) => getTicketsForTransportSuccess({tickets})),
        catchError(() => of(UI.userError({message: Messages.SAVING_DATA_ERR}))),
      );
    })
  ));

  updateTicket$ = createEffect(() => this.actions.pipe(
    ofType(TICKETS.updateTicketRequest),
    exhaustMap(({ticket, callback}) => {
      return this.ticketsSrv.updateTicketData(ticket).pipe(
        map(() => {
          if (callback) {
            callback();
          } else {
            this.store.dispatch(UI.showUserMessage({message: {type: ToastType.SUCCESS, message: TicketMsg.UPDATED}}));
          }
          return TICKETS.loadTicketByIdRequest({ticketId: ticket.id});
        }),
        catchError(() => {
          this.store.dispatch(TICKETS.loadTicketByIdRequest({ticketId: ticket.id}));
          return of(UI.userError({message: Messages.SAVING_DATA_ERR}));
        }),
      );
    })
  ));

  constructor(
    private actions: Actions,
    private store: Store<fromRoot.State>,
    private ticketsSrv: TicketsService,
  ) {
  }

}
