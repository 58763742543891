import { catchError, finalize, map, startWith, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

import * as UI from '../../../ngrx/ui.actions';
import * as EVENT_CONFIG from './event-config.actions';
import * as fromRoot from '../../../app.reducer';

import { IRodzajAlarmu } from '../../alerts/interfaces';
import { EventsConfigService } from '../services/events-config.service';
import { Messages } from '../../../helpers/enum';

@Injectable()
export class EventConfigEffects {


  loadAlertTypes$ = createEffect(() => this.actions.pipe(
    ofType(EVENT_CONFIG.ActionNames.LOAD_ALERT_TYPES_REQUEST),
    switchMap(() => {
      return this.eventsConfigSrv.getAlertTypes().pipe(
        map(alertTypes => new EVENT_CONFIG.LoadAlertTypesSuccess(alertTypes)),
        startWith(UI.showProgressBar()),
        catchError(() => of(UI.userError({message: Messages.READING_DATA_ERR}))),
        finalize(() => this.store.dispatch(UI.hideProgressBar()))
      );
    })
  ));


  addAlertType$ = createEffect(() => this.actions.pipe(
    ofType(EVENT_CONFIG.ActionNames.ADD_ALERT_TYPE_REQUEST),
    switchMap((action) => {
      const newType: IRodzajAlarmu = action.payload;
      return this.eventsConfigSrv.addAlertType(
        newType.id,
        newType.rodzaj,
        newType.opis,
        newType.ocenaR
      ).pipe(
        startWith(new EVENT_CONFIG.AddAlertTypeSuccess(newType)),
        map(() => new EVENT_CONFIG.LoadAlertTypesRequest),
        catchError(() => of(UI.userError({message: Messages.READING_DATA_ERR})))
      );
    })
  ));


  deleteAlertType$ = createEffect(() => this.actions.pipe(
    ofType(EVENT_CONFIG.ActionNames.DELETE_ALERT_TYPE_REQUEST),
    switchMap((action) => {
      const id = action.payload;
      return this.eventsConfigSrv.deleteAlertType(id).pipe(
        startWith(new EVENT_CONFIG.DeleteAlertTypeSuccess(id)),
        map(() => new EVENT_CONFIG.LoadAlertTypesRequest),
        catchError(() => of(UI.userError({message: Messages.READING_DATA_ERR})))
      );
    })
  ));


  updateAlertType$ = createEffect(() => this.actions.pipe(
    ofType(EVENT_CONFIG.ActionNames.UPDATE_ALERT_TYPE_REQUEST),
    switchMap((action) => {
      const updatedType: IRodzajAlarmu = action.payload;
      return this.eventsConfigSrv.updateAlertType(
        updatedType.id,
        updatedType.ocenaR,
        updatedType.opis,
        updatedType.rodzaj,
      ).pipe(
        startWith(new EVENT_CONFIG.UpdateAlertTypeSuccess(updatedType)),
        map(() => new EVENT_CONFIG.LoadAlertTypesRequest),
        catchError(() => of(UI.userError({message: Messages.READING_DATA_ERR})))
      );
    })
  ));

  constructor(
    private actions: Actions<EVENT_CONFIG.EventConfigActions>,
    private store: Store<fromRoot.State>,
    private eventsConfigSrv: EventsConfigService,
  ) {
  }
}
