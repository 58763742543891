import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { delay, filter, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as Auth from './auth.actions';
import * as UI from './ui.actions';

import { MyToastService, UserMenuService } from '../services';

@Injectable()
export class UiEffects {

  authenticationSuccess$ = createEffect(() => this.actions.pipe(
    ofType<Auth.LoginUserData>(Auth.LOGIN_USER_DATA),
    filter(r => r.payload !== null && r.payload.id !== null),
    switchMap(() => {
      const links = this.userMenuSrv.getTopMenuForUser();
      return of(UI.setTopLinks({links}));
    })
  ));

  guiUserError$ = createEffect(() => this.actions.pipe(
    ofType(UI.userError),
    delay(10),
    tap((errorInfo) => {
      this.myMsg.error(errorInfo.message);
    })
  ), {dispatch: false});

  showUserMessage$ = createEffect(() => this.actions.pipe(
    ofType(UI.showUserMessage),
    delay(10),
    tap((action) => {
      const msg = action.message;
      this.myMsg.show(msg.message, msg.type, msg.title, msg.config);
    })
  ), {dispatch: false});

  constructor(
    private actions: Actions,
    private userMenuSrv: UserMenuService,
    private myMsg: MyToastService
  ) {
  }
}
