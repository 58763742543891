import { Naczepa, Samochod } from '../models/dto/vehicle';
import { ITruckAndTrailerDetails, ZestawTransportowy, ZtNaczepa, ZtSamochod } from '../modules/transport-set/interfaces';

export class TruckAndTrailerDetails {
  static getCurrentTransportSetDetails(transportSet: ZestawTransportowy): ITruckAndTrailerDetails {
    let truckDetails: Samochod | ZtSamochod = null;
    let trailerDetails: Naczepa | ZtNaczepa = null;
    if (!!transportSet?._zestaw_transportowy_samochod) {
      truckDetails = transportSet?._zestaw_transportowy_samochod[0]?._samochod ??
        transportSet?._zestaw_transportowy_samochod[0]?.id_samochod;
    }
    if (!!transportSet?._naczepa_zestaw) {
      trailerDetails = transportSet?._naczepa_zestaw[0]?._naczepa ?? transportSet?._naczepa_zestaw[0]?.id_naczepa;
    }
    return {
      truck: truckDetails,
      trailer: trailerDetails,
    };
  }
}
