import { Pipe, PipeTransform } from '@angular/core';
import { IInfo } from '../../../models/frame';
import { LocationService } from '../../map/services/location.service';
import { AtLeast } from '../../../helpers/types';
import { Coords, CoordsNamedPL } from '../interfaces';

@Pipe({
  name: 'validateGpsInfo'
})
export class ValidateGpsInfoPipe implements PipeTransform {

  transform(list: AtLeast<IInfo, CoordsNamedPL | Coords>[], terminalId: number): boolean {
    const position = list.find(d => d.idTerminal === terminalId);
    if (!position) {
      return true;
    }
    return LocationService.coordsValidator(position);
  }

}
