<div fxLayout="column" fxLayoutGap="16px" class="mat-elevation-z3 main">
  <h4>
    <div fxLayout="row" fxLayoutAlign="start center">
      <span>Assigned parking lots</span>
      <span fxFlex></span>
      <button mat-stroked-button [innerText]="isHidden ? 'Show' : 'Hide'" (click)="isHidden = !isHidden"></button>
    </div>
  </h4>
  <ng-container *ngIf="parkingLots.length > 0; else noParking">
    <ng-container *ngIf="!isHidden; else total">
      <div *ngFor="let parking of parkingLots" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center"
           class="mat-elevation-z1 parking">
        <mat-icon>local_parking</mat-icon>
        <span>{{parking.nazwa}}</span>
        <span fxFlex></span>
        <span>{{parking.kraj}}</span>
      </div>
    </ng-container>
    <ng-template #total>
      <div class="parking total mat-elevation-z3"><span>Total assigned parking lots: <strong>{{parkingLots.length}}</strong></span></div>
    </ng-template>
  </ng-container>
</div>
<ng-template #noParking>
  <div class="error">Parking not assigned for this transport</div>
</ng-template>
