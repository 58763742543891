import { createAction, props } from '@ngrx/store';
import { Firma } from '../models/dto/company';
// [general] load dictionary data
export const loadDictionaryData = createAction('[GENERAL] load dictionary data');
export const loadCompanyListRequest = createAction('[GENERAL] get company data - request');
export const loadCompanyListSuccess = createAction(
  '[GENERAL] get company data - success',
  props<{ companies: Firma[] }>()
);

export const updateCompanyRequest = createAction(
  '[GENERAL] update company - request',
  props<{ company: Firma }>()
);
export const updateCompanySuccess = createAction(
  '[GENERAL] update company - success',
);
