import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import * as fromRoot from '../../../app.reducer';
import * as USER from '../../../ngrx/user.actions';
import { RoleUzytkownikow, UprawnienieUzytkownika } from '../../../models/authentication';
import { UserDetailsForm } from '../../../models/form/user-details-form';
import { Uzytkownik } from '../../../models/dto/user';
import { Firma } from '../../../models/dto/company';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit, OnDestroy {
  public user: Uzytkownik;
  public userPrivileges: UprawnienieUzytkownika[];
  public userRequestState = 'init';
  public userForm = new UserDetailsForm();
  selectedUsername = '';
  filteredRoles$: Observable<RoleUzytkownikow[]>;
  filteredCompany$: Observable<Firma[]>;
  private subs = new Subscription();

  constructor(private route: ActivatedRoute, private store: Store<fromRoot.State>) {
    this.filteredRoles$ = this.userForm.roleChanges().pipe(
      debounceTime(300),
      switchMap((value) => {
        return this.store.select(fromRoot.selectors.user.getCompanyRoles).pipe(
          map((roles) => {
            if (typeof value !== 'string') {
              return roles;
            }

            return roles.filter((role) => role.nazwa_roli.includes(value));
          })
        );
      })
    );
    this.filteredCompany$ = this.userForm.companyChanges().pipe(
      debounceTime(300),
      switchMap((value) => {
        return this.store.select(fromRoot.getCompanyList).pipe(
          map((companies) => {
            if (typeof value !== 'string') {
              return companies;
            }

            return companies.filter((company: Firma) =>
              company.nazwa.includes(value) || company.vat.includes(value) || company.ulica.includes(value) || company.kod.includes(value)
            );
          })
        );
      })
    );
  }

  ngOnInit() {
    this.subs.add(
      this.store.select(fromRoot.selectors.user.getSelectedUserInfo)
        .pipe(
          filter(user => user !== undefined)
        )
        .subscribe(user => {
          if (user) {
            this.user = user;
            this.userRequestState = 'ok';
            this.userForm.patchFromModel(user);
          }
        })
    );
  }

  onSubmit() {
  }

  updateUser() {
    this.store.dispatch(new USER.EditUserRequest({user: this.userForm.getUpdatedModel()}));
  }

  removeUser() {
    this.store.dispatch(new USER.RemoveUserRequest({userId: this.user.id}));
  }

  lockManagement() {
    if (this.user.czy_zabl) {
      this.store.dispatch(new USER.UnLockUserRequest({userId: this.user.id}));
      return;
    }

    this.store.dispatch(new USER.LockUserRequest({userId: this.user.id}));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  displayRole(role: RoleUzytkownikow): string {
    return role.nazwa_roli;
  }

  displayCompany(company: Firma): string {
    return company.nazwa;
  }
}
