import { Component, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import * as fromRoot from '../../../../app.reducer';

import { ViewMode } from '../../../../helpers/enum';

@Component({
  selector: 'app-ticket-list',
  templateUrl: './ticket-list.component.html',
  styleUrls: ['./ticket-list.component.scss']
})
export class TicketListComponent implements OnDestroy {
  viewMode$: Observable<ViewMode>;
  subs = new Subscription();
  eViewMode = ViewMode;

  constructor(private store: Store<fromRoot.State>) {
    this.viewMode$ = store.select(fromRoot.selectors.ui.getAlertViewMode);
  }


  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
