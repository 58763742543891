import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { Terminal } from '../../../models/dto/terminale';

@Pipe({
  name: 'terminalLastTimeInfo',
  pure: true
})
export class TerminalLastTimeInfoPipe implements PipeTransform {

  transform(value: Partial<Terminal>, format = 'YYYY-MM-DD HH:mm:ss'): string {
    if (!value || !value.czas_ost_kom || !moment(value.czas_ost_kom).isValid()) {
      return '';
    }

    const ts = moment(value.czas_ost_kom);
    const tsString = ts.format(format);
    const days = moment().diff(ts, 'days');
    if (days > 2) {
      return `<span title="${tsString}">~${days} days ago </span>`;
    }

    return `<span>${tsString}</span>`;
  }

}
