import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { filter, take } from 'rxjs/operators';
import moment from 'moment';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import * as fromRoot from '../../../../app.reducer';
import * as fromTickets from '../../ngrx/tickets.reducer';
import * as UI from '../../../../ngrx/ui.actions';

import { SelectOption } from '../../../shared/interfaces';
import { Event } from '../../interfaces';
import { FormHelper } from '../../../shared/helpers';
import { AppUserInfo } from '../../../../models/authentication';
import { INowaPodjetaAkcjaDTO } from '../../../../models/dto/alert/podjeta-akcja';
import { newTicketActionRequest } from '../../ngrx/tickets.actions';
import { ToastType } from '../../../../helpers/enum';

@Component({
  selector: 'app-ticket-timeline-form',
  templateUrl: './ticket-timeline-form.component.html',
  styleUrls: ['./ticket-timeline-form.component.scss']
})
export class TicketTimelineFormComponent implements OnInit, OnDestroy {

  @Input() ticketId: number;

  form = new FormGroup({
    message: new FormControl(''),
    action: new FormControl(null, Validators.required)
  });

  public eventsLov: SelectOption<Event>[] = [];
  subs = new Subscription();
  userInfo: AppUserInfo;

  constructor(private store: Store<fromRoot.State>) {
    store.select(fromRoot.selectors.auth.getUserInfo)
      .pipe(
        filter(d => d !== null && d !== undefined),
        take(1)
      ).subscribe(user => this.userInfo = user);

    this.subs.add(
      store.select(fromTickets.getEvents)
        .pipe(filter(res => res.length > 0))
        .subscribe(events => {
          this.eventsLov = FormHelper.makeSelectLov(events, 'akcja');
        })
    );
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  submit(): void {
    if (this.form.invalid || !this.userInfo) {
      return;
    }

    const actionTaken: INowaPodjetaAkcjaDTO = {
      czas: moment().toISOString(),
      idObslugujacy: +this.userInfo.userId,
      idTicket: this.ticketId,
      idAkcja: this.form.value.action.id,
      opis: this.form.value.message
    };

    this.store.dispatch(newTicketActionRequest({
      ticketAction: actionTaken,
      callback: () => {
        this.form.reset();
        const msg = {
          message: 'Event has been added to the ticket.',
          type: ToastType.INFO,
        };
        this.store.dispatch(UI.showUserMessage({message: msg}));
      }
    }));

  }

}
