<div [ngSwitch]="(currentLoginStep$ | async).step" fxFlex fxLayout="column" fxLayoutAlign="center center"
     fxLayoutGap="24px" class="main">
  <div class="logo">
    <svg></svg>
  </div>
  <p *ngSwitchCase="1">
    <app-step1-login-form></app-step1-login-form>
  </p>
  <p *ngSwitchCase="2">
    <app-step2-login-pin></app-step2-login-pin>
  </p>
  <h1 *ngSwitchDefault><br/>Login in progress, please wait...<br/></h1>
</div>
