import { UserPasswordReset } from '../../../../models/authentication';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

  resetpwd = new UserPasswordReset();

  constructor() {
  }

  ngOnInit() {
  }

  resetPwdAction(e) {
    e.preventDefault();
  }
}
