<div *ngIf="{
    editMode: editMode$ | async,
    processing: processing$ | async
  } as sp" class="shipping-point-container">
  <h4>
    {{ sp.editMode ? 'Edit shipping point' : 'New shipping point '}}
  </h4>

  <mat-vertical-stepper [selectedIndex]="sp.editMode ? 1 : 0" #shippingPointStepper>
    <mat-step>
      <mat-card class="step step-1">
        <mat-card-content>
          <ng-template matStepLabel>Set shipping point location</ng-template>
          <p class="step-1-hint">
            <mat-icon class="step-1-hint--icon">mouse</mat-icon>
            <span class="step-1-hint--text">
              Select location from the context menu using right mouse button on the map.
            </span>
          </p>
          <p class="step-1-hint">
            <mat-icon class="step-1-hint--icon">search</mat-icon>
            <span class="step-1-hint--text">Or search by providing an address below:</span>
          </p>

          <mat-form-field class="w-100">
            <mat-label>Search by address</mat-label>
            <geocoding-search [ngModel]="searchLocation" [addPoint]="false" (geocoded)="geocoded($event)"
              (ngModelChange)="enableForm($event)">
            </geocoding-search>
          </mat-form-field>

          <div *ngIf="searchLocation" class="selected-location">
            <p class="step-1-hint">
              <span class="step-1-hint--text">
                You have selected:<br />
                <span class="address">
                  {{ searchBase.displayFn(searchLocation) }}<br />
                  <mat-icon [class.d-none]="!position" class="align-text-bottom">
                    location_on
                  </mat-icon>
                  {{ position }}
                </span>
              </span>
            </p>

            <button mat-raised-button matStepperNext type="button" class="w-100 mt-2">
              Continue
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-step>

    <mat-step>
      <mat-card class="step step-2">
        <mat-card-content>
          <ng-template matStepLabel>Fill address data</ng-template>
          <mat-error *ngIf="!searchLocation && !sp.editMode" class="mb-3">
            You need to set shipping point location first.
          </mat-error>
          <p *ngIf="rangeMessage" class="range-message">
            <mat-icon class="range-message--icon">warning</mat-icon>
            Make sure that address data you change corresponds to selected location. If not, please consider moving back to location selection.

            <button
              mat-raised-button
              matStepperPrevious
              color="primary"
              type="button"
              class="d-block mt-2">
              Re-select location
            </button>
          </p>
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="shipping-point-form">
              <app-input autocomplete="false" label="Name" [control]="form.controls['nazwa']" [required]="true"></app-input>
              <app-input autocomplete="false" label="Name code" [control]="form.controls['nazwa_kod']"
                placeholder="Max 10 characters"[required]="true"></app-input>
              <app-input label="Country" [control]="form.controls['panstwo']" [required]="true"></app-input>
              <app-input label="City" [control]="form.controls['miasto']" [required]="true"></app-input>
              <app-input label="Postal code" [control]="form.controls['kod']" [required]="true"></app-input>
              <app-input label="Street" [control]="form.controls['ulica']" [required]="true"></app-input>
              <app-input label="House number" [control]="form.controls['nr_d']" [required]="true"></app-input>
              <app-input label="Apartament number" [control]="form.controls['nr_l']"></app-input>
              <app-input autocomplete="false" label="Base radius (meters]"
                [control]="form.controls['promien_bazy']"></app-input>
              <app-input autocomplete="false" label="Description" [control]="form.controls['opis']"></app-input>
            </div>
            <div class="shipping-point-actions">
              <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid || sp.processing">
                Save
              </button>
              <button *ngIf="callbackData" (click)="callbackData.fn()" mat-raised-button type="button"
                [disabled]="sp.processing">
                {{callbackData.label}}
              </button>
            </div>
          </form>
        </mat-card-content>

        <mat-card-footer>
          <mat-progress-bar *ngIf="sp.processing" color="accent" mode="indeterminate">
          </mat-progress-bar>
        </mat-card-footer>
      </mat-card>
    </mat-step>
  </mat-vertical-stepper>

</div>
