export enum DriverReportStatusEnum {
  NEW = 1,
  RECEIVED = 2,
  FINISHED = 3,
  ASSIGNED_TO_TICKET = 4,
  CANCELLED = 5
}

export enum DriverReportStatusNameEnum {
  NEW = 'new',
  RECEIVED = 'received',
  FINISHED = 'finished',
  ASSIGNED_TO_TICKET = 'assigned to ticket',
  CANCELLED = 'cancelled',
}
