import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import * as fromAdmin from '../ngrx/admin.reducer';
import { RoutingApi } from '../models/admin';
import { Uprawnienie } from '../models/authentication';

interface Selectors {
  getProxyRouting: MemoizedSelector<any, RoutingApi[]>;
  getIsRoutingChanged: MemoizedSelector<any, boolean>;
  getPrivileges: MemoizedSelector<any, Uprawnienie[]>;
}

export class AdminSelectors {
  static _state = createFeatureSelector<fromAdmin.State>('admin');
  static _selectors: Selectors;

  static getSelectors() {
    this._selectors = {
      getProxyRouting: createSelector(this._state, fromAdmin.getProxyRouting),
      getIsRoutingChanged: createSelector(this._state, fromAdmin.isRoutingChanged),
      getPrivileges: createSelector(this._state, fromAdmin.getPrivileges)
    };

    return this._selectors;
  }
}
