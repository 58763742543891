import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { LayoutModule } from '@angular/cdk/layout';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { EffectsModule } from '@ngrx/effects';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { AuthenticationService, NavMenuService } from 'src/app/services';
import {
  HttpJwtInterceptor,
  Ignore403ErrorsForGivenRolesInterceptor,
  RepeatAfter500ProxyErrorInterceptor,
  UnauthorizedInterceptor
} from './interceptors';
import { CustomMaterialModule } from './modules/material.modules';

import { AppComponent } from './app.component';
import { AppRouting } from './app.routing';
import { metaReducers, reducers } from './app.reducer';
import { effects } from './app.effects';
import { UserInfoComponent } from './layout/user-info/user-info.component';

import { AuthenticationModule } from './modules/authentication/authentication.module';
import { MapModule } from './modules/map/map.module';
import { MY_DATE_FORMATS } from './helpers';
import { UsersModule } from './modules/users/users.module';
import { SharedModule } from './modules/shared/shared.module';
import { DriversModule } from './modules/drivers/drivers.module';
import { TransportSetModule } from './modules/transport-set/transport-set.module';
import { AlertsModule } from './modules/alerts/alerts.module';
import { EventsConfigModule } from './modules/events-config/events-config.module';
import { NewLayoutComponent } from './layout/new-layout.component';
import { UserAffiliationComponent } from './layout/user-affiliation/user-affiliation.component';
import { MainMenuComponent } from './layout/main-menu/main-menu.component';
import { SubMenuComponent } from './layout/sub-menu/sub-menu.component';
import { TopBarComponent } from './layout/top-bar/top-bar.component';
import { BreadcrumbComponent } from './layout/top-bar/breadcrumb/breadcrumb.component';
import { TopRightCornerMenuComponent } from './layout/top-right-corner-menu/top-right-corner-menu.component';

import { ConfirmationDialogComponent } from './components';
import { ProgressBarComponent } from './layout/progress-bar/progress-bar.component';
import { RightDrawerContainerComponent } from './layout/right-drawer-container/right-drawer-container.component';
import { TicketsModule } from './modules/tickets/tickets.module';
import { TransportModule } from './modules/transports/transport.module';
import { EventQuantityBadgeComponent } from './layout/main-menu/components/event-quantity-badge/event-quantity-badge.component';
import { DriverReportsModule } from './modules/driver-reports/driver-reports.module';

@NgModule({
  declarations: [
    AppComponent,
    UserInfoComponent,
    ConfirmationDialogComponent,
    NewLayoutComponent,
    UserAffiliationComponent,
    MainMenuComponent,
    SubMenuComponent,
    TopBarComponent,
    BreadcrumbComponent,
    UserAffiliationComponent,
    MainMenuComponent,
    TopRightCornerMenuComponent,
    ProgressBarComponent,
    RightDrawerContainerComponent,
    EventQuantityBadgeComponent,
  ],
  imports: [
    AuthenticationModule,
    BreadcrumbModule,
    MapModule,
    SharedModule,
    UsersModule,
    DriversModule,
    AlertsModule,
    TicketsModule,
    DriverReportsModule,
    EventsConfigModule,
    TransportModule,
    TransportSetModule,
    AppRouting,
    BrowserModule,
    BrowserAnimationsModule,
    CustomMaterialModule,
    HttpClientModule,
    LayoutModule,
    FlexLayoutModule,
    StoreModule.forRoot(reducers, {metaReducers}),
    EffectsModule.forRoot(effects),
    StoreDevtoolsModule.instrument({
      maxAge: 30
    })
  ],
  providers: [
    AuthenticationModule, NavMenuService, AuthenticationService,
    {provide: HTTP_INTERCEPTORS, useClass: HttpJwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: Ignore403ErrorsForGivenRolesInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: RepeatAfter500ProxyErrorInterceptor, multi: true},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS},
  ],
  entryComponents: [UserInfoComponent],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
