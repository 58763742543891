<form [formGroup]="userForm" class="example-container"  autocomplete="off" (ngSubmit)="onSubmit()">
    <mat-expansion-panel [expanded]="true" class="mb-4">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Basic info
        </mat-panel-title>
        <mat-panel-description>
          Base information about user
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-form-field>
        <input matInput placeholder="First name" id="nimie" formControlName="imie">
      </mat-form-field>
      ,&nbsp;
      <mat-form-field>
        <input matInput placeholder="Last name" id="nazwisko" formControlName="nazwisko">
      </mat-form-field>
      ,&nbsp;
      <mat-form-field>
        <input matInput placeholder="Email address" id="email" formControlName="email">
      </mat-form-field>
      <br>
      <mat-form-field>
        <mat-select placeholder="Company"
                    formControlName="_firma"
        >
          <mat-option value="0">None</mat-option>
          <mat-option value="{{firma.id}}" *ngFor="let firma of firmy$ | async">
            {{firma.nazwa}}, {{firma.miasto}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      ,&nbsp;
      <mat-form-field>
        <input matInput placeholder="Login" id="nlogin" formControlName="username">
        <mat-error *ngIf="userForm.get('username').hasError('usernameAlreadyExists')">Username already exists</mat-error>
      </mat-form-field>,
      <mat-form-field>
        <input matInput placeholder="Password" id="npassword" formControlName="password" type="password" autocomplete="new-password">
      </mat-form-field>
      ,&nbsp;
      <mat-form-field>
        <input matInput [matAutocomplete]="autoRole" type="text" placeholder="User role" id="_rola"
               formControlName="_rola" autocomplete="user-role">
        <mat-autocomplete #autoRole="matAutocomplete" [displayWith]="roleName(filteredRoles$ | async)">
          <mat-option *ngFor="let role of filteredRoles$ | async" [value]="role.id">
            {{ role.nazwa_roli }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Additional information
        </mat-panel-title>
      </mat-expansion-panel-header>
      <br/>

      <mat-form-field>
        <input matInput placeholder="Country" id="panstwo" formControlName="panstwo">
      </mat-form-field>
      ,&nbsp;
      <mat-form-field>
        <input matInput placeholder="Postal code" id="kod" formControlName="kod">
      </mat-form-field>
      ,&nbsp;
      <mat-form-field>
        <input matInput placeholder="City" id="miasto" formControlName="miasto">
      </mat-form-field>
      <br>
      <mat-form-field>
        <input matInput placeholder="Street" id="ulica" formControlName="ul">
      </mat-form-field>
      ,&nbsp;
      <mat-form-field>
        <input matInput placeholder="House number" id="nrD" formControlName="nr_d">
      </mat-form-field>
      ,&nbsp;
      <mat-form-field>
        <input matInput placeholder="Apartment number" id="nrL" formControlName="nr_l">
      </mat-form-field>
      <br>

      <mat-form-field>
        <input matInput placeholder="Help question" formControlName="pyt">
      </mat-form-field>
      ,&nbsp;
      <mat-form-field>
        <input matInput placeholder="Help reponse" formControlName="odp">
      </mat-form-field>
      ,&nbsp;
      <mat-form-field>
        <input matInput placeholder="Employment date" formControlName="data_zatr">
      </mat-form-field>

    </mat-expansion-panel>
  <hr/>
  <button type="submit" class="btn btn-primary">
    Create
  </button>

</form>
