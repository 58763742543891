import { Uprawnienie } from '../../../models/authentication';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface PrivilegesHierarchy {
  privilegeId: number;
  parents: number[];
}

class PrivilegeMap {
  private list$ = new BehaviorSubject<PrivilegesHierarchy[]>([]);

  public get allPrivilegesNum$(): Observable<number> {
    return this.list$.asObservable().pipe(map(r => r.length));
  }

  public get allPrivileges(): { privilegeId: number, parents: number[] }[] {
    return [...this.list$.getValue()];
  }

  public getById(privilegeId: number): number[] {
    return this.list$.getValue().find(item => item.privilegeId === privilegeId)?.parents ?? [];
  }

  public addParent(privilegeId: number, parentId: number): void {
    const currentList = this.list$.getValue();
    const privilege = currentList.find(item => item.privilegeId = privilegeId);
    if (privilege) {
      privilege.parents.push(parentId);
    } else {
      currentList.push({privilegeId: privilegeId, parents: [parentId]});
    }
    this.list$.next(currentList);
  }

  public setPrivilege(parents: number[]): void {
    const privilegeId = parents[0];
    if (this.list$.getValue().find(item => item.privilegeId === privilegeId)) {
      return;
    }
    this.list$.next([...this.list$.getValue(), {privilegeId, parents}]);
  }
}

export abstract class PrivilegeHelper {
  static readonly privilegeMap = new PrivilegeMap();

  static parsePrivileges(privileges: Uprawnienie[]): void {
    privileges
      .forEach(p => {
        if (p._upr_nadrz) {
          PrivilegeHelper.crawlForPrivileges(p._upr_nadrz as Uprawnienie, [p.id]);
        } else {
          PrivilegeHelper.privilegeMap.setPrivilege([p.id]);
        }
      });
  }

  private static crawlForPrivileges(privilege: Uprawnienie, path: number[]): void {
    path = [...path, privilege.id];
    if (privilege._upr_nadrz) {
      PrivilegeHelper.crawlForPrivileges(privilege._upr_nadrz as Uprawnienie, path);
    } else {
      PrivilegeHelper.privilegeMap.setPrivilege(path);
    }
  }
}
