import { Action, createReducer, on } from '@ngrx/store';
import * as mapActions from './map.actions';
import { TerminalLocationSat, TerminalLocationShort } from '../models/helpers';
import { DraggedPoint, Filtry, GenericPoint, MapCenter, MapPoint, TermianlSearchTracksParams } from '../models/map';
import { ReverseGeocodeResponse } from '../models/map/reverseGeocode';
import { Coords } from '../modules/shared/interfaces';
import { MapColorScheme } from '../helpers/enum';
import { Strings } from '../helpers';
import { Terminal } from '../models/dto/terminale';

export interface State {
  reverseGeoCode: ReverseGeocodeResponse;
  mapPointMoved: DraggedPoint;
  mapPointRemoved: MapPoint;
  searchRouteResult: any[];
  ostatnie_pozycje: TerminalLocationShort[];
  trasy: TerminalLocationShort[][];
  trasySAT: TerminalLocationSat[][];
  trasy_parametry_wyszukiwania: TermianlSearchTracksParams;
  filtry: Filtry;
  adHocPoints: GenericPoint[];
  adHocLines: Coords[][];
  centerMap: MapCenter;
  viewerExtraData: any;
  markedMapPoints: Terminal[];
}

export const initialState: State = {
  reverseGeoCode: null,
  mapPointMoved: null,
  mapPointRemoved: null,
  searchRouteResult: [],
  ostatnie_pozycje: [],
  trasy: [],
  trasySAT: [],
  trasy_parametry_wyszukiwania: null,
  filtry: {markerColoringScheme: MapColorScheme.DELAY, reduceMarkersAmount: true},
  adHocPoints: [],
  adHocLines: [],
  centerMap: {zoom: 6, point: null},
  viewerExtraData: null,
  markedMapPoints: []
};

const mapReducer = createReducer(
  initialState,
  on(mapActions.GetTerminalLocPointsSuccess, (state, {points, satPoints, searchParams}) => {
    return {
      ...state,
      trasy: [...points]
        .map(p => p === undefined || p === null ? [] : p)
        .map(trackerPoints => [...trackerPoints].sort((a, b) => +b.czas - +a.czas)),
      trasySAT: [...satPoints].map(p => p === undefined || p === null ? [] : p),
      trasy_parametry_wyszukiwania: searchParams
    };
  }),
  on(mapActions.LoadTerminalsLastPositionSuccess, (state, {terminalPoints}) => ({...state, ostatnie_pozycje: terminalPoints})),
  on(mapActions.NewMapFilters, (state, {filters}) => ({...state, filtry: filters})),
  on(mapActions.SearchRouteForWayPointsSuccess, (state, {data}) => ({...state, searchRouteResult: data ?? []})),
  on(mapActions.ReverseGeocodeSuccess, (state, {data}) => ({...state, reverseGeoCode: data})),
  on(mapActions.MapPointDragged, (state, {point}) => ({...state, mapPointMoved: point})),
  on(mapActions.MapPointRemoved, (state, {point}) => ({...state, mapPointRemoved: point})),
  on(mapActions.AdhocPoints, (state, {points}) => ({...state, adHocPoints: points})),
  on(mapActions.AdhocLines, (state, {lines}) => ({...state, adHocLines: lines})),
  on(mapActions.ViewerExtraData, (state, {extraData}) => ({...state, viewerExtraData: extraData})),
  on(mapActions.MarkTermianls, (state, {mapPointData}) => {
    let out = [...mapPointData];
    if (Strings.getObjectHash(mapPointData) === Strings.getObjectHash(state.markedMapPoints)) {
      out = [];
    }
    return {...state, markedMapPoints: out};
  }),
  on(mapActions.CenterMap, (state, {point, zoom}) => ({
    ...state,
    centerMap: {
      ...state.centerMap,
      zoom: zoom ?? initialState.centerMap.zoom,
      point: point ?? initialState.centerMap.point,
    }
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return mapReducer(state, action);
}

export const getLastLocations = (currState: State) => currState.ostatnie_pozycje;
export const getLastLocationsByTerminalId = (currState: State, props) =>
  currState.ostatnie_pozycje.find(i => i.id_terminal === props.terminalId);
export const getSelectedTerminalPath = (currState: State) => {
  return {points: currState.trasy, search: currState.trasy_parametry_wyszukiwania, satPoints: currState.trasySAT};
};
export const getFilters = (currState: State) => currState.filtry;
export const getSearchingRouteResults = (currState: State) => currState.searchRouteResult;
export const getReverseGeocode = (currState: State) => currState.reverseGeoCode;
export const getMovedPoint = (currState: State) => currState.mapPointMoved;
export const getRemovedPoint = (currState: State) => currState.mapPointRemoved;
export const getAdhocPoints = (currState: State) => currState.adHocPoints;
export const getAdhocLines = (currState: State) => currState.adHocLines;
export const getMapCenter = (currState: State) => currState.centerMap;
export const getViewerExtraData = (currState: State) => currState.viewerExtraData;
export const getSelectedTerminalList = (currState: State) => currState.markedMapPoints;
