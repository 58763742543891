import { Component, Input } from '@angular/core';
import { ICompanyRoute } from '../../../interfaces';
import { ITransportEntityExtended } from '../../../interfaces/transport-entity';

@Component({
  selector: 'app-transport-routes',
  templateUrl: './transport-routes.component.html',
  styleUrls: ['./transport-routes.component.scss']
})
export class TransportRoutesComponent {
  @Input() transportRoutes: ICompanyRoute[] = [];
  @Input() transport: ITransportEntityExtended;
}
