<div class="content">
  <button mat-icon-button color="primary" routerLink='create' routerLinkActive="active">
    <mat-icon aria-label="Example icon-button with a heart icon">playlist_add</mat-icon>
  </button>
  <button mat-icon-button routerLink='list' routerLinkActive="active">
    <mat-icon aria-label="Example icon-button with a heart icon">list</mat-icon>
  </button>
  <hr>
  <router-outlet></router-outlet>
</div>
