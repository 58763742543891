import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { IsAuthenticatedGuard } from '../../guards';
import {
  CreateNewTransportComponent,
  FavouriteTransitsComponent,
  ParkingLotsComponent,
  ShippingPointsComponent,
  TransportListComponent,
} from './page';
import { EntrypointComponent } from './entrypoint/entrypoint.component';

const routes: Routes = [
  {
    path: 'transport', data: {breadcrumb: 'Transports'}, component: EntrypointComponent, children: [
      {path: '', pathMatch: 'full', redirectTo: 'list'},
      {path: 'list', data: {breadcrumb: 'All transports'}, component: TransportListComponent},
      {path: 'edit/:id', data: {breadcrumb: 'Transport edition'}, component: CreateNewTransportComponent},
      {path: 'edit/:id/:step', data: {breadcrumb: 'Transport edition'}, component: CreateNewTransportComponent},
      {path: 'new-transport', data: {breadcrumb: 'New transport'}, component: CreateNewTransportComponent},
      {path: 'favourite', data: {breadcrumb: 'Assigned transports'}, component: FavouriteTransitsComponent, children: []},
      {path: 'parking', data: {breadcrumb: 'Parking lots'}, component: ParkingLotsComponent},
      {path: 'shipping-points', data: {breadcrumb: 'Shipping points'}, component: ShippingPointsComponent},
    ], canActivate: [IsAuthenticatedGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TransportRouting {
}
