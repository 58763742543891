import { Action } from '@ngrx/store';

import {
  Karta,
  KartaTerminal,
  OprogramowanieTerminala,
  OprogramowanieWersjaRamkiInsert,
  PoleRamki,
  PoleRamkiTypDanych,
  Ramka,
  Rygiel,
  SamochodTerminal,
  StatusTerminala,
  Terminal,
  TerminalNaczepa,
  WersjaRamki,
  WersjaRamkiOpr,
  WersjaRamkiRequest
} from '../models/dto/terminale';
import { TerminalFrames } from '../helpers/enum';

export const LOAD_TERMINALS_TRAILS_REQUEST = '[TERMINAL] Load terminals trails - request';
export const LOAD_TERMINALS_TRAILS_SUCCESS = '[TERMINAL] Load terminals trails - success';
export const LOAD_TERMINALS_TRUCKS_REQUEST = '[TERMINAL] Load terminals trucks - request';
export const LOAD_TERMINALS_TRUCKS_SUCCESS = '[TERMINAL] Load terminals trucks - success';

export const NEW_TERMINAL_REQUEST = '[TERMINAL] New terminal - request';
export const NEW_TERMINAL_SUCCESS = '[TERMINAL] New terminal - success';
export const NEW_TERMINAL_TRUCK_REQUEST = '[TERMINAL] New terminal truck - request';
export const NEW_TERMINAL_TRUCK_SUCCESS = '[TERMINAL] New terminal truck - success';
export const NEW_TERMINAL_TRAIL_REQUEST = '[TERMINAL] New terminal trail - request';
export const NEW_TERMINAL_TRAIL_SUCCESS = '[TERMINAL] New terminal trail - success';

export const LOAD_TERMINALS_REQUEST = '[TERMINAL] Load terminals - request';
export const LOAD_TERMINALS_SUCCESS = '[TERMINAL] Load terminals - success';

// [terminal] load terminal statuses

export const LOAD_TERMINAL_STATUSES_REQUEST = '[TERMINAL] load terminal statuses - request';
export const LOAD_TERMINAL_STATUSES_SUCCESS = '[TERMINAL] load terminal statuses - success';

export const LOAD_TERMINALS_SIM_CARDS_REQUEST = '[TERMINAL] Load terminals sim cards - request';
export const LOAD_TERMINALS_SIM_CARDS_SUCCESS = '[TERMINAL] Load terminals sim cards - success';

export const LOAD_SIM_CARDS_REQUEST = '[TERMINAL] Load sim cards - request';
export const LOAD_SIM_CARDS_SUCCESS = '[TERMINAL] Load sim cards - success';

export const ASSIGN_SIM_CARD_TO_TERMINAL = '[TERMINAL] Assign sim card to terminal';
export const UNASSIGN_SIM_CARD_FROM_TERMINAL = '[TERMINAL] Unassign sim card from terminal';

export class LoadTerminalStatusesRequest implements Action {
  readonly type = LOAD_TERMINAL_STATUSES_REQUEST;
}

export class LoadTerminalStatusesSuccess implements Action {
  readonly type = LOAD_TERMINAL_STATUSES_SUCCESS;

  constructor(public payload: StatusTerminala[]) {
  }
}

export class LoadTerminalsRequest implements Action {
  readonly type = LOAD_TERMINALS_REQUEST;
}

export class LoadTerminalsSuccess implements Action {
  readonly type = LOAD_TERMINALS_SUCCESS;

  constructor(public payload: Terminal[]) {
  }
}

export class LoadTerminalsTrailsRequest implements Action {
  readonly type = LOAD_TERMINALS_TRAILS_REQUEST;
}

export class LoadTerminalsTrailsSuccess implements Action {
  readonly type = LOAD_TERMINALS_TRAILS_SUCCESS;

  constructor(public payload: TerminalNaczepa[]) {
  }
}

export class LoadTerminalsSimCardsRequest implements Action {
  readonly type = LOAD_TERMINALS_SIM_CARDS_REQUEST;
}

export class LoadTerminalSimCardsSuccess implements Action {
  readonly type = LOAD_TERMINALS_SIM_CARDS_SUCCESS;

  constructor(public payload: { result: KartaTerminal[] }) {
  }

  get terminalSimCards(): KartaTerminal[] {
    return this.payload.result;
  }
}

export class LoadSimCardsRequest implements Action {
  readonly type = LOAD_SIM_CARDS_REQUEST;
}

export class AssignSimCardToTerminal implements Action {
  readonly type = ASSIGN_SIM_CARD_TO_TERMINAL;

  constructor(public readonly payload: { terminalSimCard: KartaTerminal }) {
  }

  get terminalSimCard(): KartaTerminal {
    return this.payload.terminalSimCard;
  }
}

export class UnassignSimCardFromTerminal implements Action {
  readonly type = UNASSIGN_SIM_CARD_FROM_TERMINAL;

  constructor(public readonly payload: { terminalSimCard: KartaTerminal }) {
  }

  get terminalSimCard(): KartaTerminal {
    return this.payload.terminalSimCard;
  }
}

export class LoadSimCardsSuccess implements Action {
  readonly type = LOAD_SIM_CARDS_SUCCESS;

  constructor(public payload: { result: Karta[] }) {
  }

  get simCards(): Karta[] {
    return this.payload.result;
  }
}

export class LoadTerminalsTrucksRequest implements Action {
  readonly type = LOAD_TERMINALS_TRUCKS_REQUEST;
}

export class LoadTerminalsTrucksSuccess implements Action {
  readonly type = LOAD_TERMINALS_TRUCKS_SUCCESS;

  constructor(public payload: SamochodTerminal[]) {
  }
}

export class NewTerminalRequest implements Action {
  readonly type = NEW_TERMINAL_REQUEST;

  constructor(public payload: { device: Terminal, successCallback?: () => void }) {
  }
}

export class NewTerminalSuccess implements Action {
  readonly type = NEW_TERMINAL_SUCCESS;

  constructor(public payload?: Terminal) {
  }
}

export class NewTerminalTruckRequest implements Action {
  readonly type = NEW_TERMINAL_TRUCK_REQUEST;

  // constructor(public payload: any) {
  // constructor(public payload: { device: SamochodTerminal, dialog: MatDialogRef<any> }) {
  constructor(public payload: { device: SamochodTerminal, successCallback: () => void }) {
  }
}

export class NewTerminalTruckSuccess implements Action {
  readonly type = NEW_TERMINAL_TRUCK_SUCCESS;

  constructor(public payload: SamochodTerminal) {
  }
}

export class NewTerminalTrailRequest implements Action {
  readonly type = NEW_TERMINAL_TRAIL_REQUEST;

  constructor(public payload: { device: TerminalNaczepa, successCallback: () => void }) {
  }
}

export class NewTerminalTrailSuccess implements Action {
  readonly type = NEW_TERMINAL_TRAIL_SUCCESS;

  constructor(public payload: TerminalNaczepa) {
  }
}

// [terminal] load terminal software list

export const LOAD_TERMINAL_SOFTWARE_LIST_REQUEST = '[TERMINAL] load terminal software list - request';
export const LOAD_TERMINAL_SOFTWARE_LIST_SUCCESS = '[TERMINAL] load terminal software list - success';

export class LoadTerminalSoftwareListRequest implements Action {
  readonly type = LOAD_TERMINAL_SOFTWARE_LIST_REQUEST;
}

export class LoadTerminalSoftwareListSuccess implements Action {
  readonly type = LOAD_TERMINAL_SOFTWARE_LIST_SUCCESS;

  constructor(public payload: OprogramowanieTerminala[]) {
  }
}

// [terminal] add new terminal software

export const NEW_TERMINAL_SOFTWARE_REQUEST = '[TERMINAL] New terminal software - request';

export class NewTerminalSoftwareRequest implements Action {
  readonly type = NEW_TERMINAL_SOFTWARE_REQUEST;

  // constructor(public payload: OprogramowanieTerminala) {
  constructor(public payload: { soft: OprogramowanieTerminala, successCallback?: (softId: number) => void }) {
  }
}

// [terminal] update terminal software

export const UPDATE_TERMINAL_SOFTWARE_REQUEST = '[TERMINAL] update terminal software - request';

export class UpdateTerminalSoftwareRequest implements Action {
  readonly type = UPDATE_TERMINAL_SOFTWARE_REQUEST;

  constructor(public payload: OprogramowanieTerminala) {
  }
}

// [terminal] upgrade software - request

export const UPGRADE_SOFTWARE_REQUEST = '[TERMINAL] upgrade software - request';

export class UpgradeSoftwareRequest implements Action {
  readonly type = UPGRADE_SOFTWARE_REQUEST;

  constructor(public payload: { termId: number, softCode: string, packetSize: number }) {
  }
}

// [terminal] upgrade software - success

export const UPGRADE_SOFTWARE_SUCCESS = '[TERMINAL] upgrade software - success';

export class UpgradeSoftwareSuccess implements Action {
  readonly type = UPGRADE_SOFTWARE_SUCCESS;
}


export const LOAD_FRAMES_REQUEST = '[TERMINAL] load frame - request';

export class LoadFramesRequest implements Action {
  readonly type = LOAD_FRAMES_REQUEST;
}

export const LOAD_FRAMES_SUCCESS = '[TERMINAL] load frame - success';

export class LoadFramesSuccess implements Action {
  readonly type = LOAD_FRAMES_SUCCESS;

  constructor(public payload: { frames: Ramka[] }) {
  }
}

// [terminal] - update frame version
export const UPDATE_FRAME_VERSION_REQUEST = '[TERMINAL] update frame version - request';

export class UpdateFrameVersionRequest implements Action {
  readonly type = UPDATE_FRAME_VERSION_REQUEST;

  constructor(public payload: { wersja: WersjaRamki, succCallback?: () => void, ramka: Ramka }) {
  }
}

export const UPDATE_FRAME_VERSION_SUCCESS = '[TERMINAL] update frame version - success';

export class UpdateFrameVersionSuccess implements Action {
  readonly type = UPDATE_FRAME_VERSION_SUCCESS;

  constructor(public payload: { wersja: WersjaRamki, ramka: Ramka }) {
  }
}

// [terminal] - update frame field
export const UPDATE_FRAME_FIELD_REQUEST = '[TERMINAL] update frame field - request';

export class UpdateFrameFieldRequest implements Action {
  readonly type = UPDATE_FRAME_FIELD_REQUEST;

  constructor(public payload: { pole: PoleRamki, wersja: WersjaRamki, succCallback?: () => void, ramka: Ramka }) {
  }
}

export const UPDATE_FRAME_FIELD_SUCCESS = '[TERMINAL] update frame field - success';

export class UpdateFrameFieldSuccess implements Action {
  readonly type = UPDATE_FRAME_FIELD_SUCCESS;

  constructor(public payload: { pole: PoleRamki, wersja: WersjaRamki, ramka: Ramka }) {
  }
}

// [terminal] - load frame for software
export const LOAD_FRAMES_FOR_SOFTWARE_REQUEST = '[TERMINAL] load frame for software - request';

export class LoadFramesForSoftwareRequest implements Action {
  readonly type = LOAD_FRAMES_FOR_SOFTWARE_REQUEST;
}

export const LOAD_FRAMES_FOR_SOFTWARE_SUCCESS = '[TERMINAL] load frame for software - success';

export class LoadFramesForSoftwareSuccess implements Action {
  readonly type = LOAD_FRAMES_FOR_SOFTWARE_SUCCESS;

  constructor(public payload: { result: WersjaRamkiOpr[] }) {
  }
}

// [terminal] - new software frame
export const NEW_SOFTWARE_FRAME_REQUEST = '[TERMINAL] new software frame - request';

export class NewSoftwareFrameRequest implements Action {
  readonly type = NEW_SOFTWARE_FRAME_REQUEST;

  constructor(public payload: { record: OprogramowanieWersjaRamkiInsert, successCallback: () => void }) {
  }
}

// [terminal] - new software frame
export const NEW_SOFTWARE_FRAMES_REQUEST = '[TERMINAL] new software frame - request';

export class NewSoftwareFramesRequest implements Action {
  readonly type = NEW_SOFTWARE_FRAMES_REQUEST;

  constructor(public payload: { records: OprogramowanieWersjaRamkiInsert[] }) {
  }
}


// [terminal] - remove software frame
export const REMOVE_SOFTWARE_FRAME_REQUEST = '[TERMINAL] remove software frame - request';

export class RemoveSoftwareFrameRequest implements Action {
  readonly type = REMOVE_SOFTWARE_FRAME_REQUEST;

  constructor(public payload: { record: number, successCallback: () => void }) {
  }
}

// [terminal] - load frame field types
export const LOAD_FRAME_FIELD_TYPES_REQUEST = '[TERMINAL] load frame field types - request';

export class LoadFrameFieldTypesRequest implements Action {
  readonly type = LOAD_FRAME_FIELD_TYPES_REQUEST;
}

export const LOAD_FRAME_FIELD_TYPES_SUCCESS = '[TERMINAL] load frame field types - success';

export class LoadFrameFieldTypesSuccess implements Action {
  readonly type = LOAD_FRAME_FIELD_TYPES_SUCCESS;

  constructor(public payload: { typy: PoleRamkiTypDanych[] }) {
  }
}

// [terminal] - new software version
export const NEW_FRAME_VERSION_REQUEST = '[TERMINAL] new frame version - request';

export class NewFrameVersionRequest implements Action {
  readonly type = NEW_FRAME_VERSION_REQUEST;

  constructor(public payload: { version: WersjaRamkiRequest }) {
  }
}

// [terminal] - locker open
export const LOCKER_OPEN_REQUEST = '[TERMINAL] locker open - request';

export class LockerOpenRequest implements Action {
  readonly type = LOCKER_OPEN_REQUEST;

  constructor(public payload: { terminalId: number }) {
  }
}

export const LOCKER_OPEN_SUCCESS = '[TERMINAL] locker open - success';

export class LockerOpenSuccess implements Action {
  readonly type = LOCKER_OPEN_SUCCESS;
}

// [terminal] - locker close
export const LOCKER_CLOSE_REQUEST = '[TERMINAL] locker close - request';

export class LockerCloseRequest implements Action {
  readonly type = LOCKER_CLOSE_REQUEST;

  constructor(public payload: { terminalId: number }) {
  }
}

export const LOCKER_CLOSE_SUCCESS = '[TERMINAL] locker close - success';

export class LockerCloseSuccess implements Action {
  readonly type = LOCKER_CLOSE_SUCCESS;
}


// [terminal] - locker status
export const LOCKER_STATUS_REQUEST = '[TERMINAL] locker status - request';

export class LockerStatusRequest implements Action {
  readonly type = LOCKER_STATUS_REQUEST;

  constructor(public payload: { terminalId: number, successCallback: () => void }) {
  }
}

export const LOCKER_STATUS_SUCCESS = '[TERMINAL] locker status - success';

export class LockerStatusSuccess implements Action {
  readonly type = LOCKER_STATUS_SUCCESS;
}

// [terminal] - request for frame
export const REQUEST_FOR_FRAME_REQUEST = '[TERMINAL] request for frame - request';

export class RequestForFrameRequest implements Action {
  readonly type = REQUEST_FOR_FRAME_REQUEST;

  constructor(public payload: { terminalId: number, frameName: TerminalFrames }) {
  }
}

export const REQUEST_FOR_FRAME_SUCCESS = '[TERMINAL] request for frame - success';

export class RequestForFrameSuccess implements Action {
  readonly type = REQUEST_FOR_FRAME_SUCCESS;
}

// [terminal] - locker insert or update
export const LOCKER_INSERT_OR_UPDATE_REQUEST = '[TERMINAL] locker insert or update - request';

export class LockerInsertOrUpdateRequest implements Action {
  readonly type = LOCKER_INSERT_OR_UPDATE_REQUEST;

  constructor(public payload: { locker: Rygiel, successCallback: () => void }) {
  }
}

export const LOCKER_INSERT_OR_UPDATE_SUCCESS = '[TERMINAL] locker insert or update - success';

export class LockerInsertOrUpdateSuccess implements Action {
  readonly type = LOCKER_INSERT_OR_UPDATE_SUCCESS;
}

export const GET_BOLT_PIN_REQUEST = '[TERMINAL] get bolt pin - request';

export class GetBoltPinRequest implements Action {
  readonly type = GET_BOLT_PIN_REQUEST;

  constructor(public payload: { terminalId: number }) {
  }
}

export const GET_BOLT_PIN_SUCCESS = '[TERMINAL] get bolt pin - success';

export class GetBoltPinSuccess implements Action {
  readonly type = GET_BOLT_PIN_SUCCESS;

  constructor(public payload: { data: Terminal }) {
  }
}

export type TerminalsActions =
  LoadTerminalsRequest
  | LoadTerminalsSuccess
  | NewTerminalRequest
  | NewTerminalSuccess
  |
  LoadTerminalsTrailsRequest
  | LoadTerminalsTrailsSuccess
  | LoadTerminalsTrucksRequest
  | LoadTerminalsTrucksSuccess
  |
  NewTerminalTrailRequest
  | NewTerminalTrailSuccess
  | NewTerminalTruckRequest
  | NewTerminalTruckSuccess
  |
  LoadTerminalSoftwareListRequest
  | LoadTerminalSoftwareListSuccess
  | NewTerminalSoftwareRequest
  | UpdateTerminalSoftwareRequest
  |
  UpgradeSoftwareRequest
  | UpgradeSoftwareSuccess
  | LoadTerminalStatusesRequest
  | LoadTerminalStatusesSuccess
  |
  LoadFramesRequest
  | LoadFramesSuccess
  | UpdateFrameVersionRequest
  | UpdateFrameVersionSuccess
  | UpdateFrameFieldRequest
  | UpdateFrameFieldSuccess
  | LoadFramesForSoftwareRequest
  | LoadFramesForSoftwareSuccess
  | LoadFrameFieldTypesRequest
  | LoadFrameFieldTypesSuccess
  |
  NewSoftwareFrameRequest
  | NewSoftwareFramesRequest
  | RemoveSoftwareFrameRequest
  |
  LockerOpenRequest
  | LockerOpenSuccess
  | LockerCloseRequest
  | LockerCloseSuccess
  | RequestForFrameRequest
  | RequestForFrameSuccess
  | LockerStatusRequest
  | LockerStatusSuccess
  | LockerInsertOrUpdateRequest
  | LockerInsertOrUpdateSuccess
  |
  LoadSimCardsRequest
  | LoadSimCardsSuccess
  |
  LoadTerminalsSimCardsRequest
  | LoadTerminalSimCardsSuccess
  |
  AssignSimCardToTerminal
  | UnassignSimCardFromTerminal
  |
  GetBoltPinRequest
  | GetBoltPinSuccess
  ;
