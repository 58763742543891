import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss']
})
export class TextFieldComponent {
  @Input() label: string;
  @Input() layout = 'row';
  @Input() value: string | number;
  @Input() showNoValue = true;
  @Input() noValueLabel = 'Not defined';
  @Input() prefixIcon: string;
  @Input() suffixIcon: string;

}
