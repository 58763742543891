import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../app.reducer';
import { map, take } from 'rxjs/operators';

export function usernameValidator(store: Store<fromRoot.State>): AsyncValidatorFn {
  return (control: AbstractControl) => {
    return store.select(fromRoot.selectors.user.getUserByUsername(control.value))
      .pipe(
        take(1),
        map((user) => user ? {usernameAlreadyExists: true} : null)
      );
  };
}
