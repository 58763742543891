import { FormControl, Validators } from '@angular/forms';
import { DefaultForm } from '../default-form';

export class TransportSecurityForm extends DefaultForm<any> {
  constructor(controls?: any | null) {
    super({
      'id': new FormControl(null, Validators.required),
      'geofencing': new FormControl(false, Validators.required),
      'geofMetry': new FormControl(null),
      'zezwRuszenia': new FormControl(false),
      'wlascicielTowaru': new FormControl(null),
      'cm': new FormControl(null),
    });
  }
}
