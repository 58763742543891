import { IDriverReport } from '../interfaces';
import { DriverReportStatusEnum } from '../enums/driver-report-status.enum';

export class DriverReportsStatusHelper {
  static isDriverReportNew(driverReport: IDriverReport): boolean {
    return driverReport?.statusZk.id === DriverReportStatusEnum.NEW;
  }

  static isDriverReportReceived(driverReport: IDriverReport): boolean {
    return driverReport?.statusZk.id === DriverReportStatusEnum.RECEIVED;
  }

  static isDriverReportFinished(driverReport: IDriverReport): boolean {
    return driverReport?.statusZk.id === DriverReportStatusEnum.FINISHED;
  }

  static isDriverReportAssignedToTicket(driverReport: IDriverReport): boolean {
    return driverReport?.statusZk.id === DriverReportStatusEnum.ASSIGNED_TO_TICKET;
  }

  static isDriverReportCanceled(driverReport: IDriverReport): boolean {
    return driverReport?.statusZk.id === DriverReportStatusEnum.CANCELLED;
  }
}
