import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import * as fromUi from '../ngrx/ui.reducer';
import { GenericNavItem, RightDrawer } from '../models/dto';
import { SideLinkActionData } from '../models/helpers';
import { ViewMode } from '../helpers/enum';

interface Selectors {
  getIsLoading: MemoizedSelector<any, boolean>;
  getSideMenuLinks: MemoizedSelector<any, GenericNavItem[]>;
  getSideMenuLinksStatus: MemoizedSelector<any, boolean>;
  getTopLeftMenuLinks: MemoizedSelector<any, GenericNavItem[]>;
  getTopRightMenuLinks: MemoizedSelector<any, GenericNavItem[]>;
  getLastSideLinkAction: MemoizedSelector<any, SideLinkActionData>;
  getWindowResizeEvent: MemoizedSelector<any, string>;
  getMapSearchEventsNumber: MemoizedSelector<any, number>;

  getRightDrawerInfo: MemoizedSelector<any, RightDrawer>;
  isLeftDrawerOpened: MemoizedSelector<any, boolean>;
  isProgressBarVisible: MemoizedSelector<any, boolean>;
  getAlertViewMode: MemoizedSelector<any, ViewMode>;
  getTransportViewMode: MemoizedSelector<any, ViewMode>;
  getTicketViewMode: MemoizedSelector<any, ViewMode>;
}

export class UiSelectors {
  static _state = createFeatureSelector<fromUi.State>('ui');
  static _selectors: Selectors;

  static getSelectors() {
    this._selectors = {
      getIsLoading: createSelector(this._state, fromUi.getIsLoading),
      getSideMenuLinks: createSelector(this._state, fromUi.getSideLinks),
      getSideMenuLinksStatus: createSelector(this._state, fromUi.getSideLinksStatus),
      getTopLeftMenuLinks: createSelector(this._state, fromUi.getTopLeftLinks),
      getTopRightMenuLinks: createSelector(this._state, fromUi.getTopRightLinks),
      getLastSideLinkAction: createSelector(this._state, fromUi.getSideLinkAction),
      getWindowResizeEvent: createSelector(this._state, fromUi.getResizeWindowStatus),
      getMapSearchEventsNumber: createSelector(this._state, fromUi.getMapSearchRouteEvents),

      getRightDrawerInfo: createSelector(this._state, fromUi.getRightDrawer),
      isLeftDrawerOpened: createSelector(this._state, fromUi.isLeftDrawerOpened),
      isProgressBarVisible: createSelector(this._state, fromUi.isProgressBarVisible),
      getAlertViewMode: createSelector(this._state, fromUi.getAlertViewMode),
      getTransportViewMode: createSelector(this._state, fromUi.getTransportViewMode),
      getTicketViewMode: createSelector(this._state, fromUi.getTicketViewMode),
    };

    return this._selectors;
  }
}
