import { createAction, props } from '@ngrx/store';
import { TerminalLocationSat, TerminalLocationShort } from '../models/helpers';
import { DraggedPoint, Filtry, GenericPoint, MapPoint, TermianlSearchTracksParams } from '../models/map';
import { LocationData } from '../models/map/geocode';
import { ReverseGeocodeResponse } from '../models/map/reverseGeocode';
import { Coords, CoordsNamedPL } from '../modules/shared/interfaces';
import { Terminal } from '../models/dto/terminale';

export const MapPointDragged = createAction(
  '[MAP] point dragged',
  props<{ point: DraggedPoint }>()
);

export const MapPointRemoved = createAction(
  '[MAP] point removed',
  props<{ point: MapPoint }>()
);

export const AdhocPoints = createAction(
  '[MAP] show adhoc points on map',
  props<{ points: GenericPoint[] }>()
);

export const AdhocLines = createAction(
  '[MAP] show adhoc lines on map',
  props<{ lines: Coords[][] }>()
);

export const ViewerExtraData = createAction(
  '[MAP] extra data to for map viewer',
  props<{ extraData: any }>()
);

export const CenterMap = createAction(
  '[MAP] center map to give point',
  props<{ point?: CoordsNamedPL, zoom?: number }>()
);

export const ReverseGeocodeSuccess = createAction(
  '[MAP] reverse geocode - success',
  props<{ data?: ReverseGeocodeResponse }>()
);

export const SearchRouteForWayPointsRequest = createAction(
  '[MAP] search route for way points - request',
  props<{ data: (LocationData | ReverseGeocodeResponse)[] }>()
);

export const SearchRouteForWayPointsSuccess = createAction(
  '[MAP] search route for way points - success',
  props<{ data?: any }>()
);

export const NewMapFilters = createAction(
  '[MAP] New filters',
  props<{ filters: Filtry }>()
);

export const GetTerminalLocPointsRequest = createAction(
  '[MAP] Get terminal position - request',
  props<{ searchParams: TermianlSearchTracksParams }>()
);

export const GetTerminalLocPointsSuccess = createAction(
  '[MAP] Get terminal position - success',
  props<{
    points: TerminalLocationShort[][],
    satPoints: TerminalLocationSat[][],
    searchParams?: TermianlSearchTracksParams
  }>()
);

export const LoadTerminalsLastPositionRequest = createAction('[MAP] Load company last position - request');

export const LoadTerminalsLastPositionSuccess = createAction(
  '[MAP] Load company last position - success',
  props<{ terminalPoints: TerminalLocationShort[] }>()
);

export const MarkTermianls = createAction(
  '[MAP] terminal rocket clicked',
  props<{ mapPointData: Terminal[] }>()
);

export const ShowEventsOnMap = createAction(
  '[MAP] show events on map',
  props<{ mapPoints: TerminalLocationShort[] }>()
);
