export const Api = {
  liveData: {
    info: '/api/pinfo'
  },
  Admin: {
    proxy_routing: '/api/routing_api',
    proxy_routing_reload: '/proxy/refresh_routes',
    proxy_privs_reload: '/proxy/refresh_permissions',
    uprawnienie: '/api/uprawnienie'
  },
  Action: {
    podjete_akcje: '/api/podjete_akcje',
  },
  Alert: {
    alarmy: '/api/alarmy',
    rodzaj_alarmu: '/api/rodzaj_alarmu',
    dyspozytor: '/dyspozytor',
    przypisz: '/przypisz',
    usun_przypisanie: '/usun_przypisanie',
    alarmy_dyspozytor: '/api/alarmy/dyspozytor',
    alarmy_obslugujacy: '/api/alarmy/obslugujacy',
    alarmy_wszyscy_dysp: '/api/alarmy/wszyscy_dysp',
    obs_dysp_przypisz: '/obs_dysp/przypisz',
    obs_dysp_usun_przypisanie: '/obs_dysp/usun_przypisanie',
    zakoncz: '/zakoncz',
    statusy_alarmu: '/api/status_alarmu'
  },
  Ticket: {
    tickety: '/api/tickety',
    akcje: '/api/akcje',
    utworz_dla_alarmu: '/utworz_dla_alarmu',
    podjete_akcje: '/podjete_akcje',
  },
  DriverReports: {
    zgloszenia: '/api/zgloszenia',
    zgloszenia_kod_otwarcia: '/api/zgloszenia_kod_otwarcia',
    statuses: '/api/status_zk'
  },
  Transit: {
    przejazd: '/api/przejazd',
    przejazdPoId: '/api/przejazd_info',
    przejazd_edycja: '/api/przejazd_edycja/',
    przejazd_kierowcy_odczyt: '/api/przejazd/kierowcy',
    kierowca_przejazd: '/api/przejazd/kierowcaPrzejazd',
    usun_kierowcow_z_przejazdu: '/api/przejazd/usunKierowcow',
    zlecenie_nadzoru_przeslij: '/api/przejazd/zlecenieNadz/przeslij',

    dostepneParametry: '/api/przejazdy/dostepne_parametry',
    ustawioneParametry: '/api/przejazdy/ustawione_parametry',
    dodanieParametru: '/api/przejazdy/dodaj_parametr',
    usuwanieParametru: '/api/przejazdy/usun_parametr/',
    parkingi: '/api/przejazdy/parkingi/',

    wlasciciel_towaru: '/api/wlasciciel_towaru',
    trasa: '/api/trasa',
    trasa_firmy: '/api/trasa_firmy',
    szablon_trasy: '/api/szablon_trasy',
    zapisz_trase_z_szablonu_edycja: '/api/zapisz_trase_z_szablonu',
    zestawy_dostepne_odczyt: '/api/zestawy/dostepne',
    zestawy_wszystkie_odczyt: '/api/zestawy/wszystkie',
    cm: '/api/cm',
    cmMonitorowaneTerminale: '/api/cmterminal',

    generate_report: '/api/raporty/xlsx',
  },
  Vehicle: {
    jsonType: 'json-ld',
    samochody: '/api/samochod',
    samochodTablet: '/api/tablet_samochod',
    kierowcaTablet: '/api/tablet_kierowca',
    naczepy: '/api/naczepa',
    naczepy_v2: '/api/naczepa_v2',
    model: '/api/model',
    marka: '/api/marka',
    typ_naczepy: '/api/typ_naczepy',
    typ_zapiecia: '/api/typ_zapiecia',
    rodzaj_pojazdu: '/api/rodzaj_pojazdu',
    powod_nieczynn: '/api/powod_nieczynnosci',
    stan_pracy: '/api/stan_pracy',
    status_pojazdu: '/api/status_pojazdu',
  },
  Auth: {
    jsonType: 'json-ld',
    logowanie: '/api/login',
    odsw_tokena: '/api/refresh',
  },
  User: {
    jsonType: 'json-ld',
    uzytkownik: '/api/uzytkownik',
    uzytkownik_odblokuj: '/api/odblokuj_uzytkownika',
    uzytkownik_rejestracja: '/api/rejestracja_uzytkownika',
    uprawnienia_uzytkownika: '/api/uprawnienia_uzytkownika',
    role_uzytkownikow: '/api/role_uzytkownikow',
    pin: '/api/pin_check',
    hist_logowan: '/api/logowanie_uzytkownika',
    password_change: '/api/zmiana_hasla',
  },
  Driver: {
    kierowca: '/api/kierowca',
    kierowcaKategoriePJ: '/api/prawo_jazdy_kierowcy',
    kategoriePJ: '/api/kategorie_prawa_jazdy',
  },
  Company: {
    jsonType: 'json-ld',
    firma: '/api/firma',
    typ_firmy: '/api/typ_firmy',
  },
  TransSets: {
    jsonType: 'json',
    drivers: '/drivers',

    sets: '/api/zestaw',
    fullSets: '/relations',
    inactiveSets: '/noactive',

    transportTrucks: '/transtotruck',
    availTrucks: '/freetrucks',

    transportTrails: '/transtotrailer',
    availTrailers: '/freetrailers',

    shippingPt: '/forwardstg',
    monitoringCtr: '/monitorctr',
    punktySpedycyjne: '/api/punkt_spedycyjny',
    nowaTrasa: '/api/nowa_trasa',
  },
  Terminale: {
    jsonType: 'json',

    terminal: '/api/terminal',
    StatusTerminala: '/api/status_terminala',
    Rygiel: '/api/rygiel',
    RygielStatus: '/api/status_rygla',

    Graber: '/api/graber',
    OprogramowanieTerminala: '/api/oprogramowanie_terminala',
    WersjaOprogramowanie: '/api/wersja_ramki_opr',
    AktualizacjeTerminala: '/api/terminal_aktualizacje',
    OprogramowanieTerminalaUpgrade: '/api/oprogramowanie_terminala_aktualizacja',
    SamochodTerminal: '/api/samochod_terminal',
    wyposazenieTerminala: '/api/konfiguracja_terminala/wyposazenie_odczyt',
    TerminalNaczepa: '/api/terminal_naczepa',
    KodOtwarcia: '/api/bolt_pin',

    Karta: '/api/karta',
    KartaTerminal: '/api/karta_terminal'
  },
  Ramki: {
    Ramka: '/api/ramka',
    RamkaGui: '/api/ramka_gui',
    WersjaRamek: '/api/wersja_ramki',
    WersjaRamkiNowa: '/api/wstaw_wersja_ramki',
    PoleRamek: '/api/pole_ramki',
    TypyDanychGui: '/api/typ_danych_gui'
  },
  Lokalizacja: {
    pozycja: '/api/lokalizacja'
  },
  Administracja: {
    routing_api: '/api/routing_api',
  },
  TrakerKomunikacja: {
    rygielOtworz: '/api/otworz_rygiel',
    rygielZamknij: '/api/zamknij_rygiel',
    zadanieRamki: '/api/zadanie_ramki'
  },
  Pakiet: {
    info: '/api/inf',
    awar: '/api/awr',
    cn: '/api/cn',
    cs: '/api/cs',
    rygiel: '/api/ryg',
    sat: '/api/sat',
    satS: '/api/sat_s',
    satJ: '/api/sat_j',
    satTerminal: '/api/sat_terminal',
    satSTerminal: '/api/sat_s_terminal',
    satJTerminal: '/api/sat_j_terminal',
  }
};
