import { Directive } from '@angular/core';
import { CommonDirective } from '../common.directive';
import { UserRoles } from '../../../../helpers/enum';

@Directive({
  selector: '[appHasRoleCm]'
})
export class HasRoleCmDirective extends CommonDirective {

  ROLE = UserRoles.CM;
  type = 'roles';
}
