<div fxFlex fxLayout="column" fxLayoutGap="16px" class="main-body">
  <div class="tools">
    <app-input *ngIf="markedTerminalList.length < 1" label="Type to search" name="map-transport-search"
               suffixIcon="search" [control]="textSearch"></app-input>
    <div *ngIf="markedTerminalList.length > 0" fxLayout="column" fxLayoutGap="8px">
      <span>Filtered:</span>
      <div fxLayout="row" fxLayoutGap="8px">
        <app-terminal-short-info [terminal]="terminal" *ngFor="let terminal of markedTerminalList"></app-terminal-short-info>
        <button mat-raised-button color="accent" (click)="clearMarkedTerminals()">Clear</button>
      </div>
    </div>
  </div>
  <div class="list" fxFlex fxLayout="column" fxLayoutGap="8px">
    <cdk-virtual-scroll-viewport itemSize="130" class="virtual-scroll" minBufferPx="190" maxBufferPx="380">
      <div fxFlex fxLayout="column" fxLayoutGap="8px">
        <app-transport-tile *cdkVirtualFor="let transport of transportList"
                            [transport]="transport" [markedTerminalList]="markedTerminalList">
        </app-transport-tile>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>
</div>
