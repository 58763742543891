import { Component, Input } from '@angular/core';
import { ITransportEntityExtended } from '../../../interfaces/transport-entity';

@Component({
  selector: 'app-transport-monitoring-parameters',
  templateUrl: './transport-monitoring-parameters.component.html',
  styleUrls: ['./transport-monitoring-parameters.component.scss']
})
export class TransportMonitoringParametersComponent {
  @Input() transport: ITransportEntityExtended;
}
