<div class="table-container mat-elevation-z8">

  <!-- <table mat-table [dataSource]="companiesInfo" matSort class="mat-elevation-z8" matSortActive="id"matSortDirection="desc"> -->
  <table mat-table [dataSource]="userLogs" matSort class="mat-elevation-z8">

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>
    <ng-container matColumnDef="czas">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Kiedy </th>
      <td mat-cell *matCellDef="let element">
        {{element.czas | date:df.PIPE_DATETIME_LONGER_LABEL}}
      </td>
    </ng-container>
    <ng-container matColumnDef="adres_ip">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> IP </th>
      <td mat-cell *matCellDef="let element">
        {{element._adres_ip.adres_ip}}
      </td>
    </ng-container>
    <ng-container matColumnDef="czy_poprawne">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Czy poprawne </th>

      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.poprawne; else elseTemplate">
          {{element.poprawne | yesNo}}
        </ng-container>
        <ng-template #elseTemplate>
          <b>{{element.poprawne | yesNo}}, kod: {{element.kodBledu}} <i>Brak</i></b>
        </ng-template>
      </td>
    </ng-container>
    <ng-container matColumnDef="user_agent">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Agent </th>
      <td mat-cell *matCellDef="let element">
        {{ element.user_agent }}
      </td>
    </ng-container>
    <ng-container matColumnDef="idKomp">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Id urządzenia </th>
      <td mat-cell *matCellDef="let element">
        {{ element.id_komp }}
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator appPaginationPageSize
                 [length]="userLogs.data.length"
                 showFirstLastButtons>
  </mat-paginator>

</div>
