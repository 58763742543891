<div fxLayout="row">
  <mat-form-field class="filter-form-field">
    <mat-label>Type to search...</mat-label>
    <input matInput [formControl]="searchControl" name="fdw-search-ticket">
    <mat-icon matSuffix class="filter-icon">search</mat-icon>
  </mat-form-field>
  <mat-form-field class="filter-form-field pl-2">
    <mat-label>Filter by status</mat-label>
    <mat-select [formControl]="statusFilterControl" class="select-input">
      <mat-option value="new">New</mat-option>
      <mat-option value="assigned">Assigned</mat-option>
      <mat-option value="finished">Finished</mat-option>
      <mat-option value="all">All</mat-option>
    </mat-select>
  </mat-form-field>

<!--  todo: odkomentować, jesli w ticketach będzie potrzebny widok 'kafelków'-->
<!--  <div class="view-switcher" fxFlex="1 0 auto">-->
<!--      <span class="pointer" (click)="tileView()">-->
<!--        <mat-icon class="apps {{viewMode$ | async}}">apps</mat-icon>-->
<!--      </span>-->
<!--    <span class="pointer" (click)="tableView()">-->
<!--        <mat-icon class="menu {{viewMode$ | async}}">menu</mat-icon>-->
<!--      </span>-->
<!--  </div>-->

</div>
