<ng-container *ngIf="shippingPoints$ | async as shippingPoints">
  <input
    matInput
    [matAutocomplete]="auto"
    [ngModel]="value"
    [attr.aria-labelledby]="_formField.getLabelId()"
    [disabled]="disabled"
    (focus)="focused = true"
    (blur)="focused = false"
    #searchInput
  />

  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="searchBase.displayFn"
    (optionSelected)="locationPicked($event)"
  >
    <mat-option
      *ngFor="let shippingPoint of shippingPoints"
      [value]="shippingPoint"
    >
      {{ shippingPoint.nazwa_kod }}: {{ shippingPoint.nazwa }}
    </mat-option>
  </mat-autocomplete>
</ng-container>
