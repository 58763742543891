import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../app.reducer';
import { DateFormat } from '../../../../../helpers/enum';
import { IZdarzeniePojazduDTO } from '../../../../../models/dto/alert/zdarzenie-pojazdu';
import { IZgloszenieKierowcyDTO } from '../../../../../models/dto/alert/zgloszenie-kierowcy';
import * as MAP from '../../../../../ngrx/map.actions';
import { LocationService } from '../../../../map/services/location.service';
import { CoordsNamedPL } from '../../../../shared/interfaces';

@Component({
  selector: 'app-event-tile',
  templateUrl: './event-tile.component.html',
  styleUrls: ['./event-tile.component.scss'],
})
export class EventTileComponent implements OnInit {
  @Input() event: IZdarzeniePojazduDTO | IZgloszenieKierowcyDTO;
  @Input() clickOption = '';
  @Output() clickAction = new EventEmitter<boolean>();

  type = '';
  dt = DateFormat;

  tooltip = '';

  constructor(private store: Store<fromRoot.State>) {}

  getIconType() {
    if (this.event['czasDo'] !== undefined) {
      return 'directions_car';
    }

    return 'person';
  }

  ngOnInit(): void {
    if (this.event['czasDo'] !== undefined) {
      this.type = 'vehicle-event';
    } else {
      this.type = 'driver-event';
    }

    if (this.clickOption === 'map-preview') {
      this.tooltip = 'Show on map';
    }
  }

  getInfo(kind = 'type'): string {
    let out = 'Not recognized';
    if (
      this.event['ocenaRyzyka'] !== undefined &&
      this.type === 'vehicle-event'
    ) {
      out = this.getVehicleInfo(kind, out);
    } else {
      out = this.getDriverInfo(kind, out);
    }

    return out;
  }

  shouldShowOnMap(): boolean {
    return (
      this.clickOption === 'map-preview' &&
      LocationService.coordsValidator(this.event as IZdarzeniePojazduDTO)
    );
  }

  centerToEvent(): void {
    this.store.dispatch(
      MAP.CenterMap({ point: this.event as CoordsNamedPL, zoom: 11 })
    );
  }

  private getDriverInfo(kind: string, fallbackValue: string): string {
    switch (kind) {
      case 'name':
        return (
          (this.event as IZgloszenieKierowcyDTO).kierowca.uzytkownik.nazwisko +
          ' ' +
          (this.event as IZgloszenieKierowcyDTO).kierowca.uzytkownik.imie
        );

      case 'type':
        return 'Driver | ' + (this.event as IZgloszenieKierowcyDTO).typZgl.typ;
    }

    return fallbackValue;
  }

  private getVehicleInfo(kind: string, def) {
    switch (kind) {
      case 'name':
        if ((this.event as IZdarzeniePojazduDTO).samochod) {
          return (this.event as IZdarzeniePojazduDTO).samochod.nrRej;
        }
        if ((this.event as IZdarzeniePojazduDTO).naczepa) {
          return (this.event as IZdarzeniePojazduDTO).naczepa.nrRej;
        }
        if ((this.event as IZdarzeniePojazduDTO).terminal) {
          return (this.event as IZdarzeniePojazduDTO).terminal.uwagi;
        }
        break;
      case 'type':
        if ((this.event as IZdarzeniePojazduDTO).samochod) {
          return 'Truck';
        }
        if ((this.event as IZdarzeniePojazduDTO).naczepa) {
          return 'Trailer';
        }
        if ((this.event as IZdarzeniePojazduDTO).terminal) {
          return 'Terminal';
        }
        break;
    }

    return def;
  }
}
