export enum MapViewRoute {
  ALERT = 'alert',

  TRANSPORT_NEW_ROUTE = 'transport-new-route',
  TRANSPORT_ROUTE = 'transport-route',
  TRANSPORT_PARKING_LIST = 'transport-parking-lots',
  ALARM_TEST = 'transport-alarm-test',
  TRANSPORT_PROGRESS = 'transport-progress',

  PARKING = 'parking',
  PARKING_LIST = 'parking-lots',

  SHIPPING_POINT_LIST = 'shipping-points',
  SHIPPING_POINT = 'shipping-point',
}
