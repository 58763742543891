import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

@Component({
  selector: 'battery-level',
  templateUrl: './battery-level.component.html',
  styleUrls: ['./battery-level.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BatteryLevelComponent {
  @HostBinding('style.--percentage')
  @Input()
  percentage = 0;

  @Input() showPercentage = false;

  BATTERY_PERCENT_LOW = 30;
  BATTERY_PERCENT_VERY_LOW = 20;

  constructor() {}
}
