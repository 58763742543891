<transit-test-result-frame>
  <p class="results-title">{{ resultsTitle }}</p>
  <ng-container [ngSwitch]="getTextStatus(status)">
    <span
      *ngSwitchCase="textStatusEnum.NOT_VERIFIED_PROCESSING"
      class="status-text verifing"
    >
      <mat-spinner [diameter]="16" class="loading-spinner"></mat-spinner>
      Not verified yet. Verifying.
    </span>

    <span *ngSwitchCase="textStatusEnum.NO_DEVICE" class="status-text">
      <mat-icon class="status-icon">developer_board_off</mat-icon>
      No device.
    </span>

    <span
      *ngSwitchCase="textStatusEnum.DEVICE_BROKEN"
      class="status-text problem"
    >
      <mat-icon class="status-icon problem-icon">sync_problem</mat-icon>
      Device is non functional.
    </span>

    <ng-container *ngSwitchCase="textStatusEnum.DEVICE_OK_PROCESSING_LOCATION">
      <span class="status-text verifing">
        <mat-icon class="status-icon">check_circle</mat-icon>
        <mat-spinner [diameter]="16" class="loading-spinner"></mat-spinner>

        Verified. Waiting for device location.
      </span>

      <ng-container
        [ngTemplateOutlet]="signalStrength"
        [ngTemplateOutletContext]="{ $implicit: status.signalStrength }"
      >
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="textStatusEnum.DEVICE_OK_NO_LOCATION">
      <span class="status-text">
        <mat-icon class="status-icon">check_circle</mat-icon>
        <mat-icon class="status-icon">location_off</mat-icon>
        Verified. Device is ok but location couldn't be verified.
      </span>

      <ng-container
        [ngTemplateOutlet]="signalStrength"
        [ngTemplateOutletContext]="{ $implicit: status.signalStrength }"
      >
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="textStatusEnum.VERIFIED_WORKS_WELL">
      <span class="status-text works-well">
        <mat-icon class="status-icon works-well-icon">check_circle</mat-icon>
        <mat-icon class="status-icon works-well-icon">where_to_vote</mat-icon>
        Verified. Works well.
      </span>

      <span class="status-text">
        <mat-icon class="status-icon">schedule</mat-icon>

        <span class="time">
          Last frame time:
          <span class="time-value">
            {{ status.satJFrame.czas | date: dateFormat.PIPE_LONG_24H }}
          </span>
        </span>
      </span>

      <ng-container
        [ngTemplateOutlet]="signalStrength"
        [ngTemplateOutletContext]="{ $implicit: status.signalStrength }"
      >
      </ng-container>
    </ng-container>
  </ng-container>
</transit-test-result-frame>

<ng-template #signalStrength let-strength>
  <span class="status-text">
    <sat-signal-strength
      [signalStrength]="strength"
      class="sat-strength"
    ></sat-signal-strength>
    Signal strength
  </span>
</ng-template>
