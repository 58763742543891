import { Filter } from './filter';
import { Ticket } from '../../interfaces';
import { Strings } from '../../../../helpers';

export class SearchTextFilter extends Filter {
  constructor(private readonly searchText) {
    super();
  }

  get name(): string {
    return 'search-text';
  }

  private static createSearchableStringFrom(ticket: Ticket): string {
    let searchableString = (ticket.uwagi ?? '') + (ticket.nazwa ?? '') + ticket.id;
    if (ticket.terminal) {
      searchableString += ticket.terminal.id;
      searchableString += ticket.terminal.uwagi ?? '';
    }
    if (ticket.samochod) {
      searchableString += ticket.samochod.nrRej;
    }
    if (ticket.naczepa) {
      searchableString += ticket.naczepa.nrRej;
    }
    if (ticket.obslugujacy) {
      searchableString += ticket.obslugujacy.imie + ticket.obslugujacy.nazwisko;
    }

    return searchableString;
  }

  isMatching(ticket: Ticket): boolean {
    if (this.searchText.length > 0) {
      return Strings.searchTextIgnoreAccents(this.searchText, SearchTextFilter.createSearchableStringFrom(ticket)) !== -1;
    }
    return true;
  }
}
