import { Directive, Input } from '@angular/core';
import { CommonDirective } from '../common.directive';
import { isNaN } from 'lodash';
import { Privs } from '../../../../helpers/enum';
import { environment } from '../../../../../environments/environment';

@Directive({
  selector: '[appHasPrivileges]'
})
export class HasPrivilegesDirective extends CommonDirective {
  @Input()
  set appHasPrivileges(privileges: string | Privs[]) {
    if (typeof privileges === 'string') {
      this.PRIVILEGES = privileges.split(',')
        .map(r => {
          const val = r.trim();
          if (isNaN(val)) {
            return Privs[val] !== undefined ? Privs[val] : 0;
          }
          return +val;
        });
      return;
    }
    this.PRIVILEGES = privileges
      .map(r => {
        return +r;
      });
  }

  /**
   * Check if user contains requested privilege
   * @param privileges required privileges
   */
  public static check(privileges: Privs[]): boolean {
    return CommonDirective.checkPermission(privileges);
  }

  /**
   * Check if user contains requested privileges and fire callback if yes
   * @param privileges list of required privileges
   * @param callback action to be executed if user has requested privileges
   * @param actionName name of the action, some context
   */
  public static checkAndFire(privileges: Privs[], callback: () => void, actionName?: string): void {
    if (!callback) {
      return;
    }

    CommonDirective.waitAndTest(() => {
      if (!HasPrivilegesDirective.check(privileges)) {
        if (!environment.production) {
          console.error('Brak uprawnień by wykonać to polecenie.', actionName, CommonDirective.privilegeMap.allPrivileges, privileges);
        }
        return;
      }

      if (!environment.production) {
        // tslint:disable-next-line:no-console
        console.debug('Wykonuje polecenie.', actionName, CommonDirective.userTokenDetails.perm, privileges);
      }

      callback();
    });
  }
}
