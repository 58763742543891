import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ITransportAssignedDriverExtended } from '../../../interfaces/transport-entity';

@Component({
  selector: 'app-transport-drivers',
  templateUrl: './transport-drivers.component.html',
  styleUrls: ['./transport-drivers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransportDriversComponent {
  @Input() kierowcaPrzejazdList: ITransportAssignedDriverExtended[] = [];
}
