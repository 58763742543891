import { ActionReducer, ActionReducerMap, createFeatureSelector, createSelector, MetaReducer } from '@ngrx/store';

import * as AUTH from './ngrx/auth.actions';
import * as UI from './ngrx/ui.actions';

import * as fromUi from './ngrx/ui.reducer';
import * as fromVehicle from './ngrx/vehicle.reducer';
import * as fromTerminal from './ngrx/terminal.reducer';
import * as fromUser from './ngrx/user.reducer';
import * as fromAuth from './ngrx/auth.reducer';
import * as fromAdmin from './ngrx/admin.reducer';
import * as fromFrame from './ngrx/frame.reducer';
import * as fromMap from './ngrx/map.reducer';
import * as fromGeneral from './ngrx/general.reducer';
import * as fromAlert from './modules/alerts/ngrx/alert.reducer';
import * as fromTransits from './modules/transports/ngrx/transport.reducer';
import * as fromDrivers from './modules/drivers/ngrx/driver.reducer';
import {
  AdminSelectors,
  AuthSelectors,
  DeviceSelectors,
  FrameSelectors,
  MapSelectors,
  TranssetSelectors,
  UiSelectors,
  UserSelectors,
  VehicleSelectors,
} from './ngrx-selectors';
import { environment } from '../environments/environment';

export interface State {
  ui: fromUi.State;
  auth: fromAuth.State;
  admin: fromAdmin.State;
  frame: fromFrame.State;
  vehicle: fromVehicle.State;
  device: fromTerminal.State;
  map: fromMap.State;
  user: fromUser.State;
  alert: fromAlert.State;
  transit: fromTransits.State;
  // transportSet: fromTransset.State;
  general: fromGeneral.State;
  driver: fromDrivers.State;
}

export const reducers: ActionReducerMap<State> = {
  ui: fromUi.reducer,
  auth: fromAuth.authReducer,
  admin: fromAdmin.adminReducer,
  frame: fromFrame.reducer,
  vehicle: fromVehicle.vehicleReducer,
  device: fromTerminal.terminalReducer,
  map: fromMap.reducer,
  user: fromUser.userReducer,
  alert: fromAlert.reducer,
  transit: fromTransits.reducer,
  // transportSet: fromTransset.reducer,
  general: fromGeneral.reducer,
  driver: fromDrivers.reducer
};

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    if (!environment.production) {
      console.log('myngrx', action, state);
    }

    return reducer(state, action);
  };
}

const notGuardedActions: string[] = [
  UI.switchRightDrawer.type,
  UI.switchLeftDrawer.type,
  UI.resizeWindow.type,
  UI.showUserMessage.type,

  AUTH.LOGIN_REQUEST,
  AUTH.BROWSER_NOT_CONFIRMED,
  AUTH.PIN_REQUEST,
  AUTH.PIN_SUCCESS,
  AUTH.PIN_FAILURE,
  AUTH.BROWSER_CONFIRMED,
  AUTH.LOGIN_SUCCESS_RESTORED,
  AUTH.LOGIN_SUCCESS,
  AUTH.LOGIN_FAILURE,
];

export function clearState(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state: State, action) {
    if (action.type === AUTH.LOGOUT_SUCCESS) {
      const {browserId, isBrowserConfirmed} = state.auth;
      state = {
        ui: fromUi.initialState,
        auth: {...fromAuth.initialState, browserId, isBrowserConfirmed},
        admin: fromAdmin.initialState,
        frame: fromFrame.initialState,
        vehicle: fromVehicle.initialState,
        device: fromTerminal.initialState,
        map: fromMap.initialState,
        user: fromUser.initialState,
        alert: fromAlert.initialState,
        transit: fromTransits.initialState,
        // transportSet: fromTransset.reducer,
        general: fromGeneral.initialState,
        driver: fromDrivers.initialState
      };
    } else if (
      state !== undefined
      && !state.auth?.isAuthenticated
      && !notGuardedActions.includes(action.type)
      && !action.type.includes('@ngrx')
    ) {
      if (!environment.production) {
        console.log('action dropped: ', action.type);
      }
      return reducer(state, {type: 'Action forbidden'});
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = environment.production ? [clearState] : [debug, clearState];

export const selectors = {
  auth: AuthSelectors.getSelectors(),
  admin: AdminSelectors.getSelectors(),
  devices: DeviceSelectors.getSelectors(),
  map: MapSelectors.getSelectors(),
  ui: UiSelectors.getSelectors(),
  user: UserSelectors.getSelectors(),
  transset: TranssetSelectors.getSelectors(),
  vehicles: VehicleSelectors.getSelectors(),
  frame: FrameSelectors.getSelectors(),
  driver: fromDrivers.getDrivers
};

export const getGeneralState = createFeatureSelector<fromGeneral.State>('general');
export const getCompanyList = createSelector(getGeneralState, fromGeneral.getCompanyList);
export const getCompanyById = (companyId: number) => createSelector(
  getGeneralState,
  data => data.firmy.find(f => f.id === companyId)
);
