import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { ITruckAndTrailerDetails, ZestawTransportowy } from '../../../transport-set/interfaces';
import { TransportSetDetailsComponent } from '../transport-set-details/transport-set-details.component';
import * as fromRoot from '../../../../app.reducer';
import * as fromTransport from '../../ngrx/transport.reducer';
import * as TRANSPORT from '../../ngrx/transport.actions';
import { RightDrawerService } from '../../../shared/services';
import { VehicleBasic } from '../../../../models/dto/vehicle';
import { ZestawTransportowyBase } from '../../../../models/dto/transportSets';
import { TransportHelper } from '../../../../helpers';
import { TerminalVehicleType } from '../../../../models/dto/terminale';
import { TransportSetsColumnsEnum } from '../../enum/transport-sets-columns.enum';

@Component({
  selector: 'app-ready-transport-sets',
  templateUrl: './ready-transport-sets.component.html',
  styleUrls: ['./ready-transport-sets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadyTransportSetsComponent implements OnInit, OnDestroy {

  transportSetList$: Observable<ZestawTransportowyBase[]>;
  currentlyViewedTransportSet$: Observable<ZestawTransportowy>;
  readyTransportSetsPanelIsOpened$: Observable<boolean>;
  subs = new Subscription();

  details: ITruckAndTrailerDetails;
  vehicleType: typeof TerminalVehicleType = TerminalVehicleType;
  transportSetsColumns: typeof TransportSetsColumnsEnum = TransportSetsColumnsEnum;
  displayedColumns: string[] = Object.values(this.transportSetsColumns);

  constructor(
    private store: Store<fromRoot.State>,
    private rightDrawer: RightDrawerService
  ) {
  }

  ngOnInit(): void {
    this.readyTransportSetsPanelIsOpened$ = this.store.select(fromTransport.getIfReadyTransportSetsPanelIsOpened);
    this.transportSetList$ = this.store.select(fromTransport.getTransportSetsAvailableWithinTimeRange)
      .pipe(
        map(r => {
          return [...r]
            .filter(t => TransportHelper.isValidTransset(t))
            .sort((a, b) => a.id > b.id ? -1 : 1);
        })
      );

    this.currentlyViewedTransportSet$ = this.store.select(fromTransport.getCurrentlyViewedTransportSet);
    this.readyTransportSetsPanelIsOpened$ = this.store.select(fromTransport.getIfReadyTransportSetsPanelIsOpened);
  }

  trackByMethod(index: number, element: any): number {
    return element.id;
  }

  shadowTransportSetRow(transportSet: ZestawTransportowy) {
    this.store.dispatch(TRANSPORT.openTransportSetDetails({set: transportSet}));
  }

  showDetails(transportSet: ZestawTransportowyBase) {
    this.rightDrawer.open(TransportSetDetailsComponent, transportSet);
  }

  selectSet(transportSet: ZestawTransportowyBase) {
    this.store.dispatch(TRANSPORT.toggleReadyTransportSetsPanel({show: false}));
    this.store.dispatch(TRANSPORT.showTransportSetAsChosenNew({zestaw: transportSet}));
  }

  getVehicleInfo(element: VehicleBasic, type = 'info') {
    if (!element) {
      return 'Not assigned';
    }

    switch (type) {
      case 'info':
        return element.model.marka.marka + ': ' + element.nrRej ?? 'No data';

      default:
        return element.uwagi ?? '';
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
