import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { concatMap, delay, retryWhen } from 'rxjs/operators';

export const retryCount = 3;
export const retryWaitMilliSeconds = 250;
export const knownProxyIssues = [
  'java.util.ConcurrentModificationException'.toLowerCase(),
  'com.netflix.zuul.exception.ZuulException: Ex: There is no URI path requested on the server'.toLowerCase()
];

@Injectable()
export class RepeatAfter500ProxyErrorInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request)
      .pipe(
        retryWhen(error =>
          error.pipe(
            concatMap((newError: HttpErrorResponse, count) => {
              if (count <= retryCount && newError.status === 500 && this.isProxyKnownIssue(newError.error)) {
                console.error('Proxy issue:', newError.message, ', repeating:' + (count + 1));
                return of(newError);
              }
              return throwError(newError);
            }),
            delay(retryWaitMilliSeconds))
        )
      );
  }

  private isProxyKnownIssue(error: any): boolean {
    return !!(error['Error message'] && knownProxyIssues.find(msg => error['Error message'].toLowerCase().includes(msg)));
  }
}
