<div style="position: relative">
  <div class="mat-elevation-z8">
    <table mat-table 
          [dataSource]="dataSource_"
          [trackBy]="trackByMethod"
          matSort
          multiTemplateDataRows
          class="mat-elevation-z8">
          
      <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns;">
        <th mat-header-cell *matHeaderCellDef [mat-sort-header]="column" class="alert-types-heading">{{ column }}</th>
        <td mat-cell *matCellDef="let element">
          <p [ngSwitch]="column">
            <span *ngSwitchCase="'Id'" class="id-column">{{ element?.id }}</span>
            <span *ngSwitchCase="'Alert type'">{{ element?.rodzaj }}</span>
            <span *ngSwitchCase="'Risk assessment'" class="risk-column">{{ element?.ocenaR }}</span>
            <span *ngSwitchCase="'Description'">{{ element?.opis }}</span>
            <span *ngSwitchCase="'Actions'" class="action-column">
              <button mat-icon-button [matMenuTriggerFor]="menu" (click)="shadowActiveRow(element)">
                <mat-icon class="more-actions-icon">more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="onDelete(element.id)">
                  <mat-icon>delete</mat-icon>
                  <span class="action-description">Delete alert type</span>
                </button>
                <button mat-menu-item (click)="onEdit(element)">
                  <mat-icon>edit</mat-icon>
                  <span class="action-description">Edit alert type</span>
                </button>
              </mat-menu>
            </span>
          </p>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row class="element-row" *matRowDef="let element; columns: displayedColumns;"
          [ngClass]="{ 'light-gray-background': (currentlyEditedType$ | async)?.id === element.id }">
      </tr>
    </table>
  </div>
</div>
