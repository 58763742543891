import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { MapColorScheme, SvgMapIcon } from 'src/app/helpers/enum';
import { IInfo } from 'src/app/models/frame';
import { GenericPoint } from 'src/app/models/map';
import { MapObjectsHelper } from 'src/app/modules/map/main/here/helpers';
import { LocationService } from 'src/app/modules/map/services/location.service';
import { CoordsNamedPL } from 'src/app/modules/shared/interfaces';
import { SatStatus, TabletStatus } from '.';
import { GsmStatus } from './transit-test-gsm';

export class TransitTestLocationManager {
  private subscription: Subscription;
  private points$: BehaviorSubject<GenericPoint[]> = new BehaviorSubject([]);

  constructor(
    statuses: [
      Observable<GsmStatus>,
      Observable<GsmStatus>,
      Observable<SatStatus>,
      Observable<TabletStatus>
    ]
  ) {
    this.subscription = combineLatest(statuses).subscribe(
      ([truckGsm, trailerGsm, satStatus, tabletStatus]) => {
        const points: GenericPoint[] = [];

        if (truckGsm.upToDate && truckGsm.infoFrame) {
          points.push(
            this.getPoint(
              truckGsm.infoFrame,
              SvgMapIcon.__CUSTOM_ICON,
              'transport-terminal-position-gsm',
              MapColorScheme.LAST_REPORT
            )
          );
        }

        if (trailerGsm.upToDate && trailerGsm.infoFrame) {
          points.push(
            this.getPoint(
              trailerGsm.infoFrame,
              SvgMapIcon.__CUSTOM_ICON,
              'transport-terminal-position-gsm',
              MapColorScheme.LAST_REPORT
            )
          );
        }

        if (satStatus.upToDate && satStatus.satJFrame) {
          points.push(
            this.getPoint(
              satStatus.satJFrame,
              SvgMapIcon.SAT,
              'transport-terminal-position-sat'
            )
          );
        }

        if (tabletStatus.upToDate && tabletStatus.tablet) {
          points.push({
            id: tabletStatus.tablet.identyfikator_aplikacyjny,
            label: 'Tablet: ' + tabletStatus.tablet.opis,
            ...this.getPoint(
              tabletStatus.tablet,
              SvgMapIcon.MOBILE,
              'transport-terminal-position-tablet'
            ),
          });
        }

        this.points$.next(points);
      }
    );
  }

  get points(): Observable<GenericPoint[]> {
    return this.points$.asObservable();
  }

  dispose() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private getPoint(
    status: CoordsNamedPL,
    iconType: SvgMapIcon,
    groupName,
    domIconString?: MapColorScheme
  ): GenericPoint {
    const coords = LocationService.getCoordsNamedPL(status);
    const point: GenericPoint = {
      ...coords,
      iconType,
      groupName,
    } as GenericPoint;

    if (domIconString) {
      point.domIconString = MapObjectsHelper.getIconForMarkerSvg(
        status as IInfo,
        MapColorScheme.LAST_REPORT
      );
    }

    return point;
  }
}
