import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';

import { WlascicielTowaru, ZestawTransportowy, ZestawTransportowyBase } from 'src/app/models/dto/transportSets';
import { IRouteTemplateEntity } from '../interfaces/route-template-entity';
import { ITransportEntity, ITransportEntityExtended } from '../interfaces/transport-entity';
import { IZestawTransportowyRozszerzony } from '../interfaces/transport-set-extended';
import { ITemplateMadeRouteSection } from '../interfaces/template-made-route-section';

import { CompanyMonit, ICompanyRoute, IDriverEntity, Parameter, ParkingInfo, TransitParameter } from '../interfaces';
import * as TRANSPORT from './transport.actions';
import { Terminal } from '../../../models/dto/terminale';
import { TransportProgress } from '../models/transport-progress-state-filter';

export interface State {
  allTransits: ITransportEntity[];
  currentTransit: ITransportEntityExtended;
  selectedTransset: ZestawTransportowyBase;
  currentlyViewedTransportSet: ZestawTransportowy;
  chosenTransportSet: IZestawTransportowyRozszerzony;
  routeTemplateList: IRouteTemplateEntity [];
  transportSetsAvailableWithinTimeRange: ZestawTransportowyBase[];
  driversAvailableWithinTimeRange: IDriverEntity[];
  newlyCreatedTransitId: number;
  readyTransportSetsPanelOpened: boolean;
  savedTemplateMadeRouteSections: ITemplateMadeRouteSection[];
  availMonitParams: Parameter[];
  assignedMonitParams: TransitParameter[];
  companyParkingLots: ParkingInfo[];
  transitRoutes: ICompanyRoute[];
  cargoOwners: WlascicielTowaru[];
  cmList: CompanyMonit[];
  cmTerminalIdList: Terminal[];
  transportFilter: {
    text: string;
    state: TransportProgress;
  };
  processingTransports: Record<number, boolean>;
  isDataLoading: boolean;
}

export const initialState: State = {
  allTransits: [],
  currentTransit: null,
  selectedTransset: null,
  currentlyViewedTransportSet: null,
  chosenTransportSet: null,
  routeTemplateList: [],
  transportSetsAvailableWithinTimeRange: [],
  driversAvailableWithinTimeRange: [],
  newlyCreatedTransitId: null,
  readyTransportSetsPanelOpened: false,
  savedTemplateMadeRouteSections: [],
  availMonitParams: null,
  assignedMonitParams: null,
  companyParkingLots: [],
  transitRoutes: [],
  cargoOwners: [],
  cmList: [],
  cmTerminalIdList: [],
  transportFilter: {
    text: '',
    state: TransportProgress.not_finished
  },
  processingTransports: {},
  isDataLoading: false,
};

export const featureName = 'transit';

const transportReducer = createReducer(
  initialState,
  on(TRANSPORT.loadAvailableMonitParamsRequest, (state) => ({...state, availMonitParams: initialState.availMonitParams})),
  on(TRANSPORT.loadAvailableMonitParamsSuccess, (state, {paramList}) => ({...state, availMonitParams: paramList})),
  on(TRANSPORT.loadAssignedMonitParamsRequest, (state) => ({...state, assignedMonitParams: initialState.assignedMonitParams})),
  on(TRANSPORT.loadAssignedMonitParamsSuccess, (state, {transitParams}) => ({...state, assignedMonitParams: transitParams})),

  on(TRANSPORT.loadAllTransitsRequest, (state) => ({...state, isDataLoading: true})),
  on(TRANSPORT.loadAllTransitsSuccess, (state, {transitList}) => ({...state, allTransits: transitList, isDataLoading: false})),
  on(TRANSPORT.loadAllTransitsFailed, (state) => ({...state, isDataLoading: false})),
  on(TRANSPORT.openTransportSetDetails, (state, {set}) => ({...state, currentlyViewedTransportSet: set})),
  on(TRANSPORT.closeTransportSetDetails, (state) => ({...state, allTransits: null})),
  on(TRANSPORT.loadAvailableCmTerminalListSuccess, (state, {terminalList}) => ({...state, cmTerminalIdList: terminalList})),
  on(TRANSPORT.showTransportSetAsChosen, (state, {trailerMakeAndModel, truckMakeAndModel}) => ({
      ...state,
      chosenTransportSet: Object.assign(
        {
          truckMakeAndModel: truckMakeAndModel,
          trailerMakeAndModel: trailerMakeAndModel,
        },
        state.currentlyViewedTransportSet)
    })
  ),
  on(TRANSPORT.showTransportSetAsNotChosen, (state) => ({...state, chosenTransportSet: null})),
  on(TRANSPORT.loadRouteTemplatesSuccess, (state, {routes}) => ({...state, routeTemplateList: routes})),

  on(TRANSPORT.loadTransportSetsAvailableWithinTimeRangeSuccess, (state, {setList}) => ({
      ...state,
      transportSetsAvailableWithinTimeRange: [...setList].filter(e => e.czyAktywny && !e.czyZlikwidowany && e.trailerSet.length > 0)
    })
  ),
  on(TRANSPORT.loadDriversAvailableWithinTimeRangeSuccess, (state, {drivers}) => ({...state, driversAvailableWithinTimeRange: drivers})),
  on(TRANSPORT.assignDriverToTransitSuccess, (state) => ({...state})),
  on(TRANSPORT.saveTransitAndPassChosenDriversInfoSuccess, (state, {transitResponse}) => ({
    ...state,
    newlyCreatedTransitId: +transitResponse.transit.id
  })),
  on(TRANSPORT.deleteTransitSuccess, (state, {transitId}) => ({
    ...state,
    allTransits: [...state.allTransits].filter(t => t.id !== transitId)
  })),
  on(TRANSPORT.toggleReadyTransportSetsPanel, (state, {show}) => ({...state, readyTransportSetsPanelOpened: show})),

  on(TRANSPORT.loadTransportByIdRequest, (state) => ({...state, currentTransit: initialState.currentTransit})),
  on(TRANSPORT.loadTransitByIdSuccess, (state, {transit}) => ({...state, currentTransit: transit})),
  on(TRANSPORT.showTransportSetAsChosenNew, (state, {zestaw}) => ({...state, selectedTransset: zestaw})),
  on(TRANSPORT.loadCompanyParkingSuccess, (state, {parkingLots}) => ({...state, companyParkingLots: parkingLots})),
  on(TRANSPORT.loadCargoOwnersSuccess, (state, {cargoOwners}) => ({...state, cargoOwners})),
  on(TRANSPORT.removeCargoOwnerSuccess, (state, {id}) => ({...state, cargoOwners: [...state.cargoOwners].filter(r => r.id !== id)})),
  on(TRANSPORT.loadCmListSuccess, (state, {cmList}) => ({...state, cmList})),
  on(TRANSPORT.loadTransitRoutesSuccess, (state, {transitRoutes, transitId}) => {
      return {
        ...state,
        transitRoutes: [
          ...[...state.transitRoutes].filter(r => r._przejazd.id !== transitId),
          ...transitRoutes]
      };
    }
  ),
  on(TRANSPORT.loadTransportParkingSuccess, (state, {transportId, parkingLots}) => {
    const allTransits = [...state.allTransits];
    const index = allTransits.findIndex((t) => t.id === transportId);
    if (index > -1) {
      allTransits[index] = {...allTransits[index], parkingLots};
    }
    const currentTransit = state.currentTransit ? {...state.currentTransit} : null;
    if (currentTransit && currentTransit.id === transportId) {
      currentTransit.parkingLots = parkingLots;
    }
    return {...state, allTransits, currentTransit};
  }),
  on(TRANSPORT.setTransportFilter, (state, transportFilter) => ({...state, transportFilter})),
  on(TRANSPORT.generateReportRequest, (state, {transportId}) => ({
    ...state,
    processingTransports: {
      ...state.processingTransports,
      [transportId]: true
    }
  })),
  on(TRANSPORT.generateReportComplete, (state, {transportId}) => ({
    ...state,
    processingTransports: {
      ...state.processingTransports,
      [transportId]: false
    }
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return transportReducer(state, action);
}

export const getState = createFeatureSelector<State>(featureName);
export const getAllTransits = createSelector(getState, (state: State) => state.allTransits);
export const getRoutesByTransitId = (transportId: number) => createSelector(
  getState,
  (state: State) => state.transitRoutes.filter(r => r._przejazd.id === transportId)
);
export const getCurrentTransit = createSelector(getState, (state: State) => state.currentTransit);
export const getCurrentlyViewedTransportSet = createSelector(getState, (state: State) => state.currentlyViewedTransportSet);
export const getSelectedTransportSet = createSelector(getState, (state: State) => state.selectedTransset);
export const getRouteTemplateList = createSelector(getState, (state: State) => state.routeTemplateList);
export const getDriversAvailableWithinTimeRange = createSelector(getState, (state: State) => state.driversAvailableWithinTimeRange);
export const getParkingLotsByTransportId = (transportId: number) => createSelector(getState, (state) => {
  if (state.allTransits.length > 0) {
    return state.allTransits.find((transit) => transit.id === transportId)?.parkingLots ?? [];
  }

  if (state.currentTransit && state.currentTransit.id === transportId) {
    return state.currentTransit.parkingLots;
  }

  return [];
});

export const getTransportSetsAvailableWithinTimeRange =
  createSelector(getState, (state: State) => state.transportSetsAvailableWithinTimeRange);
export const getNewlyCreatedTransitId = createSelector(getState, (state: State) => state.newlyCreatedTransitId);
export const getIfReadyTransportSetsPanelIsOpened = createSelector(getState, (state: State) => state.readyTransportSetsPanelOpened);
export const getAvailableMonitParams = createSelector(getState, (state: State) => state.availMonitParams);
export const getAssignedMonitParams = createSelector(getState, (state: State) => state.assignedMonitParams);
export const getCompanyParkingLots = createSelector(getState, (state: State) => state.companyParkingLots);
export const getCargoOwners = createSelector(getState, (state: State) => state.cargoOwners);
export const getCmList = createSelector(getState, (state: State) => state.cmList);
export const getTransportFilter = createSelector(getState, (state: State) => state.transportFilter);
