import { ColorIndex } from '../../../../shared/interfaces/color-index';
import { TerminalLocationShort } from '../../../../../models/helpers/terminal-location-short';
import { IInfo } from '../../../../../models/frame/i-info';

export class SpeedColor implements ColorIndex {
  constructor(private value: TerminalLocationShort | IInfo) {}

  getColorIndex(): number {
     switch (true) {
        case this.value.predkosc < 5:
          return 0;

        case this.value.predkosc < 15:
          return 1;

        case this.value.predkosc < 40:
          return 2;

        case this.value.predkosc < 60:
          return 3;

        case this.value.predkosc < 90:
          return 4;

        default:
          return 5;
      }
  }
}
