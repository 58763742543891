import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromRoot from '../../../../../app.reducer';
import * as Auth from '../../../../../ngrx/auth.actions';

@Component({
  selector: 'app-step2-login-pin',
  templateUrl: './step2-login-pin.component.html',
  styleUrls: ['./step2-login-pin.component.scss']
})
export class Step2LoginPinComponent {

  userPin = '';

  constructor(private store: Store<fromRoot.State>) {
  }

  checkPin() {
    this.store.dispatch(new Auth.PinRequest(this.userPin));
  }

}
