import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../app.reducer';
import * as USER from '../../ngrx/user.actions';
import * as ADMIN from '../../ngrx/admin.actions';

@Component({
  selector: 'app-user-mng',
  templateUrl: './user-mng.component.html',
  styleUrls: ['./user-mng.component.scss']
})
export class UserMngComponent implements OnInit {

  constructor(private store: Store<fromRoot.State>) {
  }

  ngOnInit(): void {
    this.store.dispatch(new USER.LoadUserPrivilegesRequest());
    this.store.dispatch(new ADMIN.LoadPrivilegesRequest());
  }

}
