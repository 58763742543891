<ng-container
  *ngIf="{
    gsmTruckStatus: testTruckGsm?.status | async,
    gsmTrailerStatus: testTrailerGsm?.status | async,
    satStatus: testSat?.status | async,
    tabletStatus: testTablet?.status | async
  } as alarmTesting"
>
  <div class="content">
    <h4>Alarm testing</h4>

    <span class="location-date">
      <mat-icon>calendar_today</mat-icon>
      Location date:
      {{ requestTs * 1000 | date: dateFormat.PIPE_DATETIME_LONGER_LABEL }}
    </span>

    <transit-test-result-gsm
      [status]="alarmTesting.gsmTruckStatus"
      [isDone]="isTruckGsmDone"
      resultsTitle="Truck's GSM"
      class="test-result"
    ></transit-test-result-gsm>

    <transit-test-result-gsm
      [status]="alarmTesting.gsmTrailerStatus"
      [isDone]="isTrailerGsmDone"
      resultsTitle="Trailer's GSM"
      class="test-result"
    ></transit-test-result-gsm>

    <transit-test-result-sat
      [status]="alarmTesting.satStatus"
      [isDone]="isSatDone"
      resultsTitle="Trailer's SAT"
      class="test-result"
    ></transit-test-result-sat>

    <transit-test-result-tablet
      [status]="alarmTesting.tabletStatus"
      [isDone]="isTabletDone"
      resultsTitle="Truck's tablet"
      class="test-result"
    ></transit-test-result-tablet>

    <button
      *ngIf="fallback"
      mat-raised-button
      color="primary"
      (click)="fallback()"
    >
      Go back
    </button>
  </div>
</ng-container>
