import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {
  MatFormField,
  MatFormFieldControl,
  MAT_FORM_FIELD,
} from '@angular/material/form-field';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  fromEvent,
  Observable,
  Subject,
  Subscription,
} from 'rxjs';
import { map, skipWhile, withLatestFrom } from 'rxjs/operators';
import { PunktSpedycyjny } from 'src/app/models/dto/transportSets';
import { AutocompleteSuggestion } from 'src/app/models/map/autocomplete';
import * as fromRoot from '../../../../app.reducer';
import { Strings } from '../../../../helpers';
import { HereService } from '../../services/here.service';
import { PointSearchBase } from '../point-search-base/pont-search-base';

@Component({
  selector: 'shipping-point-search',
  templateUrl: './shipping-point-search.component.html',
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: ShippingPointSearchComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShippingPointSearchComponent
  implements
    AfterViewInit,
    OnDestroy,
    MatFormFieldControl<PunktSpedycyjny>,
    ControlValueAccessor {
  static nextId = 0;

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }
  @HostBinding()
  id = `shipping-point-search-${ShippingPointSearchComponent.nextId++}`;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  @Input() pointIdPrefix: string;
  @Input() iconName: string;
  @Output() suggestionPicked: EventEmitter<AutocompleteSuggestion> =
    new EventEmitter();

  @ViewChild('searchInput', { static: false }) input: ElementRef;

  private inputSubscription: Subscription;
  private shippingPointsSearch$: BehaviorSubject<string> = new BehaviorSubject(
    ''
  );
  private _placeholder: string;

  shippingPoints$: Observable<PunktSpedycyjny[]> =
    this.shippingPointsSearch$.pipe(
      withLatestFrom(
        this.store
          .select(fromRoot.selectors.transset.getShippingPoints)
          .pipe(
            skipWhile(
              (shippingPoints: PunktSpedycyjny[]) =>
                !shippingPoints || !shippingPoints.length
            )
          )
      ),
      map(([query, shippingPoints]: [string, PunktSpedycyjny[]]) => {
        return shippingPoints.filter((shippingPoint) => {
          const search = `${shippingPoint.nazwa_kod}
                          ${shippingPoint.nazwa}
                          ${shippingPoint.miasto}
                          ${shippingPoint.panstwo}`;
          return this.isPointMatching(search, query);
        });
      })
    );

  value: PunktSpedycyjny;
  stateChanges = new Subject<void>();
  focused: boolean;
  required: boolean;
  disabled: boolean;
  errorState: boolean;

  searchBase: PointSearchBase = new PointSearchBase();

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    private hereService: HereService,
    protected store: Store<fromRoot.State>
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  onChange = (_: PunktSpedycyjny) => {};
  onTouched = () => {};

  ngAfterViewInit(): void {
    this.setupInputEvent();
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.inputSubscription.unsubscribe();
    this.shippingPointsSearch$.unsubscribe();
  }

  writeValue(obj: PunktSpedycyjny): void {
    this.value = obj;
    this.stateChanges.next();
    this.onChange(this.value);
    this.onTouched();
  }

  registerOnChange(fn: (point: PunktSpedycyjny) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  setDescribedByIds(ids: string[]): void {
    this.input?.nativeElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(_: MouseEvent): void {
    return;
  }

  locationPicked(event: MatAutocompleteSelectedEvent): void {
    this.placeLocationDataPoint(event.option.value);
  }

  get empty(): boolean {
    return !Boolean(this.input?.nativeElement.value);
  }

  private placeLocationDataPoint(point: PunktSpedycyjny): void {
    const pointLocation = {
      latitude: point.gps_ns,
      longitude: point.gps_ew,
    };

    this.writeValue(point);
    this.hereService.addPoint(
      pointLocation,
      { pointId: `${this.pointIdPrefix}${point.id}` },
      this.iconName
    );
  }

  private setupInputEvent(): void {
    this.inputSubscription = fromEvent(
      this.input.nativeElement,
      'input'
    ).subscribe((event: InputEvent) => {
      this.shippingPointsSearch$.next((event.target as HTMLInputElement).value);
    });
  }

  private isPointMatching(search: string, query: string): boolean {
    return (
      Strings.searchTextIgnoreAccents(query ?? '', search) > -1 ||
      query.trim().length === 0
    );
  }
}
