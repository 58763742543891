import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, startWith } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

import * as fromRoot from '../../../../app.reducer';
import * as TRANSPORT from '../../ngrx/transport.actions';
import * as fromTransport from '../../ngrx/transport.reducer';
import * as MAP from '../../../../ngrx/map.actions';

import { ParkingInfo } from '../../interfaces';
import { ParkingViewComponent } from '../../components';
import { RightDrawerService } from '../../../shared/services';
import { Strings } from '../../../../helpers';
import { GenericPoint } from '../../../../models/map';
import { MapViewRoute, SvgMapIcon } from '../../../../helpers/enum';
import { LocationService } from '../../../map/services/location.service';

@Component({
  selector: 'app-parking-lots',
  templateUrl: './parking-lots.component.html',
  styleUrls: ['./parking-lots.component.scss']
})
export class ParkingLotsComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort) sort: MatSort;
  dataSource = new MatTableDataSource<ParkingInfo>();
  displayedColumns: string[] = ['id', 'nazwa', 'kraj', 'region', 'uwagi', 'inne'];
  formSearch = new FormControl();
  subs = new Subscription();
  parkingList: ParkingInfo[];

  constructor(
    private store: Store<fromRoot.State>,
    private rightDrawer: RightDrawerService,
    private route: ActivatedRoute,
    private router: Router) {
    this.store.dispatch(TRANSPORT.loadCompanyParkingRequest());
  }

  private static filter(parking: ParkingInfo, term: string): boolean {
    if (term.trim().length < 1) {
      return true;
    }
    const searchField = parking.kraj + parking.uwagi + parking.region + parking.nazwa;
    return Strings.searchTextIgnoreAccents(term, searchField) >= 1;
  }

  ngOnInit(): void {
    this.subs.add(
      this.store.select(fromTransport.getCompanyParkingLots)
        .subscribe(r => {
          this.parkingList = r;
          this.dataSource.data = [...this.parkingList]
            .filter(parking => ParkingLotsComponent.filter(parking, this.formSearch.value ?? ''));
          this.dataSource.sort = this.sort;
        })
    );

    this.formSearch.valueChanges
      .pipe(
        startWith(''),
        debounceTime(250)
      )
      .subscribe(r => {
        this.dataSource.data = [...this.parkingList].filter(parking => ParkingLotsComponent.filter(parking, r));
      });
  }

  edit(parking: ParkingInfo) {
    this.rightDrawer.openOver(ParkingViewComponent, parking);
  }

  remove(parking: ParkingInfo) {
    this.rightDrawer.close();
    this.store.dispatch(TRANSPORT.removeParkingRequest({parkingId: parking.id}));
  }

  presentOnMap(parking: ParkingInfo) {
    const coords = LocationService.getCoordsNamedPL(parking);
    const points: GenericPoint[] = [
      {
        ...coords,
        id: parking.id, label: parking.nazwa, iconType: SvgMapIcon.PARKING
      }
    ];
    this.store.dispatch(MAP.AdhocPoints({points}));
    const extraData = {
      parking, callbackData: {
        label: 'Go back',
        fn: () => {
          this.router.navigateByUrl('/transport/parking');
        }
      }
    };
    this.store.dispatch(MAP.ViewerExtraData({extraData}));
    this.router.navigate(['/map-view', MapViewRoute.PARKING]);
  }

  presentAllOnMap() {
    const points = this.parkingList.map(parking => {
      const coords = LocationService.getCoordsNamedPL(parking);
      return {...coords,
        id: parking.id,
        label: parking.nazwa,
        iconType: SvgMapIcon.PARKING,
        pointClickAction: () => {
          this.rightDrawer.openOver(ParkingViewComponent, {
            data: {...parking},
            mode: 'map-view',
            callbackData: {
              label: 'Go to parking table',
              fn: () => {
                this.router.navigateByUrl('/transport/parking');
              }
            }
          });
        }
      } as GenericPoint;
    });
    this.store.dispatch(MAP.AdhocPoints({points}));
    this.router.navigate(['/map-view', MapViewRoute.PARKING_LIST]);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.rightDrawer.close();
  }
}
