import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DateFormat } from 'src/app/helpers/enum';
import { GsmStatus } from '../transit-test';

enum GsmTextStatus {
  NOT_VERIFIED_PROCESSING,
  NOT_VERIFIED,
  VERIFIED_WORKS_WELL,
}

@Component({
  selector: 'transit-test-result-gsm',
  templateUrl: './transit-test-result-gsm.component.html',
  styleUrls: ['../transit-test-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransitTestResultGsmComponent {
  @Input() resultsTitle: string;
  @Input() status: GsmStatus;
  @Input() isDone: boolean = false;

  textStatusEnum: typeof GsmTextStatus = GsmTextStatus;
  dateFormat: typeof DateFormat = DateFormat;

  constructor() {}

  getTextStatus(status: GsmStatus): GsmTextStatus {
    if (status?.upToDate) {
      return GsmTextStatus.VERIFIED_WORKS_WELL;
    }

    if (this.isDone) {
      return GsmTextStatus.NOT_VERIFIED;
    }

    return GsmTextStatus.NOT_VERIFIED_PROCESSING;
  }
}
