import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { catchError, delay, map, switchMap, tap } from 'rxjs/operators';

import * as fromRoot from '../../../app.reducer';
import * as UI from '../../../ngrx/ui.actions';

import { environment } from '../../../../environments/environment';
import { TerminalLocationFull, TerminalLocationSat, TerminalLocationShort } from '../../../models/helpers';
import { TermianlSearchTracksParams } from '../../../models/map';
import { EnvHelper } from '../../../helpers';
import { Coords, CoordsMap, CoordsNamedPL, CoordsU } from '../../shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  private readonly locationEndpoint: string = '';

  constructor(private http: HttpClient, private store: Store<fromRoot.State>) {

    this.locationEndpoint = EnvHelper.getApiUrl();
  }

  static coordsValidatorRaw(r: Coords): boolean {
    return LocationService.coordsValidator({dlugosc: r.gpsEw, szerokosc: r.gpsNs});
  }

  static coordsValidator(r: CoordsNamedPL | Coords): boolean {
    if ('dlugosc' in r && 'szerokosc' in r) {
      return LocationService.coordsValidatorNamedPL(r);
    } else if ('gpsEw' in r && 'gpsNs' in r) {
      return LocationService.coordsValidatorNamedPL({dlugosc: r.gpsEw, szerokosc: r.gpsNs});
    }
    return false;
  }

  static coordsValidatorNamedPL(r: CoordsNamedPL): boolean {
    return r.dlugosc !== undefined && r.szerokosc !== undefined
      && (r.dlugosc !== 0 || r.szerokosc !== 0)
      && r.dlugosc > -360 && r.dlugosc < 360
      && r.szerokosc > -90 && r.szerokosc < 90
      && r.szerokosc !== null && r.dlugosc !== null;
  }

  static getCoordsNamedPL(r: Coords | CoordsNamedPL | CoordsU): CoordsNamedPL | undefined {
    if ('dlugosc' in r && 'szerokosc' in r) {
      const {dlugosc, szerokosc} = r;
      return {dlugosc, szerokosc};
    } else if ('gpsEw' in r && 'gpsNs' in r) {
      const {gpsEw: dlugosc, gpsNs: szerokosc} = r;
      return {dlugosc, szerokosc};
    } else if ('gps_ew' in r && 'gps_ns' in r) {
      const {gps_ew: dlugosc, gps_ns: szerokosc} = r;
      return {dlugosc, szerokosc};
    }
    return undefined;
  }

  static getCoordsMap(r: Coords | CoordsNamedPL): CoordsMap | undefined {
    if ('dlugosc' in r && 'szerokosc' in r) {
      const {dlugosc: lng, szerokosc: lat} = r;
      return {lat, lng};
    } else if ('gpsEw' in r && 'gpsNs' in r) {
      const {gpsEw: lng, gpsNs: lat} = r;
      return {lat, lng};
    }
    return undefined;
  }

  static getMapCoordsStr(coords: CoordsMap): string {
    const toFixed4 = (coord: number) => Math.abs(Number(coord.toFixed(4)));
    return `${toFixed4(coords.lat)}${coords.lat > 0 ? 'N' : 'S'},${toFixed4(
      coords.lng
    )}${coords.lng > 0 ? 'E' : 'W'}`;
  }

  getCurrentTerminalTrackPoints(search: TermianlSearchTracksParams, recNum = 100)
    : Observable<any>[] {

    return search.terminals.map((terminal, idx) => {
      const params = new HttpParams()
        .set('fromdate', search.dataOd)
        .set('todate', search.dataDo)
        .set('firm', search.idFirma.toString())
        .set('limit', recNum.toString())
        .set('terminal', terminal.toString());

      return of(null).pipe(
        tap(() => this.store.dispatch(UI.mapSearchRouteRequest())),
        delay(50 * idx),
        switchMap(() => {
          return forkJoin([
            this.http.get<TerminalLocationShort[]>(
              this.locationEndpoint + environment.apiModules.Lokalizacja.pozycja + '/terminal',
              {params: params}),
            this.http.get<TerminalLocationSat[]>(
              this.locationEndpoint + environment.apiModules.Lokalizacja.pozycja + '/terminal/sat',
              {params: params})
          ]).pipe(
            delay(150),
            map(([normal, sat]) => {
              return [normal.filter(LocationService.coordsValidator), sat.filter(LocationService.coordsValidator)];
            }),
            catchError(() => {
              this.store.dispatch(UI.mapSearchRouteRequestDone());
              return of([]);
            })
          );
        }),
        tap(() => this.store.dispatch(UI.mapSearchRouteRequestDone())),
      );
    });

  }

  getLastTerminalPositions(): Observable<TerminalLocationShort[]> {
    return this.http.get<TerminalLocationShort[]>(
      this.locationEndpoint + environment.apiModules.Lokalizacja.pozycja + '/firmlast'
    );
  }

  getLastTerminalPositionsFull(): Observable<TerminalLocationFull[]> {
    return this.http.get<TerminalLocationFull[]>(
      this.locationEndpoint + environment.apiModules.Lokalizacja.pozycja + '/firmlast'
    );
  }
}
