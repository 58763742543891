import { Component, Input } from '@angular/core';
import { ITransportEntityExtended } from '../../../interfaces/transport-entity';
import { DateFormat } from '../../../../../helpers/enum';

@Component({
  selector: 'app-transport-timetable',
  templateUrl: './transport-timetable.component.html',
  styleUrls: ['./transport-timetable.component.scss']
})
export class TransportTimetableComponent {
  @Input() transport: ITransportEntityExtended;
  df = DateFormat;
}
