import { UserManagementService } from 'src/app/services';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';

import * as fromRoot from '../../../app.reducer';
import { Subscription } from 'rxjs';
import { DateFormat } from '../../../helpers/enum';

@Component({
  selector: 'app-user-log-history',
  templateUrl: './user-log-history.component.html',
  styleUrls: ['./user-log-history.component.scss']
})
export class UserLogHistoryComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = ['id', 'czas', 'adres_ip', 'czy_poprawne', 'user_agent', 'idKomp'];
  subs = new Subscription();
  df = DateFormat;

  userLogs = new MatTableDataSource();

  constructor(private route: ActivatedRoute, private userSrv: UserManagementService, private store: Store<fromRoot.State>) {
  }


  ngOnInit() {
    this.subs.add(
      this.store.select(fromRoot.selectors.user.getUserLoginAttempts).subscribe(workers => {
        this.userLogs.data = workers;
      })
    );

    this.route.paramMap.subscribe(res => {
      this.userSrv.getUserLogHistory(res.get('userid'))
        .subscribe(response => {
          this.userLogs.data = response;
        });
    });
  }

  ngAfterViewInit(): void {
    this.userLogs.sort = this.sort;
    this.userLogs.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
