import { Pipe, PipeTransform } from '@angular/core';
import { ITransportEntity } from '../../transports/interfaces/transport-entity';

@Pipe({
  name: 'getTransitTooltip'
})
export class GetTransitTooltipPipe implements PipeTransform {

  transform(value: ITransportEntity): any {
    if (!value) {
      return '';
    }

    let out = value.id + ': ' + (value.uwagi ?? 'Name not set');

    if (value.notatki) {
      out += ' \r\n\r\n ' + value.notatki;
    }

    return out;
  }

}
