<div class="transit-details-container" fxLayout="column" fxLayoutGap="24px">
  <div class="transit-name" fxLayout="column">
    <p class="name-heading">Transport</p>
    <!--    todo: 'nrRej' oraz 'trailerSet' nierozpoznawane - poprawić model -->
    <p *ngIf="transit?.zestaw?.trailerSet | vehicleFromTransit" mat-subheading-1 class="name">
      <span [matTooltip]="(transit.zestaw.truckSet | vehicleFromTransit)?.uwagi"
        [innerHTML]="(transit?.zestaw?.truckSet | vehicleFromTransit) ? (transit?.zestaw?.truckSet | vehicleFromTransit).nrRej : 'No truck'"></span>
      /
      <span [matTooltip]="(transit.zestaw.trailerSet | vehicleFromTransit).uwagi">{{ (transit.zestaw.trailerSet | vehicleFromTransit).nrRej }}</span>
    </p>
    <p *ngIf="lastLocation" class="name-heading">
      Last record from {{ lastLocation.czas | date : df.PIPE_TIME_24H }}
      on {{ lastLocation.czas | date : df.PIPE_DATE_LABEL }}
    </p>
  </div>

  <div *ngIf="lastLocation; else noLocationInfo" class="details-frame" fxLayout="column">
    <div class="details-frame-second-row" fxLayout="row" fxLayoutAlign="space-between" fxFlex>
      <span class="mat-subheading-1">Speed</span>
      <span *ngIf="lastLocation.predkosc !== null" class="mat-subheading-1">{{ lastLocation.predkosc }} km/h</span>
    </div>
    <div class="details-frame-third-row" fxLayout="row" fxLayoutAlign="space-between" fxFlex>
      <span class="mat-subheading-1">Position</span>
      <span *ngIf="lastLocation.szerokosc !== null && lastLocation.dlugosc !== null" class="mat-subheading-1">
        {{ lastLocation.szerokosc | latitude }} {{ lastLocation.dlugosc | longitude }}
      </span>
    </div>
    <div class="details-frame-fourth-row" fxLayout="row" fxLayoutAlign="space-between" fxFlex>
      <span class="mat-subheading-1">Azimuth</span>
      <span *ngIf="lastLocation.azymut !== null" class="mat-subheading-1">
        {{ lastLocation.azymut }}<span>&deg;</span>
      </span>
    </div>
    <div class="details-frame-fifth-row" fxLayout="row" fxLayoutAlign="space-between" fxFlex>
      <span class="mat-subheading-1">Altitude</span>
      <span *ngIf="lastLocation.wysokosc !== null" class="mat-subheading-1">
        {{ lastLocation.wysokosc }} m
      </span>
    </div>
    <div class="details-frame-sixth-row" fxLayout="row" fxLayoutAlign="space-between" fxFlex>
      <span class="mat-subheading-1">Sat. vid.</span>
      <span *ngIf="lastLocation.liczba_sat_wid !== null" class="mat-subheading-1">
        {{ lastLocation.liczba_sat_wid }} pcs.
      </span>
    </div>
    <div class="details-frame-seventh-row" fxLayout="row" fxLayoutAlign="space-between" fxFlex>
      <span class="mat-subheading-1">Sat. uti.</span>
      <span *ngIf="lastLocation.liczba_sat_uzy !== null" class="mat-subheading-1">
        {{ lastLocation.liczba_sat_uzy }} pcs.
      </span>
    </div>
    <div class="details-frame-eighth-row" fxLayout="row" fxLayoutAlign="space-between" fxFlex>
      <span class="mat-subheading-1">Record no</span>
      <span *ngIf="lastLocation.nrRekordu !== null" class="mat-subheading-1">
        {{ lastLocation.nrRekordu }}
      </span>
    </div>
    <div class="details-frame-nineth-row" fxLayout="row" fxLayoutAlign="space-between" fxFlex>
      <span class="mat-subheading-1">GSM signal strength</span>
      <span *ngIf="lastLocation.rssi_wart !== null" class="mat-subheading-1">
        {{ lastLocation.rssi_wart }} dbm
      </span>
    </div>
  </div>
  <ng-template #noLocationInfo>
    <div><em>No location info</em></div>
  </ng-template>

</div>
