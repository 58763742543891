import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, filter, map, take } from 'rxjs/operators';
import { isNumeric } from 'rxjs/internal-compatibility';

import { IAlarmTransportowy, IPodjetaAkcja } from '../../interfaces';
import * as ALERT from '../../ngrx/alert.actions';
import * as fromDrivers from '../../../drivers/ngrx/driver.reducer';
import * as fromAlert from '../../ngrx/alert.reducer';
import * as fromRoot from '../../../../app.reducer';
import { Kierowca } from '../../../drivers/interfaces';
import { TransportAlert } from '../../../../helpers';
import { DateFormat } from '../../../../helpers/enum';

@Component({
  selector: 'app-alert-timeline',
  templateUrl: './alert-timeline.component.html',
  styleUrls: ['./alert-timeline.component.scss']
})
export class AlertTimelineComponent implements OnInit, OnDestroy {

  @Input() alertId: number;
  df = DateFormat;

  alert: IAlarmTransportowy;
  displayedColumns: string[] = ['date', 'statusChange', 'by', 'notes'];
  driversList: Kierowca[];

  list = new TableVirtualScrollDataSource([]);
  alertTicketId = new BehaviorSubject<number>(null);

  subs = new Subscription();

  constructor(private store: Store<fromRoot.State>) {
  }

  ngOnInit(): void {
    this.alertTicketId
      .pipe(filter(r => isNumeric(r)))
      .subscribe(ticketId => {
        this.store.select(fromAlert.getTakenActionsByTicketId(ticketId))
          .pipe(
            debounceTime(500),
            map((info: { ticketId: number, actions: IPodjetaAkcja[] }) => {
              const ticket = this.alert.ticket;
              const ticketInfo = {
                czas: ticket.dataUtw,
                opis: ticket.nazwa,
                utwPrzez: ticket.tworca.imie + ' ' + ticket.tworca.nazwisko,
                status: ticket.statusT.opis,
                uwagi: ticket.uwagi
              };
              let temporaryList: any[] = [ticketInfo];

              const takenActions = info?.actions.map((takenAction) => {
                return {
                  czas: takenAction.czas,
                  status: takenAction.akcja.akcja,
                  przypisanyPrzez: takenAction.obslugujacy.imie + ' ' + takenAction.obslugujacy.nazwisko,
                  opis: takenAction.opis,
                };
              }) || [];

              let terminationInfo = null;
              if (TransportAlert.isAlertClosed(this.alert)) {
                terminationInfo = {
                  czas: this.alert.czasZakonczenia,
                  zakPrzez: this.alert.obslugujacy?.imie + ' ' + this.alert.obslugujacy?.nazwisko,
                  status: this.alert.statusAlarmu.status,
                };

                if (temporaryList.some(elem => elem.czas === terminationInfo.czas)) {
                  temporaryList = [...temporaryList, ...terminationInfo];
                }
              }

              if (!temporaryList.some(elem => elem.czas === takenActions[0]?.czas)) {
                temporaryList = [...temporaryList, ...takenActions].sort((a, b) => (a.czas < b.czas) ? 1 : -1);
              }

              this.list.data = temporaryList;
            }),
            take(1)
          )
          .subscribe();
      });

    this.subs.add(this.store.select(fromAlert.getDetailedTransportAlertsById(this.alertId))
      .pipe(
        map((alert: IAlarmTransportowy) => {
          this.alert = alert;
          if (alert.ticket && alert.ticket.id) {
            this.store.dispatch(new ALERT.LoadTakenActionsByTicketIdRequest(alert.ticket.id));
            this.alertTicketId.next(alert.ticket.id);
          } else {
            this.alertTicketId.next(null);
            this.list.data = [...alert.zgloszeniaKierowcy, ...alert.zdarzeniaPojazdu]
              .sort((a, b) => (a.czas < b.czas) ? 1 : -1);
          }
        })
      ).subscribe());

    this.subs.add(
      this.store.select(fromDrivers.getDrivers).pipe(
        map((drivers) => {
          this.driversList = drivers;
        })
      ).subscribe()
    );
  }

  findDriverById(driverId: number) {
    return this.driversList.find(d => +d.id === +driverId);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }


}
