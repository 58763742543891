import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { FormArray } from '@angular/forms';
import { intersectionBy, isNil } from 'lodash';
import { IDriverEntityDTO } from 'src/app/models/dto/transit';
import { Samochod } from 'src/app/models/dto/vehicle';
import * as fromRoot from '../../../../../app.reducer';
import { Strings } from '../../../../../helpers';
import { AtLeast } from '../../../../../helpers/types';
import {
  AddedDriver,
  CreateNewTransitForm
} from '../../../../../models/form/transit';
import { Kierowca } from '../../../../drivers/interfaces';
import * as fromDrivers from '../../../../drivers/ngrx/driver.reducer';
import { BaseYesNoDialogComponent } from '../../../../shared/dialogs';
import { BaseYesNoConfig } from '../../../../shared/interfaces';
import { IDriverEntity } from '../../../interfaces';
import {
  ITransportAssignedDriver,
  ITransportEntityExtended
} from '../../../interfaces/transport-entity';
import * as TRANSPORT from '../../../ngrx/transport.actions';
import * as fromTransport from '../../../ngrx/transport.reducer';

@Component({
  selector: 'app-transit-drivers-form',
  templateUrl: './transit-drivers-form.component.html',
  styleUrls: ['./transit-drivers-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransitDriversFormComponent implements OnInit, OnChanges {
  @Input() transport: ITransportEntityExtended;
  @Input() form: CreateNewTransitForm;

  availableDrivers$: Observable<Kierowca[]> = combineLatest([
    this.store.select(fromDrivers.getDrivers),
    this.store.select(fromTransport.getDriversAvailableWithinTimeRange),
  ]).pipe(
    map(([drivers, availableDrivers]: [Kierowca[], IDriverEntity[]]) =>
      (
        intersectionBy<Kierowca | IDriverEntity>(
          drivers,
          availableDrivers,
          'id'
        ) as Kierowca[]
      ).sort((a, b) => Strings.compare(a._uzytkownik.imie, b._uzytkownik.imie))
    )
  );
  defaultDrivers$: Observable<Kierowca[]>;

  addedDrivers: FormArray;
  departureDateTime: Date;
  arrivalDateTime: Date;

  isNil = isNil;

  constructor(
    private store: Store<fromRoot.State>,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.addedDrivers = this.form.addedDrivers;

    this.defaultDrivers$ = combineLatest([
      this.form?.addedDrivers.valueChanges.pipe(
        map<AddedDriver[], Array<IDriverEntityDTO | null>>((addedDrivers) =>
          addedDrivers.map((addedDriver) => addedDriver.driver)
        )
      ),
      this.store.select(fromDrivers.getDrivers),
      this.store.select(fromRoot.selectors.vehicles.getTrucks),
    ]).pipe(
      map(
        ([addedDrivers, drivers, trucks]: [
          Array<IDriverEntityDTO | null>,
          Kierowca[],
          Samochod[]
        ]) => {
          const defaultDrivers: Kierowca[] = [];

          const truck = this.transport.zestaw.truckSet?.filter(
            (_truck) => _truck.czasOdlaczenia === null
          )[0];
          if (truck) {
            const truckId = truck.samochod.id;
            const inventoryTruck = trucks.find(
              (_truck) => _truck.id === truckId
            );

            if (inventoryTruck) {
              const { id_kierowca_1, id_kierowca_2 } = inventoryTruck;

              if (
                id_kierowca_1 &&
                typeof addedDrivers.find(
                  (addedDriver) => addedDriver?.id === id_kierowca_1
                ) === 'undefined'
              ) {
                const driver1 = drivers.find(
                  (driver) => driver.id === id_kierowca_1
                );
                defaultDrivers.push(driver1);
              }

              if (
                id_kierowca_2 &&
                typeof addedDrivers.find(
                  (addedDriver) => addedDriver?.id === id_kierowca_2
                ) === 'undefined'
              ) {
                const driver2 = drivers.find(
                  (driver) => driver.id === id_kierowca_2
                );
                defaultDrivers.push(driver2);
              }
            }
          }

          return defaultDrivers;
        }
      ),
      tap(() => {
        this.cd.markForCheck();
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.transport) {
      this.loadAvailableDrivers();
      this.departureDateTime = this.form.departureDateTime;
      this.arrivalDateTime = this.form.arrivalDateTime;
      this.addedDrivers?.updateValueAndValidity({onlySelf: true, emitEvent: true});
    }
  }

  loadAvailableDrivers(): void {
    this.store.dispatch(
      TRANSPORT.loadDriversAvailableWithinTimeRangeRequest({
        timeRange: {
          from: new Date(this.transport?.czasWyjazdu).toISOString(),
          to: new Date(this.transport?.planowanyCzasPrzyjazdu).toISOString(),
        },
      })
    );
  }

  addDefaultDriver(defaultDriver: Kierowca): void {
    const driverIndex = this.form.addDriver({
      // Why drivers are type of IDriverEntityDTO? I don't know, we usually use Kierowca
      // TODO: unify driver type for transit form
      driver: defaultDriver as unknown as IDriverEntityDTO,
      fromDate: this.departureDateTime,
      toDate: this.arrivalDateTime,
    });

    this.addedDrivers.at(driverIndex).markAsTouched();
  }

  driverName: (driver: Kierowca | IDriverEntity) => string | null = (
    driver
  ) => {
    if (this.isDriver(driver)) {
      return `${driver._uzytkownik?.imie} ${driver._uzytkownik?.nazwisko}`;
    }

    if (this.isDriverEntity(driver)) {
      return `${driver.uzytkownik?.imie} ${driver.uzytkownik?.nazwisko}`;
    }

    return null;
  }

  private isDriver(driver: Kierowca | IDriverEntity): driver is Kierowca {
    return typeof (driver as Kierowca)?._uzytkownik !== 'undefined';
  }

  private isDriverEntity(
    driver: Kierowca | IDriverEntity
  ): driver is IDriverEntity {
    return typeof (driver as IDriverEntity)?.uzytkownik !== 'undefined';
  }

  removeDriver(formDrv: AddedDriver, index: number): void {
    if (!formDrv.transportDriverId) {
      this.form.removeDriver(index);
      return;
    }

    const config: BaseYesNoConfig = {
      title: 'Remove driver',
      content: `Are you sure you want to remove <b>${formDrv.driver.uzytkownik.imie} ${formDrv.driver.uzytkownik.nazwisko}</b> from this transport?`,
      yesAction: (autoClose = true) => {
        this.store.dispatch(
          TRANSPORT.removeDriversFromTransitRequest({
            transitId: this.transport.id,
            drivers: [formDrv.driver.id],
          })
        );
      },
      yesLabel: 'Delete',
      yesColor: 'warn',
      noLabel: 'Cancel',
      noColor: 'primary',
      autoClosure: true,
    };

    this.dialog.open(BaseYesNoDialogComponent, {
      data: config,
      id: 'BaseYesNoDialogComponent-removeTransportDriver',
      position: { top: '7%' },
    });
  }

  assignNewDriver(formDrv: AddedDriver) {
    const transitDriver: AtLeast<
      ITransportAssignedDriver,
      { idKierowca: number; idPrzejazd: number }
    > = {
      idKierowca: formDrv.driver.id,
      idPrzejazd: this.transport.id,
      planowanyCzasOd: formDrv.fromDate.toISOString(),
      planowanyCzasDo: formDrv.toDate.toISOString(),
    };
    this.store.dispatch(
      TRANSPORT.assignDriverToTransitRequest({ transitDriver })
    );
  }

  updateDriver(formDrv: AddedDriver) {
    const transitDriver: AtLeast<
      ITransportAssignedDriver,
      { idKierowca: number; idPrzejazd: number }
    > = {
      id: formDrv.transportDriverId,
      idKierowca: formDrv.driver.id,
      idPrzejazd: this.transport.id,
      planowanyCzasOd: formDrv.fromDate.toISOString(),
      planowanyCzasDo: formDrv.toDate.toISOString(),
    };
    this.store.dispatch(
      TRANSPORT.assignDriverToTransitRequest({ transitDriver })
    );
  }
}
