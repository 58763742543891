import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import * as fromRoot from '../../../../app.reducer';
import { HereService } from '../../services/here.service';
import { tap } from 'rxjs/operators';
import { SideLinkActionData } from '../../../../models/helpers';

@Component({
  selector: 'app-here',
  templateUrl: './here.component.html',
  styleUrls: ['./here.component.scss']
})
export class HereComponent implements OnInit, OnDestroy {
  H = window['H'];

  @ViewChild('map', {static: true}) mapContainer: ElementRef;
  map: any;

  subs = new Subscription();
  lastAction: SideLinkActionData = null;

  constructor(
    private hereService: HereService,
    private store: Store<fromRoot.State>
  ) {
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.map = this.hereService.initializeMap(this.H, this.mapContainer);

    this.subs.add(
      this.store.select(fromRoot.selectors.ui.getLastSideLinkAction)
        .pipe(
          tap(() => HereService.map.removeObjects(HereService.map.getObjects()))
        )
        .subscribe(res => this.lastAction = res)
    );

    setTimeout(() => this.map.getViewPort().resize(), 500);
    setTimeout(() => this.map.getViewPort().resize(), 1500);

    this.subs.add(
      this.store.select(fromRoot.selectors.ui.getWindowResizeEvent)
        .subscribe(action => {
          if (action && action.length > 0) {
            this.onResize(null);
          }
        })
    );
  }

  onResize($event) {
    this.map.getViewPort().resize();
  }
}
