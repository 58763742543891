import { Action } from '@ngrx/store';
import { Kierowca, LogowanieUzytkownika, Uzytkownik } from '../models/dto/user';
import { UzytkownikNowy } from '../models/dto/user/uzytkownik-nowy';
import { RoleUzytkownikow, UprawnienieUzytkownika } from '../models/authentication';

// [USER] get company user list

export const LOAD_COMPANY_USER_LIST_REQUEST = '[USER] get company user list - request';
export const LOAD_COMPANY_USER_LIST_SUCCESS = '[USER] get company user list - success';

export class LoadCompanyUserListRequest implements Action {
  readonly type = LOAD_COMPANY_USER_LIST_REQUEST;
}

export class LoadCompanyUserListSuccess implements Action {
  readonly type = LOAD_COMPANY_USER_LIST_SUCCESS;

  constructor(public payload: any[]) {
  }
}

// [user] load user login attempts

export const LOAD_USER_LOGIN_ATTEMPTS_REQUEST = '[USER] load user login attempts - request';
export const LOAD_USER_LOGIN_ATTEMPTS_SUCCESS = '[USER] load user login attempts - success';

export class LoadUserLoginAttemptsRequest implements Action {
  readonly type = LOAD_USER_LOGIN_ATTEMPTS_REQUEST;

  constructor(public payload: string) {
  }
}

export class LoadUserLoginAttemptsSuccess implements Action {
  readonly type = LOAD_USER_LOGIN_ATTEMPTS_SUCCESS;

  constructor(public payload: LogowanieUzytkownika[]) {
  }
}

// [user] load user by login

export const LOAD_USER_BY_LOGIN_REQUEST = '[USER] load user by login - request';
export const LOAD_USER_BY_LOGIN_SUCCESS = '[USER] load user by login - success';

export class LoadUserByLoginRequest implements Action {
  readonly type = LOAD_USER_BY_LOGIN_REQUEST;

  constructor(public payload: string) {
  }
}

export class LoadUserByLoginSuccess implements Action {
  readonly type = LOAD_USER_BY_LOGIN_SUCCESS;

  constructor(public payload: Uzytkownik) {
  }
}

// [user] load user privileges

export const LOAD_USER_PRIVILEGES_REQUEST = '[USER] load user privileges - request';
export const LOAD_USER_PRIVILEGES_SUCCESS = '[USER] load user privileges - success';

export class LoadUserPrivilegesRequest implements Action {
  readonly type = LOAD_USER_PRIVILEGES_REQUEST;
}

export class LoadUserPrivilegesSuccess implements Action {
  readonly type = LOAD_USER_PRIVILEGES_SUCCESS;

  constructor(public payload: UprawnienieUzytkownika[]) {
  }
}

// [user] load drivers

export const LOAD_DRIVERS_REQUEST = '[USER] load drivers - request';
export const LOAD_DRIVERS_SUCCESS = '[USER] load drivers - success';

export class LoadDriversRequest implements Action {
  readonly type = LOAD_DRIVERS_REQUEST;
}

export class LoadDriversSuccess implements Action {
  readonly type = LOAD_DRIVERS_SUCCESS;

  constructor(public payload: Kierowca[]) {
  }
}

// [user] add new user

export const ADD_NEW_USER_REQUEST = '[USER] add new user - request';
export const ADD_NEW_USER_SUCCESS = '[USER] add new user - success';

export class AddNewUserRequest implements Action {
  readonly type = ADD_NEW_USER_REQUEST;

  constructor(public payload: UzytkownikNowy) {}
}

export class AddNewUserSuccess implements Action {
  readonly type = ADD_NEW_USER_SUCCESS;
}

// [user] edit user
export const EDIT_USER_REQUEST = '[USER] edit user - request';

export class EditUserRequest implements Action {
  readonly type = EDIT_USER_REQUEST;

  constructor(public payload: { user: Partial<Uzytkownik> }) {
  }

  get user(): Partial<Uzytkownik> {
    return this.payload.user;
  }
}

// [user] remove user privilege

export const REMOVE_USER_PRIVILEGE_REQUEST = '[USER] remove user privilege - request';
export const REMOVE_USER_PRIVILEGE_SUCCESS = '[USER] remove user privilege - success';

export class RemoveUserPrivilegeRequest implements Action {
  readonly type = REMOVE_USER_PRIVILEGE_REQUEST;

  constructor(public payload: UprawnienieUzytkownika) {
  }
}

export class RemoveUserPrivilegeSuccess implements Action {
  readonly type = REMOVE_USER_PRIVILEGE_SUCCESS;

}

export const LOCK_USER = '[USER] lock user account';
export const UNLOCK_USER = '[USER] unlock user account';

export class LockUserRequest implements Action {
  readonly type = LOCK_USER;

  constructor(public payload: { userId: number, callback?: () => {} }) {
  }
}

export const REMOVE_USER = '[USER] remove user account';
export class RemoveUserRequest implements Action {
  readonly type = REMOVE_USER;

  constructor(public payload: { userId: number, callback?: () => {} }) {
  }
}

export class UnLockUserRequest implements Action {
  readonly type = UNLOCK_USER;

  constructor(public payload: { userId: number, callback?: () => {} }) {
  }
}

// [user] add user privilege

export const ADD_USER_PRIVILEGE_REQUEST = '[USER] add user privilege - request';
export const ADD_USER_PRIVILEGE_SUCCESS = '[USER] add user privilege - success';

export class AddUserPrivilegeRequest implements Action {
  readonly type = ADD_USER_PRIVILEGE_REQUEST;

  constructor(public payload: UprawnienieUzytkownika) {
  }
}

export class AddUserPrivilegeSuccess implements Action {
  readonly type = ADD_USER_PRIVILEGE_SUCCESS;

  constructor(public payload: UprawnienieUzytkownika) {
  }
}

// [user] load company roles

export const LOAD_COMPANY_ROLES_REQUEST = '[USER] add user company roles- tre';
export const LOAD_COMPANY_ROLES_SUCCESS = '[USER] add user company roles- success';

export class LoadCompanyRolesRequest implements Action {
  readonly type = LOAD_COMPANY_ROLES_REQUEST;
}

export class LoadCompanyRolesSuccess implements Action {
  readonly type = LOAD_COMPANY_ROLES_SUCCESS;

  constructor(public payload: { role: RoleUzytkownikow[] }) {
  }
}

export type UserActions =
  LoadCompanyUserListRequest | LoadCompanyUserListSuccess
  | LoadUserLoginAttemptsRequest | LoadUserLoginAttemptsSuccess
  | LoadUserByLoginRequest | LoadUserByLoginSuccess
  | LoadUserPrivilegesRequest | LoadUserPrivilegesSuccess
  | LoadDriversRequest | LoadDriversSuccess | AddNewUserRequest | AddNewUserSuccess
  | RemoveUserPrivilegeRequest | RemoveUserPrivilegeSuccess | AddUserPrivilegeRequest | AddUserPrivilegeSuccess
  | EditUserRequest | LoadCompanyRolesRequest | LoadCompanyRolesSuccess
  | LockUserRequest | UnLockUserRequest | RemoveUserRequest;
