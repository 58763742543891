<nav mat-tab-nav-bar animationDuration="0ms" *ngIf="isMenuVisible">
  <a mat-tab-link *ngFor="let link of subMenuLinks; index as i"
     routerLink="{{link.path}}"
     routerLinkActive
     #rla="routerLinkActive"
     [active]="rla.isActive">
     {{link?.title}}
  </a>
  <a mat-tab-link *ngFor="let link of subMenuNgrxLinks; index as j"
     routerLink="{{link.path}}"
     routerLinkActive
     #rla="routerLinkActive"
     [active]="rla.isActive"
     (click)="ngrxMenuAction(link.path)">
     {{link?.title}}
  </a>
</nav>
