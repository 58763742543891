<div class="actions-container" fxFlex fxLayout="column" fxLayoutGap="32px">
    <div class="buttons-container" fxFlex fxLayout="column" fxLayoutGap="32px">
        <div fxFlex fxLayout="column" fxLayoutGap="16px">
          <button mat-raised-button color="primary" routerLink="/transport/list"><mat-icon>list</mat-icon> Go to transport list</button>
        </div>

        <div fxFlex fxLayout="column" fxLayoutGap="16px">
          <button mat-raised-button color="accent" [disabled]="transport.przekazDoCm || transport?.zakonczony" (click)="activateMonitoring()" *appHasPrivileges="[ePrivs.ADM_TRANSITS]"><mat-icon>control_camera</mat-icon>Send supervision order to AMIC</button>
          <button mat-raised-button color="accent" [disabled]="transport?.zakonczony"  (click)="testAlarms()" *appHasPrivileges="[ePrivs.ADM_TRANSITS]">
            <mat-icon>alarm</mat-icon>Test alarms and communication</button>
        </div>

        <div fxFlex fxLayout="column" fxLayoutGap="16px">
          <button mat-raised-button class="start-transport-btn" [disabled]="(transport.rozpoczety || transport?.zakonczony) && !transportInTesting" (click)="transportStartStop()" *appHasPrivileges="[ePrivs.START_TRANSIT]">
            <mat-icon>play_arrow</mat-icon>Arm the system and start transport
          </button>
          <button mat-raised-button color="warn" [disabled]="!transport.rozpoczety || transportInTesting" (click)="transportStartStop()" *appHasPrivileges="[ePrivs.STOP_TRANSIT]">
            <mat-icon>stop</mat-icon> Stop transport
          </button>
          <button mat-raised-button color="warn" [disabled]="transport.rozpoczety || transport.zakonczony"
                  (click)="deleteTransport()" *appHasPrivileges="[ePrivs.ADM_TRANSITS]">
            <mat-icon>delete</mat-icon> Delete transport
          </button>
        </div>

    </div>
    <div>
      <app-textarea name="comment" placeholder="Enter a comment" label="Send a comment to your AMIC"
                    minRows="10" floatLabel="always" [control]="commentControl"></app-textarea>
      <div fxLayoutAlign="end">
        <button mat-raised-button color="primary" disabled>Send a comment</button>
      </div>
    </div>
</div>
