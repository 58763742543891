import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import moment from 'moment';

import * as fromRoot from '../../../../../../app.reducer';

import { ObjectInformationComponent } from '../../object-information/object-information.component';
import { SideLinkActionData } from '../../../../../../models/helpers';
import { MapObjectsHelper } from '../MapObjects.helper';
import { HereService } from '../../../../services/here.service';
import { MapNgrxActions } from '../../../../enum';
import { MarkTermianls } from '../../../../../../ngrx/map.actions';
import { LocationService } from '../../../../services/location.service';

@Component({
  selector: 'app-last-terminal-positions',
  templateUrl: './last-terminal-positions.component.html',
  styleUrls: ['./last-terminal-positions.component.scss']
})
export class LastTerminalPositionsComponent implements OnInit, OnDestroy {
  subs = new Subscription();
  @Input() lastAction: SideLinkActionData = null;

  constructor(private store: Store<fromRoot.State>,
              private hereService: HereService,
              private dialog: MatDialog) {
  }

  ngOnInit() {

    this.subs.add(
      combineLatest([
        this.store.select(fromRoot.selectors.devices.getTerminals),
        this.store.select(fromRoot.selectors.frame.getInfo),
        this.store.select(fromRoot.selectors.map.getFilters),
        this.store.select(fromRoot.selectors.auth.getAuthIsAuthenticated),
      ])
        .pipe(
          filter(res =>
            res[0] && res[0].length > 0
            && res[1] && res[1].length > 0
            && res[3]
          )
        )
        .subscribe(([terminals, terminalList, filters]) => {
          terminalList = terminalList.filter(t => moment.duration(moment().diff(moment(t.czas))).asHours() < (24 * 45));

          const views = [MapNgrxActions.SHOW_VEHICLES, MapNgrxActions.GENERIC_VIEW];
          if (this.lastAction && !views.includes(this.lastAction.value as MapNgrxActions)) {
            return false;
          }

          HereService.map.removeObjects(HereService.map.getObjects());

          terminalList.forEach((terminalPosition) => {
            if (
              !LocationService.coordsValidator(terminalPosition)
              || moment().diff(moment(terminalPosition.czas), 'days') > 30
            ) {
              return;
            }

            const coords = LocationService.getCoordsMap(terminalPosition);

            const terminal = terminals.find(term => term.id === terminalPosition.idTerminal);
            const marker = new HereService.H.map.DomMarker(coords, {
              data: {
                ost_data: terminalPosition.czas,
                terminal,
                terminalMismatch: typeof terminal === 'undefined',
                ost_dane: terminalPosition
              },
              icon: new HereService.H.map.DomIcon(
                MapObjectsHelper.getIconForMarkerSvg(terminalPosition, filters.markerColoringScheme)
              )
            });

            marker.addEventListener('tap', (evt) => {
              const pointData = evt.target.getData();
              const dialogConfig = new MatDialogConfig();
              dialogConfig.data = pointData;
              if (!!pointData?.terminal?.id) {
                this.store.dispatch(MarkTermianls({mapPointData: [pointData.terminal]}));
              }

              this.dialog.open(ObjectInformationComponent, dialogConfig);
            });
            HereService.map.addObject(marker);
          });
        })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
