<div class="mat-elevation-z8 white-bar tickets-table-container"
     *ngIf="{isLoading: isDataLoading$ | async} as tickets">
  <div class="table-wrapper">
    <table mat-table [dataSource]="ticketsInfo"
           aria-describedby="List of raised tickets"
           matSort class="mat-elevation-z8">

    <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns">
      <th mat-header-cell *matHeaderCellDef [mat-sort-header]="column" class="alert-types-heading">{{ column }}</th>
      <td mat-cell *matCellDef="let element" (click)="!tickets.isLoading && showDetails(element.id)">
        <div [ngSwitch]="column">
          <span *ngSwitchCase="ticketTableColumns.Status">{{ element.statusT.status }}</span>
          <span *ngSwitchCase="ticketTableColumns.Id">{{ element.id }}</span>
          <span *ngSwitchCase="ticketTableColumns.Company">{{ element.nazwaFirmy }}</span>
          <span *ngSwitchCase="ticketTableColumns.Set">{{ element.idZestaw }}</span>
          <div *ngSwitchCase="ticketTableColumns.Plates" class="name-column">
            {{ !element.samochod && !element.naczepa ? 'No data' : '' }}

              <terminal-veh-reg-number
                *ngIf="element.samochod"
                [vehType]="vehicleType.TRUCK"
              >
                {{ element.samochod?.nrRej}}
              </terminal-veh-reg-number>

              <terminal-veh-reg-number
                *ngIf="element.naczepa"
                [vehType]="vehicleType.TRAILER"
              >
                {{ element.naczepa.nrRej}}
              </terminal-veh-reg-number>
            </div>
            <span *ngSwitchCase="ticketTableColumns.Title">{{ element.nazwa || 'No title' }}</span>
            <span *ngSwitchCase="ticketTableColumns.TotalAlerts"> {{element.alarmy.length}} </span>
            <span *ngSwitchCase="ticketTableColumns.ActiveAlerts"> {{activeAlerts(element.alarmy)}} </span>
            <span *ngSwitchCase="ticketTableColumns.StartDate">
            {{ element.dataUtw | date : dateFormat.PIPE_LONG_24H }}
          </span>
            <span *ngSwitchCase="ticketTableColumns.ClosingDate">
            {{ (element.dataZak | date : dateFormat.PIPE_LONG_24H) || 'No closing date' }}
          </span>
            <span *ngSwitchCase="ticketTableColumns.Attendant">
              <span *ngIf="element.obslugujacy; else noAttendant">
                {{ element.obslugujacy?.imie }} {{ element.obslugujacy?.nazwisko }}
              </span>
              <ng-template #noAttendant>
                <span class="no-info">No assigned attendant</span>
              </ng-template>
            </span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="progress">
        <th mat-header-cell *matCellDef="let element"></th>
        <td mat-cell *matHeaderCellDef table-progress-cell [columnSpan]="displayedColumns.length"></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        *matHeaderRowDef="['progress']; sticky: true"
        mat-header-row
        table-progress-row
        [isVisible]="tickets.isLoading">
      </tr>
      <tr
        *matRowDef="let element; columns: displayedColumns;"
        mat-row
        [table-disabled-row]="tickets.isLoading"
        class="element-row"
      ></tr>
    </table>
    <mat-paginator #paginator
                   [length]="ticketsInfo.data.length"
                   appPaginationPageSize
                   showFirstLastButtons>
    </mat-paginator>
  </div>
</div>
