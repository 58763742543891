import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {
  @Input() label: string | null;
  @Input() placeholder: string | null;
  @Input() control: FormControl;
  @Input() readonly = false;
  @Input() name = 'fdw-generic-input';
  @Input() autocomplete = true;
  @Input() hint = '';
  @Input() suffixIcon = '';
  @Input() type = 'text';
  @Input() required = false;

  @Input() value?: string | number = '';

  isRequired(): boolean {
    return this.control.errors !== null && this.control.errors.required;
  }

  ngOnInit() {
    if (this.control === undefined) {
      this.control = new FormControl(this.value);
      this.control.disable();
    }
  }
}

