import { FormControl, FormGroup } from '@angular/forms';
import { Uzytkownik } from '../dto/user';
import { merge, Observable, of } from 'rxjs';

export class UserDetailsForm extends FormGroup {
  private model: Uzytkownik | null = null;

  constructor(user?: Uzytkownik | null) {
    super({
      imie: new FormControl(null),
      nazwisko: new FormControl(null),
      email: new FormControl(null),

      tel1: new FormControl(null),
      tel2: new FormControl(null),

      login: new FormControl(null),
      firma: new FormControl(null),
      rola: new FormControl(null),

      miasto: new FormControl(null),
      kod: new FormControl(null),
      ulica: new FormControl(null)
    });

    if (user) {
      this.patchFromModel(user);
    }
  }

  roleChanges(): Observable<string | null> {
    return merge(
      of(null),
      this.get('rola').valueChanges
    );
  }

  companyChanges(): Observable<string | null> {
    return merge(
      of(null),
      this.get('firma').valueChanges
    );
  }

  patchFromModel(user: Uzytkownik): void {
    this.patchValue({
      imie: user.imie,
      nazwisko: user.nazwisko,
      email: user.email,
      tel1: user.tel1,
      tel2: user.tel2,
      login: user.username,
      firma: user._firma,
      rola: user._rola,
      miasto: user.miasto,
      kod: user.kod,
      ulica: user.ul
    });
    this.model = user;
  }

  getUpdatedModel(): Partial<Uzytkownik> {
    return {
      id: this.model.id,
      imie: this.get('imie').value,
      nazwisko: this.get('nazwisko').value,
      email: this.get('email').value,
      tel1: this.get('tel1').value,
      tel2: this.get('tel2').value,
      _rola: this.get('rola').value,
    };
  }
}
