import { Component, EventEmitter, Output } from '@angular/core';
import { filter, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';

import * as fromRoot from '../../app.reducer';
import { AppUserInfo, RoleUzytkownikow } from '../../models/authentication';
import { Firma } from '../../models/dto/company';

class UserAffiliationModel {
  constructor(private readonly myInfo: AppUserInfo, private readonly myCompany: Firma | undefined) {
  }

  get fullName(): string {
    return `${this.myInfo?.name} ${this.myInfo?.surname}`;
  }

  get companyName(): string {
    return this.myCompany?.nazwa;
  }

  get role(): RoleUzytkownikow | undefined {
    return this.myInfo?.role;
  }
}


@Component({
  selector: 'app-user-affiliation',
  templateUrl: './user-affiliation.component.html',
  styleUrls: ['./user-affiliation.component.scss']
})
export class UserAffiliationComponent {
  @Output() mainMenuCloseIconClicked_ = new EventEmitter<boolean>();
  @Output() showUserInfoAction = new EventEmitter<boolean>();

  userAffiliationModel$: Observable<UserAffiliationModel>;

  constructor(private store: Store<fromRoot.State>) {

    this.userAffiliationModel$ = this.store.select(fromRoot.selectors.auth.getAuthIsAuthenticated).pipe(
      filter((isAuthenticated) => isAuthenticated),
      switchMap(() => {
        return this.store.select(fromRoot.selectors.auth.getUserInfo).pipe(
          switchMap((userInfo: AppUserInfo) => {
            if (!userInfo || !userInfo.companyId) {
              return of(null);
            }
            return this.store.select(fromRoot.getCompanyList).pipe(
              map((companies: Firma[]) => companies.find((company: Firma) => company.id === +userInfo?.companyId)),
              map((company: Firma | undefined) => {
                return new UserAffiliationModel(userInfo, company);
              })
            );
          })
        );
      })
    );
  }

  toggle_() {
    this.mainMenuCloseIconClicked_.emit(true);
  }

  showUserInfo(): void {
    this.showUserInfoAction.emit(true);
  }

}
