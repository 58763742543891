import { FormControl, Validators } from '@angular/forms';
import { DefaultForm } from '../default-form';
import { WlascicielTowaru } from '../../dto/transportSets';

export class CargoOwnerForm extends DefaultForm<WlascicielTowaru> {
  constructor(controls?: any | null) {
    super({
      'nazwa': new FormControl(null, [Validators.required, Validators.minLength(5)]),
      'telefon': new FormControl(null, [Validators.required, Validators.minLength(10)]),
      'kontakt': new FormControl(null, [Validators.required, Validators.minLength(5)]),
      'idStatus': new FormControl(1),
      'uwagi': new FormControl(null),
    });
    if (controls) {
      this.patchFromModel(controls);
    }
  }

  enable(opts?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.enable(opts);
    setTimeout(() => super.enable(opts));
  }

  disable(opts?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.disable(opts);
    setTimeout(() => super.disable(opts));
  }
}
