import { AbstractControl, ValidationErrors } from '@angular/forms';

export function urlValidator(control: AbstractControl) {
  if (!control.value.startsWith('https') || !control.value.includes('.me')) {
    return {urlValid: true};
  }
  return null;
}

export function digitOnly(control: AbstractControl) {
  if (isNaN(control.value)) {
    return {notValidNumber: true};
  }
  return null;
}

export function positiveDigitOnly(control: AbstractControl) {
  if (isNaN(control.value) || parseInt(control.value, 10) < 1) {
    return {notValidNumber: true};
  }
  return null;
}

export function notNegativeDigitOnly(control: AbstractControl) {
  if (isNaN(control.value) || parseInt(control.value, 10) < 0) {
    return {notValidNumber: true};
  }
  return null;
}

export function inArray<T>(array: Array<T>): (control: AbstractControl) => ValidationErrors | null {
  return (control: AbstractControl) => array.includes(control.value) ? null : { notInArray: true };
}
