import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, exhaustMap, filter, map, switchMap, tap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';

import * as UI from './ui.actions';
import * as TERMINAL from './terminal.actions';
import * as fromRoot from '../app.reducer';

import { TerminalMngService } from '../services/terminal-mng.service';
import { Messages, TerminalFrames } from '../helpers/enum';

@Injectable()
export class TerminalEffects {

  loadTerminals$ = createEffect(() => this.actions.pipe(
    ofType(TERMINAL.LOAD_TERMINALS_REQUEST),
    switchMap(() => {
        return this.terms.loadTerminals()
          .pipe(
            map(terminalList => new TERMINAL.LoadTerminalsSuccess(terminalList)),
            catchError(() => {
              return of(UI.userError({message: Messages.READING_DATA_ERR}));
            })
          );
      }
    )
  ));

  loadTerminalStatuses$ = createEffect(() => this.actions.pipe(
    ofType(TERMINAL.LOAD_TERMINAL_STATUSES_REQUEST),
    switchMap(() => {
        return this.terms.loadTerminalStatus()
          .pipe(
            map(terminalStatusList => new TERMINAL.LoadTerminalStatusesSuccess(terminalStatusList)),
            catchError(() => {
              return of(UI.userError({message: Messages.READING_DATA_ERR}));
            })
          );
      }
    )
  ));

  loadTerminalTrucks$ = createEffect(() => this.actions.pipe(
    ofType(TERMINAL.LOAD_TERMINALS_TRUCKS_REQUEST),
    switchMap(() => {
        return this.terms.loadTerminalTrucks()
          .pipe(
            map(terminalTruckList => new TERMINAL.LoadTerminalsTrucksSuccess(terminalTruckList)),
            catchError(() => {
              return of(UI.userError({message: Messages.READING_DATA_ERR}));
            })
          );
      }
    )
  ));

  loadTerminalTrails$ = createEffect(() => this.actions.pipe(
    ofType(TERMINAL.LOAD_TERMINALS_TRAILS_REQUEST),
    switchMap(() => {
      return this.terms.loadTerminalTrails()
        .pipe(
          map(terminalTrailList => new TERMINAL.LoadTerminalsTrailsSuccess(terminalTrailList)),
          catchError(() => {
            return of(UI.userError({message: Messages.READING_DATA_ERR}));
          })
        );
    })
  ));

  loadSimCards$ = createEffect(() => this.actions.pipe(
    ofType(TERMINAL.LOAD_SIM_CARDS_REQUEST),
    switchMap(() => this.terms.loadSimCards().pipe(
      map(simCards => new TERMINAL.LoadSimCardsSuccess({result: simCards})),
      catchError(() => {
        return of(UI.userError({message: Messages.READING_DATA_ERR}));
      })
    ))
  ));

  loadTerminalSimCards$ = createEffect(() => this.actions.pipe(
    ofType(TERMINAL.LOAD_TERMINALS_SIM_CARDS_REQUEST),
    switchMap(() => this.terms.loadTerminalSimCards().pipe(
      map(terminalSimCards => new TERMINAL.LoadTerminalSimCardsSuccess({result: terminalSimCards})),
      catchError(() => {
        return of(UI.userError({message: Messages.READING_DATA_ERR}));
      })
    ))
  ));


  getBoltPin$ = createEffect(() => this.actions.pipe(
    ofType<TERMINAL.GetBoltPinRequest>(TERMINAL.GET_BOLT_PIN_REQUEST),
    switchMap(({payload}) => this.terms.getTerminalBoltPin(payload.terminalId).pipe(
      map(data => new TERMINAL.GetBoltPinSuccess({data})),
      catchError(() => {
        return of(UI.userError({message: Messages.READING_DATA_ERR}));
      })
    ))
  ));

  assignNewSimCardToTerminal$ = createEffect(() => this.actions.pipe(
    ofType<TERMINAL.AssignSimCardToTerminal>(TERMINAL.ASSIGN_SIM_CARD_TO_TERMINAL),
    filter((action: TERMINAL.AssignSimCardToTerminal) => !action.terminalSimCard.id),
    switchMap(action => {
      return this.terms.newSimCardToTerminal(action.terminalSimCard).pipe(
        tap(() => {
          this.toastr.success('SIM card assigned into terminal.');
          this.store.dispatch(new TERMINAL.LoadTerminalsRequest());
          this.store.dispatch(UI.switchRightDrawer({isOpened: false}));
        }),
        map(() => {
          return new TERMINAL.LoadTerminalsSimCardsRequest();
        })
      );
    })
  ));

  assignExistingSimCardToTerminal$ = createEffect(() => this.actions.pipe(
    ofType<TERMINAL.AssignSimCardToTerminal>(TERMINAL.ASSIGN_SIM_CARD_TO_TERMINAL),
    filter((action: TERMINAL.AssignSimCardToTerminal) => !!action.terminalSimCard.id),
    switchMap(action => {
      return this.terms.updateSimCardInTerminal(action.terminalSimCard).pipe(
        tap(() => {
          this.toastr.success('SIM card assigned into terminal was updated.');
          this.store.dispatch(new TERMINAL.LoadTerminalsRequest());
          this.store.dispatch(UI.switchRightDrawer({isOpened: false}));
        }),
        map(() => {
          return new TERMINAL.LoadTerminalsSimCardsRequest();
        })
      );
    })
  ));

  unassignExistingSimCardFromTerminal$ = createEffect(() => this.actions.pipe(
    ofType<TERMINAL.UnassignSimCardFromTerminal>(TERMINAL.UNASSIGN_SIM_CARD_FROM_TERMINAL),
    switchMap(action => {
      return this.terms.removeSimCardFromTerminal(action.terminalSimCard).pipe(
        tap(() => {
          this.toastr.success('SIM card was unassigned from the terminal.');
          this.store.dispatch(new TERMINAL.LoadTerminalsRequest());
          this.store.dispatch(UI.switchRightDrawer({isOpened: false}));
        }),
        map(() => {
          return new TERMINAL.LoadTerminalsSimCardsRequest();
        })
      );
    })
  ));

  newTerminal$ = createEffect(() => this.actions.pipe(
    ofType<TERMINAL.NewTerminalRequest>(TERMINAL.NEW_TERMINAL_REQUEST),
    switchMap((action) => {
        return this.terms.newTerminal(action.payload.device)
          .pipe(
            map(() => {
              if (action.payload.successCallback) {
                action.payload.successCallback();
              }
              this.store.dispatch(new TERMINAL.LoadTerminalsRequest());
              return new TERMINAL.NewTerminalSuccess();
            }),
            catchError(() => {
              return of(UI.userError({message: Messages.SAVING_DATA_ERR}));
            })
          );
      }
    )
  ));

  newTerminalTruck$ = createEffect(() => this.actions.pipe(
    ofType<TERMINAL.NewTerminalTruckRequest>(TERMINAL.NEW_TERMINAL_TRUCK_REQUEST),
    switchMap((action) => {
        return this.terms.newTerminalTruck(action.payload.device)
          .pipe(
            map(backTerminalInfo => {
              this.store.dispatch(new TERMINAL.LoadTerminalsTrucksRequest());
              action.payload.successCallback();
              return new TERMINAL.NewTerminalTruckSuccess(backTerminalInfo);
            }),
            catchError(() => {
              return of(UI.userError({message: Messages.SAVING_DATA_ERR}));
            })
          );
      }
    )
  ));

  newTerminalTrail$ = createEffect(() => this.actions.pipe(
    ofType<TERMINAL.NewTerminalTrailRequest>(TERMINAL.NEW_TERMINAL_TRAIL_REQUEST),
    switchMap((action) => {
        return this.terms.newTerminalTrail(action.payload.device)
          .pipe(
            map(backTerminalInfo => {
              action.payload.successCallback();
              this.store.dispatch(new TERMINAL.LoadTerminalsTrailsRequest());
              return new TERMINAL.NewTerminalTrailSuccess(backTerminalInfo);
            }),
            catchError(() => {
              return of(UI.userError({message: Messages.SAVING_DATA_ERR}));
            })
          );
      }
    )
  ));

  loadSoftwareList$ = createEffect(() => this.actions.pipe(
    ofType<TERMINAL.LoadTerminalSoftwareListRequest>(TERMINAL.LOAD_TERMINAL_SOFTWARE_LIST_REQUEST),
    exhaustMap(() => {
        return this.terms.loadSoftwareList()
          .pipe(
            map(response => new TERMINAL.LoadTerminalSoftwareListSuccess(response)),
            catchError(() => {
              return of(UI.userError({message: Messages.READING_DATA_ERR}));
            })
          );
      }
    )
  ));

  loadFramesConfig$ = createEffect(() => this.actions.pipe(
    ofType<TERMINAL.LoadFramesRequest>(TERMINAL.LOAD_FRAMES_REQUEST),
    switchMap(() => {
        return this.terms.loadFrameList()
          .pipe(
            map(response => new TERMINAL.LoadFramesSuccess({frames: response})),
            catchError(() => {
              return of(UI.userError({message: Messages.READING_DATA_ERR}));
            })
          );
      }
    )
  ));

  loadFrameFieldTypes$ = createEffect(() => this.actions.pipe(
    ofType<TERMINAL.LoadFrameFieldTypesRequest>(TERMINAL.LOAD_FRAME_FIELD_TYPES_REQUEST),
    switchMap(() => {
        return this.terms.loadFrameFieldTypes()
          .pipe(
            map(response => new TERMINAL.LoadFrameFieldTypesSuccess({typy: response})),
            catchError(() => {
              return of(UI.userError({message: Messages.READING_DATA_ERR}));
            })
          );
      }
    )
  ));


  loadSoftwareFrames$ = createEffect(() => this.actions.pipe(
    ofType<TERMINAL.LoadFramesForSoftwareRequest>(TERMINAL.LOAD_FRAMES_FOR_SOFTWARE_REQUEST),
    switchMap(() => {
        return this.terms.loadSoftwareFrameVersion()
          .pipe(
            map(response => new TERMINAL.LoadFramesForSoftwareSuccess({result: response})),
            catchError(() => {
              return of(UI.userError({message: Messages.READING_DATA_ERR}));
            })
          );
      }
    )
  ));

  newTerminalSoftware$ = createEffect(() => this.actions.pipe(
    ofType<TERMINAL.NewTerminalSoftwareRequest>(TERMINAL.NEW_TERMINAL_SOFTWARE_REQUEST),
    switchMap((action) => {
        return this.terms.newSoftware(action.payload.soft)
          .pipe(
            map(() => {
              this.dialog.closeAll();
              this.toastr.success('Oprogramowanie zostało dodane');
              if (action.payload.successCallback) {
                this.terms.loadSoftwareList()
                  .pipe(
                    filter(r => r.length > 0),
                    map(r => r.reduce((prev, current) => prev.id > current.id ? prev : current))
                  )
                  .subscribe((soft) => {
                    action.payload.successCallback(soft.id);
                  });
              }
              return new TERMINAL.LoadTerminalSoftwareListRequest();
            }),
            catchError(() => {
              return of(UI.userError({message: Messages.READING_DATA_ERR}));
            })
          );
      }
    )
  ));

  updateTerminalSoftware$ = createEffect(() => this.actions.pipe(
    ofType<TERMINAL.UpdateTerminalSoftwareRequest>(TERMINAL.UPDATE_TERMINAL_SOFTWARE_REQUEST),
    switchMap((action) => {
        return this.terms.updateSoftware(action.payload)
          .pipe(
            map(() => {
              this.dialog.closeAll();
              this.toastr.success('Dane zostały poprawnie wpisane');
              return new TERMINAL.LoadTerminalSoftwareListRequest();
            }),
            catchError(() => {
              return of(UI.userError({message: Messages.READING_DATA_ERR}));
            })
          );
      }
    )
  ));

  upgradeSoftware$ = createEffect(() => this.actions.pipe(
    ofType<TERMINAL.UpgradeSoftwareRequest>(TERMINAL.UPGRADE_SOFTWARE_REQUEST),
    switchMap(action => {
      const {termId, softCode, packetSize} = action.payload;
      return this.terms.updateTerminalSoftware({terminalId: termId, softwareCode: softCode}, packetSize)
        .pipe(
          map(() => new TERMINAL.UpgradeSoftwareSuccess())
        );
    })
  ));


  updateFrameVersion$ = createEffect(() => this.actions.pipe(
    ofType<TERMINAL.UpdateFrameVersionRequest>(TERMINAL.UPDATE_FRAME_VERSION_REQUEST),
    switchMap(action => {
      return this.terms.updateFrameVersion(action.payload.wersja)
        .pipe(
          map(() => {
            if (action.payload.succCallback) {
              action.payload.succCallback();
            }
            return new TERMINAL.UpdateFrameVersionSuccess({wersja: action.payload.wersja, ramka: action.payload.ramka});
          })
        );
    })
  ));


  updateFrameField$ = createEffect(() => this.actions.pipe(
    ofType<TERMINAL.UpdateFrameFieldRequest>(TERMINAL.UPDATE_FRAME_FIELD_REQUEST),
    switchMap(action => {
      return this.terms.updateFrameField(action.payload.pole)
        .pipe(
          map(() => {
            if (action.payload.succCallback) {
              action.payload.succCallback();
            }
            return new TERMINAL.UpdateFrameFieldSuccess({
              wersja: action.payload.wersja,
              ramka: action.payload.ramka,
              pole: action.payload.pole
            });
          })
        );
    })
  ));


  newSoftwareFrame$ = createEffect(() => this.actions.pipe(
    ofType<TERMINAL.NewSoftwareFrameRequest>(TERMINAL.NEW_SOFTWARE_FRAME_REQUEST),
    switchMap(action => {
      return this.terms.newSoftwareFrame(action.payload.record).pipe(
        map(() => {
          action.payload.successCallback();
          return new TERMINAL.LoadTerminalSoftwareListRequest();
        }),
        catchError(() => of(UI.userError({message: Messages.SAVING_DATA_ERR})))
      );
    })
  ));


  newSoftwareFrames$ = createEffect(() => this.actions.pipe(
    ofType<TERMINAL.NewSoftwareFramesRequest>(TERMINAL.NEW_SOFTWARE_FRAMES_REQUEST),
    switchMap(action => {
      return forkJoin(this.terms.newSoftwareFrames(action.payload.records))
        .pipe(
          map(() => {
            return new TERMINAL.LoadTerminalSoftwareListRequest();
          }),
          catchError(() => of(UI.userError({message: Messages.SAVING_DATA_ERR})))
        );
    })
  ));


  removeSoftwareFrame$ = createEffect(() => this.actions.pipe(
    ofType<TERMINAL.RemoveSoftwareFrameRequest>(TERMINAL.REMOVE_SOFTWARE_FRAME_REQUEST),
    switchMap(action => {
      return this.terms.removeSoftwareFrame(action.payload.record).pipe(
        map(() => {
          action.payload.successCallback();
          return new TERMINAL.LoadTerminalSoftwareListRequest();
        }),
        catchError(() => of(UI.userError({message: Messages.SAVING_DATA_ERR})))
      );
    })
  ));


  newFrameVersion$ = createEffect(() => this.actions.pipe(
    ofType<TERMINAL.NewFrameVersionRequest>(TERMINAL.NEW_FRAME_VERSION_REQUEST),
    switchMap(action => {
      return this.terms.newFrameVersion(action.payload.version).pipe(
        map(() => new TERMINAL.LoadFramesRequest()),
        catchError(() => of(UI.userError({message: Messages.SAVING_DATA_ERR})))
      );
    })
  ));


  lockerOpen$ = createEffect(() => this.actions.pipe(
    ofType<TERMINAL.LockerOpenRequest>(TERMINAL.LOCKER_OPEN_REQUEST),
    switchMap(action => {
      return this.terms.lockerRequestOpen(action.payload.terminalId).pipe(
        map(() => new TERMINAL.LockerOpenSuccess()),
        catchError(() => of(UI.userError({message: Messages.SAVING_DATA_ERR})))
      );
    })
  ));


  lockerClose$ = createEffect(() => this.actions.pipe(
    ofType<TERMINAL.LockerCloseRequest>(TERMINAL.LOCKER_CLOSE_REQUEST),
    switchMap(action => {
      return this.terms.lockerRequestClose(action.payload.terminalId).pipe(
        map(() => new TERMINAL.LockerCloseSuccess()),
        catchError(() => of(UI.userError({message: Messages.SAVING_DATA_ERR})))
      );
    })
  ));


  requestForFrame$ = createEffect(() => this.actions.pipe(
    ofType<TERMINAL.RequestForFrameRequest>(TERMINAL.REQUEST_FOR_FRAME_REQUEST),
    concatMap(action => {
      return this.terms.requestForFrame(action.payload.terminalId, action.payload.frameName).pipe(
        map(() => new TERMINAL.RequestForFrameSuccess()),
        catchError(() => of(UI.userError({message: Messages.SAVING_DATA_ERR})))
      );
    })
  ));


  lockerSendForStatus$ = createEffect(() => this.actions.pipe(
    ofType<TERMINAL.LockerStatusRequest>(TERMINAL.LOCKER_STATUS_REQUEST),
    switchMap((action) => {
      return this.terms.requestForFrame(action.payload.terminalId, TerminalFrames.rygiel).pipe(
        map(() => {
          action.payload.successCallback();
          this.toastr.info('Your request has been sent, please wait.', 'Bolt status');
          return new TERMINAL.LockerStatusSuccess();
        })
      );
    })
  ));


  lockerInsertOrUpdate$ = createEffect(() => this.actions.pipe(
    ofType<TERMINAL.LockerInsertOrUpdateRequest>(TERMINAL.LOCKER_INSERT_OR_UPDATE_REQUEST),
    switchMap(action => {
      return this.terms.insertOrUpdateLocker(action.payload.locker)
        .pipe(
          map(() => {
            action.payload.successCallback();
            return new TERMINAL.LoadTerminalsRequest();
          })
        );
    })
  ));

  constructor(private actions: Actions,
              private terms: TerminalMngService,
              private store: Store<fromRoot.State>,
              public dialog: MatDialog,
              private toastr: ToastrService) {
  }
}

