import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromRoot from '../../../../../app.reducer';
import * as MAP from '../../../../../ngrx/map.actions';
import * as UI from '../../../../../ngrx/ui.actions';

import { IAlarmTransportowy } from '../../../interfaces';
import { IZdarzeniePojazduDTO } from '../../../../../models/dto/alert/zdarzenie-pojazdu';
import { IZgloszenieKierowcyDTO } from '../../../../../models/dto/alert/zgloszenie-kierowcy';
import { MomentHelper } from '../../../../../helpers';
import { LocationService } from '../../../../map/services/location.service';
import { GenericPoint } from '../../../../../models/map';
import { AtLeast } from '../../../../../helpers/types';
import { CoordsNamedPL } from '../../../../shared/interfaces';
import { SvgMapIcon, ToastType } from '../../../../../helpers/enum';

@Component({
  selector: 'app-event-tile-list',
  templateUrl: './event-tile-list.component.html',
  styleUrls: ['./event-tile-list.component.scss']
})
export class EventTileListComponent implements OnInit {

  @Input() transportAlert: IAlarmTransportowy;
  @Input() type = '';

  events: (IZdarzeniePojazduDTO | IZgloszenieKierowcyDTO)[] = [];

  constructor(private store: Store<fromRoot.State>) {
  }

  ngOnInit(): void {
    this.events = [...this.transportAlert.zdarzeniaPojazdu || [], ...this.transportAlert.zgloszeniaKierowcy || []]
      .sort((a, b) => MomentHelper.compareDates(b.czas, a.czas));

    if (this.type === 'map-preview') {
      const points = this.events
        .filter(item => LocationService.coordsValidator(item))
        .map(item => {
          const event = item as AtLeast<IZdarzeniePojazduDTO | IZgloszenieKierowcyDTO, CoordsNamedPL>;
          const coords = LocationService.getCoordsNamedPL(event);
          return {
            ...coords,
            label: event.czas,
            id: event.id,
            iconType: SvgMapIcon.ALERT_EVENT
          } as GenericPoint;
        });

      if (points.length < 1) {
        this.store.dispatch(UI.showUserMessage({
          message: {
            message: 'No points to present on map.',
            type: ToastType.WARN,
            title: 'Map preview',
          }
        }));
        return;
      }
      this.store.dispatch(MAP.AdhocPoints({points}));
    }
  }

}
