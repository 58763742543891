import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import * as fromTransset from '../modules/transport-set/ngrx/transset.reducer';
import { Kierowca, ZestawTransportowy } from '../modules/transport-set/interfaces';
import { PunktSpedycyjny, ZestawTransportowyBase } from '../models/dto/transportSets';

interface Selectors {
  getFullTranssetListNew: MemoizedSelector<any, ZestawTransportowyBase[]>;
  getFullTranssetListNewById: (setId: number) => MemoizedSelector<any, ZestawTransportowyBase>;
  getShippingPoints: MemoizedSelector<any, PunktSpedycyjny[]>;
  getShippingPointById: (id: number) => MemoizedSelector<any, PunktSpedycyjny>;
  getShippingPointProcessing: () => MemoizedSelector<any, boolean>;
  getNonLiquidatedAndNonEmptyFullCurrentSets: MemoizedSelector<any, ZestawTransportowy[]>;
  getAvailableDrivers: MemoizedSelector<any, Kierowca[]>;
  getSaveRouteInProgress: MemoizedSelector<any, boolean>;
}

export class TranssetSelectors {
  static _state = createFeatureSelector<fromTransset.State>(fromTransset.featureName);

  static _selectors: Selectors;

  static getSelectors() {
    this._selectors = {
      getFullTranssetListNew: createSelector(this._state, fromTransset.getFullCurrentSetsNew),
      getFullTranssetListNewById: (setId) =>  createSelector(this._state, data => data.fullSetsNew.find(p => p.id === setId)),
      getShippingPoints: createSelector(this._state, fromTransset.getShippingPoints),
      getShippingPointById: (id) => createSelector(this._state, data => data.shippingPoints.find(p => p.id === id)),
      getShippingPointProcessing: () => createSelector(this._state, fromTransset.getShippingPointProcessing),
      getNonLiquidatedAndNonEmptyFullCurrentSets: createSelector(this._state, fromTransset.getNonLiquidatedAndNonEmptyFullCurrentSets),
      getAvailableDrivers: createSelector(this._state, fromTransset.getAvailableDrivers),
      getSaveRouteInProgress: createSelector(this._state, fromTransset.getSaveRouteInProgress),
    };

    return this._selectors;
  }
}
