import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  combineLatest,
  fromEvent,
  Observable,
  Subject,
} from 'rxjs';
import { filter, startWith, take, takeUntil } from 'rxjs/operators';

import * as fromRoot from '../../../../app.reducer';
import * as TRANSSET from '../../../transport-set/ngrx/transset.actions';

import { MatStepper } from '@angular/material/stepper';
import { JwtBodyData, RightDrawer } from 'src/app/models/dto';
import { DraggedPoint, MapChannelEventType } from 'src/app/models/map';
import { LocationData } from 'src/app/models/map/geocode';
import { ReverseGeocodeResponse } from 'src/app/models/map/reverseGeocode';
import { CoordsMap } from 'src/app/modules/shared/interfaces';
import { MapPointsGuard, PointGuard } from 'src/app/modules/shared/type-guards';
import { Strings } from '../../../../helpers';
import { ShippingPointForm } from '../../../../models/form/shipping-point-form';
import { HereService } from '../../services/here.service';
import { LocationService } from '../../services/location.service';
import { PointSearchBase } from '../point-search-base/pont-search-base';

interface CallbackData {
  label: string;
  fn: () => void;
}

@Component({
  selector: 'app-shipping-point',
  templateUrl: './shipping-point.component.html',
  styleUrls: ['./shipping-point.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShippingPointComponent implements OnInit, OnDestroy {
  static readonly myname = Strings.getObjectHash('ShippingPointComponent');

  @ViewChild('shippingPointStepper', { static: false })
  private stepper: MatStepper;

  processing$: Observable<boolean> = this.store.select(
    fromRoot.selectors.transset.getShippingPointProcessing()
  );
  destroyed$ = new Subject();
  editMode$ = new BehaviorSubject(false);

  form = new ShippingPointForm();
  callbackData: CallbackData;
  searchLocation: PointGuard;
  searchBase: PointSearchBase = new PointSearchBase();
  rangeMessage = false;

  constructor(
    protected hereService: HereService,
    protected store: Store<fromRoot.State>,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.setupForm();

    if (HereService.isInit) {
      this.setupContextMenu();
      this.setupDragEvent();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

  geocoded(locationData: LocationData | undefined): void {
    this.form.patchFromGeocodeResponse(locationData);
    this.hereService.clearAllPoints();
    this.hereService.addPointDraggable(locationData, {
      pointId: locationData.LocationId,
    });
    this.searchLocation = locationData;
    this.hideRangeMessage();
  }

  enableForm(point: PointGuard): void {
    if (typeof point !== 'undefined') {
      this.form.enable({ emitEvent: false });
    }
  }

  onSubmit(): void {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }
    if (this.form.value.id) {
      this.store.dispatch(
        TRANSSET.updateShippingPointRequest({
          punkt: this.form.getUpdatedModel(),
        })
      );
    } else {
      this.store.dispatch(
        TRANSSET.newShippingPointRequest({
          punkt: this.form.getUpdatedModel(),
        })
      );
    }
  }

  get position(): string {
    if (typeof this.searchLocation === 'undefined') {
      return '';
    }

    if (MapPointsGuard.isLocationData(this.searchLocation)) {
      const { Latitude, Longitude } = this.searchLocation.DisplayPosition;
      return `lat: ${Latitude}, lon: ${Longitude}`;
    } else if (MapPointsGuard.isReverseGeocodeResponse(this.searchLocation)) {
      const { latitude, longitude } =
        this.searchLocation.location.displayPosition;
      return `lat: ${latitude}, lon: ${longitude}`;
    }
  }

  private setupForm(): void {
    combineLatest([
      this.store
        .select(fromRoot.selectors.ui.getRightDrawerInfo)
        .pipe(
          filter(
            (data) =>
              (data.componentName === ShippingPointComponent.myname &&
                typeof data.componentData !== 'undefined') ||
              data.isOpened === false
          )
        ),
      this.store.select(fromRoot.selectors.auth.getUserTokenDetails),
    ])
      .pipe(take(1))
      .subscribe(([data, userToken]: [RightDrawer, JwtBodyData]) => {
        if (data.componentData?.shippingPoint) {
          this.form.patchFromModel(data.componentData.shippingPoint);
          this.editMode$.next(true);
        } else {
          this.form.patchValue({
            id_firma: +userToken.jti,
          });

          this.form.disable();
        }
        if (data.componentData?.callbackData) {
          this.callbackData = data.componentData.callbackData;
        }
      });

    combineLatest([
      this.form.get('panstwo').valueChanges.pipe(startWith('')),
      this.form.get('miasto').valueChanges.pipe(startWith('')),
      this.form.get('kod').valueChanges.pipe(startWith('')),
      this.form.get('ulica').valueChanges.pipe(startWith('')),
    ])
      .pipe(
        takeUntil(this.destroyed$),
        filter((values) => values.some((value) => value))
      )
      .subscribe((vals) => {
        this.showRangeMessage();
      });
  }

  private setupContextMenu(): void {
    fromEvent(HereService.map, 'contextmenu')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (event: { items: unknown[]; viewportX: number; viewportY: number }) => {
          const coords: CoordsMap = HereService.map.screenToGeo(
            event.viewportX,
            event.viewportY
          );

          event.items.push(
            new HereService.H.util.ContextItem({
              label: LocationService.getMapCoordsStr(coords),
            }),
            HereService.H.util.ContextItem.SEPARATOR,
            new HereService.H.util.ContextItem({
              label: 'Add shipping point from here',
              callback: async () => {
                const reverseGeocodeResponse = await this.updateSearchLocation(
                  coords
                );
                const location = reverseGeocodeResponse.location;

                this.hereService.clearAllPoints();
                this.hereService.addPointDraggable(location.displayPosition, {
                  pointId: location.locationId,
                });
              },
            })
          );
        }
      );
  }

  private setupDragEvent(): void {
    HereService.channel
      .asObservable()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(async (event) => {
        switch (event.eventType) {
          case MapChannelEventType.PointDragend:
            this.updateSearchLocation((event.value as DraggedPoint).geo);
            break;
        }
      });
  }

  private async updateSearchLocation(
    coords: CoordsMap
  ): Promise<ReverseGeocodeResponse> {
    const reverseGeocodeResponse = await this.hereService.reverseGeocode(
      coords
    );
    this.form.patchFromReverseGeocodeResponse(reverseGeocodeResponse);
    this.searchLocation = { ...reverseGeocodeResponse };
    this.hideRangeMessage();

    return new Promise<ReverseGeocodeResponse>((resolve) => {
      resolve(reverseGeocodeResponse);
    });
  }

  private showRangeMessage(): void {
    this.rangeMessage = true;
    this.cd.markForCheck();
  }

  private hideRangeMessage(): void {
    this.rangeMessage = false;
    this.cd.markForCheck();
  }
}
