import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { filter, map, take } from 'rxjs/operators';

import { BaseDialogConfig, BaseYesNoConfig } from '../../../shared/interfaces';
import { BaseConfirmationDialogComponent, BaseYesNoDialogComponent } from '../../../shared/dialogs';
import { RightDrawerService } from '../../../shared/services';
import { BoltState, DateFormat, MapViewRoute, Privs, ToastType, TransportFormStep, UserRoles } from '../../../../helpers/enum';

import * as TRANSPORT from '../../ngrx/transport.actions';
import * as fromTransport from '../../ngrx/transport.reducer';
import * as fromRoot from '../../../../app.reducer';
import * as UI from '../../../../ngrx/ui.actions';
import { showUserMessage } from '../../../../ngrx/ui.actions';
import * as MAP from '../../../../ngrx/map.actions';
import * as TERMINAL from '../../../../ngrx/terminal.actions';

import { Strings, TransportHelper } from '../../../../helpers';
import { ITransportEntity } from '../../interfaces/transport-entity';
import { TransitRelatedAlertsComponent } from '../transit-related-alerts/transit-related-alerts.component';
import { SearchTextFilter } from '../../models/search-text-filter';
import { AllFiltersMustMatchFilter } from '../../models/all-filters-must-match-filter';
import { DispatcherFilter } from '../../models/dispatcher-filter';
import { TransportProgressStateFilter } from '../../models/transport-progress-state-filter';
import { Locker } from '../../../../models/dto/vehicle';
import { VehicleFromTransitPipe } from '../../../shared/pipes';
import { TerminalMsg, TransportMsg } from '../../../../messages';
import { HasPrivilegesDirective } from '../../../access-control/directives/by-privilege/has-privileges.directive';
import { TransportOverviewComponent } from '../transport-overview';
import { LockerHelper } from '../../../shared/helpers';
import { TransportTableColumnsEnum } from '../../enum/transport-table-columns';
import { SirenDialogComponent } from '../siren-dialog/siren-dialog.component';
import { BoltDialogComponent } from '../bolt-dialog/bolt-dialog.component';

@Component({
  selector: 'app-table-list',
  templateUrl: './table-list.component.html',
  styleUrls: ['./table-list.component.scss']
})
export class TableListComponent implements OnDestroy {
  @Input() onlyAssigned = false;

  ePrivs = Privs;
  eBoltState = BoltState;
  df = DateFormat;

  transportTableColumns: typeof TransportTableColumnsEnum = TransportTableColumnsEnum;
  displayedColumns: string[] = Object.values(this.transportTableColumns);
  transportTableList = new MatTableDataSource<ITransportEntity>([]);

  @ViewChild(MatSort, {static: false}) set content(sort: MatSort) {
    this.transportTableList.sort = sort;
  }

  subs = new Subscription();
  processing$: Observable<Record<number, boolean>> = this.store.select(state => state.transit.processingTransports);
  isDataLoading$: Observable<boolean> = this.store.select(state => state.transit.isDataLoading);

  constructor(
    private store: Store<fromRoot.State>,
    private rightDrawer: RightDrawerService,
    private dialog: MatDialog,
    private readonly router: Router
  ) {
    this.subs.add(
      combineLatest([
        this.store.select(fromTransport.getAllTransits),
        this.store.select(fromRoot.selectors.auth.getUserInfo),
        this.store.select(fromTransport.getTransportFilter),
      ])
        .pipe(
          filter(r => r[1] !== undefined && r[1].role !== undefined),
          map(([transits, user, transportFilter]) => {
            if (this.onlyAssigned) {
              return {
                transits: [...transits].filter(elem => TransportHelper.isValidTransset(elem.zestaw)),
                user,
                filter: new AllFiltersMustMatchFilter([
                  new TransportProgressStateFilter(transportFilter.state),
                  new SearchTextFilter(transportFilter.text),
                  new DispatcherFilter(+user.userId),
                ])
              };
            }

            return {
              transits: [...transits].filter(elem => TransportHelper.isValidTransset(elem.zestaw)),
              user,
              filter: new AllFiltersMustMatchFilter([
                new TransportProgressStateFilter(transportFilter.state),
                new SearchTextFilter(transportFilter.text),
              ])
            };
          }),
          map((obj) => {
            if (obj.user.role.id !== UserRoles.CM) {
              return obj.transits.filter((transit) => {
                return obj.filter.isMatching(transit);
              });
            }
            return [...obj.transits].filter(t => t.centrum !== null).filter(
              (transit) => {
                return obj.filter.isMatching(transit);
              }
            );
          })
        )
        .subscribe((value) => {
          this.transportTableList.data = value;
          this.assignSortingDataAccessor();
        }));
  }

  assignSortingDataAccessor(): void {
    this.transportTableList.sortingDataAccessor = (element, property) => {
      switch (property) {
        case this.transportTableColumns.Status:
          return element.stanPracy?.stanPracy;
        case this.transportTableColumns.Name:
          if (element.zestaw?.truckSet.length > 0) {
            const samochod = element?.zestaw?.truckSet[0]?.samochod;
            return `${samochod.nrRej} / ${samochod?.nrRej}`;
          }
          return '';
        case this.transportTableColumns.Route:
          return `${element?.mscWyjazdu?.miasto} - ${element?.mscDocelowe?.miasto}`;
        case this.transportTableColumns.Departure:
          return element?.czasWyjazdu ? Date.parse(element?.czasWyjazdu) : 0;
        case this.transportTableColumns.Expected_arrival:
          if (element?.czasPrzyjazdu) {
            return Date.parse(element?.czasPrzyjazdu);
          }
          if (element?.planowanyCzasPrzyjazdu) {
            return Date.parse(element?.planowanyCzasPrzyjazdu);
          }
          return 0;
        case this.transportTableColumns.Dispatcher:
          return `${element?.dyspozytor?.imie} ${element?.dyspozytor?.nazwisko}`;
      }
    };
  }

  trackByMethod(_index: number, element: ITransportEntity): string {
    const toHash = element.id + '-'
      + element.uwagi
      + JSON.stringify(element.zestaw) + '-'
      + JSON.stringify(element.mscDocelowe) + '-'
      + JSON.stringify(element.mscWyjazdu) + '-'
      + JSON.stringify(element.planowanyCzasPrzyjazdu) + '-'
      + JSON.stringify(element.czasWyjazdu) + '-'
    ;
    return Strings.getObjectHash(toHash);
  }

  showRelatedAlerts(transit: ITransportEntity) {
    if (!!transit.id) {
      this.rightDrawer.open(TransitRelatedAlertsComponent, {transsetId: transit.id});
    } else {
      this.rightDrawer.close();
      this.store.dispatch(UI.showUserMessage({
        message: {
          title: 'No data to present', message: TransportMsg.NO_INFORMATION, type: ToastType.WARN
        }
      }));
    }
  }

  showBoltCode(transport: ITransportEntity): void {
    const terminalId = TransportHelper.getBasicVehicle(transport.zestaw.trailerSet)
      .naczepaTerminal?.find(t => t.czasUsuniecia === null)?.terminal.id;

    if (terminalId === undefined) {
      this.store.dispatch(showUserMessage({
        message: {
          message: TransportMsg.NO_TRACKING_DEVICE, type: ToastType.WARN
        }
      }));
      return;
    }

    this.store.dispatch(new TERMINAL.GetBoltPinRequest({terminalId}));
    const lockerSub = this.store.select(fromRoot.selectors.devices.getBoltPin(terminalId))
      .pipe(filter(r => r !== undefined), take(1))
      .subscribe(data => {
        const informationConfig: BaseDialogConfig = {
          title: 'Bolt open code',
          content: '',
          confirmationLabel: 'Ok'
        };
        if (data.kod_otwarcia) {
          informationConfig.content = `Current bolt PIN: <span class="red">${data.kod_otwarcia}</span>`;
        } else {
          informationConfig.content = 'Open pin is not set.';
        }

        this.dialog.open(BaseConfirmationDialogComponent, {
          data: informationConfig,
          id: 'BaseConfirmationDialogComponent-locker-safe-code',
          position: {top: '7%'}
        });
      });

    setTimeout(() => {
      if (!lockerSub.closed) {
        this.store.dispatch(showUserMessage({
          message: {
            message: 'Timeout occurred, can\'t obtain locker information.', type: ToastType.ERROR
          }
        }));
        lockerSub.unsubscribe();
      }
    }, 3000);
  }

  showDetails(transit: ITransportEntity) {
    const {id: transportId} = transit;
    this.store.dispatch(TRANSPORT.loadTransportByIdRequest({transportId}));
    this.subs.add(
      this.store.select(fromTransport.getCurrentTransit)
        .pipe(filter(r => r?.id === transportId))
        .subscribe(transport => {
          this.rightDrawer.open(TransportOverviewComponent, {transport});
        })
    );
  }

  manageBolt(transport: ITransportEntity): void {
    const transportLocker = this.getLocker(transport);

    const boltDialogRef = this.dialog.open(
      BoltDialogComponent,
      { data: {transport, transportLocker}, width: '500px' }
    );

    boltDialogRef.afterClosed().pipe(take(1)).subscribe(() => {
      this.store.dispatch(TRANSPORT.loadAllTransitsRequest());
    });
  }

  manageSiren(transport: ITransportEntity): void {
    const sirenDialogRef = this.dialog.open(
      SirenDialogComponent,
      { data: transport, width: '500px' }
    );

    sirenDialogRef.afterClosed().pipe(take(1)).subscribe(() => {
      this.store.dispatch(TRANSPORT.loadAllTransitsRequest());
    })
  }

  showOnTheMap(transport: ITransportEntity): void {
    const terminalList = TransportHelper.getTerminalIdList(transport);

    if (terminalList.length < 1) {
      this.store.dispatch(UI.showUserMessage({message: {message: TransportMsg.NO_TRACKING_DEVICE, type: ToastType.WARN}}));
      return;
    }

    this.store.dispatch(MAP.ViewerExtraData({extraData: terminalList}));
    this.router.navigate(['/map-view', MapViewRoute.TRANSPORT_PROGRESS, transport.id])
      .then(() => {
        this.store.dispatch(UI.showUserMessage({
          message: {
            message: TransportMsg.PROGRESS_GATHERING_DATA,
            type: ToastType.INFO
          }
        }));
      });
  }

  editDetails(transport: ITransportEntity): void {
    let step = TransportFormStep.Overview;
    if (transport.kierowcaPrzejazdList.length < 1) {
      step = TransportFormStep.Drivers;
    }
    this.router.navigate(['transport', 'edit', transport.id, step]);
  }

  deleteTransport(transport: ITransportEntity) {
    if (transport.zakonczony || !transport.rozpoczety) {
      const config: BaseYesNoConfig = {
        title: 'Transport deletion',
        content: TransportMsg.DELETE,
        yesAction: () => this.store.dispatch(TRANSPORT.deleteTransitRequest({transitId: transport.id})),
        yesLabel: 'Delete',
        yesColor: 'warn',
        noLabel: 'Cancel',
        noColor: 'primary',
        autoClosure: true,
      };
      this.dialog.open(BaseYesNoDialogComponent, {
        data: config,
        id: 'BaseYesNoDialogComponent-Transport',
        position: {top: '7%'}
      });
    } else {
      const config: BaseDialogConfig = {
        title: 'Transport deletion',
        content: TransportMsg.DELETE_NOT_FINALIZED,
        confirmationLabel: 'Close',
      };
      this.dialog.open(BaseConfirmationDialogComponent, {
        data: config,
        id: 'BaseConfirmationDialogComponent-Transport',
        position: {top: '7%'}
      });
    }
  }

  generateReport(transportId: number): void {
    this.store.dispatch(TRANSPORT.generateReportRequest({transportId: transportId}));
  }

  getLocker(transport: ITransportEntity): Locker | undefined {
    const trailer = VehicleFromTransitPipe.transform(transport.zestaw.trailerSet);
    return trailer.naczepaTerminal[0]?.terminal.rygle[0] || undefined;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.rightDrawer.close();
  }

  canEdit(privs: Privs[]): boolean {
    return HasPrivilegesDirective.check(privs);
  }

  stopTransport(transport: ITransportEntity): void {
    const config: BaseYesNoConfig = {
      title: 'Stopping transport.',
      content: `Are you sure you want to proceed?`,
      yesLabel: 'Yes',
      yesColor: 'warn',
      noLabel: 'No',
      noColor: 'primary',
      autoClosure: true,
      yesAction: () => {
        this.store.dispatch(TRANSPORT.stopTransportRequest({
          transportId: transport.id,
          onSuccess: () => this.store.dispatch(TRANSPORT.loadAllTransitsRequest())
        }));
      }
    };

    this.dialog.open(BaseYesNoDialogComponent, {
      data: config,
      id: 'BaseYesNoDialogComponent-transport',
      position: {top: '7%'}
    });
  }
}
