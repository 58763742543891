import { Injectable, TemplateRef, ViewContainerRef } from '@angular/core';

interface TargetData {
  viewContainerRef: ViewContainerRef | undefined;
  templateRef: TemplateRef<unknown> | undefined;
}

@Injectable({
  providedIn: 'root'
})
export class PortalSlotService {
  private targets: Map<string, TargetData> = new Map<string, TargetData>();

  private static createEmbeddedView(targetData: TargetData | undefined): void {
    if (targetData?.viewContainerRef && targetData?.templateRef) {
      targetData.viewContainerRef.createEmbeddedView(targetData.templateRef);
    }
  }

  addTarget(targetName: string, viewContainerRef: ViewContainerRef): void {
    this.targets.set(targetName, {viewContainerRef, templateRef: this.getTarget(targetName)?.templateRef});
    PortalSlotService.createEmbeddedView(this.targets.get(targetName));
  }

  isAttached(targetName: string): boolean {
    return this.targets.has(targetName) && this.getTarget(targetName).templateRef !== null;
  }

  clear(targetName: string): void {
    this.getTarget(targetName)?.viewContainerRef?.clear();
    this.clearTemplateReference(targetName);
  }

  attach(targetName: string, templateRef: TemplateRef<unknown>): void {
    this.clear(targetName);
    this.addTemplateReference(targetName, templateRef);
    PortalSlotService.createEmbeddedView(this.targets.get(targetName));
  }

  private addTemplateReference(targetName: string, templateRef: TemplateRef<unknown>): void {
    this.targets.set(targetName, {viewContainerRef: this.getTarget(targetName)?.viewContainerRef, templateRef});
  }

  private clearTemplateReference(targetName: string): void {
    this.targets.set(targetName, {viewContainerRef: this.getTarget(targetName)?.viewContainerRef, templateRef: null});
  }

  private getTarget(targetName: string): TargetData | null {
    return this.targets.has(targetName) ? (this.targets.get(targetName) as TargetData) : null;
  }
}
