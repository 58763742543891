import { Action, createReducer, on } from '@ngrx/store';
import { Firma } from '../models/dto/company';
import { loadCompanyListSuccess } from './general.actions';

export interface State {
  firmy: Firma[];
}

export const initialState: State = {
  firmy: []
};

const generalReducer = createReducer(
  initialState,
  on(loadCompanyListSuccess, (state, {companies}) => ({...state, firmy: companies}))
);

export function reducer(state: State | undefined, action: Action) {
  return generalReducer(state, action);
}

export const getCompanyList = (state: State) => state.firmy;
