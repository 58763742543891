import { Directive, ElementRef, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, combineLatest, interval, Subscription } from 'rxjs';
import { debounce, debounceTime, filter, take } from 'rxjs/operators';

import { JwtBodyData } from '../../../models/dto';
import { environment } from '../../../../environments/environment';
import { PrivilegeHelper } from '../helpers';
import { uniq } from 'lodash';
import { Privs } from '../../../helpers/enum';

@Directive({
  selector: '[appCommon]'
})
export class CommonDirective extends PrivilegeHelper implements OnDestroy {

  protected static tokenDetails$ = new BehaviorSubject<JwtBodyData>(null);
  subs = new Subscription();
  protected PRIVILEGES: number[] = [];
  protected ROLE: number;
  protected type = 'privileges';

  constructor(protected element: ElementRef,
              protected templateRef: TemplateRef<any>,
              protected viewContainer: ViewContainerRef) {
    super();
    if (CommonDirective.privilegeMap.allPrivileges.length > 5) {
      setTimeout(() => this.updateView());
    } else {
      this.subs.add(
        CommonDirective.privilegeMap.allPrivilegesNum$
          .pipe(filter(r => r > 5), debounceTime(1))
          .subscribe(() => this.updateView())
      );
    }
  }

  static get userTokenDetails(): JwtBodyData {
    return CommonDirective.tokenDetails$.getValue();
  }

  static set userTokenDetails(data: JwtBodyData) {
    CommonDirective.tokenDetails$.next(data);
  }

  static waitAndTest(fn: (arg?: any) => void) {
    combineLatest([
      CommonDirective.privilegeMap.allPrivilegesNum$,
      interval(250)
    ])
      .pipe(
        debounce((r) => {
          return r[0] < 100 ? interval(1000) : interval(10);
        }),
        filter(([privNum, iteration]) => iteration > 5 || privNum > 100),
        take(1)
      )
      .subscribe((w) => {
        fn(w);
      });
  }

  /**
   * Check if user contains requested privilege, FALSE if not
   * @param privileges
   * @protected
   */
  protected static checkPermission(privileges: Privs[]): boolean {
    if (!CommonDirective.tokenDetails$.getValue()) {
      return false;
    }
    const allPrivs = uniq(
      privileges
        .map(privId => CommonDirective.privilegeMap.getById(privId))
        .filter(r => r.length > 0)
        .reduce((acc, data) => [...acc, ...data], [])
    );

    return CommonDirective.tokenDetails$.getValue().perm.find(r => allPrivs.indexOf(+r) !== -1) !== undefined;
  }


  /**
   * Check if user contains requested ROLE, FALSE if not
   * @param role
   * @protected
   */
  protected static checkRole(role: number): boolean {
    if (!CommonDirective.tokenDetails$.getValue()) {
      return false;
    }

    return CommonDirective.tokenDetails$.getValue().role_id === role;
  }


  ngOnDestroy() {
    // todo: wywalić to zabezpieczenie dla użytkownika id=1
    if (!environment.production && CommonDirective.tokenDetails$.getValue()?.sub === '1') {
      return true;
    }
    this.subs.unsubscribe();
  }

  protected updateView() {
    if (this.validate()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  /**
   * Should not be overridden
   * @protected
   */
  protected validate(): boolean {
    if (this.type === 'privileges') {
      if (this.PRIVILEGES.length > 0) {
        // dodanie także uprawnień nadrzędnych
        const newPrivileges = this.PRIVILEGES
          .map(privId => CommonDirective.privilegeMap.getById(privId))
          .filter(r => r.length > 0)
          .reduce((acc, data) => [...acc, ...data], []);
        this.PRIVILEGES = uniq(newPrivileges);
      }
      return CommonDirective.checkPermission(this.PRIVILEGES);
    } else if (this.type === 'roles') {
      if (!!this.ROLE) {
        return CommonDirective.checkRole(this.ROLE);
      }

      return false;
    }
  }
}
