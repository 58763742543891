import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { IRodzajAlarmuDTO } from '../../../models/dto/alert/rodzaj-alarmu';
import { IRodzajAlarmu } from '../../alerts/interfaces/rodzaj-alarmu';
import { EnvHelper } from '../../../helpers';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventsConfigService {
  apiConfig = environment.apiModules.Alert;
  onAlertTypeEditClicked$ = new Subject<IRodzajAlarmu>();
  onCancelAlertTypeEdition$ = new Subject<any | null>();
  onEditedAlertTypeUpdate$ = new Subject<IRodzajAlarmu>();

  constructor(private http: HttpClient) {
  }

  getAlertTypes(): Observable<IRodzajAlarmu[]> {
    return this.http.get<IRodzajAlarmuDTO[]>(
      EnvHelper.getApiUrl() + this.apiConfig.rodzaj_alarmu
    );
  }

  addAlertType(index: number, type: string, description: string, assessment: number): Observable<IRodzajAlarmu> {
    return this.http.post<IRodzajAlarmuDTO>(
      EnvHelper.getApiUrl() + this.apiConfig.rodzaj_alarmu,
      {
        'id': index,
        'ocenaR': assessment,
        'opis': description,
        'rodzaj': type,
      }
    );
  }

  updateAlertType(index: number, assessment: number, description: string, type: string): Observable<IRodzajAlarmu> {
    return this.http.put<IRodzajAlarmuDTO>(
      EnvHelper.getApiUrl() + this.apiConfig.rodzaj_alarmu + '/' + index,
      {
        'id': index,
        'ocenaR': assessment,
        'opis': description,
        'rodzaj': type,
      }
    );
  }

  deleteAlertType(index: number): Observable<any> {
    return this.http.delete(
      EnvHelper.getApiUrl() + this.apiConfig.rodzaj_alarmu + '/' + index
    );
  }

}
