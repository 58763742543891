<div class="container">

  <mat-card class="example-card">
    <mat-card-header>
      <mat-card-title>Twoje dane:</mat-card-title>
      <mat-card-subtitle>{{myInfo.name}}, {{myInfo.surname}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field>
        <mat-select placeholder="Company"
                    [formControl]="company"
                    (valueChange)="companySelected($event)"
        >
          <mat-option value="0">None</mat-option>
          <mat-option value="{{company.id}}" *ngFor="let company of firmy$ | async">
            {{company.nazwa}}, {{company.miasto}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </mat-card-content>
    <mat-card-actions>
      <div fxFlex fxLayout="column" fxLayoutGap="5px">
        <div>
          <button mat-raised-button color="primary" (click)="passwordReset()">
            Change password
          </button>
        </div>
        <div fxFlex fxLayout="row" fxLayoutGap="5px" *ngIf="myInfo.role.id == 1">
          <button mat-raised-button color="accent" (click)="reloadProxy()">
            Refresh privileges on PROXY
          </button>
          <button mat-raised-button color="accent" (click)="reloadProxyRouting()">
            Refresh routing on PROXY
          </button>
        </div>
      </div>
    </mat-card-actions>
  </mat-card>
</div>
