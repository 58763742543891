import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { DateFormat } from '../../../helpers/enum';
import { ITransportEntity } from '../interfaces/transport-entity';

@Pipe({
  name: 'getTransportDatesTooltip'
})
export class GetTransportDatesTooltipPipe implements PipeTransform {

  transform(transport: ITransportEntity): string {
    const departure = moment(transport.czasWyjazdu)
      .format(DateFormat.PIPE_DATETIME_LABEL_MOMENT);
    const arrival = moment(transport.planowanyCzasPrzyjazdu || transport.czasPrzyjazdu)
      .format(DateFormat.PIPE_DATETIME_LABEL_MOMENT);
    return `Departure: ${departure}, Arrival: ${arrival}`;
  }

}
