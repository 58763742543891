<div class="container" fxFlex fxLayout="row" fxLayoutGap="4px">
  <div class="icon">
    <mat-icon class="material-icons-outlined">info</mat-icon>
  </div>
  <div class="transit" fxFlex fxLayout="column" fxLayoutGap="8px">
    <p class="id">Transit no.: {{transit.id}}/{{transit.zestaw.id}}</p>
    <div class="info column" fxFlex fxLayout="column">
      <span class="label">
        Description:
      </span>
      <span class="value" [matTooltip]="transit.uwagi">{{transit.notatki}}</span>
    </div>
    <div class="cm column" fxFlex fxLayout="column">
      <span class="label">Monitored by:</span>
      <span class="value">{{transit.centrum ? transit.centrum.nazwa : 'None'}}</span>
    </div>
    <div class="assignee column" fxFlex fxLayout="column">
      <span class="label">Assigned to:</span>
      <span class="value" [matTooltip]="transit.dyspozytor ? transit.dyspozytor.email : ''">
        {{transit.dyspozytor ? transit.dyspozytor.nazwisko + ' ' + transit.dyspozytor.imie : 'None'}}
      </span>
    </div>
    <div class="work-state column" fxFlex fxLayout="column">
      <span class="label">Work state:</span>
      <span class="value"> {{transit.stanPracy.stanPracy}}</span>
    </div>
  </div>
</div>
