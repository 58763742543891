<div *ngIf="(relatedTickets$ | async); else noTickets" fxLayout="column" fxLayoutGap="4px">
  <div *ngFor="let ticket of relatedTickets$ | async" class="ticket-row"
       [routerLink]="['/events/tickets', ticket.id]"
       fxLayout="row" fxLayoutGap="8px">
    <app-text-field label="Id" [value]="ticket.id"></app-text-field>
    <app-text-field label="Start" [value]="ticket.dataUtw | date : df.PIPE_DATETIME_LABEL"></app-text-field>
    <app-text-field label="End" [value]="ticket.dataZak | date : df.PIPE_DATETIME_LABEL"></app-text-field>
  </div>
</div>

<ng-template #noTickets>
  <span>{{message}}</span>
</ng-template>
