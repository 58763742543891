<div class="dashboard-container" fxFlex fxLayoutAlign="center center">
  <div
    class="dashboard-body"
    fxFlex
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <div class="logo"></div>

    <div class="title">
      <h1>Welcome {{ (username$ | async).name }}. Let's get started.</h1>
    </div>

    <div class="sub-title">
      <h5>
        You have
        <span class="alerts-quantity">{{ (alerts$ | async).length }} new</span>
        alerts.
      </h5>
    </div>

    <div class="manage-transport-btn">
      <button mat-raised-button color="primary" routerLink="/transport/list">Manage your transports</button>
    </div>
  </div>
</div>
