import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { AlertTypeFormComponent, AlertTypeListComponent, ConfigComponent, MainComponent } from './components';
import { IsAuthenticatedGuard } from '../../guards';
import { AlertsComponent, TransportAlertDetailsComponent, TransportAlertListComponent } from '../alerts/pages';
import { EntrypointComponent } from '../tickets/entrypoint/entrypoint.component';
import { TicketDetailsComponent, TicketListComponent } from '../tickets/page';
import { DriverReportsComponent } from '../driver-reports/driver-reports.component';
import { DriverReportListComponent } from '../driver-reports/pages/driver-report-list/driver-report-list.component';

const routes: Routes = [
  {
    path: 'events', data: {breadcrumb: 'Alerts'}, component: MainComponent, children: [
      {
        path: 'config', data: {breadcrumb: 'Configuration'}, component: ConfigComponent, children: [
          {path: '', data: {breadcrumb: null}, pathMatch: 'full', redirectTo: 'dictionary'},
          {path: 'dictionary', data: {breadcrumb: 'Alert types'}, component: AlertTypeListComponent},
          {path: 'dictionary/:id', data: {breadcrumb: null}, component: AlertTypeFormComponent},
        ]
      },
      {
        path: 'alerts', data: {breadcrumb: null}, component: AlertsComponent, children: [
          {path: '', data: {breadcrumb: null}, pathMatch: 'full', redirectTo: 'transport'},
          {path: 'transport', data: {breadcrumb: 'Transport alerts'}, component: TransportAlertListComponent},
          {path: 'transport/:id', data: {breadcrumb: 'Alert on '}, component: TransportAlertDetailsComponent}
        ]
      },
      {
        path: 'config', data: {breadcrumb: 'Configuration'}, component: ConfigComponent, children: [
          {path: '', data: {breadcrumb: null}, pathMatch: 'full', redirectTo: 'dictionary'},
          {path: 'dictionary', data: {breadcrumb: 'Alert types'}, component: AlertTypeListComponent},
          {path: 'dictionary/:id', data: {breadcrumb: null}, component: AlertTypeFormComponent},
        ]
      },
      {
        path: 'tickets', data: {breadcrumb: 'Tickets'}, component: EntrypointComponent, children: [
          {path: '', data: {breadcrumb: null}, pathMatch: 'full', redirectTo: 'ticketList'},
          {path: 'ticketList', data: {breadcrumb: 'Ticket list'}, component: TicketListComponent, pathMatch: 'full'},
          {path: ':id', data: {breadcrumb: 'Ticket details'}, component: TicketDetailsComponent}
        ]
      },
      {
        path: 'driver-reports', data: {breadcrumb: 'Driver reports'}, component: DriverReportsComponent, children: [
          {path: '', data: {breadcrumb: null}, pathMatch: 'full', redirectTo: 'driver-report-list'},
          {path: 'driver-report-list', data: {breadcrumb: 'Driver report list'}, component: DriverReportListComponent, pathMatch: 'full'},
        ]
      },
      {path: '', data: {breadcrumb: null}, pathMatch: 'full', redirectTo: 'alerts'},
    ], canActivate: [IsAuthenticatedGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EventsConfigRouting {
}
