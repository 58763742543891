import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { EnvHelper } from '../../../helpers';
import { Event, Ticket, TicketEvents, TicketMaintainer } from '../interfaces';
import { INowaPodjetaAkcjaDTO, IPodjetaAkcjaDTO } from '../../../models/dto/alert/podjeta-akcja';
import { IPodjetaAkcja } from '../../alerts/interfaces';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {
  apiConfig = environment.apiModules.Ticket;

  constructor(private http: HttpClient) {
  }

  getAllTickets(): Observable<Ticket[]> {
    return this.http.get<Ticket[]>(
      EnvHelper.getApiUrl() + this.apiConfig.tickety
    );
  }

  getTicketById(ticketId: number): Observable<Ticket> {
    return this.http.get<Ticket>(
      EnvHelper.getApiUrl() + this.apiConfig.tickety + '/' + ticketId
    );
  }

  getTicketTakenActions(ticketId: number): Observable<TicketEvents[]> {
    return this.http.get<TicketEvents[]>(
      EnvHelper.getApiUrl() + this.apiConfig.tickety + '/' + ticketId + '/podjete_akcje'
    );
  }

  getEvents(): Observable<Event[]> {
    return this.http.get<Event[]>(
      EnvHelper.getApiUrl() + this.apiConfig.akcje
    );
  }

  saveNewTakenAction(action: INowaPodjetaAkcjaDTO): Observable<IPodjetaAkcja> {
    return this.http.post<IPodjetaAkcjaDTO>(
      EnvHelper.getApiUrl() + '/api' + this.apiConfig.podjete_akcje,
      action,
    );
  }

  updateTicketData(ticket: Partial<Ticket> | TicketMaintainer): Observable<unknown> {
    const {id: ticketId, ...data} = {...ticket};
    return this.http.put<unknown>(EnvHelper.getApiUrl() + this.apiConfig.tickety + '/' + ticketId, data);
  }

  loadTicketsForTransport(transportId: number): Observable<Ticket[]> {
    return this.http.get<Ticket[]>(
      EnvHelper.getApiUrl() + this.apiConfig.tickety + '/przejazd/' + transportId
    );
  }

}
