import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'latitude'
})
export class LatitudePipe implements PipeTransform {

  transform(value: string | number): string {
    if (+value >= 0) {
      return value + 'N';
    } else {
      return value + 'S';
    }
  }

}
