<div fxLayout="column" class="mat-elevation-z3 main">
  <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
    <h5>Route</h5>
  </div>
  <div fxLayout="column" fxLayoutGap="8px">

    <ng-container *ngIf="transport && transport.mscWyjazdu; else noStartShippingPoint">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            From
          </mat-panel-title>
          <mat-panel-description>
            {{transport.mscWyjazdu.nazwa}}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div fxFlex fxLayout="column" fxLayoutGap="4px" class="shipping-point">
          <div fxFlex fxLayout="row"><span fxFlex="4em">Country</span><span>{{transport.mscWyjazdu.panstwo}}</span>
          </div>
          <div fxFlex fxLayout="row"><span fxFlex="4em">City</span><span>{{transport.mscWyjazdu.kod}}
            , {{transport.mscWyjazdu.miasto}}</span></div>
          <div fxFlex fxLayout="row"><span fxFlex="4em">Street</span><span>{{transport.mscWyjazdu.ulica}}</span></div>
        </div>
      </mat-expansion-panel>
    </ng-container>

    <div *ngFor="let route of transportRoutes" fxLayout="row" fxLayoutGap="8px"
         fxLayoutAlign="start center"
         class="mat-elevation-z1 route">
      <mat-icon>directions</mat-icon>
      <span>{{route.nazwa}},</span>
      <span>Distance: {{route._trasa.dlugosc_trasy}} km</span>
    </div>

    <ng-container *ngIf="transport && transport.mscDocelowe; else noEndShippingPoint">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            To
          </mat-panel-title>
          <mat-panel-description>
            {{transport.mscDocelowe.nazwa}}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div fxFlex fxLayout="column" fxLayoutGap="4px" class="shipping-point">
          <div fxFlex fxLayout="row"><span fxFlex="4em">Country</span><span>{{transport.mscDocelowe.panstwo}}</span>
          </div>
          <div fxFlex fxLayout="row"><span fxFlex="4em">City</span><span>{{transport.mscDocelowe.kod}}
            , {{transport.mscDocelowe.miasto}}</span></div>
          <div fxFlex fxLayout="row"><span fxFlex="4em">Street</span><span>{{transport.mscDocelowe.ulica}}</span></div>
        </div>
      </mat-expansion-panel>
    </ng-container>
  </div>
</div>

<ng-template #noStartShippingPoint>
  <div class="error">DEPARTURE shipping point not defined.</div>
</ng-template>
<ng-template #noEndShippingPoint>
  <div class="error">ARRIVAL shipping point not defined.</div>
</ng-template>
