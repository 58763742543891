import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as EVENT_CONFIG from '../../ngrx/event-config.actions';
import * as fromEventConfig from '../../ngrx/event-config.reducer';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
export class ConfigComponent implements OnInit {

  constructor(private store: Store<fromEventConfig.State>) {
  }

  ngOnInit(): void {
    this.store.dispatch(new EVENT_CONFIG.LoadAlertTypesRequest());
  }

}
