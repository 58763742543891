import { DefaultForm } from '../default-form';
import { IMapSearchRouteModel } from './i-map-search-route-model';
import {
  AbstractControl,
  FormArray,
  FormControl,
  Validators,
} from '@angular/forms';
import { MapPoint } from '../../map';
import { ReverseGeocodeResponse } from '../../map/reverseGeocode';

export class MapSearchRouteForm extends DefaultForm<IMapSearchRouteModel> {
  constructor(controls?: IMapSearchRouteModel | null) {
    super({
      poczatekTrasy: new FormControl('', Validators.required),
      koniecTrasy: new FormControl('', Validators.required),
      posrednie: new FormArray([]),
    });

    if (controls) {
      this.patchFromModel(controls);
    }
  }

  reset(value?: any, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.reset(value, options);
    this.getStartPoint().setValue('');
    this.getEndPoint().setValue('');
    this.getViaPoints().clear();
  }

  getViaPoints(): FormArray {
    return this.get('posrednie') as FormArray;
  }

  replaceViaPoint(pointId: string, point: ReverseGeocodeResponse): void {
    if (!this.getViaPoints) {
      return;
    }

    const foundPointIndex: number = this.getViaPoints().value.findIndex(
      (p: ReverseGeocodeResponse) => {
        return p.location.locationId === pointId;
      }
    );

    if (foundPointIndex !== -1) {
      this.getViaPoints().setValue([
        ...this.getViaPoints().value.map(
          (viaPoint: ReverseGeocodeResponse, index: number) => {
            return index === foundPointIndex
              ? {
                  ...point,
                  location: {
                    ...point.location,
                    // Keep original pointId for future comparisons
                    locationId: pointId,
                  },
                }
              : viaPoint;
          }
        ),
      ]);
    }
  }

  removeViaPoint(point: MapPoint): void {
    if (!this.getViaPoints) {
      return;
    }

    const i = this.getViaPoints().value.findIndex(
      (p: ReverseGeocodeResponse) => {
        return p.location.locationId === point.pointId;
      }
    );
    if (i >= 0) {
      this.getViaPoints().removeAt(i);
    }
  }

  getEndPoint(): AbstractControl {
    return this.get('koniecTrasy');
  }

  getStartPoint(): AbstractControl {
    return this.get('poczatekTrasy');
  }
}
