import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { SelectOption } from '../../interfaces';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { Strings } from '../../../../helpers';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit {
  @Input() label: string | null;
  @Input() placeholder: string | null;
  @Input() control: FormControl;
  @Input() readonly = false;
  @Input() hint = '';
  @Input() id = '';
  @Input() name = '';
  @Input() prefixIcon = 'person';
  @Input() suffixIcon = 'search';
  @Input() lov: SelectOption<any>[] = [];

  filteredOptions: Observable<SelectOption<any>[]>;

  ngOnInit() {
    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(''),
      debounceTime(350),
      map(value => {
        let searchTerm = '';
        if (typeof value === 'string') {
          searchTerm = value;
        }
        return this._filter(searchTerm);
      }),
    );
  }

  private _filter(value: string): SelectOption<any>[] {
    if (value.trim().length < 1) {
      return this.lov;
    }
    return this.lov
      .filter(option => Strings.searchTextIgnoreAccents(value, option.label + (option.subLabel || '')) >= 0);
  }

  isRequired(): boolean {
    return this.control.errors !== null && this.control.errors.required;
  }

  compareById(o1, o2) {
    if (!o1 || !o2) {
      return false;
    }
    return o1.id === o2.id;
  }

  displayWithFn(p?: SelectOption<any>): string | undefined {
    return p ? p.label : undefined;
  }
}
