<div fxFlex fxLayout="column" fxLayoutGap="8px">
  <div fxFlex fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
    <mat-form-field>
      <input name="search-parking-lots" matInput placeholder="Type to search..." [formControl]="formSearch">
    </mat-form-field>
    <button mat-raised-button color="primary" (click)="presentAllOnMap()">Show all on map</button>
    <button mat-raised-button (click)="createNewPoint()">Add new</button>
  </div>
  <div class="table-container">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <caption>List of shipping points for company</caption>

      <ng-container matColumnDef="id">
        <th scope="col" mat-header-cell *matHeaderCellDef> No.</th>
        <td mat-cell *matCellDef="let element">
          {{element.id}}
        </td>
      </ng-container>

      <ng-container matColumnDef="nazwa">
        <th scope="col" mat-header-cell *matHeaderCellDef> Name</th>
        <td mat-cell *matCellDef="let element"> {{element.nazwa}} </td>
      </ng-container>

      <ng-container matColumnDef="nazwa_kod">
        <th scope="col" mat-header-cell *matHeaderCellDef> Code</th>
        <td mat-cell *matCellDef="let element"> {{element.nazwa_kod}} </td>
      </ng-container>

      <ng-container matColumnDef="kraj">
        <th scope="col" mat-header-cell *matHeaderCellDef> Country</th>
        <td mat-cell *matCellDef="let element"> {{element.panstwo}} </td>
      </ng-container>

      <ng-container matColumnDef="miasto">
        <th scope="col" mat-header-cell *matHeaderCellDef> City</th>
        <td mat-cell *matCellDef="let element"> {{element.miasto}} </td>
      </ng-container>

      <ng-container matColumnDef="opis">
        <th scope="col" mat-header-cell *matHeaderCellDef> Details</th>
        <td mat-cell *matCellDef="let element"> {{element.opis}} </td>
      </ng-container>

      <ng-container matColumnDef="inne">
        <th scope="col" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu" class="parking-menu">
            <button mat-menu-item (click)="presentOnMap(element)">
              <mat-icon class="present-on-map material-icons-outlined">edit</mat-icon>
              <span>Edit</span>
            </button>
            <button mat-menu-item (click)="remove(element)">
              <mat-icon class="remove material-icons-outlined">delete</mat-icon>
              <span>Remove</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; "></tr>
    </table>
  </div>
</div>
