import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { LogowanieUzytkownika, Uzytkownik } from '../models/dto/user';
import { environment } from 'src/environments/environment';
import { UzytkownikNowy } from '../models/dto/user/uzytkownik-nowy';
import { RoleUzytkownikow, Uprawnienie, UprawnienieUzytkownika } from '../models/authentication';
import { EnvHelper } from '../helpers';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  userEndpoint = EnvHelper.getApiUrl() + environment.apiModules.User.uzytkownik;
  roleEndpoint = EnvHelper.getApiUrl() + environment.apiModules.User.role_uzytkownikow;
  userConfig = environment.apiModules.User;

  constructor(private http: HttpClient) {
  }

  private static convertUserRequest(user: Partial<Uzytkownik>) {
    const request: Partial<Uzytkownik> = {...user};
    if (user?._firma) {
      // @ts-ignore
      request._firma = `${environment.apiModules.Company.firma}/${user._firma}`;
    }
    if (user?._rola) {
      // @ts-ignore
      request._rola = `${environment.apiModules.User.role_uzytkownikow}/${user._rola.id}`;
    }
    return request;
  }

  createNewUser(nUser: UzytkownikNowy): Observable<any> {
    return this.http.post(EnvHelper.getApiUrl() + this.userConfig.uzytkownik_rejestracja, nUser);
  }

  editUser(user: Partial<Uzytkownik>): Observable<Uzytkownik> {
    return this.http.put<Uzytkownik>(EnvHelper.getApiUrl() + `${this.userConfig.uzytkownik}/${user.id}`,
      UserManagementService.convertUserRequest(user));
  }

  unlockUser(userId: number): Observable<unknown> {
    return this.http.post<unknown>(EnvHelper.getApiUrl() + `${this.userConfig.uzytkownik_odblokuj}`, {id_uzytkownik: userId});
  }

  lockUser(userId: number): Observable<unknown> {
    return this.http.put<unknown>(
      EnvHelper.getApiUrl() + `${this.userConfig.uzytkownik}/${userId}`,
      {
        czy_zabl: true
      });
  }

  removeUser(userId: number): Observable<unknown> {
    return this.http.put<unknown>(
      EnvHelper.getApiUrl() + `${this.userConfig.uzytkownik}/${userId}`,
      {
        czy_usun: true
      });
  }

  getWorkerList(): Observable<Uzytkownik[]> {
    return this.http.get<Uzytkownik[]>(this.userEndpoint);
  }

  getWorkerInfo(userLogin: string): Observable<Uzytkownik[]> {
    const hParams = new HttpParams().set('username', userLogin);
    return this.http.get<Uzytkownik[]>(this.userEndpoint, {params: hParams});
  }

  getUserLogHistory(uid: string): Observable<LogowanieUzytkownika[]> {
    return this.http.get<LogowanieUzytkownika[]>(
      EnvHelper.getApiUrl() +
      environment.apiModules.User.hist_logowan + '?_uzytkownik=' + uid + '&order[czas]=desc'
    );
  }

  changUserPassword(uid: number, newPassword: string): Observable<any> {
    return this.http.post(EnvHelper.getApiUrl() + environment.apiModules.User.password_change,
      {id_uzytkownik: uid, newPassword: newPassword});
  }

  getUserPrivileges(): Observable<UprawnienieUzytkownika[]> {
    return this.http.get<UprawnienieUzytkownika[]>(
      EnvHelper.getApiUrl() + environment.apiModules.User.uprawnienia_uzytkownika
    );
  }

  addUserPrivilege(d: UprawnienieUzytkownika): Observable<UprawnienieUzytkownika> {
    const params = {
      '_uzytkownik': environment.apiModules.User.uzytkownik + '/' + d._uzytkownik.id,
      '_dost_mod': d._dost_mod,
      '_upr': '' + environment.apiModules.Admin.uprawnienie + '/' + d._upr.id,
    };
    return this.http.post<UprawnienieUzytkownika>(
      EnvHelper.getApiUrl() + environment.apiModules.User.uprawnienia_uzytkownika
      , params
    );
  }

  removeUserPrivilege(userPriv: UprawnienieUzytkownika): Observable<any> {
    return this.http.delete(EnvHelper.getApiUrl()
      + environment.apiModules.User.uprawnienia_uzytkownika
      + '/' + userPriv.id);
  }

  getPrivileges(): Observable<Uprawnienie[]> {
    return this.http.get<Uprawnienie[]>(
      EnvHelper.getApiUrl() + environment.apiModules.Admin.uprawnienie
    );
  }

  getRoles(): Observable<RoleUzytkownikow[]> {
    return this.http.get<RoleUzytkownikow[]>(this.roleEndpoint);
  }
}
