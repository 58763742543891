import { Action, createReducer, on } from '@ngrx/store';

import * as TRANSSET from './transset.actions';
import {
  Kierowca,
  NaczepaZestawSimple,
  SamochodZestawSimple,
  ZestawTransportowy,
  ZestawTransportowySimple,
  ZtNaczepa,
  ZtSamochod
} from '../interfaces';
import { PunktSpedycyjny, ZestawTransportowyBase } from '../../../models/dto/transportSets';

export interface State {
  drivers: Kierowca[];
  trucks: ZtSamochod[];
  trails: ZtNaczepa[];
  sets: ZestawTransportowySimple[];
  fullSets: ZestawTransportowy[];
  fullSetsNew: ZestawTransportowyBase[];
  truckForTransports: SamochodZestawSimple[];
  trailForTransports: NaczepaZestawSimple[];
  shippingPoints: PunktSpedycyjny[];
  shippingPointProcessing: boolean;
  saveRouteInProgress: boolean;
}

const initialState: State = {
  drivers: [],
  trails: [],
  trucks: [],
  sets: [],
  fullSets: [],
  fullSetsNew: [],
  truckForTransports: [],
  trailForTransports: [],
  shippingPoints: [],
  shippingPointProcessing: false,
  saveRouteInProgress: false
};

export const featureName = 'transportSet';

const transportReducer = createReducer(
  initialState,
  on(TRANSSET.loadShippingPointSuccess, (state, {punkty}) => ({
    ...state, shippingPoints: punkty
  })),
  on(TRANSSET.loadFullTranssetListSuccessNew, (state, {zestawy}) => ({
    ...state, fullSetsNew: zestawy
  })),
  on(TRANSSET.loadDriversSuccess, (state, {kierowcy}) => ({
    ...state, drivers: kierowcy
  })),
  on(TRANSSET.newTranssetRouteRequest, (state) => ({
    ...state,
    saveRouteInProgress: true
  })),
  on(TRANSSET.newTranssetRouteRequestCompleted, (state) => ({
    ...state,
    saveRouteInProgress: false
  })),
  on(TRANSSET.newTranssetRouteRequestFailed, (state) => ({
    ...state,
    saveRouteInProgress: false
  })),
  on(TRANSSET.newShippingPointRequest, (state) => ({
    ...state,
    shippingPointProcessing: true
  })),
  on(TRANSSET.updateShippingPointRequest, (state) => ({
    ...state,
    shippingPointProcessing: true
  })),
  on(TRANSSET.shippingPointSuccess, (state) => ({
    ...state,
    shippingPointProcessing: false
  })),
  on(TRANSSET.shippingPointFailure, (state) => ({
    ...state,
    shippingPointProcessing: false
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return transportReducer(state, action);
}

export const getAvailableDrivers = (state: State) => state.drivers;
export const getFullCurrentSetsNew = (state: State) => state.fullSetsNew;
export const getShippingPoints = (state: State) => state.shippingPoints;
export const getNonLiquidatedAndNonEmptyFullCurrentSets =
  (state: State) => state.fullSets.filter(set => {
      const setIsNonLiquidated = set.czy_zlikwidowany === false;
      const setIsNonEmpty = !!set._naczepa_zestaw.length || !!set._zestaw_transportowy_samochod.length;
      return setIsNonLiquidated && setIsNonEmpty;
    }
  );
export const getSaveRouteInProgress = (state: State) => state.saveRouteInProgress;
export const getShippingPointProcessing = (state: State) => state.shippingPointProcessing;
