import { TerminalLocationShort } from '../../../../../models/helpers';
import { IInfo } from '../../../../../models/frame';
import { DelayColor } from './delay-color';
import { RssiColor } from './rssi-color';
import { SpeedColor } from './speed-color';
import { MapColorScheme } from '../../../../../helpers/enum';
import { LastReportColor } from './last-report-color';

export class MapObjectsHelper {

  static svgRocketIcon = '';
  static svgAlertEventDomObject: Element;
  static svgSatDomObject: Element;
  static svgMobDomObject: Element;
  static svgParkingDomObject: Element;
  static svgParkingSelectedDomObject: Element;
  static svgShippingPointDomObject: Element;
  static svgArrowIcon = '';

  static svgColors = [
    {color: '#000000', name: 'black'},
    {color: '#a60007', name: 'red'},
    {color: '#da8600', name: 'orange'},
    {color: '#c9d100', name: 'yellow'},
    {color: '#29b304', name: 'green'},
    {color: '#00aeef', name: 'blue'},
  ];

  static pngIcons = {
    black: 'assets/img/map/marker-black.png',
    red: 'assets/img/map/marker-red.png',
    orange: 'assets/img/map/marker-orange.png',
    yellow: 'assets/img/map/marker-yellow.png',
    green: 'assets/img/map/marker-green.png',
    blue: 'assets/img/map/marker-blue.png',
  };

  static lineColors = [
    ['rgba(234, 41, 255, 1)', 'rgba(234, 41, 255, 0.8)'],
    ['rgba(0, 159, 212, 1)', 'rgba(0, 159, 212, 0.8)'],
    ['rgba(0, 204, 0, 1)', 'rgba(0, 204, 0, 0.8)'],
    ['rgba(178, 34, 34, 1)', 'rgba(178, 34, 34, 0.8)'],
    ['rgba(128, 0, 255, 1)', 'rgba(128, 0, 255, 0.8)'],
    ['rgba(230, 92, 0, 1)', 'rgba(230, 92, 0, 0.8)'],
    ['rgba(31, 58, 147, 1)', 'rgba(31, 58, 147, 0.8)'],
    ['rgba(153, 204, 0, 1)', 'rgba(153, 204, 0, 0.8)'],
  ];

  // todo: dodać tworzenie i cache-owanie obiektów typu H.map.DomIcon

  static getIconForMarkerSvg(terminalPosition: TerminalLocationShort | IInfo, colorType = MapColorScheme.DELAY) {
    let nIcon = this.svgRocketIcon;
    const colorIdx = this.getColorIdxBy(terminalPosition, colorType);
    const color = this.getMarkerColor(colorIdx, 'svg');

    nIcon = nIcon
      .replace(/fill_color/g, color)
      .replace(/XX/, '' + (terminalPosition.azymut || 0));
    return nIcon;
  }

  static getIconForMarkerPng(terminalPosition: TerminalLocationShort, colorType = MapColorScheme.DELAY) {
    const colorIdx = this.getColorIdxBy(terminalPosition, colorType);
    const color = this.getMarkerColor(colorIdx, 'png');
    return this.pngIcons[color];
  }

  static getLineColor(idx) {
    return this.lineColors[idx % this.lineColors.length];
  }

  static getMarkerColor(idx, type = 'svg') {
    if (type === 'png') {
      return this.svgColors[idx % this.lineColors.length].name;

    }
    return this.svgColors[idx % this.lineColors.length].color;
  }

  static getColorIdxBy(value: TerminalLocationShort | IInfo, type = MapColorScheme.DELAY): number {
    const colorMap = new Map();
    colorMap.set(MapColorScheme.RSSI, new RssiColor(value));
    colorMap.set(MapColorScheme.DELAY, new DelayColor(value));
    colorMap.set(MapColorScheme.SPEED, new SpeedColor(value));
    colorMap.set(MapColorScheme.LAST_REPORT, new LastReportColor(value));

    if (colorMap.has(type)) {
        return colorMap.get(type).getColorIndex();
    }
    return 0;
  }
}
