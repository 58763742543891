import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as TICKETS from '../ngrx/tickets.actions';
import * as fromTickets from '../ngrx/tickets.reducer';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-entrypoint',
  templateUrl: './entrypoint.component.html',
  styleUrls: ['./entrypoint.component.scss']
})
export class EntrypointComponent implements OnInit, OnDestroy {

  ticketInterval = interval(120 * 1000);
  subs = new Subscription();

  constructor(private store: Store<fromTickets.State>) {
    this.subs.add(
      this.ticketInterval.subscribe(() => {
        this.store.dispatch(TICKETS.loadAllTicketsRequest());
      })
    );
  }

  ngOnInit(): void {
    this.store.dispatch(TICKETS.loadAllTicketsRequest());
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
