import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { debounceTime, filter, map, take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import * as fromRoot from '../../../../app.reducer';
import * as fromAlert from '../../../alerts/ngrx/alert.reducer';
import * as fromTicket from '../../ngrx/tickets.reducer';
import * as ALERT from '../../../alerts/ngrx/alert.actions';
import { startCheckTicketAlert, updateTicketRequest } from '../../ngrx/tickets.actions';
import { showUserMessage } from '../../../../ngrx/ui.actions';

import { Ticket, TicketClose, TicketMaintainer } from '../../interfaces';
import { DateFormat, TicketStatus, ToastType, UserRoles } from '../../../../helpers/enum';
import { TicketEditForm } from '../../models/forms';
import { AppUserInfo } from '../../../../models/authentication';
import { BaseYesNoConfig, SelectOption } from '../../../shared/interfaces';
import { BaseYesNoDialogComponent } from '../../../shared/dialogs';
import { TransportAlert } from '../../../../helpers';
import { FormHelper } from '../../../shared/helpers';
import { IStatusAlarmu } from '../../../../models/dto/alert/alarm-transportowy-uproszczony';
import { IAlarmPodstawowy } from '../../../../models/dto/alert/alarm-transportowy';
import { TicketMsg } from 'src/app/messages';
import { Uzytkownik } from '../../../../models/dto/user';
import moment from 'moment';
import { IDriverEntityDTO } from '../../../../models/dto/transit';

enum TerminationContext {
  NONE,
  ALL_ACTIVE,
  ONLY_SELECTED
}

@Component({
  selector: 'app-ticket-data',
  templateUrl: './ticket-data.component.html',
  styleUrls: ['./ticket-data.component.scss'],
})
export class TicketDataComponent implements OnInit, OnChanges, OnDestroy {
  @Input() ticket: Ticket;
  @Input() drivers: IDriverEntityDTO[];

  private alertTerminationContext: TerminationContext = TerminationContext.NONE;
  eTicketStatus = TicketStatus;

  subs = new Subscription();
  pendingAlerts: IAlarmPodstawowy[] = [];
  selectedAlerts: number[] = [];
  df = DateFormat;
  form = new TicketEditForm();
  currentUser: AppUserInfo;
  showHistory = false;

  alertTerminationReasonLov: SelectOption<IStatusAlarmu>[] = [];
  companyUsersLov: SelectOption<Uzytkownik>[] = [];

  alertTerminationReasonControl = new FormControl(0, [Validators.required, Validators.min(1)]);
  ticketAttendantControl = new FormControl();

  constructor(private store: Store<fromRoot.State>, private dialog: MatDialog) {

    this.subs.add(
      store.select(fromRoot.selectors.user.getCompanyUsers)
        .pipe(
          map(userList => userList
            .filter(user => user._rola.id !== UserRoles.KIEROWCA && user.czy_zabl === false))
        )
        .subscribe(userList => {
          this.companyUsersLov = FormHelper.makeSelectLov(userList, ['imie', 'nazwisko']);
        })
    );

    store.select(fromRoot.selectors.auth.getUserInfo)
      .pipe(filter(r => r !== null), take(1))
      .subscribe(user => this.currentUser = user);

    store.select(fromAlert.getAlertStatusList)
      .pipe(
        debounceTime(500),
        filter(r => r.length > 0),
        map(list => [...list].filter(status => status.czyZamyka === true)),
        take(1)
      )
      .subscribe(statusList => {
        this.alertTerminationReasonLov = FormHelper.makeSelectLov(statusList, 'status');
      });

    this.subs.add(
      store.select(fromTicket.getTicketSelectedAlerts)
        .pipe(debounceTime(500))
        .subscribe(alertList => {
          this.selectedAlerts = alertList;
        })
    );
  }

  ngOnInit(): void {
    this.form.disable();
    this.form.patchFromModel(this.ticket);
    this.alertTerminationReasonControl.disable();
    this.ticketAttendantControl.disable();
    this.pendingAlerts = [...this.ticket.alarmy].filter(alert => TransportAlert.isAlertOpen(alert));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.form.disabled && changes.ticket) {
      this.ticket = changes.ticket.currentValue;
      this.form.patchFromModel(this.ticket);
      this.pendingAlerts = [...this.ticket.alarmy].filter(alert => TransportAlert.isAlertOpen(alert));
    }
    if (changes.drivers) {
      this.drivers = changes.drivers.currentValue;
    }
  }

  saveOrEdit(): void {
    if (this.form.disabled) {
      this.form.enable();
      return;
    }

    this.form.disable();
    const updatedTicket = this.form.getValue();
    this.store.dispatch(updateTicketRequest({ticket: updatedTicket}));
  }

  cancel(): void {
    this.form.patchFromModel(this.ticket);
    this.form.disable();
  }

  reassignTicket(persist = false): void {
    if (!persist) {
      if (this.ticketAttendantControl.enabled) {
        this.ticketAttendantControl.reset();
        this.ticketAttendantControl.disable();
      } else {
        this.ticketAttendantControl.enable();
      }
    } else {
      const newAttendant = this.ticketAttendantControl.value as SelectOption<Uzytkownik>;
      if (newAttendant && newAttendant.value) {
        const {id, imie, nazwisko} = newAttendant.value;
        const updatedTicket: TicketMaintainer = {
          id: this.ticket.id,
          idObslugujacy: id,
          idStatusT: this.ticket.statusT.id !== TicketStatus.FINISHED ? TicketStatus.ASSIGNED : TicketStatus.FINISHED
        };
        const config: BaseYesNoConfig = {
          title: 'Change person responsible?',
          content: `Do you want to change person responsible for that ticket to: <b>${imie} ${nazwisko}</b>?`,
          yesAction: () => this.store.dispatch(updateTicketRequest({ticket: updatedTicket})),
          yesLabel: 'Yes',
          yesColor: 'warn',
          noLabel: 'Cancel',
          noColor: 'primary',
          autoClosure: true,
        };
        this.dialog.open(BaseYesNoDialogComponent, {
          data: config,
          id: 'BaseYesNoDialogComponent-TicketAssignee',
          position: {top: '7%'}
        });
      }

      this.ticketAttendantControl.reset();
      this.ticketAttendantControl.disable();
    }
  }

  canCloseAlerts(): boolean {
    if (this.pendingAlerts.length < 1 || this.alertTerminationReasonControl.invalid) {
      return false;
    }

    if (this.alertTerminationContext === TerminationContext.ONLY_SELECTED) {
      return this.selectedAlerts.length > 0;
    } else {
      return true;
    }
  }

  cancelCloseAlerts(): void {
    this.alertTerminationContext = TerminationContext.NONE;
    this.alertTerminationReasonControl.reset();
    this.alertTerminationReasonControl.disable();
    this.store.dispatch(startCheckTicketAlert({start: false}));
  }

  closeTicket(): void {
    if (this.pendingAlerts.length > 0) {
      this.store.dispatch(showUserMessage({
        message: {
          message: TicketMsg.CANT_CLOSE_PEND_ALERTS,
          type: ToastType.WARN
        }
      }));
      return;
    }

    const updatedTicket: TicketClose = {
      id: this.ticket.id,
      idStatusT: TicketStatus.FINISHED,
      dataZak: moment().toISOString(),
      idObslugujacy: +this.currentUser.userId
    };
    const config: BaseYesNoConfig = {
      title: 'Closing ticket',
      content: `Do you want to close this ticket: <b>${this.ticket.id}</b>?`,
      yesAction: () => this.store.dispatch(updateTicketRequest({
        ticket: updatedTicket,
        callback: () => this.store.dispatch(showUserMessage({message: {type: ToastType.SUCCESS, message: TicketMsg.CLOSED}}))
      })),
      yesLabel: 'Yes',
      yesColor: 'warn',
      noLabel: 'Cancel',
      noColor: 'primary',
      autoClosure: true,
    };
    this.dialog.open(BaseYesNoDialogComponent, {
      data: config,
      id: 'BaseYesNoDialogComponent-TicketClose',
      position: {top: '7%'}
    });
  }

  closeAlerts(): void {
    if (this.pendingAlerts.length < 1) {
      return;
    }

    let toCloseAlertIdList = this.selectedAlerts;
    if (this.alertTerminationContext === TerminationContext.ALL_ACTIVE) {
      toCloseAlertIdList = [...this.pendingAlerts].map(alert => alert.id);
    }

    const config: BaseYesNoConfig = {
      title: 'Close opened alerts?',
      content: 'Do you want to close ' + toCloseAlertIdList.length + ' alerts?',
      yesAction: () => {
        const alertTerminationStatusId = this.alertTerminationReasonControl.value.id;
        this.cancelCloseAlerts();
        toCloseAlertIdList.forEach(alertId => {
          this.store.dispatch(new ALERT.TerminateTransportAlertRequest({
            alertId,
            alertTerminationStatusId,
            ticketId: this.ticket.id
          }));
        });
      },
      yesLabel: 'Yes',
      yesColor: 'warn',
      noLabel: 'Cancel',
      noColor: 'primary',
      autoClosure: true,
    };

    this.dialog.open(BaseYesNoDialogComponent, {
      data: config,
      id: 'BaseYesNoDialogComponent-AlertTermination',
      position: {top: '7%'}
    });

  }

  startSelectingAlerts(): void {
    if (this.alertTerminationContext === TerminationContext.NONE) {
      this.alertTerminationReasonControl.enable();
      this.alertTerminationContext = TerminationContext.ONLY_SELECTED;
      this.store.dispatch(showUserMessage({
        message: {
          type: ToastType.INFO,
          message: TicketMsg.SELECT_ALERTS_TO_CLOSE
        }
      }));
    } else {
      this.alertTerminationReasonControl.disable();
      this.alertTerminationContext = TerminationContext.NONE;
    }

    this.store.dispatch(startCheckTicketAlert({start: this.alertTerminationContext === TerminationContext.ONLY_SELECTED}));
  }

  startTerminatingAllAlerts(): void {
    if (this.alertTerminationContext === TerminationContext.NONE) {
      this.alertTerminationReasonControl.enable();
      this.alertTerminationContext = TerminationContext.ALL_ACTIVE;
    } else {
      this.alertTerminationReasonControl.disable();
      this.alertTerminationContext = TerminationContext.NONE;
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
