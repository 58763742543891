import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import * as fromRoot from '../../../../app.reducer';
import * as fromTransport from '../../ngrx/transport.reducer';
import * as TRANSPORT from '../../ngrx/transport.actions';
import { ZestawTransportowy } from '../../../transport-set/interfaces';
import { VehicleBasic } from 'src/app/models/dto/vehicle';
import { RightDrawerService } from '../../../shared/services';
import { ZestawTransportowyBase } from '../../../../models/dto/transportSets';
import { VehicleFromTransitPipe } from '../../../shared/pipes';
import { Strings } from '../../../../helpers';

@Component({
  selector: 'app-transport-set-details',
  templateUrl: './transport-set-details.component.html',
  styleUrls: ['./transport-set-details.component.scss']
})
export class TransportSetDetailsComponent implements OnInit, OnDestroy {
  static readonly myname = Strings.getObjectHash('TransportSetDetailsComponent');

  transportSet$: Observable<ZestawTransportowy>;
  readyTransportSetsPanelIsOpened$: Observable<boolean>;
  details: ZestawTransportowyBase;
  truck: VehicleBasic;
  trailer: VehicleBasic;

  subs = new Subscription();

  constructor(
    private store: Store<fromRoot.State>,
    private rightDrawer: RightDrawerService
  ) {
  }

  ngOnInit(): void {
    this.readyTransportSetsPanelIsOpened$ = this.store.select(fromTransport.getIfReadyTransportSetsPanelIsOpened);
    this.subs.add(
      this.store.select(fromRoot.selectors.ui.getRightDrawerInfo).pipe(
        tap((info) => {
          if (!info.isOpened) {
            this.store.dispatch(TRANSPORT.closeTransportSetDetails());
          }
        }),
        filter(data => (data.componentName !== undefined && data.componentName === TransportSetDetailsComponent.myname)
          || data.isOpened === false && this.details !== null && this.details !== undefined),
        map(data => data.componentData as ZestawTransportowyBase)
      ).subscribe(details => {
        if (details) {
          this.details = details;
          this.trailer = VehicleFromTransitPipe.transform(details.trailerSet);
          this.truck = VehicleFromTransitPipe.transform(details.truckSet);
        }
      })
    );
    this.transportSet$ = this.store.select(fromTransport.getCurrentlyViewedTransportSet);
  }

  selectSet() {
    this.store.dispatch(TRANSPORT.toggleReadyTransportSetsPanel({show: false}));
    this.store.dispatch(TRANSPORT.showTransportSetAsChosenNew({zestaw: this.details}));
    this.rightDrawer.close();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
