import * as CryptoJS from 'crypto-js';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Params, Router } from '@angular/router';

import * as fromRoot from '../app.reducer';
import * as Auth from '../ngrx/auth.actions';
import * as UI from '../ngrx/ui.actions';

import { environment } from '../../environments/environment';
import { EnvHelper } from '../helpers';
import { JwtHelper } from '../helpers/jwt.helper';
import { Uprawnienie } from '../models/authentication';
import { ToastType } from '../helpers/enum';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  static fallbackUrl = '';
  static fallbackQueryParams: Params = {};

  /**
   * Sposób trzymania informacji nt użytkownika
   */
  public userStorageType = 'localstorage';
  private fdwgd = '';
  private browser = {
    id: null,
    isValid: false
  };

  private authModule = environment.apiModules.Auth;

  private userToken = '';
  private tokenMonitorInterval;

  constructor(
    private http: HttpClient,
    private store: Store<fromRoot.State>,
    private dialog: MatDialog,
    private router: Router,
  ) {
    this.store.select(fromRoot.selectors.auth.getBrowserId).subscribe(br => this.browser.id = br);
    this.store.select(fromRoot.selectors.auth.getUserToken)
      .pipe(
        filter(t => t !== null)
      )
      .subscribe(token => {
        if (token !== this.userToken) {
          clearInterval(this.tokenMonitorInterval);
          this.tokenMonitorInterval = undefined;
        }
        this.tokenMonitor(token);
      });
    this.store.select(fromRoot.selectors.auth.getIsBrowserValid).subscribe(br => this.browser.isValid = br);
    this.checkBrowserGuid();
  }

  static setFallbackUrl(url: string, search: string | null | undefined) {
    if (this.fallbackUrl.length < 5) {
      if(typeof search === 'string') {
        const searchParams = new URLSearchParams(search);
        const queryParams: Params = Array.from(searchParams.keys()).reduce((acc, key) => {
          return {...acc, [key]: searchParams.get(key)};
        }, {});

        this.fallbackQueryParams = queryParams;
      }

      this.fallbackUrl = url;

      setTimeout(() => {
        this.fallbackUrl = '';
        this.fallbackQueryParams = {};
      }, 2 * 1000);
    }
  }

  /**
   * Wygenerowanie unikalnego identyfikatora dla przeglądarki
   * @returns wygenerowany identyfikator
   */
  private static generateBrowserGuid(): string {
    const nav = window.navigator;
    const screen = window.screen;
    let guid = '' + nav.mimeTypes.length;
    guid += nav.userAgent.replace(/\D+/g, '');
    guid += nav.plugins.length;
    guid += screen.height || '';
    guid += screen.width || '';
    guid += screen.pixelDepth || '';
    return guid;
  }

  public isBrowserValid(): boolean {
    return this.browser.isValid;
  }

  /**
   * Pobranie unikalnego identyfikatora przeglądarki i ew. jego generowanie
   */
  public checkBrowserGuid() {
    this.fdwgd = localStorage.getItem('fdwgd');
    if (this.fdwgd === null) {
      this.fdwgd = AuthenticationService.generateBrowserGuid();
      this.store.dispatch(new Auth.BrowserNotConfirmed(this.fdwgd));
    } else {
      this.store.dispatch(new Auth.BrowserConfirmed(this.fdwgd));
      this.checkUserCreds();
    }
  }

  public checkUserCreds() {
    switch (this.userStorageType) {
      case 'localstorage':
        const obj = localStorage.getItem('fdwud');
        if (obj) {
          const bytes = CryptoJS.AES.decrypt(obj, this.fdwgd);
          if (bytes.toString()) {
            const lInfo = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

            if (JwtHelper.isTokenExpired(lInfo['token'])) {
              localStorage.removeItem('fdwud');
              break;
            }

            setTimeout(() => {
              this.store.dispatch(new Auth.LoginSuccessRestored({token: lInfo['token'], user: lInfo['user']}));
              if (AuthenticationService.fallbackUrl.length > 5) {
                setTimeout(() => {
                  this.router.navigate([AuthenticationService.fallbackUrl], { queryParams: AuthenticationService.fallbackQueryParams }).then(() => {
                    AuthenticationService.fallbackUrl = '';
                    this.store.dispatch(
                      UI.showUserMessage({message: {type: ToastType.SUCCESS, message: 'Your session has been restored.'}})
                    );
                  });
                }, 10);
              }
            }, 1);
          }
        }
        break;
    }
  }

  authenticate(login: string, haslo: string, xappid: string): Observable<any> {
    return this.http.post<any>(
      EnvHelper.getApiUrl() + this.authModule.logowanie
      , {username: login, password: haslo, xappid: xappid}
    ).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    );
  }

  refreshJwtToken(): Observable<any> {
    return this.http.post<any>(EnvHelper.getApiUrl() + this.authModule.odsw_tokena,
      {token: this.userToken});
  }

  loadPrivilegesMap(): Observable<Uprawnienie[]> {
    return this.http.get<Uprawnienie[]>(EnvHelper.getApiUrl() + environment.apiModules.Admin.uprawnienie + '_odczyt');
  }

  private tokenMonitor(token: string) {
    this.userToken = token;
    if (!this.tokenMonitorInterval) {
      this.tokenMonitorInterval = setInterval(() => {
        const minutes = JwtHelper.getTokenExpirationMinutes(this.userToken);

        if (minutes <= 4) {
          this.store.dispatch(new Auth.RefreshTokenRequest());
        }
      }, 5 * 1000);
    }
  }
}
