import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';

import { CompanyService } from '../../services';
import { Firma } from '../../models/dto/company';
import { AppUserInfo } from '../../models/authentication';
import * as fromRoot from '../../app.reducer';
import * as Auth from '../../ngrx/auth.actions';
import * as GENERAL from '../../ngrx/general.actions';
import { PasswordChangeComponent } from '../../modules/users';
import { EnvHelper } from '../../helpers';
import * as ADMIN from '../../ngrx/admin.actions';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit, OnDestroy {

  subs = new Subscription();
  public firmy$: Observable<Firma[]>;
  public myInfo: AppUserInfo;
  company = new FormControl();

  constructor(private companies: CompanyService,
              private store: Store<fromRoot.State>,
              private hCli: HttpClient,
              private dialog: MatDialog) {
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.subs.add(
      this.store.select(fromRoot.selectors.auth.getUserInfo)
        .pipe(
          filter(res => JSON.stringify(res) !== JSON.stringify(this.myInfo)),
        )
        .subscribe(val => {
          this.myInfo = val;
          this.company.patchValue(val.companyId);
        })
    );

    this.firmy$ = this.store.select(fromRoot.getCompanyList);
  }

  companySelected(event): void {
    this.store.dispatch(new Auth.ChangeUserComapnyReq(event));
    this.store.dispatch(GENERAL.loadDictionaryData());
  }

  reloadProxy(): void {
    this.hCli.get(EnvHelper.getApiUrl() + '/proxy/refresh_permissions').subscribe(res => console.log(res));
  }

  reloadProxyRouting(): void {
    this.store.dispatch(new ADMIN.ReloadProxyRoutingRequest());
  }

  passwordReset(): void {
    this.dialog.open(PasswordChangeComponent);
  }
}
