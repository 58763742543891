import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { debounceTime, skip, startWith, switchMap, take, tap } from 'rxjs/operators';

import * as fromRoot from '../../../../app.reducer';
import * as UI from '../../../../ngrx/ui.actions';
import * as TRANSPORT from '../../ngrx/transport.actions';

import { Privs, ViewMode } from '../../../../helpers/enum';
import { TransportProgress } from '../../models/transport-progress-state-filter';
import * as fromTransport from '../../ngrx/transport.reducer';

@Component({
  selector: 'app-view-filter',
  templateUrl: './view-filter.component.html',
  styleUrls: ['./view-filter.component.scss']
})
export class ViewFilterComponent implements OnInit, OnDestroy {
  searchControl = new FormControl();
  ePrivs = Privs;
  eTransportProgress = TransportProgress;
  transportProgressControl = new FormControl(TransportProgress.not_finished);
  viewMode$: Observable<ViewMode> = this.store.select(fromRoot.selectors.ui.getTransportViewMode);
  subs = new Subscription();
  private DEBOUNCE_TIME_MS = 350;

  constructor(private store: Store<fromRoot.State>) {}

  ngOnInit(): void {
    this.subscribeFormControls();
  }

  subscribeFormControls(): void {
    this.subs.add(
      this.store.select(fromTransport.getTransportFilter).pipe(
        take(1),
        tap(transportFilter => {
          this.searchControl.setValue(transportFilter.text);
          this.transportProgressControl.setValue(transportFilter.state);
        }),
        switchMap(transportFilter => combineLatest([
            this.searchControl.valueChanges.pipe(startWith(transportFilter.text)),
            this.transportProgressControl.valueChanges.pipe(startWith(transportFilter.state))
          ])
        ),
        debounceTime(this.DEBOUNCE_TIME_MS),
        skip(1),
      ).subscribe(([text, state]) => {
        this.store.dispatch(TRANSPORT.setTransportFilter({text, state}));
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  tableView(): void {
    this.store.dispatch(UI.setTransportViewMode({mode: ViewMode.TABLE}));
  }

  tileView(): void {
    this.store.dispatch(UI.setTransportViewMode({mode: ViewMode.TILE}));
  }

}
