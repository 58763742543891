import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SelectOption } from '../../interfaces';
import { MatOptionSelectionChange } from '@angular/material/core';

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss']
})
export class MultiselectComponent implements OnInit {
  @Input() label: string | null;
  @Input() placeholder: string | null;
  @Input() control: FormControl;
  @Input() readonly = false;
  @Input() hint = '';
  @Input() id = '';
  @Input() suffixIcon = '';
  @Input() lov: SelectOption<any>[] = [];
  @Output() selected = new EventEmitter<MatOptionSelectionChange>();

  ngOnInit() {
    this.control.valueChanges.subscribe(val => console.log(val));
  }

  onCatRemoved(item: SelectOption<any>) {
    const selectedLov = (this.control.value as SelectOption<any>[]).filter(elem => elem.label !== item.label);
    this.control.setValue(selectedLov); // To trigger change detection
  }

  compareById(o1, o2) {
    if (!o1 || !o2) {
      return false;
    }
    return o1.id === o2.id;
  }
}
