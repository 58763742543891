import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TerminalVehicleType } from 'src/app/models/dto/terminale';

@Component({
  selector: 'terminal-veh-reg-number',
  template: `
    <span class="veh-reg-number">
      <span class="veh-reg-number-type {{ vehType }}">
        {{ vehType }}
      </span>
      <ng-content></ng-content>
    </span>
  `,
  styleUrls: ['./terminal-veh-reg-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TerminalVehRegNumberComponent {
  @Input() vehType: TerminalVehicleType;

  constructor() {}
}
