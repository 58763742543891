<mat-form-field appearance="outline">
  <mat-label>{{ label }}</mat-label>
  <input matInput [formControl]="control" [autocomplete]="autocomplete" [required]="required || isRequired()"
         [placeholder]="placeholder" [readonly]="readonly" [name]="name" [type]="type">
  <mat-icon matSuffix *ngIf="suffixIcon">{{suffixIcon}}</mat-icon>
  <mat-hint *ngIf="hint">{{hint}}</mat-hint>
  <mat-error *ngIf="control.hasError('required')">Value is required.</mat-error>
  <mat-error *ngIf="control.hasError('min')">Minimum value should greater than {{ control.errors.min.min }}.</mat-error>
  <mat-error *ngIf="control.hasError('max')">Minimum value should lower than {{ control.errors.max.max }}.</mat-error>
  <mat-error *ngIf="control.hasError('email')">Value should have email format.</mat-error>
  <mat-error *ngIf="control.hasError('minlength')">Value should have more characters than {{ control.errors.minlength.requiredLength }}.</mat-error>
  <mat-error *ngIf="control.hasError('maxlength')">Value should have less characters than {{ control.errors.maxlength.requiredLength }}.</mat-error>
  <mat-error *ngIf="control.hasError('pattern')">Value must match pattern: {{ control.errors.pattern.requiredPattern }}.</mat-error>
  <mat-error *ngIf="control.hasError('custom')">{{ control.errors.custom.message }}.</mat-error>
</mat-form-field>
