import { FormControl, Validators } from '@angular/forms';
import { DefaultForm } from '../../../../models/form/default-form';
import { Ticket } from '../../interfaces';

export class TicketEditForm extends DefaultForm<Ticket> {
  constructor(controls?: any | null) {
    super({
      id: new FormControl(null, Validators.required),
      nazwa: new FormControl(null, [Validators.required, Validators.minLength(5)]),
      uwagi: new FormControl(null)
    });

    if (controls) {
      this.patchFromModel(controls);
    }
  }

  get id(): FormControl {
    return this.getFormControl('id');
  }

  get nazwa(): FormControl {
    return this.getFormControl('nazwa');
  }

  get uwagi(): FormControl {
    return this.getFormControl('uwagi');
  }
}
