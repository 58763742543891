<ng-container *ngIf="{
  availableDrivers: availableDrivers$ | async,
  defaultDrivers: defaultDrivers$ | async
} as df">
  <div
    *ngIf="form && addedDrivers"
    [formGroup]="form.stepTwo"
    class="step-content">

    <div *ngIf="df.defaultDrivers?.length" class="mb-4">
      <h4>Suggested default drivers</h4>
      <div
        *ngFor="let defaultDriver of df.defaultDrivers"
        class="default-driver mat-elevation-z3">
        <div class="default-driver-name">
          {{driverName(defaultDriver)}}
        </div>
        <button mat-icon-button color="primary" (click)="addDefaultDriver(defaultDriver)">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>

    <h4>Assigned to transport</h4>
    <p *ngIf="!addedDrivers.controls.length">No drivers assigned yet</p>
    <div *ngFor="let driver of addedDrivers.controls; let i = index" formArrayName="addedDrivers">
      <ng-container *ngIf="form.getDriverByIdx(i) as driverGroup">
        <mat-card
          [formGroup]="driverGroup"
          [ngClass]="{
            'new-driver': isNil(driverGroup.value.transportDriverId) && driverGroup.touched,
            'updated-driver': !isNil(driverGroup.value.transportDriverId) && driverGroup.touched
          }"
          class="driver">

          <div class="driver-content">
            <mat-chip-list class="mr-4">
              <mat-chip selectable="false" removable="false">{{i + 1}}</mat-chip>
            </mat-chip-list>

            <div class="driver-data">
                <p class="label">From</p>
                <date-time-picker
                  [minDate]="departureDateTime"
                  [maxDate]="arrivalDateTime"
                  formControlName="fromDate">
                </date-time-picker>

                <p class="label">To</p>
                <date-time-picker
                  [minDate]="departureDateTime"
                  [maxDate]="arrivalDateTime"
                  formControlName="toDate">
                </date-time-picker>

              <div class="driver-select-container">
                <mat-form-field appearance="outline">
                  <mat-label>Search for a driver...</mat-label>
                  <mat-icon
                    *ngIf="driverGroup.value.driver"
                    matPrefix
                    class="material-icons-outlined selected-driver">
                    person
                  </mat-icon>

                  <input
                    #driverSelect
                    matInput
                    [matAutocomplete]="auto"
                    class="mat-body-2" type="text"
                    formControlName="driver"
                    (focus)="loadAvailableDrivers()">

                  <mat-autocomplete
                    autoActiveFirstOption
                    #auto="matAutocomplete"
                    [displayWith]="driverName">
                    <mat-option
                      *ngFor="let driver of df.availableDrivers | filterDrivers: driverSelect.value"
                      [value]="driver"
                      class="person-option-icon mat-body-2">
                      <mat-icon matPrefix>person</mat-icon>
                      {{driver._uzytkownik.imie}} {{driver._uzytkownik.nazwisko}}
                    </mat-option>

                    <mat-option *ngIf="df.availableDrivers.length < 1" disabled>
                      <mat-icon matPrefix class="person-option-icon cancel">cancel</mat-icon>
                      No drivers to select
                    </mat-option>
                  </mat-autocomplete>

                  <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>

                <button
                  *ngIf="isNil(driverGroup.value.transportDriverId) && !isNil(driverGroup.value.driver?.id) && driverGroup.touched"
                  mat-raised-button
                  [disabled]="driverGroup.invalid"
                  color="accent"
                  (click)="assignNewDriver(driverGroup.value)">
                  Save
                </button>
                <button
                  *ngIf="!isNil(driverGroup.value.transportDriverId) && !isNil(driverGroup.value.driver?.id) && driverGroup.touched"
                  mat-raised-button
                  [disabled]="driverGroup.invalid"
                  color="accent"
                  (click)="updateDriver(driverGroup.value)">
                  Update
                </button>
              </div>
            </div>
          </div>

          <div class="driver-messages">
            <button
              *ngIf="!transport?.zakonczony"
              mat-icon-button
              color="accent"
              (click)="removeDriver(driverGroup.value, i)">
              <mat-icon>delete</mat-icon>
            </button>

            <ng-container *ngIf="driverGroup.invalid && driverGroup.errors">
              <p *ngIf="driverGroup.errors['emptyTransportDates']" class="p-error">
                Transport dates are invalid. Please set correct transport time before proceed.
              </p>

              <p *ngIf="driverGroup.errors['emptyDates']" class="p-error">
                Driver dates are required.
              </p>

              <p *ngIf="driverGroup.errors['fromGreaterThanTo']" class="p-error">
                Start date is greater than end date.
              </p>

              <p *ngIf="driverGroup.errors['lessThan30Minutes']" class="p-error">
                Difference between start and end dates have to be 30 minutes or more.
              </p>

              <p *ngIf="driverGroup.errors['mismatchWithTransport']" class="p-error">
                Provided times are mismatch with transport time.
              </p>
            </ng-container>

            <p *ngIf="driverGroup.get('driver').errors" class="p-error">
              Please select driver.
            </p>
          </div>
        </mat-card>
      </ng-container>
    </div>

    <div class="driver-buttons">
      <button
        *ngIf="form.transsetObj.valid"
        mat-flat-button
        color="primary"
        type="button"
        (click)="form.addDriver()">
        Add driver
      </button>

      <p
        *ngIf="!form.addedDrivers.valid && form.addedDrivers.errors && form.addedDrivers.errors['driversNotSaved']"
        class="ml-4 p-error">
        Please save selected drivers!
      </p>
    </div>

    <div class="step-navigation">
      <button mat-button color="primary" matStepperPrevious>
        <mat-icon>navigate_before</mat-icon> <span>Back</span>
      </button>

      <button
        *ngIf="form.transportId"
        mat-stroked-button
        [disabled]="!form.addedDrivers.valid"
        color="accent"
        matStepperNext>
        <span>Next</span> <mat-icon>navigate_next</mat-icon>
      </button>
    </div>
  </div>
</ng-container>
