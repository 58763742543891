import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { debounceTime, startWith, take } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import chunk from 'lodash/chunk';

import * as fromAlert from '../../ngrx/alert.reducer';
import * as fromRoot from '../../../../app.reducer';
import * as FRAME from '../../../../ngrx/frame.actions';
import * as UI from '../../../../ngrx/ui.actions';

import { IAlarmTransportowyUproszczony } from '../../interfaces';
import { TransportAlert } from '../../../../helpers';
import { ActiveFilter, FilterAlerts, InactiveFilter, SearchTextFilter } from '../../models/filters';
import { ViewMode } from '../../../../helpers/enum';
import { SetAlertFilter } from '../../ngrx/alert.actions';
import { AlertFilterStatusEnum } from '../../enum/alert-filter-status.enum';
import { AlertFilterStatusType } from '../../models/filters';

@Component({
  selector: 'app-transport-alert-list',
  templateUrl: './transport-alert-list.component.html',
  styleUrls: ['./transport-alert-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransportAlertListComponent implements OnInit, OnDestroy {
  viewMode$: Observable<ViewMode> = this.store.select(fromRoot.selectors.ui.getAlertViewMode);
  subs = new Subscription();
  eViewMode = ViewMode;
  alertFilterStatus: typeof AlertFilterStatusEnum = AlertFilterStatusEnum;

  dataSource = new TableVirtualScrollDataSource<IAlarmTransportowyUproszczony>([]);
  searchControl = new FormControl('');
  statusFilterControl = new FormControl(this.alertFilterStatus.Active);
  fullAlertsList = [];
  alertsList = [];
  private DEBOUNCE_TIME_MS = 300;

  private filterAlerts = new FilterAlerts();

  constructor(private store: Store<fromRoot.State>, private changeDetector: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.reassignFiltersFromStore();
    this.subscribeControls();
    this.getAlerts();

    this.store.dispatch(FRAME.infoRequest());
  }

  getAlerts(): void {
    this.subs.add(
      this.store.select(fromAlert.getFlattenedTransportAlerts)
        .subscribe(alerts => {
          const alertList = [...alerts];
          const terminatedAlerts = alertList.filter(a => TransportAlert.isAlertClosed(a));
          const validAlerts = alertList.filter(a => TransportAlert.isAlertOpen(a))
            .sort((a, b) => a.aktOcenaRyzyka > b.aktOcenaRyzyka ? 1 : -1)
            .sort((a, b) => (!!a.dyspozytor === true && !!b.dyspozytor === false) ? 1 : -1)
          ;
          this.fullAlertsList = [...validAlerts, ...terminatedAlerts];
          this.dataSource.data = alerts;
          this.applyFilters();
        })
    );

  }

  assignFilter(filter: AlertFilterStatusType): void {
    switch (filter) {
      case this.alertFilterStatus.Active : {
        this.filterAlerts.set(new ActiveFilter(true));
        break;
      }
      case this.alertFilterStatus.Inactive : {
        this.filterAlerts.set(new InactiveFilter(true));
        break;
      }
      case this.alertFilterStatus.All : {
        this.filterAlerts.resetFilter('active-filter');
        break;
      }
      default:
        break;
    }
    this.store.dispatch(new SetAlertFilter({text: this.searchControl.value, status: filter}));

    this.applyFilters();
  }

  reassignFiltersFromStore(): void {
    this.store.select(fromAlert.getAlertFilter).pipe(take(1)).subscribe(filter => {
      if (filter.status) {
        this.statusFilterControl.setValue(filter.status);
        this.assignFilter(filter.status);
      }
      if (filter.text) {
        this.searchControl.setValue(filter.text);
        this.applyFilters();
      }
    });
  }

  subscribeControls(): void {
    this.subs.add(
      this.searchControl.valueChanges
        .pipe(
          startWith(''),
          debounceTime(this.DEBOUNCE_TIME_MS)
        )
        .subscribe(searchText => {
          this.filterAlerts.set(new SearchTextFilter(searchText));
          this.store.dispatch(new SetAlertFilter({text: searchText, status: this.statusFilterControl.value}));
          this.applyFilters();
        })
    );

    this.subs.add(
      this.statusFilterControl.valueChanges
        .subscribe(
          (status) => {
            this.assignFilter(status);
          }
        )
    );
  }

  tableView() {
    this.store.dispatch(UI.setAlertViewMode({mode: ViewMode.TABLE}));
  }

  tileView() {
    this.store.dispatch(UI.setAlertViewMode({mode: ViewMode.TILE}));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private applyFilters(): void {
    const chunks = chunk(this.fullAlertsList, 2500);
    const filteredAlertChunks: IAlarmTransportowyUproszczony[] = [];

    chunks.forEach((alertChunk, idx) => {
      setTimeout(() => {
        const filteredChunk = this.filterAlerts.applyFilters(alertChunk);
        filteredAlertChunks.push(...filteredChunk);

        if (idx >= chunks.length - 1) {
          this.alertsList = filteredAlertChunks;
          this.dataSource.data = this.alertsList;
          this.changeDetector.markForCheck();
        }
      }, idx * 100);
    });

    this.changeDetector.detectChanges();
  }
}
