import { createAction, props } from '@ngrx/store';
import { IDriverReport } from '../interfaces';


export const loadAllDriverReportsRequest = createAction(
  '[DRIVER_REPORTS] load all driver reports - request'
);

export const loadAllDriverReportsSuccess = createAction(
  '[DRIVER_REPORTS] load all driver reports - success',
  props<{ driverReports: IDriverReport[] }>()
);

export const loadAllDriverReportsFailed = createAction(
  '[DRIVER_REPORTS] load all driver reports - failed'
);
export const loadDriverReportsByTypeIdRequest = createAction(
  '[DRIVER_REPORTS] load driver reports by type id - request',
  props<{ reportTypeId: number }>()
);

export const loadDriverReportsByTypeIdSuccess = createAction(
  '[DRIVER_REPORTS] load driver reports by type id - success',
  props<{ driverReports: IDriverReport[] }>()
);
export const loadDriverReportsByTypeIdFailed = createAction(
  '[DRIVER_REPORTS] load driver reports by type id - failed'
);


export const setDriverReportsFilter = createAction(
  '[DRIVER_REPORTS] set driver reports filter',
  props<{ text: string, status: string }>()
);
