import { catchError, concatMap, finalize, map, startWith, switchMap, tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';

import * as ALERT from './alert.actions';
import * as UI from '../../../ngrx/ui.actions';
import * as fromRoot from '../../../app.reducer';

import { AlertService } from '../services';
import { Messages, ToastType } from '../../../helpers/enum';
import { IPodjetaAkcja } from '../interfaces';
import { Alert } from '../../../messages';
import { loadTicketByIdRequest } from '../../tickets/ngrx/tickets.actions';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable()
export class AlertEffects {

  loadAllAlerts$ = createEffect(() => this.actions.pipe(
    ofType(ALERT.ActionNames.LOAD_ALL_TRANSPORT_ALERTS_REQUEST),
    switchMap(() => {
      return this.alertSrv.getAllAlerts().pipe(
        map(alerts => new ALERT.LoadAllTransportAlertsSuccess(alerts)),
        catchError(() => {
          return of(UI.userError({message: Messages.READING_DATA_ERR}));
        })
      );
    })
  ));

  loadAlertsByStatus$ = createEffect(() => this.actions.pipe(
    ofType(ALERT.ActionNames.LOAD_TRANSPORT_ALERTS_BY_STATUS_REQUEST),
    switchMap(({payload}) => {
      return this.alertSrv.getAlertsByStatus(payload.statusList, payload.fromTimestamp).pipe(
        map(alerts => {
          return new ALERT.LoadTransportAlertsByStatusSuccess({alerts, statusList: payload.statusList, refreshDates: payload.refreshDates, updateDate: moment.default()})
        }),
        catchError(() => {
          return of(UI.userError({message: Messages.READING_DATA_ERR}));
        })
      );
    })
  ));

  loadDetailedTransportAlertById$ = createEffect(() => this.actions.pipe(
    ofType(ALERT.ActionNames.LOAD_DETAILED_TRANSPORT_ALERT_BY_ID_REQUEST),
    switchMap((action) => {
      return this.alertSrv.getDetailedTransportAlertById(action.payload).pipe(
        map(alert => new ALERT.LoadDetailedTransportAlertByIdSuccess(alert)),
        startWith(UI.showProgressBar()),
        catchError(() => {
          return of(UI.userError({message: Messages.READING_DATA_ERR}));
        }),
        finalize(() => this.store.dispatch(UI.hideProgressBar())),
      );
    }),
  ));


  assignTransportAlertHandler$ = createEffect(() => this.actions.pipe(
    ofType(ALERT.ActionNames.ASSIGN_TRANSPORT_ALERT_HANDLER_REQUEST),
    switchMap((action) => {
      return this.alertSrv.assignTransportAlertHandler(action.payload.alertId).pipe(
        startWith(new ALERT.AssignTransportAlertHandlerSuccess({alertId: action.payload.alertId})),
        map(() => new ALERT.UpdateDetailedTransportAlertByIdRequest(action.payload.alertId)),
        catchError(() => of(UI.userError({message: Messages.READING_DATA_ERR})))
      );
    }),
  ));


  updateDetailedTransportAlertById$ = createEffect(() => this.actions.pipe(
    ofType(ALERT.ActionNames.UPDATE_DETAILED_TRANSPORT_ALERT_BY_ID_REQUEST),
    switchMap((action) => {
      return this.alertSrv.getDetailedTransportAlertById(action.payload).pipe(
        map(alert => new ALERT.UpdateDetailedTransportAlertByIdSuccess(alert)),
        // startWith(new ALERT.LoadAllTransportAlertsRequest()),
        catchError(() => {
          return of(UI.userError({message: Messages.READING_DATA_ERR}));
        })
      );
    }),
  ));


  loadAvailableDispatchers$ = createEffect(() => this.actions.pipe(
    ofType(ALERT.ActionNames.LOAD_AVAILABLE_DISPATCHERS_REQUEST),
    switchMap(() => {
      return this.alertSrv.loadAvailableDispatchers().pipe(
        map((dispatchers) => new ALERT.LoadAvailableDispatchersSuccess(dispatchers),
          catchError(() => {
            return of(UI.userError({message: Messages.READING_DATA_ERR}));
          })
        )
      );
    })
  ));


  assignTransportAlertToParticularDispatcher$ = createEffect(() => this.actions.pipe(
    ofType(ALERT.ActionNames.ASSIGN_TRANSPORT_ALERT_TO_PARTICULAR_DISPATCHER_REQUEST),
    switchMap((action) => {
      return this.alertSrv.assignTransportAlertToParticularDispatcher(action.payload.alertId, action.payload.dispatcherId).pipe(
        startWith(new ALERT.CancelTransportAlertParticularDispatcherAssignmentRequest({alertId: action.payload.alertId})),
        tap(() => this.store.dispatch(new ALERT.AssignTransportAlertToParticularDispatcherSuccess({
          alertId: action.payload.alertId,
          dispatcherId: action.payload.dispatcherId,
        }))),
        tap(() => this.store.dispatch(UI.showProgressBar())),
        map(() => new ALERT.UpdateDetailedTransportAlertByIdRequest(action.payload.alertId)),
        catchError(() => {
          return of(UI.userError({message: Messages.READING_DATA_ERR}));
        }),
        finalize(() => this.store.dispatch(UI.hideProgressBar()))
      );
    })
  ));


  cancelTransportAlertParticularDispatcherAssignment$ = createEffect(() => this.actions.pipe(
    ofType(ALERT.ActionNames.CANCEL_TRANSPORT_ALERT_PARTICULAR_DISPATCHER_ASSIGNMENT_REQUEST),
    switchMap((action) => {
      return this.alertSrv.cancelTransportAlertParticularDispatcherAssignment(action.payload.alertId).pipe(
        startWith(new ALERT.CancelTransportAlertParticularDispatcherAssignmentSuccess({alertId: action.payload.alertId})),
        tap(() => this.store.dispatch(UI.showProgressBar())),
        map(() => new ALERT.UpdateDetailedTransportAlertByIdRequest(action.payload.alertId)),
        catchError(() => {
          return of(UI.userError({message: Messages.READING_DATA_ERR}));
        }),
        finalize(() => this.store.dispatch(UI.hideProgressBar()))
      );
    })
  ));


  createTransportAlertTicket$ = createEffect(() => this.actions.pipe(
    ofType(ALERT.ActionNames.CREATE_TRANSPORT_ALERT_TICKET_REQUEST),
    switchMap(({payload: {data, callback}}) => {
      return this.alertSrv.createTransportAlertTicket(data.nazwa, data.alarm).pipe(
        startWith(new ALERT.CreateTransportAlertTicketSuccess({alarm: data.alarm})),
        tap(() => this.store.dispatch(UI.showProgressBar())),
        map(() => {
          callback();
          return new ALERT.UpdateDetailedTransportAlertByIdRequest(data.alarm.id);
        }),
        catchError(() => {
          return of(UI.userError({message: Messages.READING_DATA_ERR}));
        }),
        finalize(() => this.store.dispatch(UI.hideProgressBar()))
      );
    })
  ));


  terminateTransportAlert$ = createEffect(() => this.actions.pipe(
    ofType(ALERT.ActionNames.TERMINATE_TRANSPORT_ALERT_REQUEST),
    concatMap(({payload}) => {
      return this.alertSrv.terminateTransportAlert(payload.alertId, payload.alertTerminationStatusId).pipe(
        startWith(new ALERT.TerminateTransportAlertSuccess({alertId: payload.alertTerminationStatusId})),
        map(() => {
          this.store.dispatch(loadTicketByIdRequest({ticketId: payload.ticketId}));
          this.store.dispatch(UI.showUserMessage({
            message: {
              type: ToastType.SUCCESS,
              message: Alert.ALERT_TERMINATED
            }
          }));
          return new ALERT.UpdateDetailedTransportAlertByIdRequest(payload.alertId);
        }),
        catchError(() => of(UI.userError({message: Messages.READING_DATA_ERR}))),
      );
    }),
  ));


  saveNewTakenAction$ = createEffect(() => this.actions.pipe(
    ofType(ALERT.ActionNames.SAVE_TAKEN_ACTION_REQUEST),
    switchMap((action) => {
      return this.alertSrv.saveNewTakenAction(action.payload).pipe(
        map((action_: IPodjetaAkcja) => new ALERT.SaveTakenActionSuccess({ticketId: action.payload.idTicket, action: action_})),
        catchError(() => of(UI.userError({message: Messages.SAVING_DATA_ERR}))),
      );
    })
  ));


  loadTakenActionsByTicketId$ = createEffect(() => this.actions.pipe(
    ofType(ALERT.ActionNames.LOAD_TAKEN_ACTIONS_BY_TICKET_ID_REQUEST),
    switchMap((action) => {
      return this.alertSrv.getTakenActionsByTicketId(action.payload)
        .pipe(
          map((actions: IPodjetaAkcja[]) => {
            return new ALERT.LoadTakenActionsByTicketIdSuccess({
              ticketId: action.payload,
              actions: actions,
            });
          }),
          catchError(() => of(UI.userError({message: Messages.READING_DATA_ERR}))),
        );
    })
  ));

  loadAlertStatusList$ = createEffect(() => this.actions.pipe(
    ofType(ALERT.ActionNames.LOAD_ALERT_STATUS_LIST_REQUEST),
    switchMap(() => {
      return this.alertSrv.getAlertStatusList()
        .pipe(
          map(statusList => {
            return new ALERT.LoadAlertStatusListSuccess({statusList});
          }),
          catchError(() => of(UI.userError({message: Messages.READING_DATA_ERR}))),
        );
    })
  ));

  constructor(
    private store: Store<fromRoot.State>,
    private actions: Actions<ALERT.AlertActions>,
    private alertSrv: AlertService,
    private toastr: ToastrService,
    private router: Router
  ) {
  }
}
