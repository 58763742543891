<div
  *ngIf="{processing: processing$ | async, isDataLoading: isDataLoading$ | async} as transports"
  class="mat-elevation-z8 tab-container">
  <table mat-table
         [dataSource]="transportTableList"
         [trackBy]="trackByMethod"
         matSort
         [matSortActive]="transportTableColumns.Departure"
         matSortDirection="desc"
         aria-describedby="List of available transits"
         multiTemplateDataRows
         class="mat-elevation-z8">
    <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns;">
      <th id="th-{{column}}" mat-header-cell *matHeaderCellDef [mat-sort-header]="column"
          class="transits-heading">{{ column }}</th>
      <td mat-cell *matCellDef="let element">
        <p [ngSwitch]="column">
            <span *ngSwitchCase="transportTableColumns.Status" class="status-column" fxFlex fxLayout="row" fxLayoutAlign="start center">
              <bolt-status-icon *ngIf="!element?.zakonczony" [locker]="getLocker(element)"></bolt-status-icon>
              <mat-icon
                [class]="(element?.stanPracy | getTransportStatusIcon : element?.rozpoczety : element?.zakonczony).sClass"
                [innerText]="(element?.stanPracy | getTransportStatusIcon : element?.rozpoczety : element?.zakonczony).icon"
                [matTooltip]="(element?.stanPracy | getTransportStatusIcon : element?.rozpoczety : element?.zakonczony).tooltip">
              </mat-icon>
            </span>
          <span class="table-cell-content" *ngSwitchCase="transportTableColumns.Name" fxFlex fxLayout="row" fxLayoutAlign="start center">
            <button mat-icon-button (click)="editDetails(element)" *ngIf="canEdit([ePrivs.ADM_TRANSITS])"
                    [disabled]="transports.isDataLoading">
              <mat-icon [class.pending]="element.kierowcaPrzejazdList.length === 0"
                        [innerText]="element.kierowcaPrzejazdList.length > 0 ? 'mode_edit' : 'edit_notifications'"></mat-icon>
            </button>

              <span fxFlex fxLayout="column" [matTooltip]="element | getTransitTooltip">
                <span>
                  <span class="transport-name">
                    {{element?.zestaw?.id || 'nn'}} / {{element?.id}}:
                    <span [class.no-transport-name]="!element?.uwagi"
                          [innerHTML]="element?.uwagi ? (element?.uwagi | truncate : 25 : '...' : true) : 'Name not provided'">
                    </span>
                  </span>
                </span>
                <span class="sub-name">
                  <span class="vehicle-name"
                        [innerHTML]="(element.zestaw?.truckSet | vehicleFromTransit) ? (element.zestaw?.truckSet | vehicleFromTransit).nrRej : 'No truck'"
                        [class.no-vehicle]="!(element.zestaw?.truckSet | vehicleFromTransit)"></span>
                  <span class="vehicle-name"
                        [innerHTML]="(element.zestaw?.trailerSet | vehicleFromTransit) ? (element.zestaw?.trailerSet | vehicleFromTransit).nrRej : 'No trailer'"></span>
                </span>
              </span>
            </span>
          <span *ngSwitchCase="transportTableColumns.Route">
              {{ element?.mscWyjazdu?.miasto }} - {{ element?.mscDocelowe?.miasto}}
            </span>
          <span *ngSwitchCase="transportTableColumns.Departure" class="departure-column">
              {{ element?.czasWyjazdu | date : df.PIPE_DATETIME_LABEL }}
            </span>
          <span *ngSwitchCase="transportTableColumns.Expected_arrival" class="arrival-column">
              <span *ngIf="!element?.czasPrzyjazdu && element?.planowanyCzasPrzyjazdu"
                    [matTooltip]="'Expected arrival'">
                {{ element?.planowanyCzasPrzyjazdu | date : df.PIPE_DATETIME_LABEL }}
              </span>
              <span *ngIf="element?.czasPrzyjazdu"
                    [matTooltip]="'Arrival date'">
                {{ element?.czasPrzyjazdu | date : df.PIPE_DATETIME_LABEL }}
              </span>
            </span>
          <span *ngSwitchCase="transportTableColumns.Dispatcher">
              {{ element?.dyspozytor?.imie }} {{ element?.dyspozytor?.nazwisko}}
            </span>
          <span *ngSwitchCase="transportTableColumns.Actions">
              <button mat-icon-button [matMenuTriggerFor]="menu" [disabled]="transports.isDataLoading"><mat-icon
                class="more-actions-icon">more_vert</mat-icon></button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="showRelatedAlerts(element)"
                        *appHasPrivileges="[ePrivs.MNG_ALARMS_EVENTS_TRANSIT]">
                  <mat-icon>history</mat-icon>
                  <span class="action-description">Show related alarms and events</span>
                </button>
                <button mat-menu-item (click)="editDetails(element)" *appHasPrivileges="[ePrivs.ADM_TRANSITS]">
                  <mat-icon>mode_edit</mat-icon>
                  <span class="action-description">Edit transport details</span>
                </button>
                <button mat-menu-item (click)="showDetails(element)" *appHasPrivileges="[ePrivs.READ_TRANSIT]">
                  <mat-icon>info_outline</mat-icon>
                  <span class="action-description">Show transport details</span>
                </button>
                <button mat-menu-item (click)="showBoltCode(element)" *appHasPrivileges="[ePrivs.MNG_TRANSSET_SAFETY]">
                  <mat-icon>password</mat-icon>
                  <span class="action-description">Show bolt code</span>
                </button>
                <button mat-menu-item (click)="showOnTheMap(element)" *appHasPrivileges="[ePrivs.READ_TRANSIT]">
                  <mat-icon>pin_drop</mat-icon>
                  <span class="action-description">Show on the map</span>
                </button>
                <button mat-menu-item (click)="manageBolt(element)" *appHasPrivileges="[ePrivs.MNG_CARGO_OPEN]"
                        [disabled]="(getLocker(element) | getLockerStatus).class === eBoltState.NO_DEVICE">
                  <mat-icon>lock</mat-icon>
                  <span class="action-description">Manage bolt</span>
                </button>
                <button
                  *appHasPrivileges="[ePrivs.MNG_TRANSSET_SAFETY]"
                  mat-menu-item
                  [disabled]="false && !element.syrena"
                  (click)="manageSiren(element)">
                  <mat-icon>volume_up</mat-icon>
                  <span class="action-description">Manage trailer&apos;s siren</span>
                </button>
                <ng-container *ngIf="!element.rozpoczety && !element.zakonczony">
                  <button mat-menu-item (click)="deleteTransport(element)" *appHasPrivileges="[ePrivs.ADM_TRANSITS]">
                    <mat-icon>delete</mat-icon>
                    <span class="action-description">
                      Delete
                    </span>
                  </button>
                </ng-container>
                <ng-container *appHasPrivileges="[ePrivs.STOP_TRANSIT]">
                  <button
                    *ngIf="!element.zakonczony && element.rozpoczety"
                    mat-menu-item
                    (click)="stopTransport(element)">
                    <mat-icon>pan_tool</mat-icon>
                    <span class="action-description">
                      Stop transport
                    </span>
                  </button>
                </ng-container>
                <button mat-menu-item (click)="generateReport(element.id)" *appHasPrivileges="[ePrivs.ADM_TRANSITS, ePrivs.GENERATE_TRANSPORT_REPORT]">
                  <mat-icon>attachment</mat-icon>
                  <span class="action-description">
                    Generate report
                  </span>
                </button>
              </mat-menu>
            </span>
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="loading-transports">
      <th mat-header-cell *matCellDef="let element"></th>
      <td mat-cell *matHeaderCellDef table-progress-cell [columnSpan]="displayedColumns.length"></td>
    </ng-container>

    <ng-container matColumnDef="processing-transport">
      <td *matCellDef="let element" mat-cell table-progress-cell [columnSpan]="displayedColumns.length"></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      *matHeaderRowDef="['loading-transports']; sticky: true"
      mat-header-row
      table-progress-row
      [isVisible]="transports.isDataLoading">
    </tr>

    <tr
      *matRowDef="let element; columns: displayedColumns;"
      mat-row
      [table-disabled-row]="transports.isDataLoading"
      class="element-row">
    </tr>
    <tr
      *matRowDef="let element; columns: ['processing-transport'];"
      mat-row
      table-progress-row
      [isVisible]="transports.processing[element.id]">
    </tr>
  </table>
</div>
