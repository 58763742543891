export enum SvgMapIcon {
  __CUSTOM_ICON = 'custom-icon',

  ALERT_EVENT = 'assets/img/map/marker-event.svg',
  SAT = 'assets/img/map/marker-sat.svg',
  MOBILE = 'assets/img/map/marker-mob.svg',
  PARKING = 'assets/img/map/marker-parking.svg',
  ROCKET = 'assets/img/map/rocket.svg',
  PARKING_SELECTED = 'assets/img/map/marker-parking-checked.svg',
  SHIPPING = 'assets/img/map/marker-shippingpoint.svg',
}
