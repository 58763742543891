<div fxLayout="column" fxLayoutGap="16px" class="mat-elevation-z3 main">
  <div class="header">
    <h4>Monitoring parameters</h4>
  </div>
  <div fxLayout="column" fxLayoutGap="8px">
      <!-- todo: ngif - wstawic poprawne dane  -->
    <ng-container *ngIf="transport; else noParamsTpl">
      <div fxLayout="column" class="mat-elevation-z1 param">
        <span>Temperature: <b>Not set</b></span>
        <span>Fuel level: <b>Not set</b></span>
      </div>
    </ng-container>
  </div>
</div>
<ng-template #noParamsTpl>
  <div class="error">
    There is no monitoring parameters assigned to the transport.
  </div>
</ng-template>
