import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, concatMap, exhaustMap, expand, finalize, map, startWith, switchMap, tap, throttleTime } from 'rxjs/operators';
import { EMPTY, Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import * as TRANSPORT from './transport.actions';
import * as INVENTORY from '../../../ngrx/terminal.actions';
import * as fromRoot from '../../../app.reducer';
import * as UI from '../../../ngrx/ui.actions';
import { MapViewRoute, Messages, ToastType } from '../../../helpers/enum';
import { CargoOwnerService, ParkingService, TransitService } from '../services';
import { Router } from '@angular/router';

@Injectable()
export class TransportEffects {

  loadAllTransits$ = createEffect(() => this.actions.pipe(
    ofType(TRANSPORT.loadAllTransitsRequest),
    throttleTime(2 * 1000),
    exhaustMap(() => {
      return this.transitSrv.getAllTransits().pipe(
        map((transits) => TRANSPORT.loadAllTransitsSuccess({transitList: transits})),
        catchError(() => {
          this.store.dispatch(TRANSPORT.loadAllTransitsFailed());
          return of(UI.userError({message: Messages.READING_DATA_ERR}));
        }),
      );
    })
  ));

  loadAvailMonitParams$ = createEffect(() => this.actions.pipe(
    ofType(TRANSPORT.loadAvailableMonitParamsRequest),
    switchMap((action) => {
      return this.transitSrv.getAvailMonitParams(action.transitId)
        .pipe(
          map((params) => TRANSPORT.loadAvailableMonitParamsSuccess({paramList: params})),
          catchError(() => {
            return of(UI.userError({message: Messages.READING_DATA_ERR}));
          }),
        );
    })
  ));

  loadAssignedMonitParams$ = createEffect(() => this.actions.pipe(
    ofType(TRANSPORT.loadAssignedMonitParamsRequest),
    switchMap((action) => {
      return this.transitSrv.getAssignedMonitParams(action.transitId)
        .pipe(
          map((params) => TRANSPORT.loadAssignedMonitParamsSuccess({transitParams: params})),
          catchError(() => {
            return of(UI.userError({message: Messages.READING_DATA_ERR}));
          }),
        );
    })
  ));

  loadRouteTemplates$ = createEffect(() => this.actions.pipe(
    ofType(TRANSPORT.loadRouteTemplatesRequest),
    switchMap(() => {
      let pageNumber = 1;
      let routeTemplateList = [];
      let currentIterationRouteTemplates = [];
      return this.transitSrv.getRouteTemplatesByPageNumber(pageNumber).pipe(
        expand(templates => {
          currentIterationRouteTemplates = templates;
          pageNumber++;
          if (currentIterationRouteTemplates.length > 0) {
            routeTemplateList = [...routeTemplateList, ...templates];
            return this.transitSrv.getRouteTemplatesByPageNumber(pageNumber);
          }
          return EMPTY;
        }),
        map(() => TRANSPORT.loadRouteTemplatesSuccess({routes: routeTemplateList})),
        catchError(() => {
          return of(UI.userError({message: Messages.READING_DATA_ERR}));
        }),
      );
    })
  ));

  loadTransportSetsAvailableWithinTimeRange$ = createEffect(() => this.actions.pipe(
    ofType(TRANSPORT.loadTransportSetsAvailableWithinTimeRangeRequest),
    switchMap((action) => {
      return this.transitSrv.getTransportSetsAvailableWithinTimeRange(action.timeRange.from, action.timeRange.to).pipe(
        map((setList) => TRANSPORT.loadTransportSetsAvailableWithinTimeRangeSuccess({setList})),
        catchError(() => {
          return of(UI.userError({message: Messages.READING_DATA_ERR}));
        }),
      );
    })
  ));

  loadDriversAvailableWithinTimeRange$ = createEffect(() => this.actions.pipe(
    ofType(TRANSPORT.loadDriversAvailableWithinTimeRangeRequest),
    switchMap((action) => {
      return this.transitSrv.getDriversAvailableWithinTimeRange(action.timeRange.from, action.timeRange.to).pipe(
        map((drivers) => TRANSPORT.loadDriversAvailableWithinTimeRangeSuccess({drivers})),
        catchError(() => {
          return of(UI.userError({message: Messages.READING_DATA_ERR}));
        }),
      );
    })
  ));

  saveTransportRequest$ = createEffect(() => this.actions.pipe(
    ofType(TRANSPORT.saveTransportRequest),
    exhaustMap(({transportRequest, successCallback}) => {
      if (transportRequest.id) {
         return this.transitSrv.updateTransit(transportRequest)
          .pipe(
            map(() => {
              if (successCallback) {
                successCallback(transportRequest.id);
              }
              return TRANSPORT.loadTransportByIdRequest({transportId: transportRequest.id});
            }),
            catchError(() => {
              return of(UI.userError({message: Messages.SAVING_DATA_ERR}));
            }),
          );
      } else {
        return this.transitSrv.createNewTransit(transportRequest)
          .pipe(
            map((response) => {
              if (successCallback) {
                successCallback(response.id);
              }
              return TRANSPORT.loadTransportByIdRequest({transportId: response.id});
            }),
            catchError(() => {
              return of(UI.userError({message: Messages.SAVING_DATA_ERR}));
            }),
          );
      }
    })
  ));

  loadTransitRoutes$ = createEffect(() => this.actions.pipe(
    ofType(TRANSPORT.loadTransportRoutesRequest),
    switchMap((action) => {
      return this.transitSrv.getAssignedRouteSectionListForTransit(action.transportId)
        .pipe(
          map(transitRoutes => TRANSPORT.loadTransitRoutesSuccess({transitRoutes, transitId: action.transportId})),
          catchError(() => {
            return of(UI.userError({message: Messages.READING_DATA_ERR}));
          })
        );
    })
  ));

  assignDriversToSavedTransit$ = createEffect(() => this.actions.pipe(
    ofType(TRANSPORT.saveTransitAndPassChosenDriversInfoSuccess),
    tap(() => this.store.dispatch(TRANSPORT.loadAllTransitsRequest())),
    exhaustMap((action) => {
      const newTransitId = +action.transitResponse.transit.id;
      const addedDrivers = action.transitResponse.addedDrivers;
      const actionsArray = [];
      if (action.transitResponse.previouslyAssignedDrivers && action.transitResponse.previouslyAssignedDrivers.length > 0) {
        actionsArray.push(TRANSPORT.removeDriversFromTransitRequest({
          transitId: newTransitId,
          drivers: action.transitResponse.previouslyAssignedDrivers.map(d => d.idKierowca)
        }));
      }
      this.transitSrv.getDriversDesignatedToNewlyCreatedTransit(newTransitId, addedDrivers)
        .forEach(transitDriver => {
          actionsArray.push(TRANSPORT.assignDriverToTransitRequest({transitDriver}));
        });
      return actionsArray;
    })
  ));

  removeDriversFromTransit$ = createEffect(() => this.actions.pipe(
    ofType(TRANSPORT.removeDriversFromTransitRequest),
    concatMap((action) => {
      return this.transitSrv.removeDriversFromTransit(action.transitId, action.drivers).pipe(
        map(() => TRANSPORT.loadTransportByIdRequest({transportId: action.transitId})),
        catchError(() => {
          return of(UI.userError({message: Messages.SAVING_DATA_ERR}));
        }),
      );
    }),
  ));

  assignDriverToTransit$ = createEffect(() => this.actions.pipe(
    ofType(TRANSPORT.assignDriverToTransitRequest),
    concatMap((action) => {
      return this.transitSrv.assignDriverToTransit(action.transitDriver).pipe(
        map((transitDriver) => TRANSPORT.assignDriverToTransitSuccess({transitDriver})),
        catchError(() => {
          return of(UI.userError({message: Messages.SAVING_DATA_ERR}));
        }),
      );
    }),
    tap((data) => {
      if (data['transitDriver']) {
        this.store.dispatch(TRANSPORT.loadTransportByIdRequest({transportId: data['transitDriver']['idPrzejazd']}));
      }
    })
  ));

  deleteTransit$ = createEffect(() => this.actions.pipe(
    ofType(TRANSPORT.deleteTransitRequest),
    switchMap(({transitId}) => {
      return this.transitSrv.deleteTransit(transitId).pipe(
        map(() => {
          this.store.dispatch(TRANSPORT.deleteTransitSuccess({transitId}));
          return TRANSPORT.loadAllTransitsRequest();
        }),
        tap(() => {
          this.store.dispatch(UI.showUserMessage({
            message: {message: `Transport has been deleted. (${transitId})`, type: ToastType.SUCCESS}
          }));
          this.router.navigate(['transport/list']);
        }),
        catchError(() => {
          return of(UI.userError({message: Messages.GENERIC_FAILURE}));
        }),
      );
    })
  ));

  loadTransitById$ = createEffect(() => this.actions.pipe(
    ofType(TRANSPORT.loadTransportByIdRequest),
    switchMap((action) => {
      return this.transitSrv.getTransitById(action.transportId)
        .pipe(
          map((transit) => TRANSPORT.loadTransitByIdSuccess({transit})),
          catchError(() => {
            return of(UI.userError({message: Messages.GENERIC_FAILURE}));
          }),
        );
    })
  ));

  saveTemplateMadeRouteSection$ = createEffect(() => this.actions.pipe(
    ofType(TRANSPORT.saveTemplateMadeRouteSectionRequest),
    switchMap((action) => {
      return this.transitSrv.saveTemplateMadeRouteSection(action.route).pipe(
        map((route) => {
          if (action.onSuccess) {
            action.onSuccess();
          }
          return TRANSPORT.saveTemplateMadeRouteSectionSuccess({route});
        }),
        startWith(UI.showProgressBar()),
        catchError(() => {
          return of(UI.userError({message: Messages.SAVING_DATA_ERR}));
        }),
        finalize(() => this.store.dispatch(UI.hideProgressBar())),
      );
    })
  ));

  saveMonitoringParameter$ = createEffect(() => this.actions.pipe(
    ofType(TRANSPORT.saveMonitorParamRequest),
    switchMap((action) => {
      return this.transitSrv.saveMonitoringParameter(action.param)
        .pipe(
          map(() => {
            if (action.onSuccess) {
              action.onSuccess();
            }
            return TRANSPORT.saveMonitorParamSuccess({requestedParam: action.param});
          }),
          catchError(() => {
            return of(UI.userError({message: Messages.SAVING_DATA_ERR}));
          }),
        );
    })
  ));

  deleteMonitoringParameter$ = createEffect(() => this.actions.pipe(
    ofType(TRANSPORT.deleteMonitorParamRequest),
    switchMap((action) => {
      return this.transitSrv.deleteMonitoringParameter(action.id)
        .pipe(
          map(() => {
            if (action.onSuccess) {
              action.onSuccess();
            }
            return TRANSPORT.deleteMonitorParamSuccess();
          }),
          catchError(() => {
            return of(UI.userError({message: Messages.SAVING_DATA_ERR}));
          }),
        );
    })
  ));

  removeTransportRouteSection$$ = createEffect(() => this.actions.pipe(
    ofType(TRANSPORT.removeRouteSectionRequest),
    switchMap((action) => {
      return this.transitSrv.removeTransportRouteSection(action.routeId)
        .pipe(
          map(() => {
            if (action.onSuccess) {
              action.onSuccess();
            }
            return TRANSPORT.removeRouteSectionSuccess();
          }),
          catchError(() => {
            return of(UI.userError({message: Messages.SAVING_DATA_ERR}));
          }),
        );
    })
  ));

  loadCompanyParkingLots$ = createEffect(() => this.actions.pipe(
    ofType(TRANSPORT.loadCompanyParkingRequest),
    switchMap(() => {
      return this.parkingSrv.loadCompanyParkingLots()
        .pipe(
          map((parkingLots) => TRANSPORT.loadCompanyParkingSuccess({parkingLots}))
        );
    })
  ));

  loadCargoOwners$ = createEffect(() => this.actions.pipe(
    ofType(TRANSPORT.loadCargoOwnersRequest),
    switchMap(() => {
      return this.cargoSrv.loadCargoOwnerList()
        .pipe(
          map((cargoOwners) => TRANSPORT.loadCargoOwnersSuccess({cargoOwners}))
        );
    })
  ));

  newCargoOwner$ = createEffect(() => this.actions.pipe(
    ofType(TRANSPORT.newCargoOwnerRequest, TRANSPORT.updateCargoOwnerRequest),
    switchMap((action) => {
      let res: Observable<unknown>;
      if (action.owner.id) {
        res = this.cargoSrv.updateCargoOwner(action.owner);
      } else {
        res = this.cargoSrv.newCargoOwner(action.owner);
      }
      return res.pipe(
        map(() => {
          if (action.callback) {
            action.callback();
          }
          return TRANSPORT.loadCargoOwnersRequest();
        })
      );
    })
  ));

  removeCargoOwner$ = createEffect(() => this.actions.pipe(
    ofType(TRANSPORT.removeCargoOwnerRequest),
    switchMap(({owner}) => {
      return this.cargoSrv.removeCargoOwner(owner)
        .pipe(
          map(() => TRANSPORT.removeCargoOwnerSuccess({id: owner.id}))
        );
    })
  ));

  removeCompanyParking$ = createEffect(() => this.actions.pipe(
    ofType(TRANSPORT.removeParkingRequest),
    switchMap((action) => {
      return this.parkingSrv.removeCompanyParking(action.parkingId)
        .pipe(
          map(() => TRANSPORT.loadCompanyParkingRequest())
        );
    })
  ));

  loadTransitParkingLots$ = createEffect(() => this.actions.pipe(
    ofType(TRANSPORT.loadTransportParkingRequest),
    switchMap((action) => {
      return this.parkingSrv.loadTransitParkingLots(action.transportId)
        .pipe(
          map((parkingLots) => TRANSPORT.loadTransportParkingSuccess({parkingLots, transportId: action.transportId}))
        );
    })
  ));

  loadCmList$ = createEffect(() => this.actions.pipe(
    ofType(TRANSPORT.loadCmListRequest),
    switchMap(() => {
      return this.transitSrv.getCmList()
        .pipe(
          map((cmList) => TRANSPORT.loadCmListSuccess({cmList}))
        );
    })
  ));

  assignParkingToTransit$ = createEffect(() => this.actions.pipe(
    ofType(TRANSPORT.assignParkingToTransitRequest),
    switchMap((action) => {
      return this.parkingSrv.assignParkingToTransit(action.parkingId, action.transitId)
        .pipe(
          map(() => {
            this.toastr.success('Parking assigned to transport');
            return TRANSPORT.loadTransportByIdRequest({transportId: action.transitId});
          })
        );
    })
  ));

  unassignParkingFromTransit$ = createEffect(() => this.actions.pipe(
    ofType(TRANSPORT.unassignParkingFromTransitRequest),
    switchMap((action) => {
      return this.parkingSrv.unassignParkingFromTransit(action.parkingId, action.transitId)
        .pipe(
          map(() => {
            this.toastr.success('Parking unassigned from transport');
            return TRANSPORT.loadTransportByIdRequest({transportId: action.transitId});
          })
        );
    })
  ));

  updateTransport$ = createEffect(() => this.actions.pipe(
    ofType(TRANSPORT.updateTransportRequest),
    switchMap((action) => {
      return this.transitSrv.updateTransport(action.transport, action.transportId)
        .pipe(
          map(() => {
            this.toastr.success('Transport has been updated');
            return TRANSPORT.loadTransportByIdRequest({transportId: action.transportId});
          })
        );
    })
  ));

  startTransport$ = createEffect(() => this.actions.pipe(
    ofType(TRANSPORT.startTransportRequest, TRANSPORT.stopTransportRequest),
    exhaustMap((action) => {
      let res: Observable<any>;
      if (action.type === TRANSPORT.startTransportRequest.type) {
        res = this.transitSrv.startTransport(action.transportId, action.newBoltPin);
      } else {
        res = this.transitSrv.stopTransport(action.transportId);
      }
      return res.pipe(
        map(() => {
          if (action.onSuccess) {
            action.onSuccess();
          }
          return TRANSPORT.startStopTransportSuccess();
        }),
        catchError((errorResponse) => {
          return of(UI.showUserMessage({
            message: {
              message: errorResponse.error.message,
              title: '',
              type: ToastType.ERROR,
              config: {
                tapToDismiss: true
              }
            }
          }));
        }),
      );
    })
  ));

  startTestingTransport$ = createEffect(() => this.actions.pipe(
    ofType(TRANSPORT.startTestingTransportRequest),
    exhaustMap((action) => {
      return this.transitSrv.startTestingTransport(action.transportId).pipe(
        map(() => {
          this.store.dispatch(TRANSPORT.loadTransportByIdRequest({transportId: action.transportId}));
          this.store.dispatch(UI.showUserMessage({message: {message: 'Transport testing started', type: ToastType.SUCCESS}}));

          return TRANSPORT.startTestingTransportSuccess({ transportId: action.transportId });
        }),
        catchError((errorResponse) => {
          return of(UI.showUserMessage({
            message: {
              message: errorResponse.error.message,
              title: '',
              type: ToastType.ERROR,
              config: {
                tapToDismiss: true
              }
            }
          }));
        }),
      );
    })
  ));

  startTestingTransportSuccess$ = createEffect(() => this.actions.pipe(
    ofType(TRANSPORT.startTestingTransportSuccess),
    tap((action) => {
      this.router.navigate(['/map-view', MapViewRoute.ALARM_TEST, action.transportId]);
    })
  ), { dispatch: false });

  loadTerminalForCm$ = createEffect(() => this.actions.pipe(
    ofType(TRANSPORT.loadAvailableCmTerminalListRequest),
    exhaustMap(() => {
      return this.transitSrv.getMonitTerminalList()
        .pipe(
          map((terminalList) => {
            return new INVENTORY.LoadTerminalsSuccess(terminalList);
          })
        );
    })
  ));

  activateMonitoringRequest$ = createEffect(() => this.actions.pipe(
    ofType(TRANSPORT.activateMonitoringRequest),
    switchMap((action) => {
      return this.transitSrv.activateMonitoring(action.transportId)
        .pipe(
          map(() => {
            if (action.onSuccess) {
              action.onSuccess();
            }
            return TRANSPORT.activateMonitoringSuccess();
          }),
          catchError(() => {
            return of(UI.userError({message: Messages.SAVING_DATA_ERR}));
          }),
        );
    })
  ));

  generateReportRequest = createEffect(() => this.actions.pipe(
    ofType(TRANSPORT.generateReportRequest),
    switchMap((action) => {
      return this.transitSrv.generateReport(action.transportId)
        .pipe(
          switchMap((transportReportResponse) => {
            const attachmentRegex: RegExp = /attachment; filename=(.*)/;
            const blob = new Blob([transportReportResponse.body], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = transportReportResponse.headers
              .get('Content-Disposition')
              .match(attachmentRegex)[1];
            anchor.click();

            return of(TRANSPORT.generateReportComplete(
              {transportId: action.transportId}
            ));
          }),
          catchError(() => {
            this.store.dispatch(
              TRANSPORT.generateReportComplete(
                {transportId: action.transportId}
              ));
            return of(UI.userError({message: Messages.GENERIC_FAILURE}));
          }),
        );
    })
  ));

  constructor(
    private readonly store: Store<fromRoot.State>,
    private readonly actions: Actions,
    private readonly transitSrv: TransitService,
    private readonly parkingSrv: ParkingService,
    private readonly cargoSrv: CargoOwnerService,
    private readonly toastr: ToastrService,
    private readonly router: Router
  ) {
  }
}
