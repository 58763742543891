<ng-container *ngIf="{
  locker: locker$ | async,
  lockerInfo: lockerInfo$ | async,
  processing: processing$ | async
} as boltDialog">
  <h3 mat-dialog-title>{{ data.transport.id }}/{{ data.transport.id }} {{ data.transport.uwagi }} - Manage bolt</h3>

  <div mat-dialog-content>
    <mat-card class="bolt-card">
      <mat-card-content>
        <div class="d-flex align-items-center bolt-status-container">
          <bolt-status-icon [locker]="boltDialog.locker"></bolt-status-icon>

          <p class="bolt-status">
            {{ boltDialog.lockerInfo.tooltip }}
          </p>
        </div>

        <div [ngSwitch]="boltDialog.lockerInfo.class">
          <button
            *ngSwitchCase="BoltState.OPENED"
            [disabled]="boltDialog.processing"
            mat-raised-button
            color="accent"
            type="button"
            (click)="openCloseBolt(true)">
            Close bolt
          </button>

          <button
            *ngSwitchCase="BoltState.CLOSED"
            [disabled]="boltDialog.processing"
            mat-raised-button
            color="accent"
            type="button"
            (click)="openCloseBolt(false)">
            Open bolt
          </button>

          <ng-container *ngSwitchDefault>
            <p>
              Locker status should be updated soon,
              you can close this window or check it manually:
            </p>

            <button
              [disabled]="boltDialog.processing"
              mat-raised-button
              color="accent"
              type="button"
              (click)="checkBoltStatus()">
              Check bolt status
            </button>
          </ng-container>
        </div>
      </mat-card-content>

      <mat-card-footer>
        <mat-progress-bar
          *ngIf="boltDialog.processing"
          color="accent"
          mode="indeterminate">
        </mat-progress-bar>
      </mat-card-footer>
    </mat-card>
  </div>
</ng-container>
