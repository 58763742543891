import { Filter } from './filter';
import { IAlarmTransportowyUproszczony } from '../../interfaces';
import { TransportAlert } from '../../../../helpers';

export class ActiveFilter extends Filter {
  constructor(private readonly enableFilter: boolean) {
    super();
  }

  get name(): string {
    return 'active-filter';
  }

  isMatching(alarm: IAlarmTransportowyUproszczony): boolean {
    if (this.enableFilter) {
      return TransportAlert.isAlertOpen(alarm);
    }

    return true;
  }
}
