import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { combineLatest, Subscription } from 'rxjs';
import isEqual from 'lodash/isEqual';

import * as fromRoot from '../../../../app.reducer';
import * as fromTransport from '../../ngrx/transport.reducer';
import * as TRANSPORT from '../../ngrx/transport.actions';
import * as MAP from '../../../../ngrx/map.actions';

import { ParkingInfo } from '../../interfaces';
import { AssignParkingForm } from '../../../../models/form/assign-parking-form';
import { ITransportEntity, ITransportEntityExtended } from '../../interfaces/transport-entity';
import { Strings } from '../../../../helpers';
import { SvgMapIcon } from '../../../../helpers/enum';
import { GenericPoint } from '../../../../models/map';
import { LocationService } from '../../../map/services/location.service';

interface InData {
  transit: ITransportEntity;
  fallback?: () => void;
}

@Component({
  selector: 'app-assign-parking-to-transit',
  templateUrl: './assign-parking-to-transit.component.html',
  styleUrls: ['./assign-parking-to-transit.component.scss']
})
export class AssignParkingToTransitComponent implements OnDestroy {
  static readonly myname = Strings.getObjectHash('AssignParkingToTransitComponent');

  fallback: () => void;
  subs = new Subscription();

  transport: ITransportEntityExtended;
  allParkingLots: ParkingInfo[] = [];

  assignParkingForm = new AssignParkingForm();
  filteredParking$ = this.assignParkingForm.parkingChanges().pipe(
    debounceTime(250),
    distinctUntilChanged(),
    switchMap((value) => {
      if (!value || typeof value !== 'string') {
        return this.store.select(fromTransport.getCompanyParkingLots)
          .pipe(
            map((parkingLots) => parkingLots)
          );
      }

      return this.store.select(fromTransport.getCompanyParkingLots).pipe(
        map((parkingLots) => parkingLots.filter((parking) => JSON.stringify(parking).includes(value)))
      );
    }),
    map((parkingLots: ParkingInfo[]) => {
      const assignedParkingLotsIds = [...this.transport.parkingPrzejazdList]
        .map(item => item.parking.id);

      return [...parkingLots].filter(p => !assignedParkingLotsIds.includes(p.id));
    })
  );

  constructor(private readonly store: Store<fromRoot.State>) {
    this.store.dispatch(TRANSPORT.loadCompanyParkingRequest());

    this.subs.add(
      this.store.select(fromRoot.selectors.ui.getRightDrawerInfo)
        .pipe(
          filter(data => (data.componentName !== undefined && data.componentName === AssignParkingToTransitComponent.myname)
            || data.isOpened === false),
          map((value) => value.componentData as InData),
          filter(data => data !== undefined)
        )
        .subscribe((data: InData) => {
          if (data.fallback) {
            this.fallback = data.fallback;
          }

          this.store.dispatch(TRANSPORT.loadTransportByIdRequest({transportId: data.transit.id}));
        })
    );

    this.subs.add(
      combineLatest([
        this.store.select(fromTransport.getCurrentTransit),
        this.store.select(fromTransport.getCompanyParkingLots)
      ])
        .pipe(filter(r => r[0] !== null && r[0] !== undefined))
        .subscribe(([transport, parkingLots]) => {
          if (!isEqual(transport, this.transport)) {
            this.transport = transport;
          }
          if (parkingLots.length > 0) {
            this.allParkingLots = parkingLots;
            this.presentParkingsOnMap();
          }
        })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  displayParking(p?: ParkingInfo): string | undefined {
    return p ? p.nazwa : undefined;
  }

  assign(transitId: number): void {
    if (this.assignParkingForm.valid) {
      this.store.dispatch(TRANSPORT.assignParkingToTransitRequest({transitId, parkingId: this.assignParkingForm.getParkingId()}));
    }
  }

  unassign(transitId: number, parkingId: number): void {
    this.store.dispatch(TRANSPORT.unassignParkingFromTransitRequest({transitId, parkingId}));
  }

  private presentParkingsOnMap() {
    const points = this.allParkingLots.map(parking => {
      let iconType = SvgMapIcon.PARKING;

      const assignedParking = this.transport.parkingPrzejazdList
        .find(transportParking => transportParking.parking.id === parking.id);
      if (assignedParking) {
        iconType = SvgMapIcon.PARKING_SELECTED;
      }

      const coords = LocationService.getCoordsNamedPL(parking);
      return {...coords,
        id: parking.id,
        label: parking.nazwa,
        transportParkingId: assignedParking ? assignedParking.id : undefined,
        iconType,
        pointClickAction: (evt) => {
          this.store.dispatch(TRANSPORT.assignParkingToTransitRequest({transitId: this.transport.id, parkingId: parking.id}));
        }
      } as GenericPoint;
    });

    this.store.dispatch(MAP.AdhocPoints({points}));
  }
}
