import { Action } from '@ngrx/store';
import { Kategoria, Kierowca, KierowcaKategoria, KierowcaNowy } from '../interfaces';

export enum ActionNames {
  LOAD_DRIVERS_REQUEST = '[DRIVER] load drivers - request',
  LOAD_DRIVERS_SUCCESS = '[DRIVER] load drivers - success',
  NEW_DRIVERS_REQUEST = '[DRIVER] new drivers - request',
  NEW_DRIVERS_SUCCESS = '[DRIVER] new drivers - success',
  UPD_DRIVER_REQUEST = '[DRIVER] upd drivers - request',
  UPD_DRIVER_SUCCESS = '[DRIVER] upd drivers - success',
  LOAD_DRIVING_LICENCES_REQUEST = '[DRIVER] load driving licences - request',
  LOAD_DRIVING_LICENCES_SUCCESS = '[DRIVER] load driving licences - success',
  ADD_DRIVER_LICENCE_REQUEST = '[DRIVER] add driver licence - request',
  ADD_DRIVER_LICENCE_SUCCESS = '[DRIVER] add driver licence - success',
  REMOVE_DRIVER_LICENCE_REQUEST = '[DRIVER] remove driver licence - request',
  UPDATE_DRIVER_LICENCE_REQUEST = '[DRIVER] update driver licence - request',
  UPDATE_DRIVER_LICENCE_SUCCESS = '[DRIVER] update driver licence - success',
  DELETE_DRIVER_REQUEST = '[DRIVER] remove driver - request',
  SET_PINS_TO_DISPLAY = '[DRIVER] set pins to display',
  GENERATE_NEW_PINS = '[DRIVER] generate new pins',
}

export class LoadDriversRequest implements Action {
  readonly type = ActionNames.LOAD_DRIVERS_REQUEST;
}

export class LoadDriversSuccess implements Action {
  readonly type = ActionNames.LOAD_DRIVERS_SUCCESS;

  constructor(public payload: Kierowca[]) {
  }
}

export class NewDriversRequest implements Action {
  readonly type = ActionNames.NEW_DRIVERS_REQUEST;

  constructor(public payload: { kierowca: Partial<KierowcaNowy>, callback?: (driverId: number) => void }) {
  }
}

export class UpdDriverRequest implements Action {
  readonly type = ActionNames.UPD_DRIVER_REQUEST;

  constructor(public payload: { kierowca: KierowcaNowy, callback?: () => void }) {
  }
}

export class UpdDriverSuccess implements Action {
  readonly type = ActionNames.UPD_DRIVER_SUCCESS;
}

export class LoadDrivingLicensesRequest implements Action {
  readonly type = ActionNames.LOAD_DRIVING_LICENCES_REQUEST;
}

export class LoadDrivingLicensesSuccess implements Action {
  readonly type = ActionNames.LOAD_DRIVING_LICENCES_SUCCESS;

  constructor(public payload: { kategorie: Kategoria[] }) {
  }
}

export class AddDriverLicenceRequest implements Action {
  readonly type = ActionNames.ADD_DRIVER_LICENCE_REQUEST;

  constructor(public payload: { kierowcaKategoria: KierowcaKategoria }) {
  }
}

export class AddDriverLicenceSuccess implements Action {
  readonly type = ActionNames.ADD_DRIVER_LICENCE_SUCCESS;

  constructor(public payload: { kierowcaKategoria: KierowcaKategoria }) {
  }
}

export class RemoveDriverLicenceRequest implements Action {
  readonly type = ActionNames.REMOVE_DRIVER_LICENCE_REQUEST;

  constructor(public payload: { id: number, successCallback: () => void }) {
  }
}

export class DeleteDriverRequest implements Action {
  readonly type = ActionNames.DELETE_DRIVER_REQUEST;

  constructor(public payload: { id: number }) {
  }
}

export class SetPinsToDisplay implements Action {
  readonly type = ActionNames.SET_PINS_TO_DISPLAY;

  constructor(
    public payload: Pick<Kierowca, 'pin' | 'pin_ca'> | null
  ) {}
}

export class GenerateNewPins implements Action {
  readonly type = ActionNames.GENERATE_NEW_PINS;

  constructor(public payload: { id: number; pinType?: 'pin' | 'pinca' }) {}
}

export type DriverActions =
  LoadDriversRequest
  | LoadDriversSuccess
  | NewDriversRequest
  | UpdDriverRequest
  | UpdDriverSuccess
  | LoadDrivingLicensesRequest
  | LoadDrivingLicensesSuccess
  | AddDriverLicenceRequest
  | AddDriverLicenceSuccess
  | RemoveDriverLicenceRequest
  | DeleteDriverRequest
  | SetPinsToDisplay
  | GenerateNewPins;
