import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { isNil } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { Locker } from 'src/app/models/dto/vehicle';
import { LockerInfo } from '../../helpers';
import { GetLockerStatusPipe } from '../../pipes';

@Component({
  selector: 'bolt-status-icon',
  templateUrl: './bolt-status-icon.component.html',
  styleUrls: ['./bolt-status-icon.component.scss'],
  providers: [GetLockerStatusPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoltStatusIconComponent implements OnChanges {
  @Input() locker: Locker;

  lockerInfo$ = new BehaviorSubject<LockerInfo | null | undefined>(null);

  constructor(private lockerStatusPipe: GetLockerStatusPipe) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { locker } = changes;

    if (!isNil(locker)) {
      this.lockerInfo$.next(
        this.lockerStatusPipe.transform(locker.currentValue)
      );
    }
  }
}
