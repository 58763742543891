// import {AuthenticationService} from '.';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Kierowca, TrailToTransport, TransportSetFull, TruckToTransport, ZestawTransportowy } from '../models/dto/transportSets';
import { EnvHelper } from '../helpers';

@Injectable({
  providedIn: 'root'
})
export class TransportSetService {

  transApi = environment.apiModules.TransSets;

  constructor(private http: HttpClient) {
  }

  getDrivers(): Observable<Kierowca[]> {
    return this.http.get<Kierowca[]>(
      EnvHelper.getApiUrl() + this.transApi.sets + this.transApi.drivers
    );
  }

  setDriver1(driverId: number, setId: number) {
    return this.http.put<Kierowca[]>(
      EnvHelper.getApiUrl() + this.transApi.sets + '/drivers/' + setId + '/setdriver1/' + driverId, {}
    );
  }

  setDriver2(driverId: number, setId: number) {
    return this.http.put<Kierowca[]>(
      EnvHelper.getApiUrl() + this.transApi.sets + '/drivers/' + setId + '/setdriver2/' + driverId, {}
    );
  }

  setTruck(truckId: number, setId: number) {
    return this.http.post(
      EnvHelper.getApiUrl() + this.transApi.sets + this.transApi.transportTrucks, {'id_samochod': truckId, 'id_zestaw': setId}
    );
  }

  setTrail(trailId: number, setId: number) {
    return this.http.post(
      EnvHelper.getApiUrl() + this.transApi.sets + this.transApi.transportTrails, {
        'id_naczepa': trailId,
        'id_zestaw': setId,
        'zezw_odlaczenia': true
      }
    );
  }

  getActiveTransportSets(): Observable<ZestawTransportowy[]> {
    return this.http.get<ZestawTransportowy[]>(
      EnvHelper.getApiUrl() + this.transApi.sets
    );
  }

  getActiveFullTransportSets(): Observable<TransportSetFull[]> {
    return this.http.get<TransportSetFull[]>(
      EnvHelper.getApiUrl() + this.transApi.sets + this.transApi.fullSets
    );
  }

  createNewTransportSet(set: ZestawTransportowy): Observable<ZestawTransportowy> {
    return this.http.post<ZestawTransportowy>(EnvHelper.getApiUrl() + this.transApi.sets, set);
  }

  getTruckToTransportsAssignment(): Observable<TruckToTransport[]> {
    return this.http.get<TruckToTransport[]>(
      EnvHelper.getApiUrl() + this.transApi.sets + this.transApi.transportTrucks
    );
  }

  getTrailToTransportsAssignment(): Observable<TrailToTransport[]> {
    return this.http.get<TrailToTransport[]>(
      EnvHelper.getApiUrl() + this.transApi.sets + this.transApi.transportTrails
    );
  }

  assignTruckToTransport(inputData: TruckToTransport): Observable<any> {
    return this.http.post(EnvHelper.getApiUrl() + this.transApi.transportTrucks, inputData);
  }

  updateTruckToTransport(inputData: TruckToTransport): Observable<any> {
    return this.http.put(EnvHelper.getApiUrl() + this.transApi.transportTrucks + '/' + inputData.id, inputData);
  }

  assignTrailToTransport(inputData: TrailToTransport): Observable<any> {
    return this.http.post(EnvHelper.getApiUrl() + this.transApi.transportTrucks, inputData);
  }

  updateTrailToTransport(inputData: TrailToTransport): Observable<any> {
    return this.http.put(EnvHelper.getApiUrl() + this.transApi.transportTrucks + '/' + inputData.id, inputData);
  }

  getTruckForTransport(trans_id: number) {
    return this.http.get<ZestawTransportowy[]>(
      EnvHelper.getApiUrl() + this.transApi.sets + this.transApi.transportTrucks + '/set/' + trans_id.toString()
    );
  }

}
