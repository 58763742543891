import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { map, take, tap } from 'rxjs/operators';

import * as EVENT_CONFIG from '../../../../ngrx/event-config.actions';
import * as fromRoot from '../../../../../../app.reducer';
import * as fromAlert from '../../../../../alerts/ngrx/alert.reducer';
import * as fromEventsConfig from '../../../../ngrx/event-config.reducer';
import { IAlarmTransportowyUproszczony, IRodzajAlarmu } from '../../../../../alerts/interfaces';
import { AlertTypeFormComponent } from '../../alert-type-form/alert-type-form.component';
import { RightDrawerService } from '../../../../../shared/services';
import { BaseYesNoConfig } from '../../../../../shared/interfaces';
import { BaseYesNoDialogComponent } from '../../../../../shared/dialogs';

@Component({
  selector: 'app-table-list',
  templateUrl: './table-list.component.html',
  styleUrls: ['./table-list.component.scss']
})
export class TableListComponent implements OnInit, OnDestroy {
  @Input() dataSource_: any;
  @Input() alertTypesList_: IRodzajAlarmu [];

  displayedColumns: string[] = ['Id', 'Alert type', 'Risk assessment', 'Description', 'Actions'];
  alertTypeIsUsedInAlerts = false;

  currentlyEditedType$: Observable<IRodzajAlarmu>;
  subs = new Subscription();

  constructor(
    private store: Store<fromRoot.State>,
    private rightDrawer: RightDrawerService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.currentlyEditedType$ = this.store.select(fromEventsConfig.getCurrentlyEditedAlertType);
  }

  trackByMethod(index: number, element: IRodzajAlarmu): number {
    return element.id;
  }

  shadowActiveRow(alertType: IRodzajAlarmu) {
    this.store.dispatch(new EVENT_CONFIG.OpenAlertTypeEdition(alertType));
  }

  onDelete(alertTypeId: number) {
    this.subs.add(
      this.store.select(fromAlert.getFlattenedTransportAlerts)
        .pipe(
          map((alerts: IAlarmTransportowyUproszczony[]) => {
            return alerts.filter(a => a.rodzajAlarmu.id === alertTypeId);
          }),
          take(1),
          tap((filteredAlerts: IAlarmTransportowyUproszczony[]) => {
            const hasAlertTypeInExistingAlerts = !!filteredAlerts.length;
            const alertTypeToDelete = this.alertTypesList_.find(t => t.id === alertTypeId);
            if (hasAlertTypeInExistingAlerts) {
              const config: BaseYesNoConfig = {
                title: 'Alert type deletion',
                content: `Alert type <b>${alertTypeToDelete.rodzaj}</b> cannot be deleted since it used in existing alerts.`,
                noLabel: 'Close',
                noColor: 'primary'
              };
              this.dialog.open(BaseYesNoDialogComponent, {
                data: config,
                id: 'BaseYesNoDialogComponent-alertType',
                position: {top: '7%'}
              });
            } else {
              const config: BaseYesNoConfig = {
                title: 'Alert type deletion',
                content: `Are you sure you want to delete alert type <b>${alertTypeToDelete.rodzaj}</b>?`,
                yesAction: () => this.store.dispatch(new EVENT_CONFIG.DeleteAlertTypeRequest(alertTypeId)),
                yesLabel: 'Delete',
                yesColor: 'warn',
                noLabel: 'Cancel',
                noColor: 'primary',
                autoClosure: true,
              };
              this.dialog.open(BaseYesNoDialogComponent, {
                data: config,
                id: 'BaseYesNoDialogComponent-alertType',
                position: {top: '7%'}
              });
            }
          })
        ).subscribe()
    );
  }

  onEdit(alertType: IRodzajAlarmu) {
    this.rightDrawer.open(AlertTypeFormComponent, alertType);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
