import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDrawer, MatSidenav } from '@angular/material/sidenav';
import { Store } from '@ngrx/store';
import { interval, Observable, of, Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { delay, filter, map } from 'rxjs/operators';

import * as fromRoot from '../app.reducer';
import * as ALERT from '../modules/alerts/ngrx/alert.actions';
import * as UI from '../ngrx/ui.actions';

import { GenericNavItem } from '../models/dto';
import { AppUserInfo } from '../models/authentication';
import { UserInfoComponent } from './user-info/user-info.component';
import { RightDrawerService } from '../modules/shared/services';
import { TransportAlert } from '../helpers';
import moment from 'moment/moment';
import * as TICKETS from '../modules/tickets/ngrx/tickets.actions';
import * as DRIVER_REPORTS from '../modules/driver-reports/ngrx/driver-reports.actions';
import { DriverReportTypesEnum } from '../modules/driver-reports/enums/driver-report-types.enum';

@Component({
  selector: 'app-new-layout',
  templateUrl: './new-layout.component.html',
  styleUrls: ['./new-layout.component.scss'],
})
export class NewLayoutComponent implements OnInit, OnDestroy {

  opened = true;
  @ViewChild('sidenav', {static: true}) leftDrawer: MatSidenav;
  @ViewChild('drawer', {static: true}) rightDrawer: MatDrawer;
  animationClass = '';

  showProgressBar$: Observable<boolean>;

  currentUrl$: Observable<any> = of('');
  userInformation$: Observable<AppUserInfo>;

  subs = new Subscription();

  // w starym UI topRightLinks$: Observable<GenericNavItem[]> stają się teraz:
  leftBottomLinks$: Observable<GenericNavItem[]>;

  alertsCyclicRequest: Subscription = undefined;
  alertsClosedCyclicRequest: Subscription = undefined;
  alertInterval = interval(20 * 1000);
  alertClosedInterval = interval(5 * 60 * 1000);
  alertsFromTimestamp = moment().subtract(1, 'month').startOf('month').unix();

  constructor(
    private store: Store<fromRoot.State>,
    private router: Router,
    private dialog: MatDialog,
    public rightDrawerSrv: RightDrawerService
  ) {
    const gmt = moment().utc();
    this.alertsFromTimestamp = gmt.subtract(1, 'month').startOf('month').unix();

    this.subs.add(
      this.store.select(fromRoot.selectors.auth.getAuthIsAuthenticated)
        .subscribe(isLogged => {
          if (isLogged) {
            if (typeof this.alertsCyclicRequest === 'undefined') {
              this.store.dispatch(TICKETS.loadAllTicketsRequest());
              this.store.dispatch(DRIVER_REPORTS.loadDriverReportsByTypeIdRequest({reportTypeId: DriverReportTypesEnum.PIN_REQUEST}));
              this.store.dispatch(new ALERT.LoadTransportAlertsByStatusRequest({
                statusList: TransportAlert.activeAlertStatusList
              }));

              this.alertsCyclicRequest = this.alertInterval.subscribe(() => {
                this.store.dispatch(TICKETS.loadAllTicketsRequest());
                this.store.dispatch(DRIVER_REPORTS.loadDriverReportsByTypeIdRequest({reportTypeId: DriverReportTypesEnum.PIN_REQUEST}));
                this.store.dispatch(new ALERT.LoadTransportAlertsByStatusRequest({
                  statusList: TransportAlert.activeAlertStatusList
                }));
              });

              setTimeout(() => {
                this.store.dispatch(new ALERT.LoadTransportAlertsByStatusRequest({
                  statusList: TransportAlert.notActiveAlertStatusList, fromTimestamp: this.alertsFromTimestamp
                }));
              }, 2500);
              this.alertsClosedCyclicRequest = this.alertClosedInterval.subscribe(() => {
                this.store.dispatch(new ALERT.LoadTransportAlertsByStatusRequest({
                  statusList: TransportAlert.notActiveAlertStatusList, fromTimestamp: this.alertsFromTimestamp
                }));
              });
            }
          } else {
            if (typeof this.alertsCyclicRequest !== 'undefined') {
              this.alertsCyclicRequest.unsubscribe();
              this.alertsCyclicRequest = undefined;
            }
            if (typeof this.alertsClosedCyclicRequest !== 'undefined') {
              this.alertsClosedCyclicRequest.unsubscribe();
              this.alertsClosedCyclicRequest = undefined;
            }
          }
        })
    );

    this.leftBottomLinks$ = this.store.select(fromRoot.selectors.ui.getTopRightMenuLinks);

    this.userInformation$ = this.store.select(fromRoot.selectors.auth.getUserInfo);

    this.currentUrl$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map((nav: NavigationEnd) => nav.url)
    );
  }

  ngOnInit(): void {

    this.showProgressBar$ = this.store.select(fromRoot.selectors.ui.isProgressBarVisible)
      .pipe(delay(10));

    this.subs.add(
      this.store.select(fromRoot.selectors.ui.getRightDrawerInfo)
        .pipe(
          filter(r => r.isOpened !== this.rightDrawer.opened),
          map(settings => settings.isOpened)
        )
        .subscribe(shouldOpen => this.rightDrawer.toggle(shouldOpen))
    );

    this.subs.add(
      this.store.select(fromRoot.selectors.ui.isLeftDrawerOpened)
        .pipe(
          filter(shouldOpen => shouldOpen !== this.leftDrawer.opened),
        )
        .subscribe(() => {
          this.animationClass = this.leftDrawer.opened ? 'a-closing' : 'a-opening';
          this.leftDrawer.toggle().then(() => this.animationClass = '');
        })
    );
  }

  toggleMainMenu(isEscaped = false) {
    let isOpened = this.leftDrawer.opened;
    if (isEscaped) {
      isOpened = !isOpened;
    }
    this.store.dispatch(UI.switchLeftDrawer({show: !isOpened}));
  }

  toggleRightDrawer() {
    this.rightDrawerSrv.close();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    if (typeof this.alertsCyclicRequest !== 'undefined' && !this.alertsCyclicRequest.closed) {
      this.alertsCyclicRequest.unsubscribe();
    }
    if (typeof this.alertsClosedCyclicRequest !== 'undefined' && !this.alertsClosedCyclicRequest.closed) {
      this.alertsClosedCyclicRequest.unsubscribe();
    }
  }

  openedLeftChangeEvent() {
    this.store.dispatch(UI.resizeWindow({context: 'left-menu-opened-change - ' + this.leftDrawer.opened}));
    return true;
  }

  openedRightChangeEvent() {
    this.store.dispatch(UI.resizeWindow({context: 'right-menu-opened-change - ' + this.rightDrawer.opened}));
    return true;
  }

  showUserInfo(): void {
    this.dialog.open(UserInfoComponent);
  }

}
