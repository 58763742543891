import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { IDriverReport, IDriverReportStatus } from '../interfaces';
import { EnvHelper } from '../../../helpers';

@Injectable({
  providedIn: 'root'
})
export class DriverReportsService {
  private api = environment.apiModules.DriverReports;

  constructor(private http: HttpClient) {
  }

  getAllDriverReports(): Observable<IDriverReport[]> {
    return this.http.get<IDriverReport[]>(
      EnvHelper.getApiUrl() + this.api.zgloszenia);
  }

  getDriverReportsByTypeId(typeId: number): Observable<IDriverReport[]> {
    return this.http.get<IDriverReport[]>(
      EnvHelper.getApiUrl() + this.api.zgloszenia, {
        params: {reportTypeIds: typeId}
      });
  }

  getDriverReportsStatutes(): Observable<IDriverReportStatus[]> {
    return this.http.get<IDriverReportStatus[]>(
      EnvHelper.getApiUrl() + this.api.statuses);
  }

  updateDriverReportStatus(reportId: number, confirmReport: boolean): Observable<void> {
    return this.http.post<void>(
      EnvHelper.getApiUrl() + this.api.zgloszenia_kod_otwarcia + `/${reportId}/${confirmReport}`, {});
  }
}
