import { Filter } from './filter';
import { IDriverReport } from '../interfaces';
import { Strings } from '../../../helpers';

export class SearchTextFilter extends Filter {
  constructor(private readonly searchText: string) {
    super();
  }

  private static createSearchableStringFrom(driverReport: IDriverReport): string {
    return (driverReport.opis) + (driverReport.id);
  }

  isMatching(driverReport: IDriverReport): boolean {
    if (this.searchText.length > 0) {
      return Strings.searchTextIgnoreAccents(this.searchText, SearchTextFilter.createSearchableStringFrom(driverReport)) !== -1;
    }
    return true;
  }
}
