import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromRoot from '../../../../app.reducer';
import * as fromTickets from '../../ngrx/tickets.reducer';
import { getTicketsForTransportRequest } from '../../ngrx/tickets.actions';
import { showUserMessage } from '../../../../ngrx/ui.actions';

import { Ticket } from '../../interfaces';
import { TicketMsg } from '../../../../messages';
import { DateFormat, ToastType } from '../../../../helpers/enum';

@Component({
  selector: 'app-transport-tickets',
  templateUrl: './transport-tickets.component.html',
  styleUrls: ['./transport-tickets.component.scss']
})
export class TransportTicketsComponent implements OnInit {

  @Input() ticket: Ticket;
  relatedTickets$: Observable<Ticket[]>;
  df = DateFormat;
  message = TicketMsg.NO_TICKETS_FOR_TRANSPORT;

  constructor(private store: Store<fromRoot.State>) {
  }

  ngOnInit(): void {
    if (!this.ticket.idPrzejazd) {
      this.store.dispatch(showUserMessage({
        message: {message: TicketMsg.NO_TICKETS_FOR_TRANSPORT, type: ToastType.WARN}
      }));
      return;
    }

    this.store.dispatch(showUserMessage({
      message: {message: TicketMsg.LOAD_RELATED, type: ToastType.INFO}
    }));
    this.store.dispatch(getTicketsForTransportRequest({transportId: this.ticket.idPrzejazd}));

    this.relatedTickets$ = this.store.select(fromTickets.getTicketByTransport(this.ticket.idPrzejazd));
  }

}
