import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { LocationHelper, RssiHelper } from '../../../../../helpers';
import { Rygiel, Terminal } from '../../../../../models/dto/terminale';
import { TerminalLocationSat, TerminalLocationShort } from '../../../../../models/helpers';
import { DateFormat } from '../../../../../helpers/enum';
import { IInfo } from '../../../../../models/frame';

interface TerminalDetails {
  ost_data: number;
  terminal: Terminal;
  terminalMismatch: boolean;
  ost_dane: TerminalLocationShort | TerminalLocationSat | IInfo;
}

@Component({
  selector: 'app-object-information',
  templateUrl: './object-information.component.html',
  styleUrls: ['./object-information.component.scss']
})
export class ObjectInformationComponent {

  rssiHelper = RssiHelper;
  rygiel: Rygiel;
  delay: number;
  df = DateFormat;

  public terminalInfoPass: TerminalDetails;
  locationShort: TerminalLocationShort;
  locationSat: TerminalLocationSat;

  constructor(private dialogRef: MatDialogRef<ObjectInformationComponent>,
              @Inject(MAT_DIALOG_DATA) data: TerminalDetails) {
    this.terminalInfoPass = data;
    if (data.ost_dane['liczba_sat_wid'] === undefined) {
      this.locationSat = data.ost_dane as TerminalLocationSat;
    } else {
      this.locationShort = data.ost_dane as TerminalLocationShort;
    }
    if (data.terminal?.rygiels?.length > 0) {
      this.rygiel = data.terminal.rygiels[0];
    }
    this.delay = ObjectInformationComponent.getDelay(data.ost_dane as TerminalLocationShort | IInfo);
  }

  private static getDelay(data: TerminalLocationShort | IInfo): number {
    let czasZapisu;
    if (LocationHelper.isTerminalLocationShort(data)) {
      czasZapisu = data.czas_zapisu;
    } else {
      czasZapisu = data.czasZapisu;
    }
    return (new Date(czasZapisu)).getTime() - (new Date(data.czas)).getTime();
  }

}
