import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';

import { ToastrModule } from 'ngx-toastr';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTabsModule } from '@angular/material/tabs';
import { MatChipsModule } from '@angular/material/chips';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CalendarModule } from 'primeng/calendar';
import { MatStepperModule } from '@angular/material/stepper';

import { AlertColHeaderPipe, AlertStatePipe, ArraySortPipe, LatitudePipe, LongitudePipe, YesNoPipe } from '../pipes';
import { InputComponent, SelectComponent, TextareaComponent } from './shared/components';

@NgModule({
  declarations: [
    YesNoPipe,
    AlertStatePipe,
    AlertColHeaderPipe,
    LatitudePipe,
    LongitudePipe,
    ArraySortPipe,
    InputComponent, TextareaComponent, SelectComponent
  ],
  imports: [
    CommonModule,
    DragDropModule,
    MatStepperModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    MatTooltipModule,
    MatInputModule,
    MatFormFieldModule,
    MatSortModule,
    MatDialogModule,
    MatTableModule,
    MatTabsModule,
    MatMenuModule,
    MatIconModule, MatPaginatorModule, MatSelectModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatStepperModule,
    MatNativeDateModule, MatSlideToggleModule, MatSidenavModule, MatListModule,
    ReactiveFormsModule, MatExpansionModule, ToastrModule.forRoot({
      timeOut: 5000,
      maxOpened: 5,
      preventDuplicates: true,
      countDuplicates: true,
      resetTimeoutOnDuplicate: true
    }),
    MatSnackBarModule, FlexLayoutModule, MatCheckboxModule, MatRadioModule,
    CalendarModule
  ],
  exports: [
    CommonModule,
    DragDropModule,
    MatStepperModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatToolbarModule,
    MatButtonModule,
    MatTooltipModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatSortModule,
    MatDialogModule,
    MatTableModule,
    MatTabsModule,
    MatMenuModule,
    MatIconModule, MatPaginatorModule, MatSelectModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatStepperModule,
    MatNativeDateModule, MatSlideToggleModule, MatSidenavModule, MatListModule,
    YesNoPipe, AlertStatePipe, AlertColHeaderPipe, LatitudePipe, LongitudePipe,
    ArraySortPipe,
    ReactiveFormsModule, MatExpansionModule, ToastrModule,
    MatSnackBarModule, FlexLayoutModule, MatCheckboxModule, MatRadioModule, CalendarModule,
    InputComponent, TextareaComponent, SelectComponent
  ],
})
export class CustomMaterialModule {
}
