import { Filter } from './filter';
import { Ticket } from '../../interfaces';
import { TransportTicket } from '../../../../helpers/transport-ticket';

export enum TicketFilterStatus {
  NEW = 'new',
  ASSIGNED = 'assigned',
  FINISHED = 'finished',
  ALL = 'all',
}

export class TicketStatusFilter implements Filter {
  constructor(private readonly status: string) {
  }

  isMatching(ticket: Ticket): boolean {
    switch (this.status) {
      case TicketFilterStatus.NEW: {
        return TransportTicket.isTicketNew(ticket);
      }
      case TicketFilterStatus.ASSIGNED: {
        return TransportTicket.isTicketAssigned(ticket);
      }
      case TicketFilterStatus.FINISHED: {
        return TransportTicket.isTicketFinished(ticket);
      }
      case TicketFilterStatus.ALL: {
        return true;
      }
    }
    return true;
  }
}
