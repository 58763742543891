import { Directive, OnDestroy, OnInit } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { PaginatorConfig } from '../../../helpers/paginator-config';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const PAGE_SIZE_KEY = 'pagination_page_size';

@Directive({
  selector: '[appPaginationPageSize]'
})
export class PaginationPageSizeDirective implements OnInit, OnDestroy {
  private element: MatPaginator;
  private paginatorConfig = PaginatorConfig;
  private destroyed$ = new Subject<void>();

  get pageSize() {
    return parseInt(localStorage.getItem(PAGE_SIZE_KEY), 10);
  }

  set pageSize(size: number) {
    localStorage.setItem(PAGE_SIZE_KEY, size.toString());
  }

  constructor(private el: MatPaginator) {
    this.element = el;
  }

  ngOnInit(): void {
    this.element.pageSize = this.pageSize || this.paginatorConfig.PAGE_SIZE;
    this.element.pageSizeOptions = this.paginatorConfig.PAGE_SIZE_OPTIONS;

    this.element.page.pipe(takeUntil(this.destroyed$)).subscribe((page: PageEvent) => {
      this.pageSize = page.pageSize;
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
