import { FormControl, Validators } from '@angular/forms';
import { DefaultForm } from '../default-form';

export class MonitParameterForm extends DefaultForm<any> {
  constructor(controls?: any | null) {
    super({
      'id': new FormControl(null),
      'parametr': new FormControl(null, Validators.required),
      'idPrzejazd': new FormControl(null, Validators.required),
      'ryzykoBrakWart': new FormControl(0, Validators.required),
      'ryzykoZlaWart': new FormControl(0, Validators.required),
      'wartMax': new FormControl(0, Validators.required),
      'wartMin': new FormControl(0, Validators.required),
    });
  }
}
