import { Filter } from './filter';
import { ITransportEntity } from '../interfaces/transport-entity';

export enum TransportProgress {
  started = 'started',
  not_started = 'not-started',
  finished = 'finished',
  not_finished = 'not-finished',
}

export class TransportProgressStateFilter implements Filter {
  constructor(private readonly progressState: string) {
  }

  isMatching(transport: ITransportEntity): boolean {
    switch (this.progressState) {
      case TransportProgress.started: {
        return transport.rozpoczety && !transport.zakonczony;
      }
      case TransportProgress.finished: {
        return transport.zakonczony;
      }
      case TransportProgress.not_started: {
        return !transport.rozpoczety && !transport.zakonczony;
      }
      case TransportProgress.not_finished: {
        return !transport.zakonczony;
      }
    }
    return true;
  }

}
