import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromDriversReports from '../driver-reports/ngrx/driver-reports.reducer';
import * as driverReports from '../driver-reports/ngrx/driver-reports.actions';
import { DriverReportTypesEnum } from './enums/driver-report-types.enum';

@Component({
  selector: 'app-driver-reports',
  templateUrl: './driver-reports.component.html',
  styleUrls: ['./driver-reports.component.scss']
})
export class DriverReportsComponent implements OnInit {
  constructor(private store: Store<fromDriversReports.State>) {
  }

  ngOnInit(): void {
    this.store.dispatch(driverReports.loadDriverReportsByTypeIdRequest({reportTypeId: DriverReportTypesEnum.PIN_REQUEST}));
  }

}
