<cdk-virtual-scroll-viewport
  itemSize="32"
  minBufferPx="260"
  maxBufferPx="520"
  class="virtual-scroll">
  <div fxLayout="row wrap" fxLayoutGap="8px grid" fxLayoutAlign="flex-start">
    <div *cdkVirtualFor="let transport of transportList; templateCacheSize: 120; trackBy: trackByMethod"
         fxFlex="0 0 calc(25%)">
      <app-transport-tile [transport]="transport" [editable]="true"></app-transport-tile>
    </div>
  </div>
</cdk-virtual-scroll-viewport>
