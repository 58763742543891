import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseDialogConfig } from '../../interfaces';

@Component({
  selector: 'app-base-confirmation-dialog',
  templateUrl: './base-confirmation-dialog.component.html',
  styleUrls: ['./base-confirmation-dialog.component.scss']
})
export class BaseConfirmationDialogComponent {


  constructor(@Inject(MAT_DIALOG_DATA) public config: BaseDialogConfig,
              public dialogRef: MatDialogRef<BaseConfirmationDialogComponent>) {
  }

  confirmationAction() {
    if (this.config.confirmationAction) {
      this.config.confirmationAction();
    }
    this.dialogRef.close();
  }
}
