import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';

import { ITransportEntity } from '../../interfaces/transport-entity';
import * as fromRoot from '../../../../app.reducer';
import * as fromTransport from '../../ngrx/transport.reducer';
import { AllFiltersMustMatchFilter } from '../../models/all-filters-must-match-filter';
import { TransportProgressStateFilter } from '../../models/transport-progress-state-filter';
import { SearchTextFilter } from '../../models/search-text-filter';
import { MomentHelper, TransportHelper } from '../../../../helpers';

@Component({
  selector: 'app-transport-tile-list',
  templateUrl: './transport-tile-list.component.html',
  styleUrls: ['./transport-tile-list.component.scss']
})
export class TransportTileListComponent implements OnDestroy {
  transportList: ITransportEntity[] = [];
  subs = new Subscription();

  constructor(
    private store: Store<fromRoot.State>
  ) {
    this.subs.add(
      combineLatest([
        this.store.select(fromTransport.getAllTransits),
        this.store.select(fromTransport.getTransportFilter),
      ]).subscribe(([transports, transportFilter]) => {
        const filter = new AllFiltersMustMatchFilter([
          new TransportProgressStateFilter(transportFilter.state),
          new SearchTextFilter(transportFilter.text),
        ]);
        this.transportList = [...transports]
          .filter(elem => TransportHelper.isValidTransset(elem.zestaw))
          .filter(t => filter.isMatching(t))
          .sort((a, b) =>
            MomentHelper.compareDates(a.planowanyCzasPrzyjazdu || a.czasPrzyjazdu, b.planowanyCzasPrzyjazdu || b.czasPrzyjazdu));
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  trackByMethod(index: number, element: ITransportEntity): number {
    return element.id;
  }
}
