<div class="events" *ngIf="transportAlert && transportAlert.zdarzeniaPojazdu">
  <cdk-virtual-scroll-viewport
    itemSize="85"
    class="virtual-scroll"
    minBufferPx="190"
    maxBufferPx="380"
  >
    <div fxFlex fxLayout="column" fxLayoutGap="8px">
      <app-event-tile
        *cdkVirtualFor="let event of events"
        [event]="event"
        [clickOption]="type"
      ></app-event-tile>
    </div>
  </cdk-virtual-scroll-viewport>
</div>
