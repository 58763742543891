import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../app.reducer';
import * as fromTickets from '../../ngrx/tickets.reducer';
import * as TICKETS from '../../ngrx/tickets.actions';
import { TicketEvents } from '../../interfaces';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DateFormat } from '../../../../helpers/enum';
import { MomentHelper } from '../../../../helpers';

@Component({
  selector: 'app-ticket-timeline',
  templateUrl: './ticket-timeline.component.html',
  styleUrls: ['./ticket-timeline.component.scss']
})
export class TicketTimelineComponent implements OnInit, OnDestroy {

  @Input() ticketId: number;
  subs = new Subscription();
  eventList: TicketEvents[] = [];

  df = DateFormat;

  constructor(private store: Store<fromRoot.State>) {
  }

  ngOnInit(): void {
    if (this.ticketId) {
      this.store.dispatch(TICKETS.loadTicketEventsRequest({ticketId: this.ticketId}));
      this.subs.add(
        this.store.select(fromTickets.getTicketEventsById(this.ticketId))
          .pipe(filter(res => res.length > 0))
          .subscribe(events =>
            this.eventList = [...events].sort((a, b) => MomentHelper.compareDates(a.czas, b.czas, 'desc'))
          )
      );
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
