import { EffectsModule } from '@ngrx/effects';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EventConfigEffects } from './ngrx/event-config.effects';
import { CustomMaterialModule } from '../material.modules';
import * as fromEventConfig from './ngrx/event-config.reducer';
import { SharedModule } from '../shared/shared.module';
import { AlertTypeFormComponent, AlertTypeListComponent, ConfigComponent, MainComponent, TableListComponent } from './components';
import { EventsConfigRouting } from './events-config.routing';

@NgModule({
  declarations: [
    ConfigComponent,
    AlertTypeListComponent,
    TableListComponent,
    AlertTypeFormComponent,
    MainComponent
  ],
  imports: [
    CommonModule,
    CustomMaterialModule,
    EventsConfigRouting,
    RouterModule,
    StoreModule.forFeature(fromEventConfig.featureName, fromEventConfig.reducer),
    EffectsModule.forFeature([EventConfigEffects]),
    SharedModule
  ],
  exports: [
    AlertTypeFormComponent,
  ]
})
export class EventsConfigModule {
}
