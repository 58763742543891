import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import {
  ITerminalEquipment,
  Karta,
  KartaTerminal,
  OprogramowanieTerminala,
  OprogramowanieWersjaRamkiInsert,
  PoleRamki,
  PoleRamkiTypDanych,
  Ramka,
  Rygiel,
  SamochodTerminal,
  StatusTerminala,
  Terminal,
  TerminalNaczepa,
  WersjaRamki,
  WersjaRamkiOpr,
  WersjaRamkiRequest
} from '../models/dto/terminale';
import { EnvHelper } from '../helpers';
import { TerminalFrames } from '../helpers/enum';
import { Locker } from '../models/dto/vehicle';

@Injectable({
  providedIn: 'root'
})
export class TerminalMngService {

  constructor(private http: HttpClient) {
  }

  terminalsEndpoint = EnvHelper.getApiUrl();
  ramkaEndpoint = EnvHelper.getApiUrl();
  api = environment.apiModules;

  private static createTerminalSimCardRequest(terminalSimCard: KartaTerminal) {
    const request: Partial<{ id: number, _karta: string, _terminal: string, czas_zamont: string, czas_usuniecia?: string | null }> = {
      id: terminalSimCard.id,
      _karta: `${environment.apiModules.Terminale.Karta}/${terminalSimCard._karta.id}`,
      _terminal: `${environment.apiModules.Terminale.terminal}/${terminalSimCard._terminal.id}`,
      czas_zamont: terminalSimCard.czas_zamont,
    };
    if (terminalSimCard.czas_usuniecia) {
      request.czas_usuniecia = terminalSimCard.czas_usuniecia;
    }
    return request;
  }

  loadTerminals(): Observable<Terminal[]> {
    return this.http
      .get<Terminal[]>(
        this.terminalsEndpoint +
        environment.apiModules.Terminale.terminal + '_odczyt'
      );
  }

  getTerminalEquipment(idTerminal: number): Observable<ITerminalEquipment[]> {
    return this.http.get<ITerminalEquipment[]>(this.terminalsEndpoint +
      environment.apiModules.Terminale.wyposazenieTerminala + '/' + idTerminal);
  }

  loadTerminalStatus(): Observable<StatusTerminala[]> {
    return this.http
      .get<StatusTerminala[]>(
        this.terminalsEndpoint +
        environment.apiModules.Terminale.StatusTerminala
      );
  }

  loadTerminalTrucks(): Observable<SamochodTerminal[]> {
    return this.http
      .get<SamochodTerminal[]>(
        this.terminalsEndpoint +
        environment.apiModules.Terminale.SamochodTerminal
      );
  }

  loadTerminalTrails(): Observable<TerminalNaczepa[]> {
    return this.http
      .get<TerminalNaczepa[]>(
        this.terminalsEndpoint +
        environment.apiModules.Terminale.TerminalNaczepa
      );
  }

  getTerminalBoltPin(terminalId: number): Observable<Terminal> {
    return this.http
      .get<Terminal>(
        this.terminalsEndpoint +
        environment.apiModules.Terminale.KodOtwarcia + '/' + terminalId
      );
  }

  loadSimCards(): Observable<Karta[]> {
    return this.http
      .get<Karta[]>(
        this.terminalsEndpoint +
        environment.apiModules.Terminale.Karta + '_odczyt'
      );
  }

  loadTerminalSimCards(): Observable<KartaTerminal[]> {
    return this.http
      .get<KartaTerminal[]>(
        this.terminalsEndpoint +
        environment.apiModules.Terminale.KartaTerminal + '_odczyt'
      );
  }

  newTerminal(newTerminal: Terminal): Observable<Terminal> {
    const terminalInfo = {
      zmiana_kluczy: newTerminal.zmiana_kluczy,
      data_produkcji: newTerminal.data_produkcji,
      czas_ost_kom: newTerminal.czas_ost_kom,
      oczek_czas_kom: newTerminal.oczek_czas_kom,
      czy_aktywny: newTerminal.czy_aktywny,
      tryb_testowy: newTerminal.tryb_testowy,
      _graber: environment.apiModules.Terminale.Graber + '/' + newTerminal._graber['id'],
      czas_odczytu_wypos: newTerminal.czas_odczytu_wypos,
      _status: environment.apiModules.Terminale.StatusTerminala + '/' + newTerminal._status.id,
      uwagi: newTerminal.uwagi,
      nr_seryjny: newTerminal.nr_seryjny,
    };

    if (newTerminal._opr) {
      terminalInfo['_opr'] = environment.apiModules.Terminale.OprogramowanieTerminala + '/' + newTerminal._opr.id;
    }
    if (newTerminal.id) {
      terminalInfo['id'] = newTerminal.id;
      return this.http
        .put<Terminal>(
          this.terminalsEndpoint +
          environment.apiModules.Terminale.terminal + '/' + newTerminal.id, terminalInfo
        );
    }

    return this.http
      .post<Terminal>(
        this.terminalsEndpoint +
        environment.apiModules.Terminale.terminal, terminalInfo
      );
  }

  newSoftwareFrame(record: OprogramowanieWersjaRamkiInsert): Observable<unknown> {
    return this.http
      .post(
        this.terminalsEndpoint +
        environment.apiModules.Terminale.WersjaOprogramowanie + '_edycja', record
      );
  }

  newSoftwareFrames(records: OprogramowanieWersjaRamkiInsert[]): Observable<unknown>[] {
    return records.map(rec => this.newSoftwareFrame(rec));
  }

  removeSoftwareFrame(id: number): Observable<unknown> {
    return this.http
      .delete(
        this.terminalsEndpoint +
        environment.apiModules.Terminale.WersjaOprogramowanie + '_edycja/' + id
      );
  }

  newTerminalTrail(newTerminal: TerminalNaczepa): Observable<TerminalNaczepa> {
    const info = {
      czas_zamont: newTerminal.czas_zamont,
      _terminal: environment.apiModules.Terminale.terminal + '/' + newTerminal._terminal,
      _naczepa: environment.apiModules.Vehicle.naczepy + '/' + newTerminal._naczepa
    };

    return this.http
      .post<TerminalNaczepa>(
        this.terminalsEndpoint +
        environment.apiModules.Terminale.TerminalNaczepa + '_edycja', info
      );
  }

  newTerminalTruck(newTerminal: SamochodTerminal): Observable<SamochodTerminal> {
    const info = {
      czas_zamont: newTerminal.czas_zamont,
      _terminal: environment.apiModules.Terminale.terminal + '/' + newTerminal._terminal,
      _samochod: environment.apiModules.Vehicle.samochody + '/' + newTerminal._samochod
    };

    return this.http
      .post<SamochodTerminal>(
        this.terminalsEndpoint +
        environment.apiModules.Terminale.SamochodTerminal + '_edycja', info
      );
  }

  loadSoftwareList(): Observable<OprogramowanieTerminala[]> {
    return this.http
      .get<OprogramowanieTerminala[]>(
        this.terminalsEndpoint +
        environment.apiModules.Terminale.OprogramowanieTerminala
      );
  }

  loadFrameList(): Observable<Ramka[]> {
    return this.http
      .get<Ramka[]>(
        this.ramkaEndpoint +
        environment.apiModules.Ramki.RamkaGui
      );
  }

  loadFrameFieldTypes(): Observable<PoleRamkiTypDanych[]> {
    return this.http
      .get<PoleRamkiTypDanych[]>(
        this.ramkaEndpoint +
        environment.apiModules.Ramki.TypyDanychGui
      );
  }

  loadSoftwareFrameVersion(): Observable<WersjaRamkiOpr[]> {
    return this.http
      .get<WersjaRamkiOpr[]>(
        this.terminalsEndpoint +
        environment.apiModules.Terminale.WersjaOprogramowanie
      );
  }

  updateFrameVersion(wersja: WersjaRamki): Observable<WersjaRamki> {
    const nWersja = {...wersja};
    delete nWersja.poleRamkis;
    return this.http
      .put<WersjaRamki>(
        `${this.ramkaEndpoint}${environment.apiModules.Ramki.WersjaRamek}/${nWersja.id}`, nWersja
      );
  }

  updateFrameField(pole: PoleRamki): Observable<PoleRamki> {
    const nPole = {...pole};
    delete nPole.typDanych;
    if (!nPole.funkcjaPrzeksztalcenia) {
      delete nPole.funkcjaPrzeksztalcenia;
    }

    return this.http
      .put<PoleRamki>(
        `${this.ramkaEndpoint}${environment.apiModules.Ramki.PoleRamek}/${nPole.id}`, nPole
      );
  }

  newSoftware(soft: OprogramowanieTerminala): Observable<unknown> {
    return this.http
      .post(
        this.terminalsEndpoint +
        environment.apiModules.Terminale.OprogramowanieTerminala + '_edycja', soft
      );
  }

  updateSoftware(soft: OprogramowanieTerminala): Observable<unknown> {
    const nSoft = {
      opis: soft.opis,
      opis_zmian: soft.opis_zmian,
      oznaczenie: soft.oznaczenie,
      wycofane: soft.wycofane
    };
    return this.http
      .put(
        this.terminalsEndpoint +
        environment.apiModules.Terminale.OprogramowanieTerminala + '_edycja/' + soft.id, nSoft
      );
  }

  updateTerminalSoftware(terminalSoft: { terminalId: number, softwareCode: string }, rozmiar = 64): Observable<unknown> {
    const apiLink = environment.apiModules.Terminale.OprogramowanieTerminalaUpgrade;
    return this.http
      .get(
        this.terminalsEndpoint +
        apiLink + '/' + terminalSoft.terminalId + '/' + terminalSoft.softwareCode + '/' + rozmiar
      );
  }

  newFrameVersion(request: WersjaRamkiRequest): Observable<unknown> {
    const apiLink = environment.apiModules.Ramki.WersjaRamkiNowa;
    return this.http
      .post(
        this.ramkaEndpoint + apiLink, request
      );
  }

  lockerRequestOpen(terminalId: number): Observable<unknown> {
    return this.http.get(
      this.terminalsEndpoint + this.api.TrakerKomunikacja.rygielOtworz + '/' + terminalId
    );
  }

  lockerRequestClose(terminalId: number): Observable<unknown> {
    return this.http.get(
      this.terminalsEndpoint + this.api.TrakerKomunikacja.rygielZamknij + '/' + terminalId
    );
  }

  requestForFrame(terminalId: number, frameName: TerminalFrames): Observable<unknown> {
    return this.http.get(
      this.terminalsEndpoint + this.api.TrakerKomunikacja.zadanieRamki + '/' + terminalId + '/nazwa_ramki/' + frameName.trim().toUpperCase()
    );
  }

  getLockerStatus(lockerId: number): Observable<Locker> {
    return this.http.get<Locker>(`${this.terminalsEndpoint}/api/rygiel_stan/${lockerId}`);
  }

  insertOrUpdateLocker(locker: Rygiel): Observable<any> {
    const data = {
      ...locker,
      _statusRygla: this.api.Terminale.RygielStatus + '/' + locker._statusRygla.id,
      _terminal: this.api.Terminale.terminal + '/' + locker._terminal.id
    };

    if (locker.id) {
      // update
      return this.http.put(
        this.terminalsEndpoint + this.api.Terminale.Rygiel + '_edycja/' + locker.id, data
      );
    }

    // insert
    return this.http.post(
      this.terminalsEndpoint + this.api.Terminale.Rygiel + '_edycja', data
    );
  }

  newSimCardToTerminal(terminalSimCard: KartaTerminal): Observable<unknown> {
    const request = TerminalMngService.createTerminalSimCardRequest(terminalSimCard);
    return this.http.post(
      this.terminalsEndpoint +
      environment.apiModules.Terminale.KartaTerminal + '_edycja',
      request
    );
  }

  updateSimCardInTerminal(terminalSimCard: KartaTerminal): Observable<unknown> {
    const request = TerminalMngService.createTerminalSimCardRequest(terminalSimCard);
    return this.http.put(
      `${this.terminalsEndpoint}${environment.apiModules.Terminale.KartaTerminal}_edycja/${terminalSimCard.id}`,
      request
    );
  }

  removeSimCardFromTerminal(terminalSimCard: KartaTerminal): Observable<unknown> {
    return this.http.delete(
      `${this.terminalsEndpoint}${environment.apiModules.Terminale.KartaTerminal}_edycja/${terminalSimCard.id}`
    );
  }
}
