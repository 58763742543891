<div mat-dialog-title *ngIf="config.title" [innerHTML]="config.title"></div>
<div mat-dialog-content *ngIf="config.content" [innerHTML]="config.content"></div>
<div mat-dialog-actions class="actions" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
  <button mat-flat-button [color]="config.yesColor ? config.yesColor : 'primary'" class="mat-button" (click)="yesAction(config.autoClosure)">
    {{ config.yesLabel || 'Yes' }}
  </button>
  <button mat-raised-button [color]="config.noColor" class="mat-button" (click)="noAction()">
    {{ config.noLabel || 'No' }}
  </button>
</div>
<br/>
