import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { ParkingInfo } from '../interfaces';
import { EnvHelper } from '../../../helpers';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ParkingService {
  apiConfig = environment.apiModules;

  constructor(
    private readonly http: HttpClient
  ) {
  }

  loadCompanyParkingLots(): Observable<ParkingInfo[]> {
    return this.http.get<ParkingInfo[]>(
      EnvHelper.getApiUrl() + this.apiConfig.Transit.parkingi
    );
  }

  removeCompanyParking(id: number): Observable<unknown> {
    return this.http.delete<unknown>(
      EnvHelper.getApiUrl() + this.apiConfig.Transit.parkingi + id
    );
  }

  loadTransitParkingLots(transitId: number): Observable<ParkingInfo[]> {
    return this.http.get<ParkingInfo[]>(
      EnvHelper.getApiUrl() + this.apiConfig.Transit.parkingi + 'przejazd/' + transitId
    );
  }

  assignParkingToTransit(parkingId: number, transitId: number): Observable<unknown> {
    return this.http.post<unknown>(
      EnvHelper.getApiUrl() + this.apiConfig.Transit.parkingi + 'przejazd',
      {
        idParking: parkingId,
        idPrzejazd: transitId
      }
    );
  }

  unassignParkingFromTransit(parkingId: number, transitId: number): Observable<unknown> {
    return this.http.delete<unknown>(
      EnvHelper.getApiUrl() + this.apiConfig.Transit.parkingi + 'przejazd/' + transitId + '/parking/' + parkingId,
    );
  }
}
