import { PunktSpedycyjny } from 'src/app/models/dto/transportSets';
import { LocationData } from 'src/app/models/map/geocode';
import { ReverseGeocodeResponse } from 'src/app/models/map/reverseGeocode';
import { MapPointsGuard } from 'src/app/modules/shared/type-guards';

/**
 * Point search base class - contains method(s) commmon for all "point related" search boxes
 */
export class PointSearchBase {
  displayFn(
    point: LocationData | ReverseGeocodeResponse | PunktSpedycyjny
  ): string {
    if (!point) {
      return '';
    }

    if (MapPointsGuard.isPunktSpedycyjny(point)) {
      return point.nazwa_kod + ': ' + point.nazwa;
    }

    if (MapPointsGuard.isLocationData(point)) {
      return point.Address.Label.replace(/<[^>]*>/g, '');
    }

    if (MapPointsGuard.isReverseGeocodeResponse(point)) {
      return point.location.address.label;
    }

    return '';
  }
}
