export enum WebsocketMessageType {
  ALERT_CREATE = 'alert_create',
  EVENT_CREATE = 'event_create',
  ALERT_UPDATE = 'alert_update',
  EVENT_UPDATE = 'event_update',
}

export interface WebsocketMessage {
  value: WebsocketMessageType;
  id_alert: number;
  content: string;
}
