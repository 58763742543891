import { BehaviorSubject, Observable } from 'rxjs';
import { IInfo } from 'src/app/models/frame';
import { FrameTest } from './frame-test';
import { TransitTest } from './transit-test';

export interface GsmStatus {
  infoFrame: IInfo | null;
  upToDate: boolean;
}
export class TransitTestGsm implements TransitTest<GsmStatus, IInfo> {
  private frameTest: FrameTest;

  private status$: BehaviorSubject<GsmStatus> = new BehaviorSubject({
    infoFrame: null,
    upToDate: false,
  });

  constructor(frameRequestTimestamp: number) {
    this.frameTest = new FrameTest(frameRequestTimestamp);
  }

  get status(): Observable<GsmStatus> {
    return this.status$.asObservable();
  }

  passData(frame: IInfo): void {
    this.testFrame(frame);
  }

  dispose(): void {
    return;
  }

  private testFrame(frame: IInfo): void {
    // GSM is up to date
    if (this.frameTest.isUpToDate(frame?.czas)) {
      this.status$.next({
        upToDate: true,
        infoFrame: frame,
      });
    }
  }
}
