import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import * as fromRoot from '../../../../app.reducer';
import * as fromTickets from '../../ngrx/tickets.reducer';
import { DateFormat, UserRoles } from '../../../../helpers/enum';
import { Ticket } from '../../interfaces';
import { filter, map } from 'rxjs/operators';
import { AllFiltersMustMatchFilter, SearchTextFilter } from '../../models/filters';
import { Router } from '@angular/router';
import { IAlarmPodstawowy } from '../../../../models/dto/alert/alarm-transportowy';
import { TransportAlert } from '../../../../helpers';
import { MatPaginator } from '@angular/material/paginator';
import { TerminalVehicleType } from '../../../../models/dto/terminale';
import { TicketStatusFilter } from '../../models/filters/ticket-status-filter';
import { TicketTableColumnsEnum } from '../../enum/ticket-table-columns';

@Component({
  selector: 'app-ticket-table-list',
  templateUrl: './ticket-table-list.component.html',
  styleUrls: ['./ticket-table-list.component.scss']
})
export class TicketTableListComponent implements OnDestroy, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;
  subs = new Subscription();
  dateFormat: typeof DateFormat = DateFormat;
  vehicleType: typeof TerminalVehicleType = TerminalVehicleType;
  ticketTableColumns: typeof TicketTableColumnsEnum = TicketTableColumnsEnum;

  ticketsInfo = new MatTableDataSource<Ticket>([]);
  displayedColumns: string[] = Object.values(this.ticketTableColumns);
  isDataLoading$: Observable<boolean> = this.store.select(fromTickets.isDataLoading);

  constructor(private store: Store<fromRoot.State>, private router: Router) {
    this.subs.add(
      combineLatest([
        this.store.select(fromTickets.getAllTickets),
        this.store.select(fromRoot.selectors.auth.getUserInfo),
        this.store.select(fromTickets.getTicketFilter),
        this.store.select(fromRoot.getCompanyList)
      ])
        .pipe(
          filter(r => r[1] !== undefined && r[1].role !== undefined),
          map(([ticketList, user, ticketFilter, companies]) => {
            return {
              tickets: ticketList.map(ticket => (
                {
                  ...ticket,
                  nazwaFirmy: companies.find(company => company.id === ticket.idFirma)?.nazwa
                }
              )),
              user,
              filter: new AllFiltersMustMatchFilter([
                new SearchTextFilter(ticketFilter.text),
                new TicketStatusFilter(ticketFilter.status),
              ])
            };
          }),
          map((obj) => {
            if (obj.user.role.id !== UserRoles.CM) {
              return obj.tickets.filter((ticket) => {
                return obj.filter.isMatching(ticket);
              });
            }
            return [...obj.tickets].filter(t => t.idCm !== null).filter(
              (ticket) => {
                return obj.filter.isMatching(ticket);
              }
            );
          })
        )
        .subscribe(tickets => {
          this.ticketsInfo.data = [...tickets].sort((a, b) => a.id > b.id ? -1 : 1);
        })
    );
  }

  ngAfterViewInit() {
    this.ticketsInfo.sort = this.sort;
    this.ticketsInfo.paginator = this.paginator;
    this.assignSortingDataAccessor();
  }

  assignSortingDataAccessor(): void {
    this.ticketsInfo.sortingDataAccessor = (item: Ticket, property) => {
      switch (property) {
        case this.ticketTableColumns.Id:
          return item.id;
        case this.ticketTableColumns.Status:
          return item.statusT.status;
        case this.ticketTableColumns.Company:
          return item.idFirma;
        case this.ticketTableColumns.Set:
          return item.idZestaw;
        case this.ticketTableColumns.Plates:
          return item.samochod.nrRej;
        case this.ticketTableColumns.Title:
          return item.nazwa;
        case this.ticketTableColumns.TotalAlerts:
          return item.alarmy.length;
        case this.ticketTableColumns.ActiveAlerts:
          return this.activeAlerts(item.alarmy);
        case this.ticketTableColumns.StartDate:
          return item.dataUtw;
        case this.ticketTableColumns.ClosingDate:
          return item.dataZak;
        case this.ticketTableColumns.Attendant:
          return item.obslugujacy.imie;
        default:
          return item[property];
      }
    };
  }

  showDetails(ticketId: number) {
    this.router.navigate(['events', 'tickets', ticketId]);
  }

  activeAlerts(alerts: IAlarmPodstawowy[]): number {
    return alerts.filter(alert => TransportAlert.isAlertOpen(alert)).length;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
