import { Filter } from './filter';
import { IDriverReport } from '../interfaces';

export class AllFiltersMustMatchFilter implements Filter {
  constructor(private readonly filters: Filter[]) {}

  isMatching(driverReport: IDriverReport): boolean {
    return this.filters.every((filter) => {
      return filter.isMatching(driverReport);
    });
  }
}
