<form fxFlex fxLayout="column" fxLayoutGap="4px" [formGroup]="form" (ngSubmit)="assignParameter()"
      *ngIf="availableParameters && availableParameters.length > 0">
  <mat-form-field>
    <mat-label *ngIf="form.get('parametr').value">Parameter</mat-label>
    <input placeholder="Select parameter to monitor"
           matInput name="avail-params"
           [matAutocomplete]="auto" formControlName="parametr"
    >
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngFor="let option of filteredParams$ | async" [value]="option">
        ({{option.id}}) {{option.parametr}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <div fxFlex fxLayout="row" fxLayoutGap="8px" *ngIf="form.get('parametr').value">
    <mat-form-field fxFlex="35%">
      <mat-label>Min</mat-label>
      <input matInput name="minimum-value" formControlName="wartMin">
    </mat-form-field>
    <mat-form-field fxFlex="35%">
      <mat-label>Max</mat-label>
      <input matInput name="minimum-value" formControlName="wartMax">
    </mat-form-field>
  </div>
  <div fxFlex fxLayout="row" fxLayoutGap="8px" *ngIf="form.get('parametr').value">
    <mat-form-field fxFlex="35%">
      <mat-label>Bad val.</mat-label>
      <input matInput name="minimum-value" formControlName="ryzykoZlaWart">
    </mat-form-field>
    <mat-form-field fxFlex="35%">
      <mat-label>No val.</mat-label>
      <input matInput name="minimum-value" formControlName="ryzykoBrakWart">
    </mat-form-field>
  </div>
  <div fxFlex fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="end center" *ngIf="form.get('parametr').value">
    <button mat-stroked-button color="primary" (click)="initForm()">Reset</button>
    <button mat-raised-button color="primary" type="submit"
                [disabled]="!form.valid || !form.touched">
      Apply
    </button>
  </div>
</form>
