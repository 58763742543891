import { Injectable } from '@angular/core';
import { EnvHelper } from '../helpers';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { RoutingApi } from '../models/admin';
import { HttpClient } from '@angular/common/http';
import { Uprawnienie } from '../models/authentication';

@Injectable({
  providedIn: 'root'
})
export class AdministrationService {
  private endpoint = EnvHelper.getApiUrl();
  private adminConfig = environment.apiModules.Admin;

  constructor(private http: HttpClient) {
  }

  getRouting(): Observable<RoutingApi[]> {
    return this.http.get<RoutingApi[]>(this.endpoint + this.adminConfig.proxy_routing);
  }

  addRouting(entry: RoutingApi): Observable<any> {
    let params: RoutingApi = entry;
    if (typeof params._uprawnienie !== 'string') {
      params = {...params, _uprawnienie: this.adminConfig.uprawnienie + '/' + entry._uprawnienie['id']};
    }
    const url = this.endpoint + this.adminConfig.proxy_routing + '_edycja/';
    return this.http.post(url, params);
  }

  updPrivilege(entry: Uprawnienie): Observable<any> {
    const params = {...entry};
    delete params.children;
    if (params._upr_nadrz && typeof params._upr_nadrz !== 'string') {
      params._upr_nadrz = this.adminConfig.uprawnienie + '/' + entry._upr_nadrz['id'];
    }
    const url = this.endpoint + this.adminConfig.uprawnienie + '_edycja/' + entry.id;
    return this.http.put(url, params);
  }

  updRouting(entry: RoutingApi): Observable<any> {
    const params = {...entry};
    if (typeof params._uprawnienie !== 'string') {
      params._uprawnienie = this.adminConfig.uprawnienie + '/' + entry._uprawnienie['id'];
    }
    const url = this.endpoint + this.adminConfig.proxy_routing + '_edycja/' + entry.id;
    return this.http.put(url, params);
  }

  delRouting(entry: RoutingApi): Observable<any> {
    const params = entry;
    if (typeof params._uprawnienie !== 'string') {
      params._uprawnienie = this.adminConfig.uprawnienie + '/' + entry._uprawnienie['id'];
    }
    params.aktywne = false;
    const url = this.endpoint + this.adminConfig.proxy_routing + '_edycja/' + entry.id;
    return this.http.put(url, {params});
  }

  reloadProxyRouting() {
    return this.http.get(EnvHelper.getApiUrl() + this.adminConfig.proxy_routing_reload);
  }

  reloadProxyPrivs() {
    return this.http.get(EnvHelper.getApiUrl() + this.adminConfig.proxy_privs_reload);
  }


  getPrivileges(): Observable<Uprawnienie[]> {
    return this.http.get<Uprawnienie[]>(
      EnvHelper.getApiUrl() + environment.apiModules.Admin.uprawnienie
    );
  }
}
