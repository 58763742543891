import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import * as ALERTS from '../../ngrx/alert.actions';
import * as fromAlert from '../../ngrx/alert.reducer';
import * as fromTransport from '../../../transports/ngrx/transport.reducer';
import * as fromRoot from '../../../../app.reducer';
import * as UI from '../../../../ngrx/ui.actions';

import { IAlarmTransportowy } from '../../interfaces';
import { AlertService } from '../../services';
import { Strings, TransportAlert } from '../../../../helpers';

@Component({
  selector: 'app-transport-alert-details',
  templateUrl: './transport-alert-details.component.html',
  styleUrls: ['./transport-alert-details.component.scss']
})
export class TransportAlertDetailsComponent implements OnInit, OnDestroy {
  currentAlert: IAlarmTransportowy = undefined;
  selectedAlertId = 0;
  objHash = '';
  eTransportAlert = TransportAlert;

  subs = new Subscription();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private alertSrv: AlertService,
    private store: Store<fromRoot.State>) {
    this.route.paramMap.subscribe(res => {
      this.selectedAlertId = +res.get('id');
      this.store.dispatch(new ALERTS.LoadDetailedTransportAlertByIdRequest(this.selectedAlertId));
    });
    this.store.dispatch(UI.switchLeftDrawer({show: false}));
  }

  ngOnInit(): void {
    this.store.select(fromAlert.getDetailedTransportAlertsById(this.selectedAlertId))
      .pipe(
        filter(f => f !== undefined && this.objHash !== Strings.getObjectHash(f)),
      )
      .subscribe(alert => {
        if (!!alert.terminal) {
          this.alertSrv.showAlertDetailInBreadcrumb$.next('terminal id ' + alert.terminal.id.toString());
        }
        this.objHash = Strings.getObjectHash(alert);
        this.currentAlert = alert;
      });
  }

  closeAndNavigateBack() {
    this.subs.add(
      this.store.select(fromTransport.getCurrentTransit).pipe(
        tap((currentTransit) => {
          if (!currentTransit) {
            this.router.navigate(['events', 'alerts']);
          } else {
            this.router.navigate(['transport', 'all', 'list']);
          }
        })
      ).subscribe()
    );
  }

  ngOnDestroy() {
    this.store.dispatch(new ALERTS.LoadCurrentlyViewedAlertId(null));
    this.alertSrv.showAlertDetailInBreadcrumb$.next(' ');
    this.subs.unsubscribe();
    this.store.dispatch(UI.switchLeftDrawer({show: true}));
  }
}
