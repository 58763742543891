import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import {
  filter,
  finalize,
  map,
  switchMap,
  takeWhile,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { TimedInterval } from 'src/app/modules/shared/helpers';
import { LastFrameDataService, TrucksInventoryService } from 'src/app/services';
import * as fromRoot from '../../../../app.reducer';
import { Strings, TransportHelper } from '../../../../helpers';
import {
  DateFormat,
  TerminalFrames,
  ToastType,
} from '../../../../helpers/enum';
import {
  Terminal,
  TerminalDetails,
  TerminalVehicleType,
} from '../../../../models/dto/terminale';
import { IInfo, ISat, ISatJ } from '../../../../models/frame';
import { GenericPoint } from '../../../../models/map';
import { showUserMessage } from '../../../../ngrx/ui.actions';
import { ITransportEntityExtended } from '../../interfaces/transport-entity';
import {
  GsmStatus,
  SatStatus,
  TabletStatus,
  TransitTestGsm,
  TransitTestLocationManager,
  TransitTestSat,
  TransitTestTablet,
} from './transit-test';

import moment from 'moment/moment';

import * as TRANSPORT from '../../ngrx/transport.actions';
import * as fromTransport from '../../ngrx/transport.reducer';
import * as MAP from '../../../../ngrx/map.actions';
import * as TERMINAL from '../../../../ngrx/terminal.actions';
interface InData {
  fallback?: () => void;
  transportId: number;
}

@Component({
  selector: 'app-transport-alarm-test',
  templateUrl: './transit-alarm-test.component.html',
  styleUrls: ['./transit-alarm-test.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransitAlarmTestComponent implements OnInit, OnDestroy {
  static readonly myname = Strings.getObjectHash('TransitAlarmTestComponent');
  private frameIntervalMs = 5 * 1000;
  private frameTimerMs = 120 * 1000;
  private subscriptions = new Subscription();
  private locationManager: TransitTestLocationManager;

  private truckTerminals: TerminalDetails[] = [];
  private trailerTerminals: TerminalDetails[] = [];
  private driverIds: number[];

  testTruckGsm: TransitTestGsm;
  testTrailerGsm: TransitTestGsm;
  testSat: TransitTestSat;
  testTablet: TransitTestTablet;

  isTruckGsmDone: boolean = false;
  isTrailerGsmDone: boolean = false;
  isSatDone: boolean = false;
  isTabletDone: boolean = false;

  requestTs: number;

  dateFormat: typeof DateFormat = DateFormat;
  fallback: () => void;

  transit$: Observable<ITransportEntityExtended> = this.store
    .select(fromRoot.selectors.ui.getRightDrawerInfo)
    .pipe(
      filter(
        (data) =>
          (data.componentName !== undefined &&
            data.componentName === TransitAlarmTestComponent.myname) ||
          data.isOpened === false
      ),
      map((value) => value.componentData as InData),
      filter((data) => data !== undefined),
      switchMap(({ transportId, fallback }) => {
        if (fallback) {
          this.fallback = fallback;
        }
        this.store.dispatch(
          TRANSPORT.loadTransportByIdRequest({ transportId })
        );
        return this.store
          .select(fromTransport.getCurrentTransit)
          .pipe(filter((r) => r !== null));
      }),
      tap((transit) => {
        const frameRequestTs = moment().unix();
        this.requestTs = frameRequestTs;
        this.setUpTests(frameRequestTs);
        this.setUpLocationManager();
        this.setUpFrameRequests(transit);
        this.setUpTabletRequest(transit);

        this.addTruckGsmSubscription();
        this.addTrailerGsmSubscription();
        this.addSatSubscription();
        this.addTabletSubscription();

        this.cd.detectChanges();
      })
    );

  constructor(
    private readonly store: Store<fromRoot.State>,
    private lastFrameService: LastFrameDataService,
    private trucksInventoryService: TrucksInventoryService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(this.transit$.subscribe());
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();

    this.testTruckGsm.dispose();
    this.testTrailerGsm.dispose();
    this.testSat.dispose();
    this.testTablet.dispose();
    this.locationManager.dispose();
  }

  private setUpTests(frameRequestTs: number): void {
    this.testTruckGsm = new TransitTestGsm(frameRequestTs);
    this.testTrailerGsm = new TransitTestGsm(frameRequestTs);
    this.testSat = new TransitTestSat(frameRequestTs);
    this.testTablet = new TransitTestTablet(frameRequestTs);
  }

  private setUpLocationManager() {
    this.locationManager = new TransitTestLocationManager([
      this.testTruckGsm.status,
      this.testTrailerGsm.status,
      this.testSat.status,
      this.testTablet.status,
    ]);

    this.subscriptions.add(
      this.locationManager.points.subscribe((points: GenericPoint[]) => {
        this.store.dispatch(
          MAP.AdhocPoints({
            points,
          })
        );
      })
    );
  }

  private setUpFrameRequests(transit: ITransportEntityExtended): void {
    const transitTerminals = TransportHelper.getTerminalList(transit);

    this.truckTerminals = transitTerminals.filter(
      (terminal) => terminal.vehicleType === TerminalVehicleType.TRUCK
    );
    this.trailerTerminals = transitTerminals.filter(
      (terminal) => terminal.vehicleType === TerminalVehicleType.TRAILER
    );

    this.truckTerminals.forEach((terminal) => {
      this.store.dispatch(
        showUserMessage({
          message: {
            message: `Requesting status for: ${terminal.vehiclePlate}`,
            type: ToastType.INFO,
          },
        })
      );

      this.requestFrameEmit(terminal.id, TerminalFrames.info);
    });

    this.trailerTerminals.forEach((terminal) => {
      this.store.dispatch(
        showUserMessage({
          message: {
            message: `Requesting status for: ${terminal.vehiclePlate}`,
            type: ToastType.INFO,
          },
        })
      );

      this.requestFrameEmit(terminal.id, TerminalFrames.info);
      this.requestFrameEmit(terminal.id, TerminalFrames.infoSatJ);
      this.requestFrameEmit(terminal.id, TerminalFrames.sat);
    });
  }

  private setUpTabletRequest(transit: ITransportEntityExtended): void {
    const [firstDriverId, secondDriverId]: number[] =
      transit.kierowcaPrzejazdList.map(
        (driverTransit) => driverTransit.idKierowca.id
      );

    this.driverIds = [firstDriverId, secondDriverId];
    this.makeTabletRequest();
  }

  private makeTabletRequest(): void {
    const [firstDriverId, secondDriverId] = this.driverIds;

    this.trucksInventoryService
      .getTruckTablet(firstDriverId, secondDriverId)
      .subscribe((tablets) => {
        if(Array.isArray(tablets)) {
          this.testTablet.passData(tablets);
        } else {
          this.testTablet.passData([tablets]);
        }
      });
  }

  private requestFrameEmit(
    terminalId: number,
    frameName: TerminalFrames
  ): void {
    this.store.dispatch(
      new TERMINAL.RequestForFrameRequest({ terminalId, frameName })
    );
  }

  private addTruckGsmSubscription() {
    this.subscriptions.add(
      TimedInterval.create(this.frameIntervalMs, this.frameTimerMs)
        .pipe(
          withLatestFrom(this.testTruckGsm.status),
          tap(([_, status]: [number, GsmStatus]) => {
            if (status.upToDate) {
              this.isTruckGsmDone = true;
              this.cd.detectChanges();
            }
          }),
          takeWhile(() => !this.isTruckGsmDone),
          finalize(() => {
            this.isTruckGsmDone = true;
            this.cd.detectChanges();
          })
        )
        .subscribe(() => {
          if (this.truckTerminals[0]) {
            this.lastFrameService
              .getInfoFrames([this.truckTerminals[0] as unknown as Terminal])
              .subscribe(([truckInfoFrame]: [IInfo]) => {
                this.testTruckGsm.passData(truckInfoFrame);
              });
          } else {
            this.isTruckGsmDone = true;
          }
        })
    );
  }

  private addTrailerGsmSubscription() {
    this.subscriptions.add(
      TimedInterval.create(this.frameIntervalMs, this.frameTimerMs)
        .pipe(
          withLatestFrom(this.testTrailerGsm.status),
          tap(([_, status]: [number, GsmStatus]) => {
            if (status.upToDate) {
              this.isTrailerGsmDone = true;
              this.cd.detectChanges();
            }
          }),
          takeWhile(() => !this.isTrailerGsmDone),
          finalize(() => {
            this.isTrailerGsmDone = true;
            this.cd.detectChanges();
          })
        )
        .subscribe(() => {
          this.lastFrameService
            .getInfoFrames([this.trailerTerminals[0] as unknown as Terminal])
            .subscribe(([trailerInfoFrame]: [IInfo]) => {
              this.testTrailerGsm.passData(trailerInfoFrame);
            });
        })
    );
  }

  private addSatSubscription() {
    this.subscriptions.add(
      TimedInterval.create(this.frameIntervalMs, this.frameTimerMs)
        .pipe(
          withLatestFrom(this.testSat.status),
          tap(([_, status]: [number, SatStatus]) => {
            if (!status.hasOwnProperty('noDevice')) {
              return;
            }
            // In case of no device, device problem or up to date, mark as done
            if (
              status.noDevice ||
              (!status.noDevice && !status.deviceOk) ||
              status.upToDate
            ) {
              this.isSatDone = true;
              this.cd.detectChanges();
            }
          }),
          takeWhile(() => !this.isSatDone),
          finalize(() => {
            this.isSatDone = true;
            this.cd.detectChanges();
          })
        )
        .subscribe(() => {
          const trailerTerminalId: number = this.trailerTerminals[0].id;

          combineLatest([
            this.lastFrameService.getSatTerminalFrame(trailerTerminalId),
            this.lastFrameService.getSatJTerminalFrame(trailerTerminalId),
          ]).subscribe(([satFrame, satJFrame]: [ISat, ISatJ]) => {
            this.testSat.passData([satFrame], 'sat');
            this.testSat.passData([satJFrame], 'satj');
          });
        })
    );
  }

  private addTabletSubscription() {
    this.subscriptions.add(
      TimedInterval.create(this.frameIntervalMs, this.frameTimerMs)
        .pipe(
          withLatestFrom(this.testTablet.status),
          tap(([_, status]: [number, TabletStatus]) => {
            if (!status.hasOwnProperty('noDevice')) {
              return;
            }
            // In case of no device or up to date, mark as done
            if (status.upToDate || status.noDevice) {
              this.isTabletDone = true;
              this.cd.detectChanges();
            }
          }),
          takeWhile(() => !this.isTabletDone),
          finalize(() => {
            this.isTabletDone = true;
            this.cd.detectChanges();
          })
        )
        .subscribe(() => {
          this.makeTabletRequest();
        })
    );
  }
}
