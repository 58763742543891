import { Action } from '@ngrx/store';
import {DriverTablet, Naczepa, Samochod, StanPracy, StatusPojazdu, VehSearchTerm} from '../models/dto/vehicle';

export const ADD_TRUCK_REQUEST = '[VEHICLE] Add new vehicle - request';
export const ADD_TRUCK_SUCCESS = '[VEHICLE] Add new vehicle - success';
export const ADD_TRUCK_ERROR = '[VEHICLE] Add new vehicle - error';

export const LOAD_VEHICLES_REQUEST = '[VEHICLE] Load trucks - request';
export const LOAD_VEHICLES_SUCCESS = '[VEHICLE] Load trucks - success';
export const LOAD_VEHICLE_REQUEST = '[VEHICLE] Load truck - request';
export const LOAD_VEHICLE_SUCCESS = '[VEHICLE] Load truck - success';

export const EDIT_VEHICLE_REQUEST = '[VEHICLE] Edit vehicle - request';
export const EDIT_VEHICLE_SUCCESS = '[VEHICLE] Edit vehicle - success';

export const REMOVE_VEHICLE_REQUEST = '[VEHICLE] Remove vehicle - request';
export const REMOVE_VEHICLE_FORCE_REQUEST = '[VEHICLE] Remove vehicle force - request';

export const ADD_TRAIL_REQUEST = '[VEHICLE] Add new trail - request';
export const ADD_TRAIL_SUCCESS = '[VEHICLE] Add new trail - success';
export const LOAD_TRAILS_REQUEST = '[VEHICLE] Load trails - request';
export const LOAD_TRAILS_SUCCESS = '[VEHICLE] Load trails - success';
export const REMOVE_TRAIL_REQUEST = '[VEHICLE] Remove trail - request';

export const EDIT_TRAIL_REQUEST = '[VEHICLE] Edit trail - request';

export const LOAD_WORK_STATES_REQUEST = '[VEHICLE] Load Work states - request';
export const LOAD_WORK_STATES_SUCCESS = '[VEHICLE] Load Work states - success';

export const LOAD_VEHICLE_STATES_REQUEST = '[VEHICLE] Load Vehicle states - request';
export const LOAD_VEHICLE_STATES_SUCCESS = '[VEHICLE] Load Vehicle states - success';
export const FILTER_VEHICLES = '[VEHICLE] filter vehicles';


export class LoadVehicleStatesRequest implements Action {
  readonly type = LOAD_VEHICLE_STATES_REQUEST;
}

export class LoadVehicleStatesSuccess implements Action {
  readonly type = LOAD_VEHICLE_STATES_SUCCESS;

  constructor(public payload: StatusPojazdu[]) {
  }
}

export class LoadWorkStatesRequest implements Action {
  readonly type = LOAD_WORK_STATES_REQUEST;
}

export class LoadWorkStatesSuccess implements Action {
  readonly type = LOAD_WORK_STATES_SUCCESS;

  constructor(public payload: StanPracy[]) {
  }
}


export class AddTruckRequest implements Action {
  readonly type = ADD_TRUCK_REQUEST;

  constructor(public payload: {truck: Samochod}) {
  }
}

export class AddTruckSuccess implements Action {
  readonly type = ADD_TRUCK_SUCCESS;

  constructor(public payload: Samochod) {
  }
}

export class RemoveVehicleRequest implements Action {
  readonly type = REMOVE_VEHICLE_REQUEST;

  constructor(public payload: {vehicle: Samochod}) {
  }
}
export class RemoveVehicleForceRequest implements Action {
  readonly type = REMOVE_VEHICLE_FORCE_REQUEST;

  constructor(public payload: {id: number}) {
  }
}

export class RemoveTrailRequest implements Action {
  readonly type = REMOVE_TRAIL_REQUEST;

  constructor(public payload: {trail: Naczepa}) {
  }
}

export class AddTruckError implements Action {
  readonly type = ADD_TRUCK_ERROR;

  constructor(public payload: any) {
  }
}

export class LoadVehiclesRequest implements Action {
  readonly type = LOAD_VEHICLES_REQUEST;
}

export class LoadVehiclesSuccess implements Action {
  readonly type = LOAD_VEHICLES_SUCCESS;

  constructor(public payload: Samochod[]) {
  }
}

export class LoadVehicleRequest implements Action {
  readonly type = LOAD_VEHICLE_REQUEST;

  constructor(public payload: number) {
  }
}

export class LoadVehicleSuccess implements Action {
  readonly type = LOAD_VEHICLE_SUCCESS;

  constructor(public payload: Samochod) {
  }
}

export class EditVehicleRequest implements Action {
  readonly type = EDIT_VEHICLE_REQUEST;

  constructor(public payload: { record: Partial<Samochod>, callback?: () => void }) {
  }
}

export class EditVehicleSuccess implements Action {
  readonly type = EDIT_VEHICLE_SUCCESS;

  constructor(public payload: any) {
  }
}

export class AddTrailRequest implements Action {
  readonly type = ADD_TRAIL_REQUEST;

  constructor(public payload: {trailer: Naczepa, callback: () => void}) {
  }
}

export class AddTrailSuccess implements Action {
  readonly type = ADD_TRAIL_SUCCESS;

  constructor(public payload: Naczepa) {
  }
}

export class EditTrailRequest implements Action {
  readonly type = EDIT_TRAIL_REQUEST;

  constructor(public payload: {trail: Naczepa, callback?: () => void}) {
  }
}


export class LoadTrailsRequest implements Action {
  readonly type = LOAD_TRAILS_REQUEST;
}

export class LoadTrailsSuccess implements Action {
  readonly type = LOAD_TRAILS_SUCCESS;

  constructor(public payload: Naczepa[]) {
  }
}

export const LOAD_TRAIL_TYPES_REQUEST = '[VEHICLE] Load trails types - request';
export const LOAD_CONN_TYPES_REQUEST = '[VEHICLE] Load  connection types- request';
export const LOAD_TRUCK_TYPES_REQUEST = '[VEHICLE] Load truck types - request';
export const LOAD_UNAVAIL_REASONS_REQUEST = '[VEHICLE] Load unavailability reasons - request';
export const LOAD_MODELS_REQUEST = '[VEHICLE] Load models - request';
export const LOAD_VENDOR_REQUEST = '[VEHICLE] Load vendors - request';

export class LoadTrailTypesRequest implements Action {
  readonly type = LOAD_TRAIL_TYPES_REQUEST;
}

export class LoadConnectionTypesRequest implements Action {
  readonly type = LOAD_CONN_TYPES_REQUEST;
}

export class LoadTruckTypesRequest implements Action {
  readonly type = LOAD_TRUCK_TYPES_REQUEST;
}

export class LoadUnavailReasonsRequest implements Action {
  readonly type = LOAD_UNAVAIL_REASONS_REQUEST;
}

export class LoadModelsRequest implements Action {
  readonly type = LOAD_MODELS_REQUEST;
}

export class LoadVendorsRequest implements Action {
  readonly type = LOAD_VENDOR_REQUEST;
}

export const LOAD_TRAIL_TYPES_SUCCESS = '[VEHICLE] Load trails types - success';
export const LOAD_CONN_TYPES_SUCCESS = '[VEHICLE] Load  connection types- success';
export const LOAD_TRUCK_TYPES_SUCCESS = '[VEHICLE] Load truck types - success';
export const LOAD_UNAVAIL_REASONS_SUCCESS = '[VEHICLE] Load unavailability reasons - success';
export const LOAD_MODELS_SUCCESS = '[VEHICLE] Load models - success';
export const LOAD_VENDOR_SUCCESS = '[VEHICLE] Load vendors - success';

export class LoadTrailTypesSuccess implements Action {
  readonly type = LOAD_TRAIL_TYPES_SUCCESS;

  constructor(public payload: any[]) {
  }
}

export class LoadConnectionTypesSuccess implements Action {
  readonly type = LOAD_CONN_TYPES_SUCCESS;

  constructor(public payload: any[]) {
  }
}

export class LoadTruckTypesSuccess implements Action {
  readonly type = LOAD_TRUCK_TYPES_SUCCESS;

  constructor(public payload: any[]) {
  }
}

export class LoadUnavailReasonsSuccess implements Action {
  readonly type = LOAD_UNAVAIL_REASONS_SUCCESS;

  constructor(public payload: any[]) {
  }
}

export class LoadModelsSuccess implements Action {
  readonly type = LOAD_MODELS_SUCCESS;

  constructor(public payload: any[]) {
  }
}

export class LoadVendorsSuccess implements Action {
  readonly type = LOAD_VENDOR_SUCCESS;

  constructor(public payload: any[]) {
  }
}

export class FilterVehicles implements Action {
  readonly type = FILTER_VEHICLES;
  constructor(public payload: VehSearchTerm) {
  }
}

// [terminal] - request for truck tablet location
export const TRUCK_TABLET_LOC_REQUEST = '[TERMINAL] request for tablet location - request';

export class TruckTabletLocationRequest implements Action {
  readonly type = TRUCK_TABLET_LOC_REQUEST;

  constructor(public payload: { firstDriverId: number, secondDriverId: number }) {
  }
}

export const TRUCK_TABLET_LOC_SUCCESS = '[TERMINAL] request for tablet location - success';

export class TruckTabletLocationSuccess implements Action {
  readonly type = TRUCK_TABLET_LOC_SUCCESS;

  constructor(public payload: { data: DriverTablet[] }) {
  }
}

export type VehicleActions =
  AddTruckRequest | AddTruckSuccess | AddTruckError | LoadVehiclesRequest | LoadVehiclesSuccess |
  EditVehicleRequest | EditVehicleSuccess |
  AddTrailRequest | AddTrailSuccess | LoadTrailsRequest | LoadTrailsSuccess |
  LoadTrailTypesRequest | LoadConnectionTypesRequest | LoadTruckTypesRequest |
  LoadUnavailReasonsRequest | LoadModelsRequest | LoadVendorsRequest |
  LoadTrailTypesSuccess | LoadConnectionTypesSuccess | LoadTruckTypesSuccess |
  LoadUnavailReasonsSuccess | LoadModelsSuccess | LoadVendorsSuccess |
  LoadWorkStatesRequest | LoadWorkStatesSuccess | LoadVehicleStatesRequest | LoadVehicleStatesSuccess |
  LoadVehicleRequest | LoadVehicleSuccess | FilterVehicles |
  TruckTabletLocationRequest | TruckTabletLocationSuccess;
