<div fxFlex fxLayout="column" fxLayoutGap="8px">
  <div fxFlex fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
    <mat-form-field>
      <input name="search-parking-lots" matInput placeholder="Type to search..." [formControl]="formSearch">
    </mat-form-field>
    <button mat-raised-button (click)="createNew()">Add new</button>
  </div>
  <div class="table-container">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <caption>List of cargo owners for company</caption>

      <ng-container matColumnDef="id">
        <th scope="col" mat-header-cell *matHeaderCellDef> No.</th>
        <td mat-cell *matCellDef="let element">
          {{element.id}}
        </td>
      </ng-container>

      <ng-container matColumnDef="nazwa">
        <th scope="col" mat-header-cell *matHeaderCellDef> Name</th>
        <td mat-cell *matCellDef="let element"> {{element.nazwa}} </td>
      </ng-container>

      <ng-container matColumnDef="telefon">
        <th scope="col" mat-header-cell *matHeaderCellDef> Phone</th>
        <td mat-cell *matCellDef="let element"> {{element.telefon}} </td>
      </ng-container>

      <ng-container matColumnDef="kontakt">
        <th scope="col" mat-header-cell *matHeaderCellDef> Contact</th>
        <td mat-cell *matCellDef="let element"> {{element.kontakt}} </td>
      </ng-container>

      <ng-container matColumnDef="uwagi">
        <th scope="col" mat-header-cell *matHeaderCellDef> Details</th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.uwagi; else noData">{{element.uwagi}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="inne">
        <th scope="col" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu" class="parking-menu">
            <button mat-menu-item (click)="edit(element)">
              <mat-icon class="edit material-icons-outlined">edit</mat-icon>
              <span>Edit</span>
            </button>
            <button mat-menu-item (click)="remove(element)">
              <mat-icon class="remove material-icons-outlined">delete</mat-icon>
              <span>Remove</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; "></tr>
    </table>
  </div>
</div>

<ng-template #noData>
  <span class="no-details">No information</span>
</ng-template>
