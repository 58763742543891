<div
  *ngIf="viaPoints.value.length > 0"
  cdkDropList
  class="via-points"
  (cdkDropListDropped)="drop($event)"
>
  <mat-label>Via</mat-label>
  <div *ngFor="let point of viaPoints.value; let i = index">
    <div cdkDrag appMaterialElevation class="via-point">
      <span class="material-icons-outlined" cdkDragHandle>drag_indicator</span>
      <span class="via-point-label">{{ searchBase.displayFn(point) }}</span>
      <button
        mat-icon-button
        type="button"
        color="warn"
        class="delete-button"
        (click)="remove(point, i)"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
</div>
