import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../app.reducer';
import { tap } from 'rxjs/operators';
import { AuthenticationService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class IsAuthenticatedGuard implements CanActivate {

  constructor(private store: Store<fromRoot.State>, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.select(fromRoot.selectors.auth.getAuthIsAuthenticated)
      .pipe(
        tap(r => {
          if (!r) {
            AuthenticationService.setFallbackUrl(window.location.pathname, window.location.search);
            this.router.navigate(['/access/login']);
          }
        })
      );
  }
}
