<div class="content" *ngIf="transit" fxFlex fxLayout="column" fxLayoutGap="16px">
  <div>
    <h2>Monitoring parameters</h2>
    <app-transit-card [transit]="transit"></app-transit-card>
  </div>
  <div class="params" fxFlex fxLayout="column" fxLayoutGap="8px">
    <app-transit-monitor-param-form [transit]="transit"
                                    (reloadParameters)="loadParams()"></app-transit-monitor-param-form>

    <app-transit-assigned-params [transit]="transit"
                                 (reloadParameters)="loadParams()"></app-transit-assigned-params>
  </div>
</div>
