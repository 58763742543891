import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IAlarmTransportowyDTO } from '../../../models/dto/alert/alarm-transportowy';
import { IAlarmTransportowy, IAlarmTransportowyUproszczony, IPodjetaAkcja } from '../interfaces';
import { IAlarmTransportowyUproszczonyDTO, IStatusAlarmu } from 'src/app/models/dto/alert/alarm-transportowy-uproszczony';
import { Uzytkownik } from '../../../models/dto/user';
import { EnvHelper } from '../../../helpers';
import { INowaPodjetaAkcjaDTO, IPodjetaAkcjaDTO } from 'src/app/models/dto/alert/podjeta-akcja';
import { Ticket } from '../../tickets/interfaces';
import { UserRoleNames } from '../../../helpers/enum';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  apiConfig = environment.apiModules;

  showAlertDetailInBreadcrumb$ = new Subject<string>();

  constructor(private http: HttpClient) {
  }

  getAllAlerts(): Observable<IAlarmTransportowyUproszczony[]> {
    return this.http.get<IAlarmTransportowyUproszczonyDTO[]>(
      EnvHelper.getApiUrl() + this.apiConfig.Alert.alarmy,
    );
  }

  getAlertsByStatus(statusList: number[], fromTimestamp?: number): Observable<IAlarmTransportowyUproszczony[]> {
    let linkParams = statusList.filter(n => typeof n === 'number').join(',');
    if (fromTimestamp) {
      linkParams += '/ts/' + fromTimestamp;
    }
    return this.http.get<IAlarmTransportowyUproszczonyDTO[]>(
      EnvHelper.getApiUrl() + this.apiConfig.Alert.alarmy + '/statusy/' + linkParams,
    );
  }

  getDetailedTransportAlertById(alertId: number): Observable<IAlarmTransportowy> {
    return this.http.get<IAlarmTransportowyDTO>(
      EnvHelper.getApiUrl() + this.apiConfig.Alert.alarmy + '/' + alertId
    );
  }

  assignTransportAlertHandler(alertId: number): Observable<IAlarmTransportowy> {
    return this.http.put<IAlarmTransportowyDTO>(
      EnvHelper.getApiUrl() + this.apiConfig.Alert.alarmy + '/' + alertId + this.apiConfig.Alert.obs_dysp_przypisz,
      {},
    );
  }

  loadAvailableDispatchers() {
    return this.http.get<Uzytkownik[]>(
      EnvHelper.getApiUrl() + this.apiConfig.User.uzytkownik + '?' + 'rola=' + UserRoleNames.dispatcher
    );
  }

  assignTransportAlertToParticularDispatcher(alertId: number, dispatcherId: number): Observable<IAlarmTransportowy> {
    return this.http.put<IAlarmTransportowyDTO>(
      EnvHelper.getApiUrl() + this.apiConfig.Alert.alarmy + '/' + alertId + this.apiConfig.Alert.dyspozytor + '/' + dispatcherId
      + this.apiConfig.Alert.przypisz,
      {
        statusAlarmu: {
          id: 2,
          status: 'obsługiwany'
        }
      }
    );
  }

  cancelTransportAlertParticularDispatcherAssignment(alertId: number): Observable<IAlarmTransportowy> {
    return this.http.put<IAlarmTransportowyDTO>(
      EnvHelper.getApiUrl() + this.apiConfig.Alert.alarmy + '/' + alertId + this.apiConfig.Alert.dyspozytor
      + this.apiConfig.Alert.usun_przypisanie,
      {}
    );
  }

  createTransportAlertTicket(ticketName: string, alert: IAlarmTransportowy): Observable<Ticket> {
    return this.http.post<Ticket>(
      EnvHelper.getApiUrl() + this.apiConfig.Ticket.tickety + this.apiConfig.Ticket.utworz_dla_alarmu,
      {
        alarm: alert,
        nazwa: ticketName,
      }
    );
  }

  terminateTransportAlert(alertId: number, alertStatusId: number): Observable<IAlarmTransportowy> {
    return this.http.put<IAlarmTransportowyDTO>(
      EnvHelper.getApiUrl() + this.apiConfig.Alert.alarmy + this.apiConfig.Alert.zakoncz,
      {
        id: +alertId,
        idStatusAlarmu: +alertStatusId,
      },
    );
  }

  saveNewTakenAction(action: INowaPodjetaAkcjaDTO): Observable<IPodjetaAkcja> {
    return this.http.post<IPodjetaAkcjaDTO>(
      EnvHelper.getApiUrl() + this.apiConfig.Action.podjete_akcje,
      action,
    );
  }

  getTakenActionsByTicketId(ticketId: number): Observable<IPodjetaAkcja[]> {
    return this.http.get<IPodjetaAkcjaDTO[]>(
      EnvHelper.getApiUrl() + this.apiConfig.Ticket.tickety + '/' + ticketId + this.apiConfig.Ticket.podjete_akcje
    );
  }

  getAlertStatusList(): Observable<IStatusAlarmu[]> {
    return this.http.get<IStatusAlarmu[]>(
      EnvHelper.getApiUrl() + this.apiConfig.Alert.statusy_alarmu
    );
  }

}
