<div class="r-drawer-container">
  <div class="title-bar">
    Traveled routes
  </div>

  <form [formGroup]="formTrasaPrzejazdu" (ngSubmit)="onSubmit()" #form fxLayout="column" fxLayoutGap="10px">
    <div class="content" fxLayout="column">
      <section fxLayout="column" fxLayoutGap="10px">
        <mat-form-field>
          <input matInput [matDatepicker]="pickerFrom" placeholder="Date from:" formControlName="dataOd">
          <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
          <mat-datepicker #pickerFrom></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
          <input matInput [matDatepicker]="pickerTo" placeholder="Date to:" formControlName="dataDo">
          <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
          <mat-datepicker #pickerTo></mat-datepicker>
        </mat-form-field>
      </section>
      <mat-form-field>
        <mat-label>Veh. type</mat-label>
        <mat-select formControlName="typPojazdu">
          <mat-option value="truck" *appHasPrivileges="[ePrivs.READ_VEHICLES]">Truck</mat-option>
          <mat-option value="trail" *appHasPrivileges="[ePrivs.READ_VEHICLES]">Trailer</mat-option>
          <mat-option value="terminal" *appHasPrivileges="[ePrivs.READ_TERMINALS]">Terminal</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="formTrasaPrzejazdu.value.typPojazdu == 'truck'">
        <mat-select formControlName="identyfikatorPojazdu" placeholder="Pick one...">
          <mat-option *ngFor="let item of samochod$ | async" [value]="item.id">
            {{item.nr_rej}}: <i>{{item._model._marka.marka}} {{item._model.model}}</i>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="formTrasaPrzejazdu.value.typPojazdu == 'trail'">
        <mat-select formControlName="identyfikatorPojazdu" placeholder="Pick one...">
          <mat-option *ngFor="let item of naczepy$ | async" [value]="item.id">
            {{item.nr_rej}}: <i>{{item._model.model}}, {{item._model._marka.marka}}</i>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="formTrasaPrzejazdu.value.typPojazdu == 'terminal'">
        <mat-chip-list #chipList aria-label="Terminal selection">
          <mat-chip
            *ngFor="let terminal of formTrasaPrzejazdu.value.identyfikatoryPojazdu"
            [selectable]="false"
            [removable]="true"
            (removed)="remove(terminal)">
            {{terminal.id}}: {{terminal.uwagi}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input
            placeholder="Type to search..."
            #terminalInput
            formControlName="identyfikatorTerminala"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList">
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
          <mat-option *ngFor="let terminal of fTerminale$ | async" [value]="terminal">
            {{terminal.id}}: {{terminal.uwagi}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <div fxFlex fxLayout="row" fxLayoutGap="5px">
        <mat-checkbox formControlName="pokazPunkty">Points</mat-checkbox>
        <mat-checkbox formControlName="pokazLinie">Paths</mat-checkbox>
      </div>
    </div>
    <div class="actions" fxLayoutGap="5px">
      <button mat-raised-button (click)="downloadCsv()"
              [disabled]="(searchProcesses$ | async) > 0 || trasaPrzejazdu.length < 1">
        Save to CSV
      </button>
      <button mat-raised-button color="primary" type="submit" [disabled]="(searchProcesses$ | async) > 0">
        Show
      </button>
    </div>
  </form>
</div>
