import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActionNames, AlertActions } from './alert.actions';
import { IAlarmTransportowy, IAlarmTransportowyUproszczony, IPodjetaAkcja } from '../interfaces';
import { Uzytkownik } from '../../../models/dto/user';
import { IStatusAlarmu } from '../../../models/dto/alert/alarm-transportowy-uproszczony';
import { AlertFilterStatusType } from '../models/filters';
import { AlertFilterStatusEnum } from '../enum/alert-filter-status.enum';
import * as moment from 'moment';

export interface State {
  flattenedTransportAlerts: IAlarmTransportowyUproszczony[];
  detailedTransportAlerts: IAlarmTransportowy[];
  currentlyViewedAlertId: number | null;

  transportAlertsHandledIdList: number[];
  availableDispatchers: Uzytkownik[];
  transportAlertsAssignedToParticularDispatchers: { alarmId: number, dyspozytorId: number }[];
  transportAlertsWithTicketCreatedIdList: number[];
  transportAlertsTerminatedIdList: number[];
  takenActionsList: { ticketId: number, actions: IPodjetaAkcja[] }[];
  alertStatusList: IStatusAlarmu[];
  alertFilter: {
    text: string;
    status: AlertFilterStatusType;
  };

  prevAlertsUpdate: moment.Moment | null;
  lastAlertsUpdate: moment.Moment | null;
}

export const initialState: State = {
  flattenedTransportAlerts: [],
  detailedTransportAlerts: [],
  currentlyViewedAlertId: null,

  transportAlertsHandledIdList: [],
  availableDispatchers: [],
  transportAlertsAssignedToParticularDispatchers: [],
  transportAlertsWithTicketCreatedIdList: [],
  transportAlertsTerminatedIdList: [],
  takenActionsList: [],
  alertStatusList: [],
  alertFilter: {
    text: '',
    status: AlertFilterStatusEnum.Active
  },

  prevAlertsUpdate: null,
  lastAlertsUpdate: null,
};

export const featureName = 'alert';

export function reducer(state = initialState, action: AlertActions) {
  switch (action.type) {
    case ActionNames.LOAD_TRANSPORT_ALERTS_BY_STATUS_SUCCESS: {
      const alerts = [...state.flattenedTransportAlerts]
        .filter(a => !action.payload.statusList.includes(a.statusAlarmu.id));

      return {
        ...state,
        flattenedTransportAlerts: [...alerts, ...action.payload.alerts],
        ...(action.payload.refreshDates
          ? {
              prevAlertsUpdate: state.lastAlertsUpdate,
              lastAlertsUpdate: action.payload.updateDate,
            }
          : {}),
      };
    }
    case ActionNames.LOAD_ALL_TRANSPORT_ALERTS_SUCCESS:
      return {
        ...state,
        flattenedTransportAlerts: action.payload,
      };

    case ActionNames.LOAD_DETAILED_TRANSPORT_ALERT_BY_ID_SUCCESS:
      return {
        ...state,
        detailedTransportAlerts: [...state.detailedTransportAlerts, action.payload]
      };

    case ActionNames.LOAD_CURRENTLY_VIEWED_ALERT_ID:
      return {
        ...state,
        currentlyViewedAlertId: action.payload,
      };

    case ActionNames.ASSIGN_TRANSPORT_ALERT_HANDLER_SUCCESS:
      return {
        ...state,
        transportAlertsHandledIdList: [...state.transportAlertsHandledIdList, action.payload.alertId]
      };

    case ActionNames.UPDATE_DETAILED_TRANSPORT_ALERT_BY_ID_SUCCESS:
      const updatedAlertId = action.payload.id;
      const detailedAlertsFiltered = [...state.detailedTransportAlerts].filter(a => a.id !== updatedAlertId);
      return {
        ...state,
        detailedTransportAlerts: [...detailedAlertsFiltered, action.payload],
      };

    case ActionNames.LOAD_AVAILABLE_DISPATCHERS_SUCCESS:
      return {
        ...state,
        availableDispatchers: action.payload
      };

    case ActionNames.ASSIGN_TRANSPORT_ALERT_TO_PARTICULAR_DISPATCHER_SUCCESS:
      const data = {alarmId: action.payload.alertId, dyspozytorId: action.payload.dispatcherId};
      return {
        ...state,
        transportAlertsAssignedToParticularDispatchers: [...state.transportAlertsAssignedToParticularDispatchers, data]
      };

    case ActionNames.CANCEL_TRANSPORT_ALERT_PARTICULAR_DISPATCHER_ASSIGNMENT_SUCCESS:
      const alertIndex = action.payload.alertId;
      const dataId = [...state.transportAlertsAssignedToParticularDispatchers].findIndex(el => el.alarmId === alertIndex);
      if (!!dataId) {
        state.transportAlertsAssignedToParticularDispatchers.splice(dataId, 1);
      }
      return {
        ...state,
        transportAlertsAssignedToParticularDispatchers: [...state.transportAlertsAssignedToParticularDispatchers],
      };

    case ActionNames.CREATE_TRANSPORT_ALERT_TICKET_SUCCESS:
      return {
        ...state,
        transportAlertsWithTicketCreatedIdList:
          [...state.transportAlertsWithTicketCreatedIdList, action.payload.alarm.id]
      };

    case ActionNames.TERMINATE_TRANSPORT_ALERT_SUCCESS:
      return {
        ...state,
        transportAlertsTerminatedIdList: [...state.transportAlertsTerminatedIdList, action.payload.alertId]
      };

    case ActionNames.SAVE_TAKEN_ACTION_SUCCESS:
      const ticketId_ = action.payload.ticketId;
      const action_ = action.payload.action;
      const alreadyStoredTicketsIdList = [];

      state.takenActionsList.forEach(elem => {
        if (elem) {
          alreadyStoredTicketsIdList.push(elem.ticketId);
        }
      });

      if (alreadyStoredTicketsIdList.indexOf(ticketId_) === -1) {
        return {
          ...state,
          takenActionsList: [...state.takenActionsList, {ticketId: ticketId_, actions: [action_]}]
        };
      } else {
        const alreadyExistingActionsList_ = [...state.takenActionsList].find(el => el.ticketId === ticketId_);
        const takenActionsList_ = [...state.takenActionsList].filter(el => el.ticketId !== ticketId_);
        takenActionsList_.push({ticketId: ticketId_, actions: [...alreadyExistingActionsList_.actions, action_]});
        return {
          ...state,
          takenActionsList: takenActionsList_,
        };
      }

    case ActionNames.LOAD_ALERT_STATUS_LIST_SUCCESS:
      return {...state, alertStatusList: action.payload.statusList};

    case ActionNames.LOAD_TAKEN_ACTIONS_BY_TICKET_ID_SUCCESS:
      const currentTicketId = action.payload.ticketId;
      let currentTicketTakenActions = [];
      if (action.payload.actions.length > 0) {
        currentTicketTakenActions = [{ticketId: currentTicketId, actions: action.payload.actions}];
      }
      return {
        ...state,
        takenActionsList: currentTicketTakenActions,
      };

    case ActionNames.SET_ALERT_FILTER:
      return {
        ...state,
        alertFilter: action.payload
      };

    default:
      return state;
  }
}

export const getState = createFeatureSelector<State>(featureName);
export const getFlattenedTransportAlerts = createSelector(getState, (state: State) => state.flattenedTransportAlerts);
export const getTransitRelatedFlattenedTransportAlerts = (transportId: number) => createSelector(
  getState,
  (data) => data.flattenedTransportAlerts.filter(t => t.idPrzejazd && t.idPrzejazd === transportId)
);
export const getFlattenedTransportAlertsById = (alertId: number) => createSelector(
  getState,
  (data) => data.flattenedTransportAlerts.find(a => a.id === alertId)
);

export const getDetailedTransportAlerts = createSelector(getState, (state: State) => state.detailedTransportAlerts);
export const getDetailedTransportAlertsById = (alertId: number) => createSelector(getState, (state: State) => {
  return state.detailedTransportAlerts.find(a => a.id === alertId);
});

export const getAlertStatusList = createSelector(getState, (state: State) => state.alertStatusList);

export const getHandledTransportAlertsIds = createSelector(getState, (state: State) => state.transportAlertsHandledIdList);
export const getAvailableDispatchers = createSelector(getState, (state: State) => state.availableDispatchers);
export const getTransportAlertsAssignedToParticularDispatchers =
  createSelector(getState, (state: State) => state.transportAlertsAssignedToParticularDispatchers);
export const getTakenActionsByTicketId = (ticketId: number) => createSelector(getState, (state) => {
  return state.takenActionsList.find(a => a.ticketId === ticketId);
});
export const getAlertFilter = createSelector(getState, (state: State) => state.alertFilter);

