import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import isEqual from 'lodash/isEqual';

import * as fromDrivers from '../../../drivers/ngrx/driver.reducer';
import * as fromRoot from '../../../../app.reducer';
import * as fromTransport from '../../../transports/ngrx/transport.reducer';
import { loadTransportByIdRequest } from '../../../transports/ngrx/transport.actions';

import { IAlarmTransportowy } from '../../interfaces';
import { Kierowca } from '../../../drivers/interfaces';
import { Terminal } from '../../../../models/dto/terminale';
import { IZgloszenieKierowcyDTO } from '../../../../models/dto/alert/zgloszenie-kierowcy';
import { IInfo } from '../../../../models/frame';
import { DateFormat } from '../../../../helpers/enum';
import { TransportOverviewComponent } from '../../../transports/components/transport-overview';
import { RightDrawerService } from '../../../shared/services';

@Component({
  selector: 'app-transport-data',
  templateUrl: './transport-data.component.html',
  styleUrls: ['./transport-data.component.scss']
})
export class TransportDataComponent implements OnInit, OnDestroy {
  @Input() alert: IAlarmTransportowy;

  lastLocation: IInfo;
  terminal: Terminal;
  lastDriverReport: IZgloszenieKierowcyDTO;
  driver$: Observable<Kierowca>;
  subs = new Subscription();
  df = DateFormat;

  constructor(private store: Store<fromDrivers.State>, private rightDrawer: RightDrawerService) {
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  showTransportData(transportId: number): void {
    this.store.dispatch(loadTransportByIdRequest({transportId}));
    this.subs.add(
      this.store.select(fromTransport.getCurrentTransit)
        .pipe(
          filter(transport => transport?.id !== undefined),
          take(1)
        )
        .subscribe(transport => {
          this.rightDrawer.openOver(TransportOverviewComponent, {transport});
        })
    );
  }

  ngOnInit(): void {
    if (this.alert.zgloszeniaKierowcy.length > 0) {
      this.lastDriverReport = this.alert.zgloszeniaKierowcy[this.alert.zgloszeniaKierowcy.length - 1];
    }

    if (this.alert && this.alert.terminal && this.alert.terminal.id) {
      this.subs.add(
        combineLatest([
          this.store.select(fromRoot.selectors.devices.getTerminalById(this.alert.terminal.id)),
          this.store.select(fromRoot.selectors.frame.getInfoByTerminal(this.alert.terminal.id))
        ]).subscribe(([t, p]) => {
          if (t && t.id && !isEqual(t, this.terminal)) {
            this.terminal = t;
          }
          if (p && p.czas && !isEqual(p, this.lastLocation)) {
            this.lastLocation = p;
          }
        })
      );
    }

    this.driver$ = this.store.select(fromDrivers.getDrivers).pipe(
      map((drivers) => {
        return drivers.find(d => d.id === this.lastDriverReport?.idKierowca);
      })
    );
  }

}
