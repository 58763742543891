import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import moment from 'moment/moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as fromRoot from '../../../app.reducer';
import { IAlarmTransportowyUproszczony } from '../interfaces';

@Pipe({
  name: 'isNewAlert',
})
export class IsNewAlertPipe implements PipeTransform {
  constructor(private store: Store<fromRoot.State>) {}

  transform(alert: IAlarmTransportowyUproszczony): Observable<boolean> {
    return this.store
      .select((state) => state.alert.prevAlertsUpdate)
      .pipe(
        map(
          (prevAlertsUpdate) =>
            prevAlertsUpdate !== null &&
            moment(alert.czasRozpoczecia).isAfter(prevAlertsUpdate)
        )
      );
  }
}
