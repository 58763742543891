<mat-form-field appearance="outline">
  <mat-label>{{ label }}</mat-label>

  <mat-icon matPrefix class="material-icons-outlined" *ngIf="prefixIcon">{{prefixIcon}}</mat-icon>
  <input type="text" matInput [formControl]="control" [matAutocomplete]="auto" name="{{name}}"
         [required]="isRequired()">
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayWithFn">
    <mat-option *ngFor="let option of filteredOptions | async"
                [value]="option" class="mat-body-2" [disabled]="option.disabled">
      <span fxLayout="column">
        <span class="title">
          <mat-icon matPrefix *ngIf="prefixIcon">{{prefixIcon}}</mat-icon>
          {{option.label}}
        </span>
        <span class="sub-title" *ngIf="option.subLabel">{{option.subLabel}}</span>
      </span>
    </mat-option>
    <mat-option *ngIf="(filteredOptions | async)?.length < 1" disabled="true">
      <em>No matching results</em>
    </mat-option>
  </mat-autocomplete>
  <mat-icon matSuffix *ngIf="suffixIcon">{{suffixIcon}}</mat-icon>

  <mat-hint *ngIf="hint">{{hint}}</mat-hint>
  <mat-error *ngIf="control.hasError('required')">Value is required.</mat-error>
  <mat-error *ngIf="control.hasError('custom')">{{ control.errors.custom.message }}.</mat-error>
</mat-form-field>
