import { createAction, props } from '@ngrx/store';
import { PunktSpedycyjny, ZestawTransportowyBase } from '../../../models/dto/transportSets';
import { Kierowca, ZestawTransportowyRequest, ZestawTransportowyRequestNew } from '../interfaces';
import { NewTransitRouteRequest } from '../../../models/form/map-search-route-form';

export const loadShippingPointRequest = createAction('[TRANS_SET] load shipping points - request');
export const loadShippingPointSuccess = createAction(
  '[TRANS_SET] load shipping points - success',
  props<{ punkty: PunktSpedycyjny[] }>()
);

export const newShippingPointRequest = createAction(
  '[TRANS_SET] Save shipping point - request',
  props<{ punkt: PunktSpedycyjny }>()
);

export const updateShippingPointRequest = createAction(
  '[TRANS_SET] Update shipping point - request',
  props<{ punkt: PunktSpedycyjny }>()
);

export const removeShippingPointRequest = createAction(
  '[TRANS_SET] Remove shipping point - request',
  props<{ id: number, successCallback?: () => void }>()
);

export const shippingPointSuccess = createAction(
  '[TRANS_SET] Shipping point - success',
  props<{ message: string }>()
);

export const shippingPointFailure = createAction(
  '[TRANS_SET] Shipping point - failure'
);

export const loadDriversRequest = createAction('[TRANS_SET] load drivers - request');
export const loadDriversSuccess = createAction(
  '[TRANS_SET] load drivers - success',
  props<{ kierowcy: Kierowca[] }>()
);

export const loadFullTranssetListRequestNew = createAction('[TRANS_SET] load full transset list NEW - request');
export const loadFullTranssetListSuccessNew = createAction(
  '[TRANS_SET] load full transset list NEW - success',
  props<{ zestawy: ZestawTransportowyBase[] }>()
);

export const addNewSetFull = createAction(
  '[TRANS_SET] add new set - full info',
  props<{ request: ZestawTransportowyRequest }>()
);

export const addNewSetFullNew = createAction(
  '[TRANS_SET] add new set - full info new',
  props<{ request: ZestawTransportowyRequestNew }>()
);

export const newTranssetRouteRequest = createAction(
  '[TRANS_SET] new transset route - request',
  props<{ request: NewTransitRouteRequest, successCallback: () => void }>()
);

export const newTranssetRouteRequestCompleted = createAction(
  '[TRANS_SET] new transset route - request completed'
);

export const newTranssetRouteRequestFailed = createAction(
  '[TRANS_SET] new transset route - request failed'
);
