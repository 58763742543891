export enum DriverReportsColumnsEnum {
  Id = 'Id',
  Status = 'Status',
  Driver = 'Driver',
  Description = 'Description',
  Route = 'Route',
  ReportDate = 'Date',
  GoodsOwner = 'Goods owner',
  Actions = 'Actions',
}
