import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { EnvHelper, MomentHelper } from '../../../helpers';
import { ITransportAssignedDriver, ITransportEntity, ITransportEntityExtended } from '../interfaces/transport-entity';
import { IRouteEntity } from '../interfaces/route-entity';
import { IRouteTemplateEntity } from '../interfaces/route-template-entity';
import { ITemplateMadeRouteSection } from '../interfaces/template-made-route-section';
import { ZestawTransportowyBase } from '../../../models/dto/transportSets';

import { environment } from '../../../../environments/environment';
import { CompanyMonit, ICompanyRoute, IDriverEntity, ISaveTransitRequest, Parameter } from '../interfaces';
import { IDriverEntityDTO, IRouteEntityDTO, IRouteTemplateEntityDTO } from '../../../models/dto/transit';
import { AtLeast } from '../../../helpers/types';
import { Terminal } from '../../../models/dto/terminale';
import { AddedDriver } from '../../../models/form/transit';

@Injectable({
  providedIn: 'root'
})
export class TransitService {
  apiConfig = environment.apiModules;

  constructor(
    private readonly http: HttpClient
  ) {
  }

  getAllTransits(): Observable<ITransportEntity[]> {
    return this.http.get<ITransportEntity[]>(
      EnvHelper.getApiUrl() + this.apiConfig.Transit.przejazd + '/',
    );
  }

  getAvailMonitParams(transitId: number): Observable<Parameter[]> {
    return this.http.get<Parameter[]>(
      EnvHelper.getApiUrl() + this.apiConfig.Transit.dostepneParametry + '/' + transitId
    );
  }

  getMonitTerminalList(): Observable<Terminal[]> {
    return this.http.get<Terminal[]>(
      EnvHelper.getApiUrl() + this.apiConfig.Transit.cmMonitorowaneTerminale
    );
  }

  getAssignedMonitParams(transitId: number): Observable<any[]> {
    return this.http.get<any[]>(
      EnvHelper.getApiUrl() + this.apiConfig.Transit.ustawioneParametry + '/' + transitId
    );
  }

  getRouteTemplatesByPageNumber(page: number): Observable<IRouteTemplateEntity[]> {
    return this.http.get<IRouteTemplateEntityDTO[]>(
      EnvHelper.getApiUrl() + this.apiConfig.Transit.szablon_trasy + '?page=' + page,
    ).pipe(tap((t)=>{console.log(t)}));
  }

  getRoutesByPageNumber(page: number): Observable<IRouteEntity[]> {
    return this.http.get<IRouteEntityDTO[]>(
      EnvHelper.getApiUrl() + this.apiConfig.Transit.trasa + '?page=' + page,
    );
  }

  getTransportSetsAvailableWithinTimeRange(from: string, to: string): Observable<ZestawTransportowyBase[]> {
    return this.http.get<ZestawTransportowyBase[]>(
      EnvHelper.getApiUrl() + this.apiConfig.Transit.zestawy_dostepne_odczyt + '?CzasDo=' + to + '&CzasOd=' + from,
    );
  }

  getAllTransportSets(): Observable<ZestawTransportowyBase[]> {
    return this.http.get<ZestawTransportowyBase[]>(
      EnvHelper.getApiUrl() + this.apiConfig.Transit.zestawy_wszystkie_odczyt,
    );
  }

  getDriversAvailableWithinTimeRange(from: string, to: string): Observable<IDriverEntity[]> {
    return this.http.get<IDriverEntityDTO[]>(
      EnvHelper.getApiUrl() + this.apiConfig.Transit.przejazd_kierowcy_odczyt + '?CzasDo=' + to + '&CzasOd=' + from,
    );
  }

  createNewTransit(transit: ISaveTransitRequest): Observable<ITransportEntity> {
    return this.http.post<ITransportEntity>(
      EnvHelper.getApiUrl() + this.apiConfig.Transit.przejazd + '_edycja',
      transit,
    );
  }

  updateTransit(transit: ISaveTransitRequest): Observable<void> {
    return this.http.patch<void>(
      EnvHelper.getApiUrl() + this.apiConfig.Transit.przejazd + '_edycja/' + transit.id,
      transit,
    );
  }

  assignDriverToTransit(driver: AtLeast<ITransportAssignedDriver, { idKierowca: number, idPrzejazd: number }>):
    Observable<ITransportAssignedDriver> {
    if (driver.id) {
      return this.removeDriversFromTransit(driver.idPrzejazd, [driver.idKierowca])
        .pipe(switchMap(() => {
          return this.http.post<ITransportAssignedDriver>(
            EnvHelper.getApiUrl() + this.apiConfig.Transit.kierowca_przejazd,
            driver,
          );
        }));
      // todo: po poprawce na backend-zie zmienić na put
      // return this.http.put<ITransitAssignedDriver>(
      //   EnvHelper.getApiUrl() + this.apiConfig.Transit.kierowca_przejazd,
      //   driver,
      // );
    }
    return this.http.post<ITransportAssignedDriver>(
      EnvHelper.getApiUrl() + this.apiConfig.Transit.kierowca_przejazd,
      driver,
    );
  }

  removeDriversFromTransit(transitId: number, drivers: number[]): Observable<unknown> {
    return this.http.delete(
      EnvHelper.getApiUrl() + this.apiConfig.Transit.usun_kierowcow_z_przejazdu + '/' + transitId + '?' + drivers.map(
        (driver) => `driverList=${driver}`
      ).join('&'),
    );
  }

  deleteTransit(transitId: number): Observable<any> {
    return this.http.delete(
      EnvHelper.getApiUrl() + this.apiConfig.Transit.przejazd + '/' + transitId,
    );
  }

  getTransitById(transitId: number): Observable<ITransportEntityExtended> {
    return this.http.get<ITransportEntityExtended>(
      EnvHelper.getApiUrl() + this.apiConfig.Transit.przejazdPoId + '/' + transitId
    );
  }

  saveTemplateMadeRouteSection(routeSection: ITemplateMadeRouteSection): Observable<ITemplateMadeRouteSection> {
    return this.http.post<ITemplateMadeRouteSection>(
      EnvHelper.getApiUrl() + this.apiConfig.Transit.zapisz_trase_z_szablonu_edycja,
      routeSection,
    );
  }

  saveMonitoringParameter(param: any): Observable<any> {
    return this.http.post<any>(
      EnvHelper.getApiUrl() + this.apiConfig.Transit.dodanieParametru, [param]
    );
  }

  deleteMonitoringParameter(id: number): Observable<any> {
    return this.http.delete<any>(
      EnvHelper.getApiUrl() + this.apiConfig.Transit.usuwanieParametru + id
    );
  }

  removeTransportRouteSection(id: number): Observable<any> {
    return this.http.delete<any>(
      EnvHelper.getApiUrl() + this.apiConfig.Transit.trasa_firmy + '_edycja/' + id
    );
  }

  getAssignedRouteSectionListForTransit(transitId: number): Observable<ICompanyRoute[]> {
    return this.http.get<ICompanyRoute[]>(
      EnvHelper.getApiUrl() + this.apiConfig.Transit.trasa_firmy + '_odczyt?_przejazd.id=' + transitId,
    );
  }

  getDriversDesignatedToNewlyCreatedTransit(transitId: number, addedDrivers: AddedDriver[]) {
    return addedDrivers.map((addedDriver) => {
      const to_ = MomentHelper.datetimeForGivenDateAndTime(addedDriver['toDate'], addedDriver['toTime']).toISOString();
      const from_ = MomentHelper.datetimeForGivenDateAndTime(addedDriver['fromDate'], addedDriver['fromTime']).toISOString();
      if (addedDriver.driver) {
        return {
          czasDo: null,
          czasMod: null,
          czasOd: null,
          id: null,
          idKierowca: addedDriver.driver.id,
          idPrzejazd: transitId,
          kieruje: false,
          planowanyCzasDo: to_,
          planowanyCzasOd: from_,
          wyslanoKierowcy: null,
        };
      } else {
        return null;
      }
    });
  }

  startTransport(transportId: number, newBoltPin?: boolean): Observable<unknown> {
    let url = this.apiConfig.Transit.przejazd + '_rozpocznij/' + transportId;
    if (newBoltPin === true) {
      url += '/kod/true';
    }
    return this.http.put<unknown>(
      EnvHelper.getApiUrl() + url, []
    );
  }

  stopTransport(transportId: number): Observable<unknown> {
    return this.http.put<unknown>(
      EnvHelper.getApiUrl() + this.apiConfig.Transit.przejazd + '_zakoncz/' + transportId, []
    );
  }

  startTestingTransport(transportId: number): Observable<unknown> {
    const url = this.apiConfig.Transit.przejazd + '/rozpocznij_testy/' + transportId;
    return this.http.put<unknown>(
      EnvHelper.getApiUrl() + url, undefined
    );
  }

  getCmList(): Observable<CompanyMonit[]> {
    return this.http.get<CompanyMonit[]>(
      EnvHelper.getApiUrl() + this.apiConfig.Transit.cm
    );
  }

  updateTransport(transportValues: Partial<ITransportEntity>, transportId: number): Observable<unknown> {
    return this.http.patch(
      EnvHelper.getApiUrl() + this.apiConfig.Transit.przejazd + '_edycja/' + transportId, transportValues
    );
  }

  activateMonitoring(transportId: number): Observable<unknown> {
    return this.http.put<unknown>(
      EnvHelper.getApiUrl() + this.apiConfig.Transit.zlecenie_nadzoru_przeslij + '/' + transportId, []
    );
  }

  generateReport(transportId: number): Observable<HttpResponse<BlobPart>> {
    return this.http.get<BlobPart>(
      `${EnvHelper.getApiUrl()}${this.apiConfig.Transit.generate_report}`,
      {
        responseType: 'blob' as 'json',
        observe: 'response',
        params: { przejazdId: transportId }
      },
    );
  }

  armDisarmSiren(
    transitId: number,
    armSiren: boolean
  ): Observable<unknown> {
    return this.http.put(
      `${EnvHelper.getApiUrl()}${this.apiConfig.Transit.przejazd}_syrena/${transitId}/uzbroj/${armSiren}`,
      {}
    );
  }

  openCloseBolt(
    transitId: number,
    terminalId: number,
    openBolt: boolean
  ): Observable<unknown> {
    return this.http.put(
      `${EnvHelper.getApiUrl()}${this.apiConfig.Transit.przejazd}_rygiel/${transitId}/terminal/${terminalId}/zamknij/${openBolt}`,
      {}
    );
  }
}
