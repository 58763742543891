import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { MapMainComponent } from './main/map-main.component';
import { IsAuthenticatedGuard } from '../../guards';
import { ViewerComponent } from './viewer/viewer.component';
import { MapViewRoute } from '../../helpers/enum';

const routes: Routes = [
  {
    path: 'map', component: MapMainComponent, canActivate: [IsAuthenticatedGuard], data: {breadcrumb: 'Map'},
  },
  {
    path: 'map-view', canActivate: [IsAuthenticatedGuard], children: [
      {path: MapViewRoute.ALERT + '/:alertId/:transportId', component: ViewerComponent},
      {path: MapViewRoute.PARKING, component: ViewerComponent},
      {path: MapViewRoute.TRANSPORT_NEW_ROUTE, component: ViewerComponent},
      {path: MapViewRoute.TRANSPORT_PARKING_LIST, component: ViewerComponent},
      {path: MapViewRoute.PARKING_LIST, component: ViewerComponent},
      {path: MapViewRoute.SHIPPING_POINT_LIST, component: ViewerComponent},
      {path: MapViewRoute.SHIPPING_POINT + '/:id', component: ViewerComponent},
      {path: MapViewRoute.SHIPPING_POINT, component: ViewerComponent},
      {path: MapViewRoute.TRANSPORT_PROGRESS + '/:transportId', component: ViewerComponent},
      {path: MapViewRoute.TRANSPORT_ROUTE + '/:transportId', component: ViewerComponent},
      {path: MapViewRoute.ALARM_TEST + '/:transportId', component: ViewerComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MapRouting {
}
