<div fxFlex fxLayout="column" fxLayoutGap="5px">

  <div class="filter-and-switcher" fxLayout="row">
    <mat-form-field class="filter-form-field">
      <mat-label>Type to search...</mat-label>
      <input matInput [formControl]="searchControl" name="fdw-search-alert">
      <mat-icon matSuffix class="filter-icon">search</mat-icon>
    </mat-form-field>
    <mat-form-field class="filter-form-field pl-1">
      <mat-label>Filter by status</mat-label>
      <mat-select [formControl]="statusFilterControl" class="select-input">
        <mat-option [value]="alertFilterStatus.Active">Active</mat-option>
        <mat-option [value]="alertFilterStatus.Inactive">Inactive</mat-option>
        <mat-option [value]="alertFilterStatus.All">All</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="view-switcher" fxFlex="1 0 auto">
      <span class="pointer" (click)="tileView()">
        <mat-icon class="apps {{viewMode$ | async}}">apps</mat-icon>
      </span>
      <span class="pointer" (click)="tableView()">
        <mat-icon class="menu {{viewMode$ | async}}">menu</mat-icon>
      </span>
    </div>
  </div>

  <div *ngIf="(viewMode$ | async) === eViewMode.TABLE">
    <app-alert-table-list [alertsList]="alertsList"></app-alert-table-list>
  </div>

  <div *ngIf="(viewMode$ | async) === eViewMode.TILE">
    <app-alert-tile-list [flattenedAlerts]="dataSource.data"></app-alert-tile-list>
  </div>

</div>
